import React from 'react';
import { Card, CardContent, Typography, Grid, withStyles, Divider } from '@material-ui/core';
import commonStyles from '../../../commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  card: {
    boxShadow: 'none', // Remove the card shadow
  },
  boldHeader: {
    fontWeight: 'bold',
  },
  customTitle: {
    color: '#3f50b5',
  },
  innerDiv: {
    marginBottom: theme.spacing(2), // Add space between items
  },
  divider: {
    marginTop: theme.spacing(2), 
    marginBottom: theme.spacing(2), 
    backgroundColor: '#3f50b5', 
    height: '2px', 
  },
});
const formatDate = (dateString) => {
  const options = { year: 'numeric', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const EventDetailsCard = ({ eventData, classes }) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" gutterBottom className={`${classes.boldHeader} ${classes.customTitle}`}>
          Event Details
        </Typography>
        <Divider variant="fullWidth" className={classes.divider} />
        <Typography variant="h5" >Event Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className={classes.gridItem} >
            {/*<div className={classes.innerDiv}>*/}
              <Typography variant="body1" className={classes.boldHeader}>
                Event ID:
              </Typography>
              <Typography variant="body1">
                {eventData.txnEventId}
              </Typography>
              </Grid>
            {/*</div>*/}
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Event Type:
              </Typography>
              <Typography variant="body1">
                {eventData.eventType.eventTypeName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Event Subtype:
              </Typography>
              <Typography variant="body1">
                {eventData.eventSubtype.eventSubtypeName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Notification Source:
              </Typography>
              <Typography variant="body1">
                {eventData?.notifyBy || "NA"}
              </Typography>
            </Grid>
          
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Direction:
              </Typography>
              <Typography variant="body1">
                {eventData.directionName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Location:
              </Typography>
              <Typography variant="body1">
                {eventData.eventLatitude + "," + eventData.eventLongitude}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Event Date:
              </Typography>
              <Typography variant="body1">
              {formatDate(eventData.eventDate)}
              </Typography>
            </Grid>
           
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Event Description:
              </Typography>
              <Typography variant="body1">
                {eventData.eventDescription}
              </Typography>
            </Grid>
        </Grid>

            <Divider variant="fullWidth" className={classes.atmsPageSectionDivider}  />

            <Typography variant="h5" >Equipment Information</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Equipment Name:
              </Typography>
              <Typography variant="body1">
                {eventData.equipment.equipmentName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Equipment Type:
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant="body1">
                {eventData.equipmentType.equipmentTypeName}
              </Typography>
            </Grid>
          <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Chainage Equipment:
              </Typography>
              <Typography variant="body1">
                {eventData.chainageEquipment?.equipmentName || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Chainage Name:
              </Typography>
              <Typography variant="body1">
                {eventData.chainageEquipment?.chainageName || "NA"}
              </Typography>
            </Grid>
         
       </Grid>

       <Divider variant="fullWidth" className={classes.atmsPageSectionDivider}  />
       <Typography variant="h5" >Event Status</Typography>
       <Grid container spacing={2}>
       <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Event Severity:
              </Typography>
              <Typography  variant="body1">
                {eventData.eventSeverity?.severityName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Detection Time:
              </Typography>
              <Typography  variant="body1">
                {formatDate(eventData?.detectionTime)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Activation Time:
              </Typography>
              <Typography variant="body1">
                {formatDate(eventData?.detectionTime)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Resolved Time:
              </Typography>
              <Typography  variant="body1">
                {formatDate(eventData?.resolveTime)}
              </Typography>
            </Grid>
             <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Nearest Hospital:
              </Typography>
              <Typography  variant="body1">
                {eventData?.nearestHospitalLocation?.locationDetailsName || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Nearest POLICE-Station:
              </Typography>
              <Typography  variant="body1">
                {eventData?.nearestPoliceLocation?.locationDetailsName || "NA"}
              </Typography>
            </Grid>
           <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Resolve Remarks:
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant="body1">
                {eventData.resolveRemarks}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem} >
              <Typography variant="body1" className={classes.boldHeader}>
                Created By:
              </Typography>
              <Typography  variant="body1">
                {eventData.createdBy?.userDetailName}
              </Typography>
            </Grid>
         </Grid>
      

      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(EventDetailsCard);
