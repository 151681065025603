import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import commonStyles from "../../../commonStyles";
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, Button, InputLabel, Box, Typography, Modal,List,ListItem,ListItemIcon,ListItemText
} from '@material-ui/core';
import axios from "axios"
import appConfig from '../../../appConfig.json';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';



const BASE_URL = appConfig.BASE_URL;


const styles = (theme) => ({
  ...commonStyles(theme),
  treeView: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'100%'
  },
  repList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '200px',
    color:'red'
  }
});

let expandList = [];
let currentUserRoleId ;
class AssignedReportList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      selectedRoleEntitleMents: [],
      isUpdated: false,
      allreportList: [],
      checked:[],
      selectedReport:[{"reportDetailsId": ""}],
      alert: {
        msg: null,
        severity: 'success'
      },
      openSnakbar: false,

      reportCheckedState: {}

    }
  }

  // currentUserRoleId = useSelector(state => state.auth.user?.userRoleId);
  // var selectedReports = [];
  //handleToggle = (value) => () => {
    
  //  const currentIndex = this.state.checked.indexOf(value);
  //  console.log("%%%%%%%%%%%%", this.state.checked)
  //  const newChecked = [...this.state.checked];
  //  console.log("currentIndex", currentIndex)
  //  console.log("newChecked", newChecked)
    

  //  if (currentIndex === -1) {
  //    newChecked.push(value);
  //    expandList.push({"reportDetailsId":value, "roleId":this.props.selectedRoleId})
  //  } else {
  //    newChecked.splice(currentIndex, 1);
  //    expandList.splice(currentIndex,1);
  //  }

  //  this.setState({checked : newChecked});
    
  //};

  handleToggle = (value) => () => {
    const { checked, reportCheckedState } = this.state;
  
    const isChecked = !reportCheckedState[value];
  
    // Update the checked state for the specific report
    this.setState((prevState) => ({
      reportCheckedState: {
        ...prevState.reportCheckedState,
        [value]: isChecked,
      },
    }));
  
    // Update the checked array
    const newChecked = isChecked ? [...checked, value] : checked.filter((item) => item !== value);
  
    // Update the expandList
    const newExpandList = isChecked
      ? [...expandList, { reportDetailsId: value, roleId: this.props.selectedRoleId }]
      : expandList.filter((item) => item.reportDetailsId !== value);
  
    this.setState({
      checked: newChecked,
    });
  
    expandList = newExpandList;
  };
  
  
  

  openSnakbar = (alertType, alertMsg) => {
    this.setState({ openSnakbar: true, alert: { msg: alertMsg, severity: alertType } })
  }

  handleClick = () => {
    // Notify the parent component to refresh
    this.props.onRefresh();
  }

  handleUpdate = async () => {
    // const UPDATE_URI = 'http://localhost:3030/api/roleEntitlements'
    const UPDATE_URI = BASE_URL + '/api/reportPrivileges'

    await axios.patch(UPDATE_URI, [...expandList]).then((res) => {
      this.openSnakbar('success', 'Removed Report Successfully');
      this.setState({ isUpdated: true })
      //this.props.setUserName(true)
      expandList = [];
      this.handleClick();

    }).catch(e => {
      console.log("error in udating entitlements", e)
      this.openSnakbar('error', 'Error !! Error while Removing Report !!!');
    })
  }

  
  // if user role change update report list
  watchSelectedChangeRole(selectedRoleId) {
    if (this.props.selectedRoleId === '') {
      this.setState({ selectedRoleEntitleMents: [] })
    } else {
      this.setState({ isLoading: true })
      // const SelctedRoleEndPoint = `http://localhost:3030/api/roleEntitlements?roleId=${selectedRoleId}&entitlementsType=0`
      // const SelctedRoleEndPoint = BASE_URL + `/api/reportPrivileges?roleId=${this.props.currentUserRoleId}`
      const SelctedRoleEndPoint = BASE_URL + `/api/reportPrivileges?roleId=${selectedRoleId}`
       
    

      axios.get(SelctedRoleEndPoint).then((res) => {
        const initialReportCheckedState = res.data.reduce((acc, report) => {
          acc[report.reportDetailsId] = false; // Set to true or false based on your logic
          return acc;
        }, {});
        this.setState({ selectedRoleEntitleMents: [...res.data], reportCheckedState: initialReportCheckedState, isLoading: false })
      }).catch(e => {
        console.log("error in feching entitlements", e)
        this.props.openSnakbar('Error', 'Error in fetching Entitlements!!!');
        this.setState({ selectedRoleEntitleMents: [], isLoading: false })
      })
    }
  }

  


  componentDidUpdate(prevProps, prevState) {
    // if role change update values
    if (this.state.isUpdated ) {
      this.watchSelectedChangeRole(this.props.selectedRoleId)
      this.setState({ isUpdated: false })
    }
    //if (prevProps.selectedRoleId !== this.props.selectedRoleId) {
    //  this.watchSelectedChangeRole(this.props.selectedRoleId)
    //}

    if (prevProps.refreshSibling2 !== this.props.refreshSibling2 && this.props.refreshSibling2 ) {
      console.log('SiblingComponent1 Refreshed');
      this.watchSelectedChangeRole(this.props.selectedRoleId);
     
    }

  }

  componentDidMount() {
    this.watchSelectedChangeRole(this.props.selectedRoleId)
  }

  handleClose = () => {
    this.setState({ openSnakbar: false })
  }
  
 
  // rendering reports
  render() {
    const { classes } = this.props;

    let reportList = this.state.selectedRoleEntitleMents.map(e=> e.reportDetails);
    let totalReportsList = this.state.allreportList.map(e=> e.reportDetails)
    // console.log("selectedRoleEntitleMents",this.state.selectedRoleEntitleMents)
    console.log("reportList-A", reportList)
    // console.log("totalReportList", totalReportsList)
    // console.log("this.state.checked11111", this.state.checked)
    // console.log("selectedReport", this.state.selectedReport)
    console.log("expandList-A", expandList)

    console.log("this.props.isRefreshssssssssssssssssssssss", this.props)

    return (
      <div>
        <Card >
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSnakbar} autoHideDuration={6000} onClose={this.handleClose}>
          <MuiAlert className={classes.snakbar} elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.alert.severity}>
            {this.state.alert.msg}
          </MuiAlert>
        </Snackbar>
          <CardHeader
            className={classes.textPrimary}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            title='Assigned Reports'
          />
          <Divider variant="list" className={classes.atmsPageSectionDivider} />
          <CardContent
           
            style={{ height: "50vh", overflow: "auto", fontWeight: 'bold' }}
            
          >
            
            {reportList.length > 0 ? 
              <FormGroup row>
                <Grid container spacing={6}>
                  <List style={{ marginTop: "2em" }}>
                    
                    {reportList && reportList.map((report) => {
                      const labelId = `transfer-list-item-${report.reportDetailsId}-label`
                      return (
                        
                        <Grid item md={2} xs={12}>
                          <ListItem
                            
                            disablePadding
                            key={report.reportDetailsId}
                            // role="listitem"
                            button
                            // onClick={this.handleToggle(report.reportDetailsId)}
                          >
                              <Checkbox 
                              // (this.state.stakeholderArray.indexOf(input.name) !== -1 ? true : false)
                              //   (this.props.form.EventGeneralDtsForm.values.stakeHolder && this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',').indexOf(input.name) !== -1) ? true : false
                                checked={this.state.reportCheckedState[report.reportDetailsId]}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                                onChange={this.handleToggle(report.reportDetailsId)}
                                
                              />
                              <ListItemText 
                                  id={report.reportDetailsId} 
                                  primary={`${report.reportName} REPORT`}
                                  primaryTypographyProps={{ 
                                    variant: 'subtitle2', 
                                    style: {
                                        whiteSpace: 'nowrap',
                                        // overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }
                                  } 
                                  }/>

                          </ListItem>
                            
                        </Grid>
                      )
                    })}
                  </List>
                </Grid>
              </FormGroup>
            :
              <div className={classes.repList}>
              <h3 >
                No Reports Assigned Yet !
              </h3>
              </div>
            }
          </CardContent>
        </Card>
        {reportList.length >0 ?
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
            >
            {/* <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">EDIT</Button> */}
            <Button 
              className={classes.button} 
              color="primary" 
              size="large" 
              variant="contained" 
              onClick={this.handleUpdate} 
              disabled={!expandList.length > 0 }
            >REMOVE</Button>
          </Box>
          :""}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuItems: state.menu.menuItems,
    currentUserRoleId: state.auth.user.userRoleId
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AssignedReportList));