import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Container, Button, Modal, Typography, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DataGrid from '../../../../components/mastersGrid/DataGrid';
import { connect } from 'react-redux';
import AssetVehicleCatCreate from './assetVehicleCatCreate';
import AssetVehicleCatEdit from './assetVehicleCatEdit';
import Toaster from '../../../../components/common/Toaster';
import checkEntitlement from "../../../../utils/checkEntitlement";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8'
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto',

    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    }
});


class AssetVehicleCat extends React.Component {

    currentSelectedRow = null;

    constructor(props) {
        super(props)

        this.state = {
            isEnabled: 'all',
            urlParams: [],
            isOpen: false,
            modalType: ""
        };
        this.grid = React.createRef();
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        /*
         * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
         */
        if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
            this.grid.current.reload();
        }
    }

    render() {
        const { classes } = this.props;
        let modalDiv = null;

        if (this.state.modalType === "ADD") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
                closeAfterTransition>
                <AssetVehicleCatCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        if (this.state.modalType === "EDIT") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
                closeAfterTransition>
                <AssetVehicleCatEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        return (
            <Container maxWidth="x1" className={classes.root}>
                <Toaster
                    open={this.props.toasterOpen}
                    message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
                    severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
                />

                <Box mt={2}>
                    <Typography variant="h4" gutterBottom className={classes.header}>
                        Asset Vehicle Category
                </Typography>
                    <Button variant="contained"
                        disabled={!checkEntitlement(this.props.location.pathname, 'add')}
                        color="primary"
                        startIcon={<AddIcon />}
                        className={classes.addButton}
                        onClick={() => this.setState({ isOpen: true, modalType: "ADD" })} >
                        Add NEW ASSET VEHICLE CATEGORY
                </Button>
                </Box>
                <Box mt={1}>
                    <DataGrid downloadReport={true} ref={this.grid} endpoint="api/assetVehicleCategories" urlParams={this.state.urlParams} isEditable={checkEntitlement(this.props.location.pathname, 'edit')} onEditClick={(event, rowData) => {
                        this.currentSelectedRow = rowData;
                        this.setState({ isOpen: true, modalType: "EDIT" })
                    }}
                    />
                </Box>
                {modalDiv}
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.assetVehicleCatReducer.loading,
        toasterOpen: state.common.toasterOpen,
        toasterMessageConfig: state.common.toasterMessageConfig
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(AssetVehicleCat));