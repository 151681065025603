import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm, isDirty } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import _ from 'lodash';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

class EventSubtypeAddForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            eventTypeId: 0,
            eventSeverityId: 0
        };
    }

    componentDidMount() {
        this.props.getEventTypesForEventSubtype();
        this.props.getEventSeverityForEventSubtype();      
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add New Event Subtype"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>

            </React.Fragment>
            subheaderName = "Edit Event Subtype"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Event Subtype"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Event Type" id="eventTypeId" >
                                        <option value={""}>{'Select Event Type'}</option>
                                        {this.props.ArrEventType.map((eventTypeDropDown) => <option key={eventTypeDropDown.eventTypeId} value={eventTypeDropDown.eventTypeId}>{eventTypeDropDown.eventTypeName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventSubtypeName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Event Subtype Name" id="eventSubtypeName" />
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventSubtypeDescription" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Event Subtype Description" id="eventSubtypeDescription" />
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="sensorEventId" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Sensor Event Id" id="sensorEventId" />
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventSeverityId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Event Severity" id="eventSeverityId" >
                                        <option value={""}>{'Select Event Severity'}</option>
                                        {this.props.ArrEventSeverity.map((eventSeverityDropDown) => <option key={eventSeverityDropDown.eventSeverityId} value={eventSeverityDropDown.eventSeverityId}>{eventSeverityDropDown.severityName}</option>)}
                                    </Field>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Box mt={3}>
                                <Divider />
                                <Card>
                                    <CardHeader
                                        subheader="Event Subtype Details" >
                                    </CardHeader>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item item md={6} xs={12}>
                                                <Field name="registerEventSampleCount" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Register Event Sample Count" id="registerEventSampleCount" />
                                            </Grid>
                                            <Grid item item md={6} xs={12}>
                                                <Field name="registerEventSamplingTime" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Register Event Sampling Time" id="registerEventSamplingTime" />
                                            </Grid>
                                            <Grid item item md={6} xs={12}>
                                                <Field name="breakEventSamplingTime" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Break Event Sampling Time" id="breakEventSamplingTime" />
                                            </Grid>
                                            <Grid item item md={6} xs={12}>
                                                <Field name="breakEventSampleCount" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Break Event Sample Count" id="breakEventSampleCount" />
                                            </Grid>
                                            <Grid item item md={6} xs={12}>
                                                <label>All time in seconds</label>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Grid>{isEnabled}</Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const RegXforName = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
    const RegXforNumber = /^[0-9\b]+$/;

    if (!formValues.eventTypeId) {
        error.eventTypeId = "Select Event Type";
    }

    if (formValues.eventTypeId) {
        if (formValues.eventTypeId == "") {
            error.eventTypeId = "Select Event Type";
        }
    }

    if (!formValues.eventSubtypeName) {
        error.eventSubtypeName = "Enter Event Subtype Name";
    }
    if (formValues.eventSubtypeName) {
        if (!RegXforName.test(formValues.eventSubtypeName)) {
            error.eventSubtypeName = "No special characters are allowed";
        }
    }
    if (!formValues.eventSubtypeDescription) {
        error.eventSubtypeDescription = "Enter Event Subtype Description";
    }

    if (formValues.eventSubtypeDescription) {
        if (formValues.eventSubtypeDescription.length > 201) {
            error.eventSubtypeDescription = "Maximum characters should be 200 only";
        }
    }
    if (!formValues.sensorEventId) {
        error.sensorEventId = "Enter Sensor Event ID";
    }
    if (!formValues.eventSeverityId) {
        error.eventSeverityId = "Select Event Severity";
    }

    if (formValues.eventSeverityId) {
        if (formValues.eventSeverityId == "") {
            error.eventSeverityId = "Select Event Severity";
        }
    }

    if (formValues.registerEventSampleCount) {
        if (!RegXforNumber.test(formValues.registerEventSampleCount)) {
            error.registerEventSampleCount = "Enter only Numbers";
        }
    }

    if (formValues.registerEventSamplingTime) {
        if (!RegXforNumber.test(formValues.registerEventSamplingTime)) {
            error.registerEventSamplingTime = "Enter only Numbers";
        }
    }

    if (formValues.breakEventSamplingTime) {
        if (!RegXforNumber.test(formValues.breakEventSamplingTime)) {
            error.breakEventSamplingTime = "Enter only Numbers";
        }
    }

    if (formValues.breakEventSampleCount) {
        if (!RegXforNumber.test(formValues.breakEventSampleCount)) {
            error.breakEventSampleCount = "Enter only Numbers";
        }
    }

    return error;
};

const mapStateToProps = state => {
    return {
        ArrEventType: state.eventSubtypeReducer.ArrEventType,
        ArrEventSeverity: state.eventSubtypeReducer.ArrEventSeverity
    };
};
const mapDispatchToProps = (dispatch, state) => {
    return {
        getEventTypesForEventSubtype: () => dispatch(actions.getEventTypesForEventSubtype()),
        getEventSeverityForEventSubtype: () => dispatch(actions.getEventSeverityForEventSubtype())      
    };
};

EventSubtypeAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventSubtypeAddForm)

export default reduxForm({
    validate: validate,
    form: 'EventSubtypeAddForm'
})(withStyles(styles)(EventSubtypeAddForm));