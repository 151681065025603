import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, InputLabel, Grid } from '@material-ui/core';


const styles = theme => ({
    selectFormControl: {
         minWidth: 120,
    },
    header: {
        display: 'inline'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    header: {
        display: 'inline'
    },
    gridCont: {
        margin : '8px 0px'
    }
});

class RejectionDetails extends React.Component {


    render() {                
        const { classes } = this.props;        
        let fromAnprImgPath = null;
        let toAnprImgPath = null;

        fromAnprImgPath = "NoImageAvailable.jpg"; 
        toAnprImgPath = "NoImageAvailable.jpg";


        if (this.props.selectedRejectedRow) {
            fromAnprImgPath = "NoImageAvailable.jpg"; 
            toAnprImgPath = "NoImageAvailable.jpg";

            if (this.props.selectedRejectedRow.fromAnprImgPath) {
                fromAnprImgPath = this.props.selectedRejectedRow.fromAnprImgPath
            }

            if (this.props.selectedRejectedRow.toAnprImgPath) {
                toAnprImgPath = this.props.selectedRejectedRow.toAnprImgPath
            }

            return (
            <React.Fragment>
                    <Grid container spacing={3}>
                        {/* 
                          * Violation Scrutiny Div. Accept and Reject the violation
                          * 
                          */}
                        <Grid item xs={6}>
                            <Grid container spacing={1} className={classes.gridCont}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Challan Details
                                </Typography>
                            </Grid>
                            <Grid container spacing={1}>
                            {/* 1.Violation ID */}
                                <Grid item xs={2} >
                 
                                    <TextField
                                        id="violationId"
                                        label="Violation Id"
                                        value={this.props.selectedRejectedRow.txnSpeedViolationId}
                                        fullWidth
                                        disabled                            
                                        variant="filled"
                                    />
                                </Grid>
                                
                                {/* 2.Vehicle Number*/}
                                <Grid item xs={2}>
                        
                                    <TextField
                                        id="vehicleNumber"
                                        label="Vehicle Number"
                                        value={this.props.selectedRejectedRow.vehicleNumber}
                                        fullWidth
                                        disabled
                                        variant="filled"
                                    />
                                </Grid>

                                  {/* 3 Vehicle Class*/}
                                <Grid item xs={8}>
                                
                                    <TextField
                                        id="vehicleClass"
                                        label="Vehicle Class"
                                        value={this.props.selectedRejectedRow.vehicleClass.vehicleClassName}
                                        fullWidth
                                        disabled
                                        variant="filled"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                            
                                  {/* 4. Rejection Remarks */}
                                  <Grid item xs={12}>
                                    {/* <InputLabel>{"Rejection Remarks"}</InputLabel> */}
                                    <TextField
                                        id="rejectionRemarks"
                                        label="Rejection Remarks"
                                        value={this.props.selectedRejectedRow.violationScrutinyRemarks}
                                        fullWidth
                                        disabled
                                        variant="filled"
                                        multiline
                                        rows={1}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        {/* 
                          * Violation Proof Div. Show the fromAnprImgPath and toAnprImgPath
                          * 
                          */}
                        <Grid item xs={6}>
                            <Grid container spacing={1} className={classes.gridCont}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Rejection Proof
                                </Typography>
                            </Grid>
                            <Grid container spacing={1}>
                              
                                {/* 1. From ANPR IMG PATH */}
                                
                                <Grid item xs={6}>
                                    <div>
                                        <Typography variant="caption" gutterBottom className={classes.header}>
                                            From ANPR Path
                                        </Typography>
                                    </div>
                                    <img className={classes.image}
                                        src={fromAnprImgPath} 
                                        alt={this.props.selectedRejectedRow ? this.props.selectedRejectedRow.vehicleClass.vehicleClassName : null } 
                                    />
                                </Grid> 
                                  
                               {/* 2. To ANPR IMG PATH */}
                               {this.props.violationType != 0 && <Grid item xs={6}>
                                    <div>
                                        <Typography variant="caption" gutterBottom className={classes.header}>
                                            To ANPR Path
                                        </Typography>
                                    </div>
                                    <img className={classes.image}
                                        src={toAnprImgPath} 
                                        alt={this.props.selectedRejectedRow ? this.props.selectedRejectedRow.vehicleClass.vehicleClassName : null } 
                                    />
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
            </React.Fragment>
        );

    } else {
        return <div>Loading...</div>
    }
    };
}


export default withStyles(styles)(RejectionDetails);