import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { Box, Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import EventGeneralDtsForm from './EventGeneralDtsForm';
import EventInformationForm from './EventInformationForm';
import EventSop from './EventSop';
import EventsAlert from './EventsAlert';
import EventResolve from './EventResolve';
import EventTimeLine from './EventTimeLine';
import * as actions from '../../../store/actions/index';
import StepButton from '@material-ui/core/StepButton';
import commonStyles from '../../../commonStyles';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import deenRlogo from '../../../images/DEENRLOGO.png';
import { useHistory } from 'react-router-dom';

const styles = theme => ({
  ...commonStyles(theme),
  firstBox: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  
  buttonBack: {
    marginRight: theme.spacing(1),
    //boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    //marginRight: '10px',
    backgroundColor:"#002884",
    color:"white",
    '&:hover': {
      
      marginRight: theme.spacing(1),
      //boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#002884",
      color: "white",
    },

  },
  buttonPrint: {
    marginRight: theme.spacing(1),
    //boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundColor:"#002884",
    color:"white",
      // Comment out or remove the hover styles below
      '&:hover': {
      
        marginRight: theme.spacing(1),
        //boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        backgroundColor: "#002884",
        //border:"solid 2px black",
        color: "white",
      },
  },
});

const steps = ['General Details', 'Event Information', 'SOP', 'Alerts', 'Resolve', 'Event Timeline'];

class EventFlow extends React.Component {
  constructor() {
    super();
    this.state = {
      activeStep: 0,
    };
  }

  componentDidMount() {

    // Dropdown 1 : Populate the Notification Source = Equipment Source Dropdown
    this.props.getNotificationSource();

    // Dropdown 3: Populate the Event status
    this.props.getEventStatus();

    // Dropdown 4: Populate the Event Type
    this.props.getEventType();

    // Dropdown 6: Populate the Event Severity
    this.props.getEventSeverity();

    // checkbox 1: Populate the stakeholders
    this.props.getStakeholderUser();


    if (this.props.selectedEvent.eventData) {
      // Dropdown 2: Populate the Equipments by notification source
      if (this.props.selectedEvent.eventData.equipmentTypeId) {
        this.props.getEquipment(this.props.selectedEvent.eventData.equipmentTypeId);
      }

      // Dropdown 5: Populate the Event subtype on Event 
      if (this.props.selectedEvent.eventData.eventTypeId) {
        this.props.getEventSubType(this.props.selectedEvent.eventData.eventTypeId);
      }

      // Dropdown 7: Populate the Event reason on Event Subtype
      if (this.props.selectedEvent.eventData.eventSubtypeId) {
        this.props.getEventReason(this.props.selectedEvent.eventData.eventSubtypeId);
      }

      // Get the Event Additional Info, Accident Vehicle Info and Event Files for the Event
      if (this.props.selectedEvent.eventData.txnEventId) {
        this.props.getEventAdditionalInfo(this.props.selectedEvent.eventData.txnEventId);
        this.props.getEventAccidentVehicleInfo(this.props.selectedEvent.eventData.txnEventId);
        this.props.getEventFiles(this.props.selectedEvent.eventData.txnEventId);
      }
    }
  }

  handleNext = () => {
    if (this.state.activeStep === 0) {
      console.log(this.props.form.EventGeneralDtsForm.values)
      // console.log("componentDidMount Eventflow", this.props.selectedEvent.eventData.txnEventId)

    }
    if (this.state.activeStep === 1) {
      console.log(this.props.form.EventInformationForm.values)
    }
    let newActiveState = this.state.activeStep + 1;
    if (this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName != 'ACCIDENT' && newActiveState == 1) {
      newActiveState++;
    }
    this.setState({ activeStep: newActiveState });
  };

  handleBack = () => {
    let newActiveState = this.state.activeStep - 1;
    if (this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName != 'ACCIDENT' && newActiveState == 1) {
      newActiveState--;
    }
    this.setState({ activeStep: newActiveState });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  }

  onSubmit = formValues => {
    console.log("EventFlow", formValues);
}

handlePdf=()=>{
  const printObj= document.getElementById('page');

  setTimeout(() =>{
    html2canvas(printObj,{ onclone: document => {
      document.getElementById("btnPrint").style.visibility = "hidden";
      document.getElementById("btnBack").style.visibility = "hidden";
      document.getElementById("btnHome").style.visibility = "hidden";
      document.getElementById("stepper").style.visibility = "hidden";
     
    }
    }).then(canvas =>{
     
     const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();

      const logoX = 10;
      const logoY = 10;
      const logoWidth = 50;
      const logoHeight = 50;
    
      // Adjust the PDF width and height as per your requirements
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      //console.log("width:",pdfWidth);
      //console.log("height:",pdfHeight)
      const dynamicData=(this.props.selectedEvent.eventData.chainageName ? this.props.selectedEvent.eventData.chainageName : "Chainage Name") + " | " + (this.props.selectedEvent.eventData.eventType ? this.props.selectedEvent.eventData.eventType.eventTypeName : "Event Type Name") + " | " + (this.props.selectedEvent.eventData.eventSubtype ? this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName : "Event Sub Type Name") + " | " + (this.props.selectedEvent.eventData.eventDescription ? this.props.selectedEvent.eventData.eventDescription : "Event Description");
      const addContent= `<h3 >${dynamicData}</h3>`
      pdf.addImage(imgData, 'PNG', 0, 20, pdfWidth, pdfHeight);
      const logoData = deenRlogo; // Make sure it contains valid base64-encoded image data
    
      // Check if the logo data is a valid base64 string
      if (logoData.startsWith('data:image')) {
        pdf.addImage(logoData, 'PNG', logoX, logoY, logoWidth, logoHeight); // Adjust the position and size as needed
      }
      pdf.fromHTML(addContent, 10, 10);
      pdf.save('myPdf.pdf');
    },1000);
  })
};















hadleHome=()=>{
  this.props.history.push('./manageEvent');
}

  getStepContent = (step, selectedEvent) => {

    // console.log("this is selectedEvent", selectedEvent, selectedEvent.txnEventId);

    switch (step) {
      case 0:
        return (<EventGeneralDtsForm initialValues={selectedEvent.eventData} onSubmit={this.onSubmit} />);
      case 1:
        return (<EventInformationForm initialValues={selectedEvent.addEventInfo} onSubmit={this.onSubmit} />);
      case 2:
        return (<EventSop />);
      case 3:
        return (<EventsAlert initialValues={selectedEvent.eventData} />);
      case 4:
        return (<EventResolve initialValues={selectedEvent.eventData} />);
      case 5:
        return (<EventTimeLine />);
      default:
        return 'Unknown step';
    }
  }

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
  };

   

  render() {
    const { classes } = this.props;
    const isLastStep = this.state.activeStep === steps.length - 1;
    return (
      <div  className={classes.atmsPageOuterDiv}>
        <Card  className={classes.atmsPageOuterCard}>
          <CardHeader  title={(this.props.selectedEvent.eventData.chainageName ? this.props.selectedEvent.eventData.chainageName : "Chainage Name") + " | " +
            (this.props.selectedEvent.eventData.eventType ? this.props.selectedEvent.eventData.eventType.eventTypeName : "Event Type Name") + " | " +
            (this.props.selectedEvent.eventData.eventSubtype ? this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName : "Event Sub Type Name") + " | " +
            (this.props.selectedEvent.eventData.eventDescription ? this.props.selectedEvent.eventData.eventDescription : "Event Description")} />
          <Divider variant="middle" />
          <CardContent  id='page'>
            <Stepper id='stepper' nonLinear activeStep={this.state.activeStep} className={classes.stepper} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} disabled={label == 'Event Information' && this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName != 'ACCIDENT'}>
                  <StepButton onClick={this.handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Divider variant="middle" />

            {this.getStepContent(this.state.activeStep, this.props.selectedEvent)}
            <Box style={{ 'margin-top': '10px' }} display="flex" justifyContent="flex-end">
        {this.state.activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button size="large" color="primary" onClick={this.handleReset} className={classes.button}>Reset</Button>
          </div>
        ) : (
            <div>
              {isLastStep && ( // Conditionally render "Print" and "Home" buttons
                <>
                  <Button id='btnPrint' size='large' onClick={this.handlePdf} className={classes.buttonPrint}>Print<i class="fa fa-print"></i></Button>
                  <Button id='btnHome' size="large" onClick={this.hadleHome} className={classes.buttonBack}>
                    Home
                  </Button>
                </>
              )}
              <Button size="large" id='btnBack' disabled={this.state.activeStep === 0} onClick={this.handleBack} className={classes.buttonBack}>
                Back
              </Button>
              {this.state.activeStep !== steps.length - 1 && (
                <Button size="large" variant="contained" color="primary" onClick={this.handleNext} className={classes.buttonNext}>
                  {this.state.activeStep === steps.length - 2 ? 'View Timeline' : 'Next'}
                </Button>
              )}
            </div>
          )}
      </Box>
          </CardContent>
        </Card>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    selectedEvent: state.selectedEvent,
    form: state.form
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotificationSource: () => dispatch(actions.getNotificationSource()),
    getEventStatus: () => dispatch(actions.getEventStatus()),
    getEventType: () => dispatch(actions.getEventType()),
    getEventSeverity: () => dispatch(actions.getEventSeverity()),
    getEquipment: (categoryId) => dispatch(actions.getEquipment(categoryId)),
    getEventSubType: (eventTypeId) => dispatch(actions.getEventSubType(eventTypeId)),
    getEventReason: (eventSubtypeId) => dispatch(actions.getEventReason(eventSubtypeId)),
    getStakeholderUser: () => dispatch(actions.getStakeholderUser()),
    getEventAdditionalInfo: (eventId) => dispatch(actions.getEventAdditionalInfo(eventId)),
    getEventAccidentVehicleInfo: (eventId) => dispatch(actions.getEventAccidentVehicleInfo(eventId)),
    getEventFiles: (eventId) => dispatch(actions.getEventFiles(eventId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventFlow));