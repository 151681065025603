import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    // SopItem: [],
    ArrSopCat: [],
    // strAddSopItem: ''
    loading: null
};

const getSOPCatSuccess = (state,action) => {
    var updatedState = updateObject(state, {
        ArrSopCat: action.arrSopCat
    });
    return updatedState;
}

const sopItemStateReset = (state,action) => {
    return updateObject(state, { loading: null });
};
const sopItemCreateStart = (state, action) => {
    return updateObject(state, { loading: true});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SOP_CAT_SUCCESS:
            return getSOPCatSuccess(state, action)
     
        case actionTypes.SOP_ITEM_CREATE_START:
            return sopItemCreateStart(state, action);

        case actionTypes.SOP_ITEM_STATE_RESET:
            return sopItemStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;