import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getStakeholderCatSuccess = (arrStakeholderCat) => {
    return {
        type: actionTypes.GET_STAKEHOLDER_CAT_SUCCESS,
        arrStakeholderCat: arrStakeholderCat
    }
}

export const getShiftsSuccess = (arrShift) => {
    return {
        type: actionTypes.GET_SHIFTS_SUCCESS,
        arrShift: arrShift
    }
}

export const stakeholderUserSuccess = (stakeholderUser) => {
    return {
        type: actionTypes.STAKEHOLDER_USER_SUCCESS,
        stakeholderUser: stakeholderUser
    };
};

export const getStakeholderCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/stakeholdersCategories?reqType=all")
            .then(res => {
                console.log("ACTION for Stakeholder Category: " + res.data);
                dispatch(getStakeholderCatSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}

export const getShifts = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/shifts?reqType=all")
            .then(res => {
                console.log("ACTION for Shifts: " + res.data);
                dispatch(getShiftsSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}

export const fetchStakeholderUserData = (endpoint) => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/stakeholderUsers")
            .then(res => {
                dispatch(stakeholderUserSuccess(
                    res.data
                ));
                console.log(res.data)
            }).catch(error => {
                console.log('error occured' + error);
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}

export const stakeholderUserCreateStart = () => {
    return {
        type: actionTypes.STAKEHOLDER_USER_CREATE_START
    };
};

export const stakeholderUserCreate = (formValues) => {
    console.log("add formValues", formValues);
    return async (dispatch) => {
        try {
            dispatch(stakeholderUserCreateStart());
            const response = await axios.post(BASE_URL + "/api/stakeholderUsers", formValues);
            dispatch(showToaster('success', 'Stakeholder Users Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}

export const stakeholderUserStateReset = () => {
    return {
        type: actionTypes.STAKEHOLDER_USER_STATE_RESET
    };
};

export const stakeholderUserEdit = (formValues) => {
    console.log("Edit formValues: ", formValues);
    let finalIsNotificationEnable = 0;
    let finalIsPrimaryContact = 0;

    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }

    if (formValues.isNotificationEnable === true || formValues.isNotificationEnable === 1) {
        finalIsNotificationEnable = 1;
    }
    else {
        finalIsNotificationEnable = 0;
    }
    if (formValues.isPrimaryContact === true || formValues.isPrimaryContact === 1) {
        finalIsPrimaryContact = 1;
    }
    else {
        finalIsPrimaryContact = 0;
    }
    let values = {
        stakeholdersCategoryId: formValues.stakeholdersCategoryId,
        stakeholderUserName: formValues.stakeholderUserName,
        stakeholderUserMobileNo: formValues.stakeholderUserMobileNo,
        stakeholderUserEmailId: formValues.stakeholderUserEmailId,
        stakeholderUserAddress: formValues.stakeholderUserAddress,
        stakeholderUserDesignation: formValues.stakeholderUserDesignation,
        isNotificationEnable: finalIsNotificationEnable,
        isPrimaryContact: finalIsPrimaryContact,
        shiftId: formValues.shiftId,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(stakeholderUserCreateStart());
            const response = await axios.patch(BASE_URL + "/api/stakeholderUsers/" + formValues.stakeholderUserId, values);
            dispatch(showToaster('success', 'Stakeholder Users Successfully Edited.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message))
        }
    }
}


