import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

// random please delete me

const initialState = {
    arrDirection:[],
    arrChainage:[],
    arrEquipment:[],
    arrVehicleType:[],
    strSpeedAudit:''
};
const speedAuditStateReset = ( state, action ) => {
    return updateObject( state, { successMsgCrt: null, errorMsgCrt: null, loading: null });
};
const getAuditDirSuccess = (state, action) => {
    //console.log("RED-DIR: " + JSON.stringify(action.arrDirection))
    var updatedState = updateObject(state, {
        arrDirection: action.arrDirection
    });
    return updatedState;
};

const getAuditChainageSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrChainage: action.arrChainage
    });
    return updatedState;
};

const getAuditEquipmentSuccess = (state, action) => {
    //console.log("RED-EQUIP: " + JSON.stringify(action.arrEquipment))
    var updatedState = updateObject(state, {
        arrEquipment: action.arrEquipment
    });
    return updatedState;
};

const getAuditVehTypeSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrVehicleType: action.arrVehicleType
    });
    return updatedState;
};
const auditFailure = ( state, action ) => {
    return updateObject( state, { successMsgCrt: null, errorMsgCrt: action.errorMsgCrt, loading: false });
};
// const speedAudit = ( state, action ) => {
//     var updatedState = updateObject(state,
//         {
//             strSpeedAudit: action.strSpeedAudit
//         });
//     return updatedState;
// };
const speedAudit = (state, action) => {
    return updateObject(state, { successMsgCrt: action.successMsgCrt, errorMsgCrt: null, loading: false, modalOpen: false });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.GET_AUDIT_DIRECTION_SUCCESS:
            return getAuditDirSuccess(state, action);
        case actionTypes.GET_AUDIT_CHAINAGE_SUCCESS:
            return getAuditChainageSuccess(state, action);
        case actionTypes.GET_AUDIT_EQUIPMENT_SUCCESS:
            return getAuditEquipmentSuccess(state, action);
        case actionTypes.GET_AUDIT_VEHTYPE_SUCCESS:
            return getAuditVehTypeSuccess(state, action);
        case actionTypes.AUDIT_FAILURE:
            return auditFailure(state, action);
        case actionTypes.SPEED_AUDIT_CREATED:
            return speedAudit(state, action);
        case actionTypes.SPEED_AUDIT_STATE_RESET:
            return speedAuditStateReset(state, action);
        default:
            return state;
    }
};

export default reducer;