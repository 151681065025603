import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Box, Tabs, Paper, Tab, Card, CardContent, CardHeader, TextField, Button, Grid,InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText, } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import TabPanel from '../../components/Navigation/TabPanel';
import DataGrid from '../../components/mastersGrid/DataGrid.js';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as actions from '../../store/actions/index';
import EventAuditSearchFilters from '../../components/common/EventAudit/eventAuditSearchFilters';

import axios from 'axios';
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import { Field, reduxForm } from 'redux-form';
import * as reduxFormFunction from '../../components/common/Redux_forms_Common';

const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => ({
  ...commonStyles(theme),
  tabs: {
    backgroundColor: '#f4f6f8',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '100px',
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    marginRight: theme.spacing(2),
  },
});

class manageEvent extends Component {
  constructor() {
    super();
    this.state = {
      tabValue: 0,
      eventOpenId: 1,
      eventActiveId: null,
      eventManageId: null,
      urlParams: [],
      searchId: '',
      eventManageConfirmationOpen: false,
      txnEventId: '',
      eventTypeId: [], // Add this line
      eventTypes: [], // Add this line
      selectedEventTypes: [], 
    };
    this.typingTimeout = null; // To track typing timeout
  }

  //componentDidMount() {
    
  //  axios
  //    .get(BASE_URL + '/api/eventStatus?reqType=all')
  //    .then((res) => {
  //      if (res.data != null) {
  //        this.setState({
  //          eventOpenId: this.getEventStatusId('OPEN', res.data),
  //          eventActiveId: this.getEventStatusId('ACTIVE', res.data),
  //          eventManageId: this.getEventStatusId('MANAGE', res.data),
  //        });
  //      }
  //    })
  //    .catch((error) => {});
  //}

  componentDidMount() {
    axios
      .all([
        axios.get(BASE_URL + '/api/eventStatus?reqType=all'),
        axios.get(BASE_URL + '/api/eventTypes?reqType=all'),
      ])
      .then(
        axios.spread((eventStatusResponse, eventTypesResponse) => {
          if (eventStatusResponse.data != null) {
            const eventStatusData = eventStatusResponse.data;
            this.setState({
              eventOpenId: this.getEventStatusId('OPEN', eventStatusData),
              eventActiveId: this.getEventStatusId('ACTIVE', eventStatusData),
              eventManageId: this.getEventStatusId('MANAGE', eventStatusData),
            });
          }
  
          if (eventTypesResponse.data != null) {
            const eventTypesData = eventTypesResponse.data;
            this.setState({ eventTypes: eventTypesData });
          }
        })
      )
      .catch((error) => {
        // Handle the error as needed
      });
  }
  


 

  getEventStatusId(eventStatusName, eventStatusDetails) {
    let eventStatus = eventStatusDetails.find((eveStatus) => eveStatus.eventStatusName == eventStatusName);
    if (eventStatus != null) {
      return eventStatus.eventStatusId;
    }
  }

  handleSearchIdChange = (event) => {
    // Clear existing typing timeout to start a new one
    clearTimeout(this.typingTimeout);
     const value = event.target.value;
    // Set the input value to the state immediately
    this.setState({
      txnEventId: value,
    });

    // Set a new typing timeout
    this.typingTimeout = setTimeout(this.handleSearch, 1000);
  };

  handleSearch = (event) => {
    // Clear the typing timeout
    //event.preventDefault();
    clearTimeout(this.typingTimeout);

    const urlParams = [];
    if (this.state.txnEventId.length > 0) {
      urlParams.push({ key: 'txnEventId', value: this.state.txnEventId });
    }
    if (this.state.selectedEventTypes.length > 0) {
      urlParams.push({ key: 'eventTypeId', value: this.state.selectedEventTypes.join(',') });
    }
    this.setState({ urlParams: urlParams });
    console.log("url paramsssssssssss",urlParams)
  };

  tabOnChange = (event, value) => {
    this.setState({ tabValue: value });
  }

  //onSearchSubmit = (urlParams) => {
  //  this.setState({ urlParams: urlParams });
  //}
  handleEventTypesChange = (event) => {
    this.setState({ selectedEventTypes: event.target.value });
  };
  handleResetFilters = () => {
    this.setState({
      selectedEventTypes: [], 
      txnEventId: ''
    });
  
    
    this.handleSearch();
  };
  



  getJoinedNames = (idArray, fieldName) => {
    let result = [];
  
    if (fieldName === 'EVENT') {
      this.state.eventTypes.forEach((event) => {
        if (idArray.indexOf(event.eventTypeId) > -1) {
          result.push(event.eventTypeName);
        }
      });
    }
  
    return result.join(', ');
  };
  
  

  render() {
    const { classes } = this.props;
    let eventStatusEndpoint = 'api/events?eventStatusId=';

    if (this.state.eventOpenId) {
      return (
        <React.Fragment>
          <ConfirmationDialog
            primaryOption="Detailed"
            secondaryOption="Quick"
            open={this.state.eventManageConfirmationOpen}
            message="How do you want to manage the event?"
            onClose={(selectedOption) => {
              console.log('selected option: ', selectedOption);
              if (selectedOption) {
                if (this.state.selectedEvent.eventStatusId != this.state.eventManageId) {
                  this.props.changeEventStatus(this.state.selectedEvent.txnEventId, {
                    eventStatusId: this.state.eventManageId,
                  });
                }
                this.setState({ eventManageConfirmationOpen: false });
                this.props.history.push(selectedOption == 'Detailed' ? '/eventflow' : '/quickevent');
              } else {
                this.setState({ eventManageConfirmationOpen: false });
              }
            }}
          />
          <div className={classes.atmsPageOuterDiv}>
            <Box mt={3} className={classes.searchBox}>
              <Card>
                <CardContent>
                  <form noValidate autoComplete="off" onSubmit={this.handleSearch}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <div style={{ maxWidth: '70px' }}>
                          <Typography variant="h6" gutterBottom className={classes.sheader}>
                            Search
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl component="fieldset">
                          <TextField
                            label="Event Id"
                            name="txnEventId"
                            className={classes.sheader}
                            onChange={this.handleSearchIdChange}
                            value={this.state.txnEventId}
                            variant="outlined"
                          />
                        </FormControl>
                      
                        </Grid>
                        <Grid item xs={2}>
                        <FormControl variant="outlined" component="fieldset">
                          <InputLabel>Event Types</InputLabel>
                          <Select
                            name="eventTypes"
                            multiple
                            value={this.state.selectedEventTypes}
                            onChange={this.handleEventTypesChange}
                            input={<OutlinedInput label="Event Types" />}
                            style={{ width: '250px', marginLeft: '5px' }}
                           renderValue={(selected) => this.getJoinedNames(selected, "EVENT")}
                          >
                            {this.state.eventTypes.map((event) => (
                              <MenuItem key={event.eventTypeId} value={event.eventTypeId}>
                                <Checkbox
                                  checked={this.state.selectedEventTypes.indexOf(event.eventTypeId) !== -1}
                                />
                                <ListItemText primary={event.eventTypeName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        </Grid>
                  
                
                      <Grid item xs={4}>
                        <Button
                          className={`${classes.sheader} ${classes.addButton}`}
                          variant="contained"
                          color="primary"
                          startIcon={<SearchIcon />}
                          type="button"
                          onClick={this.handleSearch}
                        >
                          Search
                        </Button>
                        <Button
                          className={`${classes.sheader} ${classes.addButton}`}
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={this.handleResetFilters}
                          style={{marginLeft:"7px"}}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </div>
          <CardContent>
            <Tabs
              className={classes.tabs}
              value={this.state.tabValue}
              onChange={this.tabOnChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="OPEN" />
              <Tab label="ACTIVE" />
              <Tab label="MANAGE" />
              <Tab label="ALL" />
            </Tabs>
            <TabPanel value={this.state.tabValue} index={0}>
              <DataGrid
                ref={this.grid}
                endpoint={eventStatusEndpoint + this.state.eventOpenId}
                urlParams={[...this.state.urlParams, { key: '$sort[createdAt]', value: -1 }]}
                isMasterGrid={false}
                actions={[
                  {
                    icon: VisibilityIcon,
                    onClick: (event, rowData) => {
                      this.props.setSelectedEvent(rowData);
                      this.props.history.push('/approverejectevent');
                    },
                  },
                ]}
              />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              <DataGrid
                ref={this.grid}
                endpoint={eventStatusEndpoint + this.state.eventActiveId}
                urlParams={[...this.state.urlParams, { key: '$sort[createdAt]', value: -1 }]}
                isMasterGrid={false}
                actions={[
                  {
                    icon: DehazeIcon,
                    onClick: (event, rowData) => {
                      this.props.setSelectedEvent(rowData);
                      this.setState({ selectedEvent: rowData, eventManageConfirmationOpen: true });
                    },
                  },
                ]}
              />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
              <DataGrid
                ref={this.grid}
                endpoint={eventStatusEndpoint + this.state.eventManageId}
                urlParams={[...this.state.urlParams, { key: '$sort[createdAt]', value: -1 }]}
                isMasterGrid={false}
                actions={[
                  {
                    icon: DehazeIcon,
                    onClick: (event, rowData) => {
                      this.props.setSelectedEvent(rowData);
                      this.setState({ selectedEvent: rowData, eventManageConfirmationOpen: true });
                    },
                  },
                ]}
              />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={3}>
              <DataGrid
                ref={this.grid}
                endpoint="api/events"
                urlParams={[...this.state.urlParams, { key: '$sort[createdAt]', value: -1 }]}
                isMasterGrid={false}
                actions={[
                  {
                    icon: DehazeIcon,
                    onClick: (event, rowData) => {
                      this.props.setSelectedEvent(rowData);
                      rowData.eventStatusId === 1
                        ? this.props.history.push('/approverejectevent')
                        : this.props.history.push('/eventflow');
                    },
                  },
                ]}
              />
            </TabPanel>
          </CardContent>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedEvent: (eventData) => dispatch(actions.setSelectedEvent(eventData)),
    changeEventStatus: (eventId, body) => dispatch(actions.changeEventStatus(eventId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(manageEvent));
