import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    arrEquipCat: [],
   loading: null
};

const getEquipCatSuccess = (state, action) => {
    var updatedState = updateObject(state,
        {
            arrEquipCat: action.arrEquipCat
        });  
    return updatedState;
};

const equipTypeStateReset = ( state, action ) => {
    return updateObject( state, { loading: null });
};

const equipTypeCreateStart = ( state, action ) => {
    return updateObject( state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EQUIP_CAT_SUCCESS:
            return getEquipCatSuccess(state, action);
        case actionTypes.EQUIP_TYPE_STATE_RESET:
            return equipTypeStateReset(state, action);
        case actionTypes.EQUIP_TYPE_CREATE_START:
            return equipTypeCreateStart(state, action);
        default:
            return state;
    }
};

export default reducer;