import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, FormControl, Checkbox, Typography, FormControlLabel, InputLabel, label } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import axios from 'axios';
import appConfig from '../../../appConfig.json';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class MenuAddForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            menuLevel: null,
        }
    }

    //#region componentDidMount
    componentDidMount() {
        this.props.getParentMenu();                         // Get dropdown of Parent Menu      
        console.log("initialValues", this.props.initialValues);

        if (this.props.type === "Edit") {                           // As menu level is label and initially for Edit we get value from DB
            this.setState({
                menuLevel: this.props.initialValues.menuLevel       // set initial value of Menu Level to state
            });
        }
        console.log("menu level", this.state.menuLevel);

        console.log("pathname", getRoute())
    }
    //#endregion

    //#region On Change
    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    onDropdownChange = async (event) => {
        let menuId = event.target.value;
        try {
            const getMenuData = await axios.get(BASE_URL + "/api/menu?menuId=" + menuId);       // Get call for Menu level
            // console.log("getMenuData", getMenuData.data.data[0]);
            // console.log("menu level", getMenuData.data.data[0].menuLevel);

            this.setState({
                menuLevel: getMenuData.data.data[0].menuLevel + 1,
            });
        }
        catch (err) {
            console.log("Onchange issue", err);
        }
    }
    //#endregion

    //#region  Form Submit
    onSubmit = (values) => {
        values['menuLevel'] = this.state.menuLevel;     // For Menu Level label, we change it's value based on Parent Menu from dropdown.
        // As there is no onChange for Label we need to send it's current value which is stored in a state.
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    checkIsLeafNode = ({ input, ...reset }) => (
        <FormControl fullWidth variant="outlined">
            <FormControlLabel
                control={<Checkbox {...input}
                    name="menuLeafNode"
                    checked={input.value ? true : false} />}
                label="Leaf Node" />
        </FormControl>
    )

    //----changed by Rahul for solving the reset bug--------
    onReset = () => {
        this.setState({
            menuLevel: null
        })
        this.props.reset()
    }

    render() {
        const { classes, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={this.onReset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Menu";
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Menu";
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Menu"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="parentMenuId" component={reduxFormFunctions.renderSelectField} onChange={this.onDropdownChange} label="Parent Menu" id="parentMenuId">
                                        <option value={''}>{''}</option>
                                        {this.props.ArrParentMenu.map((parentMenu) => <option key={parentMenu.menuId} value={parentMenu.menuId}>{parentMenu.menuName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="menuName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Menu Name" id="menuName" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="menuDescription" id="menuDescription" component={reduxFormFunctions.renderTextField} label="Menu Description" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="menuRoute" id="menuRoute" component={reduxFormFunctions.renderTextField} label="Menu Route" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography>Menu Level: <InputLabel name="menuLevel" id="menuLevel" label="Menu Level" >{this.state.menuLevel}</InputLabel> </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="menuLeafNode" id="menuLeafNode" component={this.checkIsLeafNode} label="Leaf Node" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (!formValues.parentMenuId) {
        error.parentMenuId = "Select Parent Menu"
    }
    if (formValues.parentMenuId) {
        if (formValues.parentMenuId == "") {
            error.parentMenuId = "Select Parent Menu"
        }
    }

    if (!formValues.menuName) {
        error.menuName = "Enter Menu Name";
    }
    if (!formValues.menuLevel) {
        error.menuLevel = "Enter Menu Level";
    }
    if (formValues.menuDescription) {
        if (formValues.menuDescription.length > 201) {
            error.menuDescription = "Maximum characters should be 200 only";
        }
    }

    return error;
};

const mapStateToProps = state => {
    return {
        ArrParentMenu: state.menuReducer.ArrParentMenu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getParentMenu: () => dispatch(actions.getParentMenu())
    }
}

MenuAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuAddForm);

export default reduxForm({
    validate: validate,
    form: 'MenuAddForm'
})(withStyles(styles)(MenuAddForm));