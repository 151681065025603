import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, Button, InputLabel, Box, Typography, Modal
} from '@material-ui/core';
import { connect } from "react-redux";
import FormHelperText from '@material-ui/core/FormHelperText'
import * as actions from '../../../store/actions/index';
import Toaster from '../../../components/common/Toaster';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import commonStyles from '../../../commonStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import UpstreamCCTV from './eventEquipmentModals/UpstreamCCTV';

const styles = theme => ({
  ...commonStyles(theme),
  spinDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
});

class EventGeneralDtsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stakeholderArray :[],
      videoUrl: '',
      modalOpen : false,
      modalType: "",
      clickedEquipment: null,
    }

    
  }


  showFullScreen = (chartType) => {
    this.setState({
      modalType: chartType,
      modalOpen: true
    })
  }

  // onEquipmentMarkerClick = (props, marker, e, equipment) => {
  //   console.log("onEquipmentMarkerClick props", props);
  //   console.log("onEquipmentMarkerClick e", e);
  //   console.log("onEquipmentMarkerClick marker", marker);
  //   console.log("onEquipmentMarkerClick equipment", equipment);
  //   console.log("onEquipmentMarkerClick call");
  //   // Get the categoryname
  //   let categoryName = this.getEquipmentCategoryNameById(equipment);

  //   this.setState({
  //       clickedEquipment: equipment,
  //       clickedModalOpen: true,
  //       clickedCategoryName: categoryName,
  //       showHoverWindow: false
  //   });
  //   console.log("STATE", this.state)
  // }

  onEquipmentModalClose = () => {
    this.setState({
      modalType: "",
      modalOpen: false
    })
}
  componentDidMount(){
    if(this.props.form.EventGeneralDtsForm.values.stakeHolder !== null){
    this.setState({
        stakeholderArray : this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',')
      })
    }   
    
  }
  
  renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
    <React.Fragment>
      <InputLabel>{label}</InputLabel>
      <TextField
        variant="outlined"
        fullWidth
        // label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </React.Fragment>
  )

  renderDateField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
    // console.log("input and custom in datefield", input, custom);
    // Changing the data value
    input.value = new Date(input.value).toISOString().slice(0, 16);
    // console.log(input.value)
    return (
      <FormControl fullWidth={true}>
        <TextField
          variant="outlined"
          label={label}
          type="datetime-local"
          defaultValue="2017-05-24T10:30"
          error={touched && invalid}
          helperText={touched && error}
          // className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          {...input}
          {...custom}
        />
      </FormControl>
    )
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
    return (
      <FormControl fullWidth variant="outlined" error={touched && error} helperText={touched && error}>
        <InputLabel>{label}</InputLabel>
        <Select
          error={touched && error}
          native
          {...input}
          {...custom}
          
          inputProps={{
            name: input.name,
            id: 'color-native-simple',
            autofocus: true
          }}
        >
          {children}

        </Select>
        {this.renderFromHelper({ touched, error })}
      </FormControl>

    )
  }


  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }

  renderCheckbox = ({ input, label }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              // (this.state.stakeholderArray.indexOf(input.name) !== -1 ? true : false)
              (this.props.form.EventGeneralDtsForm.values.stakeHolder && this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',').indexOf(input.name) !== -1) ? true : false
            }
            onChange={(evt) => {
              input.onChange(evt);
              if (evt.target.checked) {
                // let stakeholderArray = [];
                if (this.props.form.EventGeneralDtsForm.values.stakeholder != null) {
                  this.setState({
                    stakeholderArray : this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',')
                  })
                  // this.state.stakeholderArray = this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',');
                  console.log('STAKEHOLDERS', this.state.stakeholderArray)
                }
                this.state.stakeholderArray.push(input.name);
                console.log('STAKEHOLDERS', this.state.stakeholderArray)
                this.props.change('stakeHolder', this.state.stakeholderArray.join());
              }
              if(!evt.target.checked){
                console.log('unchecked',input.name );
                if(this.state.stakeholderArray.indexOf(input.name) !== -1){
                  this.state.stakeholderArray.splice (this.state.stakeholderArray.indexOf(input.name), 2)
                  console.log('STAKEHOLDERS', this.state.stakeholderArray)
                  this.props.change('stakeHolder', this.state.stakeholderArray.join())
                }
              }

            }}
          />
        }
        label={label}
      />
    </div>
  )

  notificationSrcOnChange = (event, newValue, previousValue, name) => {
    this.props.getEquipment(newValue);
  };

  eventTypeOnChange = (event, newValue, previousValue, name) => {
    this.props.getEventSubType(newValue);
  };

  eventSubtypeOnChange = (event, newValue, previousValue, name) => {
    this.props.getEventReason(newValue);
  }

  // OnBubmit in Event General Details
  onSubmit = (values) => {
    console.log("OnSubmit inside Event General Details", values);
    // // Below field should be editable when the Event General Details
    // Severity
    // Estimated Duration
    // Event Description
    // Event Reason

    // console.log("values Severity ID", values.eventSeverityId);
    // console.log("values Estimated Duration ", values.estimatedDuration);
    // console.log("values Event Description ", values.eventDescription);
    // console.log("values Event Reason ID", values.eventReasonId);
    console.log(this.state.stakeholderArray.join(), 'stake holder')
    var genDtsVals = {
      txnEventId: this.props.selectedEvent.eventData.txnEventId,
      eventSeverityId: values.eventSeverityId,
      estimatedDuration: values.estimatedDuration,
      eventDescription: values.eventDescription,
      eventReasonId: values.eventReasonId==''?null:values.eventReasonId,
      stakeHolder: this.state.stakeholderArray.join()
    }
    console.log("genDtsVals", genDtsVals);
    this.props.editEventGeneralDts(genDtsVals);
    // this.props.(editGenDts);
  }

  // loaderState = () =>{
  //   setTimeout(this.setState({
  //     loader: false,
  //   }), 5000);
  // }

  render() {

    const { classes } = this.props;
    console.log("EVENT GENERAL DTS FORM RENDER CALLED", this.state.stakeholderArray)
    // if(this.state.stakeholderArray.indexOf(this.props.form.EventGeneralDtsForm.values.stakeHolder) == -1){
    //   this.state.stakeholderArray.push(this.props.form.EventGeneralDtsForm.values.stakeHolder)
    // }
    console.log('******', this.props.eventGeneralDts.loading)

    console.log("this.props", this.props.equipments[0])
    
    if (this.props.eventGeneralDts.loading) {
      return (
        <div className={classes.spinDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      );
    }else{
    return (
      <React.Fragment>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <form autoComplete="off" name="eventGeneralForm" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <fieldset disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName!= 'MANAGE'}>
            <Typography variant="h5" className="sectionHeading">Source</Typography>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                
                  <Field name="equipmentTypeId" component={reduxFormFunctions.renderSelectField} label="Notification Source" onChange={this.notificationSrcOnChange} disabled>
                  <option value="" />
                  {this.props.notificationSource.map((notificationSource) => {
                    return (<option value={notificationSource.equipmentTypeId}>{notificationSource.equipmentTypeName}</option>);
                  })}
                </Field>
        
              </Grid>
              <Grid item md={3} xs={12}>
               
                  <Field name="equipmentId" component={reduxFormFunctions.renderSelectField} label="Equipment" disabled>
                  <option value="" />
                  {this.props.equipments.map((equipment) => {
                    return (<option value={equipment.equipmentId}>{equipment.equipmentName}</option>);
                  })}
                </Field> 
              </Grid>

              <Grid item md={3} xs={12}>
                <Field name="detectionTime" component={this.renderDateField} label="Detection Time" disabled />
              </Grid>
              <Grid item md={3} xs={12}>
              
                  <Field name="eventStatusId" component={reduxFormFunctions.renderSelectField} label="Status" disabled>
                  <option value="" />
                  {this.props.eventStatus.map((eventStatus) => {
                    return (<option value={eventStatus.eventStatusId}>{eventStatus.eventStatusName}</option>);
                  })}
                </Field>
              </Grid>
            </Grid>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5">Event</Typography>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                
                  <Field name="eventTypeId" component={reduxFormFunctions.renderSelectField} label="Event Type" onChange={this.eventTypeOnChange} disabled>
                  <option value="" />
                  {this.props.eventType.map((eventType) => {
                    return (<option value={eventType.eventTypeId}>{eventType.eventTypeName}</option>);
                  })}
                </Field>
              </Grid>
              <Grid item md={3} xs={12}>
               
                  <Field name="eventSubtypeId" component={reduxFormFunctions.renderSelectField} label="Event Sub Type" onChange={this.eventSubtypeOnChange} disabled>
                  <option value="" />
                  {this.props.eventSubType.map((eventSubType) => {
                    return (<option value={eventSubType.eventSubtypeId}>{eventSubType.eventSubtypeName}</option>);
                  })}
                </Field> 
              </Grid>
              <Grid item md={3} xs={12}>
                
                  <Field name="eventSeverityId" id="eventSeverityId" component={reduxFormFunctions.renderSelectField} label="Severity">
                  <option value="" />
                  {this.props.eventSeverity.map((eventSeverity) => {
                    return (<option value={eventSeverity.eventSeverityId}>{eventSeverity.severityName}</option>);
                  })}
                </Field>
              </Grid>
              <Grid item md={3} xs={12}>

                <Field name="estimatedDuration" parse={(val) => typeof val == 'String'? parseInt(val, 10): val} type="number" component={reduxFormFunctions.renderTextField} label="Estimated Duration (minutes)" />
              </Grid>
              <Grid item md={3} xs={12}>
                <Field name="eventDescription" component={reduxFormFunctions.renderTextField} label="Event Description" />
              </Grid>
              <Grid item md={3} xs={12}>
                <Field name="eventReasonId" component={reduxFormFunctions.renderSelectField} label="Event Reason">
                  <option value="" />
                  {this.props.eventReason.map((eventReason) => {
                    return (<option value={eventReason.eventReasonId}>{eventReason.eventReasonName}</option>);
                  })}
                </Field>
              </Grid>
            </Grid>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5">Location</Typography>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <Field name="chainageName" component={reduxFormFunctions.renderTextField} label="Chainage Name" disabled />
              </Grid>
              <Grid item md={3} xs={12} >
                <Field name="directionName" component={reduxFormFunctions.renderTextField} label="Direction Name" disabled />
              </Grid>
              <Grid item md={3} xs={12} >
                <Field name="upstreamLocation" component={reduxFormFunctions.renderTextField} label="Upstream Location" disabled />
              </Grid>
              <Grid item md={3} xs={12} >
                <Field name="downstreamLocation" component={reduxFormFunctions.renderTextField} label="Downstream Location" disabled />
              </Grid>
              <Grid item md={3} xs={12} >
                <Field name="eventLatitude" component={reduxFormFunctions.renderTextField} label="Latitude" disabled />
              </Grid>
              <Grid item md={3} xs={12}>
                <Field name="eventLongitude" component={reduxFormFunctions.renderTextField} label="Longitude" disabled />
              </Grid>
            </Grid>

            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5">Stake Holders</Typography>
            <FormGroup row>
              <Grid container spacing={8}>
                {this.props.stakeholderUsers && this.props.stakeholderUsers.map((stakeholderUser) => {
                  return (
                    <Grid item md={2} xs={12}>
                      <Field name={'' + stakeholderUser.stakeholdersCategoryId} component={this.renderCheckbox} label={stakeholderUser.stakeholdersCategoryName} />
                    </Grid>
                  )
                })}
              </Grid>
            </FormGroup>

            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5">Feeds</Typography>
            <Grid container spacing={1}>
              <Grid item md={3} xs={12}>
                <Button variant="contained" size="large" color="primary" onClick={() => this.setState({modalOpen:true})}>
                  UPSTREAM CCTV
                <Modal open={this.state.modalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <UpstreamCCTV selectedEquipment={this.props.selectedEvent.eventData.chainageEquipment} onClose={this.onEquipmentModalClose}></UpstreamCCTV>
                </Modal>
                                        </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button variant="contained" size="large" color="primary">
                  UPSTREAM PTZ
                                        </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button variant="contained" size="large" color="primary">
                  DOWNSTREAM CCTV
                                        </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button variant="contained" size="large" color="primary">
                  DOWNSTREAM PTZ
                </Button>
              </Grid>
            </Grid>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Button variant="contained" className={classes.atmsSaveButton} disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName!= 'MANAGE'} size="large" type="submit">
              SAVE
            </Button>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
          </fieldset>
        </form >
      </React.Fragment >
    );}
  }

}

const validate = (formValues, state) => {
  const errors = {};   
  console.log('stakeHolder', state)
  if(!formValues.stakeHolder){
    errors.stakeHolder = "Stakeholders are required"
  }
  return errors;
};

const mapStateToProps = state => {
  return {
    notificationSource: state.eventGeneralDts.notificationSource,
    eventStatus: state.eventGeneralDts.eventStatus,
    eventType: state.eventGeneralDts.eventType,
    eventSeverity: state.eventGeneralDts.eventSeverity,
    equipments: state.eventGeneralDts.equipments,
    eventSubType: state.eventGeneralDts.eventSubType,
    eventReason: state.eventGeneralDts.eventReason,
    stakeholderUsers: state.eventGeneralDts.stakeholderUsers,
    form: state.form,
    selectedEvent: state.selectedEvent,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig,
    eventGeneralDts: state.eventGeneralDts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEquipment: (categoryId) => dispatch(actions.getEquipment(categoryId)),
    getEventSubType: (eventTypeId) => dispatch(actions.getEventSubType(eventTypeId)),
    getEventReason: (eventSubtypeId) => dispatch(actions.getEventReason(eventSubtypeId)),
    editEventGeneralDts: (genDtsVals) => dispatch(actions.editEventGeneralDts(genDtsVals)),
  };
};

EventGeneralDtsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventGeneralDtsForm);

export default reduxForm({
  form: 'EventGeneralDtsForm',
  validate: validate,
})(withStyles(styles)(EventGeneralDtsForm));
