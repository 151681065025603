import React from 'react';
import LocationCatForm from './locationCatForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class LocationCatEdit extends React.Component {

    componentDidMount() {
        this.props.locationCatStateReset();
    }

    onSubmit = formValues => {
        this.props.locationCatEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        console.log("InitialValues", this.props.initialValues);

        return (
            <React.Fragment>
                { progress}
                <LocationCatForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.locationCatReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        locationCatEdit: (formValues) => dispatch(actions.locationCatEdit(formValues)),
        locationCatStateReset: () => dispatch(actions.locationCatStateReset()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationCatEdit));