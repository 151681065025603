import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    ArrEventType: [],
    ArrEventSeverity: [],
    loading: null
};

const getEventTypesForEventSubtypeSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrEventType: action.arrEventType
    });
    return updatedState;
}
const getEventSeverityForEventSubtypeSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrEventSeverity: action.arrEventSeverity
    });
    return updatedState;
}

const eventSubtypeStateReset = (state,action) => {
    return updateObject(state, { loading: null });
};
const eventSubtypeCreateStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVENT_TYPES_FOR_EVENT_SUBTYPE_SUCCESS:
            return getEventTypesForEventSubtypeSuccess(state, action)

        case actionTypes.GET_EVENT_SEVERITY_FOR_EVENT_SUBTYPE_SUCCESS:
            return getEventSeverityForEventSubtypeSuccess(state, action)

        case actionTypes.EVENT_TYPE_CREATE_START:
            return eventSubtypeCreateStart(state, action);

        case actionTypes.EVENT_SUBTYPE_STATE_RESET:
            return eventSubtypeStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;