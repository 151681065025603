import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class ChainageAddForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            directionId: 0,
            directionName: ''
        }
    }


    //#region componentDidMount
    componentDidMount() {
        this.props.getDirection();        
        console.log("direction info", this.props.ArrDirection);
        console.log("initial props", this.props.initialValues);
        console.log("pathname",getRoute())
    }
    //#endregion

    //#region On Change
    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    //#endregion

    onDropdownChange = (event, newValue, previousValue, name) => {

        let directionData = this.props.ArrDirection[newValue - 1];

        console.log("Directiion name", directionData);

        if (directionData && name === "directionId") {
            this.setState({
                directionName: directionData.directionName,
                directionId: directionData.directionId
            })
        }
    }

    //#region  Form Submit
    onSubmit = (values) => {

        if (this.props.initialValues && this.props.initialValues.direction.directionName && (this.state.directionName === '')) {
            values["directionName"] = this.props.initialValues.direction.directionName;
        }
        else if (this.state.directionName) {
            values["directionName"] = this.state.directionName;
        }

        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Chainage"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Chainage"
        }
       
        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Chainage"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionId" component={reduxFormFunctions.renderSelectField} onChange={this.onDropdownChange} label="Select Direction" id="directionId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrDirection.map((directionDropDown) => <option key={directionDropDown.directionId} value={directionDropDown.directionId}>{directionDropDown.directionName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageName" id="chainageName" component={reduxFormFunctions.renderTextField} label="Chainage Name" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageAlias" id="chainageAlias" component={reduxFormFunctions.renderTextField} label="Chainage Alias" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageDescription" id="chainageDescription" component={reduxFormFunctions.renderTextField} label="Chainage Description" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageLatitude" id="chainageLatitude" component={reduxFormFunctions.renderTextField} label="Chainage Latitude" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageLongitude" id="chainageLongitude" component={reduxFormFunctions.renderTextField} label="Chainage Longitude" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageMilestone" id="chainageMilestone" component={reduxFormFunctions.renderTextField} label="Chainage Milestone" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const regExpNumber = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;
    const lat_lng_Regex = /^[+-]?\d{1,18}(\.\d+)?$/;

    if (!formValues.directionId) {
        error.directionId = "Select Direction";
    }

    if (formValues.directionId) {
        if (formValues.directionId == "") {
            error.directionId = "Select Direction";
        }
    }

    if (!formValues.chainageName) {
        error.chainageName = "Enter Chainage Name";
    }

    if (!formValues.chainageDescription) {
        error.chainageDescription = "Enter Chainage Description";
    }

    if (formValues.chainageDescription) {
        if (formValues.chainageDescription.length > 201) {
            error.chainageDescription = "Maximum characters should be 200 only";
        }
    }
    if (!formValues.chainageAlias) {
        error.chainageAlias = "Enter Chainage Alias";
    }
    if (!formValues.chainageLatitude) {
        error.chainageLatitude = "Enter Chainage Latitude";
    }

    if (formValues.chainageLatitude) {
        if (!lat_lng_Regex.test(formValues.chainageLatitude)) {
            error.chainageLatitude = "Please enter a valid value";
        }
    }

    if (formValues.chainageLatitude) {
        if ( (formValues.chainageLatitude > 90) || (formValues.chainageLatitude < -90)) {
            error.chainageLatitude = "Valid range of Latitude is -90 to 90";
        }
    }

    if (!formValues.chainageLongitude) {
        error.chainageLongitude = "Enter Chainage Longitude";
    }

    if (formValues.chainageLongitude) {
        if (!lat_lng_Regex.test(formValues.chainageLongitude)) {
            error.chainageLongitude = "Please enter a valid value";
        }
    }

    if (formValues.chainageLongitude) {
        if ((formValues.chainageLongitude > 180) || (formValues.chainageLongitude < -180)) {
            error.chainageLongitude = "Valid range of Longitude is -180 to 180";
        }
    }

    if (!formValues.chainageMilestone) {
        error.chainageMilestone = "Enter Chainage Milestone";
    }

    if (formValues.chainageMilestone) {
        if (!regExpNumber.test(formValues.chainageMilestone)) {
            error.chainageMilestone = "Enter only numbers";
        }
    }

    return error;
};

const mapStateToProps = state => {
    return {
        ArrDirection: state.chainageReducer.ArrDirection
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        getDirection: () => dispatch(actions.getDirection())        
    };
};

ChainageAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChainageAddForm)


export default reduxForm({
    validate: validate,
    form: 'ChainageAddForm'
})(withStyles(styles)(ChainageAddForm))