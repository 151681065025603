import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, Divider, CardContent, Grid, Typography, Paper, Button, TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, InputLabel, Input, ListItem, Radio, RadioGroup, Menu, MenuItem,
  AccordionDetails, Accordion, AccordionSummary
} from '@material-ui/core';
import { connect } from "react-redux";
import DataGrid from '../../components/mastersGrid/DataGrid';
import * as actions from '../../store/actions/index';
import axios from 'axios';
import _ from 'lodash';
import { autofill } from 'redux-form';
import MaterialTable from "material-table";
import appConfig from '../../appConfig.json';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportFilters from './ReportFilters';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  outerDiv: {
    'margin-top': theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
  },
  outerCard: {
    'min-height': '90vh'
  },
  collapsiblePanel: {
    backgroundColor: '#f5f6f7'
  },
  heading: {
    backgroundColor: '#c4dbe2',
    marginTop: '5px'
  },
  accordianDetails: {
    marginTop: '10px'
  },
  reportSection: {
    marginTop: '10px'
  },
  downloadButton: {
    marginRight: '10px'
  },
  access: {
    marginTop: '50px',
    display:"flex",
    justifyContent:"center"
  },
 
  legendTable: {
    borderCollapse: "collapse",
    "& td": {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
      fontWeight: "bold"
    },

  },
});

class ReportContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      reportCategories: [],
      reports: [],
      reportFilters: [],
      reqBody: {},
      anchorEl: null,
      anchorEl2: null,
      checkReport: true
    };
  }

  

 componentDidMount() {
    axios
      .get(BASE_URL + "/api/reportCategories?reqType=all")
      .then(res => {
        if (res.data != null) {
          this.setState({
            reportCategories: res.data
            // eventManageId: this.getEventStatusId('MANAGE', res.data)
          })
        }
      }).catch(error => {
      });
  }

  // componentDidUpdate() {
  //   if(this.state.selectedReportId) {
  //     console.log("%%%%%%%%%%%%%%%%%%%")
  //     axios
  //     .post(BASE_URL + "/api/reports/" + this.state.selectedReportId)
  //     .then(res => {
  //       if (res.data != null) {
  //         this.setState({
  //           checkReport: false
  //           // eventManageId: this.getEventStatusId('MANAGE', res.data)
  //         })
        
  //       }
       
  //     }).catch(error => {
  //     });
  //   }
    
  // }

  changeReportCategory = async (event) => {
    let selectedReportCategory = event.target.value;
    let res = await axios
      .get(BASE_URL + "/api/reportDetails?reqType=all&reportCategoryId=" + selectedReportCategory);
    if (res.data != null) {
      this.setState({
        reports: res.data, selectedReportCategory: selectedReportCategory
      })
    }
  }

  getFilters = async (urlParams, selectedReportId, selectedReport) => {
    let reportFilterParams = "";
    if (selectedReportId == null) {
      selectedReportId = this.state.selectedReportId;
    }
    if (urlParams != null && urlParams.length > 0) {
      urlParams.forEach((urlParamObj) => {
        reportFilterParams = reportFilterParams + '&' + urlParamObj['key'] + "=" + urlParamObj['value'];
      });
    }
    let res = await axios
      .get(BASE_URL + "/api/reportFilters?reqType=all&reportDetailsId=" + selectedReportId + reportFilterParams);
    if (res.data != null) {
      let stateObjToUpdate = { reportFilters: res.data };
      if (selectedReportId) {
        stateObjToUpdate['selectedReportId'] = selectedReportId;
      }
      if (selectedReport) {
        stateObjToUpdate['selectedReport'] = selectedReport;
      }
      this.setState(stateObjToUpdate);
    }
  }

  //selectReport = async (event) => {
  //  let selectedReportId = event.target.value;
  //  let res = await axios
  //   .post(BASE_URL + "/api/reports/" + selectedReportId);
  //   console.log("%%%%%%%%%%%%%%%%%%%%%%%",res.data)
  //  if (res.data.data == null) {
  //    this.setState({
  //      checkReport: false,
       
  //    })
  //  }else {
  //    let selectedReport = this.state.reports.find((report) => report.reportDetailsId == selectedReportId);
  //    console.log("#################selected report", selectedReport)
  //    await this.getFilters([], selectedReportId, selectedReport);
  //    this.setState({
  //      checkReport: true
  //    })
  //  }  
  //}
  selectReport = async (event) => {
    let selectedReportId = event.target.value;
    let res = await axios.post(BASE_URL + "/api/reports/" + selectedReportId);
    console.log("%%%%%%%%%%%%%%%%%%%%%%%", res.data);
    if (res.data.data == null) {
      this.setState({
        checkReport: false,
      });
    } else {
      let selectedReport = this.state.reports.find(
        (report) => report.reportDetailsId === selectedReportId
      );
      console.log("#################selected report", selectedReport);
      await this.getFilters([], selectedReportId, selectedReport);
      this.setState({
        checkReport: true,
        selectedReport: selectedReport, // Update selectedReport state
      });
    }
  }
  

  openDownloadXLSMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeDownloadXLSMenu = () => {
    this.setState({ anchorEl: null });
  };

  openDownloadPDFMenu = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  closeDownloadPDFMenu = () => {
    this.setState({ anchorEl2: null });
  };

  downloadReport = async (reportFormat, reportData) => {
    let url = BASE_URL + '/api/reports/' + this.state.selectedReportId;
    let reqBody = { __reqType: 'report', ...this.state.reqBody, __reportFormat: reportFormat };
    if (reportData == 'all') {
      delete reqBody.PAGINATION_OFFSET;
      delete reqBody.PAGINATION_NEXT;
    }
    let response = await axios
      .post(url, reqBody, {
        responseType: 'arraybuffer'
      });
    const objUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = objUrl;
    if (reportFormat == 'xls') {
      link.setAttribute('download', this.state.selectedReport ? this.state.selectedReport.reportName + '.xlsx' : 'report.xlsx');

    } else if (reportFormat == 'pdf') {
      link.setAttribute('download', this.state.selectedReport ? this.state.selectedReport.reportName + '.pdf' : 'report.pdf');
    }
    document.body.appendChild(link);
    link.click();
    if (reportFormat == 'xls') {
      this.closeDownloadXLSMenu();
    }
    else {
      this.closeDownloadPDFMenu();
    }
  }
  render() {
    const { classes } = this.props;

    console.log("this.state.selectedReport",this.state.selectedReport);
    console.log("this.state.checkReport",this.state.checkReport);
    //console.log("globalConfig:", metAggFrequency);
    //console.log("global config metttttttttttttt",this.props.metAggFrequency);

    return (
      <React.Fragment >
        <div className={classes.outerDiv}>
          <Card className={classes.outerCard}>
            <CardHeader title="Select Report" />
            <Divider />
            <CardContent>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel >Report Category</InputLabel>
                    <Select
                      label="Report Category"
                      value={this.state.selectedReportCategory}
                      onChange={this.changeReportCategory}
                    >
                      {this.state.reportCategories.map((reportCategory) => {
                        return (
                          <MenuItem value={reportCategory.reportCategoryId}>{reportCategory.reportCategoryName}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel>Report</InputLabel>
                    <Select
                      label="Report"
                      value={this.state.selectedReport}
                      onChange={this.selectReport}
                      variant="outlined"
                      fullWidth={true}
                    >
                      {this.state.reports.map((report) => {
                        return (
                          <MenuItem value={report.reportDetailsId}>{report.reportName}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {this.state.checkReport?
                this.state.selectedReport && <Accordion className={classes.collapsiblePanel}>
                    <AccordionSummary className={classes.heading}
                      expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.column}>
                        <Typography>Report Filters</Typography>
                      </div>
                    </AccordionSummary><AccordionDetails className={classes.accordianDetails}>
                        <ReportFilters filters={this.state.reportFilters} applyFilter={(reqBody) => {
                          this.setState({ reqBody: reqBody });
                        } }
                          refreshFilters={this.getFilters}
                          selectedReportId={this.state.selectedReportId} />
                    </AccordionDetails>
              
                
              </Accordion>
              
              :""}
              {this.state.selectedReportId === 6 ? (
                <div style={{ color: 'red',fontWeight:'bold' }}>
                  
                  {this.state.checkReport &&  (
                    <p>The Data shown in {this.props.metAggFrequency} minutes time interval.</p>
                  ) }
                </div>
              ) : null}
                {this.state.selectedReportId === 17 ? (
                <div style={{ color: 'red',fontWeight:'bold' }}>
                  
                  {this.state.checkReport && (
                    <p>The Data shown in {this.props.atccAggFrequency} minutes time interval.</p>
                  )}
                </div>
              ) : null}
             
              <div className={classes.reportGrid}>
                {this.state.checkReport ?

                  this.state.selectedReport &&
                    <div>
                      <DataGrid
                        endpoint={"api/reports/" + this.state.selectedReportId}
                        method="post"
                        reqBody={this.state.reqBody}
                        isReportGrid={true}
                        isMasterGrid={false}
                      />
           
                      <div className={classes.reportSection}>
                        <Button className={classes.downloadButton} onClick={this.openDownloadXLSMenu} color="primary" variant="contained">Export To Excel</Button>
                        <Menu
                          id="download-menu-xls"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.closeDownloadXLSMenu}
                        >
                          <MenuItem onClick={() => this.downloadReport('xls', 'currentPage')}>Current Page</MenuItem>
                          <MenuItem onClick={() => this.downloadReport('xls', 'all')}>All</MenuItem>
                        </Menu>
                        <Button className={classes.downloadButton} onClick={this.openDownloadPDFMenu} color="primary" variant="contained">Export To PDF</Button>
                        <Menu
                          id="download-menu-pdf"
                          anchorEl={this.state.anchorEl2}
                          keepMounted
                          open={Boolean(this.state.anchorEl2)}
                          onClose={this.closeDownloadPDFMenu}
                        >
                          <MenuItem onClick={() => this.downloadReport('pdf', 'currentPage')}>Current Page</MenuItem>
                          <MenuItem onClick={() => this.downloadReport('pdf', 'all')}>All</MenuItem>
                        </Menu>
                      </div>
                    </div>
                   :
                      <Typography className={classes.access} variant="h1" style={{  align:"center" }}> 
                          <p>
                            You are not authorized to access this Report !
                            <br/>
                            <Typography className={classes.access} variant="h1" style={{  align:"center" }}> 
                              Please contact your Supervisor.
                            </Typography>

                          </p>
                           
                      </Typography>
                    
                  }
                
              </div>
            </CardContent>
          </Card>
        </div>
      </React.Fragment >
    );
  }
}


const mapStateToProps = state => {
  return {
    // selectedEvent: state.selectedEvent,
      metAggFrequency: state.common.globalConfig.met_aggregation_frequency.configValue1,
      atccAggFrequency: state.common.globalConfig.atcc_aggregation_frequency.configValue1
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // changeEventStatus: (eventId, body) => dispatch(actions.changeEventStatus(eventId, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportContainer));