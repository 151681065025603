import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getChainageSuccess = (arrChainage) => {
    return {
        type: actionTypes.GET_CHAINAGE_SUCCESS,
        arrChainage: arrChainage
    };
}

export const getDirectionForSectionSuccess = (arrDirection) => {
    return {
        type: actionTypes.GET_DIRECTION_FOR_SECTION_SUCCESS,
        arrDirection: arrDirection
    };
}

export const getChainage = (dirId) => {
    console.log("Direction Id"+dirId);
    return dispatch => {
        axios
            .get(BASE_URL + "/api/chainage?reqType=all&directionId="+dirId)
            .then(res => {
                console.log("ACTION : " + res.data);
                dispatch(getChainageSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    }
}

export const getDirectionForSection = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/direction?reqType=all")
            .then(res => {
                console.log("ACTION : " + res.data);
                dispatch(getDirectionForSectionSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    }
}

export const sectionCreateStart = () => {
    return {
        type: actionTypes.SECTION_CREATE_START
    };
};

export const sectionCreate = (formValues) => {

    return async (dispatch) => {
        try {
            dispatch(sectionCreateStart());
            const response = await axios.post(BASE_URL + "/api/sections", formValues);
            console.log("Created")
            dispatch(showToaster('success','Section Successfully Created.'))
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const sectionStateReset = () => {
    return {
        type: actionTypes.SECTION_STATE_RESET
    };
};

export const sectionEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        sectionName: formValues.sectionName,
        fromChainageId: formValues.fromChainageId,
        fromChainageName: formValues.fromChainageName,
        toChainageId: formValues.toChainageId,
        toChainageName: formValues.toChainageName,
        directionId: formValues.directionId,
        directionName: formValues.directionName,
        sectionDistance: formValues.sectionDistance,
        sectionCrossingThresholdTime: formValues.sectionCrossingThresholdTime,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(sectionCreateStart());
            const response = await axios.patch(BASE_URL + "/api/sections/" + formValues.sectionId, values);
            console.log(response);
            dispatch(showToaster('success','Section Successfully Edited.'));
        } catch (error) {
            console.log(error)
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}
