import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton, Box, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DataGrid from '../../../components/mastersGrid/DataGrid';
import VIDSVideoPlayer from './VIDSVideoPlayer';
import axios from 'axios';
import appConfig from '../../../appConfig.json';
import VisibilityIcon from '@material-ui/icons/Visibility';
const BASE_URL = appConfig.BASE_URL;


const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    modal: {
        'overflow-y': 'auto',
    },
    marquee: {
        color:'red',
        fontWeight: 'bold'
      }
});


class VIDSDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            audioModalOpen: false,
            audioSrc : null,
            selectedRow : null
        };
    }

    closeModal = () => {
        this.setState({audioModalOpen: false});
    }

    render() {
        const { classes, selectedEquipment } = this.props;
        console.log('props',this.props)
        const headerTitle = selectedEquipment.equipmentName + ' | ' + this.props.selectedEquipment.chainageName + ' | ' + this.props.selectedEquipment.directionName;
        let alert;
        if(selectedEquipment.isFaulty === 1){
        alert =   
                <div className={classes.marquee}>
                    These equipment is under Maintaince
                </div> 
        }

        let modalVidsDiv = <Modal open={this.state.audioModalOpen} 
                                    className={classes.modal} 
                                    onClose={() => { this.closeModal() }}
                                    closeAfterTransition>
                                        <VIDSVideoPlayer onClose={() => { this.closeModal() }} audioSrc={this.state.audioSrc} 
                                                        selectedEquipment={ this.state.selectedRow } />
                            </Modal>

        return (
            <React.Fragment>
                <Container maxWidth="md" className={classes.container}>
                    <Card>
                        <CardHeader
                            title={headerTitle}
                            subheader = {alert}
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>

                            <Box mt={3}>
                                {this.props.selectedEquipment.equipmentId != null &&
                                    <DataGrid ref={this.grid}
                                        endpoint="api/eventsByVids"
                                        // endpoint="api/ecbCallRaws"
                                        limit="5"
                                        skip="0"
                                        urlParams={[{
                                            key: "equipmentId", value: this.props.selectedEquipment.equipmentId,
                                            // key:"$sort[updatedAt]", value: "-1", 
                                         },
                                         { 
                                            key:"$sort[updatedAt]", value: "-1",
                                        } 
                                        ]}
                                        paging={false}
                                        actions={[
                                            {
                                                icon: VisibilityIcon,
                                                onClick: async (event, rowData) => {
                                                    console.log("ROWDATA FROM ", rowData);
                                                    // try {
                                                    //     const res = await axios.get(BASE_URL + "/api/uploads?file=uploads/" + rowData.fullAudioFilePath ,
                                                    //                                     {responseType: 'blob'});
                                                    //     this.setState({ audioSrc: URL.createObjectURL(res.data) });
                                                    // } catch (error) {
                                                    //     console.log(error.response);
                                                    // }
                                                    this.setState({ audioSrc: rowData });
                                                    this.setState({ audioModalOpen: true })
                                                    this.setState({ selectedRow:  rowData });
                                                }
                                            }
                                        ]}
                                    />
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
                {modalVidsDiv}
            </React.Fragment>
        );

    }
}

export default connect(null, null)(withStyles(styles)(VIDSDetails));
