import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

export const getEquipmentsSuccess = (equipments) => {
    return {
        type : actionTypes.GET_EQUIPMENTS_ON_MAPS,
        equipments : equipments,
    };
};

export const getEquipmentsCategoriesSuccess = (equipmentCategories) => {
    return {
        type : actionTypes.GET_EQUIPMENT_CATEGORIES,
        equipmentCategories : equipmentCategories,
    };
};

export const getEventsOnMapSuccess = (eventsOnMap) => {
    return {
        type : actionTypes.GET_EVENTS_ON_MAPS,
        eventsOnMap : eventsOnMap,
    };
}

export const getEquipments = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/equipments?reqType=all")
        .then(res => {    
            console.log(res.data);
            dispatch(getEquipmentsSuccess(res.data));
        }).catch(error => {
            // dispatch(getUserDtlsDrpdwnFail(error.response.data.message));
        });
    };
}

export const getEquipementCategories = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/equipmentCategories?reqType=all")
        .then(res => {    
            console.log(res.data);
            dispatch(getEquipmentsCategoriesSuccess(res.data));
        }).catch(error => {
            // dispatch(getUserDtlsDrpdwnFail(error.response.data.message));
        });
    };
}

export const getEventsOnMap = () => {
    console.log('Event')
    return dispatch => {
        axios
        .get(BASE_URL + "/api/events?$limit=5&$sort[createdAt]=asc&eventStatusId=1")
        .then(res => {    
            console.log('Event1', res.data.rows);
            dispatch(getEventsOnMapSuccess(res.data.data.rows));
        }).catch(error => {
            console.log('EVENT ERROR')
            console.log(error)
            // dispatch(getUserDtlsDrpdwnFail(error.response.data.message));
        });
    }
}
