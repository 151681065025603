import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Divider, CardContent, Grid, Typography, Paper, Box, Container } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@material-ui/lab';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import commonStyles from '../../../commonStyles';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';

const styles = theme => ({
  ...commonStyles(theme),
});

class EventTimeLine extends React.Component {
  componentDidMount() {
    this.props.fetchEventTimeline(this.props.selectedEvent.eventData.txnEventId);
  }
  render() {
    const { classes } = this.props;
    console.log('.............selectedEvent: ', this.props.selectedEvent);
    console.log(this.props.selectedEvent.addEventInfo, )
    return (
      <React.Fragment>
        <div>
          <Typography variant="h5" className="sectionHeading">Event Information</Typography>

          <Grid container spacing={2}>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Event ID
                  </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.txnEventId}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Event Type
                  </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.eventType.eventTypeName}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Event Sub Type
                                        </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Notification Source
                                        </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.equipmentType ? this.props.selectedEvent.eventData.equipmentType.equipmentTypeName : ''}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Equipment
                  </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.equipment ? this.props.selectedEvent.eventData.equipment.equipmentName : ''}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Direction
                                            </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.directionName}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Location
                    </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.eventLatitude + ',' + this.props.selectedEvent.eventData.eventLongitude}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Upstream Reference
                    </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.upstreamLocation}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Downstream Reference
                    </Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.downstreamLocation}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Event Stakeholders
                    </Typography>
              <Typography variant="body1" gutterBottom>
                CTRL Users
                    </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
              <Typography variant="h6" gutterBottom>
                Event Description</Typography>
              <Typography variant="body1" gutterBottom>
                {this.props.selectedEvent.eventData.eventDescription}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} className={classes.gridItem}>
            </Grid>
          </Grid>

          {
            this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName == 'ACCIDENT' && this.props.selectedEvent.addEventInfo &&
            <div>
              <Divider variant="list" className={classes.atmsPageSectionDivider} />
              <Typography variant="h5" className="sectionHeading">Incident Information</Typography>

              <Grid container spacing={2}>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Incident Type
                                        </Typography>
                  <Typography variant="body1" gutterBottom>
                    {this.props.selectedEvent.eventData.eventSubtype.eventSubtypeName}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Incident Sub Type
                                        </Typography>
                  <Typography variant="body1" gutterBottom>
                    Other
                                        </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    No of Casualties
                    </Typography>
                  <Typography variant="body1" gutterBottom>
                    {this.props.selectedEvent.addEventInfo.noOfCasualties}
                  </Typography>
                </Grid>

                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    No of Vehicles Involved
                    </Typography>
                  <Typography variant="body1" gutterBottom>
                    {this.props.selectedEvent.addEventInfo.noOfVehicles}

                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Police FIR No
                                            </Typography>
                  <Typography variant="body1" gutterBottom>
                    {this.props.selectedEvent.addEventInfo.policeFirNo}

                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Description
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    
                    {this.props.selectedEvent.addEventInfo.length !== 0 ? this.props.selectedEvent.addEventInfo.informationDescription : ''}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          }


          {this.props.selectedEvent.addEventInfo.vehicleInfo ? this.props.selectedEvent.addEventInfo.vehicleInfo.map((vehicleInfo) => {
            return (
              <div>
                <Divider variant="list" className={classes.atmsPageSectionDivider} />
                <Typography variant="h5" className="sectionHeading">Vehicle Information</Typography>

                <Grid container spacing={2}>
                  <Grid item md={3} xs={12} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>
                      Type of Vehicle
                        </Typography>
                    <Typography variant="body1" gutterBottom>
                      {vehicleInfo.typeOfVehicle}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>
                      Vehicle Make Model
                        </Typography>
                    <Typography variant="body1" gutterBottom>
                      {vehicleInfo.vehicleMakeModel}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>
                      Vehicle Number
                        </Typography>
                    <Typography variant="body1" gutterBottom>
                      {vehicleInfo.vehicleNumberPlate}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )
          }) : ''
        }
          <Divider variant="list" className={classes.atmsPageSectionDivider} />
          <Typography variant="h5" className="sectionHeading">Event Time Line</Typography>
          <Container maxWidth="md">
            <Timeline align="alternate">
              {this.props.selectedEvent.eventTimeline && this.props.selectedEvent.eventTimeline.map((timelineItem) => {
                return (<TimelineItem>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(timelineItem.updatedAt))}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {timelineItem.hasOwnProperty('txnEventLifecycleId') &&
                      <Paper elevation={3} className={classes.boxPaper}>
                        <Typography variant="h6" component="h1">
                          EVENT {timelineItem.eventStatusAlias}
                        </Typography>
                        <Divider />
                        <Box textAlign="left" m={1}>
                          <b>User ID  </b><span style={{ 'padding-left': '4em' }}>{timelineItem.updatedBy ? timelineItem.updatedBy.userDetailName : ''}</span><br />
                          <b>TimeStamp</b><span style={{ 'padding-left': '2em' }}> {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(timelineItem.updatedAt))}</span><br />
                          {/* <span style={{ 'padding-left': '8em' }}>9:30:41 AM</span> */}
                        </Box>
                      </Paper>
                    }
                    {timelineItem.hasOwnProperty('txnEventSopId') &&
                      <Paper elevation={3} className={classes.boxPaper}>
                        <Typography variant="h6" component="h1">
                          SOP 
                        </Typography>
                        <Divider />
                        <Box textAlign="left" m={1}>
                          <b>SOP Description</b><span style={{ 'padding-left': '4em' }}>{timelineItem?.eventSopItemDescription}</span><br/>
                          <b>TimeStamp</b><span style={{ 'padding-left': '2em' }}> {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(timelineItem.updatedAt))}</span><br />
                        </Box>
                      </Paper>
                 }

              {timelineItem.hasOwnProperty('txnEventAidVehicleStatusId') &&
                      <Paper elevation={3} className={classes.boxPaper}>
                        <Typography variant="h6" component="h1">
                          Highway Aid
                        </Typography>
                        <Divider />
                        <Box textAlign="left" m={1}>
                          <b>Vehicle Name</b><span style={{ 'padding-left': '4em' }}>{timelineItem?.assetCategory[0]?.assetVehicleDetailsName}</span><br/>
                          <b>Vehicle Number</b><span style={{ 'padding-left': '4em' }}>{timelineItem?.assetCategory[0]?.assetVehicleDetailsNumber}</span><br/>
                          <b>Vehicle Model</b><span style={{ 'padding-left': '4em' }}>{timelineItem?.assetCategory[0]?.assetVehicleDetailsModel}</span><br/>
                          <b>Vehicle Incharge's Contact</b><span style={{ 'padding-left': '4em' }}>{timelineItem?.aidVehicleInchargeContact}</span><br/>

                          <b>TimeStamp</b><span style={{ 'padding-left': '2em' }}> {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(timelineItem?.updatedAt))}</span><br />
                        </Box>
                      </Paper>
                 }

                    {timelineItem.hasOwnProperty('txnEventAlertId') &&
                      <Paper elevation={3} className={classes.paper}>
                        <Typography variant="h6" component="h1">
                          ALERTS
                                </Typography>
                        <Divider />
                        <Box textAlign="left" m={1}>
                          <b>{timelineItem.eventNotificationCategory ? timelineItem.eventNotificationCategory.notificationCategoryName : ''}</b><br />
                          <b>User ID </b><span style={{ 'padding-left': '2em' }}>{timelineItem.updatedBy ? timelineItem.updatedBy.userDetailName : ''}</span><br />
                          <b>Message</b><span style={{ 'padding-left': '2em' }}> {timelineItem.eventNotificationMessage}</span><br />
                        </Box>

                      </Paper>
                    }
                  </TimelineContent>
                </TimelineItem>);
              })}
            </Timeline>
          </Container>
          <Divider variant="list" className={classes.atmsPageSectionDivider} />
        </div >
      </React.Fragment >
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    //fetchAllAlerts: (eventId) => dispatch(actions.fetchSentAlerts(eventId)),
    fetchEventInfo: (eventId) => dispatch(actions.fetchEventDetails(eventId)),
    getEventAccidentVehicleInfo: (eventId) => dispatch(actions.getEventAccidentVehicleInfo(eventId)),
    getEventAdditionalInfo: (eventId) => dispatch(actions.getEventAdditionalInfo(eventId)),
    fetchEventTimeline: (eventId) => dispatch(actions.fetchEventTimeline(eventId))
  };
};

const mapStateToProps = state => {
  return {
    selectedEvent: state.selectedEvent

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventTimeLine));