import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth';
import masterGrid from './masterGrid';
import eventGrid from './eventGrid';
import userRole from './usermanagement/UserRole';
import userDetails from './usermanagement/userdetails';
import equipTypeReducer from './equipmentManagement/equipTypeReducer';
import equipGroupReducer from './equipmentManagement/equipGroupReducer';
import equipCatReducer from './equipmentManagement/equipCatReducer';
import eventGeneralDts from './eventManagement/eventGeneralDts';
import menu from './menu';
import locationTypeReducer from './locationManagement/locationTypeReducer';
import locationCatReducer from './locationManagement/locationCatReducer';
import locationDetailsReducer from './locationManagement/locationDetailsReducer';
import selectedEvent from './eventManagement/selectedEvent';
import equipmentsReducer from './equipmentManagement/equipmentsReducer';
import mapsReducer from './maps/mapsReducer';
import audVioCmnReducer from './violationManagement/audVioCmnReducer';
import eventAlert from './eventManagement/eventAlerts';
import eventSop from './eventManagement/eventSop';
import auditReducer from './violationManagement/auditReducer';
import commonReducer from './common';
import shiftReducer from './shiftManagement/shiftReducer';
import assetVehicleCatReducer from './assetVehicleManagement/assetVehicleCatReducer';
import assetVehicleDetailsReducer from './assetVehicleManagement/assetVehicleDetailsReducer';
import eventTypeReducer from './eventManagement/eventTypeReducer';
import eventSubtypeReducer from './eventManagement/eventSubtypeReducer';
import sopCatReducer from './eventManagement/sopCatReducer';
import sopItemReducer from './eventManagement/sopItemReducer';
import sopMapReducer from './eventManagement/sopMapReducer'
import hotlistVehicleReducer from './hotlistVehicle/hotlistVehicleReducer';
import chainageReducer from './chainage/chainageReducer';
import sectionReducer from './section/sectionReducer';
import stakeholderCatReducer from './stakeholderManagement/stakeholderCatReducer';
import stakeholderUserReducer from './stakeholderManagement/stakeholderUserReducer';
import menuReducer from './menu/menuReducer';
import notificationCatReducer from './notificationManagement/notificationCatReducer';
import notificationTemplateReducer from './notificationManagement/notificationTemplateReducer';
import eventRuleReducer from './eventManagement/eventRuleReducer';
import directionReducer from './directionManagement/directionReducer';
import ecbReducer from './ecbManagement/ecbReducer';

export default combineReducers({
    auth: authReducer,
    form: formReducer,
    masterGrid: masterGrid,
    userRole: userRole,
    userDetails: userDetails,
    equipTypeReducer: equipTypeReducer,
    equipGroupReducer: equipGroupReducer,
    equipCatReducer: equipCatReducer,
    menu: menu,
    eventGeneralDts: eventGeneralDts,
    locationTypeReducer: locationTypeReducer,
    locationCatReducer: locationCatReducer,
    locationDetailsReducer: locationDetailsReducer,
    eventGrid: eventGrid,
    selectedEvent: selectedEvent,
    equipmentsReducer: equipmentsReducer,
    mapsReducer: mapsReducer,
    audVioCmnReducer: audVioCmnReducer,
    eventAlerts: eventAlert,
    eventSop: eventSop,
    auditReducer: auditReducer,
    common: commonReducer,
    shiftReducer: shiftReducer,
    assetVehicleCatReducer: assetVehicleCatReducer,
    assetVehicleDetailsReducer: assetVehicleDetailsReducer,
    eventTypeReducer: eventTypeReducer,
    eventSubtypeReducer: eventSubtypeReducer,
    sopCatReducer: sopCatReducer,
    sopItemReducer: sopItemReducer,
    sopMapReducer: sopMapReducer,
    hotlistVehicleReducer: hotlistVehicleReducer,
    chainageReducer: chainageReducer,
    sectionReducer: sectionReducer,
    stakeholderCatReducer: stakeholderCatReducer,
    stakeholderUserReducer: stakeholderUserReducer,
    menuReducer: menuReducer,
    notificationCatReducer: notificationCatReducer,
    notificationTemplateReducer: notificationTemplateReducer,
    eventRuleReducer: eventRuleReducer,
    directionReducer: directionReducer,
    ecbReducer: ecbReducer
});