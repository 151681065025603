import React, { useState, useEffect } from "react";
import printDoc from "./printDoc";
import { Button, Divider } from "@material-ui/core";
import format from "date-fns/format";

const PDFExportPanel = (props) => {
  let today = new Date();
  let presentDate = format(today, "yyyy-MM-dd");

  const {
    filters,
    allFilters,
    shifts,
    directions,
    chainage,
    lanes,
    plazas,
    txnTypes,
    vehicleClasses,
    tcs,
    dataCompletenessStatus,
    reportCount,
    footerValues,
    allReportFilters,
    overAllAccuracy,
  } = props;
  const [fromDate, setFromDate] = useState(presentDate);
  const [toDate, setToDate] = useState(presentDate);

  const [shiftIds, setShiftIds] = useState();
  const [laneIds, setLaneIds] = useState();
  const [chainageId, setChainageId] = useState();
  const [tcIds, setTcIds] = useState();
  const [plazaCodes, setPlazaCodes] = useState();
  const [txnTypeCodes, setTxnTypeCodes] = useState();
  const [vehicleClassCodes, setVehicleClassCodes] = useState();

  const [shiftNames, setShiftNames] = useState();
  const [laneNames, setLaneNames] = useState();
  const [plazaNames, setPlazaNames] = useState();
  const [txnTypeNames, setTxnTypeNames] = useState();
  const [vehicleClassNames, setVehicleClassNames] = useState();
  const [tcNames, setTcNames] = useState();
  const [directionIds, setDirectionIds] = useState([]);
  const [chainageIds, setChainageIds] = useState([]);
  const [directionNames, setDirectionNames] = useState();
  const [chainageNames, setChainageNames] = useState();
  

  const [PDF_PAGE_ORITENTATION, SET_PDF_PAGE_ORIENTATION] =
    useState("landscape");
  const [PDF_WITH_HEADER_IMAGE, SET_PDF_WITH_HEADER_IMAGE] = useState(false);
  const [PDF_WITH_FOOTER_PAGE_COUNT, SET_PDF_WITH_FOOTER_PAGE_COUNT] =
    useState(true);
  const [PDF_HEADER_HEIGHT, SET_PDF_HEADER_HEIGHT] = useState(25);
  const [PDF_ROW_HEIGHT, SET_PDF_ROW_HEIGHT] = useState(20);

  const [PDF_ODD_BKG_COLOR, SET_PDF_ODD_BKG_COLOR] = useState("#fcfcfc");
  const [PDF_EVEN_BKG_COLOR, SET_PDF_EVEN_BKG_COLOR] = useState("#fff");

  const [PDF_WITH_CELL_FORMATTING, SET_PDF_WITH_CELL_FORMATTING] =
    useState(true);
  const [PDF_WITH_COLUMNS_AS_LINKS, SET_PDF_WITH_COLUMNS_AS_LINKS] =
    useState(true);

  const [PDF_SELECTED_ROWS_ONLY, SET_PDF_SELECTED_ROWS_ONLY] = useState(false);

  const PDF_HEADER_COLOR = "#f8f8f8";
  const PDF_INNER_BORDER_COLOR = "#dde2eb";
  const PDF_OUTER_BORDER_COLOR = "#babfc7";
  const PDF_LOGO =
    "https://raw.githubusercontent.com/AhmedAGadir/ag-grid-todo-list-react-typescript/master/src/assets/new-ag-grid-logo.png";
  const PDF_IMAGE = (
    <img
      src="DEENRLOGO.png"
      style={{ height: "40px", width: "40px" }}
      alt="DEENRLOGO"
    />
  );
console.log("#############################",filters);
  useEffect(() => {
    filters &&
      filters.forEach((filter) => {
        if (filter.key === "fromDate") {
          setFromDate(filter.value);
        } else if (filter.key === "toDate") {
          setToDate(filter.value);
        } else if (filter.key === "shiftId") {
          setShiftIds(filter.value);
        } else if (filter.key === "laneId") {
          setLaneIds(filter.value);
        } else if (filter.key === "tc") {
          setTcIds(filter.value);
        } else if (filter.key === "plazaCode") {
          setPlazaCodes(filter.value);
        } else if (filter.key === "txnType") {
          setTxnTypeCodes(filter.value);
        } else if (filter.key === "directionId") {
          setDirectionIds(filter.value);
        } else if (filter.key ==  "chainageId") {
            setChainageIds(filter.value);
        }else if (filter.key === "vehicleClassCode") {
          setVehicleClassCodes(filter.value);
        }
      });
  }, [filters]);

//   <Divider />

  useEffect(() => {
    let tempArray = [];
    tcIds &&
      tcIds.map((tcId) => {
        tcs.length > 0 &&
          tcs.filter((tc) => {
            if (tc.loginId === tcId) {
              tempArray.push(tcId);
            }
            setTcNames(tempArray.join(", "));
          });
      });
  }, [tcIds]);

//   useEffect(() => {
//     let tempArray = [];
//     shiftIds &&
//       shiftIds.map((shiftId) => {
//         shifts.length > 0 &&
//           shifts?.filter((shift) => {
//             if (shift.shiftId === shiftId) {
//               tempArray.push(shift.shiftName);
//             }
//             setShiftNames(tempArray.join(", "));
//           });
//       });
//   }, [shiftIds]);

  useEffect(() => {
    let tempArray = [];
    laneIds &&
      laneIds.map((laneId) => {
        lanes.length > 0 &&
          lanes.filter((lane) => {
            if (lane.laneNumber === laneId) {
              tempArray.push(lane.laneName);
            }
            setLaneNames(tempArray.join(", "));
          });
      });
  }, [laneIds]);

  useEffect(() => {
    let tempArray = [];
    plazaCodes &&
      plazaCodes.map((plazaCode) => {
        plazas.length > 0 &&
          plazas.filter((plaza) => {
            if (plaza.plazaCode === plazaCode) {
              tempArray.push(plaza.plazaName);
            }
            setPlazaNames(tempArray.join(", "));
          });
      });
  }, [plazaCodes]);

  useEffect(() => {
    let tempArray = [];
    txnTypeCodes &&
      txnTypeCodes.map((txnTypeCode) => {
        txnTypes.length > 0 &&
          txnTypes.filter((txnType) => {
            if (txnType.txnTypeCode === txnTypeCode) {
              tempArray.push(txnType.txnTypeName);
            }
            setTxnTypeNames(tempArray.join(", "));
          });
      });
  }, [txnTypeCodes]);
console.log("@@@@@@@@@@@@@vehicleclasscode", vehicleClassCodes)
//   useEffect(() => {
//     let tempArray = [];
//     vehicleClassCodes &&
//     vehicleClassCodes?.map((vehicleClassCode) => {
//         vehicleClasses?.length > 0 &&
//         vehicleClasses?.filter((vehicleClass) => {
//             if (vehicleClass.vehicleClassCode === vehicleClassCode) {
//               tempArray.push(vehicleClass.vehicleClassName);
//             }
//             setVehicleClassNames(tempArray.join(", "));
//           });
//       });
//   }, [vehicleClassCodes]);

  useEffect(() => {
    let tempArray = [];
    directionIds &&
      directionIds.map((directionId) => {
        directions.length > 0 &&
          directions.filter((direction) => {
            if (direction.laneDirection === directionId) {
              tempArray.push(direction.directionName);
            }
            setDirectionNames(tempArray.join(", "));
          });
      });
  }, [directionIds]);
  useEffect(() => {
    let tempArray = [];
    chainageIds &&
    chainageIds.map((chainageId) => {
        chainage.length > 0 &&
          chainage.filter((chain) => {
            if (chain.chainageId === chainageId) {
              tempArray.push(chain.chainageName);
            }
            setChainageNames(tempArray.join(", "));
          });
      });
  }, [chainageIds]);

  const submitFormHandler = (event) => {
    event.preventDefault();
    console.log(" footer value in pdf export panel",footerValues)
    const printParams = {
      PDF_HEADER_COLOR,
      PDF_INNER_BORDER_COLOR,
      PDF_OUTER_BORDER_COLOR,
      PDF_LOGO,
      PDF_PAGE_ORITENTATION,
      PDF_WITH_HEADER_IMAGE,
      PDF_WITH_FOOTER_PAGE_COUNT,
      PDF_HEADER_HEIGHT,
      PDF_ROW_HEIGHT,
      PDF_IMAGE,
      PDF_ODD_BKG_COLOR,
      PDF_EVEN_BKG_COLOR,
      PDF_WITH_CELL_FORMATTING,
      PDF_WITH_COLUMNS_AS_LINKS,
      PDF_SELECTED_ROWS_ONLY,
      PDF_HEADING: props.heading,
      fromDate,
      toDate,
      shiftNames,
      directionNames,
      chainageNames,
      laneNames,
      tcNames,
      plazaNames,
      txnTypeNames,
      vehicleClassNames,
      footerValues,
      allReportFilters,
      overAllAccuracy,
    };

    printDoc(printParams, props.gridApi, props.columnApi, props.reportName);
    console.log(" printprams value in pdf export panel",printParams)
  };

  console.log("props in PDFEXPORT",props)
  
  return (
    <form onSubmit={submitFormHandler}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ margin: "5px" }}
      >
        Export to PDF
      </Button>
    </form>
  );
};

export default PDFExportPanel;
