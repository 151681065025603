import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    ecbTypes: [],
    callTypes: [],
    callStatuses: [],
    equipmentNames: [],
    directions: [],
    chainages: [],
    eventId: 0,
    loading: null
};

const ecbTypeSuccess = (state, action) => {
    return updateObject(state, { ecbTypes: action.ecbTypes })
}

const ecbCallTypeSuccess = (state, action) => {
    return updateObject(state, { callTypes: action.callTypes })
}

const ecbCallStatusSuccess = (state, action) => {
    return updateObject(state, { callStatuses: action.callStatuses })
}
const ecbNameSuccess = (state, action) => {
    return updateObject(state, { equipmentNames: action.equipmentNames })
}

const directionSuccess = (state, action) => {
    return updateObject(state, { directions: action.directions })
}

const chainageSuccess = (state, action) => {
    return updateObject(state, { chainages: action.chainages })
}

const ecbCreateSuccess = (state, action) => {
    return updateObject(state, { eventId: action.eventId })
}

const ecbEditSuccess = (state, action) => {
    return updateObject(state, { eventId: action.eventId })
}

const ecbStateReset = (state, action) => {
    return updateObject(state, { loading: null })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ECB_TYPE_SUCCESS:
            return ecbTypeSuccess(state, action);
        case actionTypes.GET_ECB_CALL_TYPE_SUCCESS:
            return ecbCallTypeSuccess(state, action);
        case actionTypes.GET_ECB_CALL_STATUS_SUCCESS:
            return ecbCallStatusSuccess(state, action)
        case actionTypes.GET_ECB_NAME_SUCCESS:
            return ecbNameSuccess(state, action)
        case actionTypes.ECB_DIRECTION_SUCCESS:
            return directionSuccess(state, action)
        case actionTypes.ECB_CHAINAGE_SUCCESS:
            return chainageSuccess(state, action)
        case actionTypes.ECB_CREATE_SUCCESS:
            return ecbCreateSuccess(state, action)
        case actionTypes.ECB_EDIT_SUCCESS:
            return ecbEditSuccess(state, action)
        case actionTypes.ECB_STATE_RESET:
            return ecbStateReset(state, action)
        default:
            return state;
    }
}

export default reducer;