import React, { Component } from 'react';
import { connect } from "react-redux";


import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import { withStyles } from "@material-ui/core/styles";
import commonStyles from "../../../commonStyles";

import axios from "axios"
import MenuLeafNode from './MenuLeafNode';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;


const styles = (theme) => ({
  ...commonStyles(theme),
  treeView: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'100%'
  }
});

const expandList = [];

class MenuEntitlements extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      selectedRoleEntitleMents: [],

    }
  }

  // render menu list recursively
  renderNode = (node) => {
    expandList.push(node.menuId.toString())
    return node.children.length > 0
      ?
      this.getMenuRootNode(node)
      : <MenuLeafNode
        key={node.menuId}
        node={node} selectedRoleId={this.props.selectedRoleId}
        updateNodeEntitlements={this.props.updateNodeEntitlements}
        selectedRoleEntitleMents={this.state.selectedRoleEntitleMents}
      />
  }

  // if user role change update entitlements
  watchSelectedChangeRole(selectedRoleId) {
    if (this.props.selectedRoleId === '') {
      this.setState({ selectedRoleEntitleMents: [] })
    } else {
      this.setState({ isLoading: true })
      // const SelctedRoleEndPoint = `http://localhost:3030/api/roleEntitlements?roleId=${selectedRoleId}&entitlementsType=0`
      const SelctedRoleEndPoint = BASE_URL + `/api/roleEntitlements?roleId=${selectedRoleId}&entitlementsType=0`

      axios.get(SelctedRoleEndPoint).then((res) => {
        this.setState({ selectedRoleEntitleMents: [...res.data], isLoading: false })
      }).catch(e => {
        console.log("error in feching entitlements", e)
        this.props.openSnakbar('Error', 'Error in fetching Entitlements!!!');
        this.setState({ selectedRoleEntitleMents: [], isLoading: false })
      })
    }
  }


  componentDidUpdate(prevProps, prevState) {
    // if role change update values
    if (this.props.isUpdated) {
      this.watchSelectedChangeRole(this.props.selectedRoleId)
      this.props.setIsUpdated(false);
    }
    if (prevProps.selectedRoleId !== this.props.selectedRoleId) {
      this.watchSelectedChangeRole(this.props.selectedRoleId)
    }

  }

  componentDidMount() {
    this.watchSelectedChangeRole(this.props.selectedRoleId)
  }
  // rendering tree
  getMenuRootNode = (node) => {
    return (
      <TreeItem style={{ padding: '0.5em' }} nodeId={node.menuId.toString()} label={node.menuName} key={node.menuId.toString()}>
        { node.children.map(e => this.renderNode(e))}
      </TreeItem >
    );
  }

  // rendering entitlements
  render() {
    const { classes } = this.props;
    const tree = <TreeView
      className={classes.treeView}
      defaultExpanded={expandList}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {this.props.menuItems.length >= 0 && this.props.menuItems.map((menu) => <div key={menu.menuId}>{this.renderNode(menu)}</div>)}
    </TreeView>

    return (
      <div>
        <Card >
          <CardHeader
            className={classes.textPrimary}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            title='Menu Entitlements'
          />
          <CardContent
           
            style={{ height: "50vh", overflow: "auto", fontWeight: 'bold' }}
          >
            {this.state.isLoading ? <div className={classes.loader}><CircularProgress /> </div> : tree}
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuItems: state.menu.menuItems
  };
};

export default connect(mapStateToProps)(withStyles(styles)(MenuEntitlements));