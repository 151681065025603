import React from "react";
import axios from 'axios'
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import commonStyles from "../../../commonStyles";
import SelectRole from "../../usermanagement/entitlements/SelectRole";
import ReportsList from "./ReportList";
import AssignedReportList from "./AssignedReportList";
import MenuEntitlements from "../../usermanagement/entitlements/MenuEntitlements";
import { Button } from '@material-ui/core'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    marginTop: theme.spacing(1),
    outline: 'none',
    padding: '0px',
  },
  button: {
    marginRight: theme.spacing(2)
  },
//   snakbar: {
//     minWidth: '24em'
//   }

});

class Entitlements extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedRoleId: "",
      editedEntitleMents: [],
      refreshSibling1:false,
      refreshSibling2:false,
      isSubmiting: false,
      alert: {
        msg: null,
        severity: 'success'
      },
      openSnakbar: false,
      isUpdated: false,
      selectedReports: [],
    };
  }

  handleRefresh = () => {
    this.setState({ refreshSibling1: true });
  }
  handleRefresh2=()=>{
    this.setState({refreshSibling2:true})
  }



  setSelectedRoleId = (selectedRoleId) => {
    this.setState({ selectedRoleId: selectedRoleId, editedEntitleMents: [] });
  };

  openSnakbar = (alertType, alertMsg) => {
    this.setState({ openSnakbar: true, alert: { msg: alertMsg, severity: alertType } })
  }

  //updateNodeEntitlements = (updatedEntitlement) => {
  //  this.setState({ isSubmiting: true })
  //  const tempEditedEntitlements = this.state.editedEntitleMents;
  //  console.log("tempEditedEntitlements", tempEditedEntitlements)
  //  const index = tempEditedEntitlements.findIndex((e) => e.entitlementsId === updatedEntitlement.entitlementsId)
  //  index !== -1 ? tempEditedEntitlements[index] = updatedEntitlement : tempEditedEntitlements.push(updatedEntitlement);
  //  this.setState({ editedEntitleMents: [...tempEditedEntitlements], isSubmiting: false })
  //}

  updateNodeEntitlements = (updatedEntitlement) => {
    this.setState((prevState) => {
      const tempEditedEntitlements = [...prevState.editedEntitleMents];
      const index = tempEditedEntitlements.findIndex((e) => e.entitlementsId === updatedEntitlement.entitlementsId);
  
      if (index !== -1) {
        tempEditedEntitlements[index] = updatedEntitlement;
      } else {
        tempEditedEntitlements.push(updatedEntitlement);
      }
  
      const selectedReports = tempEditedEntitlements.filter((entitlement) => entitlement.selected);
  
      return {
        editedEntitleMents: tempEditedEntitlements,
        selectedReports: selectedReports,
        isSubmiting: false,
      };
    });
  };
  
  

  update = async () => {
    // const UPDATE_URI = 'http://localhost:3030/api/roleEntitlements'
    const UPDATE_URI = BASE_URL + '/api/reportPrivileges'

    await axios.post(UPDATE_URI, [...this.state.editedEntitleMents]).then((res) => {
      console.log("updated entitlements", this.state.editedEntitleMents)
      this.openSnakbar('success', 'Entitlement Updated Successful');
      this.setState({ editedEntitleMents: [], isUpdated: true, isSubmiting: false })

    }).catch(e => {
      console.log("error in udating entitlements", e)
      this.openSnakbar('error', 'Error !! Entitlement Update Unsuccesfull !!!');
    })
  }

  setIsUpdated = (isUpdated) => {
    this.setState({ isUpdated: isUpdated })
  }

  handleUpdate = (e) => {
    e.preventDefault();
    if (!this.state.editedEntitleMents.length) return
    this.setState({ isSubmiting: true })
    this.update()
  }

  componentDidUpdate(){
    if(this.state.refreshSibling1==true){
      this.setState({ refreshSibling1: false });
    }
    if(this.state.refreshSibling2==true){
      this.setState({ refreshSibling2: false });
    }
  }

  handleClose = () => {
    this.setState({ openSnakbar: false })
  }

  render() {
    const { classes } = this.props;

    console.log("this.state.editedEntitleMents", this.state)

    return (
      <div className={classes.atmsPageOuterDiv}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSnakbar} autoHideDuration={6000} onClose={this.handleClose}>
          <MuiAlert className={classes.snakbar} elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.alert.severity}>
            {this.state.alert.msg}
          </MuiAlert>
        </Snackbar>
        <Card className={classes.atmsPageOuterCard}>
          <CardHeader title='Report Privileges Master' />
          <Divider />
          <CardContent>
            <Box mt={3} className={classes.searchBox}>
              <Card>
                <CardContent style={{ minHeight: '70vh' }}>
                  {/* select role */}
                  <SelectRole
                    openSnakbar={this.openSnakbar}
                    setSelectedRoleId={this.setSelectedRoleId}
                    selectedRoleId={this.state.selectedRoleId}
                  />

                  {/* entitlements and widget */}
                  {
                    this.state.selectedRoleId !== '' && <Grid container spacing={3} style={{ marginTop: "2em" }}>
                      <Grid item xs={12} md={6}>
                        <AssignedReportList
                          selectedRoleId={this.state.selectedRoleId}
                          updateNodeEntitlements={this.updateNodeEntitlements}
                          isUpdated={this.state.isUpdated}
                          setIsUpdated={this.setIsUpdated}
                          openSnakbar={this.openSnakbar}
                          onRefresh={this.handleRefresh}
                          refreshSibling2={this.state.refreshSibling2}
                        />
                      </Grid>


                      <Grid item xs={12} md={6}> 
                        <ReportsList
                          
                          currentUserRoleId={this.state.currentUserRoleId}
                          selectedRoleId={this.state.selectedRoleId}
                          updateNodeEntitlements={this.updateNodeEntitlements}
                          isUpdated={this.state.isUpdated}
                          setIsUpdated={this.setIsUpdated}
                          openSnakbar={this.openSnakbar}
                          onRefresh2={this.handleRefresh2}
                          refreshSibling1={this.state.refreshSibling1}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                      <Card>
                        <CardHeader
                          style={{ marginBottom: "0.5em" }}
                          title='Menu Entitlements'
                        />
                        <CardContent>widget</CardContent>
                      </Card>
                    </Grid> */}
                    </Grid>
                  }
                </CardContent>

              </Card>
              {/* <Box
                display="flex"
                justifyContent="flex-end"
                p={2}>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">EDIT</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" onClick={this.handleUpdate} disabled={!this.state.editedEntitleMents.length > 0 && !this.state.isSubmiting} >UPDATE</Button>
              </Box> */}
            </Box>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => {
    return {
      menuItems: state.menu.menuItems,
      currentUserRoleId: state.auth.user.userRoleId
    };
  };

export default connect(mapStateToProps)(withStyles(styles)(Entitlements));
