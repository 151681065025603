import React, { Component } from "react";
import io from "socket.io-client";
import NotificationPopover from "./NotificationPopover";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import EventIcon from "@material-ui/icons/Event";

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '50ch',
    backgroundColor: theme.palette.background.paper,
  },
  
});

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };
    this.handleNoficationClick = this.handleNoficationClick.bind(this)

  }


  initSocketIo() {
    // const socket = io("ws://localhost:3030");
    const socket = io("ws://ec2-43-204-174-175.ap-south-1.compute.amazonaws.com:3030");
    socket.on("connect", () => {
      socket.emit(
        "create",
        "authentication",
        {
          strategy: "jwt",
          accessToken: localStorage.getItem("jwtToken"),
        },
        function (error, newAuthResult) {
          console.log("notification connection successful");
        }
      );
    });
    socket.on("notify", (msg) => {
      // adding property isRead on msg
      msg.isRead = false;
      this.setState((prevState) => {
        // pushing element in top.
        const notifications = [msg, ...prevState.notifications]
        return {
          notifications: notifications,
        };
      });
    });
  }
  componentDidMount() {
    this.initSocketIo();
  }

  handleNoficationClick(e) {
    const notifications = this.state.notifications.map((notification) => {
      return { ...notification, isRead: true }
    })
    this.setState({ notifications: notifications })
  }


  render() {

    const { classes } = this.props;

    const notificationCount = () => {
      let count = 0;
      this.state.notifications.forEach((notification) => {
        if (!notification.isRead) {
          ++count;
        }
      })
      return count
    }

    const notificationItem = (notification, key) => {
      return (
        <ListItem selected={notification.isRead} button key={key} divider>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText 
            primary={notification.title}
            secondary={
              // notification.description.length > 50
              //   ? notification.description.substring(0, 50) + "..."
              //   : notification.description
              notification.description
            }
          />
        </ListItem>
      )
    }

    const notificationsList = this.state.notifications.length <= 0
      ? <ListItem button divider >
        <ListItemText
          primary="No new Notifications"
        />
      </ListItem>
      : this.state.notifications.sort((x, y) => { return (x.isRead === y.isRead) ? 0 : x.isRead ? 1 : -1; }).map(
        (notification, index) => {
          return (
            notificationItem(notification, index)
          );
        }
      );

    return (

      <React.Fragment>
        <NotificationPopover
          actionButton={
            <Badge onClick={this.handleNoficationClick}
              badgeContent={notificationCount()}
              color='secondary'
    
              max={99}
            >
              <NotificationsIcon />
            </Badge>
          }
          popoverContent={
            <List className={classes.root} component='nav' aria-label='mailbox folders' >
              {notificationsList}
            </List>
          }
        />
      </React.Fragment>
    );
  }
}

export default (withStyles(styles)(Notifications));
