import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, Card, CardContent } from '@material-ui/core';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from "react-redux";
import ViolationScrutiny from './VdScrutiny';
import ViolationFilter from '../../../components/common/violationManagement/ViolationFilter';
import * as actions from '../../../store/actions/index';
import globals from '../../../utils/globals';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f4f6f8',
  },
  header: {
    display: 'inline'
  }
});

class VdDashboard extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedViolationRow: null,
      urlParams: null,
      violationName: null
    };
    this.grid = React.createRef();
    this.scrutinyRef = React.createRef();
  }

  componentDidMount() {

    // Get the Directions
    this.props.getDirections();

    // Get the Vehicle Class
    this.props.getVehicleClass();

    // Get the Sections
    this.props.getSections();

  }

  onSubmit = (urlParams) => {

    console.log("onSubmit violation = urlParams", urlParams);
    this.setState({ urlParams: urlParams })
  }

  reloadViolations = () => {
    this.grid.current.reload();
  }
  
  handleScrollTo = (elRef) => {
    const el = elRef.current ? elRef.current : elRef;
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  render() {
    const { classes } = this.props;
    let violationName = null;
    let apiUrl = null;

    if (this.props.violationType) {
      violationName = globals.violationTypes[this.props.violationType.configValue1];
      console.log("Violation Name", violationName)
      apiUrl = "api/speedViolation?speedViolationType=" + this.props.violationType.configValue1 + "&violationStatusName=MARKED FOR CHALLAN";


      return (
        <Container maxWidth="xl" className={classes.root}>
          {/* Header */}
          <Typography variant="h4" gutterBottom className={classes.header}>
            Violation Dashboard
            </Typography>
          <Typography variant="h6" gutterBottom className={classes.header}>
            {" " + violationName}
          </Typography>
          {/* Violation Scrunity Filter */}
          <Box mt={3}>
            <Card>
              <CardContent>
                <ViolationFilter
                  onSubmit={(urlParams) => {
                    this.onSubmit(urlParams)
                  }}
                />
              </CardContent>
            </Card>
          </Box>

          {/* Violation Scrunity DataGrid */}
          <Box mt={3}>
            <DataGrid ref={this.grid} endpoint={apiUrl} isMasterGrid={false} downloadReport={true} urlParams={this.state.urlParams} onRowClick={(event, rowData) => {
              console.log("I came inside Edit Click", event, rowData);
              this.setState({ selectedViolationRow: rowData });
              console.log("Row Data",rowData);
              console.log("Selected Violation Row",this.state.selectedViolationRow);
              this.handleScrollTo(this.scrutinyRef)
            }}
            />
          </Box>

          {/* Violation Scrunity Filter */}
          <div ref={this.scrutinyRef}>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <ViolationScrutiny violationType={this.props.violationType.configValue1} selectedViolation={this.state.selectedViolationRow} reloadViolations={this.reloadViolations} />
                </CardContent>
              </Card>
            </Box>
          </div>
        </Container>
      );
    } else {
      return <div>Loading...</div>
    }
  }
}

const mapStateToProps = state => {
  return {
    violationType: state.common.globalConfig.violation_type
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDirections: () => dispatch(actions.getDirections()),
    getVehicleClass: () => dispatch(actions.getVehicleClass()),
    getSections: () => dispatch(actions.getSections())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VdDashboard));
