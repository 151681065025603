import React from 'react';
import ShiftAddForm from './shiftAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class ShiftCreate extends React.Component {
    componentDidMount() {
        this.props.shiftStateReset();
    }

    onSubmit = formValues => {
        formValues.isEnabled = 1;
        this.props.shiftCreate(formValues);
        this.props.onClose();
    }
    
    render() {
        const { classes} = this.props;
        let progress = null;
        let alert = null;

        if(this.props.loading) {
            progress =  <div className={classes.spinDiv}>
                            <CircularProgress size={200} thickness={2} />
                        </div>
        }

        return (
            <React.Fragment>
                {alert}
                {progress}
                <ShiftAddForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.shiftReducer.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        shiftCreate: (formValues) => dispatch(actions.shiftCreate(formValues)),
        shiftStateReset: () => dispatch(actions.shiftStateReset())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ShiftCreate));