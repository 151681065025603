import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid, Button, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop, CircularProgress } from '@material-ui/core';
import { connect } from "react-redux";
import axios from 'axios';
import ChallanView from './ChallanView';
import ChallanRelated from './violationrelated/ViolationRelated';
import appConfig from '../../../appConfig.json';
import Toaster from '../../../components/common/Toaster';
import { withRouter } from 'react-router';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    selectFormControl: {
        minWidth: 120,
    },
    header: {
        display: 'inline'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    modal: {
        'overflow-y': 'auto',
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    gridCont: {
        margin: '8px 0px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class ChallanDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            noSelectRow: true,          // no ROW is selected
            openBackdrop: false,       //Backfrop when challan generation in progress
            relModal: false,            // related Challan Box
            relatedChallan: null,       // stores the related challan

            challanHtmlOpen: false,
            challanContent: null,

            violationId: "",            // 1  non-editable
            vehicleNumber: "",         // 2  non-editable
            vehicleClass: "",          // 3  non-editable
            vehicleMake: "",           // 4  editable. Not compulsory to backend
            vehicleModel: "",          // 5  editable. Not compulsory to backend
            vehicleColor: "",          // 6  editable. Not compulsory to backend
            ownerName: "",              // 7  editable. 
            ownerMobileNumber: "",      // 8  editable. Only 10 digit
            ownerAtpRegistrationId: "", // 9  editable. Only Numbers. 
            ownerAddress: "",           // 10 editable. 200 address length
            
            vehicleSpeed: "",           // 11. Section Speed
            directionName: "",          // 12. direction name
            sectionDistance : "",       // 13. Section Distance
            startChainage : "",         // 14. Start Chainage
            endChainage : "",           // 15. End Chainage
            startViolationTime : "",    // 16. Start Violation Time
            endViolationTime : "",      // 17. End Violation Time

            challanId: "",             // challan Id 
            fromAnprImgPath: "",
            openToast: false,
            msgToast: "",
            toastSeverity: "success",

            rejectRemarks: "",
            openRejectDialog: false,
            errors: {
                ownerName: "",                  // error ownerName
                ownerMobileNumber: "",          // error ownerMobileNumber
                ownerAtpRegistrationId: "",     // error ownerAtpRegistrationId
                ownerAddress: "",               // error ownerAddress
                rejectRemarks: "",             // error rejectRemarks
            },
        };
    }

    validate = () => {
        let { errors } = this.state;
        const oNumRegEx = /^[0-9]*$/;

        if (this.state.ownerName.length > 0) {
            if (this.state.ownerName.length >= 100) {
                errors.ownerName = 'Owner Name should be below 100 characters.';
                this.setState({ errors: errors })
                return false;
            }
            errors.ownerName = '';
            this.setState({ errors: errors });
        } else {
            errors.ownerName = 'Please enter the Owner Name.';
            this.setState({ errors: errors })
            return false;
        }

        if (this.state.ownerMobileNumber.length > 0) {
            if (oNumRegEx.test(this.state.ownerMobileNumber)) {
                if (this.state.ownerMobileNumber.length != 10) {
                    errors.ownerMobileNumber = 'Owner Mobile number should be 10 digit charecters.';
                    this.setState({ errors: errors })
                    return false;
                }
                errors.ownerMobileNumber = '';
                this.setState({ errors: errors });
            } else {
                errors.ownerMobileNumber = 'Only Numbers in Mobile number.';
                this.setState({ errors: errors })
                return false;
            }

        } else {
            errors.ownerMobileNumber = 'Please enter the Owner Mobile number.';
            this.setState({ errors: errors })
            return false;
        }

        if (this.state.ownerAtpRegistrationId.length > 0) {
            if (oNumRegEx.test(this.state.ownerAtpRegistrationId)) {
                if (this.state.ownerAtpRegistrationId.length != 10) {
                    errors.ownerAtpRegistrationId = 'Owner Atp Registration ID should be 10 digit charecters.';
                    this.setState({ errors: errors })
                    return false;
                }
                errors.ownerAtpRegistrationId = '';
                this.setState({ errors: errors });
            } else {
                errors.ownerMobileNumber = 'Only Numbers in Atp Registration ID.';
                this.setState({ errors: errors })
                return false;
            }
        } else {
            errors.ownerAtpRegistrationId = 'Please enter the Owner Atp Registration ID.';
            this.setState({ errors: errors })
            return false;
        }


        if (this.state.ownerAddress.length > 0) {
            if (this.state.ownerAddress.length > 200) {
                errors.ownerAddress = 'Owner Address cannot be greater than 200 charecters.';
                this.setState({ errors: errors })
                return false;
            }
        } else {
            errors.ownerAddress = 'Please enter the Owner Address.';
            this.setState({ errors: errors })
            return false;
        }

        return true;
    }

    componentWillReceiveProps(newProps) {
        // console.log("componentWillReceiveProps props.selectedChallanRow", newProps.selectedChallanRow);
        // console.log("componentWillReceiveProps state.selectedChallanRow", this.state);
        let schallan = newProps.selectedChallanRow;

        if (schallan && schallan.txnSpeedViolationId !== this.state.violationId) {
            // console.log("componentWillReceiveProps on update");
            this.setState({
                noSelectRow: false,
                challanId: schallan.txnSpeedChallanId ? schallan.txnSpeedChallanId : '',
                violationId: schallan.txnSpeedViolationId ? schallan.txnSpeedViolationId : '',
                vehicleNumber: schallan.vehicleNumber ? schallan.vehicleNumber : '',
                vehicleClass: schallan.vehicleClass ? schallan.vehicleClass : '',
                vehicleMake: schallan.vehicleMake ? schallan.vehicleMake : '',
                vehicleModel: schallan.vehicleModel ? schallan.vehicleModel : '',
                vehicleColor: schallan.vehicleColor ? schallan.vehicleColor : '',
                ownerName: schallan.ownerName ? schallan.ownerName : '',
                ownerMobileNumber: schallan.ownerMobileNumber ? schallan.ownerMobileNumber : '',
                ownerAtpRegistrationId: schallan.ownerAtpRegistrationId ? schallan.ownerAtpRegistrationId : '',
                ownerAddress: schallan.ownerAddress ? schallan.ownerAddress : '',
                
                vehicleSpeed: schallan.speed ? schallan.speed : '',
                directionName: schallan.direction.directionName ? schallan.direction.directionName : '',
                sectionDistance : schallan.distance ? schallan.distance : '',
                startChainage : schallan.fromChainageRel.chainageName ? schallan.fromChainageRel.chainageName : '',
                endChainage : schallan.toChainageRel?.chainageName ? schallan?.toChainageRel?.chainageName : '',
                startViolationTime : schallan.fromDatetime ? schallan.fromDatetime : '',
                endViolationTime : schallan.toDatetime ? schallan.toDatetime : '',
            });
        }
    }

    componentDidMount() {
        // console.log("INTO componentDidMount of ChallanDetails", this.state.selectedChallanRow);
        let schallan = this.props.selectedChallanRow;
        if (schallan && schallan.txnSpeedViolationId !== this.state.violationId) {
            // console.log("componentDidMount on update");
            this.setState({
                noSelectRow: false,
                challanId: schallan.txnSpeedChallanId ? schallan.txnSpeedChallanId : '',
                violationId: schallan.txnSpeedViolationId ? schallan.txnSpeedViolationId : '',
                vehicleNumber: schallan.vehicleNumber ? schallan.vehicleNumber : '',
                vehicleClass: schallan.vehicleClass ? schallan.vehicleClass : '',
                vehicleMake: schallan.vehicleMake ? schallan.vehicleMake : '',
                vehicleModel: schallan.vehicleModel ? schallan.vehicleModel : '',
                vehicleColor: schallan.vehicleColor ? schallan.vehicleColor : '',
                ownerName: schallan.ownerName ? schallan.ownerName : '',
                ownerMobileNumber: schallan.ownerMobileNumber ? schallan.ownerMobileNumber : '',
                ownerAtpRegistrationId: schallan.ownerAtpRegistrationId ? schallan.ownerAtpRegistrationId : '',
                ownerAddress: schallan.ownerAddress ? schallan.ownerAddress : '',
                
                vehicleSpeed: schallan.speed ? schallan.speed : '',
                directionName: schallan.direction.directionName ? schallan.direction.directionName : '',
                sectionDistance : schallan.distance ? schallan.distance : '',
                startChainage : schallan.fromChainageRel.chainageName ? schallan.fromChainageRel.chainageName : '',
                endChainage : schallan.toChainageRel?.chainageName ? schallan.toChainageRel?.chainageName : '',
                startViolationTime : schallan.fromDatetime ? schallan.fromDatetime : '',
                endViolationTime : schallan.toDatetime ? schallan.toDatetime : '',
            });
        }
    }

    onSubmitGenerateButton = async () => {
        // console.log(this.props.selectedChallanRow.txnSpeedChallanId);
        let response;
        let vrArgs = {};         // response related challan
        let totalrc;
        let violationDetails = {};

        if (!this.validate()) {
            return false;
        }

        /* Find the related violations for the selected Violation */
        try {
            console.log("selectedChallanRow in ChallanDetails", this.props.selectedChallanRow);
            // Start the backdrop before finding the related entries
            this.setState({ openBackdrop: true });

            /*
             * Step 1: Related Violation of selected violation will be fetched.
             * api/speedChallan/:txnSpeedChallanId/related. It returns a result object with
             * 1. generatedChallanDifferentSections: []
             * 2. generatedChallanSameSection: []
             * 3. relatedChallanDifferentSections: []
             * 4. relatedChallanSameSection: []         
             */
            response = await axios.get(BASE_URL + "/api/speedChallan/" +
                this.props.selectedChallanRow.txnSpeedChallanId + "/related");
                vrArgs.rc = response.data.data;
                totalrc = vrArgs.rc.generatedChallanDifferentSections.length +
                vrArgs.rc.generatedChallanSameSection.length +
                vrArgs.rc.relatedChallanDifferentSections.length +
                vrArgs.rc.relatedChallanSameSection.length;


            if (totalrc === 0) {
                // In case of no related challan we directly Generate Challan
                this.generateChallan();
            } else {
                this.closeBackdrop();

                violationDetails.violationId = this.state.violationId;
                violationDetails.vehicleNumber = this.state.vehicleNumber;
                violationDetails.vehicleClass = this.state.vehicleClass;
                violationDetails.vehicleMake = this.state.vehicleMake;
                violationDetails.vehicleModel = this.state.vehicleModel;
                violationDetails.vehicleColor = this.state.vehicleColor;
                violationDetails.ownerName = this.state.ownerName;
                violationDetails.ownerMobileNumber = this.state.ownerMobileNumber;
                violationDetails.ownerAtpRegistrationId = this.state.ownerAtpRegistrationId;
                violationDetails.ownerAddress = this.state.ownerAddress;
                violationDetails.challanId = this.state.challanId;
                
                
                violationDetails.vehicleSpeed = this.state.vehicleSpeed;
                violationDetails.directionName = this.state.directionName;
                violationDetails.sectionDistance = this.state.sectionDistance;
                violationDetails.startChainage = this.state.startChainage;
                violationDetails.endChainage = this.state.endChainage;
                violationDetails.startViolationTime = this.state.startViolationTime;
                violationDetails.endViolationTime = this.state.endViolationTime;

                vrArgs.violationDetails = violationDetails;

                this.props.history.push({
                    pathname: "/violationrelated",
                    state: vrArgs
                });
            }
        } catch (error) {
            this.closeBackdrop();   // close the backdrop for related challan modal to open
            this.setState({ openToast: true, msgToast: error.response?.data?.message, toastSeverity: "error" });
        }
    }

    generateChallan = async () => {
        let response;

        /* Generate the challan for the speed violation */

        try {
            let genParams = {};

            genParams.vehicleMake = this.state.vehicleMake;
            genParams.vehicleModel = this.state.vehicleModel;
            genParams.vehicleColor = this.state.vehicleColor;
            genParams.ownerName = this.state.ownerName;
            genParams.ownerMobileNumber = this.state.ownerMobileNumber;
            genParams.ownerAtpRegistrationId = this.state.ownerAtpRegistrationId;
            genParams.ownerAddress = this.state.ownerAddress;

            response = await axios.patch(BASE_URL + "/api/speedChallan/" + this.props.selectedChallanRow.txnSpeedChallanId + "/generate", genParams);
            console.log("response of generated speed challan", response);
            this.closeBackdrop();                                           // close the backdrop for related challan modal to open
            this.setState({ challanHtmlOpen: true, challanContent: response.data.challanContent });
        } catch (error) {
            console.log("this is the error msg");
            this.closeBackdrop();                                           // close the backdrop for related challan modal to open
            this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
        }
    }

    viewChallan = async () => {
        let response;

        /* View the challan for the speed violation */

        try {
            response = await axios.get(BASE_URL + "/api/speedChallan/" + this.props.selectedChallanRow.txnSpeedChallanId + "/viewChallan");
            console.log("response of generated speed challan", response);
            this.setState({ challanHtmlOpen: true, challanContent: response.data.challanContent });
        } catch (error) {
            console.log("this is the error msg");
            this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
        }
    }

    onRejectButtonClick = async () => {
        this.setState({ openRejectDialog: true });
    }

    onRejectCancel = () => {
        this.setState({ openRejectDialog: false });
    }

    onRejectSubmit = async () => {
        let response;
        const { errors } = this.state;

        /* View the challan for the speed violation */
        if (this.state.rejectRemarks.length === 0) {
            errors.rejectRemarks = 'Please enter rejection remarks';
            this.setState({ errors: errors })
            return false;
        }
        errors.rejectRemarks = '';
        this.setState({ errors: errors });

        try {
            response = await axios.patch(BASE_URL + "/api/speedChallan/" +
                this.props.selectedChallanRow.txnSpeedChallanId,
                {
                    "challanStatus": "REJECTED CHALLAN",
                    "challanRejectionRemark": this.state.rejectRemarks,
                });
            // console.log("response of reject speed challan", response);
            this.setState({
                openToast: true,
                msgToast: "Rejected Challan Vehicle Number : " + this.props.selectedChallanRow.vehicleNumber,
                toastSeverity: "success"
            });
            this.setState({ openRejectDialog: false });
        } catch (error) {
            console.log("this is the error msg");
            this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
        }

    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    challanViewOnClose = () => {
        this.setState({ challanHtmlOpen: false })
        this.setState({ noSelectRow: true });

        // Reload the datagrid in the Violation ACCEPTED ONLY
        this.props.reloadViolations();
    }

    challanRelatedOnClose = () => {
        this.setState({ relModal: false });
    }

    closeBackdrop = () => {
        this.setState({ openBackdrop: false });
    }

    render() {
        const { classes } = this.props;
        let generateButton = null;
        let rejectButton = null;
        let viewChallan = null;
        let buttons = null;
        let editText = false;
        let noEditText = false;
        let speedChallanStatusName = null;
        let fromAnprImgPath = null;
        let toAnprImgPath = null;

        const { errors } = this.state;

        if (this.state.noSelectRow) {
            return <div>No Row Selected</div>
        }

        if (this.props.selectedChallanRow) {
            let speedChallanStatus = this.props.violationStatus.find((vStatus) => vStatus.speedViolationStatusId === this.props.selectedChallanRow.speedChallanStatusId);
            speedChallanStatusName = speedChallanStatus.speedViolationStatusName;
            generateButton = <Grid item xs={3}>
                <Button variant="contained" color="primary" fullWidth onClick={this.onSubmitGenerateButton}>
                    GENERATE CHALLAN
                </Button>
            </Grid>

            rejectButton = <Grid item xs={3}>
                <Button variant="contained" color="secondary" fullWidth onClick={this.onRejectButtonClick}>
                    REJECT CHALLAN
                </Button>
            </Grid>

            viewChallan = <Grid item xs={3}>
                <Button variant="contained" color="primary" fullWidth onClick={this.viewChallan}>
                    VIEW CHALLAN
                </Button>
            </Grid>

            if (speedChallanStatusName === "ACCEPTED") {
                editText = false;
                buttons = <Grid container spacing={1} className={classes.gridCont}>
                    {generateButton}
                    {rejectButton}
                </Grid>

            } else if (speedChallanStatusName === "CHALLAN GENERATED" || speedChallanStatusName === "DUPLICATE CHALLAN") {
                editText = true;
                noEditText = true;
                buttons = <Grid container spacing={1} className={classes.gridCont}>
                    {viewChallan}
                </Grid>
            } else if (speedChallanStatusName === "REJECTED CHALLAN") {
                editText = true;
                noEditText = true;
            }

            fromAnprImgPath = "NoImageAvailable.jpg";
            toAnprImgPath = "NoImageAvailable.jpg";
            if (this.props.selectedChallanRow.fromAnprImgPath) {
                fromAnprImgPath = this.props.selectedChallanRow.fromAnprImgPath
            }

            if (this.props.selectedChallanRow.toAnprImgPath) {
                toAnprImgPath = this.props.selectedChallanRow.toAnprImgPath
            }
        }

        if (this.props.selectedChallanRow) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        {/* 
                          * Violation Scrutiny Div. Accept and Reject the violation
                          * 
                          */}
                        <Grid item xs={6}>
                            <Grid container spacing={1} className={classes.gridCont}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Challan Details
                                </Typography>
                            </Grid>
                            <Grid container spacing={1} className={classes.gridCont}>
                                {/* 1.Violation ID */}
                                <Grid item xs={2} >

                                    <TextField
                                        id="violationId"
                                        label="Violation Id"
                                        value={this.state.violationId}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                {/* 2.Vehicle Number*/}
                                <Grid item xs={2}>

                                    <TextField
                                        id="vehicleNumber"
                                        label="Vehicle Number"
                                        value={this.state.vehicleNumber}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                                {/* 3 Vehicle Class*/}
                                <Grid item xs={8}>

                                    <TextField
                                        id="vehicleClass"
                                        label="Vehicle Class"
                                        value={this.state.vehicleClass}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>



                            </Grid>
                            {speedChallanStatusName !== "REJECTED CHALLAN" && 
                                <Grid container spacing={1} className={classes.gridCont}>
                                     
                                    {/* 4.Vehicle Make*/}
                                    <Grid item xs={4} >
                                        <TextField
                                            id="vehicleMake"
                                            name="vehicleMake"
                                            label="Vehicle Make"
                                            value={this.state.vehicleMake}
                                            fullWidth
                                            disabled={noEditText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    {/* 5.Vehicle Model*/}
                                    <Grid item xs={4}>
                                        <TextField
                                            id="vehicleModel"
                                            name="vehicleModel"
                                            label="Vehicle Model"
                                            value={this.state.vehicleModel}
                                            fullWidth
                                            disabled={noEditText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    {/* 6. Vehicle Color*/}
                                    <Grid item xs={4}>
                                        {/* <InputLabel>{"Vehicle Color"}</InputLabel> */}
                                        <TextField
                                            id="vehicleColor"
                                            name="vehicleColor"
                                            label="Vehicle Color"
                                            value={this.state.vehicleColor}
                                            fullWidth
                                            disabled={noEditText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {speedChallanStatusName !== "REJECTED CHALLAN" &&
                                <Grid container spacing={1} className={classes.gridCont}>



                                    <Grid item xs={4} >
                                        {/* <InputLabel>{"Owner Name"}</InputLabel> */}
                                        <TextField
                                            id="ownerName"
                                            name="ownerName"
                                            label="Owner Name"
                                            value={this.state.ownerName}
                                            fullWidth
                                            disabled={editText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                        {errors.ownerName.length > 0 &&
                                            <span>{errors.ownerName}</span>}
                                    </Grid>

                                    <Grid item xs={4}>

                                        <TextField
                                            id="ownerMobileNumber"
                                            name="ownerMobileNumber"
                                            label="Owner Mobile Number"
                                            value={this.state.ownerMobileNumber}
                                            fullWidth
                                            disabled={editText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                        {errors.ownerMobileNumber.length > 0 &&
                                            <span>{errors.ownerMobileNumber}</span>}
                                    </Grid>



                                    {/* 9 Owner ATP Registration*/}
                                    <Grid item xs={4}>
                                        {/* <InputLabel>{"Owner ATP Registration"}</InputLabel> */}
                                        <TextField
                                            id="ownerAtpRegistrationId"
                                            name="ownerAtpRegistrationId"
                                            label="Owner Atp Registration Id"
                                            value={this.state.ownerAtpRegistrationId}
                                            fullWidth
                                            disabled={editText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                        {errors.ownerAtpRegistrationId.length > 0 &&
                                            <span>{errors.ownerAtpRegistrationId}</span>}
                                    </Grid>
                                </Grid>
                            }
                            {speedChallanStatusName !== "REJECTED CHALLAN" &&
                                <Grid container spacing={1} className={classes.gridCont}>

                                    <Grid item xs={12} >
                                        {/* <InputLabel>{"Owner Address"}</InputLabel> */}
                                        <TextField
                                            id="ownerAddress"
                                            name="ownerAddress"
                                            label="Owner Address"
                                            // multiline
                                            value={this.state.ownerAddress}
                                            // rows={2}
                                            fullWidth
                                            disabled={editText}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                        {errors.ownerAddress.length > 0 &&
                                            <span>{errors.ownerAddress}</span>}
                                    </Grid>
                                </Grid>
                            }

                            {speedChallanStatusName === "REJECTED CHALLAN" &&
                                <Grid container spacing={1} className={classes.gridCont}>
                                    <Grid item xs={12} >
                                        {/* <InputLabel>{"Owner Address"}</InputLabel> */}
                                        <TextField
                                            id="rejectRemarks"
                                            name="rejectRemarks"
                                            label="Rejection Remarks"
                                            value={this.props.selectedChallanRow.challanRejectionRemark}
                                            fullWidth
                                            disabled={true}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            }

                            <Grid container spacing={1} className={classes.gridCont}>
                                {buttons}
                            </Grid>

                        </Grid>

                        {/* 
                          * Violation Proof Div. Show the fromAnprImgPath and toAnprImgPath
                          * 
                          */}
                        <Grid item xs={6} >
                            <Grid container spacing={1} className={classes.gridCont}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Challan Proof
                                </Typography>
                            </Grid>
                            <Grid container spacing={1} className={classes.gridCont}>

                                {/* 1. From ANPR IMG PATH */}
                                <Grid item xs={6} >
                                    <div>
                                        <Typography variant="subtitle2" gutterBottom className={classes.header}>
                                            From ANPR Path
                                        </Typography>
                                    </div>
                                    <img className={classes.image}
                                        height="300"
                                        src={fromAnprImgPath}
                                        alt={this.props.selectedChallanRow ? this.props.selectedChallanRow.vehicleClass.vehicleClassName : null}
                                    />
                                </Grid>

                                {/* 2. To ANPR IMG PATH */}
                                {this.props.violationType != 0 && <Grid item xs={6} >
                                    <div>
                                        <Typography variant="subtitle2" gutterBottom className={classes.header}>
                                            To ANPR Path
                                        </Typography>
                                    </div>
                                    <img className={classes.image}
                                        height="300"
                                        src={toAnprImgPath}
                                        alt={this.props.selectedChallanRow ? this.props.selectedChallanRow.vehicleClass.vehicleClassName : null}
                                    />
                                </Grid>
                                }
                            </Grid>


                        </Grid>
                    </Grid>
                    <Modal className={classes.modal}
                        open={this.state.relModal}
                    >
                        <ChallanRelated relatedChallan={this.state.relatedChallan}

                            onSubmit={(selectedOption) => {
                                console.log("selectedOption=", selectedOption);
                                this.challanRelatedOnClose();
                                if (selectedOption === "Yes") {
                                    // Start the backdrop before generating the related challan
                                    this.setState({ openBackdrop: true });
                                    this.generateChallan();
                                } else if (selectedOption === "Ok") {

                                    /* 
                                     * Reload the datagrid as this call comes when a violation gets marked as duplicate due to 
                                     *  earlier generated challan in 12 hours
                                     */
                                    this.props.reloadViolations();
                                    this.setState({ noSelectRow: true });
                                }
                            }}
                            onClose={this.challanRelatedOnClose} />
                    </Modal>
                    <Modal className={classes.modal}
                        open={this.state.challanHtmlOpen}
                        onClose={this.challanViewOnClose}
                    >
                        <ChallanView challanContent={this.state.challanContent} onClose={this.challanViewOnClose} />
                    </Modal>
                    <Dialog open={this.state.openRejectDialog} fullWidth={true} maxWidth='md'>
                        <DialogTitle>
                            <Typography variant="h6" gutterBottom className={classes.header}>
                                Reject Violation
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter the Rejection Remarks
                            </DialogContentText>
                            <TextField
                                autoFocus
                                value={this.state.rejectRemarks}
                                margin="dense"
                                id="rejectRemarks"
                                name="rejectRemarks"
                                onChange={this.handleInputChange}
                                required={true}
                                fullWidth
                            />
                            {errors.rejectRemarks.length > 0 &&
                                <span>{errors.rejectRemarks}</span>}
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={this.onRejectCancel}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={this.onRejectSubmit}>
                                Reject
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Toaster
                        open={this.state.openToast}
                        message={this.state.msgToast}
                        severity={this.state.toastSeverity}
                        onClose={() => {
                            // console.log("I came here in closing toaster");
                            if (this.state.toastSeverity === "success") {
                                // Reload the datagrid in the Violation ACCEPTED ONLY
                                this.setState({ noSelectRow: true });
                                this.props.reloadViolations();
                            }
                            this.setState({ openToast: false });
                        }}
                    />
                    <Backdrop className={classes.backdrop} open={this.state.openBackdrop} onClick={this.closeBackdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </React.Fragment>
            );

        } else {
            return <div>Loading...</div>
        }
    };
}

const mapStateToProps = state => {
    return {
        violationStatus: state.audVioCmnReducer.violationStatus
    };
};

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(ChallanDetails)));
