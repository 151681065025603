import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles, Typography, Button, Grid, Box, Stack, spacing, ButtonGroup, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import commonStyles from '../../commonStyles';
// import background from "../../images/backgroundImage.png";
import background from "../../images/driveSafe.png"

import { makeStyles } from "@material-ui/core/styles"

const styles = theme => ({
    ...commonStyles(theme),
    root: {
        marginTop: "10vh",
        fontSize: "1.5em",
        fontWeight: 'bold',
        marginRight: "4vw",
        marginLeft: "8vw",
        textDecoration: "none",
     },
     root1: {
        marginTop: "10vh",
        fontSize: "18px",
        fontWeight: 'bold',
        marginRight: "40px",
        textDecoration: "none"

    },
    root3: {
        marginTop: "10vh",
        fontSize: "18px",
        fontWeight: 'bold',
        marginRight: "40px",
        textDecoration: "none"
        // marginLeft: "720px",
    },
    root4: {
        marginTop: "10vh",
        fontSize: "18px",
        fontWeight: 'bold',
        marginRight: "90px",
        textDecoration: "none"
        // marginLeft: "750px",
    },
    root5: {
        backgroundSize: 'auto',
    },
    
    imageContainer: {
        width: "100%",
        height: "100%", 
        backgroundImage: `url(${background})`,
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        },
  

});


const useStyles = makeStyles((theme) => ({

}));


function LandingPage(props) {
    const { classes } = props;
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);


    let currentUserRoleId = useSelector(state => state.auth.user?.userRoleId)
    console.log("currentUserRoleId", currentUserRoleId)
    return (
        <div className={classes.imageContainer} >
            {/* <img src= {newBacground} style={{width : "100%", height:770}}/> */}
            <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItem="flex-start"
                >
                    <ButtonGroup
                        orientation="horizontal"
                        color='primary'

                    >

                        <Link fullWidth size="large" aria-controls="simple-menu" aria-haspopup="true"
                            variant="contained"
                            className={classes.root}
                            underline="none"
                            // onClick={(event) => setAnchorEl(event.currentTarget)}
                            onClick={() => {history.push("/landingPage") }}>
                             Dashboard
                        </Link>
                       


                        <Link
                            fullWidth size="large" aria-controls="simple-menu" aria-haspopup="true"
                            variant="contained"
                            className={classes.root}
                            onClick={() => history.push("/mapdashboard")}>
                            Maps
                        </Link>

                        <Link fullWidth size="large" aria-controls="simple-menu" aria-haspopup="true"
                            variant="contained"
                            className={classes.root}
                            underline="none"
                            onClick={(event) => setAnchorEl(event.currentTarget)}>
                            Pivot Reports
                        </Link>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem onClick={() => history.push("/atccdailytraffic")}>ATCC Daily Traffic Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/atccmonthlytraffic")}>ATCC Monthly Traffic Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/atcchourlytraffic")}>ATCC Hourly Traffic Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/vidsdailyevents")}> VIDS Daily Events Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/vidsmonthlyevents")}>VIDS Monthly Events Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/vidshourlyevents")}>VIDS Hourly Events Count Report</MenuItem>

                            <MenuItem onClick={() => history.push('/eventmanageperformance')}>Event Management Performance Report </MenuItem>
                            <MenuItem onClick={() => history.push('/eventsdailyreport')}>Daily Event/Incident Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/eventsmonthlyreport")}>Monthly Event/Incident Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/eventshourlyreport")}>Hourly Event/Incident Count Report</MenuItem>
                            <MenuItem onClick={() => history.push('/speeddailytraffic')}>Daily Speed Violation Traffic Count Report</MenuItem>
                            <MenuItem onClick={() => history.push("/speedmonthlytraffic")}>Monthly Speed Violation Traffic Count Report</MenuItem>
                            <MenuItem onClick={() => history.push('/speedhourlytraffic')}>Hourly Speed Violation Traffic Count Report</MenuItem>
                            <MenuItem onClick={() => history.push('/speeddailychallan')}>Daily Traffic Challan Report</MenuItem>
                            <MenuItem onClick={() => history.push("/speedmonthlychallan")}>Monthly Traffic Challan Report</MenuItem>
                            <MenuItem onClick={() => history.push("/TTESHourly15minAvgReport")}>Travel Time Estimate System (TTES) Hourly(15 Min) Report</MenuItem>
                            <MenuItem onClick={() => history.push("/ttesDailyAvgReport")}>Travel Time Estimate System (TTES) Daily Report</MenuItem>
                            <MenuItem onClick={() => history.push("/ttesMonthlyAverage")}>Travel Time Estimate System (TTES) Monthly Report</MenuItem>
                            <MenuItem onClick={() => history.push("/ttesHourlyAvgReport")}>Travel Time Estimate System (TTES) Hourly Report</MenuItem>
                            <MenuItem onClick={() => history.push("/methourlyavg")}>MET Hourly Average Report</MenuItem>

                        </Menu>



                        <Link
                            fullWidth size="large" aria-controls="simple-menu" aria-haspopup="true"
                            variant="contained"
                            className={classes.root1}
                            onClick={() => history.push("/genericreports")}>
                            Generic/Group-by Reports
                        </Link>


                    </ButtonGroup>

                    {/* <ButtonGroup
                        orientation="horizontal"
                        color='primary'
                    >
                        <Link fullWidth size="large" aria-controls="simple-menu" aria-haspopup="true"
                            variant="contained"
                            className={classes.root3}

                            onClick={(event) => setAnchorE2(event.currentTarget)}>
                            Custom Reports
                        </Link>
                        <Menu
                            id="custom-report"
                            anchorEl={anchorE2}
                            keepMounted
                            open={Boolean(anchorE2)}
                            onClose={() => setAnchorE2(null)}

                        >

                            <MenuItem onClick={() => history.push('/swbreconciliationdailyreport')}>SWB Reconciliation Daily Report</MenuItem>
                            <MenuItem onClick={() => history.push('/swbreconciliationmonthlyreport')}>SWB Reconciliation Monthly Report</MenuItem>
                            <MenuItem onClick={() => history.push('/reconciliationdailyreport')}>Reconciliation Daily Report</MenuItem>
                            <MenuItem onClick={() => history.push('/reconciliationmonthlyreport')}>Reconciliation Monthly Report</MenuItem>
                            <MenuItem onClick={() => history.push('/endofshiftreport')}>End Of Shift Report</MenuItem>
                            <MenuItem onClick={() => history.push('/swbtcendofshiftreport')}>SWB TC End Of Shift Report</MenuItem>
                        </Menu>
                        <Link
                            fullWidth size="large" aria-controls="simple-menu" aria-haspopup="true"
                            variant="contained"
                            className={classes.root4}
                            onClick={() => history.push("/tariffdashboard")}>
                            Tariff Dashboard
                        </Link>

                    </ButtonGroup>
 */}

                </Box>

        </div>

        
            
    )
}

export default withStyles(styles)(LandingPage);