import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    }
});
class VMSDetails extends React.Component {
    
 

    render() {
        const { classes, selectedEquipment } = this.props;
        console.log("selectedEquipment in the ECB Modal is", selectedEquipment);
        return (
             <React.Fragment>
                 <Container maxWidth="md" className={classes.container}>
                 <Card>
                            <CardHeader
                                title= {this.props.selectedEquipment.equipmentName} 
                                subheader = "VMS MODAL"
                                action={
                                    <IconButton onClick={() => { this.props.onClose() }}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                }
                            />
                            <Divider />
                            <CardContent>
                            
                    
                            </CardContent>
                            <Divider />
                        </Card>
                 </Container>
             </React.Fragment>
        );
    }
}



export default connect( null, null )(withStyles(styles)(VMSDetails));