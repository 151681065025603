import React from 'react';
import AssetVehicleDetailsAddForm from './assetVehicleDetailsAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class AssetVehicleDetailsCreate extends React.Component {

    componentDidMount() {
        this.props.assetVehicleDetailsStateReset();
    }

    onSubmit = (formValues, vehicleCatState) => {
        formValues.isEnabled = 1;
        this.props.assetVehicleDetailsCreate(formValues, vehicleCatState);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                {alert}
                {progress}
                <AssetVehicleDetailsAddForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {
        loading: state.assetVehicleDetailsReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        assetVehicleDetailsCreate: (formValues, vehicleCatState) => dispatch(actions.assetVehicleDetailsCreate(formValues, vehicleCatState)),
        assetVehicleDetailsStateReset: () => dispatch(actions.assetVehicleDetailsStateReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssetVehicleDetailsCreate));