import axios from "axios";
import appConfig from '../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

export const menuDataFetchSuccess = (menuData) => {
    return {
        type: 'MENU_DATA_FETCH_SUCCESS',
        menuData: menuData,
    };
};
export const menuDataFetchFail = (errorMsg) => {
    return {
        type: 'MENU_DATA_FETCH_FAIL',
        errorMsg: errorMsg
    };
};
export const fetchMenu = (endpoint) => {
    return dispatch => {

        axios
            .get(BASE_URL + "/" + endpoint)
            .then(res => {
                dispatch(menuDataFetchSuccess(res.data));
            }).catch(error => {
                dispatch(menuDataFetchFail(error.response.data.message));
                //temporary
                console.log('Error fetching Menu');
                
            });
    };
};