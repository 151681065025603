import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DataGrid from '../../../components/mastersGrid/DataGrid';

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    marquee: {
        color:'red',
        fontWeight: 'bold'
      }
});
class METDetails extends React.Component {
    
 

    render() {
        const { classes, selectedEquipment } = this.props;
        let alert;
        if(selectedEquipment.isFaulty === 1){
        alert =   
                <div className={classes.marquee}>
                    These equipment is under Maintaince
                </div> 
        }
        console.log("selectedEquipment in the MET Modal is", this.props);
        const headerTitle = selectedEquipment.equipmentName + ' | ' + this.props.selectedEquipment.chainageName + ' | ' + this.props.selectedEquipment.directionName;
        return (
             <React.Fragment>
                 <Container maxWidth="md" className={classes.container}>
                 <Card>
                            <CardHeader
                                title= {headerTitle}
                                subheader = {alert}
                                action={
                                    <IconButton onClick={() => { this.props.onClose() }}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                }
                            />
                            <Divider />
                                <CardContent>
                                    <Box mt={3}>
                                        {selectedEquipment.equipmentId != null && 
                                            <DataGrid ref={this.grid} 
                                                    endpoint="api/metRaws"
                                                    limit={5} 
                                                    paging={false}
                                                    urlParams={[
                                                        {
                                                        key:"equipmentId", value: selectedEquipment.equipmentId,
                                                        // key:"$sort[updatedAt]", value: "-1",
                                                        // key:"$skip", value: "-1",
                                                        // key:"$limit", value: "-1",
                                                    },
                                                    { 
                                                        // key: "equipmentId", value: this.props.selectedEquipment.equipmentId,
                                                        key:"$sort[updatedAt]", value: "-1",
                                                    } 
                                                ]} 
                                                />
                                        }
                                    </Box>
                                </CardContent>
                            <Divider />
                        </Card>
                 </Container>
             </React.Fragment>
        );
    }
}



export default connect( null, null )(withStyles(styles)(METDetails));