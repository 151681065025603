import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, InputLabel, Select, IconButton, Box, FormControl } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as reduxFormFunctions from '../../../../../components/common/Redux_forms_Common';
import { Field, reduxForm } from 'redux-form';
import checkEntitlement from "../../../../../utils/checkEntitlement";
import getRoute from '../../../../../utils/getRoute';
import * as actions from '../../../../../store/actions/index';
import { connect } from 'react-redux';
import appConfig from '../../../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})


class SOPMapAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventSopCategoryId: null,
            eventSopItemId: null,
            eventTypes: [],
            eventSubtypes: [],
            eventTypeId: null,
            eventSubtypeId: null,
            eventSopDisplayIndex: 0

        }
    }
    async componentDidMount() {
        this.props.getEventSopCategories();
        this.props.getEventTypes();
        if (this.props.initialValues) {
            this.setState({
                eventTypeId: this.props.initialValues.eventTypeId,
                eventSopCategoryId: this.props.initialValues.eventSopCategoryId,
            })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.eventSopCategoryId !== this.state.eventSopCategoryId) {
            this.props.getEventSopItems(this.state.eventSopCategoryId)
        }
        if (prevState.eventTypeId !== this.state.eventTypeId) {
            this.props.getEventSubtypes(this.state.eventTypeId)
        }
    }


    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    onSubmit = (formValues) => {
        const sopMapState = this.state
        this.props.onSubmit(formValues, sopMapState);
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Map SOP Item"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit SOP Item"

        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="SOP Mapping"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <React.Fragment>
                                    <Grid item md={6} xs={12}>
                                        <Field
                                            name="eventTypeId"
                                            id="eventTypeId"
                                            component={reduxFormFunctions.renderSelectField}
                                            onChange={this.onChange}
                                            label="Event Type"
                                            disabled={true}
                                        >
                                            <option value={0}>{""}</option>
                                            {this.props.eventTypes.map(eventType => {
                                                return (
                                                    <option key={eventType.eventTypeId} value={eventType.eventTypeId}>
                                                        {eventType.eventTypeName}
                                                    </option>
                                                )
                                            })}
                                        </Field>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Field
                                            name="eventSubtypeId"
                                            id="eventSubtypeId"
                                            component={reduxFormFunctions.renderSelectField}
                                            onChange={this.onChange}
                                            label="Event Subtype"
                                            disabled={true}
                                        >
                                            <option value={0}>{""}</option>
                                            {this.props.eventSubtypes.map(eventSubtype => {
                                                return (
                                                    <option key={eventSubtype.eventSubtypeId} value={eventSubtype.eventSubtypeId}>
                                                        {eventSubtype.eventSubtypeName}
                                                    </option>
                                                )
                                            })}
                                        </Field>
                                    </Grid>
                                </React.Fragment>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="eventSopCategoryId"
                                        id="eventSopCategoryId"
                                        component={reduxFormFunctions.renderSelectField}
                                        onChange={this.onChange}
                                        label="Event SOP Category"
                                        disabled={this.props.type === "Edit" ? true : false}
                                    >
                                        <option value={0}>{''}</option>
                                        {this.props.eventSopCategories.map(sopCategory => {
                                            return (
                                                <option key={sopCategory.sopCategoryId} value={sopCategory.sopCategoryId}>
                                                    {sopCategory.sopCategoryName}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="eventSopItemId"
                                        id="eventSopItemId"
                                        component={reduxFormFunctions.renderSelectField}
                                        onChange={this.onChange}
                                        label="Event SOP Item"
                                        disabled={this.props.type === "Edit" ? true : false}
                                    >
                                        <option value={0}>{''}</option>
                                        {this.props.eventSopItems.map(sopItem => {
                                            return (
                                                <option key={sopItem.sopItemId} value={sopItem.sopItemId}>
                                                    {sopItem.sopItemDescription}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="eventSopDisplayIndex"
                                        id="eventSopDisplayIndex"
                                        component={reduxFormFunctions.renderTextField}
                                        label="SOP Display Index"
                                        onChange={this.onChange}>
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.eventSopCategoryId || formValues.eventSopCategoryId == 0) {
        errors.eventSopCategoryId = "Please select SOP category";
    }
    if (!formValues.eventSopItemId || formValues.eventSopItemId == 0) {
        errors.eventSopItemId = "Please select SOP item";
    }
    if (!formValues.eventSopDisplayIndex) {
        errors.eventSopDisplayIndex = "Please enter Display Index";
    }
    if (!/^[0-9]*$/.test(formValues.eventSopDisplayIndex)) {
        errors.eventSopDisplayIndex = "Enter Only Numeric value";
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        eventTypes: state.sopMapReducer.eventTypes,
        eventSubtypes: state.sopMapReducer.eventSubtypes,
        eventSopCategories: state.sopMapReducer.eventSopCategories,
        eventSopItems: state.sopMapReducer.eventSopItems
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventTypes: () => dispatch(actions.getEventTypes()),
        getEventSubtypes: (eventTypeId) => dispatch(actions.getEventSubtypes(eventTypeId)),
        getEventSopCategories: () => dispatch(actions.getEventSopCategories()),
        getEventSopItems: (eventCategoryId) => dispatch(actions.getEventSopItems(eventCategoryId))
    }
}
SOPMapAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(SOPMapAddForm)


export default reduxForm({
    form: 'SOPMapAddForm',
    validate: validate,
})(withStyles(styles)(SOPMapAddForm));