import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Divider, Button, Modal, Typography, Box, Card, CardContent, TextField, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from 'react-redux';
import DirectionEdit from './directionEdit';
import FormControl from '@material-ui/core/FormControl';
import commonStyles from '../../../commonStyles';
import Toaster from '../../../components/common/Toaster';
import checkEntitlement from "../../../utils/checkEntitlement";

const styles = theme => ({
    ...commonStyles(theme),
    searchBox: {
        marginTop: theme.spacing(5),
        clear: 'both'
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto'
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    }
});

class Directions extends React.Component {
    currentSelectedRow = null;

    constructor(props) {
        super(props);
        this.state = {
            isEnabled: 'all',
            directionName: '',
            urlParams: [],
            isOpen: false,
            modalType: ''
        };
        this.grid = React.createRef();
    }

    onSearchSubmit = (event) => {
        event.preventDefault();
        const urlParams = [];

        if (this.state.directionName.length > 0) {
            urlParams.push({ 'key': 'directionName', value: this.state.directionName });
        }
        this.setState({ urlParams: urlParams })
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        this.setState({
            directionName: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        /*
         * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
         */
        if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
            this.grid.current.reload();
        }
    }


    render() {
        const { classes } = this.props;
        let modalDiv = null;

        //-----------------------------------------ADD BUTTON IS DESABLED-------------------------------------------
        // if (this.state.modalType === "ADD") {
        //     modalDiv = <Modal
        //         className={classes.modal}
        //         open={this.state.isOpen}
        //         onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
        //         closeAfterTransition>
        //         <DireactionCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
        //     </Modal>
        // }

        if (this.state.modalType === "EDIT") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
                closeAfterTransition>
                <DirectionEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        return (
            <div className={classes.atmsPageOuterDiv}>
                <Card className={classes.atmsPageOuterCard}>
                    <CardHeader title="Directions" />
                    <Divider />
                    <CardContent>
                        {/* <Button variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={() => this.setState({ isOpen: true, modalType: "ADD" })}
                            disabled>
                            Add Direction
                        </Button> */}
                        <Box mt={3} className={classes.searchBox}>
                            <Card>
                                <CardContent>
                                    <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={2}>
                                                <div style={{ maxWidth: '70px' }}>
                                                    <Typography variant="h6" gutterBottom className={classes.sheader}>
                                                        Search
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl component="fieldset">
                                                    <TextField
                                                        label="Direction Name"
                                                        name="directionName"
                                                        className={classes.sheader}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.directionName}
                                                        variant="outlined" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    className={`${classes.sheader} ${classes.addButton}`} variant="contained"
                                                    color="primary"
                                                    startIcon={<SearchIcon />}
                                                    type="submit"
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box mt={3}>
                            <DataGrid downloadReport={true} ref={this.grid} endpoint="api/direction" urlParams={this.state.urlParams} isEditable={checkEntitlement(this.props.location.pathname, 'edit')} onEditClick={(event, rowData) => {
                                this.currentSelectedRow = rowData;
                                console.log("direction row data", rowData)
                                this.setState({ isOpen: true, modalType: "EDIT" })
                            }} />
                        </Box>
                    </CardContent>
                </Card>
                <Toaster
                    open={this.props.toasterOpen}
                    message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
                    severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
                />
                { modalDiv}
            </div >

        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.directionReducer.loading,
        toasterOpen: state.common.toasterOpen,
        toasterMessageConfig: state.common.toasterMessageConfig
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Directions));

