import axios from "axios";
import * as actionTypes from '../actionTypes';
import { showToaster } from '../common';
import {fetchEventDetails} from './events';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

export const getLocationTypesSuccess = (locationTypes) => {
  return {
    type: actionTypes.GET_LOCATION_TYPES_SUCCESS,
    locationTypes: locationTypes,
  };
};

export const getLocationTypes = () => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/locationTypes?reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getLocationTypesSuccess(res.data));
      }).catch(error => {

      });
  };
}

export const getlocationsDetailsSuccess = (locationDetails) => {
  return {
    type: actionTypes.GET_LOCATION_DETAILS_SUCCESS,
    locationDetails: locationDetails,
  };
};

export const getlocationDetails = (locationTypeId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/locationDetails?locationTypeId=" + locationTypeId + "&reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getlocationsDetailsSuccess(res.data));
      }).catch(error => {

      });
  };
}

export const getEventSopSuccess = (eventSopActions) => {
  return {
    type: actionTypes.GET_EVENT_SOP_SUCCESS,
    eventSopActions: eventSopActions,
  };
};

export const getEventSop = (eventSubtypeId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/eventSop?eventSubtypeId=" + eventSubtypeId + "&reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getEventSopSuccess(res.data));
      }).catch(error => {

      });
  };
}

export const getSelectedEventSopItems = (eventId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/eventSopAction?reqType=all&eventId=" + eventId)
      .then(res => {
        console.log(res.data);
        dispatch(getSelectedEventSopItemsSuccess(res.data));
      }).catch(error => {

      });
  };
}

export const getSelectedEventSopItemsSuccess = (selectedEventSopItems) => {
  return {
    type: actionTypes.GET_SELECTED_EVENT_SOP_SUCCESS,
    selectedEventSopItems: selectedEventSopItems,
  };
};

export const updateEventSopItems = (body) => {
  return dispatch => {
    axios
      .post(BASE_URL + "/api/eventSopAction", body)
      .then(res => {
        console.log(res.data);
        dispatch(showToaster('success', 'SOP action saved successfully!'));
        dispatch(fetchEventDetails(body.eventId));
        dispatch(updateEventSopItemsSuccess(res.data));
      }).catch(error => {
        dispatch(showToaster('error', 'Error saving SOP Action!'));
      });
  };
}

export const updateEventSopItemsSuccess = (selectedEventSopItems) => {
  return {
    type: actionTypes.UPDATE_EVENT_SOP_SUCCESS,
    message: 'Success'
  };
};

