import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box, Card, CardHeader, IconButton, Grid, Divider, Container, CardContent } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line'
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    ...commonStyles(theme),
    root: {
        marginTop: theme.spacing(9),
        backgroundColor: '#f4f6f8',
    },
    header: {
        display: 'inline'
    },
    container: {
        display: 'flex',
        marginTop: theme.spacing(2),
        outline: 'none',
        padding: '0px',
        width: '100%'
    },   
    grid: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: '105%',
        width: '100%',
        // boxShadow: '0 0 10px #000000',
        padding: '2px'
    },
    text: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    chartTitle: {
        marginBottom: theme.spacing(0.5),
    }
});

class TrendsForMetEquipments extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            lineData: []
        }

    }

    async componentDidMount() {

        try {
            const res = await axios.get(BASE_URL + "/api/dashboard/metTwelveHoursTrend?EQUIPMENT_ID=" + this.props.equipmentId);
            if (res.data != null) {
                this.setState({
                    lineData: res.data.data
                })
            }
        }
        catch (error) {
            console.log("error");
        }
    }


    render() {
        const { classes } = this.props;
        let subheaderName = null;

        if (this.props.equipmentId) {
            subheaderName = "MET Equipment Trends - Equipment id : " + this.props.equipmentId;
        }
        else {
            subheaderName = "MET Equipment Trends - No Chainage is selected";
        }

        const CustomFontTick = tick => {
            return (
                <g transform={`translate(${tick.x},${tick.y + 18})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: 10,
                        }}
                    >{tick.value}
                    </text>
                </g>
            )
        }

        return (
            <React.Fragment>
                <Container maxWidth="lg" className={classes.container}>
                    <Card>
                        <CardHeader
                            title={subheaderName}                            
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid className={classes.grid} container>

                                {this.state.lineData && this.state.lineData.map((trendData) => {
                                    let trendDataArray = [];
                                    trendDataArray = [...trendDataArray, trendData];
                                    console.log("trendDataArray", trendDataArray);
                                    console.log("trendData", trendData);

                                    console.log("line data", this.state.lineData);

                                    if (trendDataArray) {

                                        let yAxislegend = trendData.YAxisTitle;

                                        return (
                                            <Grid item md={6} lg={4} xl={3}>
                                                <Typography className={classes.chartTitle} align="center">{trendData.title}</Typography>,
                                                <ResponsiveLine
                                                    data={trendDataArray}
                                                    height={200}
                                                    margin={{ top: 5, right: 50, bottom: 40, left: 60 }}
                                                    xScale={{ type: 'point' }}
                                                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                                    yFormat=" >-.1f"
                                                    // curve="natural"
                                                    // enableGridY={false}                                                   
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        orient: 'bottom',
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: 'Time',
                                                        legendOffset: 29,
                                                        legendPosition: 'middle',
                                                        
                                                        // renderTick: CustomFontTick
                                                    }}
                                                    axisLeft={{
                                                        orient: 'left',
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: yAxislegend,
                                                        legendOffset: -45,
                                                        legendPosition: 'middle',
                                                        precision: 1,
                                                        // format: e => Math.floor(e) === e && e,
                                                        // renderTick: CustomFontTick
                                                    }}
                                                    colors={{ scheme: 'category10' }}
                                                    pointSize={10}
                                                    pointColor={{ theme: 'background' }}
                                                    pointBorderWidth={2}
                                                    pointBorderColor={{ from: 'serieColor' }}
                                                    pointLabelYOffset={-12}
                                                    useMesh={true}
                                                />
                                            </Grid>
                                        )
                                    }
                                })}
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        )

    }

}

export default withStyles(styles)(TrendsForMetEquipments)