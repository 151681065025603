import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getUserRoleSuccess = (userRoles) => {
    return {
        type : actionTypes.GET_USER_ROLE_SUCCESS,
        userRoles : userRoles,
    };
};

export const getUserStatusSuccess = (userStatus) => {
    return {
        type : actionTypes.GET_USER_STATUS_SUCCESS,
        userStatus : userStatus,
    };
};

export const getUserShiftSuccess = (userShifts) => {
    return {
        type : actionTypes.GET_USER_SHIFT_SUCCESS,
        userShifts : userShifts,
    };
};

export const getUserRole = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/userRoles?reqType=all")
        .then(res => {    
            console.log(res.data);
            dispatch(getUserRoleSuccess(res.data));
        }).catch(error => {
            dispatch(showToaster('error',error.response.data.message))
        });
    };
}

export const getUserStatus = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/userStatus?reqType=all")
        .then(res => {    
            console.log(res.data);
            dispatch(getUserStatusSuccess(res.data));
        }).catch(error => {
            dispatch(showToaster('error',error.response.data.message))
        });
    };
}

export const getUserShift = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/shifts?reqType=all")
        .then(res => {    
            console.log(res.data);
            dispatch(getUserShiftSuccess(res.data));
        }).catch(error => {
            dispatch(showToaster('error',error.response.data.message))
        });
    };
}

const userDetailsCreateStart = () => {
    return {
        type : actionTypes.USER_DTLS_CREATE_START
    };
};

export const userDetailsCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(userDetailsCreateStart());
            const response = await axios.post(BASE_URL + "/api/users", formValues);
            console.log(response);
            dispatch(showToaster('success','User Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}

export const userDetailsStateReset = () => {
    return {
        type: actionTypes.USER_DTLS_STATE_RESET
    }
}

export const userDetailsEdit = (formValues) => {
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    
    let values = {
        userDetailName: formValues.userDetailName,
        userRoleId: formValues.userRoleId,
        loginId: formValues.loginId,
        mobileNo: formValues.mobileNo,
        emailId: formValues.emailId,
        userStatusId: formValues.userStatusId,
        gender: formValues.gender,
        dateOfBirth: formValues.dateOfBirth,
        dateOfJoining: formValues.dateOfJoining,
        shiftId: formValues.shiftId,
        payrollEmployee: formValues.payrollEmployee,
        activationDate: formValues.activationDate,
        expiryDate: formValues.expiryDate,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(userDetailsCreateStart());
            const response = await axios.patch(BASE_URL + "/api/users/" + formValues.userDetailId, values);
            console.log(response);
            dispatch(showToaster('success','User Successfully Edited.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message));
        }
    };
}