import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getDirectionSuccess = (arrDirection) => {
    return {
        type: actionTypes.GET_DIRECTION_SUCCESS,
        arrDirection: arrDirection
    };
}

export const getDirection = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/direction?reqType=all")
            .then(res => {
                console.log("ACTION : " + res.data);
                dispatch(getDirectionSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    }
}

export const chainageCreateStart = () => {
    return {
        type: actionTypes.CHAINAGE_CREATE_START
    };
};

export const chainageCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(chainageCreateStart());
            const response = await axios.post(BASE_URL + "/api/chainage", formValues);
            console.log("Created")
            dispatch(showToaster('success','Chainage Successfully Created.'))
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const chainageStateReset = () => {
    return {
        type: actionTypes.CHAINAGE_STATE_RESET
    };
};

export const chainageEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        directionId: formValues.directionId,
        directionName: formValues.directionName,
        chainageName: formValues.chainageName,
        chainageAlias: formValues.chainageAlias,
        chainageDescription: formValues.chainageDescription,
        chainageLatitude: formValues.chainageLatitude,
        chainageLongitude: formValues.chainageLongitude,
        chainageMilestone: formValues.chainageMilestone,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(chainageCreateStart());
            const response = await axios.patch(BASE_URL + "/api/chainage/" + formValues.chainageId, values);
            console.log(response);
            dispatch(showToaster('success','Chainage Successfully Edited.'));
        } catch (error) {
            console.log(error)
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}