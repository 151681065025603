import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    loading: null
};

const equipCatStateReset = ( state, action ) => {
    return updateObject( state, { loading: null });
};

const equipCatCreateStart = ( state, action ) => {
    return updateObject( state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EQUIP_CATEGORY_STATE_RESET:
            return equipCatStateReset(state, action);
        case actionTypes.EQUIP_CATEGORY_CREATE_START:
            return equipCatCreateStart(state, action);
        default:
            return state;
    }
};

export default reducer;