import React from 'react';
import LocationTypeForm from './locationTypeForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class LocationTypeCreate extends React.Component {

    componentDidMount() {
        this.props.locationTypeStateReset();
    }

    onSubmit = formValues => {
        this.props.locationTypeCreate(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                { alert}
                { progress}
                <LocationTypeForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.locationTypeReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        locationTypeCreate: (formValues) => dispatch(actions.locationTypeCreate(formValues)),
        locationTypeStateReset: () => dispatch(actions.locationTypeStateReset()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationTypeCreate));