import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
    Checkbox, TextField, Button, FormControl, FormControlLabel,
    Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import axios from 'axios';
import appConfig from '../../../../appConfig.json';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";
// import * as actions from '../../../../store/actions/index';

const BASE_URL = appConfig.BASE_URL;

//#region Styles
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    },
    imageInput: {
        // float: 'right',
        // border: '1px solid #ccc',
        display: 'inline - block',
        padding: '6px 12px',
        cursor: 'pointer',
    }
});
//#endregion

class LocationTypeForm extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "React",
            selectedFile: null,
            strImagePath: "",
            uploadedFileName: '',
            uploadedFileExtension: '',
        };
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        console.log("initial props", this.props.initialValues);

        if (this.props.initialValues.locationTypeImageName && this.props.initialValues.locationTypeImageExtension) {
            this.setState({
                uploadedFileName: this.props.initialValues.locationTypeImageName,
                uploadedFileExtension: this.props.initialValues.locationTypeImageExtension,
            })
        }
    }

    handleChange = (event, input) => {
        event.preventDefault();
        this.setState({
            selectedFile: event.target.files[0]
        })
    };

    renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
        if (input.name == "locationTypeImageUrl") {
            this.setState({ strImagePath: "input.value" });
        }
        return (
            <TextField
                variant="outlined"
                fullWidth
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        )
    }
    //#endregion

    // radio button
    radioIsVisible = ({ input, ...rest }) => (
        <FormControl fullWidth variant="outlined">
            <FormControlLabel
                control={<Checkbox {...input}
                    name="roaduserVisibilityStatus"
                    checked={input.value ? true : false} />}
                label="Is Visible to Road User"
            />
        </FormControl>
    )

    //#region On Change
    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleFileChange = event => {
        console.log("file id: ", event.target.id);
        console.log("file value: ", event.target.files[0]);
        if (event.target.id == "file") {
            this.setState({
                selectedFile: event.target.files[0],
            })
        }
        console.log("file from onChange", this.state.selectedFile);
    }
    //#endregion
    //#region Form Submit
    onSubmit = async (values) => {
        if (this.state.selectedFile) {
            try {
                console.log("Inside TRY OF LOCATIONTYPE")
                const data = new FormData()
                data.append('public', "true")
                data.append('uploadPath', "location")
                data.append('files', this.state.selectedFile);
                var name = this.state.selectedFile.name;
                values['locationTypeImageName'] = name.substr(0, name.indexOf('.'));
                values['locationTypeImageExtension'] = name.substr(name.indexOf('.') + 1, name.length - name.indexOf('.'));
                values['locationTypeImagePath'] = 'uploads/location';
                await axios.post(BASE_URL + "/api/uploads", data, {
                }).then(res => {
                    console.log(res.statusText)
                })
                this.props.onSubmit(values);
            }
            catch (error) {
                console.log("Upload file error", error);
            }
        } else {
            this.props.onSubmit(values);
        }

    }
    //#endregion

    resetForm = () => {
        this.props.reset();
        this.setState({
            selectedFile: null,
            file: null
        });
        this.fileInput.value = null;
    }

    render() {
        let { classes, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let locationTypeImage = null;
        console.log("---------------------------")
        console.log("pristine: ", this.props.pristine);
        console.log("invalid: ", invalid);

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={this.resetForm}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
        }

        if (this.props.type === "Edit") {
            if (this.state.selectedFile) {
                pristine = false;
            }
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            locationTypeImage =
                <React.Fragment>
                    {this.props.uploaded ?
                        <React.Fragment>
                            <p>Previous Image :</p>
                            <img src={this.props.uploaded} width="100" height="100" alt="img" />
                        </React.Fragment>
                        : "Upload an Image: "
                    }
                </React.Fragment>
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} enctype="multipart/form-data">
                    <Card>
                        <CardHeader
                            subheader={this.props.type + " Location Type"}
                            title="Location Type"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Category" id="locationCategoryId">
                                        <option value={""}>{''}</option>
                                        {this.props.arrLocationCatProps.map((userRoleProp) => <option key={userRoleProp.locationCategoryId} value={userRoleProp.locationCategoryId}>{userRoleProp.locationCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationTypeName" component={this.renderTextField} label="Type Name" onChange={this.onChange} id="locationTypeName" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationTypeDescription" id="locationTypeDescription" component={this.renderTextField} label="Type Description" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="roaduserVisibilityStatus" id="roaduserVisibilityStatus" component={this.radioIsVisible} label="Is Visible to Road User" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    {locationTypeImage}
                                    <input type="file" name="file" id="file" ref={(ref) => { this.fileInput = ref }} label="Upload file" onChange={this.handleFileChange} className={classes.imageInput} />
                                </Grid>
                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
    //#endregion
}

//#region Validations
const validate = formValues => {
    const errors = {};

    if (!formValues.locationCategoryId) {
        errors.locationCategoryId = 'Select Category';
    }
    if (formValues.locationCategoryId) {
        if (formValues.locationCategoryId == "") {
            errors.locationCategoryId = 'Select Category';
        }
    }

    if (!formValues.locationTypeName) {
        errors.locationTypeName = 'Enter Type Name';
    } else if (/^[0-9 ]*$/g.test(formValues.locationTypeName)) {
        errors.locationTypeName = 'Type Name should only have alphanumeric, underscores and spaces';
    }

    if (!formValues.locationTypeDescription) {
        errors.locationTypeDescription = 'Enter Type Description';
    } else if (/^[0-9 ]*$/g.test(formValues.locationTypeDescription)) {
        errors.locationTypeDescription = 'Type Description should only have alphanumeric, underscores and spaces';
    }

    if (formValues.locationTypeDescription) {
        if (formValues.locationTypeDescription.length > 200) {
            errors.locationTypeDescription = "Maximum characters should be 200 only";
        }
    }


    return errors;
};
//#endregion

const mapStateToProps = state => {
    return {
        arrLocationCatProps: state.locationTypeReducer.arrLocationCat,
    };
};

LocationTypeForm = connect(
    mapStateToProps,
    null)(LocationTypeForm);

export default reduxForm({
    validate: validate,
    form: 'LocationTypeForm',
    initialValues: { locationCategoryId: "" }
})(withStyles(styles)(LocationTypeForm));