import React from 'react';
import EquipGroupAddForm from './equipGroupAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class EquipGroupEdit extends React.Component {

    componentDidMount() {
        this.props.equipGroupStateReset();
    }

    onSubmit = formValues => {
        this.props.equipGroupEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress =  <div className={classes.spinDiv}>
                            <CircularProgress size={200} thickness={2}/>
                        </div>
        }

        return (
             <React.Fragment>
                 { alert }
                { progress }
                <EquipGroupAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit}/> 
             </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.equipGroupReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        equipGroupEdit: ( formValues ) => dispatch( actions.equipGroupEdit( formValues ) ),
        equipGroupStateReset:  () => dispatch( actions.equipGroupStateReset() ),
    };
};


export default connect( mapStateToProps, mapDispatchToProps )(withStyles(styles)(EquipGroupEdit));