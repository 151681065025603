import React, { Component } from "react";
import axios from "axios";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import commonStyles from "../../../commonStyles";
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => ({
  ...commonStyles(theme),
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});


class SelectRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userRoles: [],

    };
  }
  // getting all roles
  getUserRoles = async () => {
    const END_POINT = BASE_URL + "/api/userRoles?reqType=all";
    axios
      .get(END_POINT)
      .then((res) => {
        const roles = res.data.map((e) => {
          return { 'userRoleId': e['userRoleId'], 'userRoleName': e['userRoleName'] }
        })
        this.setState({ userRoles: [...roles] })
      })
      .catch((e) => {
        console.log("fetching error in user role", e)
        this.props.openSnakbar('Error', 'Error in fetching roles!!!');
      });
  };


  componentDidMount() {
    this.getUserRoles();
  }

  // set selected role
  handleChange = (e) => {
    this.props.setSelectedRoleId(e.target.value)

  }

  // render all roles in dropdown
  getSelectMenu() {
    const { classes } = this.props;
    return (
      <FormControl style={{ marginTop: '0px' }} className={classes.formControl}>
        <Select
          style={{ marginTop: '0px' }}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={this.handleChange}
          value={this.props.selectedRoleId}
        >
          <MenuItem key='empty' disabled value="">Select Role</MenuItem>
          {
            this.state.userRoles.length > 0 && this.state.userRoles.map((e) => {
              return (
                <MenuItem key={e.userRoleId} value={e.userRoleId}>{e.userRoleName}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>

    )
  }

  render() {

    return (
      <form noValidate autoComplete='off' onSubmit={this.onSearchSubmit}>
        <Grid container spacing={3} >
          <Grid item>
            <h3 style={{ marginTop: '0px' }}>
              Select Role
            </h3>
          </Grid>
          <Grid item>
            <div style={{ marginTop: '0px' }}> {this.getSelectMenu()}</div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(SelectRole);;
