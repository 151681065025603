import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import axios from "axios";
import _ from 'lodash';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  marquee: {
    position:'absolute',
    top: theme.spacing(8),
    color:'red',
    fontWeight: 'bold',
    top:'70px',
    backgroundColor:'rgba(255, 255, 0, 0.5)'
  }
});

class WebAlert extends React.Component {
  state = {};
  // getNotificationCategoryId(notificationCategoryName, notificationCategories) {
  //   console.log('getting notification category by name: ', notificationCategoryName)
  //   if (notificationCategories != null) {
  //     var notificationCategory = notificationCategories.find((nCategory) => nCategory.notificationCategoryName == notificationCategoryName);
  //     if (notificationCategory != null) {
  //       console.log('notificationCategory.notificationCategoryId: ', notificationCategory.notificationCategoryId);
  //       return notificationCategory.notificationCategoryId;
  //     }
  //   }
  // }
  // componentDidMount() {
  //   // axios
  //   //   .get(BASE_URL + "/api/notificationCategories?reqType=all")
  //   //   .then(res => {
  //   //     console.log(res.data);
  //   //     let webAlertCategoryId = this.getNotificationCategoryId('WEB ALERT', res.data);
  //   //     this.props.fetchData({eventNotificationCategoryId: webAlertCategoryId});
  //   //   }).catch(error => {
  //   //     // dispatch(getEventGeneralDetailsFail(error.response.data.message));
  //   //   });
  // }
  componentDidMount() {
    this.props.fetchData();
    this.interval = setInterval(() => this.props.fetchData(), 5000);
  }
  shouldComponentUpdate(newProps){
    if(_.isEqual(newProps.recentWebAlerts, this.props.recentWebAlerts)){
      return false;
    }
    return true;
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { classes } = this.props;
    //const items = getItems();
    return (
      <div>
        <marquee className={classes.marquee}>{this.props.recentWebAlerts.join('  |  ')}</marquee>
      
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    recentWebAlerts: state.eventAlerts.recentWebAlerts
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchData: () => dispatch(actions.fetchRecentWebAlerts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(WebAlert)));
