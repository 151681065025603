import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Divider } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';

import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    ...commonStyles(theme),
    button: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: theme.spacing(20)
    },
    div: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px'
    },
    text: {
        color: '#002884',
        float: 'left',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    text2: {
        color: '#002884',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(12)
    },
    button1: {
        float: 'right',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    }
});

class SpeedViolationProcessingChart extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            speedViolationProcessingData: [],
            total: 0,
            selectedButton: '',
            anchorEl: null,
            open: false,
            menuButton: 'Today',
            today: ''
        }
        this.setAnchorEl = this.setAnchorEl.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.handleMenuClose = this.handleMenuClose.bind(this)
    }

    handleButtonClick(event) {
        this.setAnchorEl(event.currentTarget);
        this.setState({
            open: !this.state.open
        })
    }

    setAnchorEl(value) {
        this.setState({
            anchorEl: value,
            open: false
        })
    }

    handleMenuClose() {
        this.setState({
            menuButton: 'Today',
            open: false
        })
        this.setAnchorEl(null);
    }

    async componentDidMount() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;

        console.log("today", today);
        await this.todayButtonClick();
    }

    todayButtonClick = async () => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;

        console.log("today", today);
        
        try {
            const todayData = await axios.get(BASE_URL + "/api/dashboard/speedViolationProcessing-pie?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today);
            if (todayData.data != null) {
                if (todayData.data.message) {
                    this.setState({
                        speedViolationProcessingData: [],
                        total: 0,
                        selectedButton: 'Today',
                        menuButton: 'Today',
                    })
                }
                else {
                    this.setState({
                        speedViolationProcessingData: todayData.data.data,
                        total: todayData.data.total,
                        selectedButton: 'Today',
                        menuButton: 'Today',
                    })
                    console.log("bar data", this.state.speedViolationProcessingData);
                }
            }
            this.setAnchorEl(null);
        }
        catch (error) {
            console.log("error");
        }
    }

    onOneWeekButtonClick = async () => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;

        console.log("today", today);

        var lastWeekDate = new Date();
        lastWeekDate.setDate(lastWeekDate.getDate() - 7);
        let lastWeekDay = lastWeekDate.getDate();
        let lastWeekMonth = lastWeekDate.getMonth() + 1;
        let lastWeekYear = lastWeekDate.getFullYear();

        let lastWeek = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;

        console.log("lastWeek", lastWeek);

        try {
            const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/speedViolationProcessing-pie?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today);
            if (lastWeekData.data != null) {
                if (lastWeekData.data.message) {
                    this.setState({
                        speedViolationProcessingData: [],
                        today: 0,
                        selectedButton: 'Last 7 days',
                        menuButton: 'Last 7 days'
                    })
                }
                else {
                    this.setState({
                        speedViolationProcessingData: lastWeekData.data.data,
                        total: lastWeekData.data.total,
                        selectedButton: 'Last 7 days',
                        menuButton: 'Last 7 days'
                    })
                    console.log("bar data", this.state.speedViolationProcessingData);
                }
            }
            this.setAnchorEl(null);
        }
        catch (err) {
            console.log(err);
        }
    }

    onOneMonthButtonClick = async () => {

        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;

        console.log("today", today);

        var lastMonthDate = new Date();
        lastMonthDate.setDate(lastMonthDate.getDate() - 30);
        let lastMonthDay = lastMonthDate.getDate();
        let lastMonthMonth = lastMonthDate.getMonth() + 1;
        let lastMonthYear = lastMonthDate.getFullYear();

        let lastMonth = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;

        console.log("lastMonth", lastMonth);

        try {
            const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/speedViolationProcessing-pie?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today);
            if (lastMonthData.data != null) {
                if (lastMonthData.data.message) {
                    this.setState({
                        speedViolationProcessingData: [],
                        total: 0,
                        selectedButton: 'Last 30 days',
                        menuButton: 'Last 30 days'
                    })
                }
                else {
                    this.setState({
                        speedViolationProcessingData: lastMonthData.data.data,
                        total: lastMonthData.data.total,
                        selectedButton: 'Last 30 days',
                        menuButton: 'Last 30 days'
                    })
                    console.log("bar data", this.state.speedViolationProcessingData);
                }
            }
            this.setAnchorEl(null);
        }
        catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;

        const CenteredMetric = ({ nodes, centerX, centerY }) => {
            return (
                <text
                    x={centerX}
                    y={centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '14px',
                        fontWeight: 550,
                    }}
                >TOTAL : {this.state.total}
                </text>
            )
        }

        const data = this.state.speedViolationProcessingData.length;

        return (
            <React.Fragment>
                <div className={classes.div}>
                    <Typography className={classes.text} variant="h6" align="left">Speed Violation Audit Status</Typography>

                    <Button className={classes.button1} size="small" variant="contained" color="primary" onClick={this.handleButtonClick}>
                        {this.state.menuButton}
                    </Button>
                    <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
                        <MenuItem onClick={() => this.todayButtonClick(this.state.eventTypeId)}>Today</MenuItem>
                        <MenuItem onClick={() => this.onOneWeekButtonClick(this.state.eventTypeId)}>Last 7 days</MenuItem>
                        <MenuItem onClick={() => this.onOneMonthButtonClick(this.state.eventTypeId)}>Last 30 days</MenuItem>
                    </Menu>
                </div>
                <Divider />
                {data && data !== 0 ?
                    (

                        <ResponsivePie
                            data={this.state.speedViolationProcessingData}
                            radialLabelsTextColor="#333333"
                            margin={{ top: 20, right: 50, bottom: 90, left: 120 }}
                            colors={{ scheme: 'nivo' }}
                            innerRadius={0.6}
                            enableSliceLabels={true}
                            layers={['slices', 'sliceLabels', 'legends', CenteredMetric]}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    justify: false,
                                    translateX: -100,
                                    translateY: -10,
                                    itemsSpacing: 10,
                                    itemWidth: 70,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />

                        // <ResponsiveBar
                        //     data={this.state.speedViolationProcessingData}
                        //     keys={['value']}
                        //     indexBy="id"
                        //     margin={{ top: 10, right: 10, bottom: 80, left: 40 }}
                        //     padding={0.3}
                        //     valueScale={{ type: 'linear' }}
                        //     enableGridY={false}
                        //     indexScale={{ type: 'band', round: true }}
                        //     colors={{ scheme: 'nivo' }}
                        //     markers={[
                        //         {
                        //             axis: 'y',
                        //             value: 0,
                        //             lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
                        //             legendOrientation: 'vertical',
                        //         },
                        //     ]}
                        //     // colors={{ datum: 'data.color' }}

                        //     borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        //     axisTop={null}
                        //     axisRight={null}
                        //     axisLeft={{
                        //         tickSize: 2,
                        //         tickPadding: 3,
                        //         tickRotation: 0,
                        //         format: e => Math.floor(e) === e && e,
                        //         legend: 'count',
                        //         legendPosition: 'middle',
                        //         legendOffset: -30
                        //     }}
                        //     // labelSkipWidth={12}
                        //     // labelSkipHeight={12}
                        //     labelTextColor="#ffffff"
                        //     // legends={[
                        //     //     {
                        //     //         dataFrom: 'keys',
                        //     //         anchor: 'bottom-right',
                        //     //         direction: 'column',
                        //     //         justify: false,
                        //     //         translateX: 120,
                        //     //         translateY: 0,
                        //     //         itemsSpacing: 2,
                        //     //         itemWidth: 100,
                        //     //         itemHeight: 20,
                        //     //         itemDirection: 'left-to-right',
                        //     //         itemOpacity: 0.85,
                        //     //         symbolSize: 20,
                        //     //         effects: [
                        //     //             {
                        //     //                 on: 'hover',
                        //     //                 style: {
                        //     //                     itemOpacity: 1
                        //     //                 }
                        //     //             }
                        //     //         ]
                        //     //     }
                        //     // ]}
                        //     animate={true}
                        //     motionStiffness={90}
                        //     motionDamping={15}
                        // />

                    ) :
                    (
                        <Typography className={classes.text2} variant="h6" align="center">No Data Available For {this.state.selectedButton}</Typography>
                    )}




            </React.Fragment>
        )

    }


}

export default withStyles(styles)(SpeedViolationProcessingChart)