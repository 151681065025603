import axios from "axios";
import * as actionTypes from '../actionTypes';

import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;


export const updateDirection = (directions) => {
    return {
        type : actionTypes.UPDATE_DIRECTION_AV,
        directions : directions,
    };
};

export const getDirections = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/direction?reqType=all")
        .then(res => {    
            dispatch(updateDirection(res.data));
        }).catch(error => {
            // dispatch(loadFailure(error.response.data.message));
        });
    };
}

export const updateChainage = (chainages) => {
    return {
        type : actionTypes.UPDATE_CHAINAGE_AV,
        chainages : chainages,
    };
};

export const getChainages = (dirId) => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/chainage?reqType=all&directionId=" + dirId)
        .then(res => {    
            dispatch(updateChainage(res.data));
        }).catch(error => {
            // dispatch(loadFailure(error.response.data.message));
        });
    };
}

export const updateVehicleClass = (vehicleClasses) => {
    return {
        type: actionTypes.UPDATE_VEHICLECLASS_AV,
        vehicleClasses : vehicleClasses
    }
}

export const getVehicleClass = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/vehicleClasses?reqType=all")
        .then(res => {    
            dispatch(updateVehicleClass(res.data));
        }).catch(error => {
            // dispatch(loadFailure(error.response.data.message));
        });
    };
}


export const updateSections = (sections) => {
    return {
        type: actionTypes.UPDATE_SECTIONS_AV,
        sections : sections
    }
}

export const getSections = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/sections?reqType=all")
        .then(res => {
            dispatch(updateSections(res.data));
        }).catch(error => {
             // dispatch(loadFailure(error.response.data.message));
        });  
    };
}


export const updateViolationStatus = (violationStatus) => {
    return {
        type: actionTypes.UPDATE_VIOLATION_STATUS_AV,
        violationStatus : violationStatus
    }
}

export const getViolationStatus = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/speedViolationStatuses?reqType=all&reqSource=challan")
        .then(res => {
            dispatch(updateViolationStatus(res.data));
        }).catch(error => {
             // dispatch(loadFailure(error.response.data.message));
        });  
    };
}


//#region ADD SUCCESS
const violationAddSuccess = (successMsgCrt) => {
    return {
        type : actionTypes.LOCATION_CATEGORY_CREATED,
        successMsgCrt : successMsgCrt
    };
}
//#endregion

//#region FAILURE
const violationFailure = (errorMsgCrt) => {
    return {
        type : actionTypes.LOCATION_CATEGORY_FAILURE,
        errorMsgCrt : errorMsgCrt
    };
}
//#endregion

//#region MODAL
export const violationModalOpen = (data) => {
    return {
        type: actionTypes.LOCATION_CATEGORY_MODAL_OPEN,
        data: data
    }
}
//#endregion

//#region START
const violationCreateStart = () => {
    return {
        type : actionTypes.LOCATION_CATEGORY_CREATE_START
    };
};
//#endregion

//#region  ADD
// export const violationCreate = (formValues) => {
//     return async (dispatch) => {
//         try {
//             dispatch(violationCreateStart());
//             const response = await axios.post(BASE_URL + "/api/violationegories", formValues);
//             dispatch(violationAddSuccess("Location Category Successfully Created."));
//         } catch (err) {
//             dispatch(violationFailure("Location Category Creation Failed."));
//         }
//     };
// }
//#endregion

//#region STATE
export const violationStateReset = () => {
    return {
        type: actionTypes.LOCATION_CATEGORY_STATE_RESET
    }
}
//#endregion

//#region EDIT
// export const violationEdit = (formValues) => {
//     console.log("Edit: "+ JSON.stringify(formValues));
//     return async (dispatch) => {
//         try {
//             dispatch(violationCreateStart());
//             const response = await axios.patch(BASE_URL + "/api/violationegories/" + formValues.violationegoryId, formValues);
//             console.log(response);
//             dispatch(violationAddSuccess("Location Category Successfully Edited."));
//         } catch (err) {
//             console.log(err);
//             dispatch(violationFailure("Location Category Edition Failed."));
//         }
//     };
// }
//#endregion
