import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

// random please delete me

const initialState = {
    strAddEquipments: '',
    arrEquipmentsCat: [],
    arrEquipmentsType: [],
    arrEquipmentsGroup: [],
    arrEquipmentsDir: [],
    arrEquipmentsChainage: [],
    loading: null
};

const equipmentsStateReset = (state, action) => {
    return updateObject(state, {
        loading: null,
        strAddEquipments: '',
        arrEquipmentsCat: [],
        arrEquipmentsType: [],
        arrEquipmentsGroup: [],
        arrEquipmentsDir: [],
        arrEquipmentsChainage: [],
    });
};

const equipmentsCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const equipmentsCreated = (state, action) => {
    return updateObject(state, { loading: false });
};

const getEquipmentsCatSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrEquipmentsCat: action.arrEquipmentsCat
    });
    return updatedState;
};

const getEquipmentsTypeSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrEquipmentsType: action.arrEquipmentsType
    });
    return updatedState;
};

const getEquipmentsGroupSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrEquipmentsGroup: action.arrEquipmentsGroup
    });
    return updatedState;
};

const getEquipmentsDirSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrEquipmentsDir: action.arrEquipmentsDir
    });
    return updatedState;
};

const getEquipmentsChainageSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrEquipmentsChainage: action.arrEquipmentsChainage
    });
    return updatedState;
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EQUIPMENTS_STATE_RESET:
            return equipmentsStateReset(state, action);
        case actionTypes.EQUIPMENTS_CREATE_START:
            return equipmentsCreateStart(state, action);
        case actionTypes.EQUIPMENTS_CREATED:
            return equipmentsCreated(state, action);
        case actionTypes.GET_EQUIPMENTS_CAT_SUCCESS:
            return getEquipmentsCatSuccess(state, action);
        case actionTypes.GET_EQUIPMENTS_TYPE_SUCCESS:
            return getEquipmentsTypeSuccess(state, action);
        case actionTypes.GET_EQUIPMENTS_GROUP_SUCCESS:
            return getEquipmentsGroupSuccess(state, action);
        case actionTypes.GET_EQUIPMENTS_DIR_SUCCESS:
            return getEquipmentsDirSuccess(state, action);
        case actionTypes.GET_EQUIPMENTS_CHAINAGE_SUCCESS:
            return getEquipmentsChainageSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;