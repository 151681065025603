import React from 'react';
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuAddForm from './menuAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class MenuEdit extends React.Component {
    componentDidMount() {
        this.props.menuStateReset();
    }

    onSubmit = formValues => {
        this.props.menuEdit(formValues);
        this.props.onClose();
    }
    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        console.log("Initial Props", this.props.initialValues);

        return (
            <React.Fragment>
                {alert}
                {progress}
                <MenuAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.menuReducer.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        menuEdit: (formValues) => dispatch(actions.menuEdit(formValues)),
        menuStateReset: () => dispatch(actions.menuStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuEdit));