import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getLocationCatSuccess = (locationCat) => {
    console.log("IN SUCCESS");
    return {
        type: actionTypes.GET_LOCATION_CAT_SUCCESS,
        locationCat: locationCat,
    };
};

export const getImageSuccess = (strImagePath) => {
    console.log("IN SUCCESS");
    return {
        type: actionTypes.GET_IMAGE_PATH_SUCCESS,
        strImagePath: strImagePath,
    };
};

export const getLocationCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/locationCategories?reqType=all")
            .then(res => {
                console.log("ACTION :" + res.data);
                dispatch(getLocationCatSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}


const locationTypeCreateStart = () => {
    return {
        type: actionTypes.LOCATION_TYPE_CREATE_START
    };
};

export const locationTypeCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(locationTypeCreateStart());
            const response = await axios.post(BASE_URL + "/api/locationTypes", formValues);
            console.log(response);
            dispatch(showToaster('success', 'Location Type Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}

export const locationTypeStateReset = () => {
    return {
        type: actionTypes.LOCATION_TYPE_STATE_RESET
    }
}

export const locationTypeEdit = (formValues) => {
    console.log("Final:" + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }

    let values = {
        locationCategoryId: formValues.locationCategoryId.toString(),
        locationTypeName: formValues.locationTypeName,
        locationTypeDescription: formValues.locationTypeDescription,
        roaduserVisibilityStatus: formValues.roaduserVisibilityStatus,
        locationTypeImageName: formValues.locationTypeImageName,
        locationTypeImageExtension: formValues.locationTypeImageExtension,
        locationTypeImagePath: formValues.locationTypeImagePath,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(locationTypeCreateStart());
            const response = await axios.patch(BASE_URL + "/api/locationTypes/" + formValues.locationTypeId, values);
            console.log("SSS:" + JSON.stringify(response));
            dispatch(showToaster('success', 'Location Type Successfully Edited.'));
        } catch (error) {
            console.log("WWW: " + error);
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}
