import React from 'react';
import SOPCatAddForm from './SOPCatAddForm';
import * as actions from '../../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class SOPCatEdit extends React.Component {
    
    componentDidMount() {
        this.props.sopCatStateReset();
    }

    onSubmit = formValues => {
        this.props.sopCatEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        return (
            <React.Fragment>
                {alert}
                {progress}
                <SOPCatAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.sopCatReducer.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sopCatEdit: (formValues) => dispatch(actions.sopCatEdit(formValues)),
        sopCatStateReset: () => dispatch(actions.sopCatStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SOPCatEdit))