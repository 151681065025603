import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Divider } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  button: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(20)
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px'
  },
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.1)
  },
  text2: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(12)
  },
  button1: {
    float: 'right',
    marginTop: theme.spacing(0.1),
    marginRight: theme.spacing(2)
  }
});

class EmergencyCallBoxChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      total: 0,
      selectedButton: '',
      anchorEl: null,
      open: false,
      menuButton: 'Today'
    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
  }

  handleButtonClick(event) {
    this.setAnchorEl(event.currentTarget);
    this.setState({
      open: !this.state.open
    })
  }

  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: false
    })
  }

  handleMenuClose() {
    this.setState({
      menuButton: 'Today',
      open: false
    })
    this.setAnchorEl(null);
  }

  async componentDidMount() {

    await this.todayButtonClick();
  }

  todayButtonClick = async () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today", today);

    try {
      const todayData = await axios.get(BASE_URL + "/api/dashboard/ecb-pie?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today);
      if (todayData.data != null) {
        if (todayData.data.message) {
          this.setState({
            data: [],
            total: 0,
            selectedButton: 'Today',
            menuButton: 'Today'
          })
        }
        else {
          this.setState({
            data: todayData.data.data,
            total: todayData.data.total,
            selectedButton: 'Today',
            menuButton: 'Today'
          })
          console.log("today data", todayData.data);
          console.log("data", this.state.data);
          console.log("total", this.state.total);
        }
      }
      this.setAnchorEl(null);
    }
    catch (error) {
      console.log("error");
    }
  }

  onOneWeekButtonClick = async () => {

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today", today);

    var lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);
    let lastWeekDay = lastWeekDate.getDate();
    let lastWeekMonth = lastWeekDate.getMonth() + 1;
    let lastWeekYear = lastWeekDate.getFullYear();

    let lastWeek = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;

    console.log("lastWeek", lastWeek);

    try {
      const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/ecb-pie?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today);
      if (lastWeekData.data != null) {
        if (lastWeekData.data.message) {
          this.setState({
            data: [],
            total: 0,
            selectedButton: 'Last 7 days',
            menuButton: 'Last 7 days'
          })
        }
        else {
          this.setState({
            data: lastWeekData.data.data,
            total: lastWeekData.data.total,
            selectedButton: 'Last 7 days',
            menuButton: 'Last 7 days'
          })
          console.log("lastWeekData data", lastWeekData.data);
          console.log("data", this.state.data);
          console.log("total", this.state.total);
        }
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  onOneMonthButtonClick = async () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today", today);

    var lastMonthDate = new Date();
    lastMonthDate.setDate(lastMonthDate.getDate() - 30);
    let lastMonthDay = lastMonthDate.getDate();
    let lastMonthMonth = lastMonthDate.getMonth() + 1;
    let lastMonthYear = lastMonthDate.getFullYear();

    let lastMonth = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;

    console.log("lastMonth", lastMonth);

    try {
      const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/ecb-pie?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today);
      if (lastMonthData.data != null) {
        if (lastMonthData.data.message) {
          this.setState({
            data: [],
            total: 0,
            selectedButton: 'Last 30 days',
            menuButton: 'Last 30 days'
          })
        }
        else {
          this.setState({
            data: lastMonthData.data.data,
            total: lastMonthData.data.total,
            selectedButton: 'Last 30 days',
            menuButton: 'Last 30 days'
          })
          console.log("lastMonthData data", lastMonthData.data);
          console.log("pie data", this.state.data);
          console.log("total", this.state.total);
        }
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    const { classes } = this.props;
    console.log("selectedButton: ", this.state.selectedButton)

    const CenteredMetric = ({ nodes, centerX, centerY }) => {
      return (
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 550,
          }}
        >TOTAL : {this.state.total}
        </text>
      )
    }

    const data = this.state.data.length;

    return (
      <React.Fragment>
        <div className={classes.div}>
          <Typography className={classes.text} variant="h6" align="left">Emergency Call Box</Typography>
          <Button className={classes.button1} size="small" variant="contained" color="primary" onClick={this.handleButtonClick}>
            {this.state.menuButton}
          </Button>

          <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
            <MenuItem onClick={() => this.todayButtonClick(this.state.eventTypeId)}>Today</MenuItem>
            <MenuItem onClick={() => this.onOneWeekButtonClick(this.state.eventTypeId)}>Last 7 days</MenuItem>
            <MenuItem onClick={() => this.onOneMonthButtonClick(this.state.eventTypeId)}>Last 30 days</MenuItem>
          </Menu>

        </div>
        <Divider />
        {data && data !== 0 ?
          (
            <ResponsivePie
              data={this.state.data}
              margin={{ top: 20, right: 50, bottom: 90, left: 90 }}
              // colors={{ datum: 'data.color' }}
              innerRadius={0.6}
              enableSliceLabels={true}
              layers={['slices', 'sliceLabels', 'legends', CenteredMetric]}
              legends={[
                {
                  anchor: 'top-left',
                  direction: 'column',
                  justify: false,
                  translateX: -70,
                  translateY: -10,
                  itemsSpacing: 10,
                  itemWidth: 70,
                  itemHeight: 20,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          ) :
          (
            <Typography className={classes.text2} variant="h6" align="center">No Data Available For {this.state.selectedButton}</Typography>
          )}

      </React.Fragment>
    )
  }
}

export default withStyles(styles)(EmergencyCallBoxChart)