import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    ArrEventType: [],
    ArrEventSubtype: [],
    ArrThreshold: [],
    ArrArithmeticOperation: [],
    loading: null
};

const getEventTypesForEventRuleSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrEventType: action.arrEventType
    });
    return updatedState;
}
const getEventSubtypeForEventRuleSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrEventSubtype: action.arrEventSubtype
    });
    return updatedState;
}
const getThresholdForEventRuleSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrThreshold: action.arrThreshold
    });
    return updatedState;
}
const getArithmeticOperationForEventRuleSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrArithmeticOperation: action.arrArithmeticOperation
    });
    return updatedState;
}

const eventRuleStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};
const eventRuleCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVENT_TYPES_FOR_EVENT_RULE_SUCCESS:
            return getEventTypesForEventRuleSuccess(state, action)

        case actionTypes.GET_EVENT_SUBTYPE_FOR_EVENT_RULE_SUCCESS:
            return getEventSubtypeForEventRuleSuccess(state, action)

        case actionTypes.GET_THRESHOLD_FOR_EVENT_RULE_SUCCESS:
            return getThresholdForEventRuleSuccess(state, action)

        case actionTypes.GET_ARITHMETIC_OPERATION_FOR_EVENT_RULE_SUCCESS:
            return getArithmeticOperationForEventRuleSuccess(state, action)
      
        case actionTypes.EVENT_RULE_CREATE_START:
            return eventRuleCreateStart(state, action);

        case actionTypes.EVENT_RULE_STATE_RESET:
            return eventRuleStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;