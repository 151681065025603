import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const sopCatCreateStart = () => {
    return {
        type: actionTypes.SOP_CAT_CREATE_START
    };
};

export const sopCatCreate = (formValues) => {
    console.log("Add: "+ JSON.stringify(formValues));
    return async (dispatch) => {
        try{
            dispatch(sopCatCreateStart());
            const response = await axios.post(BASE_URL + "/api/sopCategories",formValues)
            dispatch(showToaster('success','SOP Category Created Successfully'));
        }catch(error){
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const sopCatStateReset = () => {
    return {
        type: actionTypes.SOP_CAT_STATE_RESET
    };
};

export const sopCatEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        sopCategoryName: formValues.sopCategoryName,
        sopCategoryDescription: formValues.sopCategoryDescription,
        isEnabled : finalIsEnabled
    }
    return async (dispatch) => {
        try{
            dispatch(sopCatCreateStart());
            const response = await axios.patch(BASE_URL + "/api/sopCategories/"+ formValues.sopCategoryId,values);
            dispatch(showToaster('success','SOP Category Edited Successfully'));
        }catch(error){
            dispatch(showToaster('error',error.response.data.message))
        }
    }
}
