import { updateObject } from "../utility";

const initialState = {
    columns: [],
    rows: [],
};

const fetchGridDataSuccess = ( state, action ) => {
    var updatedState= updateObject( state, {
        columns : action.gridData.data.columns,
        rows : {
            data:action.gridData.data.rows, 
            totalCount: action.gridData.total, 
            page: (action.gridData.skip/action.gridData.limit)+1
        },
    });
    return updatedState;
};
const fetchGridDataFail = ( state, action ) => {
    return updateObject( state, {
        errorMsg : action.errorMsg
    });
};
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'EVENT_GRID_DATA_FETCH_SUCCESS':
            return fetchGridDataSuccess(state, action);
            break;
        case 'EVENT_GRID_DATA_FETCH_FAIL':
            return fetchGridDataFail(state, action);
        default:
            return state;
    }
};

export default reducer;