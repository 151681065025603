import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    notificationSource: [],
    eventStatus: [],
    eventType: [],
    eventSeverity: [],
    equipments: [],
    eventSubType: [],
    eventReason:[],
    errorMsg: null,
    loading: false,
};

const loadingStart = ( state, action ) => {
    return updateObject( state, { loading: true });
};

const notificationSourceSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        notificationSource : action.notificationSource
    });
    return updatedState;
};

const eventGeneralDetailsFail = ( state, action ) => {
    var updatedState = updateObject( state, {
        errorMsg : action.errorMsg
    });
    return updatedState;
};

const eventStatusSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventStatus : action.eventStatus
    });
    return updatedState;
}

const eventTypeSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventType : action.eventType
    });
    return updatedState;
}

const eventSeveritySuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventSeverity : action.eventSeverity
    });
    return updatedState;
}

const getEquipmentSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        equipments : action.equipments
    });
    return updatedState;
}

const getEventSubTypeSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventSubType : action.eventSubType,
        loading: false
    });
    return updatedState;
}

const getEventReasonSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventReason : action.eventReason
    });
    return updatedState;
}

const getStakeholderUserSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        stakeholderUsers : action.stakeholderUsers,
          
    });
    return updatedState;
}

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.LOADING_START:
            return loadingStart(state, action);
        case actionTypes.GET_NOTIFICATION_SOURCE_SUCCESS:
            return notificationSourceSuccess(state, action);
        case actionTypes.GET_EVENT_STATUS_SUCCESS:
            return eventStatusSuccess(state, action);
        case actionTypes.GET_EVENT_TYPE_SUCCESS:
            return eventTypeSuccess(state, action);
        case actionTypes.GET_EVENT_SEVERITY_SUCCESS:
            return eventSeveritySuccess(state, action);
        case actionTypes.GET_EQUIPMENTS_SUCCESS:
            return getEquipmentSuccess(state, action);
        case actionTypes.GET_EVENTSUBTYPE_SUCESS:
            return getEventSubTypeSuccess(state, action);
        case actionTypes.GET_EVENTREASON_SUCCESS:
            return getEventReasonSuccess(state, action);
        case actionTypes.GET_STAKEHOLDERUSER_SUCCESS:   
            return getStakeholderUserSuccess(state, action);
        case actionTypes.GET_EVENT_GENERAL_DETAILS_FAIL:
            return eventGeneralDetailsFail(state, action);
        default:
            return state;
    }
}

export default reducer;