import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';


const BASE_URL = appConfig.BASE_URL;


export const getEcbTypesSuccess = (ecbTypes) => {
    return {
        type: actionTypes.GET_ECB_TYPE_SUCCESS,
        ecbTypes: ecbTypes
    }
}

export const getEcbTypes = () => {
    return async dispatch => {
        try {
            const res = await axios.get(BASE_URL + "/api/equipmentCategories?equipmentCategoryName=ECB")
            let ec = res.data.data[0].equipmentCategoryId;

            const ecbTypes = await axios.get(`${BASE_URL}/api/equipmentTypes?equipmentCategoryId=${ec}&reqType=all`);
            dispatch(getEcbTypesSuccess(ecbTypes.data))
        } catch (error) {
            console.log("error", error);
        }
    }
}

export const getEcbCallTypesSuccess = (callTypes) => {
    return {
        type: actionTypes.GET_ECB_CALL_TYPE_SUCCESS,
        callTypes: callTypes
    }
}

export const getEcbCallTypes = () => {
    return async dispatch => {
        try {
            const callTypes = await axios.get(`${BASE_URL}/api/ecbCallTypes?reqType=all`);
            dispatch(getEcbCallTypesSuccess(callTypes.data))
        } catch (error) {
            console.log("error in calltypes", error);
        }
    }
}

export const getEcbCallStatusesSuccess = (callStatuses) => {
    return {
        type: actionTypes.GET_ECB_CALL_STATUS_SUCCESS,
        callStatuses: callStatuses
    }
}

export const getEcbCallStatuses = () => {
    return async dispatch => {
        try {
            const callStatuses = await axios.get(`${BASE_URL}/api/ecbCallStatuses?reqType=all`);
            dispatch(getEcbCallStatusesSuccess(callStatuses.data))
        } catch (error) {
            console.log("error", error);
        }
    }
}

export const getEcbNamesSuccess = (equipmentNames) => {
    return {
        type: actionTypes.GET_ECB_NAME_SUCCESS,
        equipmentNames: equipmentNames
    }
}

export const getEcbNames = (ecbTypeId) => {
    return async dispatch => {
        try {
            const res = await axios.get(`${BASE_URL}/api/equipments?equipmentTypeId=${ecbTypeId}`);
            let equipmentNames = res.data.data;

            dispatch(getEcbNamesSuccess(equipmentNames))
        }
        catch (error) {
            console.log("error", error)
        }
    }
}

export const getDirectionSuccess = (directions) => {
    return {
        type: actionTypes.ECB_DIRECTION_SUCCESS,
        directions: directions
    }
}

export const getDirectionName = (directionId) => {
    return async dispatch => {
        try {
            const res = await axios.get(`${BASE_URL}/api/direction?directionId=${directionId}&reqType=all`);
            // console.log("direction action res.data:", res.data)
            dispatch(getDirectionSuccess(res.data))
        } catch (error) {
            console.log(error)
        }
    }
}


export const getChainageSuccess = (chainages) => {
    return {
        type: actionTypes.ECB_CHAINAGE_SUCCESS,
        chainages: chainages
    }
}

export const getEcbChainage = (directionId) => {
    return async dispatch => {
        try {
            const res = await axios.get(`${BASE_URL}/api/chainage?reqType=all&directionId=${directionId}`)
            dispatch(getChainageSuccess(res.data))
        } catch (error) {
            console.log("error", error)
        }
    }
}
export const ecbEditSuccess = (eventId) => {
    return {
        type: actionTypes.ECB_EDIT_SUCCESS,
        eventId: eventId
    }
}

export const ecbEdit = (formValues, ecbState) => {
    console.log("formValues: ", formValues)
    // let equipmentId = formValues.equipment.equipmentId
    let isEvent = null;
    if (formValues.isEvent === true || formValues.isEvent === 1) {
        isEvent = true;
    }
    else if (formValues.isEvent === false || formValues.isEvent === 0) {
        isEvent = false;
    }

    let values = {
        ecbCallAudioFilename: formValues.ecbCallAudioFilename,
        ecbCallAudioExtension: formValues.ecbCallAudioExtension,
        ecbCallAudioPath: formValues.ecbCallAudioPath,
        ecbCallReceiveDatetime: formValues.ReceiveDateTime,
        ecbCallTerminationDatetime: formValues.TerminateDateTime,
        ecbCallTypeId: ecbState.ecbCallTypeId,
        eventTypeId: formValues.eventTypeId,
        eventSubtypeId: formValues.eventSubtypeId,
        eventDescription: formValues.eventDescription,
        callerName: formValues.callerName,
        callerContact: formValues.callerContact,
        ecbCallInfoDescription: formValues.ecbCallInfoDescription,
        isEvent: isEvent,
    };

    ["equipmentId", "latitude", "longitude", "directionName", "chainageId", "chainageName", "equipmentTypeId"].map(key => values[key] = formValues?.equipment?.[key] || null)

    return async (dispatch) => {
        try {
            const res = await axios.patch(BASE_URL + "/api/ecbCalls/" + formValues.txnEcbCallInfoId, values);
            const eventId = res.data.txnEventId;

            dispatch(showToaster('success', 'Ecb Successfully Patched.'));
            dispatch(ecbEditSuccess(eventId));

        } catch (error) {
            console.log("patch error: ", error);
            dispatch(showToaster('error', error.response.data.message));
        }
    }
}

export const ecbCreateSuccess = (eventId) => {
    return {
        type: actionTypes.ECB_CREATE_SUCCESS,
        eventId: eventId
    }
}

export const ecbCreate = (formValues, ecbState) => {

    let isEvent = null;
    let eventTypeId = null
    let eventDescription = null
    let eventSubtypeId = null

    if (formValues.isEvent === true || formValues.isEvent === 1) {
        isEvent = true
        eventTypeId = formValues.eventTypeId
        eventDescription = formValues.eventDescription
        eventSubtypeId = formValues.eventSubtypeId
    }

    const values = {
        ecbCallAudioFilename: formValues.ecbCallAudioFilename,
        ecbCallAudioExtension: formValues.ecbCallAudioExtension,
        ecbCallAudioPath: formValues.ecbCallAudioPath,
        ecbCallReceiveDatetime: formValues.ReceiveDateTime,
        ecbCallTerminationDatetime: formValues.TerminateDateTime,
        ecbCallTypeId: ecbState.ecbCallTypeId,
        equipmentId: formValues.equipmentId,
        equipmentTypeId: formValues.equipmentTypeId,
        eventTypeId: eventTypeId,
        eventDescription: eventDescription,
        eventSubtypeId: eventSubtypeId,
        callerName: formValues.callerName,
        callerContact: formValues.callerContact,
        ecbCallInfoDescription: formValues.ecbCallInfoDescription,
        directionName: ecbState.directionName,
        chainageId: ecbState.chainageId,
        chainageName: ecbState.chainageName,
        isEcbCallAutomatic: 1,
        isEvent: isEvent
    }
    return async (dispatch) => {
        try {
            const res = await axios.post(BASE_URL + "/api/ecbCalls/", values)
            const eventId = res.data.txnEventId;
            dispatch(showToaster('success', 'New Call Added Successfully.'));
            dispatch(ecbCreateSuccess(eventId));

        } catch (error) {
            console.log("post error", error)
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}

export const ecbStateReset = () => {
    return {
        type: actionTypes.ECB_STATE_RESET
    };
};