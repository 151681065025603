import React from 'react';
import { connect } from "react-redux";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    iconName: {
        display: 'block',
        margin: '12% auto',
        border: 'solid 1px',
        padding: '2%',
        borderRadius: '50%',
        fontSize: '3.2vw',
    },
    subheader:{
        padding:'2%',
    },
    marquee: {
        color:'red',
        fontWeight: 'bold'
      }
});
class PTZDetails extends React.Component {
    
 

    render() {
        const { classes, selectedEquipment } = this.props;
        const headerTitle = selectedEquipment.equipmentName + ' | ' + this.props.selectedEquipment.chainageName + ' | ' + this.props.selectedEquipment.directionName ;
        console.log("selectedEquipment in the ECB Modal is", selectedEquipment);
        let alert;
        if(selectedEquipment.isFaulty === 1){
        alert =   
                <div className={classes.marquee}>
                    These equipment is under Maintaince
                </div> 
        }
        return (
             <React.Fragment>
                 <Container maxWidth="md" className={classes.container}>
                 <Card>
                            <CardHeader
                                title= {headerTitle}
                                subheader = {alert}
                                action={
                                    <IconButton onClick={() => { this.props.onClose() }}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                }
                            />
                            <Divider />
                            <CardContent>
                            <Grid container spacing={1}> 
                                <Grid item xs={8} lg={8} md={8}>
                                    <Grid container spacing={1} >
                                        <Typography variant="h1" gutterBottom className={classes.header}>
                                                Videos
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={1} >
                                        <Container maxWidth="lg" className={classes.container}>
                                            
                                            <iframe width="800" height="400"  src="https://www.youtube.com/embed/EEIk7gwjgIM?autoplay=1" title={selectedEquipment.equipmentName} frameBorder="0"  allowFullScreen></iframe>
                                        </Container>
                                    </Grid> 
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}> 
                                    <Grid container spacing={1} >
                                        <Typography variant="h1" gutterBottom className={classes.header}>
                                                Controller
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={1} >
                                        <Typography variant="h5" gutterBottom className={classes.subheader}>
                                               Direction
                                        </Typography>
                                    </Grid>
                                    <Divider variant="list" className={classes.atmsPageSectionDivider} />
                                    <Grid container spacing={1} >
                                        <Grid item xs={4}  ></Grid>
                                        <Grid item xs={4} >
                                            <ArrowUpwardIcon className={classes.iconName}></ArrowUpwardIcon>
                                        </Grid>
                                        <Grid item xs={4} ></Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} ><ArrowBackIcon className={classes.iconName}></ArrowBackIcon></Grid>
                                        <Grid item xs={4} ><HomeIcon className={classes.iconName}></HomeIcon></Grid>
                                        <Grid item xs={4} ><ArrowForwardIcon className={classes.iconName}></ArrowForwardIcon></Grid>
                                    </Grid> 
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} ></Grid>
                                        <Grid item xs={4} >
                                            <ArrowDownwardIcon className={classes.iconName}></ArrowDownwardIcon>
                                        </Grid>
                                        <Grid item xs={4} ></Grid>
                                    </Grid>  
                                    <Grid container spacing={1} >
                                        <Typography variant="h5" gutterBottom className={classes.subheader}>
                                               Zoom
                                        </Typography>
                                    </Grid>
                                    <Divider variant="list" className={classes.atmsPageSectionDivider} />
                                    <Grid container spacing={1} >
                                        <Grid item xs={6} lg={6} md={6}>
                                            <RemoveIcon className={classes.iconName}></RemoveIcon>
                                        </Grid>
                                       
                                        <Grid item xs={6} lg={6} md={6}>
                                            <AddIcon className={classes.iconName}></AddIcon>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </CardContent>
                            <Divider />
                        </Card>
                 </Container>
             </React.Fragment>
        );
    }
}



export default connect( null, null )(withStyles(styles)(PTZDetails));