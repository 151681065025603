import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getNotificationCatSuccess = (arrNotificationCat) => {
    return {
        type: actionTypes.GET_NOTIFICATION_CAT_SUCCESS,
        arrNotificationCat: arrNotificationCat
    };
};

export const getNotificationCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/notificationCategories?reqType=all")
            .then(res => {
                console.log("ACTION : " + res.data);
                dispatch(getNotificationCatSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const notificationTemplateCreateStart = () => {
    return {
        type: actionTypes.NOTIFICATION_TEMPLATE_CREATE_START
    };
};

export const notificationTemplateCreate = (formValues) => {
    console.log("Add: " + JSON.stringify(formValues));
    return async (dispatch) => {
        try {
            dispatch(notificationTemplateCreateStart());
            const response = await axios.post(BASE_URL + "/api/notificationTemplates", formValues);
            dispatch(showToaster('success','Notification Template Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const notificationTemplateStateReset = () => {
    return {
        type: actionTypes.NOTIFICATION_TEMPLATE_STATE_RESET
    };
};

export const notificationTemplateEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        notificationCategoryId: formValues.notificationCategoryId,
        notificationTemplateName: formValues.notificationTemplateName,
        notificationTemplateDescription: formValues.notificationTemplateDescription,
        notificationTemplateMessageSubject: formValues.notificationTemplateMessageSubject,
        notificationTemplateMessageHeader: formValues.notificationTemplateMessageHeader,
        notificationTemplateMessage: formValues.notificationTemplateMessage,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(notificationTemplateCreateStart());
            const response = await axios.patch(BASE_URL + "/api/notificationTemplates/" + formValues.notificationTemplateId, values);
            dispatch(showToaster('success','Notification Template Successfully Edited.'));
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    }
}