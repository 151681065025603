import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import axios from "axios";
import Dashboard from '../Navigation/Dashboard';
import {showToaster} from '../../store/actions/common';
import appConfig from '../../appConfig.json';
import * as actions from '../../store/actions/index';
import setAuthToken from "../../utils/setAuthToken";
import BackdropLoading from '../../components/common/BackdropLoading';


const PrivateRoute = ({ component: Component, auth, drawerOpen, ...rest }) => {
  let sidebarWidth = '0px';
  //const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const newRoute = location.pathname;
  const BASE_URL = appConfig.BASE_URL;
  const dispatch = useDispatch();
  let currentUser = useSelector(state => state.auth.user?.loginId)


  // To get Current users enttitled menu
  let currentUserMenu = useSelector(state => state.menu.menuItems.map(i => i.children));
  
  
  // To get Child menu
  let childrenMenuNames = currentUserMenu.map(e => e.map(e=> e.menuRoute));
  let childMenuNames = childrenMenuNames.flat(1);

  // To get futher subChild menu
  let subChildrenMenus = currentUserMenu.map(e => e.map(e => e.children.map(e => e.menuRoute)));
  let subChildrenMenusRoute = subChildrenMenus.flat(1);
  let subChiledMenuNames = subChildrenMenusRoute.flat(1);

  //console.log("currentUserMenu", currentUserMenu)
  //console.log("childrenMenuNames", childrenMenuNames);
  //console.log("newRoute", newRoute)
  //console.log("childMenuNames", childMenuNames)
  //console.log("subChiledMenuNames", subChiledMenuNames)

  const getAppActivity = async () => {
    const currentMenu = currentUserMenu
      .flat(1) // Flatten the nested arrays
      .find(menuItem => menuItem.menuRoute === newRoute);
  console.log("menu id",currentMenu)
    if (currentMenu) {
      const body = {
        menuId: currentMenu.menuId,
        menuName: newRoute,
      };
  
      try {
        // api call for post of application activity log
        let res = await axios.post(BASE_URL + "/api/appActivity", body);
      } catch (error) {
        dispatch(showToaster("error", "Get App Activity Error"));
      }
    } else {
      console.error(`Menu not found for route: ${newRoute}`);
    }
  };
  

  useEffect(() => {
    getAppActivity();
  }, [newRoute]);







  return (
    <Route
      {...rest}
      render={props => {
        let drawerWidth = '0px';
        if (drawerOpen) {
          drawerWidth = '240px';
        }

        if (auth.isAuthenticated === true) {
          return (
            <div style={{ display: 'flex' }}>
              <Dashboard />
              <div style={{ width: `calc(100% - ${drawerWidth} - 2px )`, backgroundColor: '#d5e1ec', marginTop:'71px', height: `calc(100vh - 80px)`, overflow:'auto' }}>
                <Component  {...props} />
              </div>

            </div>
          )
        } else {
          return (
            <Redirect to="/" />
          );
        }
      }
      }
    />

  );
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  drawerOpen: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  drawerOpen: state.common.drawerOpen
});

export default connect(mapStateToProps, null)(PrivateRoute);
