import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography, InputLabel, Grid, Box, CardContent, GridList, CardActions } from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import appConfig from '../../../appConfig.json';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const BASE_URL = appConfig.BASE_URL;
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        marginLeft: '17px'
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        margin: '0px 20px',
        width: '100%'
    },
    cardStyle: {
        border: "1px solid grey",
        margin: '10px',
        padding: '10px',
        borderRadius: '7px',
        height: '25vh',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 255, 0.5)',
    },
    inputLabelStyle: {
        color: 'black',
        marginTop: '2px'
    },
    files: {
        // 'files:hover': {
        //     backgroundColor: '#eaeaea'
        // },
        cursor: 'pointer'
    }
});

class EventAuditTrail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedAuditData: [],      // To store Event Audit Data
            
        }
    }

    componentDidUpdate(prevProps, prevState) {

        console.log("Props of selectedEventRow ", this.props.selectedEventRow);

        if (this.props.selectedEventRow) {          // To check whether event is selected 
            if (prevProps.selectedEventRow) {

                if (this.props.selectedEventRow.txnEventId != null && this.props.selectedEventRow.txnEventId != prevProps.selectedEventRow.txnEventId) {
                    this.showAuditDataOnCard();     // Call function to fetch Audit Data
                }
            }
            else if (prevProps.selectedEventRow == null && this.props.selectedEventRow) {
                this.showAuditDataOnCard();          // Call function to fetch Audit Data
            }
        }
    }


    showAuditDataOnCard = async () => {         //function to fetch Audit Data
        try {
            const auditData = await axios.get(BASE_URL + "/api/eventAudit?eventId=" + this.props.selectedEventRow.txnEventId);      // GET API Call

            console.log("Audit Data from GET", auditData.data.data);

            this.setState({
                selectedAuditData: auditData.data.data      // Store auditData to state
            })

            console.log("selectedAuditData", this.state.selectedAuditData);
        }

        catch (err) {
            console.log(err);
        }
    }

    downloadFile = async (fileDetails) => {
        const res = await axios.get(BASE_URL + "/api/uploads?file=" + fileDetails.eventFileUrl, { responseType: 'blob' });

        const objUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = objUrl;
        link.setAttribute('download', fileDetails.fileFullName);
        document.body.appendChild(link);
        link.click();
    }

    render() {
        const { classes } = this.props;
        if (this.props.selectedEventRow && this.state.selectedAuditData) {      // Check Event is selected and Audit data is stored in selectedAuditData state

            return (
                <div className={classes.root}>
                    <Typography variant='h5' color='#002884'>Audit Trail - Event ID: <b>{this.props.selectedEventRow.txnEventId}</b></Typography>
                    <GridList className={classes.gridList} cols={3.5} cellHeight="auto">
                        {this.state.selectedAuditData.map((eventAudit, index) => {        // map selectedAuditData in "eventAudit"
                            return (
                                <GridListTile key={index}>
                                    <div className={classes.cardStyle}>
                                        <Grid container spacing={3}>
                                            <Grid item md={5} xs={12}>
                                                <Typography variant='h6'>Audit Remarks: </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <InputLabel
                                                    className={classes.inputLabelStyle}
                                                    id="auditRemarks"
                                                    name="auditRemarks"
                                                    fullWidth>{eventAudit.auditRemarks}</InputLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item md={5} xs={12}>
                                                <Typography variant='h6'>Audited By: </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <InputLabel
                                                    className={classes.inputLabelStyle}
                                                    id="auditedBy"
                                                    name="auditedBy"
                                                    fullWidth>{eventAudit.createdBy.userDetailName}</InputLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item md={5} xs={12}>
                                                <Typography variant='h6'>Audited On: </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <InputLabel
                                                    className={classes.inputLabelStyle}
                                                    id="createdAt"
                                                    name="createdAt"
                                                    fullWidth>{Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(eventAudit.createdAt))}</InputLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item md={5} xs={12}>
                                                <Typography variant='h6'>Files: </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Tooltip title="Download" placement="right-start" TransitionComponent={Zoom} className={classes.files}>
                                                    <Typography>
                                                        {eventAudit.eventFiles.map((eventFile) => {
                                                            return <div onClick={() => this.downloadFile(eventFile)}>{eventFile.fileFullName}</div>
                                                        })}
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </GridListTile>
                            );
                        }
                        )
                        }
                    </GridList>
                </div>
            )
        }
        else {
            return null;
        }
    }
}

export default withStyles(styles)(EventAuditTrail);

