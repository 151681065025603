import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import {
    Select, TextField, Button, Card, CardContent, Grid, FormControl, MenuItem, InputLabel
} from '@material-ui/core';
import * as actions from '../../store/actions/index';
import axios from 'axios';
import Toaster from '../../components/common/Toaster';
import appConfig from '../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    root: {
        maxWidth: "100%",
        marginTop: '66px',
        clear: 'both'
    },
    selectFormControl: {
        minWidth: 120,
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto',
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    form: {
        marginTop: theme.spacing(1),
    },
});

class VehicleAuditDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            auditedVehicleRegNumber : "",
            auditedVehicleClassId : "",

            openToast : false,
            msgToast : "",
            toastSeverity : "success",
            errors: {
                auditedVehicleRegNumber: "",        // error auditedVehicleRegNumber
                auditedVehicleClassId: "",          // error auditedVehicleClassId
            },
        };
    }

    validate = () => {
        let { errors } = this.state;
        const vehicleNumRegEx = /^[A-Z0-9- ]*$/;

        errors.auditedVehicleClassId = '';
        errors.ownerAtpRegistrationId = '';

        if (this.state.auditedVehicleRegNumber === "") {
            errors.auditedVehicleRegNumber = 'Please enter Vehicle Number.';
            this.setState({ errors: errors });
            return false;
        } else {
            if (!vehicleNumRegEx.test(this.state.auditedVehicleRegNumber)) {
                errors.auditedVehicleRegNumber = 'Only capital letters, numbers,spaces & dashes allowed.';
                this.setState({ errors: errors })
                return false;
            }
        }

        if (this.state.auditedVehicleClassId === "") {
            errors.auditedVehicleClassId = 'Please Select Vehicle Class.';
            this.setState({ errors: errors });
            return false;
        } 

        this.setState({ errors: errors });
        return true;
    }

    onSubmit = async (event) => {
        event.preventDefault();
        console.log(this.state)

        if (!this.validate()) {
            console.log("onSubmit failed");

            return false;
        }

        let vc = this.props.vehicleClasses.find((vehicleClass) => vehicleClass.vehicleClassId ===  this.state.auditedVehicleClassId);
        
        let auditParams = {};
        auditParams.auditedVehicleRegNumber = this.state.auditedVehicleRegNumber;
        auditParams.auditedVehicleClassId = this.state.auditedVehicleClassId;
        auditParams.auditedVehicleClassName = vc ? vc.vehicleClassName : null;

        try {
            var response = await axios.patch(BASE_URL + "/api/vehicleAudit/" + this.props.selectedSpeedRaw.txnSpeedRawId, auditParams);
            this.setState({ openToast : true, msgToast: "Successfully Audited Vehicle Number " + this.state.auditedVehicleRegNumber, toastSeverity: "success"});

            // Reload the datagrid in the Vehicle Audit Dashboard
            this.props.reloadAudit();
            
        } catch (error) {
            console.log(error.response);
            this.setState({ openToast : true, msgToast: error.response.data.message, toastSeverity: "error"});
        }
        
    }

    handleInputChange = (event, abc) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(abc);
        this.setState({
            [name]: value
        });
    }

    render() {
        const { classes } = this.props;
        const { errors } = this.state;

        console.log("selectedSpeedRaw", this.props.selectedSpeedRaw);

        if (this.props.selectedSpeedRaw) {
            return (
                <React.Fragment>
                    <form noValidate autoComplete="off" className={classes.root} onSubmit={this.onSubmit}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>

                                    {/* 1. Image from ANPR */}
                                    <Grid item xs={12}>
                                        <img src={this.props.selectedSpeedRaw.anprImgLoc} alt="ANPR Img" style={{ width: '450px', height: '310px' }} />
                                    </Grid>

                                    {/* 2. ANPR Detected Original Vehicle Reg Number */}
                                    <Grid item xs={6}>
                                        <TextField
                                            name='dectectedVehicleNumber'
                                            id='dectectedVehicleNumber'
                                            label="Detected Vehicle Number"
                                            fullWidth
                                            value={this.props.selectedSpeedRaw.originalVehicleRegNumber}
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    {/* 3. Validated Vehicle Number */}
                                    <Grid item xs={6}>
                                        <TextField
                                            name='auditedVehicleRegNumber'
                                            id='auditedVehicleRegNumber'
                                            label="Validated Vehicle Number"
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.auditedVehicleRegNumber}
                                            onChange={this.handleInputChange}
                                        />
                                        {errors.auditedVehicleRegNumber.length > 0 &&
                                            <span>{errors.auditedVehicleRegNumber}</span>}
                                    </Grid>

                                    {/* 4. ANPR Detected Vehicle Class */}
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                            <InputLabel>Vehicle Class</InputLabel>
                                            <Select
                                                id="detectedVehicleClass"
                                                name="detectedVehicleClass"
                                                value={this.props.selectedSpeedRaw.originalVehicleClassId}
                                                label="Detected Vehicle Class"
                                                disabled
                                                >
                                                {this.props.vehicleClasses && this.props.vehicleClasses.map((vehicleClass) => {
                                                    return (
                                                        <MenuItem value={vehicleClass.vehicleClassId}>{vehicleClass.vehicleClassName}</MenuItem>
                                                    );           
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    
                                    {/* 5.Validated Vehicle Class */}
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                            <InputLabel>Validated Vehicle Class</InputLabel>
                                            <Select
                                                id="auditedVehicleClassId"
                                                name="auditedVehicleClassId"
                                                value={this.state.auditedVehicleClassId}
                                                onChange={this.handleInputChange}
                                                label="Validated Vehicle Class"
                                                >
                                                {this.props.vehicleClasses && this.props.vehicleClasses.map((vehicleClass) => {
                                                    return (
                                                        <MenuItem value={vehicleClass.vehicleClassId}>{vehicleClass.vehicleClassName}</MenuItem>
                                                    );           
                                                })}
                                            </Select>
                                        </FormControl>
                                        {errors.auditedVehicleClassId.length > 0 &&
                                            <span>{errors.auditedVehicleClassId}</span>}
                                    </Grid>

                                     {/* 6.Audit Button */}
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" type="submit">
                                            Audit
                                        </Button>
                                    </Grid>  
                                            
                                </Grid> 
                            </CardContent>
                        </Card>
                    </form>
                    <Toaster
                        open={this.state.openToast}
                        message={this.state.msgToast}
                        severity={this.state.toastSeverity}
                        onClose={() => {
                            console.log("I came here in closing toaster");
                            this.setState({  openToast : false });
                        }}
                    />
                </React.Fragment>
            );

        } else {
            return <div>Loading...</div>
        }
    };
}

const mapStateToProps = state => {
    return {
        vehicleClasses : state.audVioCmnReducer.vehicleClasses,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        auditform: ( data ) => dispatch( actions.auditform( data ) )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VehicleAuditDetails));