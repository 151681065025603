import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, InputLabel, Typography, RadioGroup, FormLabel,
  Radio, Box, Button
} from '@material-ui/core';
import { connect } from "react-redux";
import FormHelperText from '@material-ui/core/FormHelperText'
import * as actions from '../../../store/actions/index';
import _ from 'lodash';
import Toaster from '../../../components/common/Toaster';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import commonStyles from '../../../commonStyles';

const styles = theme => ({
  ...commonStyles(theme),
});

class EventSop extends React.Component {

  constructor() {
    super();
    this.state = {
      mobileNumber: null,
      landLineNumber: null,
      selectedSopItems: null
    };

  }

  componentDidMount() {
    this.props.getLocationTypes();
    this.props.getEventSop(this.props.selectedEvent.eventData.eventSubtypeId);
    this.props.getSelectedEventSopItems(this.props.selectedEvent.eventData.txnEventId);
  }

  componentDidUpdate() {
    if (this.state.selectedSopItems == null && this.props.selectedEventSopItems != null) {
      this.setState({ selectedSopItems: _.map(this.props.selectedEventSopItems, 'eventSopItemId') });
    }
  }

  renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
    <React.Fragment>
      <InputLabel>{label}</InputLabel>
      <TextField
        variant="outlined"
        fullWidth
        // label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </React.Fragment>
  )

  renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
    return (
      <React.Fragment>
        <InputLabel>{label}</InputLabel>
        <FormControl fullWidth variant="outlined" error={touched && error} helperText={touched && error}>
          <Select
            error={touched && error}
            native
            {...input}
            {...custom}
            inputProps={{
              name: input.name,
              id: 'color-native-simple'
            }}
          >
            {children}

          </Select>
          {this.renderFromHelper({ touched, error })}
        </FormControl>
      </React.Fragment>
    )
  }


  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }

  renderCheckbox = ({ input, label }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    </div>
  )

  locationTypeOnChange = (event, newValue) => {
    this.props.getlocationDetails(newValue);
  };


  locationDetailsOnChange = (event, index) => {
    console.log("event and index", event, index);
    this.setState({ landLineNumber: this.props.locationDetails[index].locaionDetailsLandlineNumber })
    this.setState({ mobileNumber: this.props.locationDetails[index].locaionDetailsPrimaryMobile })
  }

  handleSopActionChange = (evt, value) => {
    let selectedItemIds = this.state.selectedSopItems;
    let selectedSop = parseInt(evt.target.value);
    if (evt.target.checked) {
      selectedItemIds.push(selectedSop);
    } else {
      selectedItemIds = selectedItemIds.filter((id) => id != selectedSop);
    }
    this.setState({ selectedSopItems: selectedItemIds });
  }

  isSopItemSelected = (sopItemId) => {
    if (this.state.selectedSopItems == null) {
      return false;
    }
    return this.state.selectedSopItems.indexOf(sopItemId) != -1;
  }

  onSubmit = (values) => {
    console.log(this.state.selectedSopItems);
    this.props.updateEventSopItems({
      eventId: this.props.selectedEvent.eventData.txnEventId,
      selectedSopItemIds: this.state.selectedSopItems
    });
  }

  render() {
    const { classes } = this.props;


    return (
      <React.Fragment>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Typography variant="h5" className="sectionHeading">Quick Contact Details</Typography>
            <Grid container spacing={8}>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="locationType" component={reduxFormFunctions.renderSelectField} label="Location Type" onChange={this.locationTypeOnChange}>
                  <option value="" />
                  {this.props.locationTypes.map((locationType) => {
                    return (<option value={locationType.locationTypeId}>{locationType.locationTypeName}</option>);
                  })}
                </Field>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Typography variant="h6" gutterBottom>
                  Primary Mobile Number
                                        </Typography>
                <Typography variant="body1" gutterBottom>
                  {this.state.mobileNumber ?
                    this.state.mobileNumber : 'NOT AVAILABLE'}
                </Typography>
              </Grid>

            </Grid>
            <Grid container spacing={8}>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="locationDetails" component={reduxFormFunctions.renderSelectField} label="Location Details" onChange={this.locationDetailsOnChange}>
                  <option value="" />
                  {this.props.locationDetails.map((locationDetail, index) => {
                    return (<option value={index}>{locationDetail.locationDetailsName}</option>);
                  })}
                </Field>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Typography variant="h6" gutterBottom>
                  Landline Number
                                        </Typography>
                <Typography variant="body1" gutterBottom>
                  {this.state.landLineNumber ?
                    this.state.landLineNumber : 'NOT AVAILABLE'}
                </Typography>
              </Grid>
            </Grid>


            {this.props.selectedEvent.eventData.nearestHospitalLocation &&
              <div>
                <Divider variant="list" className={classes.atmsPageSectionDivider} />
                <Typography variant="h5" className="sectionHeading">Nearest Hospital</Typography>
                <Typography variant="body1" gutterBottom>
                  {this.props.selectedEvent.eventData.nearestHospitalLocation.locationDetailsName}
                </Typography>
                <Typography variant="h6" gutterBottom>Contact Number</Typography>
                <Typography variant="body1" gutterBottom>
                  {this.props.selectedEvent.eventData.nearestHospitalLocation.locaionDetailsPrimaryMobile}
                </Typography>
              </div>
            }


            <Grid item md={6} xs={12}>
              {this.props.selectedEvent.eventData.nearestPoliceLocation &&
                <div>
                  <Divider variant="list" className={classes.atmsPageSectionDivider} />
                  <Typography variant="h5" className="sectionHeading">Nearest Police Station</Typography>
                  <Typography variant="body1" gutterBottom>
                    {this.props.selectedEvent.eventData.nearestPoliceLocation.locationDetailsName}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Contact Number
                                        </Typography>
                  <Typography variant="body1" gutterBottom>
                    {this.props.selectedEvent.eventData.nearestPoliceLocation.locaionDetailsPrimaryMobile}
                  </Typography>
                </div>
              }
            </Grid>

            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5" className="sectionHeading">Quick Actions</Typography>
            <fieldset disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName!= 'MANAGE'}>
            <RadioGroup name="quickActions">
              {this.props.eventSopActions.map((sopAction) => {
                return (<FormControlLabel value={sopAction.eventSopItemId} control={<Checkbox checked={this.isSopItemSelected(sopAction.eventSopItemId)} />} label={sopAction.sopItem.sopItemDescription}
                  onChange={this.handleSopActionChange} />);
              })}
            </RadioGroup>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Button variant="contained" className={classes.atmsSaveButton} disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName!= 'MANAGE'} size="large" type="submit">
              SAVE
            </Button>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
          </fieldset >
        </form >
      </React.Fragment >
    );
  }

}

const validate = formValues => {
  const errors = {};

  return errors;
};

const mapStateToProps = state => {
  return {
    locationTypes: state.eventSop.locationTypes,
    locationDetails: state.eventSop.locationDetails,
    eventSopActions: state.eventSop.eventSopActions,
    selectedEvent: state.selectedEvent,
    selectedEventSopItems: state.eventSop.selectedEventSopItems,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLocationTypes: () => dispatch(actions.getLocationTypes()),
    getlocationDetails: (locationTypeId) => dispatch(actions.getlocationDetails(locationTypeId)),
    getEventSop: (eventSubtypeId) => dispatch(actions.getEventSop(eventSubtypeId)),
    getSelectedEventSopItems: (eventId) => dispatch(actions.getSelectedEventSopItems(eventId)),
    updateEventSopItems: (body) => dispatch(actions.updateEventSopItems(body))
  }
}

EventSop = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventSop);

export default reduxForm({
  form: 'EventSop',
  validate: validate,
})(withStyles(styles)(EventSop));