import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    StakeholderCat: [],
    strAddStakeholderCat: ''
};

const stakeholderCatSuccess = (state,action) => {
    var updatedState = updateObject(state, 
        {
            StakeholderCat: action.stakeholderCat    
        });
    return updatedState;
};

const stakeholderCatAddSuccess = (state,action) => {
    var updatedState = updateObject(state, 
        {
            strAddStakeholderCat: action.strAddStakeholderCat    
        });
    return updatedState;
};

const stakeholderCatAddFail = (state,action) => {
    var updatedState = updateObject(state, 
        {
            strAddStakeholderCat: action.strAddStakeholderCat    
        });
    return updatedState;
}

const stakeholderCatStateReset = (state,action) => {
    return updateObject(state, { loading: null });
};

const stakeholderCatCreateStart = (state, action) => {
    return updateObject(state, { loading: true});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STAKEHOLDER_CAT_SUCCESS :
            return stakeholderCatSuccess(state, action);
        
        case actionTypes.STAKEHOLDER_CAT_ADD_SUCCESS :
            return stakeholderCatAddSuccess(state, action);
        
        case actionTypes.STAKEHOLDER_CAT_ADD_FAIL :
            return stakeholderCatAddFail(state, action);

        case actionTypes.STAKEHOLDER_CAT_STATE_RESET :
            return stakeholderCatStateReset(state, action);
        
        case actionTypes.STAKEHOLDER_CAT_CREATE_START :
            return stakeholderCatCreateStart(state, action);

        default:
            return state;        
    }
}

export default reducer;