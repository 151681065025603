import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import NestedMenu from './NestedMenu';

const drawerWidth = 240;

const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
});

class Sidebar extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Drawer variant="permanent" classes={{
          paper: clsx(classes.drawerPaper, !this.props.open && classes.drawerPaperClose),
        }}
          open={this.props.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.props.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton> 
          </div>
          <Divider />
          <NestedMenu></NestedMenu>
          
        </Drawer>
      </React.Fragment>
    );
  }
}

export default (withRouter(withStyles(styles)(Sidebar)));