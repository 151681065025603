import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search';
import DataGrid from '../../../../components/mastersGrid/DataGrid';
import { connect } from "react-redux";
import LocationDetailsCreate from './LocationDetailsCreate';
import LocationDetailsEdit from './LocationDetailsEdit';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Toaster from '../../../../components/common/Toaster';
import checkEntitlement from "../../../../utils/checkEntitlement";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f4f6f8',
  },
  addButton: {
    float: 'right'
  },
  header: {
    display: 'inline'
  },
  list: {
    marginTop: theme.spacing(3)
  },
  modal: {
    'overflow-y': 'auto',
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  sheader: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  }
});

class LocationDetails extends React.Component {

  currentSelectedRow = null;
  constructor() {
    super();
    this.state = {
      isEnabled: "all",
      catName: "",
      urlParams: [],
      isOpen: false,
    };
    this.grid = React.createRef();
  }

  onSearchSubmit = (event) => {
    event.preventDefault();
    const urlParams = [];

    if (this.state.catName.length > 0) {
      urlParams.push({ 'key': 'locationDetailsName', value: this.state.catName });
    }
    this.setState({ urlParams: urlParams })
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({
      catName: value
    });
  }

  handleRadioChange = (event) => {
    this.setState({ isEnabled: event.target.value });
  }

  componentDidUpdate(prevProps, prevState) {
    /*
     * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
     */
    if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
      this.grid.current.reload();
    }
  }

  render() {
    const { classes } = this.props;
    let modalDiv = null;

    if (this.state.modalType === "ADD") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.isOpen}
        onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
        closeAfterTransition>
        <LocationDetailsCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
      </Modal>
    }

    if (this.state.modalType === "EDIT") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.isOpen}
        onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
        closeAfterTransition>
        <LocationDetailsEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
      </Modal>
    }

    return (
      <Container maxWidth="xl" className={classes.root}>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <Typography variant="h4" gutterBottom className={classes.header}>
          Location Details
          </Typography>
        <Button variant="contained"
          disabled={!checkEntitlement(this.props.location.pathname, 'add')}
          color="primary"
          startIcon={<AddIcon />}
          className={classes.addButton}
          onClick={() => this.setState({ isOpen: true, modalType: "ADD" })} >
          Add Details
          </Button>
        <Box mt={3}>
          <Card>
            <CardContent>
              <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <div style={{ maxWidth: '70px' }}>
                      <Typography variant="h6" gutterBottom className={classes.sheader}>
                        Search
                        </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Name"
                      name="catName"
                      className={classes.sheader}
                      onChange={this.handleInputChange}
                      value={this.state.catName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      className={`${classes.sheader} ${classes.addButton}`}
                      variant="contained"
                      color="primary"
                      startIcon={<SearchIcon />}
                      type="submit"
                    >
                      Search
                      </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          <DataGrid downloadReport={true} ref={this.grid} endpoint="api/locationDetails" urlParams={this.state.urlParams} isEditable={checkEntitlement(this.props.location.pathname, 'edit')} onEditClick={(event, rowData) => {
            this.currentSelectedRow = rowData;
            this.setState({ isOpen: true, modalType: "EDIT" })
          }}
          />
        </Box>
        {modalDiv}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.locationDetailsReducer.loading,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(LocationDetails));