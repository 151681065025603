import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const notificationCatCreateStart = () => {
    return {
        type: actionTypes.NOTIFICATION_CAT_CREATE_START
    };
};

export const notificationCatCreate = (formValues) => {
    return async (dispatch) => {
        try{
            dispatch(notificationCatCreateStart());
            const response = await axios.post(BASE_URL + "/api/notificationCategories", formValues);
            console.log("Created")
            dispatch(showToaster('success','Notification Category Successfully Created.'))
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const notificationCatStateReset = () => {
    return {
        type: actionTypes.NOTIFICATION_CAT_STATE_RESET
    };
};

export const notificationCatEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        notificationCategoryName: formValues.notificationCategoryName,
        notificationCategoryDescription: formValues.notificationCategoryDescription,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(notificationCatCreateStart());
            const response = await axios.patch(BASE_URL + "/api/notificationCategories/" + formValues.notificationCategoryId,values);
            console.log(response);
             dispatch(showToaster('success','Notification Category Successfully Edited.'));

        }catch(error) {
            console.log(error)
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}
