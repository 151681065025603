import React, { Component } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { deepOrange } from '@material-ui/core/colors';
import Notifications from "./Notifications"
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';

const drawerWidth = 240;

const styles = theme => ({
  title: {
    marginLeft: 10,
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer'
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  square: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  userClass: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconClass: {
    marginLeft: '1rem',
  },

});

class Appbar extends Component {

  constructor() {
    super();
    this.state = {
      anchorEl: null,
    };
  }


  onLogoutClick = e => {
    e.preventDefault();
    this.setState({ anchorEl: null });
    this.props.logoutUser();
    this.props.history.push("/");
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AppBar position="absolute" className={clsx(classes.appBar, this.props.open && classes.appBarShift)}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.handleDrawerOpen}
              className={clsx(classes.menuButton, this.props.open && classes.menuButtonHidden)}>
              <MenuIcon />
            </IconButton>
            <div className={classes.leftItems} onClick={() => this.props.history.push('/home')}>
              <img src="DEENRLOGO.png" style={{ height: '60px', width: '60px' }} alt="DEENRLOGO">
              </img>
              <Typography variant="h6" className={classes.title}>
                DriveSafe
              </Typography>
            </div>
            <div className={classes.rightItems}>
              <div>
                <Notifications />
              </div>
              <div>
                <IconButton edge="end" color="inherit" onClick={this.handleClick} >
                  <div className={classes.userClass}>
                    <Typography variant="h6" align="right">
                      {this.props.userName}
                    </Typography>
                    <Typography variant="h6">
                      {this.props.userRole}
                    </Typography>
                  </div>
                  <AccountCircleIcon fontSize="large" className={classes.iconClass} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  // getContentAnchorEl={null}   // If we need verticle property to work uncomment this 
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                >
                  <MenuItem onClick={this.onLogoutClick}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }

}
const mapStateToProps = (state) => {
  return {
    userName: state.auth.user.userDetailName,
    userRole: state.auth.user.role.userRoleName
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(actions.logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Appbar)));