import React from 'react';
import UserRoleAddForm from './UserRoleAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class UserRoleEdit extends React.Component {
    
    componentDidMount() {
        this.props.userRoleStateReset();
    }

    onSubmit = formValues => {
        console.log("formValues:"+ formValues)
        // delete formValues.parent;
        // delete formValues.tableData;
        
        this.props.userRoleEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress =  <div className={classes.spinDiv}>
                            <CircularProgress size={200} thickness={2}/>
                        </div>
        }
        
        console.log("errorMsgCrt", this.props.initialValues);

        return (
             <React.Fragment>
                 { alert }
                { progress }
                <UserRoleAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit}/> 
             </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.userRole.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userRoleEdit: ( formValues ) => dispatch( actions.userRoleEdit( formValues ) ),
        userRoleStateReset:  () => dispatch( actions.userRoleStateReset() ),
    };
};


export default connect( mapStateToProps, mapDispatchToProps )(withStyles(styles)(UserRoleEdit));