import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const userRoleSuccess = (userRoles) => {
    return {
        type: actionTypes.USER_ROLE_SUCCESS,
        userRoles: userRoles,
    };
};

//#region Fetch User Role for Drop down
export const fetchUserRoleData = (endpoint) => {
    return dispatch => {

        axios
            .get(BASE_URL + "/api/userRoles")
            .then(res => {
                console.log("fetchUserRoleData: ", res.data)
                dispatch(userRoleSuccess(
                    res.data,
                ));
            }).catch(error => {
                console.log('error occured');
                dispatch(showToaster('error', error.response.data.message))
            });
    };
};
//#endregion

export const userRoleCreateStart = () => {
    return {
        type: actionTypes.USER_ROLE_CREATE_START
    };
};
export const userRoleCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(userRoleCreateStart());
            const response = await axios.post(BASE_URL + "/api/userRoles", formValues);
            dispatch(showToaster('success', 'User Role Successfully Created.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}

export const userRoleStateReset = () => {
    return {
        type: actionTypes.USER_ROLE_STATE_RESET
    }
}

export const userRoleEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }

    let values = {
        parentUserRoleId: formValues.parentUserRoleId,
        userRoleName: formValues.userRoleName,
        userRoleDescription: formValues.userRoleDescription,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(userRoleCreateStart());
            const response = await axios.patch(BASE_URL + "/api/userRoles/" + formValues.userRoleId, values);
            console.log(response);
            dispatch(showToaster('success', 'User Role Successfully Edited.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}
