import React from 'react';
import AssetVehicleCatAddForm from './assetVehicleCatAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class AssetVehicleCatCreate extends React.Component {

    componentDidMount() {
        this.props.assetVehicleCatStateReset();
    }

    onSubmit = formValues => {
        formValues.isEnabled = 1;
        this.props.assetVehicleCatCreate(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                {alert}
                {progress}
                <AssetVehicleCatAddForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.assetVehicleCatReducer.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        assetVehicleCatCreate: (formValues) => dispatch(actions.assetVehicleCatCreate(formValues)),
        assetVehicleCatStateReset: () => dispatch(actions.assetVehicleCatStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssetVehicleCatCreate));