import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import UserDetails from './containers/usermanagement/userdetails/UserDetails';
import UserRole from './containers/usermanagement/userRole/UserRole';
import Entitlements from './containers/usermanagement/entitlements/Entitlements';
import addEvent from './containers/eventManagement/addEvent';
import quickEvent from './containers/eventManagement/quickEvent';
import GlobalConfig from './containers/GlobalConfig/GlobalConfig';
import reportEntitlements from './containers/SystemMaster/reportPrivileges/reportEntitlements';
// import mapsDashboard from './containers/mapsDashboard/mapsDashboard';
import mapsDash from './containers/mapsDashboard/MapDash';
import manageEvent from './containers/eventManagement/manageEvent';
import EventFlow from './containers/eventManagement/manageevent/EventFlow';
import equipType from './containers/SystemMaster/equipmentManagment/equipmentType/equipType';
import equipCat from './containers/SystemMaster/equipmentManagment/equipmentCat/equipCat';
import equipGroup from './containers/SystemMaster/equipmentManagment/equipmentGroup/equipGroup';
import Login from './containers/auth/Login';
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import locationCategory from './containers/SystemMaster/locationManagement/locationCategory/locationCat';
import locationDetails from './containers/SystemMaster/locationManagement/locationDetails/locationDetails';
import locationType from './containers/SystemMaster/locationManagement/locationType/locationType';
import Equipments from './containers/SystemMaster/equipmentManagment/equipments/equipments';
import VehicleAuditDash from './containers/vehicleAudit/VehicleAuditDash';
import ApproveRejectEvent from './containers/eventManagement/approveRejectEvent';
import Shift from './containers/SystemMaster/shifts/shifts';
import AssetVehicleCat from './containers/SystemMaster/assetsVehicleManagement/assetVehicleCat/assetVehicleCat';
import AssetVehicleDetails from './containers/SystemMaster/assetsVehicleManagement/assetVehicleDetails/assetVehicleDetails';
import EventType from './containers/OperationMaster/EventManagement/eventType/eventType';
import EventSubtype from './containers/OperationMaster/EventManagement/eventSubtype/eventSubtype';
import ReportContainer from './containers/reports/ReportContainer';
import SOPCat from './containers/OperationMaster/EventManagement/EventSOPManagement/SOPCat/SOPCat'
import SOPItem from './containers/OperationMaster/EventManagement/EventSOPManagement/SOPItems/SOPItem';
import SOPMap from './containers/OperationMaster/EventManagement/EventSOPManagement/SOPMap/SOPMap';
import HotlistVehicle from './containers/OperationMaster/hotlistVehicle/hotlistVehicle';
import Chainage from './containers/OperationMaster/chainage/chainage';
import Section from './containers/OperationMaster/section/section';
import StakeholderCat from './containers/SystemMaster/stakeholderManagement/stakeholderCat/stakeholderCat';
import StakeholderUser from './containers/SystemMaster/stakeholderManagement/stakeholderUser/stakeholderUser';
import EventAudit from './containers/auditManagement/eventAudit/eventAuditDashboard';
import Menu from './containers/SystemMaster/Menu/menu';
import NotificationCat from './containers/SystemMaster/notificationManagement/notificationCategory/notificationCat';
import NotificationTemplate from './containers/SystemMaster/notificationManagement/notificationTemplate/notificationTemplate';
import EventRule from './containers/OperationMaster/EventManagement/eventRule/eventRule';
import LandingPageDashboard from './containers/landingPage/LandingPageDashboard';
import TrendsForMetEquipments from './containers/landingPage/trendsForMetEquipments';
import Directions from './containers/SystemMaster/directions/directions';
import Ecb from './containers/SystemMaster/ecb/ecb';
import commomLandingPage from './containers/landingPage/commomLandingPage';
/*****************************************************************************************
 * Violations Pages
 *****************************************************************************************/
import VdDashboard from './containers/violationManagement/violationDashboard/VdDashboard';
import ViolationRejection from './containers/violationManagement/violationRejection/Dashboard';
import ViolationChallan from './containers/violationManagement/violationChallan/Dashboard';
import ViolationRelated from './containers/violationManagement/violationChallan/violationrelated/ViolationRelated';
/*****************************************************************************************
* Summary Reports
******************************************************************************************/
import atccDailyTraffic from './containers/summaryReports/atccDailyTraffic';
import atccMonthlyTraffic from './containers/summaryReports/atccMonthlyTraffic';
import atccHourlyTraffic from './containers/summaryReports/atccHourlyTraffic';
import vidsDailyEventCount from './containers/summaryReports/vidsDailyEventCount';
import vidsMonthlyEventCount from './containers/summaryReports/vidsMonthlyEventCount';
import vidsHourlyEvents from './containers/summaryReports/vidsHourlyEvents';
import eventManagementPerformance from './containers/summaryReports/eventManagementPerformance';
import eventSubTypeReport from './containers/summaryReports/eventSubTypeReport';
import monthlyEventSubType from './containers/summaryReports/monthlyEventSubType';
import hourlyEventSubtype from './containers/summaryReports/hourlyEventSubtype';
import speedDailyTraffic from './containers/summaryReports/speedDailyTraffic';
import speedMonthlyTraffic from './containers/summaryReports/speedMonthlyTraffic';
import speedHourlyTraffic from './containers/summaryReports/speedHourlyTraffic';
import speedDailyChallan from './containers/summaryReports/speedDailyChallan';
import speedMonthlyChallan from './containers/summaryReports/speedMonthlyChallan';
import ttesHourly15MinAvg from './containers/summaryReports/ttesHourly15MinAvgReport';
import ttesHourlyAvg from './containers/summaryReports/ttesHourlyAvgReport';
import ttesDailyAvg from './containers/summaryReports/ttesDailyAvgReport';
import ttesMonthlyAvg from './containers/summaryReports/ttesMonthlyAverageReport';
import vms from './containers/Vms/vms';
import metHourlyAvg from './containers/summaryReports/metHourlyAvg';
var xirifyTheme = {
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 200,
      textAlign: 'capitalize',
    },
    fontSize: 13,
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: '-0.05px',
      color: '#002884',
      margin: '15px 0px'
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    overline: {
      fontWeight: 500
    }
  },
};

class App extends Component {


  render() {

    var applyTheme = createMuiTheme(xirifyTheme);

    return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router>
            <div className="App">
              <Route exact path="/" component={Login} />
              <Switch>
                <PrivateRoute exact path="/userdetails" component={UserDetails} />
                <PrivateRoute exact path="/userrole" component={UserRole} />
                <PrivateRoute exact path="/entitlements" component={Entitlements} />
                <PrivateRoute exact path="/equiptype" component={equipType} />
                <PrivateRoute exact path="/equipgroup" component={equipGroup} />
                <PrivateRoute exact path="/equipcat" component={equipCat} />
                <PrivateRoute exact path="/addevent" component={addEvent} />
                <PrivateRoute exact path="/approverejectevent" component={ApproveRejectEvent} />
                <PrivateRoute exact path="/quickevent" component={quickEvent} />
                <PrivateRoute exact path="/mapdashboard" component={mapsDash} />
                <PrivateRoute exact path="/manageevent" component={manageEvent} />
                <PrivateRoute exact path="/locationcategory" component={locationCategory} />
                <PrivateRoute exact path="/locationdetails" component={locationDetails} />
                <PrivateRoute exact path="/locationtype" component={locationType} />
                <PrivateRoute exact path="/eventflow" component={EventFlow} />
                <PrivateRoute exact path='/equipments' component={Equipments} />
                <PrivateRoute exact path='/violationdashboard' component={VdDashboard} />
                <PrivateRoute exact path='/violationrejection' component={ViolationRejection} />
                <PrivateRoute exact path='/violationchallan' component={ViolationChallan} />
                <PrivateRoute exact path='/violationrelated' component={ViolationRelated} />
                <PrivateRoute exact path='/speeddata' component={VehicleAuditDash} />
                <PrivateRoute exact path='/shift' component={Shift} />
                <PrivateRoute exact path="/assetVehicleCat" component={AssetVehicleCat} />
                <PrivateRoute exact path="/assetVehicleDetails" component={AssetVehicleDetails} />
                <PrivateRoute exact path="/eventType" component={EventType} />
                <PrivateRoute exact path="/eventSubtype" component={EventSubtype} />
                <PrivateRoute exact path="/genericreports" component={ReportContainer} />
                <PrivateRoute exact path="/sopCat" component={SOPCat} />
                <PrivateRoute exact path="/sopItem" component={SOPItem} />
                <PrivateRoute exact path="/sopMapping" component={SOPMap} />
                <PrivateRoute exact path="/hotlistVehicle" component={HotlistVehicle} />
                <PrivateRoute exact path="/chainage" component={Chainage} />
                <PrivateRoute exact path="/section" component={Section} />
                <PrivateRoute exact path="/stakeholderCat" component={StakeholderCat} />
                <PrivateRoute exact path="/stakeholderUser" component={StakeholderUser} />
                <PrivateRoute exact path="/eventAudit" component={EventAudit} />
                <PrivateRoute exact path="/menu" component={Menu} />
                <PrivateRoute exact path="/notificationCat" component={NotificationCat} />
                <PrivateRoute exact path="/notificationTemplate" component={NotificationTemplate} />
                <PrivateRoute exact path="/eventRule" component={EventRule} />
                <PrivateRoute exact path="/landingPage" component={LandingPageDashboard} />
                <PrivateRoute exact path="/trends" component={TrendsForMetEquipments} />
                <PrivateRoute exact path="/directions" component={Directions} />
                <PrivateRoute exact path="/ecb" component={Ecb} />
                <PrivateRoute exact path="/globalconfig" component={GlobalConfig} />
                <PrivateRoute exact path="/home" component={commomLandingPage} />
                <PrivateRoute exact path="/reportentitlements" component={reportEntitlements} />
                <PrivateRoute exact path="/atccdailytraffic" component={atccDailyTraffic} />
                <PrivateRoute exact path="/atccmonthlytraffic" component={atccMonthlyTraffic} />
                <PrivateRoute exact path="/atcchourlytraffic" component={atccHourlyTraffic} />
                <PrivateRoute exact path="/vidsdailyevents" component={vidsDailyEventCount} />
                <PrivateRoute exact path="/vidsmonthlyevents" component={vidsMonthlyEventCount} />
                <PrivateRoute exact path="/vidshourlyevents" component={vidsHourlyEvents} />
                <PrivateRoute exact path="/eventmanageperformance" component={eventManagementPerformance} />
                <PrivateRoute exact path="/eventsdailyreport" component={eventSubTypeReport} />
                <PrivateRoute exact path="/eventsmonthlyreport" component={monthlyEventSubType} />
                <PrivateRoute exact path="/eventshourlyreport" component={hourlyEventSubtype} />
                <PrivateRoute exact path="/speeddailytraffic" component={speedDailyTraffic} />
                <PrivateRoute exact path="/speedmonthlytraffic" component={speedMonthlyTraffic} />
                <PrivateRoute exact path="/speedhourlytraffic" component={speedHourlyTraffic} />
                <PrivateRoute exact path="/speeddailychallan" component={speedDailyChallan} />
                <PrivateRoute exact path="/speedmonthlychallan" component={speedMonthlyChallan} />
                <PrivateRoute exact path="/TTESHourly15minAvgReport" component={ttesHourly15MinAvg} />
                <PrivateRoute exact path="/ttesHourlyAvgReport" component={ttesHourlyAvg} />
                <PrivateRoute exact path="/ttesDailyAvgReport" component={ttesDailyAvg} />
                <PrivateRoute exact path="/ttesMonthlyAverage" component={ttesMonthlyAvg} />
                <PrivateRoute exact path="/vms" component={vms} />
                <PrivateRoute exact path="/methourlyavg" component={metHourlyAvg} />


                


















              </Switch>
            </div>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }

}

export default App;
