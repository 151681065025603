import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Box, Card, CardContent,Typography, Grid, InputLabel, Button, CardHeader, IconButton, TextField, Divider,Modal } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import EventFilter from '../../../components/common/EventAudit/eventAuditSearchFilters';
import EventAuditTrail from './eventAuditTrail';
import appConfig from '../../../appConfig.json';
import axios from 'axios';
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm } from 'redux-form';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import commonStyles from '../../../commonStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import Typography from 'material-ui/styles/typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EventDetailsCard from './eventDetailsCard';
import CloseIcon from '@material-ui/icons/Close';


const BASE_URL = appConfig.BASE_URL;
const styles = theme => ({
    ...commonStyles(theme),
    root: {
        marginTop: theme.spacing(9),
        backgroundColor: '#f4f6f8',
    },
    header: {
        display: 'inline'
    },
    card: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(0.3),
        marginBottom: theme.spacing(3),
        //  display: 'grid',
        gridGap: '15px',
        gridAutoFlow: 'column',
        width: '100%',
        gridTemplateColumns: '100px 100px',
        //  justifyContent: 'start'

    },
    filterCard: {

    },
    button: {
        padding: '8px 20px',
        marginBottom: '8px'
    },
    fileButton: {
        padding: '10px 25px'
    },
    auditTrailCard: {
        marginBottom: theme.spacing(1)
    },

    label: {
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    fileLabel: {
        marginTop: theme.spacing(2)
    },
    divider: {
        backgroundColor: '#eeeeee'
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        color:'black',
        margin: theme.spacing(1),
      },
});

class EventAuditDashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedEventRow: null,         // Sets the selected Event for Audit
            urlParams: null,                // Used by the DataGrid        
            auditRemarks: null,             // Stores the Audit Remarks
            selectedFile: [],               // Stores the Uploading Files
            FilesDeleted: [],               // stores the Deleted files
            auditFileSourceId: [],         // stores Event File Source
            success: false,
            failure: false,
            open: false,                   // Open Snackbar to show message
            backDropOpen: false,
            auditRemarkfailure: false,
            auditRemarkLengthFail: false,
            isModalOpen: false, 
           
        };
        this.grid = React.createRef();
        this.eventAudit = React.createRef();
    }

    handleCloseBackdrop = () => {
        this.setState({
            backDropOpen: false
        })
    }

    handleBackdropOpen = () => {
        this.setState({
            backDropOpen: true
        })
    }

    async componentDidMount() {
        // as soon as this page gets load, we get Event File Source from "EventFileSource" table
        try {
            const response = await axios.get(BASE_URL + "/api/eventFileSource");    // To get Event File Source Id (for Audit= 4)
            this.setState({
                auditFileSourceId: this.getEventFileSourceId('AUDIT', response.data.data)
            });
            console.log("event audit ref", this.eventAudit);
        } catch (error) {
            console.log(error);
        }

    }

    /* Gets the Event Source ID for the Audited File */
    getEventFileSourceId(eventFileSourceName, eventFileSources) {
        console.log('getting event audit file source id for: ', eventFileSourceName, eventFileSources);
        if (eventFileSources != null) {
            var eventFileSourceRet = eventFileSources.find((eventFileSource) => eventFileSource.eventFileSourceName == eventFileSourceName);
            if (eventFileSourceRet != null) {
                console.log('eventFileSourceRet.eventFileSourceId: ', eventFileSourceRet.eventFileSourceId);
                return eventFileSourceRet.eventFileSourceId;
            }
        }
    }

    // Called by the Search Filter */
    onSearchSubmit = (urlParams) => {
        console.log("On search Event Audit = urlParams", urlParams);
        this.setState({ urlParams: urlParams })
    }

    // Add Audit File Div
    auditFiles = ({ fields, meta: { error, submitFailed } }) => {
        const { classes } = this.props;
        return (
            <div>
                <input style={{ display: "none" }} ref={(ref) => this.upload = ref} type="file" onChange={(event) => {
                    var fname = event.target.files[0].name

                    fields.push({
                        selectedFileName: fname.split('.')[0],           // fetch file name from file details
                        selectedFileExtension: fname.split('.').pop(),  //  fetch file extension from file details
                        selectedFile: event.target.files[0]             //  store file at first position in selectedFile
                    })
                }} />

                {submitFailed && error && <span>{error}</span>}

                {fields.map((member, index) => (
                    <Grid key={index} container
                        // className={classes.card2}
                        direction="row"
                        justify="space-between"
                    // alignItems="flex-start"
                    >
                        <Grid item md={3} xs={12}>
                            <h3>Audit File {index + 1} -</h3>
                        </Grid>
                        <Grid item md={4} xs={12} className={classes.fileLabel}>
                            <Field
                                name={`${member}.selectedFileName`}
                                type="text"
                                component={reduxFormFunctions.renderText}
                                label="File Name"
                            />
                        </Grid>
                        <Grid item md={4} xs={12} className={classes.fileLabel}>
                            <Field
                                name={`${member}.selectedFileExtension`}
                                type="text"
                                component={reduxFormFunctions.renderText}
                                label="File Extension"
                            />
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <IconButton onClick={() => {
                                console.log(this.props.form.EventAuditDashboard.values.auditFiles[index]);
                                this.setState({
                                    FilesDeleted: [this.state.FilesDeleted, this.props.form.EventAuditDashboard.values.auditFiles[index]],
                                });
                                fields.remove(index)
                                console.log("FilesDeleted", this.state.FilesDeleted);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Button className={classes.fileButton} variant="contained" color="primary" onClick={(e) => this.upload.click()}>Upload file</Button>
            </div>);
    }

    handleRemarks = (event) => {        // set input data to states
        this.setState({
            auditRemarks: event.target.value
        });
    }

    // Upload Audit File on Server
    addAuditFile = async (files, addEventAuditParams) => {

        if (files != null) {
            for (var j = 0; j < files.length; j++) {
                try {
                    console.log("Uploading file", files[j].selectedFileName);
                    const data = new FormData()                                         // create constant to store file data to send it to server
                    data.append('public', "false")                                      // public: false -> do not create folder in public
                    data.append('uploadPath', addEventAuditParams.eventAuditPath)       // create folder on given path
                    data.append('files', files[j].selectedFile);
                    const uploadFileResponse = await axios.post(BASE_URL + "/api/uploads", data, {      // Post api call to upload files
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    console.log("Add Audit files", files[j], uploadFileResponse);
                    let auditFile = {
                        eventId: addEventAuditParams.txnEventId,
                        eventType: addEventAuditParams.eventTypeId,
                        eventSubType: addEventAuditParams.eventSubtypeId,
                        eventFileName: files[j].selectedFileName,
                        eventFileExtension: files[j].selectedFileExtension,
                        eventFilePath: uploadFileResponse.data[0].path,
                        eventFileSourceId: addEventAuditParams.eventFileSourceId,
                        eventAuditId: addEventAuditParams.eventAuditId
                    }

                    var auditFilesArr = [];
                    auditFilesArr.push(auditFile);
                    const retEventAuditFile = await axios.post(BASE_URL + "/api/eventFiles", auditFilesArr);        // Post api call to upload audit file data
                    console.log(retEventAuditFile)
                }
                catch (err) {
                    console.log("Error in addAuditFile", err)
                }
            }
        }
    }

    // Upload Audit Data on Server
    addAuditData = async (fileCount) => {
        try {
            let auditData = {
                eventId: this.state.selectedEventRow.txnEventId,
                eventType: this.state.selectedEventRow.eventType.eventTypeId,
                eventSubType: this.state.selectedEventRow.eventSubtype.eventSubtypeId,
                auditRemarks: this.state.auditRemarks,
                auditFileCount: fileCount
            }
            console.log("addAuditdata", auditData);

            const addedAuditData = await axios.post(BASE_URL + "/api/eventAudit", auditData);           // post to upload audit data

            console.log("AddedAuditData from setstate", addedAuditData);
            return addedAuditData.data;
        }
        catch (err) {
            console.log(err);
        }
    }

    handleClose = () => {
        this.setState({
            open: false

        });
        if (this.state.success) {
            this.setState({
                success: false
            })
        }
        else if (this.state.failure) {
            this.setState({
                failure: false
            })
        }
        else if (this.state.auditRemarkfailure) {
            this.setState({
                auditRemarkfailure: false
            })
        }
        else if (this.state.auditRemarkLengthFail) {
            this.setState({
                auditRemarkLengthFail: false
            })
        }
    }

    onSubmit = async () => {
        try {
            if (this.state.auditRemarks == "" || this.state.auditRemarks == null) {
                this.setState({
                    auditRemarkfailure: true,
                    open: true
                })
                console.log("Audit Remarks", this.state.auditRemarks);
                console.log("auditRemarkfailure", this.state.auditRemarkfailure);
                console.log("open", this.state.open);
            }
            else {
                if (this.state.auditRemarks.length > 201) {
                    this.setState({
                        auditRemarkLengthFail: true,
                        open: true
                    })
                }
                else {
                    console.log("auditRemarkfailure", this.state.auditRemarkfailure);
                    console.log("open", this.state.open);
                    console.log("Event Id ", this.state.selectedEventRow.txnEventId);
                    console.log("Event type name ", this.state.selectedEventRow.eventType.eventTypeName);
                    console.log("Event Subtype name ", this.state.selectedEventRow.eventSubtype.eventSubtypeName);
                    console.log("Audit Remarks ", this.state.auditRemarks);

                    var auditFilesAdded = [];       // All files to be uploaded 
                    var eventAuditPath = "eventAudit/" + this.state.selectedEventRow.txnEventId;
                    var auditFilesNew = this.props.form.EventAuditDashboard.values.auditFiles || [];
                    console.log("auditFilesNew", auditFilesNew);

                    for (var j = 0; j < auditFilesNew.length; j++) {
                        if (auditFilesNew[j].hasOwnProperty("selectedFile") === true) {
                            auditFilesAdded.push(auditFilesNew[j]);
                        }
                    }
                    var fileCount = auditFilesAdded.length;

                    this.handleBackdropOpen();

                    var temp = await this.addAuditData(fileCount)
                    console.log("temp", temp);

                    var addEventAuditParams = {
                        eventAuditPath: eventAuditPath,
                        eventFileSourceId: this.state.auditFileSourceId,
                        txnEventId: this.state.selectedEventRow.txnEventId,
                        eventTypeId: this.state.selectedEventRow.eventType.eventTypeId,
                        eventSubtypeId: this.state.selectedEventRow.eventSubtype.eventSubtypeId,
                        eventAuditId: temp.txnEventAuditId
                    }
                    console.log("Files count", fileCount);

                    await this.addAuditFile(auditFilesAdded, addEventAuditParams);        // Function call to upload audit file data

                    await this.eventAudit.current.showAuditDataOnCard();

                    this.setState({
                        success: true,
                        open: true
                    })

                    if (this.state.success) {
                        this.setState({
                            auditRemarks: ""
                        });
                        this.props.form.EventAuditDashboard.values.auditFiles = null         // Reset uploaded files
                    }
                    this.handleCloseBackdrop();
                    //this.eventAudit.current.downloadFile();
                }
            }
this.closeModal();
        }
        

        catch (err) {
            console.log("Upload file error " + err)
            this.setState({
                failure: true,
                open: true
            })
            this.handleCloseBackdrop();
        }
    }

    openModal = (chartType) => {
        console.log("Modal opened!!!")
        this.setState({
            modalType: chartType,
            isModalOpen: true,
            //selectedRowData: rowData,
        });
    }

    // Function to close the modal
    closeModal = () => {
        console.log("Modal closed!!!")
        this.setState({
            isModalOpen: false,
            //selectedRowData: null,
        });
    }


    render() {
        const { classes } = this.props;
        console.log("success", this.state.success);
        if (this.state.success) {
            console.log("open", this.state.open);
            console.log("success", this.state.success);

            alert = <Snackbar open={this.state.open} autoHideDuration={3000} onClose={() => { this.handleClose() }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => { this.handleClose() }} variant="filled" severity="success" className={classes.alert}>
                    <AlertTitle>Success</AlertTitle>
                    Audited Remarks saved Succesfully
                </Alert>
            </Snackbar>
            this.grid.current.reload();
        }

        if (this.state.failure) {
            console.log("open", this.state.open);
            alert = <Snackbar open={this.state.open} autoHideDuration={3000} onClose={() => { this.handleClose() }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => { this.handleClose() }} variant="filled" severity="error" className={classes.alert}>
                    <AlertTitle>Error</AlertTitle>
                        Error in uploading Audit File
                    </Alert>
            </Snackbar>
        }

        if (this.state.auditRemarkfailure) {
            console.log("open", this.state.open);
            console.log("auditRemarkfailure", this.state.auditRemarkfailure);
            console.log("success", this.state.success);

            alert =
                <Snackbar open={this.state.open} autoHideDuration={3000} onClose={() => { this.handleClose() }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                    <Alert onClose={() => { this.handleClose() }} variant="filled" severity="error" className={classes.alert}>
                        <AlertTitle>Error</AlertTitle>
                        Enter Audit Remarks
                    </Alert>
                </Snackbar>
        }

        if (this.state.auditRemarkLengthFail) {
            alert =
                <Snackbar open={this.state.open} autoHideDuration={3000} onClose={() => { this.handleClose() }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                    <Alert onClose={() => { this.handleClose() }} variant="filled" severity="error" className={classes.alert}>
                        <AlertTitle>Error</AlertTitle>
                        Maximum characters should be 200 only
                    </Alert>
                </Snackbar>
        }

        if (this.state.success == null && this.state.failure == null && this.state.auditRemarkfailure == null && this.state.auditRemarkLengthFail) {
            alert = null;
        }

        return (
            <div className={classes.atmsPageOuterDiv}>
                {alert}
                <Box mt={3}>
                    <Card>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <CardHeader
                                    title="Event Filters"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <CardContent> */}
                                <EventFilter
                                    onSearchSubmit={(urlParams) => {
                                        this.onSearchSubmit(urlParams)
                                    }}
                                />
                                {/* </CardContent> */}
                            </AccordionDetails>

                        </Accordion>
                    </Card>
                </Box>

                {/* Event DataGrid */}
                <Box mt={3}>
                    <Card>
                        <CardHeader
                            title="Event Details"
                        />
                        <DataGrid ref={this.grid} endpoint="api/events?audit=true" isMasterGrid={false} urlParams={this.state.urlParams} limit={5} onRowClick={(event, rowData) => {
                            console.log("I came inside Datagrid", event, rowData);
                            this.setState({ selectedEventRow: rowData });
                            this.props.reset();
                            //console.log("Row Data", rowData);
                            //console.log("Selected Event Row", this.state.selectedEventRow);
                        }}
                        actions={[{
                            icon: VisibilityIcon, 
                            onClick: (event, rowData) => {
                                this.setState({ selectedEventRow: rowData });
                                console.log("Row Data", rowData);
                                console.log("Selected Event Row", this.state.selectedEventRow);
                                this.openModal(rowData);
                             
                            }
                          }]}
                        idField="txnEventId"
                        />
                    </Card>
                </Box>
                <Modal
                    open={this.state.isModalOpen}
                    onClose={this.closeModal}
                    className={classes.modal}
                >
                    <div className={classes.paper}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={this.closeModal}
                        >
                            <CloseIcon />
                        </IconButton>
                        <EventDetailsCard eventData={this.state.selectedEventRow} />
                        <Divider variant="fullWidth" className={classes.atmsPageSectionDivider}  />
                        <Typography variant="h5" >Event Audit</Typography>
                        <Grid container spacing={2}>
                        <Grid className={classes.card} item xs={12}>
                            <FieldArray name="auditFiles" id="auditFiles" component={this.auditFiles} />
                        </Grid>

                        <Grid
                            container
                            spacing="2px"
                            className={classes.card}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item md={2} xs={12}>
                                <TextField
                                    id="auditRemarks"
                                    name="auditRemarks"
                                    type="text"
                                    label="Audit Remarks"
                                    value={this.state.auditRemarks}
                                    fullWidth
                                    autoComplete="off"
                                    onChange={this.handleRemarks}
                                    variant="outlined"
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    justify="end" onClick={this.onSubmit}>
                                    Submit
                                    </Button>

                            </Grid>

                        </Grid>
                        </Grid>
                    </div>
                    
                </Modal>



                {/* Add New Audit */}

                <Box mt={3}>
                    {/*<Card>
                        <CardHeader
                            title="Add New Audit"
                        />
                        <Grid className={classes.card} item xs={12}>
                            <FieldArray name="auditFiles" id="auditFiles" component={this.auditFiles} />
                        </Grid>

                        <Grid
                            container
                            spacing="2px"
                            className={classes.card}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item md={2} xs={12}>
                                <TextField
                                    id="auditRemarks"
                                    name="auditRemarks"
                                    type="text"
                                    label="Audit Remarks"
                                    value={this.state.auditRemarks}
                                    fullWidth
                                    autoComplete="off"
                                    onChange={this.handleRemarks}
                                    variant="outlined"
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    justify="end" onClick={this.onSubmit}>
                                    Submit
                                    </Button>

                            </Grid>

                        </Grid>

                    </Card>*/}
                    <Backdrop className={classes.backdrop} open={this.state.backDropOpen}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
                <Box mt={3}>
                    {/*Audit Trail */}
                    <Card className={classes.auditTrailCard}>
                        {/* <CardHeader
                            title="Audit Trail"
                        /> */}
                        <EventAuditTrail ref={this.eventAudit} selectedEventRow={this.state.selectedEventRow} />      {/* call EventAuditTrail component and send selected event data*/}
                    </Card>
                </Box>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        form: state.form
    }
}

EventAuditDashboard = connect(
    mapStateToProps, null
)(EventAuditDashboard)

export default reduxForm({
    form: 'EventAuditDashboard'
})(withStyles(styles)(EventAuditDashboard));