
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';



const useStyles = theme => {
  return {
    atmsPageOuterDiv: {
      'margin-top': theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      flexGrow: 1,
    },
    atmsPageOuterCard: {
      'min-height': '90vh'
    },
    atmsPageSectionDivider: {
      marginTop: theme.spacing(1)
    },
    atmsSaveButton: {
      backgroundColor: '#fb8c00',
      color: 'white',
      margin: '15px 0px'
    }
  }
}

export default useStyles;