import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Select, TextField, Button, FormControl, InputLabel, MenuItem, Grid, Input, Checkbox, ListItemText, FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import format from 'date-fns/format';
import * as actions from '../../store/actions/index';
import axios from 'axios';
import appConfig from '../../appConfig.json';
import _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8',
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto',
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    selectFormControl: {
         minWidth: 120,
    },
});

class AuditFilter extends React.Component {
    constructor() {
        super();
        let date = new Date();
        // let fromDate = new Date();
        let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        console.log("defaultToDate", defaultToDate)
        this.state = {
            fromDate: defaultFromDate,          // 1. From Audit
            toDate: defaultToDate,            // 2. To Audit
            vehicleNumber : "",      // 3. Vehicle number 
            vehicleClassId : [],     // 4. Vehicle Class ID
            vehicleDirectionId : [], // 5. Vehilcle Direction ID
            chainageId : [],         // 6. Chainage ID
            equipmentId : [],        // 7. Equipment ID
            anprEquipments : [],     // All the ANPR equipments in the dropbox
            isAudited : "Non Audit", // By default We show Non Audited entries
            isOpenSnack: false,
            isError: false,
            errorMessage: ''
        }
    }
   
    async componentDidUpdate(prevProps, prevState) {
        let response;
        let directionNames = [];

        if (_.isEqual(this.state.vehicleDirectionId, prevState.vehicleDirectionId) == false) {
            this.props.getChainages(this.state.vehicleDirectionId);
        }

        if (_.isEqual(this.state.chainageId, prevState.chainageId) == false) {
            
            try {
                /* 
                 * Get the equiment categories
                 */

                response = await axios.get(BASE_URL + "/api/equipmentCategories?reqType=all");
                const anprCat = response.data.find(equipment => equipment.equipmentCategoryName === "ANPR_SPEED")
                
                if (typeof anprCat !== 'null') {
                    let anprCatId = anprCat.equipmentCategoryId
                
                    /* 
                     * We get the equipments associated with the categoryID(ANPR). ChainageID and Vehicle Directions are array
                     */
                    response = await axios.get(BASE_URL + "/api/equipments?reqType=all&equipmentCategoryId=" + 
                    anprCatId + "&chainageId=" + this.state.chainageId.join(',') + "&directionId=" + this.state.vehicleDirectionId.join(','));
                    console.log("ANPREQUIPMENTS", response.data)
                    this.setState({anprEquipments : response.data})
                }
                
              } catch (error) {
                console.log(error);
              }
        }

    }
    onAlertClose = () => {
        // let date = new Date();
        // let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        // let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        this.setState({
            isOpenSnack: false,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        });
    }

    
    handleMultiSelect(e) {
        const name = e.target.name;
        const value = e.target.value;
        console.log("name is ", name);
        let stateObj = {};
        stateObj[name] = value;
        this.setState(stateObj);
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("handleInputChange", name, value);
        this.setState({
            [name]: value
        });
    }

    handleDateTimeChange = (dateTime, objKey) => {
        console.log("handleDateTimeChange dateTime", format(dateTime, "yyyy-MM-dd'T'HH:mm"));
        let stateObj = {};
        stateObj[objKey] = format(dateTime, "yyyy-MM-dd'T'HH:mm");
        this.setState(stateObj);
    }

    getJoinedNames(idArray, fieldName) {
        let result = [];
        
        if (fieldName == "Chainage") {
            this.props.chainages.forEach((chainage) => {
                if (idArray.indexOf(chainage.chainageId) > -1) {
                    result.push(chainage.chainageName);
                }
            });
        }

        if (fieldName == "Equipment") {
            this.state.anprEquipments.forEach((equipment) => {
                if (idArray.indexOf(equipment.equipmentId) > -1) {
                    result.push(equipment.equipmentName);
                }
            });
        }

        if (fieldName == "VehicleClass") {
            this.props.vehicleClasses.forEach((vehicleClass) => {
                if (idArray.indexOf(vehicleClass.vehicleClassId) > -1) {
                    result.push(vehicleClass.vehicleClassName);
                }
            });
        }

        if (fieldName == "VehicleDirection") {
            this.props.directions.forEach((direction) => {
                if (idArray.indexOf(direction.directionId) > -1) {
                    result.push(direction.directionName);
                }
            });
        }

        return result.join(', ');
    }

    /* 
     * Lifting State Up
     * We construct the urlparams in the function and pass it on to parent
     * for DataDrid to show
     */
     
    onSearchSubmit = (event) => {
        event.preventDefault();

        console.log("onSearchSubmit = Audit Filter", this.state);
        const urlParams = [];


        if (this.state.fromDate || this.state.toDate) {

            var fDate = new Date(this.state.fromDate);
            var tDate = new Date(this.state.toDate);
            if (this.props.timeValue) {
                var dateRange = this.props.timeValue.configValue1;
                console.log("dateRange", dateRange)
            }

            // To calculate the time difference of two dates 
            var Difference_In_Time = tDate.getTime() - fDate.getTime();

            // To calculate the no. of days between two dates 
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            console.log("Difference_In_Days", Difference_In_Days);

            if (tDate > fDate) {
                console.log("1st IF")
                if (Difference_In_Days < dateRange) {
                    console.log("2nd IF")
                    if (this.state.fromDate) {
                        urlParams.push({ 'key': 'fromDate', value: this.state.fromDate });
                    }

                    if (this.state.toDate) {
                        urlParams.push({ 'key': 'toDate', value: this.state.toDate });
                    }
                }
                else {
                    this.setState({
                        isError: true,
                        isOpenSnack: true,
                        errorMessage: "Number of days between From Date and To Date should not exceed 90."
                    });
                    console.log("errorMessage", this.state.errorMessage);
                }
            }
            else {
                this.setState({
                    isError: true,
                    isOpenSnack: true,
                    errorMessage: "To date should be the past date of From Date"
                });
                console.log("errorMessage", this.state.errorMessage);
            }
        }
        
        // if (this.state.fromDate) {
        //     urlParams.push({ 'key': 'fromDate', value: this.state.fromDate });
        // }

        // if (this.state.toDate) {
        //     urlParams.push({ 'key': 'toDate', value: this.state.toDate });
        // }

        if (this.state.vehicleNumber) {
            urlParams.push({ 'key': 'vehicleNumber', value: this.state.vehicleNumber });
        }

        if (this.state.vehicleClassId.length > 0) {
            urlParams.push({ 'key': 'originalVehicleClassId', value: this.state.vehicleClassId });
        }

        if (this.state.vehicleDirectionId.length > 0) {
            urlParams.push({ 'key': 'vehicleDirectionId', value: this.state.vehicleDirectionId });
        }

        if (this.state.chainageId.length > 0) {
            urlParams.push({ 'key': 'chainageId', value: this.state.chainageId });
        }

        if (this.state.equipmentId.length > 0) {
            urlParams.push({ 'key': 'equipmentId', value: this.state.equipmentId });
        }
        
        this.props.onSubmit(urlParams);
    }

    /* 
     * Reset the Audit Filter
     */
    
    resetAuditFilter = () => {
        let date = new Date();
        let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        this.setState({
            fromDate: defaultFromDate,          
            toDate: defaultToDate,           
            vehicleNumber : "",    
            vehicleClassId : [],  
            vehicleDirectionId : [], 
            chainageId : [],
            equipmentId : [],        
            anprEquipments : [],
        })
        this.props.radioIsAudit("Non Audit");
    }

    render() {
        const { classes } = this.props;
        if (this.state.isError) {
            alert =
                <Snackbar open={this.state.isOpenSnack} autoHideDuration={4000} onClose={() => { this.onAlertClose() }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                    <Alert onClose={() => { this.onAlertClose() }} variant="filled" severity="error" className={classes.alert}>
                        <AlertTitle>Error</AlertTitle>
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
        }
    
        return (
            <React.Fragment>
                <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                    
                        <Grid container spacing={3}>

                            {/* 1. From Time Violation */}
                            <Grid item xs={2} >
                                <DateTimePicker
                                    id="fromDate"
                                    name="fromDate"
                                    label="From"
                                    format="yyyy/MM/dd HH:mm"
                                    fullWidth
                                    disableFuture={true}
                                    ampm={false}
                                    value={this.state.fromDate}
                                    onChange={(val) => this.handleDateTimeChange(val, "fromDate")}
                                    inputVariant="outlined"
                                    allowKeyboardControl={false}
                                />
                            </Grid>

                            {/* 2. To Time Violation */}
                            <Grid item xs={2} >
                                <DateTimePicker
                                    id="toDate"
                                    name="toDate"
                                    label="To"
                                    format="yyyy/MM/dd HH:mm"
                                    fullWidth
                                    disableFuture={true}
                                    ampm={false}
                                    value={this.state.toDate}
                                    onChange={(val) => this.handleDateTimeChange(val, "toDate")}
                                    inputVariant="outlined"
                                    allowKeyboardControl={false}
                                />
                            </Grid>

                            {/* 3. Vehicle number dropdown */}
                            <Grid item xs={2}>
                                <TextField
                                    name="vehicleNumber"
                                    id="vehicleNumber" 
                                    label="Vehicle Number"
                                    fullWidth
                                    value={this.state.vehicleNumber}
                                    onChange={this.handleInputChange}
                                    variant="outlined" 
                                />
                            </Grid>
                            
                            {/* 4. Vehicle Class Dropdown */}
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel>Vehicle Class</InputLabel>
                                    <Select
                                        name="vehicleClassId"
                                        multiple
                                        value={this.state.vehicleClassId}
                                        // input={<Input />}
                                        onChange={(e) => this.handleMultiSelect(e)}
                                        label="Vehicle Class"
                                        renderValue={(selected) => this.getJoinedNames(selected, "VehicleClass")}
                                        >
                                        {this.props.vehicleClasses && this.props.vehicleClasses.map((vehicleClass) => {
                                            return (
                                                <MenuItem key={vehicleClass.vehicleClassId} value={vehicleClass.vehicleClassId}>
                                                    <Checkbox checked={this.state.vehicleClassId.indexOf(vehicleClass.vehicleClassId) > -1} />
                                                    <ListItemText primary={vehicleClass.vehicleClassName} />
                                                </MenuItem>
                                            );           
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* 5. Direction Name Dropdown */}
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel>Direction</InputLabel>
                                    <Select
                                        name="vehicleDirectionId"
                                        multiple
                                        value={this.state.vehicleDirectionId}
                                        // input={<Input />}
                                        onChange={(e) => this.handleMultiSelect(e)}
                                        label="Vehicle Direction"
                                        renderValue={(selected) => this.getJoinedNames(selected, "VehicleDirection")}
                                        >
                                        {this.props.directions && this.props.directions.map((direction) => {
                                            return (
                                                <MenuItem key={direction.directionId} value={direction.directionId}>
                                                    <Checkbox checked={this.state.vehicleDirectionId.indexOf(direction.directionId) > -1} />
                                                    <ListItemText primary={direction.directionName} />
                                                </MenuItem>
                                            );           
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            {/* 6. Chainage Name Dropdown */}
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel>Chainage</InputLabel>
                                    <Select
                                        name="chainageId"
                                        multiple
                                        value={this.state.chainageId}
                                        // input={<Input />}
                                        onChange={(e) => this.handleMultiSelect(e)}
                                        renderValue={(selected) => this.getJoinedNames(selected, "Chainage")}
                                        >
                                        {this.props.chainages && this.props.chainages.map((chainage) => {
                                            return (
                                                <MenuItem key={chainage.chainageId} value={chainage.chainageId}>
                                                    <Checkbox checked={this.state.chainageId.indexOf(chainage.chainageId) > -1} />
                                                    <ListItemText primary={chainage.chainageName} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* 7. Equipment Name Dropdown */}
                            
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel>Equipment</InputLabel>
                                    <Select
                                        name="equipmentId"
                                        multiple
                                        value={this.state.equipmentId}
                                        // input={<Input />}
                                        onChange={(e) => this.handleMultiSelect(e)}
                                        renderValue={(selected) => this.getJoinedNames(selected, "Equipment")}
                                        >
                                        {this.state.anprEquipments && this.state.anprEquipments.map((equipment) => {
                                            return (
                                                <MenuItem key={equipment.equipmentId} value={equipment.equipmentId}>
                                                    <Checkbox checked={this.state.equipmentId.indexOf(equipment.equipmentId) > -1} />
                                                    <ListItemText primary={equipment.equipmentName} />
                                                </MenuItem>
                                            );           
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            {/* 7. Equipment Name Dropdown */}
                            
                            <Grid item xs={2}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Audit Status</FormLabel>
                                    <RadioGroup row value={this.props.isAudited} onChange={(event) => this.props.radioIsAudit(event.target.value)}>
                                        <FormControlLabel value="Non Audit" control={<Radio />} label="Non Audit" />
                                        <FormControlLabel value="All" control={<Radio />} label="All" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {/* 10. Search Button */}
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" type="submit">
                                    Search
                                </Button>
                            </Grid>
                        
                            {/* 11. Reset Button */}
                            <Grid item xs={2}>
                                <Button variant="contained" color="secondary" onClick={this.resetAuditFilter}>
                                    Reset
                                </Button>
                            </Grid>

                            
                            {alert}
                        </Grid>
                    </form>
            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        directions: state.audVioCmnReducer.directions,
        chainages : state.audVioCmnReducer.chainages,
        vehicleClasses : state.audVioCmnReducer.vehicleClasses,
        timeValue: state.common.globalConfig.date_filter_range
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getChainages: (dirId) => dispatch(actions.getChainages(dirId)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditFilter));
