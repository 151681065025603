import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
    Select, Button, FormControl, Container, Card,
    CardHeader, Divider, CardContent, Grid, IconButton, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

//#region Styles
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});
//#endregion

class EquipTypeAddForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            equipmentCategoryId: 0
        };
    }

    componentDidMount() {       
        this.props.getEquipCat();
    }

    //#region Dropdown
    renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
        return (
            <FormControl fullWidth variant="outlined" error={touched && error} helperText={touched && error}>
                <Select
                    error={touched && error}
                    native
                    {...input}
                    {...custom}
                    inputProps={{
                        name: input.name,
                        id: 'color-native-simple'
                    }}
                >
                    {children}

                </Select>
                {this.renderFromHelper({ touched, error })}
            </FormControl>
        )
    }

    //#region On Change
    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    //#endregion

    //#region Form Submit
    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log("ADD FORM " + JSON.stringify(values));
    }
    //#endregion

    //#region render()
    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Equipment Type"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Equipment Type"
        }
        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Equipment Type"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeName" id="equipmentTypeName" component={reduxFormFunctions.renderTextField} label="Equipment Type" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeDescription" id="equipmentTypeDescription" component={reduxFormFunctions.renderTextField} label="Equipment Description" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeMake" id="equipmentTypeMake" component={reduxFormFunctions.renderTextField} label="Equipment Make" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeModel" id="equipmentTypeModel" component={reduxFormFunctions.renderTextField} label="Equipment Model" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeSeries" id="equipmentTypeSeries" component={reduxFormFunctions.renderTextField} label="Equipment Series" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeWarranty" id="equipmentTypeWarranty" component={reduxFormFunctions.renderTextField} label="Equipment Warranty" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeVersion" id="equipmentTypeVersion" component={reduxFormFunctions.renderTextField} label="Equipment Version" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeCount" id="equipmentTypeCount" component={reduxFormFunctions.renderTextField} label="Equipment Count" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentCategoryId" component={reduxFormFunctions.renderSelectField} label="Equipment Category" onChange={this.onChange}>
                                        <option value={""}>{'Select Equipment Category'}</option>
                                        {this.props.arrEquipCatProps.map((arrEquipCatProp) => <option key={arrEquipCatProp.equipmentCategoryId} value={arrEquipCatProp.equipmentCategoryId}>{arrEquipCatProp.equipmentCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                {isEnabled}

                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
    //#endregion
}

//#region Validations
const validate = formValues => {
    const error = {};
    if (!formValues.equipmentTypeName) {
        error.equipmentTypeName = 'Enter Equipment Type';
    }

    if (!formValues.equipmentTypeDescription) {
        error.equipmentTypeDescription = 'Enter Full Name';
    }

    if (formValues.equipmentTypeDescription) {
        if (formValues.equipmentTypeDescription.length > 201) {
            error.equipmentTypeDescription = "Maximum characters should be 200 only";
        }
    }

    if (!formValues.equipmentTypeMake) {
        error.equipmentTypeMake = 'Enter Equipment Make';
    }

    if (!formValues.equipmentTypeModel) {
        error.equipmentTypeModel = 'Enter Equipment Model';
    }

    if (!formValues.equipmentTypeSeries) {
        error.equipmentTypeSeries = 'Enter Equipment Series';
    }

    if (!formValues.equipmentTypeWarranty) {
        error.equipmentTypeWarranty = 'Enter Equipment Warrenty';
    }

    if (!formValues.equipmentTypeVersion) {
        error.equipmentTypeVersion = 'Enter Equipment Version';
    }

    if (!formValues.equipmentTypeCount) {
        error.equipmentTypeCount = 'Enter Equipment Count';
    } else if (!/^[0-9]*$/.test(formValues.equipmentTypeCount)) {
        error.equipmentTypeCount = 'Equipment Count should only have numbers';
    }

    if (!formValues.equipmentCategoryId) {
        error.equipmentCategoryId = 'Select Equipment Category';
    }
    if (formValues.equipmentCategoryId) {
        if (formValues.equipmentCategoryId == "") {
            error.equipmentCategoryId = 'Select Equipment Category';
        }
    }

    return error;
};
//#endregion

//#region Map Functions
const mapStateToProps = state => {
    return {
        arrEquipCatProps: state.equipTypeReducer.arrEquipCat
    };
};

const mapDispatchToProps = dispatch => {
    return {      
        getEquipCat: () => dispatch(actions.getEquipCat())
    };
};
//#endregion

EquipTypeAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EquipTypeAddForm);

export default reduxForm({
    validate: validate,
    form: 'EquipTypeAddForm'
})(withStyles(styles)(EquipTypeAddForm));