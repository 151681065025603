import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getLocationTypeSuccess = (arrLocationDtlType) => {
    return {
        type: actionTypes.GET_LOCATION_DETAILS_TYPE_SUCCESS,
        arrLocationDtlType: arrLocationDtlType,
    };
};

export const getLocationDetCatSuccess = (arrLocationDtlCat) => {
    return {
        type: actionTypes.GET_LOCATION_DETAILS_CAT_SUCCESS,
        arrLocationDtlCat: arrLocationDtlCat,
    };
};

export const getLocationDetDirSuccess = (arrLocationDtlDir) => {
    return {
        type: actionTypes.GET_LOCATION_DETAILS_DIR_SUCCESS,
        arrLocationDtlDir: arrLocationDtlDir,
    };
};

export const getLocationDetChainageSuccess = (arrLocationDtlChainage) => {
    return {
        type: actionTypes.GET_LOCATION_DETAILS_CHAINAGE_SUCCESS,
        arrLocationDtlChainage: arrLocationDtlChainage,
    };
};

//#region CATEGORY DROPDOWN IN SEARCH

export const getLocationDetailsCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/locationCategories?reqType=all")
            .then(res => {
                dispatch(getLocationDetCatSuccess(res.data));
            }).catch(error => {
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}
//#endregion

//#region TYPE DROPDOWN IN SEARCH
export const getLocationDetailsType = (catId) => {
    return dispatch => {
        axios
            // .get(BASE_URL + "/api/locationTypes?reqType=all&locationCategoryId="+ catId)
            .get(BASE_URL + "/api/locationTypes?reqType=all&locationCategoryId=" + catId)
            .then(res => {
                dispatch(getLocationTypeSuccess(res.data));
            }).catch(error => {
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}
//#endregion

//#region DIRECTION DROPDOWN IN SEARCH
export const getLocationDetailsDir = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/direction?reqType=all")
            .then(res => {
                dispatch(getLocationDetDirSuccess(res.data));
            }).catch(error => {
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}
//#endregion

//#region CHAINAGE DROPDOWN IN SEARCH
export const getLocationDetailsChainage = (dirId) => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/chainage?reqType=all&directionId=" + dirId)
            .then(res => {
                console.log("chainage res: ", res)
                dispatch(getLocationDetChainageSuccess(res.data));
            }).catch(error => {
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}
//#endregion

//#region START
const locationDetailsCreateStart = () => {
    return {
        type: actionTypes.LOCATION_DETAILS_CREATE_START
    };
};
//#endregion

//#region  ADD
export const locationDetailsCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(locationDetailsCreateStart());
            const response = await axios.post(BASE_URL + "/api/locationDetails", formValues);
            dispatch(showToaster('success', 'Location Details Successfully Created.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}
//#endregion

//#region STATE
export const locationDetailsStateReset = () => {
    return {
        type: actionTypes.LOCATION_DETAILS_STATE_RESET
    }
}
//#endregion

//#region EDIT
export const locationDetailsEdit = (formValues) => {
    console.log("In action Edit formValues: ", formValues);
    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }

    let values = {
        locationTypeId: formValues.locationTypeId,
        directionId: formValues.directionId,
        chainageId: formValues.chainageId,
        locationCategoryId: formValues.locationCategoryId,
        locationDetailsName: formValues.locationDetailsName,
        locationDetailsDescription: formValues.locationDetailsDescription,
        locaionDetailsAddress: formValues.locaionDetailsAddress,
        locationDetailsLongitude: formValues.locationDetailsLongitude,
        locationDetailsLatitude: formValues.locationDetailsLatitude,
        locaionDetailsIpAddress: formValues.locaionDetailsIpAddress,
        locaionDetailsLandlineNumber: formValues.locaionDetailsLandlineNumber,
        locaionDetailsPrimaryMobile: formValues.locaionDetailsPrimaryMobile,
        locaionDetailsSecondaryMobile: formValues.locaionDetailsSecondaryMobile,
        locaionDetailsPrimaryEmail: formValues.locaionDetailsPrimaryEmail,
        locaionDetailsSecondaryEmail: formValues.locaionDetailsSecondaryEmail,
        locationDetailsImageName: formValues.locationDetailsImageName,
        locationDetailsImageExtension: formValues.locationDetailsImageExtension,
        locationDetailsImagePath: formValues.locationDetailsImagePath,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(locationDetailsCreateStart());
            const response = await axios.patch(BASE_URL + "/api/locationDetails/" + formValues.locationDetailsId, values);
            dispatch(showToaster('success', 'Location Details Successfully Edited.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}
//#endregion