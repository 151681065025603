import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Button,
  Container,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  IconButton,
  Box,
  RadioGroup,
  Radio,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";
import * as reduxFormFunction from "../../components/common/Redux_forms_Common";
import CloseIcon from "@material-ui/icons/Close";
// import BackdropLoading from "../../components/common/BackdropLoading";

import appConfig from "../../appConfig.json";

const styles = (theme) => ({
  container: {
    display: "flex",
    marginTop: theme.spacing(1),
    outline: "none",
    padding: "0px",
  },
  button: {
    marginRight: theme.spacing(2),
    // marginLeft: theme.spacing(5),
  },
});

const BASE_URL = appConfig.BASE_URL;

const GlobalAddForm = (props) => {
  const { classes, type, handleSubmit, reset, pristine, invalid } = props;

  const [applicationCode, setCode] = useState([]);
  const [configDatatype, setConfigDataType] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  let renderedComponent = {
    DATE: {
      component: reduxFormFunction.renderDateField,
    },
    BOOL: {
      component: radioButtonBooleanType,
    },

    VARCHAR: {
      component: reduxFormFunction.renderTextField,
    },
    VAR: {
      component: reduxFormFunction.renderTextField,
    },
    "Date-Time": {
      component: reduxFormFunction.renderDateTimeField,
    },
    Time: {
      component: reduxFormFunction.renderTimeField,
    },
    INT: {
      component: reduxFormFunction.renderTextField,
    },
  };

  const onSubmit = (values) => {
    // console.log(values);

    props.onSubmit(values);
  };

  let subheaderName = null;
  let isEnabled = null;
  let buttons = null;

  if (type == "Add") {
    buttons = (
      <>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          type="submit"
        >
          ADD
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
          onClick={reset}
        >
          RESET
        </Button>
      </>
    );
    subheaderName = "Add  Global Config ";
  }
  if (type == "Edit") {
    buttons = (
      <>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={pristine || invalid}
          type="submit"
          size="large"
          onClose={props.onClose}
        >
          SAVE
        </Button>
      </>
    );
    isEnabled = (
      <React.Fragment>
        <Grid item md={6} xs={12}>
          <Field
            name="isEnabled"
            label="Enabled"
            component={reduxFormFunction.radioIsEnabled}
          />
        </Grid>
      </React.Fragment>
    );
    subheaderName = "Edit Global Config";
  }

  useEffect(() => {
    const func1 = async () => {
      try {
        setPageLoading(true);
        let res = await axios.get(
          BASE_URL + "/api/etmsApplication?reqType=all"
        );
        console.log("res.data",res.data);
        let result = res.data;
        setCode(result);
        setPageLoading(false);
        // console.log(res.data[0].etmsApplicationId);
        // console.log(res.data.etmsApplicationCode);
        // console.log(res.data.etmsApplicationName);
      } catch (e) {
        setPageLoading(false);
        console.log(`error`);
      }
    };
    if (type == "Edit") {
      console.log(props.initialValues);
    
      setConfigDataType(props.initialValues.configDatatype);
      // setCode(array.push(props.initialValues.configDatatype));
       //setCode(props.initialValues.etmsApplicationCode);
    }
    func1();
  }, []);


  const onDataChange = (event) => {
    let dataType = event.target.value;
    console.log(dataType);
    setConfigDataType(dataType);
  };

  function radioButtonBooleanType({
    input,
    label,
    meta: { touched, error, invalid },
    ...reset
  }) {
    /* Convert value to string as we have set it to string inside FormControlLabel */
    input.value = input.value.toString();

    return (
      <FormControl
        fullWidth
        variant="outlined"
        error={touched && invalid}
        helperText={touched && error}
      >
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup row {...input} {...reset}>
          <FormControlLabel
            value="TRUE"
            control={<Radio />}
            label="True"
            labelPlacement="end"
          />
          <FormControlLabel
            value="FALSE"
            control={<Radio />}
            label="False"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    );
  }
  console.log("configDatatype: ", configDatatype);
//   return pageLoading ? (
//     <BackdropLoading open={pageLoading} />
//   ) :
return (
    <container maxWidth="md" className={classes.container}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            title="Global Configuration"
            subheader={subheaderName}
            action={
              <IconButton onClick={props.onClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            }
          ></CardHeader>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              {/* <Grid item md={6} xs={12}>
                <Field
                  name="etmsApplicationCode"
                  id="etmsApplicationCodeId"
                  component={reduxFormFunction.renderSelectField}
                  label="ETMS Application Codes"
                  //value={applicationCode}
                >
                  <option value={""}>{""}</option>

                  {applicationCode.map((applicationCodeDropDown) => (
                    <option
                      key={applicationCodeDropDown.etmsApplicationId}
                      value={applicationCodeDropDown.etmsApplicationCode}
                    >
                      {applicationCodeDropDown.etmsApplicationCode}
                    </option>
                  ))}
                </Field>
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  name="configName"
                  id="configName"
                  component={reduxFormFunction.renderTextField}
                  label="Config Name"
                ></Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="configDescription"
                  id="configDescription"
                  component={reduxFormFunction.renderTextField}
                  label="Config Description"
                ></Field>
              </Grid>

              <Grid item md={6} xs={12}>
                <Field
                  name="configDatatype"
                  id="configDatatype"
                  component={reduxFormFunction.renderSelectField}
                  label="Config Data Type"
                  onChange={onDataChange}
                >
                  <option value={""}>{""} </option>
                  <option value={"BOOL"}>BOOL </option>
                  <option value={"DATE"}>DATE </option>
                  <option value={"Time"}>Time</option>
                  <option value={"Date-Time"}>Date-Time</option>
                  <option value={"INT"}>INT </option>
                  <option value={"VAR"}>VAR </option>
                  <option value={"VARCHAR"}>VARCHAR</option>
                </Field>
              </Grid>

              <Grid item md={6} xs={12}>
                <Field
                  name="configValue1"
                  id="configValue1"
                  component={
                    configDatatype
                      ? renderedComponent[configDatatype].component
                      : reduxFormFunction.renderTextField
                  }
                  label="CONFIG Value1"
                ></Field>
              </Grid>

              <Grid item md={6} xs={12}>
                <Field
                  name="configValue2"
                  id="configValue2"
                  component={
                    configDatatype
                      ? renderedComponent[configDatatype]?.component
                      : reduxFormFunction.renderTextField
                  }
                  label="CONFIG Value2"
                ></Field>
              </Grid>

              <Grid item md={6} xs={12}>
                <Field
                  name="configRemarks"
                  id="configRemarks"
                  component={reduxFormFunction.renderTextField}
                  label="CONFIG REMARKS"
                ></Field>
              </Grid>

              <Grid item md={6} xs={12}>
                {isEnabled}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {buttons}
          </Box>
        </Card>
      </form>
    </container>
  );
};

const validate = (formValues) => {
  console.log(formValues);
  const error = {};
  const regExContactNumber =
    /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

//   if (!formValues.etmsApplicationCode) {
//     error.etmsApplicationCode = "Enter the application Code";
//   }
  if (!formValues.configName) {
    error.configName = "Enter  Config Name";
  }

  if (!formValues.configDescription) {
    error.configDescription = "Enter the config Description";
  }

  if (!formValues.configDatatype) {
    error.configDatatype = " Enter the Config Data Type ";
  }

  if (formValues.configDatatype === "INT") {
    if (!/^[0-9]*$/g.test(formValues.configValue1)) {
      error.configValue1 = "Only Numbers are allowed";
    }
  }

  if (formValues.configDatatype === "INT") {
    if (!/^[0-9]*$/g.test(formValues.configValue1)) {
      error.configValue2 = "Only Numbers are allowed";
    }
  }

  if (!formValues.configValue1) {
    error.configValue1 = " Enter config Value1";
  }

  if (!formValues.configRemarks) {
    error.configRemarks = "Enter ConfigRemark";
  }

  return error;
};

export default reduxForm({ form: "GlobalAddorm", validate: validate,  enableReinitialize : true })(
  withStyles(styles)(GlobalAddForm)
);
