import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {   
    loading: null
};

const notificationCatStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const notificationCatCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_CAT_STATE_RESET:
            return notificationCatStateReset(state, action);

        case actionTypes.NOTIFICATION_CAT_CREATE_START:
            return notificationCatCreateStart(state, action);

        default:
            return state;

    }
}

export default reducer;