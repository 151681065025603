import axios from "axios";
import appConfig from '../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

export const gridDataFetchSuccess = (gridData) => {
  return {
    type: 'GRID_DATA_FETCH_SUCCESS',
    gridData: gridData,
  };
};
export const gridDataFetchFail = (errorMsg) => {
  return {
    type: 'GRID_DATA_FETCH_FAIL',
    errorMsg: errorMsg
  };
};

export const gridReloadStart = () => {
  return {
    type: 'GRID_RELOAD_START'
  };
};

export const fetchGridData = (method, url, data) => {
  return dispatch => {
    dispatch(gridReloadStart());
    axios({
      method: method,
      url: BASE_URL + "/" + url,
      data: data
    }).then(res => {
      dispatch(gridDataFetchSuccess(
        res.data
      ));
    });
  }
}

export const fetchMasterGridData = (endpoint) => {
  return dispatch => {
    dispatch(gridReloadStart());
    axios
      .get(BASE_URL + "/" + endpoint)
      .then(res => {
        dispatch(gridDataFetchSuccess(
          res.data
        ));
      }).catch(error => {
        //dispatch(gridDataFetchFail(error.response.data.message));
        //temporary
        console.log('got error but still proceeding with sample data');
        dispatch(gridDataFetchSuccess({
          columns: [
            { title: "Name", field: "name" },
            { title: "Last Name", field: "surname" },
            { title: "Birth Year", field: "birthYear", type: "numeric" },
          ], data: [
            {
              name: "Madhura",
              surname: "Adawadkar",
              birthYear: 1984,
              gender: 'female'
            }, {
              name: "Omkar",
              surname: "Lagu",
              birthYear: 1984,
              gender: 'male'
            },
          ]
        }));
      });
  };
};