import React from 'react';
import StakeholderUserAddForm from './stakeholderUserAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class StakeholderUserCreate extends React.Component {

    componentDidMount() {
        this.props.stakeholderUserStateReset();
    }

    onSubmit = formValues => {
        formValues.isEnabled = 1;
        if (formValues.isNotificationEnable === true) {
            formValues.isNotificationEnable = 1
        } else {
            formValues.isNotificationEnable = 0
        }
        if (formValues.isPrimaryContact === true) {
            formValues.isPrimaryContact = 1
        }
        else {
            formValues.isPrimaryContact = 0
        }
        console.log("formValues in Create: ", formValues)
        this.props.stakeholderUserCreate(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                {alert}
                {progress}
                <StakeholderUserAddForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.stakeholderUserReducer.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        stakeholderUserCreate: (formValues) => dispatch(actions.stakeholderUserCreate(formValues)),
        stakeholderUserStateReset: () => dispatch(actions.stakeholderUserStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StakeholderUserCreate))