import axios from "axios";
import appConfig from '../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

export const gridDataFetchSuccess = (gridData) => {
  return {
    type: 'EVENT_GRID_DATA_FETCH_SUCCESS',
    gridData: gridData,
  };
};
export const gridDataFetchFail = (errorMsg) => {
  return {
    type: 'EVENT_GRID_DATA_FETCH_FAIL',
    errorMsg: errorMsg
  };
};
export const fetchEventGridData = (endpoint) => {
  return dispatch => {

    axios
      .get(BASE_URL + "/" + endpoint)
      .then(res => {
        dispatch(gridDataFetchSuccess(
          res.data
        ));
      }).catch(error => {
        console.log(error);
        dispatch(gridDataFetchFail(error));
      });
  };
};