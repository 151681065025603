import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import ReactPlayer from 'react-player'
import { Container, Card, CardHeader, Divider, CardContent, IconButton, Box, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    videoCont: {
        display: 'flex',
        marginTop: theme.spacing(6),
        outline: 'none',
        padding: '0px',
    }
});

class VIDSVideoPlayer extends React.Component {

     render() {
        const { classes, audioSrc } = this.props;
        console.log("Props", this.props)
        const isEndImageVideo = audioSrc.isEndImageVideo;
        const isStartImageVideo = audioSrc.isStartImageVideo;
        const endImageVideoPath = audioSrc.endImageVideoPath;
        const startImageVideoPath = audioSrc.startImageVideoPath;
        console.log('isEndImageVideo', isEndImageVideo)
        console.log('isStartImageVideo', isStartImageVideo)
        console.log('endImageVideoPath', endImageVideoPath)
        console.log('startImageVideoPath', startImageVideoPath)
        let isEndImageOrVideo = '';
        let isEndImageOrVideoHead = '';
        let isStartImageOrVideo = '';
        let isStartImageOrVideoHead = '';
        if(isEndImageVideo === 0){
            console.log('End image')
            isEndImageOrVideoHead = "End Image";
            isEndImageOrVideo =  <img src={endImageVideoPath} width = '550px' height='350px' alt="EndImage"/>
        }if(isEndImageVideo === 1){
            console.log('End video')
            isEndImageOrVideoHead = "End Video";
            isEndImageOrVideo = <ReactPlayer
                                    url={endImageVideoPath} 
                                    width='550px'
                                    height='350px'
                                />
        }if(isEndImageVideo === null || endImageVideoPath === null ){
            isEndImageOrVideoHead = "End Video Not Available";
            isEndImageOrVideo = null
        }

        if(isStartImageVideo === 0){
            console.log('Start image')
            isStartImageOrVideoHead = "Start Image"
            isStartImageOrVideo =  <img src={startImageVideoPath} width = '550px' height='350px' alt="EndImage"/>
        }if(isStartImageVideo === 1){
            console.log('Start video')
            isStartImageOrVideoHead = "Start Video"
            isStartImageOrVideo = <ReactPlayer
                                    url={startImageVideoPath}
                                    width='550px'
                                    height='350px'
                                />
        }if(isStartImageVideo === null || startImageVideoPath === null){
            isStartImageOrVideoHead = "Start Video Not Available";
            isStartImageOrVideo = null
        }

        let titleName = null;
        console.log("this.props.selectedEquipment", this.props.selectedEquipment)
        if (this.props.selectedEquipment) {
            titleName = this.props.selectedEquipment.txnEventId + " | " + this.props.selectedEquipment.eventDescription;
        }
        if (this.props.selectedEquipment) {
            return (
                <React.Fragment>
                    <Container maxWidth="lg" className={classes.videoCont}>
                        <Card>
                            <CardHeader
                                title={titleName}
                                action={
                                    <IconButton onClick={() => { this.props.onClose() }}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <Typography variant="h6" gutterBottom>
                                            {isStartImageOrVideoHead}
                                        </Typography>
                                        { isStartImageOrVideo}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6" gutterBottom>
                                            {isEndImageOrVideoHead}
                                        </Typography>
                                        {isEndImageOrVideo}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

export default connect(null, null)(withStyles(styles)(VIDSVideoPlayer));