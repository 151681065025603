import React from 'react';
import EquipmentsForm from './equipmentsForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class EquipmentsEdit extends React.Component {

    componentDidMount() {
        this.props.equipmentsStateReset();
    }

    onSubmit = (formValues, eqState) => {
        console.log("Before Edit", formValues);
        console.log("state in editform: ", eqState);
        delete formValues.tableData;
        delete formValues.createdBy;
        delete formValues.updatedBy;
        delete formValues.uidEby;
        delete formValues.uidCby;
        delete formValues.createdAt;
        delete formValues.updatedAt;
        this.props.equipmentsEdit(formValues, eqState);
        console.log("After Edit", formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                { alert}
                { progress}
                <EquipmentsForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.equipmentsReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        equipmentsEdit: (formValues, eqState) => dispatch(actions.equipmentsEdit(formValues, eqState)),
        equipmentsStateReset: () => dispatch(actions.equipmentsStateReset()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentsEdit));