import React from 'react';
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import ShiftAddForm from './shiftAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class ShiftEdit extends React.Component {

    componentDidMount() {
        this.props.shiftStateReset();
    }

    onSubmit = formValues => {
        this.props.shiftEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        console.log("errorMsgCrt", this.props.initialValues);

        return (
            <React.Fragment>
                {alert}
                {progress}
                <ShiftAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {

    return {
        loading: state.shiftReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        shiftEdit: (formValues) => dispatch(actions.shiftEdit(formValues)),
        shiftStateReset: () => dispatch(actions.shiftStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShiftEdit));