import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import getDocDefinition from "./docDefinition";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function printDoc(printParams, gridApi, columnApi, reportName) {
  const docDefinition = getDocDefinition(printParams, gridApi, columnApi);
  pdfMake.createPdf(docDefinition).download(reportName + ".pdf");
}

export default printDoc;
