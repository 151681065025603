/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Button, InputLabel, FormLabel, RadioGroup, Radio, Typography
} from '@material-ui/core';
import Toaster from '../../../components/common/Toaster';
import appConfig from '../../../appConfig.json';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  root: {
    marginTop: '50px',
    clear: 'both'
  },
  submitButton: {
    marginTop: '10px'
  },

  optiongroup: {
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  optionchild: {
    paddingLeft: '5px'
  },
  textArea: {
    display: 'block'
  },
  paramsCard: {
    border: '1px grey solid',
    margin: '10px 0px'
  },
  cardHeader: {
    fontSize: '15px',
    color: 'blue'
  },
  sendButton: {
    marginTop: '10px'
  }
});

class EventManualAlert extends Component {
  constructor() {
    super();
    this.state = {
      serviceTypesList: [],
      aidCategories: [],
      notificationCategories: [],
      messageTemplates: [],
      notificationParams: [],
      loading: false
    };
  }

  componentDidMount() {
    axios
      .get(BASE_URL + "/api/aidCategories?reqType=all")
      .then(res => {
        console.log(res.data);
        this.setState({
          aidCategories: res.data,
        });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });

    axios
      .get(BASE_URL + "/api/locationCategories?reqType=all")
      .then(res => {
        console.log(res.data);
        var emergencyCategory = res.data.find((category) => category.locationCategoryName === 'EMERGENCY SUPPORT');
        if (emergencyCategory != null) {
          this.setState({
            emergencyCategoryId: emergencyCategory.locationCategoryId
          });
        }
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });

    axios
      .get(BASE_URL + "/api/notificationParams?reqType=all")
      .then(res => {
        console.log(res.data);
        this.setState({
          notificationParams: res.data
        });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
    axios
      .get(BASE_URL + "/api/notificationCategories?reqType=all")
      .then(res => {
        console.log(res.data);
        this.setState({
          notificationCategories: res.data
        });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  }

  createOptionsForEmergency(dataArray, options) {
    dataArray.forEach((location) => {
      if (location.hasOwnProperty('locationTypeId')) {
        options.push({ label: location.locationTypeName, class: 'optiongroup', value: location.locationTypeId });
        this.createOptionsForEmergency(location.locationDetails, options);
      } else {
        options.push({ label: location.locationDetailsName, class: 'optionchild', value: location.locationDetailsId, contactEmail : location.locaionDetailsPrimaryEmail , contactMobile: location.locaionDetailsPrimaryMobile });
      }
    });
  }

  createOptionsForHighwayAid(dataArray, options) {
    dataArray.forEach((vehicle) => {
      if (vehicle.hasOwnProperty('assetVehicleCategoryId')) {
        options.push({ label: vehicle.assetVehicleCategoryName, class: 'optiongroup', value: vehicle.assetVehicleCategoryId });
        this.createOptionsForHighwayAid(vehicle.vehicleDetails, options);
      } else {
        options.push({ label: vehicle.assetVehicleDetailsName, class: 'optionchild', value: vehicle.assetVehicleDetailsId , contactMobile: vehicle.assetVehicleDetailsContactNumber});
      }
    });
  }

  createOptionsForStakeholders(dataArray, options) {
    dataArray.forEach((stakeholder) => {
      if (stakeholder.hasOwnProperty('stakeholdersCategoryId')) {
        options.push({ label: stakeholder.stakeholdersCategoryName, class: 'optiongroup', value: '' });
        this.createOptionsForStakeholders(stakeholder.categoryUsers, options);
      } else {
        options.push({ label: stakeholder.stakeholderUserName, class: 'optionchild', value: stakeholder.stakeholderUserId , contactEmail : stakeholder.stakeholderUserEmailId , contactMobile: stakeholder.stakeholderUserMobileNo});
      }
    });
  }

  changeAidCategory(event) {

    let aidCategory = event.target.value;
    let aidCategoryObj = this.state.aidCategories.find((aidCat) => aidCat.aidCategoryName == aidCategory);

    switch (aidCategory) {
      case 'STAKEHOLDER':
        axios
          .get(BASE_URL + "/api/stakeholdersCategories?reqType=all")
          .then(res => {
            console.log(res.data);
            let options = [];
            this.createOptionsForStakeholders(res.data, options);
            this.setState({ serviceTypesList: options, aidCategory: aidCategory, aidCategoryId: aidCategoryObj.aidCategoryId});
            this.setAidID()
          }).catch(error => {
            console.log(error);
            // dispatch(getEventGeneralDetailsFail(error.response.data.message));
          });
        break;
      case 'HIGHWAY AID':
        axios
          .get(BASE_URL + "/api/assetVehicleCategories?reqType=all")
          .then(res => {
            console.log('res1',res.data);
            let options = [];
            this.createOptionsForHighwayAid(res.data, options);
            this.setState({ serviceTypesList: options, aidCategory: aidCategory, aidCategoryId: aidCategoryObj.aidCategoryId });
            this.setAidID()
          }).catch(error => {
            console.log(error);
            // dispatch(getEventGeneralDetailsFail(error.response.data.message));
          });
        break;
      case 'EMERGENCY':
        axios
          .get(BASE_URL + "/api/locationTypes?reqType=all&locationCategoryId=" + this.state.emergencyCategoryId)
          .then(res => {
            console.log('res2',res.data);
            let options = [];
            this.createOptionsForEmergency(res.data, options);
            console.log('options: ', options);
            this.setState({ serviceTypesList: options, aidCategory: aidCategory, aidCategoryId: aidCategoryObj.aidCategoryId });
            this.setAidID()
          }).catch(error => {
            console.log(error);
            // dispatch(getEventGeneralDetailsFail(error.response.data.message));
          });
        break;
    }

  }
  setAidID(){
    for(let i = 0; i < this.state.serviceTypesList.length; i++){
      if(this.state.serviceTypesList[i].class === "optionchild"){
        this.setState({aidId : this.state.serviceTypesList[i].value});
        break
      }
    }
  }
  changeAssetVehicle(event) {
    var selectedAssetVehicle = this.state.assetVehicles.find((veh) => veh.assetVehicleDetailsId == event.target.value);
    this.setState({ assetVehicle: event.target.value, selectedAssetVehicle: selectedAssetVehicle, aidAssigned: false });
  }

  changeNotificationCategory(event) {
    let notificationCategoryId = event.target.value;
    axios
      .get(BASE_URL + "/api/notificationTemplates?reqType=all&notificationCategoryId=" + notificationCategoryId)
      .then(res => {
        console.log(res.data);
        this.setState({ messageTemplates: res.data, notificationCategoryId: notificationCategoryId });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  }
  substituteParams(messageTemplate, notificationParams) {
    console.log(messageTemplate,'message Tempelate')
    if (notificationParams == null) {
      notificationParams = this.state.notificationParams;
    }
    notificationParams.forEach((param) => {
      if (param.value != null && param.value != '') {
        messageTemplate = messageTemplate.replace(param.notificationParamsName, param.value);
      }
    });
    return messageTemplate;
  }

  changeMessageTemplate(event) {
    let messageTemplateId = event.target.value;
    let messageTemplate = {};
    let message = '';
    let template = this.state.messageTemplates.find((messageTemplate) => messageTemplate.notificationTemplateId == messageTemplateId);
    // let messageTemplate = event.target.value;
    if (template) {
      messageTemplate = template.notificationTemplateMessage;
      console.log(messageTemplate,'******messageTemplate*****')
      message = this.substituteParams(messageTemplate);
    }
    
    // let messageTemplateId = event.target.value;
    // let messageTemplate = this.state.messageTemplates.find((messageTemplate) => messageTemplate.notificationTemplateId == messageTemplateId);
    // let message = this.substituteParams(messageTemplate.notificationTemplateMessage);
    this.setState({ messageTemplateId: messageTemplateId, messageTemplate: messageTemplate, message: message });
    console.log('$$$MEssageTemplate',this.state.messageTemplate)
  }
  changeServiceType(event) {
    this.setState({ aidId: event.target.value });
  }
  setParamValue(event, index) {
    let notificationParams = [...this.state.notificationParams];
    // let param = {...params[index]};
    // param.value = event.target.value;
    let param = { ...notificationParams[index], value: event.target.value };
    notificationParams[index] = param;
    console.log(this.state.messageTemplate,'*******this.state.messageTemplate')
    let message = this.substituteParams(this.state.messageTemplate, notificationParams);
    this.setState({ notificationParams: notificationParams, message: message });
  }


  sendManualAlert(values) {
    let contact = [];
    this.state.serviceTypesList.forEach((x)=>{
      console.log('AID', this.state.aidId)
      console.log('value', x.value)
      if(x.value == this.state.aidId){
        console.log('found')
        if(this.state.notificationCategoryId == "1"){
          console.log('value', x.value)
          contact= x.contactMobile
        }
        if(this.state.notificationCategoryId == "2"){
          contact = x.contactEmail;
        }
      }
    })
    console.log('Contact', contact)
    this.props.sendManualAlert({
      eventId: this.props.eventId,
      eventDescription: this.props.eventDescription,
      eventNotificationCategoryId: this.state.notificationCategoryId,
      eventNotificationTemplateId: this.state.messageTemplateId,
      eventNotificationMessage: this.state.message,
      eventNotificationStatus: 1,
      aidCategoryId: this.state.aidCategoryId,
      aidId: this.state.aidId,
      manualAlertRemarks: this.state.remarks,
      contactDetail: contact
    })

    this.setState({
      serviceTypesList: [], 
      aidCategory: '',
      notificationCategoryId: "",
      messageTemplateId: '',
      message: '',
      messageTemplates : [],
      remarks : '',
      notificationParams: this.state.notificationParams.map(x => { 
        x.value = '';
        return x;
      })
    })

    console.log(this.state.notificationParams,"notificationParams")

  }
  render() {
    const { classes } = this.props;
    console.log('props', this.props)
    console.log('state', this.state)
    

    return (
      <div style={{ maxWidth: "100%" }}>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <Typography variant="h5" className="sectionHeading">Aid</Typography>
        <fieldset disabled={this.props.eventStatusName != 'MANAGE'}>
          <FormControl>

            <RadioGroup row value={this.state.aidCategory ? this.state.aidCategory : null} onChange={(event) => this.changeAidCategory(event)}>
              {this.state.aidCategories.map((aidCategory) => {
                return (<FormControlLabel value={aidCategory.aidCategoryName} control={<Radio />} label={aidCategory.aidCategoryName} />);
              })}
            </RadioGroup>
          </FormControl>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className={classes.gridItem}>
              
              {/* {this.state.aidCategoryId 
              ? */}
              <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Service Type for Alerts</InputLabel>
              <Select label="Service Type for Alerts"
                native 
                InputLabelProps={{
                  shrink: true,
                }}
                
                value={this.state.aidId ? this.state.aidId : ''}
                onChange={(event) => this.changeServiceType(event)}>
                {this.state.serviceTypesList.map((option) => {
                  return ( option.class == 'optiongroup' ? <optgroup label={option.label}/> : <option value={option.value} className={classes.optionchild}>{option.label}</option>
                  // <option value={option.value} className={option.class == 'optiongroup' ? classes.optiongroup : classes.optionchild}>{option.label}</option>
                  );
                })}
              </Select>
              </FormControl>
              
               {/* :<Field name="equipmentTypeId" component={reduxFormFunctions.renderSelectField} label="Service Type for Alerts" >
                <option value="" />
                </Field>} */}
                
            </Grid>
          </Grid>
          <Divider variant="list" className={classes.atmsPageSectionDivider} />
          <Typography variant="h5" className="sectionHeading">Notification</Typography>

          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className={classes.gridItem}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Notification Category</InputLabel>
                <Select native label="Notification Category" value={this.state.notificationCategoryId} onChange={(event) => this.changeNotificationCategory(event)}>
                  <option value="" />
                  {this.state.notificationCategories.map((notificationCategory) => {
                    if(this.state.aidCategory === "HIGHWAY AID"){
                      if (notificationCategory.notificationCategoryName == 'SMS'){
                        console.log('AHSDJSA')
                        return (<option value={notificationCategory.notificationCategoryId}>{notificationCategory.notificationCategoryName}</option>)
                      }
                    }
                    else if (notificationCategory.notificationCategoryName == 'SMS' || notificationCategory.notificationCategoryName == 'EMAIL'){
                      return (<option value={notificationCategory.notificationCategoryId}>{notificationCategory.notificationCategoryName}</option>);
                    }
                      
                  })}
                </Select>
              </FormControl>
            </Grid>


            <Grid item md={6} xs={12} className={classes.gridItem}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Message Template</InputLabel>
                <Select native label="Message Template" value={this.state.messageTemplateId} onChange={(event) => this.changeMessageTemplate(event)}>
                  <option value="" />
                  {this.state.messageTemplates.map((messageTemplate) => {
                    return (<option value={messageTemplate.notificationTemplateId}>{messageTemplate.notificationTemplateName}</option>);
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} >
              <FormControl variant="outlined" className={classes.textArea}>
                <TextField
                  label="Selected Template Message Preview"
                  multiline
                  fullWidth
                  variant="outlined"
                  rows={4}
                  readonly
                  value={this.state.message && this.state.notificationCategoryId !== "" ? this.state.message : '' }
                />
                </FormControl>
            </Grid>
          </Grid>
          <Card className={classes.paramsCard}>
            <CardHeader className={classes.cardHeader} title="Param details" />
            <CardContent>
              <Grid container spacing={2}>

                {(this.state.messageTemplates.length !== 0)?
                this.state.notificationParams.map((param, index) => {
                  return (
                    <Grid item md={3} >
                      <FormControl variant="outlined">
                        <TextField
                          variant="outlined"
                          margin="normal"
                          value = {this.state.notificationParams[index].value}
                          fullWidth
                          label={param.notificationParamsName}
                          onChange={(event) => this.setParamValue(event, index)}
                        />
                      </FormControl>
                    </Grid>
                  )
                }): ''
              }


              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} >
              <FormControl variant="outlined" className={classes.textArea}>
                <TextField
                  label="Manual Alert Notification remarks"
                  multiline
                  fullWidth
                  variant="outlined"
                  rows={4}
                  onChange={(event) => this.setState({ remarks: event.target.value })}
                  value={this.state.remarks}
                /></FormControl>
            </Grid>
          </Grid>
          <div>
            <Button className={classes.sendButton} onClick={() => this.sendManualAlert()} disabled={this.props.eventStatusName != 'MANAGE'} color="primary" size="large" variant="contained">SEND</Button>
          </div>
        </fieldset>
      </div >
    );
  }
}
const mapStateToProps = state => {
  return {
    manualAlertSuccess: state.eventAlerts.manualAlertSuccess,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendManualAlert: (body) => dispatch(actions.sendManualAlert(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(EventManualAlert));