import React from 'react';
import EventSubtypeAddForm from './eventSubtypeAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class EventSubtypeCreate extends React.Component {
    componentDidMount() {
        this.props.eventSubtypeStateReset();
    }
    
    onSubmit = formValues => {
        formValues.isEnabled = 1;
        this.props.eventSubtypeCreate(formValues);
        this.props.onClose();
    }

    render() {
        const { classes} = this.props;
        let progress = null;
        let alert = null;

        if(this.props.loading) {
            progress =  <div className={classes.spinDiv}>
                            <CircularProgress size={200} thickness={2} />
                        </div>
        }

        return(
            <React.Fragment>
                {alert}
                {progress}
                <EventSubtypeAddForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit}/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.eventSubtypeReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        eventSubtypeCreate: ( formValues ) => dispatch( actions.eventSubtypeCreate( formValues ) ),
        eventSubtypeStateReset: () => dispatch(actions.eventSubtypeStateReset())
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EventSubtypeCreate));