import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const directionCreateStart = () => {
    return {
        type: actionTypes.DIRECTION_CREATE_START
    };
};

export const directionCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(directionCreateStart());
            const response = await axios.post(BASE_URL + "/api/direction", formValues); //Not using Response var anywhere
            console.log("Created response", response);
            dispatch(showToaster('success', 'Direction Successfully Created.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message))
        }
    };
};

export const directionStateReset = () => {
    return {
        type: actionTypes.DIRECTION_STATE_RESET
    };
};

export const directionEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        directionName: formValues.directionName,
        directionDescription: formValues.directionDescription,
        directionAlias: formValues.directionAlias,
        directionType: formValues.directionType,
        directionLatitudeStart: formValues.directionLatitudeStart,
        directionLatitudeEnd: formValues.directionLatitudeEnd,
        directionLongitudeStart: formValues.directionLongitudeStart,
        directionLongitudeEnd: formValues.directionLongitudeEnd,
        totalKilometers: formValues.totalKilometers,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(directionCreateStart());
            const response = await axios.patch(BASE_URL + "/api/direction/" + formValues.directionId, values);            
            dispatch(showToaster('success', 'Direction Successfully Edited.'));

        } catch (error) {            
            dispatch(showToaster('error', error.response.data.message))
        }
    };
}


