import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import * as actions from '../../store/actions/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoadCanvasTemplate, validateCaptcha, loadCaptchaEnginge } from 'react-simple-captcha';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import setAuthToken from "../../utils/setAuthToken";
import Toaster from '../../components/common/Toaster';
var CryptoJS = require("crypto-js");

library.add(fas);


const styles = theme => ({
  image: {
    padding: 0,
    height: '100%',
    maxHeight: '90vh',
    maxWidth: '100%',
  },
  paper: {
    margin: theme.spacing(0, 4),
    position: 'absolute',
    top: '50%',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    margin: theme.spacing(2, 1),
  },
  spinDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
});
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      captchaValue: "", // Add captchaValue to your state
      isCaptchaValid: false, // Add isCaptchaValid to your state
      captchaKey: 1,
    };
  }

  componentDidMount() {
    if (localStorage.jwtToken) {
      const token = localStorage.jwtToken;
      const userDetailId = localStorage.userDetailId;
      setAuthToken(token);
      this.props.autoLoginUser(userDetailId);
    }

    //this.loadCaptcha();
    loadCaptchaEnginge(6, 'black', 'white');
  }

  //componentDidUpdate(prevProps) {
  //  //if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
  //    if (!this.props.auth.isAuthenticated) {
  //      console.log("Is auth?????????????", this.props.auth.isAuthenticated);
  //      setTimeout(() => {
  //        let canvas = document.getElementById('canv');
  //        if (canvas) {
  //          loadCaptchaEnginge(6, 'black', 'white');
  //        }
  //      }, 500);
  //    }
  //  //}
  //}

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated 
    ) {
      if (!this.props.auth.isAuthenticated) {
        console.log("Is auth?????????????", this.props.auth.isAuthenticated);
        setTimeout(() => {
          let canvas = document.getElementById('canv');
          if (canvas) {
            loadCaptchaEnginge(6, 'black', 'white');
          }
        }, 500);
      }
    }
  }
  



  //loadCaptcha = () => {
  //  this.setState(
  //    (prevState) => ({ captchaKey: prevState.captchaKey + 1 }),
  //    () => {
  //      loadCaptchaEnginge(6, 'black', 'white', this.state.captchaKey);
  //    }
  //  );
  //};


  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      // this.props.history.push("/userDetails");
      // this.props.history.push("/audit");
      // this.props.history.push("/quickevent");
      // this.props.history.push("/eventflow");
      // this.props.history.push("/locationdetails");
      // this.props.history.push("/locationtype");
      // this.props.history.push("/equipments");
      //this.props.history.push("/manageevent");           
      //this.props.history.push("/shift");
      //this.props.history.push("/assetVehicleCat");
      // this.props.history.push("/violationdashboard");
      // this.props.history.push("/violationchallan");
      // this.props.history.push("/assetVehicleDetails");
      //this.props.history.push("/eventType");
      //this.props.history.push("/eventSubtype");
      //this.props.history.push("/sopCat");
      //this.props.history.push("/sopItem");
      // this.props.history.push("/sopMapping");
      //this.props.history.push("/hotlistVehicle");
      // this.props.history.push("/chainage");
      // this.props.history.push("/section");
      //this.props.history.push("/stakeholderCat");
      // this.props.history.push("/stakeholderUser");
      //this.props.history.push("/eventAudit");
      // this.props.history.push("/mapdashboard");
      // this.props.history.push("/menu");
      // this.props.history.push("/notificationCat");
      // this.props.history.push("/notificationTemplate");
      //this.props.history.push("/eventRule");  
      // this.props.history.push("/landingPage");
      this.props.history.push("/home");
      //this.props.history.push("/directions");
      // this.props.history.push("/trends");
      // this.props.history.push("/userrole")
      // this.props.history.push("/ecb")
      // this.props.history.push("/stakeholderUser")
      this.props.getGlobalConfig();
      this.props.getEntitlements();

    }
  }

  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleCaptchaChange = value => {
    // Update the captcha value and check its validity
    this.setState({
      captchaValue: value,
      isCaptchaValid: value !== '',
    });
  };

 

  onSubmit = event => {
    event.preventDefault();

    // Check if the captcha is valid
    const user_captcha = document.getElementById('user_captcha_input').value;
    console.log('Entered Captcha:', user_captcha);
    
    if (validateCaptcha(user_captcha)===true ) {
      console.log('Captcha is correct. Proceeding with login.');
      var encryptedPassword = CryptoJS.AES.encrypt(this.state.password, "DEENR$INFRA$SUPERSECRET$ENCKEY$").toString();
      const userData = {
        strategy: "local",
        loginId: this.state.username,
        loginPassword: encryptedPassword,
        appCode: "atms",
      };
  
      this.props.loginUser(userData);
      // ...
    }
     else {
      console.log('Captcha does not match. Alerting user.');
      alert('Captcha Does Not Match');
      event.preventDefault();
      //loadCaptchaEnginge();
      //this.loadCaptcha();
      
    }
  };

  render() {
    const { classes } = this.props;

    if (this.props.auth.loading) {
      return (
        <div className={classes.spinDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <AppBar position="static">
          <Typography variant="h6" className={classes.title}>
            DriveSafe
          </Typography>
        </AppBar>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <Grid container id="rootCont">
          <Grid item sm={6} id="imgItem">
            <div>
              <img src="/images/Night-Highway.jpg" alt="ATMS Login Img" className={classes.image} />
            </div>
          </Grid>
          <Grid item sm={6} id="loginItem">
            <div className={classes.paper} id="formDiv">
              <img src="DEENRLOGO.png" style={{ height: '200px', width: '200px' }} alt="Logo" />
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="User Name"
                  autoFocus
                  value={this.state.username}
                  onChange={this.onChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <Typography component="h1" variant="h5">
                <LoadCanvasTemplate
                  id="captcha_value"
                />
              </Typography>
              {/* {!captchaCode? */}
              <TextField
                variant="outlined"
                margin="normal"
                placeholder="Enter Captcha Value*"
                id="user_captcha_input"
                name="user_captcha_input"
                type="text"
                required
                // disabled={captchaCode ? true : false}
                inputProps={{ maxLength: 6 }} />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  //disabled={!this.state.isCaptchaValid}
                >
                  Sign In
                </Button>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </form>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: userData => dispatch(actions.loginUser(userData)),
    autoLoginUser: userDetailsId => dispatch(actions.autoLoginUser(userDetailsId)),
    getGlobalConfig: () => dispatch(actions.getGlobalConfig()),
    getEntitlements: () => dispatch(actions.getEntitlements()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));