import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ResponsiveBar } from '@nivo/bar'
import { Typography, Divider } from '@material-ui/core';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px'
  },
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  text2: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(12)
  }

});

// const CarCell = ({
//   position,
//   size,
//   x,
//   y,
//   color,
//   fill,
//   opacity,
//   borderWidth,
//   borderColor,
//   data,
//   onHover,
//   onLeave,
//   onClick,
// }) => (
//   <span style="font-size: {};">
//     <FontAwesomeIcon color={color} size={1} icon={['fas', 'car-side']} />
//   </span>
// )

class VehicleAidChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      vehicleBarData: [],
      keys: []
    }
  }

  async componentDidMount() {
    try {
      const res = await axios.get(BASE_URL + "/api/dashboard/vehicleAid-pie");
      if (res.data != null) {
        this.setState({
          vehicleBarData: res.data.data,
          keys: res.data.keys
        })
        console.log("res data", res.data);
        console.log("bar data", this.state.vehicleBarData);
      }
    }
    catch (error) {
      console.log("error");
    }
  }

  render() {
    const { classes } = this.props;

    const CustomFontTick = tick => {
      return (
        <g transform={`translate(${tick.x},${tick.y + 18})`}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={this.props.modelStatus === true ? { fontSize: 10, fontWeight: 'bold' } : { fontSize: 4.5, fontWeight: 'bold', transform: 'scale(1, 1.5)' }}
          >{tick.value}
          </text>
        </g>
      )
    }

    return (
      <React.Fragment>
        <div className={classes.div}>
          <Typography className={classes.text} variant="h6" align="left">Vehicle Aid</Typography>
        </div>
        <Divider />
        <ResponsiveBar
          data={this.state.vehicleBarData}
          keys={this.state.keys}
          indexBy="Vehicle"
          margin={{ top: 30, right: 0.5, bottom: 90, left: 40 }}
          padding={0.5}
          groupMode="stacked"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          enableGridY={false}

          markers={[
            {
              axis: 'y',
              value: 0,
              lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
              legendOrientation: 'vertical',
            },
          ]}

          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Vehicle Type',
            legendPosition: 'middle',
            legendOffset: 31,
            itemWidth: 40,
            itemHeight: 20,
            renderTick: CustomFontTick
          }}
          axisLeft={{
            tickSize: 2,
            tickPadding: 3,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -28,
            format: e => Math.floor(e) === e && e
          }}
          labelSkipWidth={5}
          labelSkipHeight={5}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'row',
              justify: false,
              translateX: -20,
              translateY: -25,
              itemsSpacing: 10,
              itemWidth: 80,
              itemHeight: 15,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 10,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </React.Fragment>
    )

  }

}

export default withStyles(styles)(VehicleAidChart)