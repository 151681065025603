import React, { Component } from "react";
import MaterialTable from "material-table";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import Button from '@material-ui/core/Button';


import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EditIcon from '@material-ui/icons/Edit';
import Toaster from '../../../components/common/Toaster';
import axios from "axios";
//import { showToaster } from "../../store/actions/common";
import appConfig from "../../../appConfig.json";

const BASE_URL = appConfig.BASE_URL;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const styles = theme => ({
  root: {
    marginTop: '50px',
    clear: 'both'
  },
});

class EventAlertVMS extends Component {
  constructor() {
    super();
    this.state = {
      vmsMessageResponse: null
    };
  }

  componentDidMount() {
    console.log('componentDidMount props: ', this.props);

    this.props.fetchData({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId });
  }

  componentDidUpdate() {
    if (this.props.vmsAlerts == null) {
      this.props.fetchData({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId });
    }
  }

  componentWillReceiveProps(newProps, oldProps) {
    console.log('got new props: ', newProps);
    if ((newProps.notificationCategoryId != this.state.notificationCategoryId) || (newProps.eventId != this.state.eventId)) {
      this.setState({
        notificationCategoryId: newProps.notificationCategoryId,
        eventId: newProps.eventId
      });
      this.props.fetchData({ eventNotificationCategoryId: newProps.notificationCategoryId, eventId: newProps.eventId });
    }

  }
  reload() {
    this.props.fetchData({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId });

  }

  sendMessageOnVMS(rowData) {
    console.log('sending...', rowData);
    this.props.sendVMSMessage({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId, id: rowData.txnEventAlertId });
  }
 
  async handleSendVMSMessage(rowData) {
    console.log('Sending message...', rowData);
    try {
      const vmsMessageLinesData = [
        {
          lineMessage: rowData.message,
          font: '5',
          fontColor:'Red',
        }
      ];
      const requestData = {
        eqpId: rowData.vmsId, // Use vmsId as eqpId
        numberOfLines: 1, // You are sending a single line message
        msgtypeId:this.props.eventType.eventTypeId,
        leftImageNo: 3,
        leftImageX: 40,
        leftImageY: 30,
        messageLocationX:15 ,
        messageLocationY: 16/* Specify the message location Y value as needed */,
        rightImageNo: 4/* Specify the right image number as needed */,
        rightImageX: 45/* Specify the right image X value as needed */,
        rightImageY: 50/* Specify the right image Y value as needed */,
        vmsMessageLinesData: vmsMessageLinesData,
      };
  
      const response = await axios.post(BASE_URL + "/api/vms", requestData);

      console.log('Response from server:', response.data);
    } catch (error) {
      //dispatch(showToaster('error', error.response.data.message));
      console.log("error",error)
    }
  }













  render() {
    const { classes } = this.props;
    var vmsAlerts = this.props.vmsAlerts;
    console.log("VMS Alert Message>>>>>>>>>>>",this.props);
    if (this.props.vmsAlerts == null) {
      vmsAlerts = [];
    }
    var columns = [
      { title: 'ID', field: 'vmsId' },
      { title: 'Description', field: 'description' },
      { title: 'Direction', field: 'direction' },
      { title: 'Proposed Message', field: 'message' },
      { title: 'Action', render: (rowData) => { return <Button disabled={this.props.eventStatusName!= 'MANAGE' || rowData.eventNotificationStatus == 1} color="primary" size="large" variant="contained" onClick={() => { 
        this.sendMessageOnVMS(rowData);
        this.handleSendVMSMessage(rowData);}}>{rowData.eventNotificationStatus == 0 || rowData.eventNotificationStatus == null ? 'SEND' : 'SENT'}</Button> } }
    ]
    return (
      <div className={classes.root} style={{ maxWidth: "100%" }}>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={vmsAlerts}
          options={{ toolbar: false, actionsColumnIndex: -1, search: false, headerStyle: { fontWeight: 600 } , paging: false}}
          title=""

        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    vmsAlerts: state.eventAlerts.vmsAlerts,
    vmsMessageResponse: state.eventAlerts.vmsMessageResponse,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: (url) => dispatch(actions.fetchVMSAlerts(url)),
    sendVMSMessage: (id) => dispatch(actions.sendVMSMessage(id))
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(EventAlertVMS));