import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Appbar from './Appbar';
import Sidebar from './Sidebar';
import WebAlert from './WebAlert';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    display: 'flex',
  }
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.state = {open: false};
  }

  handleDrawerOpen() {
    this.setState({ open : true });
    console.log("Dashboard.js-handleDrawerOpen : open", true);
    this.props.drawerOpen();
  }

  handleDrawerClose() {
    this.setState({ open : false });
    console.log("Dashboard.js-handleDrawerClose : open :", false);
    this.props.drawerClose();
  }

  render() {
      const { classes } = this.props;

      return(
        <div className={classes.root}>
            <CssBaseline />
            <Appbar open={this.state.open} handleDrawerOpen={this.handleDrawerOpen}></Appbar>
            <WebAlert/>
            <Sidebar open={this.state.open} handleDrawerClose={this.handleDrawerClose}></Sidebar>
        </div>
      );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    drawerOpen: () => dispatch(actions.drawerOpen()),
    drawerClose: () => dispatch(actions.drawerClose())
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Dashboard));