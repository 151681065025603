import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Toaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          message: props.message || '',
          severity : "success"
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.open != prevProps.open) {
            this.setState({ 
                open: this.props.open,
                message : this.props.message,
                severity : this.props.severity                  
            });
        }
        

    }

    handleClose() {
        if(this.props.onClose){
          this.props.onClose();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}open={this.state.open} autoHideDuration={6000} onClose={()=>this.handleClose()}>
                <Alert onClose={()=>this.props.onClose&&this.handleClose()} severity={this.props.severity}>
                    {this.state.message}
                </Alert>
            </Snackbar>
        )

    }
}

export default Toaster;