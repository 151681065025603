import React from 'react';
import EquipCatAddForm from './equipCatAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class EquipCatEdit extends React.Component {

    componentDidMount() {
        this.props.equipCatStateReset();
    }

    onSubmit = formValues => {

        //delete formValues.userRoleId;
        this.props.equipCatEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        console.log("errorMsgCrt", this.props.initialValues);

        return (
            <React.Fragment>
                { alert}
                { progress}
                <EquipCatAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.equipCatReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        equipCatEdit: (formValues) => dispatch(actions.equipCatEdit(formValues)),
        equipCatStateReset: () => dispatch(actions.equipCatStateReset()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipCatEdit));