import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DataGrid from '../../components/mastersGrid/DataGrid';
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import { Container, Card, CardContent, Grid, Box, Button } from '@material-ui/core';
import axios from 'axios';
import Toaster from '../../components/common/Toaster';
import VehicleAuditDetails from './VehicleAuditDetails';
import VehicleAuditFilter from './VehicleAuditFilter';
import appConfig from '../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f4f6f8',
  },
  addButton: {
    float: 'right'
  },
  header: {
    display: 'inline'
  },
  list: {
    marginTop: theme.spacing(3)
  },
  modal: {
    'overflow-y': 'auto',
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  sheader: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  bulkAuditButton: {
    float: 'right',
    marginBottom: theme.spacing(1)
  }
});

class VehicleAuditDash extends React.Component {

  currentSelectedRow = null;
  constructor() {
    super();
    this.state = {
      selectedSpeedRaw: null,
      urlParams: [],
      selection: [],
      isAudited: "Non Audit",  // By default We show Non Audited entries
      openToast: false,
      msgToast: "",
      toastSeverity: "success"
    };
    this.grid = React.createRef();
    this.detailsRef = React.createRef();
  }

  componentDidMount() {

    // Get the Directions
    this.props.getDirections();

    // Get the Vehicle Class
    this.props.getVehicleClass();

  }

  onSubmit = (urlParams) => {

    console.log("onSubmit Vehicle Audit = urlParams", urlParams);
    this.setState({ urlParams: urlParams })
  }

  reloadAudit = () => {
    this.grid.current.reload();
  }

  bulkAudit = async (event) => {

    event.preventDefault();
    console.log(this.state.selection);
    let selectedIds = this.state.selection.map((row) => row.txnSpeedRawId);
    console.log(selectedIds)


    try {
      var response = await axios.patch(BASE_URL + "/api/vehicleBulkAudit/", { txnSpeedRawIds: selectedIds });
      console.log(response);
      this.setState({ openToast: true, msgToast: "Successfully Bulk Audited Selected Entries ", toastSeverity: "success" });

      // Reload the datagrid in the Vehicle Audit Dashboard
      this.reloadAudit();

    } catch (error) {
      console.log(error.response);
      this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
    }
  }


render() {
    const { classes } = this.props;
    let apiUrl = null;

    if (this.state.isAudited === "Non Audit") {
      apiUrl = "api/vehicleAudit?isAudited=0";
    } else if (this.state.isAudited === "All") {
      apiUrl = "api/vehicleAudit";
    }


    return (
      <Container maxWidth="xl" className={classes.root}>
        {alert}
        <Typography variant="h4" gutterBottom className={classes.header}>
          Vehicle Audit
        </Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <VehicleAuditFilter
                onSubmit={(urlParams) => {
                  this.onSubmit(urlParams)
                }}
                radioIsAudit={(isAudited) => {
                  this.setState({ isAudited: isAudited })
                }}
                isAudited={this.state.isAudited}
              />
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Box mt={3}>
                <Button color="primary" className={classes.bulkAuditButton} variant="contained" onClick={this.bulkAudit} disabled={this.state.selection.length < 2}>Bulk Audit</Button>
                <DataGrid downloadReport={true} ref={this.grid} endpoint={apiUrl} isMasterGrid={false} urlParams={this.state.urlParams}
                  multiselect={true}
                  onSelectionChange={(selection) => {
                    this.setState({ selection: selection })
                  }}
                  onRowClick={(event, rowData) => {
                    console.log("I came inside Edit Click", event, rowData);
                    this.setState({ selectedSpeedRaw: rowData });
                    // this.detailsRef.current.scrollTo(0,0);
                  }}
                  idField="txnSpeedRawId"
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <VehicleAuditDetails ref={this.detailsRef}
                selectedSpeedRaw={this.state.selectedSpeedRaw}
                reloadAudit={this.reloadAudit}
              />
            </Grid>
          </Grid>
        </Box>
        <Toaster
          open={this.state.openToast}
          message={this.state.msgToast}
          severity={this.state.toastSeverity}
          onClose={() => {
            this.setState({ openToast: false });
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDirections: () => dispatch(actions.getDirections()),
    getVehicleClass: () => dispatch(actions.getVehicleClass()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VehicleAuditDash));