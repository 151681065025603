import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Select, TextField, Typography, FormControl, InputLabel, Grid, FormControlLabel, Radio, Button, MenuItem, RadioGroup } from '@material-ui/core';
import axios from 'axios';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import Toaster from '../../../components/common/Toaster';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    selectFormControl: {
         minWidth: 120,
    },
    header: {
        display: 'inline'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    gridCont: {
        margin : '8px 0px'
    }
});

class VdScrutiny extends React.Component {
    constructor() {
        super();
        this.state = {
            violationId: null,            // 1. Violation ID 
            vehicleNumber: null,          // 2. Vehicle Number 
            vehicleClassCode : null,      // 3. Vehicle Class Id 
            vehicleMake : null,           // 4. Vehicle Make
            vehicleModel: null,           // 5. Vehicle Model
            vehicleColor : null,          // 6. Vehicle Color
            radioAR : "ACCEPTED",         // 7. Radio Group
            vehicleClassName : null,      // 8. Vehicle Class Name
            notViolatedBox : false,
            disableAccept : false,
            violationScrutinyRemarks : "", // Violation Scrunity Remarks
            openToast : false,
            msgToast : "",
            toastSeverity : "success",
            vehicleCategories : [],
            vehicleCategoryId : null,
            noSelectRow : true,             // When row is not selected 
        };
    }

    async componentDidMount() {
        let response;

        // Get the vehicle categories and populate the vehicle category dropdown
        try {
            response = await axios.get(BASE_URL + "/api/vehicleCategories?reqType=all");
            this.setState({ vehicleCategories : response.data })
            let vCategoryTemp = response.data.find(vehicleCategory => vehicleCategory.vehicleCategoryName === "COMMERCIAL");
            this.setState({ vehicleCategoryId : vCategoryTemp.vehicleCategoryId });
        } catch (error) {

        }    
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.selectedViolation) {
            if (prevProps.selectedViolation) {
                if (this.props.selectedViolation.txnSpeedViolationId != prevProps.selectedViolation.txnSpeedViolationId) {
                    this.setState({ 
                        violationId: this.props.selectedViolation.txnSpeedViolationId,
                        vehicleNumber: this.props.selectedViolation.vehicleNumber,
                        vehicleClassCode: this.props.selectedViolation.vehicleClass.vehicleClassCode,
                        disableAccept : false,
                        noSelectRow : false,
                    });
                }
            } else {
            this.setState({ 
                    violationId: this.props.selectedViolation.txnSpeedViolationId,
                    vehicleNumber: this.props.selectedViolation.vehicleNumber,
                    vehicleClassCode: this.props.selectedViolation.vehicleClass.vehicleClassCode,
                    disableAccept : false,
                    noSelectRow : false,
                });
            }
        }

    }

    handleRadioAR = (event) => {
        this.setState({
            radioAR : event.target.value,
        })
    }
    
    /*
     * Change in vehicleclass on the scrunity box
     */

    handleVehicleClassChange = async (event) => {
        let scrutinyParams = {};

        // console.log("handleVehicleClassChange", event);
        let vehicleClass = this.props.vehicleClasses.find((vehicleClass) => vehicleClass.vehicleClassCode ===  event.target.value)
        // console.log(vehicleClass);
        
        this.setState({
            vehicleClassCode: event.target.value,
            vehicleClassName : vehicleClass.vehicleClassName
        })

        if (this.props.selectedViolation) {
            
            if (this.props.selectedViolation.speedViolationType === 0) {
                
                /* Case 1 : SPOT SPEED VIOLATION */
                
                scrutinyParams.speedViolationType = this.props.selectedViolation.speedViolationType;
                scrutinyParams.vehicleActualSpeed = this.props.selectedViolation.vehicleActualSpeed;
                scrutinyParams.vehicleClassCode = event.target.value;
                
                axios
                .patch(BASE_URL + "/api/scrutinyVehicleClass", scrutinyParams)
                .then(res => {
                    if (res.data == true) {
                        console.log("ViolationScrutiny : The vehicle has still violated SPOT SPEED after correcting the vehicle class.");
                    } else if (res.data == false) {
                        console.log("ViolationScrutiny : The vehicle has not violated SPOT SPEED after correcting the vehicle class.");
                        this.setState({ notViolatedBox : true });
                        this.setState({ radioAR : "REJECTED" });   // SELECT Only Rejected by Default
                        this.setState({ disableAccept : true });
                    }
                }).catch(error => {
                    console.log("Error in ViolationScrutiny API: SPOT SPEED", error);       
                });
            } else if (this.props.selectedViolation.speedViolationType === 1) {
            
                /* Case 2 : SECTION SPEED VIOLATION */
                
                scrutinyParams.speedViolationType = this.props.selectedViolation.speedViolationType;
                scrutinyParams.vehicleActualSpeed = this.props.selectedViolation.vehicleActualSpeed;
                scrutinyParams.vehicleClassCode = this.state.vehicleClassCode;
                scrutinyParams.sectionId = this.props.selectedViolation.sectionId;
                
                axios
                .patch(BASE_URL + "/api/scrutinyVehicleClass", scrutinyParams)
                .then(res => {
                    if (res.data == true) {
                        console.log("ViolationScrutiny : The vehicle has still violated SPOT SPEED after correcting the vehicle class.");
                    } else if (res.data == false) {
                        console.log("ViolationScrutiny : The vehicle has not violated SECTION SPEED after correcting the vehicle class.");
                        this.setState({ notViolatedBox : true });
                        this.setState({ radioAR : "REJECTED" });   // SELECT Only Rejected by Default
                        this.setState({ disableAccept : true });
                    }
                }).catch(error => {
                    console.log("Error in ViolationScrutiny API: SECTION SPEED", error);
                });
            }
        }

    }

    /*
     * ACCEPT or REJECT the Violation
     */
    
    onSubmit = async (event) => {
        event.preventDefault();
        console.log("onsubmit state",this.state);
        let response;

        try {
      
            if (this.state.radioAR === "ACCEPTED") {
                let acceptParams = {
                    vehicleNumber : this.state.vehicleNumber,       
                    vehicleClassCode : this.state.vehicleClassCode,
                    violationStatus : this.state.radioAR,
                    vehicleMake : this.state.vehicleMake,
                    vehicleModel : this.state.vehicleModel,
                    vehicleColor : this.state.vehicleColor,
                    vehicleCategoryId : this.state.vehicleCategoryId,
                };
                response = await axios.patch(BASE_URL + "/api/speedViolation/" + 
                                                    this.props.selectedViolation.txnSpeedViolationId,
                                                    acceptParams);
                this.setState({ openToast : true, msgToast: "ACCEPTED violation for Vehicle Number: " + this.state.vehicleNumber, toastSeverity: "success"});
            } else if (this.state.radioAR === "REJECTED")  {
                let rejParams = {
                    vehicleNumber : this.state.vehicleNumber,
                    vehicleClassCode : this.state.vehicleClassCode,
                    violationStatus : this.state.radioAR,
                    violationScrutinyRemarks : this.state.violationScrutinyRemarks,
                    vehicleCategoryId : this.state.vehicleCategoryId,
                };

                response = await axios.patch(BASE_URL + "/api/speedViolation/" + 
                                                    this.props.selectedViolation.txnSpeedViolationId,
                                                    rejParams);
                this.setState({ openToast : true, msgToast: "REJECTED violation for Vehicle Number: " + this.state.vehicleNumber, toastSeverity: "success"});
            }

            // // Reload the datagrid in the Violation Dasboard
            // this.props.reloadViolations();

            console.log("speedViolation", response)
          } catch (error) {
            console.log(error.response);
            this.setState({ openToast : true, msgToast: error.response.data.message, toastSeverity: "error"});
          }
    }

    render() {                
        const { classes } = this.props;        
        let rejectRemarksDiv = null;
        let fromAnprImgPath = null;
        let toAnprImgPath = null;
        
        if (this.state.noSelectRow) {
            return <div>No Row Selected</div>
        }

        if (this.props.selectedViolation) {
            fromAnprImgPath = "NoImageAvailable.jpg";
            toAnprImgPath = "NoImageAvailable.jpg";
            if (this.props.selectedViolation.fromAnprImgPath) {
                fromAnprImgPath = this.props.selectedViolation.fromAnprImgPath
            }
            if (this.props.selectedViolation.toAnprImgPath) {
                toAnprImgPath = this.props.selectedViolation.toAnprImgPath
            }
        }

        if (this.state.radioAR === "REJECTED") {
            rejectRemarksDiv = <Grid item xs={12}>
                                        <TextField
                                            name="violationScrutinyRemarks"
                                            label="Violation Scrutiny Remarks"
                                            value={this.state.violationScrutinyRemarks}
                                            fullWidth
                                            rows={1}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>
        }
        
        return (
            <React.Fragment>
                <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                    <Grid container spacing={3}>
                        {/* 
                          * Violation Scrutiny Div. Accept and Reject the violation
                          * 
                          */}
                        <Grid item xs={6} >
                            <Grid container spacing={1} className={classes.gridCont}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Scrutiny
                                </Typography>
                            </Grid>
                            <Grid container spacing={1} className={classes.gridCont}>
                            {/* 1.Violation ID */}
                                <Grid item xs={2} >
                                    <TextField
                                        id="violationId"
                                        name="violationId"
                                        label="Violation Id"
                                        value={this.state.violationId}
                                        fullWidth
                                        disabled
                                        onChange={this.handleInputChange}
                                        className={classes.dateInput}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant = "outlined"
                                    />
                                </Grid>
                                
                                {/* 2.Vehicle Number*/}
                                <Grid item xs={2}>
                                    <TextField
                                        id="vehicleNumber"
                                        name="vehicleNumber"
                                        label="Vehicle Number"
                                        value={this.state.vehicleNumber}
                                        fullWidth
                                        disabled
                                        onChange={this.handleInputChange}
                                        className={classes.dateInput}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant = "outlined"
                                    />
                                </Grid>

                                 {/* 3 Vehicle Class*/}
                                <Grid item xs={8} >
                                    <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                        <InputLabel>Vehicle Class</InputLabel>    
                                        <Select
                                            id="Vehicle Class"
                                            name="vehicleClassCode"
                                            label="Vehicle Class"
                                            value={this.state.vehicleClassCode}
                                            onChange={this.handleVehicleClassChange}
                                            >
                                            {this.props.vehicleClasses && this.props.vehicleClasses.map((vehicleClass) => {
                                                return (
                                                    <MenuItem value={vehicleClass.vehicleClassCode}>{vehicleClass.vehicleClassName}</MenuItem>
                                                );           
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} className={classes.gridCont}>
                            
                            {/* 4 Vehicle Make */}
                                <Grid item xs={4} >
                                    <TextField
                                        id="vehicleMake"
                                        name="vehicleMake"
                                        label="Vehicle Make"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        variant = "outlined"
                                    />
                                </Grid>
                            
                            {/* 5 Vehicle Model*/}
                                <Grid item xs={4}>
                                    <TextField
                                        id="vehicleModel"
                                        name="vehicleModel"
                                        label="Vehicle Model"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        variant = "outlined"
                                    />
                                </Grid>

                            {/* 6 Vehicle Color*/}
                                <Grid item xs={4}>
                                    <TextField
                                        id="vehicleColor"
                                        name="vehicleColor"
                                        label="Vehicle Color"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        variant = "outlined"
                                    />
                                </Grid>
                            </Grid>
                        
                            <Grid container spacing={1}
                                    className={classes.gridCont}>
                                {/* 4 Vehicle Categories */}
                                <Grid item xs={6} >
                                    <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>    
                                        <Select
                                            id="vehicleCategoryId"
                                            name="vehicleCategoryId"
                                            label="Vehicle Category"
                                            value={this.state.vehicleCategoryId}
                                            onChange={this.handleInputChange}
                                            >
                                            {this.state.vehicleCategories && this.state.vehicleCategories.map((vehicleCategory) => {
                                                return (
                                                    <MenuItem value={vehicleCategory.vehicleCategoryId}>{vehicleCategory.vehicleCategoryName}</MenuItem>
                                                );           
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                  {/* Accept/Reject Radio button*/}
                                  <Grid item xs={6} >
                                    <RadioGroup row name="accept_reject" value={this.state.radioAR} onChange={this.handleRadioAR}>
                                        <FormControlLabel
                                            value="ACCEPTED"
                                            control={<Radio color="primary" />}
                                            label="Accept"
                                            labelPlacement="bottom"
                                            disabled={this.state.disableAccept}
                                        />

                                        <FormControlLabel
                                            value="REJECTED"
                                            control={<Radio color="primary" />}
                                            label="Reject"
                                            labelPlacement="bottom"
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} className={classes.gridCont}>
                               {/* Reject remarks */}
                               {rejectRemarksDiv}
                            </Grid>

                            <Grid container spacing={1} className={classes.gridCont}>
                              
                                <Grid item xs={3} >
                                    {/* Accept/Reject Submit button*/}
                                    <Button variant="contained" color="primary" fullWidth type="submit">
                                        Submit
                                    </Button>
                                </Grid>

                          </Grid>
                        </Grid>

                        {/* 
                          * Violation Proof Div. Show the fromAnprImgPath and toAnprImgPath
                          * 
                          */}
                        <Grid item xs={6} >
                            <Grid container spacing={1} className={classes.gridCont}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Violation Proof
                                </Typography>
                            </Grid>
                            <Grid container spacing={1}>
                            
                                {/* 1. From ANPR IMG PATH */}
                                 <Grid item xs={6}>
                                     <div>
                                        <Typography variant="subtitle2" gutterBottom className={classes.header}>
                                            From ANPR Path
                                        </Typography>
                                    </div>
                                    <img className={classes.image}
                                        height="300"
                                        src={fromAnprImgPath} 
                                        alt={this.props.selectedViolation ? this.props.selectedViolation.vehicleClass.vehicleClassName : null } 
                                    />
                                </Grid>
                                
                                
                               {/* 2. To ANPR IMG PATH */}
                               {this.props.violationType != 0 && <Grid item xs={6}>
                                    <div>
                                        <Typography variant="subtitle2" gutterBottom className={classes.header}>
                                            To ANPR Path
                                        </Typography>
                                    </div>
                                    <img className={classes.image}
                                        height="300"
                                        src={toAnprImgPath} 
                                        alt={this.props.selectedViolation ? this.props.selectedViolation.vehicleClass.vehicleClassName : null } 
                                    />
                                </Grid>
                                }
                            </Grid>   
                        </Grid>
                    </Grid>
                </form>
                <ConfirmationDialog
                    primaryOption="Ok"
                    open={this.state.notViolatedBox}
                    message={ "For changed vehicle class: " + this.state.vehicleClassName  +
                              " there is no Speed violation. "}
                    onClose={() => {
                        this.setState({ notViolatedBox : false });
                    }}
                />
                <Toaster
                   open={this.state.openToast}
                   message={this.state.msgToast}
                   severity={this.state.toastSeverity}
                   onClose={() => {
                    console.log("I came here in closing toaster");
                    if (this.state.toastSeverity === "success") {
                        // Reload the datagrid in success toaster only
                        this.setState({ noSelectRow : true });
                        this.props.reloadViolations();
                    }
                    this.setState({  openToast : false });
                   }}
                />
            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        vehicleClasses : state.audVioCmnReducer.vehicleClasses,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VdScrutiny));