import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    loading: false,
    eventTypes: [],
    eventSubtypes: [],
    eventSopCategories: [],
    eventSopItems: [],
    error: null
};

const sopMapStateReset = (state, action) => {
    return updateObject(state, { loading: false, eventSubtypes: [], eventSopItems: [], error: null });
};

const getEventTypes = (state, action) => {
    return updateObject(state, { eventTypes: action.eventTypes })
}

const getEventSubtypes = (state, action) => {
    return updateObject(state, { eventSubtypes: action.eventSubtypes })
}

const getSopCategories = (state, action) => {
    return updateObject(state, { eventSopCategories: action.eventSopCategories })
}

const getSopItems = (state, action) => {
    return updateObject(state, { eventSopItems: action.eventSopItems })
}

const sopMapCreateStart = (state, action) => {
    return updateObject(state, { loading: true, error: null })
}

const sopMapError = (state, action) => {
    return updateObject(state, { loading: false, error: action.error })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SOP_MAP_GET_EVENT_TYPE:
            return getEventTypes(state, action);
        case actionTypes.SOP_MAP_GET_EVENT_SUBTYPE:
            return getEventSubtypes(state, action);
        case actionTypes.SOP_MAP_STATE_RESET:
            return sopMapStateReset(state, action);
        case actionTypes.GET_SOP_CATEGORY:
            return getSopCategories(state, action);
        case actionTypes.GET_SOP_ITEM:
            return getSopItems(state, action);
        case actionTypes.SOP_MAP_CREATE_START:
            return sopMapCreateStart(state, action);
        case actionTypes.SOP_MAP_ERROR:
            return sopMapError(state, action);
        default:
            return state;
    }
}

export default reducer;