import React from 'react';
import { withRouter } from 'react-router-dom';  //to use history.push
import EcbAddForm from './ecbAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";



const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class EcbCreate extends React.Component {
    componentDidMount() {
        this.props.ecbStateReset();
    }
    onSubmit = async (formValues, ecbState) => {
        await this.props.ecbCreate(formValues, ecbState);
        if (formValues.isEvent === true) { //If isEvent is true then only redirect the user to addevent page
            const eventId = this.props.eventId
            console.log("eventId : ", eventId)
            this.props.history.push({
                pathname: '/addevent',
                state: {
                    eventId: eventId,
                    fromRout: 'ecb',
                }
            })
        }
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                {alert}
                {progress}
                <EcbAddForm onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        loading: state.ecbReducer.loading,
        eventId: state.ecbReducer.eventId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ecbCreate: (formValues, ecbState) => dispatch(actions.ecbCreate(formValues, ecbState)),
        ecbStateReset: () => dispatch(actions.ecbStateReset())
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EcbCreate)))  //wraping all inside withRouter so that i can use history.push