import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, } from '@material-ui/core';
import * as actions from '../../../store/actions/index';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8'
    },
    button1: {
        marginLeft: '50px',
        marginTop: '8px'
    },
    button2: {
        marginLeft: '50px',
        marginTop: '8px'
    },
    header: {
        display: 'inline'
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },

    searchFilter: {
        marginLeft: '5px'
    }
});

class EcbSearchFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: null,
            toDate: null,
            equipmentTypeId: 0,
            equipmentId: 0,
            ecbCallTypeId: 0,
            callerName: '',
            callerContact: '',
        };
        this.grid = React.createRef();
    }

    componentDidMount() {
        this.props.getEcbTypes();
        this.props.getEcbCallTypes();
        this.props.getEcbCallStatuses();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.equipmentTypeId !== prevState.equipmentTypeId) {
            this.props.getEcbNames(this.state.equipmentTypeId)
        }
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    //This handling date I copied from other Masters I don't understand it

    handleFromDateTimeChange = (dateTime) => {
        var fDate = new Date(dateTime);
        let todayDate = new Date();
        if (fDate > todayDate) {
            this.props.showToaster('error', 'Cannot Take future time')
        } else {
            this.setState({
                fromDate: dateTime
            })
        }
    }
    handleToDateTimeChange = (dateTime) => {
        var tDate = new Date(dateTime);
        let todayDate = new Date();
        if (tDate > todayDate) {
            this.props.showToaster('error', 'Cannot Take future time')
        } else {
            this.setState({
                toDate: dateTime
            })
        }
    }

    onSearchSubmit = (event) => {
        event.preventDefault();
        const urlParams = [{ 'key': 'reqPage', value: 'dashboard' }];

        if (this.state.equipmentId) {
            urlParams.push({ 'key': 'equipmentId', value: this.state.equipmentId });
        }
        if (this.state.ecbCallTypeId) {
            urlParams.push({ 'key': 'ecbCallTypeId', value: this.state.ecbCallTypeId });
        }
        if (this.state.ecbCallStatusId) {
            urlParams.push({ 'key': 'ecbCallStatus', value: this.state.ecbCallStatusId });
        }
        if (this.state.callerName.length > 0) {
            urlParams.push({ 'key': 'callerName', value: this.state.callerName });
        }
        if (this.state.callerContact > 0) {
            urlParams.push({ 'key': 'callerContact', value: this.state.callerContact });
        }

        if (this.state.fromDate || this.state.toDate) {

            var fDate = new Date(this.state.fromDate);
            var tDate = new Date(this.state.toDate);

            if (Date.parse(tDate) > Date.parse(fDate)) {

                if (this.state.fromDate) {
                    urlParams.push({ 'key': 'fromDate', value: this.state.fromDate.toISOString().slice(0, 16) });
                }
                if (this.state.toDate) {
                    urlParams.push({ 'key': 'toDate', value: this.state.toDate.toISOString().slice(0, 16) });
                }
                console.log("urlParams: ", urlParams)
                console.log("Filters : " + JSON.stringify(urlParams))
                this.props.onSearchSubmit(urlParams);

            } else {
                this.props.showToaster('error', 'From date time should be past of To date time')
                console.log("errorMessage : To date should be the past date of From Date");
            }
        }
        this.props.onSearchSubmit(urlParams);
    }

    resetEcbFilter = () => {
        this.setState({
            fromDate: null,
            toDate: null,
            equipmentId: "",
            equipmentTypeId: "",
            ecbCallTypeId: null,
            callerName: '',
            callerContact: ''
        })
        this.props.reset();
    }


    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit} >
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={12}>
                            <Field
                                name="equipmentTypeId"
                                id="equipmentTypeId"
                                component={reduxFormFunctions.renderSelectField}
                                onChange={this.onChange}
                                label="ECB Type"
                            >
                                <option key={this.state.equipmentTypeId} value={''}>{''}</option>
                                {this.props.ecbTypes.map((ecb) => {
                                    return (
                                        <option key={ecb.equipmentTypeId} value={ecb.equipmentTypeId}>
                                            {ecb.equipmentTypeName}
                                        </option>
                                    )
                                })}

                            </Field>
                        </Grid>

                        <Grid item md={2} xs={12}>
                            <Field
                                name="equipmentId"
                                id="equipmentId"
                                component={reduxFormFunctions.renderSelectField}
                                onChange={this.onChange}
                                label="ECB Name"
                            >
                                <option key={this.state.equipmentId} value={''}>{''}</option>
                                {this.props.equipmentNames.map((equipment) => {
                                    return (
                                        <option key={equipment.equipmentId} value={equipment.equipmentId}>
                                            {equipment.equipmentName}
                                        </option>
                                    )
                                })}

                            </Field>
                        </Grid>
                        <Grid item md={2} xs={12} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    id="fromDate"
                                    name="fromDate"
                                    label="From"
                                    format="DD-MM-YYYY HH:mm"
                                    disableFuture={true}
                                    ampm={true}
                                    value={this.state.fromDate}
                                    onChange={(val) => this.handleFromDateTimeChange(val)} />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={2} xs={12} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    id="toDate"
                                    name="toDate"
                                    label="To"
                                    ampm={true}
                                    disableFuture={true}
                                    format="DD-MM-YYYY HH:mm"
                                    value={this.state.toDate}
                                    onChange={(val) => this.handleToDateTimeChange(val)} />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={2} xs={12}>
                            <Field
                                name="ecbCallTypeId"
                                id="ecbCallTypeId"
                                component={reduxFormFunctions.renderSelectField}
                                onChange={this.onChange}
                                label="Ecb Call Type"
                            >
                                <option key={this.state.ecbCallTypeId} value={''}>{''}</option>
                                {this.props.callTypes.map((callType) => {
                                    return (
                                        <option key={callType.ecbCallTypeId} value={callType.ecbCallTypeId}>
                                            {callType.ecbCallTypeName}
                                        </option>
                                    )
                                })}

                            </Field>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Field
                                label=" Caller Name"
                                name="callerName"
                                id="callerName"
                                component={reduxFormFunctions.renderTextField}
                                onChange={this.onChange}
                            >
                            </Field>
                        </Grid>

                        <Grid item md={2} xs={12}>
                            <Field
                                label="Caller Number"
                                name="callerContact"
                                id="callerContact"
                                component={reduxFormFunctions.renderTextField}
                                onChange={this.onChange}
                            >
                            </Field>
                        </Grid>

                        <Grid item md={2} xs={12}>
                            <Button className={classes.button1} variant="contained" color="primary" startIcon={<SearchIcon />} type="submit">
                                Search
                            </Button>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Button className={classes.button2} variant="contained" color="primary" startIcon={<RefreshIcon />} type="reset" onClick={this.resetEcbFilter}>
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        ecbTypes: state.ecbReducer.ecbTypes,
        callTypes: state.ecbReducer.callTypes,
        callStatuses: state.ecbReducer.callStatuses,
        equipmentNames: state.ecbReducer.equipmentNames,
        timeValue: state.common.globalConfig.date_filter_range
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEcbTypes: () => dispatch(actions.getEcbTypes()),
        getEcbCallTypes: () => dispatch(actions.getEcbCallTypes()),
        getEcbCallStatuses: () => dispatch(actions.getEcbCallStatuses()),
        getEcbNames: (state) => dispatch(actions.getEcbNames(state)),
        showToaster: (x, y) => dispatch(actions.showToaster(x, y)),
    };
};

EcbSearchFilters = connect(mapStateToProps, mapDispatchToProps)(EcbSearchFilters)

export default reduxForm({
    form: 'EcbSearchFilters'
})(withStyles(styles)(EcbSearchFilters));