import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, RadioGroup, Radio, FormLabel, FormControl, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import appConfig from '../../../../appConfig.json';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

class NotificationTemplateAddForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notificationCategoryId: 0
        };
    }

    componentDidMount() {
        this.props.getNotificationCat();      
    }

    onChange = event => {
        console.log("event.target.id=" + event.target.id);
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add New Notification Template"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Notification Template"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Notification Template"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item item md={6} xs={12}>
                                    <Field name="notificationCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Notification Category" id="notificationCategoryId" >
                                        <option value={""}>{'Select Notification Category'}</option>
                                        {this.props.ArrNotificationCat.map((notificationCatDropDown) => <option key={notificationCatDropDown.notificationCategoryId} value={notificationCatDropDown.notificationCategoryId}>{notificationCatDropDown.notificationCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationTemplateName" id="notificationTemplateName" component={reduxFormFunctions.renderTextField} label="Notification Template Name" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationTemplateDescription" id="notificationTemplateDescription" component={reduxFormFunctions.renderTextField} label="Notification Template Description" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationTemplateMessageSubject" id="notificationTemplateMessageSubject" component={reduxFormFunctions.renderTextField} label="Notification Template Message Subject" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationTemplateMessageHeader" id="notificationTemplateMessageHeader" component={reduxFormFunctions.renderTextField} label="Notification Template Message Header" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationTemplateMessage" id="notificationTemplateMessage" component={reduxFormFunctions.renderTextField} label="Notification Template Message" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};

    if (!formValues.notificationCategoryId) {
        error.notificationCategoryId = "Select Notification Category"
    }
    if (formValues.notificationCategoryId) {
        if (formValues.notificationCategoryId == "") {
            error.notificationCategoryId = "Select Notification Category"
        }
    }

    if (!formValues.notificationTemplateName) {
        error.notificationTemplateName = "Enter Notification Template Name";
    }

    if (!formValues.notificationTemplateDescription) {
        error.notificationTemplateDescription = "Enter Notification Template Description";
    }

    if (formValues.notificationTemplateDescription) {
        if (formValues.notificationTemplateDescription.length > 201) {
            error.notificationTemplateDescription = "Minimum characters should be 200 only";
        }
    }

    if (!formValues.notificationTemplateMessage) {
        error.notificationTemplateMessage = "Enter Notification Template Message";
    }

    return error;
};

const mapStateToProps = state => {
    return {
        ArrNotificationCat: state.notificationTemplateReducer.ArrNotificationCat
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        getNotificationCat: () => dispatch(actions.getNotificationCat())       
    };
};

NotificationTemplateAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationTemplateAddForm)


export default reduxForm({
    validate: validate,
    form: 'NotificationTemplateAddForm'
})(withStyles(styles)(NotificationTemplateAddForm))
