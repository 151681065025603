import React from 'react';
import SOPMapAddForm from './SOPMapAddForm';
import * as actions from '../../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class SOPMapCreate extends React.Component {

    componentDidMount() {
        this.props.sopMapStateReset();
    }


    onSubmit = async (formValues, sopMapState) => {
        formValues.isEnabled = 1;
        await this.props.sopMapCreate(formValues, sopMapState);

        if (!this.props.error) {
            this.props.onClose();
        }
    }

    render() {
        let initialValues = {
            eventTypeId: this.props.eventTypeId,
            eventSubtypeId: this.props.eventSubtypeId
        }
        const { classes } = this.props;
        let progress = null;
        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        return (
            <React.Fragment>
                <SOPMapAddForm initialValues={initialValues} onClose={this.props.onClose} type="Add" onSubmit={this.onSubmit} />
                {progress}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.sopMapReducer.loading,
        error: state.sopMapReducer.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sopMapCreate: (formValues, sopMapState) => dispatch(actions.sopMapCreate(formValues, sopMapState)),
        sopMapStateReset: () => dispatch(actions.sopMapStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SOPMapCreate));