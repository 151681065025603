import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px'
  }
});

class EquipmentHealthChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      barData: [],
      keys: []
    }
  }

  async componentDidMount() {
    try {
      const res = await axios.get(BASE_URL + "/api/dashboard/equipmentHealth");
      if (res.data != null) {
        this.setState({
          barData: res.data.data,
          keys: res.data.keys
        })
        console.log("res data", res.data.data);
        console.log("Full res data", res.data);
        console.log("Bar data", this.state.barData);
        console.log("keys", this.state.keys);
      }
    }
    catch (error) {
      console.log("error");
    }
  }

  render() {
    const { classes } = this.props;
    const CustomFontTick = tick => {
      return (
        <g transform={`translate(${tick.x},${tick.y + 18})`}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={this.props.modelStatus === true ? { fontSize: 10, fontWeight: 'bold' } : { fontSize: 8, fontWeight: 'bold' }}
          >{tick.value}
          </text>
        </g>
      )
    }

    console.log("Bar Data final", this.state.barData)

    return (
      <React.Fragment>
        <div className={classes.div}>
          <Typography className={classes.text} variant="h6" align="left">Equipment Health</Typography>
        </div>
        <Divider />
        <ResponsiveBar
          data={this.state.barData}
          layout="vertical"
          enableGridY={false}
          keys={this.state.keys}
          indexBy="equipment"
          margin={{ top: 10, right: 2, bottom: 70, left: 32 }}
          padding={0.5}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={({ id, data }) => id == 'up' ? '#92e217' : '#f44336'}
          colorBy="id"
          tooltip={({ id, value, color }) => (
            <strong>
              {id}: {Math.abs(value)}
            </strong>
          )}
          markers={[
            {
              axis: 'y',
              value: 0,
              lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
              legendOrientation: 'vertical',
            },
          ]}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'Equipment',
            // legendPosition: 'middle',
            // legendOffset: 25
            renderTick: CustomFontTick
          }}
          axisLeft={{
            legend: 'count',
            legendPosition: 'middle',
            legendOffset: -25,
            tickSize: 2,
            tickPadding: 3,
            tickRotation: 0,
            format: e => Math.floor(e) === e && e,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          labelFormat={value => Math.abs(value)}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </React.Fragment>
    )

  }

}

export default withStyles(styles)(EquipmentHealthChart)