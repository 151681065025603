import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Divider,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import format from "date-fns/format";
import commonStyles from "../../commonStyles";
import axios from "axios";
import appConfig from "../../appConfig.json";
import Toaster from "../../components/common/Toaster";
import { showToaster } from "../../store/actions/common";
import BackdropLoading from "../../components/common/BackdropLoading";

// import PDFExportPanel from "./pdfExport/PDFExportPanel.js";

import ReportsFooter from "../../components/common/ReportsFooter";
// import chainage from "../OperationMaster/chainage/chainage";

const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => ({
  ...commonStyles,
  cardHeaderStyle: {
    textAlign: "center",
    color: "#002884",
    padding: 0,
    marginLeft: "10px",
  },
  title: {
    margin: theme.spacing(2, 1),
    color: "white",
  },
  mopCell: {
    background: "white",
    borderLeft: "1px solid lightgrey !important",
    borderRight: "1px solid lightgrey !important",
    // borderBottom: '1px solid lightgrey !important'
  },
  colHeader: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    border: "1px solid darkgrey !important",
    padding: "10px",
  },
  reportFooter: {
    margin: theme.spacing(2),
  },
  toolTip: {
    paddingBottom: "15px",
    marginLeft: "10px",
    fontWeight: "bold"
  }
});

function VidsHourlyEvents(props) {
  const { classes } = props;

  let dispatch = useDispatch();

  const today = new Date();
  const presentDate = format(today, "yyyy-MM-dd");
  today.setDate(today.getDate() - parseInt(730));
  const twoYearAgoDate = format(today, "yyyy-MM-dd");

  const componentRef = useRef();
  const [filters, setFilters] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [txnDate, setTxnDate] = useState(presentDate);
  const [toDate, setToDate] = useState(presentDate);

  const [shiftId, setShiftId] = useState([]);
  const [directionId, setDirectionId] = useState([]);
  const [chainageId, setChainageId] = useState([]);
  const [tcId, setTcId] = useState([]);
  const [plazaCode, setPlazaCode] = useState([]);

  const [shifts, setShifts] = useState([]);
  const [directions, setDirections] = useState([]);
  const [chainages, setChainage] = useState([]);
  const [tollCollectors, setTollCollectors] = useState([]);
  const [plazas, setPlazas] = useState([]);

  const toasterOpen = useSelector((state) => state.common.toasterOpen);
  const toasterMessageConfig = useSelector(
    (state) => state.common.toasterMessageConfig
  );

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [footerValues, setFooterValues]= useState({});

  const [selectedDirection, setselectedDirection] = useState([]);


  //const  onDirectionChange = (event) => {
  //  setDirectionId(event.target.value)
  //  const direction = [];
  //   directions.forEach((e) =>{
  //    console.log("%%%%%%%%%%%%%%",e.directionId)
  //    if(event.target.value.includes(e.directionId)){
  //      console.log("###############")
  //    direction.push(e.directionId) 
  //  }
  //  console.log("direction", direction, )
  //})
  //  setselectedDirection(direction)
  //  // console.log("event.target.value",event.target.value)
  //  console.log("directionnnnnnn",directions,direction)
  //}
  const onDirectionChange = (event) => {
    const selectedDirections = event.target.value;
    setDirectionId(selectedDirections);
    console.log("Selected Directions:", selectedDirections);
  };

  const onChainageChange = (event) => {
    setChainageId(event.target.value);
    console.log("Chainage>>>>>>>>>>>>>>>>",event.target.value);
  };


  const dataCompletenessApplicable = 1;

  useEffect(() => {
    const reportGet = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          BASE_URL +
          "/api/summaryReports/vidsHourlyEvents?txnDate=" +
          txnDate +
          "&toDate=" +
          toDate
        );
        setIsLoading(false);
        setTotalRowCount(response.data.rowTotal);
        setRowData(response.data.data);
      } catch (error) {
        setIsLoading(false);
        dispatch(showToaster("error", error.response.data.message));
      }
    };
    reportGet();
  }, []);

  useEffect(() => {
   

    const getDirections = async () => {
      try {
        const res = await axios.get(BASE_URL + "/api/direction?reqType=all");
        setDirections(res.data);
      } catch (error) {
        dispatch(showToaster("error", error.response.data.message));
      }
    };

    

    const getChainage = async () => {
        try {
          const res = await axios.get(BASE_URL + "/api/chainage?reqType=all");
          setChainage(res.data);
        } catch (error) {
          dispatch(showToaster("error", error.response.data.message));
        }
    };

    
    getDirections();
    getChainage();
    
  }, []);

  const getJoinedNames = (idArray, fieldName) => {
    let result = [];

    if (fieldName === "PLAZA") {
      plazas.forEach((plaza) => {
        if (idArray.indexOf(plaza.plazaCode) > -1) {
          result.push(plaza.plazaName);
        }
      });
    }

    if (fieldName === "DIRECTION") {
      directions.forEach((direction) => {
        if (idArray.indexOf(direction.directionId) > -1) {
          result.push(direction.directionName);
        }
      });
    }

    if (fieldName === "CHAINAGE") {
      chainages.forEach((chainage) => {
        if (idArray.indexOf(chainage.chainageId) > -1) {
          result.push(chainage.chainageName);
        }
      });
    }

    if (fieldName === "TC") {
      tollCollectors.forEach((tc) => {
        if (idArray.indexOf(tc.loginId) > -1) {
          result.push(tc.loginId);
        }
      });
    }

    if (fieldName === "SHIFT") {
      shifts.forEach((shift) => {
        if (idArray.indexOf(shift.shiftId) > -1) {
          result.push(shift.shiftName);
        }
      });
    }

    return result.join(", ");
  };

  //const getJoinedNames = (selected, fieldName) => {
  //  const selectedDirectionNames = selected.map((selectedDirectionId) => {
  //    const selectedDirection = directions.find(
  //      (direction) => direction.directionId === selectedDirectionId
  //    );
  //    return selectedDirection ? selectedDirection.directionName : "";
  //  });
  
  //  return selectedDirectionNames.join(", ");
  //};

  const getSelectedChainageNames = () => {
    return chainages
      .filter((chain) => chainageId.includes(chain.chainageId))
      .map((selectedChainage) => selectedChainage.chainageName)
      .join(", ");
  };


const handleSubmit = async (event) => {
    event.preventDefault();
    const urlParams = [];
    setPageLoading(true);
    setRowData([])

    if (txnDate) {
      urlParams.push({ key: "txnDate", value: txnDate });
    }

    if (plazaCode.length > 0) {
      urlParams.push({ key: "plazaCode", value: plazaCode });
    }

    if (tcId.length > 0) {
      urlParams.push({ key: "tc", value: tcId });
    }

    if (directionId.length > 0) {
      urlParams.push({ key: "directionId", value: directionId });
    }

    if (chainageId.length > 0) {
      urlParams.push({ key: "chainageId", value: chainageId });
    }

    if (shiftId.length > 0) {
      urlParams.push({ key: "shiftId", value: shiftId });
    }

    setFilters(urlParams);
    let allUrlParams = "";
    if (urlParams != null && urlParams.length > 0) {
      urlParams.forEach((urlParamObj) => {
        allUrlParams =
          allUrlParams + "&" + urlParamObj["key"] + "=" + urlParamObj["value"];
      });
    }

    try {
      let queryString = allUrlParams.slice(1);
      const response = await axios.get(
        BASE_URL + "/api/summaryReports/vidsHourlyEvents?" + queryString
      );
      setTotalRowCount(response.data.rowTotal);
      setRowData(response.data.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      dispatch(showToaster("error", error.response.data.message));
    }
  };

  const onResetClick = () => {
    setTxnDate(presentDate);
    setToDate(presentDate);
    setShiftId([]);
    setDirectionId([]);
    setChainageId([]);
    setTcId([]);
    setPlazaCode([]);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const cellStyle = (params) => {
    if (params.value == null) {
      return {
        backgroundColor: "white",
      };
    } else {
      let style = {
        border: "1px solid lightgrey !important",
        padding: "10px",
      };
      if (
        params.colDef.field.toLowerCase() == "total" ||
        (params.data.lane && params.data.lane.toLowerCase() == "total") ||
        params.colDef.field == "TC"
      ) {
        style.fontWeight = "bold";
      }
      return style;
    }
  };

  const downloadCSV = () => {
    var params = {
      fileName: 'VIDS Hourly Events Count Report.csv',
    };
    gridApi.exportDataAsCsv(params);
  };


  const getFooterValues=(values) => {
    console.log("values",values);
    setFooterValues(values);
    
  }
console.log("selectedDirection", selectedDirection)
console.log("cchainages", chainages)

  return pageLoading ? (
    <React.Fragment>
      <BackdropLoading open={pageLoading} />
      <Toaster
        open={toasterOpen}
        message={toasterMessageConfig && toasterMessageConfig.message}
        severity={toasterMessageConfig && toasterMessageConfig.type}
      />
    </React.Fragment>
  ) : (
    <div className={classes.etmsPageOuterDiv}>
      <Card className={classes.etmsPageOuterCard}>
        <CardHeader
          title={
            <Typography variant="h2" className={classes.cardHeaderStyle}>
              VIDS Hourly Event Count Report
            </Typography>
          }
          action={
            rowData && rowData.length > 0 && totalRowCount > 0 ?
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={downloadCSV}
                  style={{ margin: "5px" }}
                >
                  Download EXCEL
                </Button>
                {/* <PDFExportPanel
                  gridApi={gridApi}
                  columnApi={gridColumnApi}
                  heading="Hourly Traffic Count Report"
                  filters={filters}
                  shifts={shifts}
                  directions={directions}
                  lanes={lanes}
                  tcs={tollCollectors}
                  plazas={plazas}
                  reportName="hourlyTrafficReport"
                  footerValues={footerValues}
                  allReportFilters={["PLAZA", "SHIFT", "LANE", "TC", "DIRECTION"]}
                /> */}
              </div> : null
          }
        />
        <Divider />
        <CardContent>
          <Card>
            <CardHeader
              title="Filters"
              style={{ padding: 0, marginLeft: "10px" }}
            />
            <CardContent>
            {/* <div className={classes.toolTip}>Maximum allowed date range is 90 days.</div> */}
              <Grid container spacing={1}>
                <Grid item lg={2}>
                  <FormControl
                    variant="outlined"
                    component="fieldset"
                    fullWidth={true}
                  >
                    <TextField
                      id="txnDate"
                      variant="outlined"
                      label="Txn Date"
                      type="date"
                      onChange={(event) => setTxnDate(event.target.value)}
                      value={txnDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: presentDate,
                        min:twoYearAgoDate
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Plaza</InputLabel>
                    <Select
                      name="plaza"
                      value={plazaCode}
                      multiple
                      input={<OutlinedInput label="Plaza" />}
                      onChange={(event) => setPlazaCode(event.target.value)}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "PLAZA")
                      }
                    >
                      {plazas &&
                        plazas.map((plaza) => {
                          return (
                            <MenuItem
                              key={plaza.plazaId}
                              value={plaza.plazaCode}
                            >
                              <Checkbox
                                checked={
                                  plazaCode.indexOf(plaza.plazaCode) > -1
                                }
                              />
                              <ListItemText primary={plaza.plazaName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Shift</InputLabel>
                    <Select
                      name="shift"
                      size="small"
                      multiple
                      value={shiftId}
                      input={<OutlinedInput label="Shift" />}
                      onChange={(event) => setShiftId(event.target.value)}
                      label="Select Shift"
                      renderValue={(selected) =>
                        getJoinedNames(selected, "SHIFT")
                      }
                    >
                      {shifts &&
                        shifts.map((shift) => {
                          return (
                            <MenuItem key={shift.shiftId} value={shift.shiftId}>
                              <Checkbox
                                checked={shiftId.indexOf(shift.shiftId) > -1}
                              />
                              <ListItemText primary={shift.shiftName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid> */}
{/*

                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Direction</InputLabel>
                    <Select
                      name="directionId"
                      multiple
                      value={directionId}
                      input={<OutlinedInput label="Direction" />}
                    //   onChange={(event) => setDirectionId(event.target.value)}
                      onChange={onDirectionChange}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "DIRECTION")
                      }
                    >
                      {directions &&
                        directions.map((direction) => {
                          return (
                            <MenuItem key={direction.directionId} value={direction.directionId}>
                              <Checkbox
                                checked={directionId.indexOf(direction.directionId) > -1}
                              />
                              <ListItemText primary={direction.directionName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>          

                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Chainage</InputLabel>
                    <Select
                      name="chainageId"
                      multiple
                      value={chainageId}
                      input={<OutlinedInput label="Chainage" />}
                      onChange={(event) => setChainageId(event.target.value)}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "CHAINAGE")
                      }
                    >
                      {chainages &&
                        chainages.filter(lane => selectedDirection.includes(lane.directionId)).map((lane) => {
                            console.log("chainages", lane)
                          return (
                            <MenuItem key={lane.chainageId} value={lane.directionId}>
                              <Checkbox
                                checked={chainageId.indexOf(lane.chainageId) > -1}
                              />
                              <ListItemText primary={lane.chainageName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>*/}

         {/* <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Direction</InputLabel>
                    <Select
                      name="directionId"
                      multiple
                      value={directionId}
                      input={<OutlinedInput label="Direction" />}
                    //   onChange={(event) => setDirectionId(event.target.value)}
                      onChange={onDirectionChange}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "DIRECTION")
                      }
                    >
                      {directions &&
                        directions.map((direction) => {
                          console.log("direction@@@@@@@@@", direction)
                          return (
                            <MenuItem key={direction.directionId} value={direction.directionId}>
                              <Checkbox
                                checked={directionId.indexOf(direction.directionId) > -1}
                              />
                              <ListItemText primary={direction.directionName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid> 
     



                    <Grid item lg={2} md={3}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Chainage</InputLabel>
                        <Select
                          name="chainageId"
                          multiple
                          value={chainageId}
                          input={<OutlinedInput label="Chainage" />}
                          onChange={onChainageChange}
                          renderValue={getSelectedChainageNames}
                        >
                          {chainage &&
                            chainage.map((chain) => (
                              
                              <MenuItem
                                key={chain.chainageId}
                                value={chain.chainageId}
                              >
                                <Checkbox
                                  checked={chainageId.includes(chain.chainageId)}
                                />
                                <ListItemText primary={chain.chainageName} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid> */}

                  <Grid item lg={2}>
                    <FormControl
                      variant="outlined"
                      component="fieldset"
                      fullWidth={true}
                    >
                      <InputLabel>Direction</InputLabel>
                      <Select
                        name="directionId"
                        multiple
                        value={directionId}
                        input={<OutlinedInput label="Direction" />}
                        onChange={onDirectionChange} // Use your updated onDirectionChange function
                        renderValue={(selected) =>
                          getJoinedNames(selected, "DIRECTION")
                        }
                      >
                        {directions &&
                          directions.map((direction) => {
                            return (
                              <MenuItem
                                key={direction.directionId}
                                value={direction.directionId}
                              >
                                <Checkbox
                                  checked={directionId.indexOf(direction.directionId) > -1}
                                />
                                <ListItemText primary={direction.directionName} />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={2} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Chainage</InputLabel>
                      <Select
                        multiple
                        value={chainageId}
                        name="chainageId"
                        input={<OutlinedInput label="Chainage" />}
                        onChange={onChainageChange} // Use your updated onChainageChange function
                        renderValue={getSelectedChainageNames}
                      >
                        {chainages &&
                          chainages.map((chain) => (
                            <MenuItem
                              key={chain.chainageId}
                              value={chain.chainageId}
                            >
                              <Checkbox
                                checked={chainageId.includes(chain.chainageId)}
                              />
                              <ListItemText primary={chain.chainageName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>











                {/* <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Toll Collector</InputLabel>
                    <Select
                      name="tcId"
                      multiple
                      value={tcId}
                      input={<OutlinedInput label="Toll Collector" />}
                      onChange={(event) => setTcId(event.target.value)}
                      renderValue={(selected) => getJoinedNames(selected, "TC")}
                    >
                      {tollCollectors &&
                        tollCollectors.map((tollCollector) => {
                          return (
                            <MenuItem
                              key={tollCollector.userDetailId}
                              value={tollCollector.loginId}
                            >
                              <Checkbox
                                checked={
                                  tcId.indexOf(tollCollector.loginId) > -1
                                }
                              />
                              <ListItemText primary={tollCollector.loginId} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid
                  item
                  lg={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    type="submit"
                    fullWidth
                  >
                    Search
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={onResetClick}
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {rowData && rowData.length > 0 && totalRowCount > 0 ? (
            <div>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100vh",
                  width: "95vw",
                  marginTop: "1rem",
                  overflow: "hidden",
                  border: "1px solid black",
                }}
              >
                <AgGridReact
                  ref={componentRef}
                  rowData={rowData}
                  onGridReady={onGridReady}
                  suppressRowTransform={true}
                  defaultColDef={{ resizable: true }}
                  frameworkComponents={{
                    dateTimeCellRenderer: DateTimeCellRenderer,
                  }}
                >
                  <AgGridColumn
                    field="dateTime"
                    headerClass={classes.colHeader}
                    headerName="Date"
                    cellStyle={cellStyle}
                    width={200}
                  />
                  {/* <AgGridColumn
                    field="TXN DATE"
                    headerClass={classes.colHeader}
                    headerName="Txn Date"
                    cellStyle={cellStyle}
                    cellRendererSelector={(params) => {
                      return {
                        component: "dateTimeCellRenderer",
                      };
                    }}
                    width={200}
                  /> */}
                  {Object.keys(rowData[0]).map((key) =>
                    key !== "dateTime" && key !== "TXN DATE" ? (
                      <AgGridColumn
                        field={key}
                        cellStyle={cellStyle}
                        headerClass={classes.colHeader}
                        headerName={key.toUpperCase()}
                        width={100}
                        flex={1}
                      />
                    ) : null
                  )}
                </AgGridReact>
              </div>
              <div className={classes.reportFooter}>
                <ReportsFooter
                  filters={filters}
                  reportCode={"VIDSEVTHUR"}
                  dataCompletenessApplicable={dataCompletenessApplicable}
                  getFooterValues={getFooterValues}
                />
              </div>
            </div>
          ) : (
            <div>
              {isLoading ? (
                <BackdropLoading open={isLoading} />
              ) : (
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  No Data available for Selected filters
                </Typography>
              )}
            </div>
          )}
        </CardContent>
      </Card>
      <Toaster
        open={toasterOpen}
        message={toasterMessageConfig && toasterMessageConfig.message}
        severity={toasterMessageConfig && toasterMessageConfig.type}
      />
    </div>
  );
}

const DateTimeCellRenderer = (params) => {
  return (
    <div>
      {Intl.DateTimeFormat("en-IN", {
        hourCycle: "h23",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(params.value))}
    </div>
  );
};

export default withStyles(styles)(VidsHourlyEvents);
