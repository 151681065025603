import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";

import { showToaster } from "../../store/actions/common";
import axios from "axios";
import appConfig from "../../appConfig.json";

const BASE_URL = appConfig.BASE_URL;

export const status = (dataCompletenessStatus, reportCount, user, reportGenerationDateTime) => {
  return {
    dataCompletenessStatus: dataCompletenessStatus,
    reportCount: reportCount,
    user: user,
    reportGenerationDateTime: reportGenerationDateTime,
  };
};

function ReportsData(props) {
  const { filters, reportCode, dataCompletenessApplicable,getFooterValues } = props;

  let dispatch = useDispatch();
  let currentUser = useSelector(state=>state.auth.user.loginId)

  const [reportCount, setReportCount] = useState(0);
  const [dataCompletenessStatus, setDataCompletenessStatus] = useState(false);
  const [user, setUser] = useState(0);
  const [reportGenerationDateTime, setReportGenerationDateTime] = useState(0);

  useEffect(() => {
    if (getFooterValues){
      getFooterValues({reportCount,dataCompletenessStatus,currentUser,reportGenerationDateTime})
      }},[reportCount,dataCompletenessStatus,user,reportGenerationDateTime])
     
  useEffect(() => {
    // const getCompletenessStatus = async () => {
    //   try {
    //     let allUrlParams = "";
    //     console.log("filters", filters)
    //     if (filters != null && filters.length > 0) {
    //       filters.forEach((urlParamObj) => {
    //         if (urlParamObj.key === "tc") {
    //           allUrlParams = allUrlParams + "&userId=" + urlParamObj["value"];
    //         } else {
    //           allUrlParams =
    //             allUrlParams +
    //             "&" +
    //             urlParamObj["key"] +
    //             "=" +
    //             urlParamObj["value"];
    //         }
    //       });
    //     }
    //     let queryParams = allUrlParams.slice(1);

    //     const response = await axios.get(
    //       BASE_URL + "/api/completnessStatus?" + queryParams
    //     );
    //     console.log("reposnse", response)
    //     setDataCompletenessStatus(response?.data?.dataCompletenessStatus);
    //   } catch (error) {
    //     dispatch(showToaster("error", error?.response?.data?.message));
    //   }
    // };

    const getReportCount = async () => {
      try {
        const response = await axios.get(
          BASE_URL + "/api/reportDetails?reportCode=" + reportCode
        );
        setReportCount(response?.data?.data[0]?.generationCount);
      } catch (error) {
        dispatch(showToaster("error", error?.response?.data?.message));
      }
    };

    const getGenerationDateTime = async () => {
      try {
        const response = await axios.get(
          BASE_URL + "/api/reportDetails?reportCode=" + reportCode
        );
        setReportGenerationDateTime(response?.data?.data[0]?.updatedAt);
      } catch (error) {
        dispatch(showToaster("error", error?.response?.data?.message));
      }
    };


    const getUser = async () => {
      try {
        
        setUser(currentUser);
      } catch (error) {
        dispatch(showToaster("error", error?.response?.data?.message));
      }
    };



    filters?.length > 0 &&
      dataCompletenessApplicable == 1 &&
      // getCompletenessStatus();

    setTimeout(() => reportCode && getReportCount(), 1500);
    setTimeout(() => reportCode && getUser(), 1500);
    setTimeout(() => reportCode && getGenerationDateTime(), 1500);
    status(dataCompletenessStatus, reportCount, user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, reportCode, dataCompletenessApplicable]);

 console.log("currentUser", currentUser)
 console.log("reportGenerationDateTime", reportGenerationDateTime)
 console.log("reportCount", reportCount)



  return (
    <div
      style={{
        display: "flex",
        justifyContent: (dataCompletenessApplicable ? "space-between" : 'space-around'),
        alignItems: "center",
      }}
    >
      {/* {dataCompletenessApplicable && dataCompletenessApplicable === 1 ? (
        <div>
          <Typography variant="h5" display="inline">
            Data Completeness: &nbsp;
          </Typography>
          <Typography variant="h4" display="inline">
            {dataCompletenessStatus === 1 ? (
              <span style={{ color: "green" }}>True</span>
            ) : (
              <span style={{ color: "red" }}>False</span>
            )}
          </Typography>
        </div>
      ) : null} */}

      
      <div>
        <Typography variant="h5" display="inline">
          User: &nbsp;
        </Typography>
        <Typography variant="h4" display="inline">
          {currentUser && currentUser}
        </Typography>
      </div>

      <div>
        <Typography variant="h5" display="inline">
          Generation Date & Time: &nbsp;
        </Typography>
        <Typography variant="h4" display="inline">
        {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(reportGenerationDateTime && reportGenerationDateTime))}
      
        </Typography>
      </div>    
      
          

      <div>
        <Typography variant="h5" display="inline">
          Report Generation Count: &nbsp;
        </Typography>
        <Typography variant="h4" display="inline">
          {reportCount && reportCount}
        </Typography>
      </div>
    </div>
  );
}

export default ReportsData;
