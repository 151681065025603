import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import axios from 'axios';
import Toaster from '../../../components/common/Toaster';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, Button, InputLabel
} from '@material-ui/core';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;
const styles = theme => ({
  root: {
    marginTop: '50px',
    clear: 'both'
  },
  submitButton: {
    marginTop: '10px'
  },
  gridItem: {
    maxWidth: '350px',
    minWidth: '100px'
  },
  cardHeader: {
    fontSize: '15px',
    color: 'blue'
  },
  vehicleDetails: {
    border: '1px grey solid',
    margin: '10px 0px'
  }
});

class EventHighwayAid extends Component {
  constructor() {
    super();
    this.state = {
      vehicleCategories: [],
      assetVehicles: [],
      aidAssigned:false
    };
  }

  componentDidMount() {
    this.getVehicleCategory()
  }

  

  getVehicleCategory(){
    axios
      .get(BASE_URL + "/api/assetVehicleCategories?reqType=all")
      .then(res => {
        console.log(res.data);
        this.setState({
          vehicleCategories: res.data
        });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  }

  changeVehicleCategory(event) {
    let vehicleCategory = event.target.value;
    axios
      .get(BASE_URL + "/api/assetVehicleDetails?reqType=all&availabilityStatus=available&assetVehicleCategoryId=" + vehicleCategory)
      .then(res => {
        console.log(res.data);
        this.setState({ vehicleCategory: vehicleCategory, assetVehicles: res.data, selectedAssetVehicle: null, aidAssigned:false });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  }

  changeAssetVehicle(event) {
    var selectedAssetVehicle = this.state.assetVehicles.find((veh) => veh.assetVehicleDetailsId == event.target.value);
    this.setState({ assetVehicle: event.target.value, selectedAssetVehicle: selectedAssetVehicle, aidAssigned:false });
  }

  // getVehicleStatus(statusId) {
  //   switch (statusId) {
  //     case 0:
  //       return 'AVAILABLE';
  //     case 1:
  //       return 'BUSY'
  //     case 2:
  //       return 'UNDER MAINTENANCE'
  //     default:
  //       return 'BUSY';
  //   }
  // }

  assignVehicleToEvent(values) {
    this.props.assignAidVehicle({vehicle: this.state.selectedAssetVehicle, eventId: this.props.eventId});
    this.setState({
      vehicleCategories: [],
      vehicleCategory: null,
      assetVehicles: [],
      aidAssigned:false
    })
    this.getVehicleCategory()
  }
  render() {
    const { classes } = this.props;
    console.log(this.props,'assetVehicle')


    return (
      <div className={classes.root} style={{ maxWidth: "100%" }}>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <fieldset disabled={ this.props.eventStatusName != 'MANAGE'}>
        <Grid container spacing={8}>
          <Grid item md={6} xs={12} className={classes.gridItem}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Vehicle Category</InputLabel>
              <Select native label="Vehicle Category" value={this.state.vehicleCategory} onChange={(event) => this.changeVehicleCategory(event)}>
                <option value="" />
                {this.state.vehicleCategories.map((vehicleCategory) => {
                  return (<option value={vehicleCategory.assetVehicleCategoryId}>{vehicleCategory.assetVehicleCategoryName}</option>);
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} className={classes.gridItem}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Asset Vehicle</InputLabel>
              <Select label="Asset Vehicle" native value={this.state.assetVehicle} onChange={(event) => this.changeAssetVehicle(event)}>
                <option value="" />
                {this.state.assetVehicles.map((assetVehicle) => {
                  return (<option value={assetVehicle.assetVehicleDetailsId}>{assetVehicle.assetVehicleDetailsName}</option>);
                })}
              </Select>
            </FormControl>
              {this.state.vehicleCategory && this.state.assetVehicles.length === 0 ? (
                <p
                  style={{
                    color: "#D0312D",
                    fontWeight: "bold",
                    backgroundColor: "#f0f0f0",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  All Vehicles are Busy!
                </p>
              ) : null}
          
          </Grid>
       
        </Grid>
        </fieldset>
        {this.state.selectedAssetVehicle &&
          <div>
            <Card className={classes.vehicleDetails}>
              <CardHeader className={classes.cardHeader} title="Vehicle Details" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12} className={classes.gridItem}>
                    <div>
                      <div>Vehicle Number</div>
                      <div>{this.state.selectedAssetVehicle.assetVehicleDetailsNumber}</div>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.gridItem}>
                    <div>
                      <div>Vehicle Make/Model</div>
                      <div>{this.state.selectedAssetVehicle.assetVehicleDetailsModel}</div>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.gridItem}>
                    <div>
                      <div>Vehicle Color</div>
                      <div>{this.state.selectedAssetVehicle.assetVehicleDetailsColor}</div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12} className={classes.gridItem}>
                    <div>
                      <div>Vehicle Contact</div>
                      <div>{this.state.selectedAssetVehicle.assetVehicleDetailsContactNumber}</div>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.gridItem}>
                    <div>
                      <div>Vehicle Status</div>
                      <div>{this.state.selectedAssetVehicle.availability.vehicleStatusName}</div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <FormControlLabel
              control={
                <Checkbox disabled={this.state.aidAssigned || this.state.selectedAssetVehicle.availability.vehicleStatusName != 'AVAILABLE' || this.state.assetVehicles.length <= 0}
                  onChange={(event) => this.setState({ assignAid: event.target.checked })}
                  color="primary"
                />
              }
              label="Assign Aid to this Event"
            />
            <Button className={classes.sendButton}
              disabled={!this.state.assignAid || this.state.aidAssigned || this.state.selectedAssetVehicle.availability.vehicleStatusName != 'AVAILABLE' || this.state.assetVehicles.length <= 0 || this.props.eventStatusName != 'MANAGE'} color="primary"
              size="large" variant="contained" onClick={(values) => this.assignVehicleToEvent(values)}>
              SUBMIT
          </Button>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    vehicleAssignSuccess: state.eventAlerts.vehicleAssignSuccess,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    assignAidVehicle: (params) => dispatch(actions.assignAidVehicle(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(EventHighwayAid));