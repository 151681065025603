import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from "../common";

const BASE_URL = appConfig.BASE_URL;

export const getEquipCatSuccess = (arrEquipCat) => {
        return {
            type : actionTypes.GET_EQUIP_CAT_SUCCESS,
            arrEquipCat : arrEquipCat,
        };
};

export const getEquipCat = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/equipmentCategories?reqType=all")
        .then(res => {    
            console.log("ACTION: " + JSON.stringify(res.data));
            dispatch(getEquipCatSuccess(res.data));
        }).catch(error => {
            dispatch(showToaster('error',error.response.data.message))
        });
    };
}

const equipTypeCreateStart = () => {
    return {
        type : actionTypes.EQUIP_TYPE_CREATE_START
    };
};

export const equipTypeCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(equipTypeCreateStart());
            const response = await axios.post(BASE_URL + "/api/equipmentTypes", formValues);
            dispatch(showToaster('success','Equipment Type Successfully Created.'));
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}

export const equipTypeStateReset = () => {
    return {
        type: actionTypes.EQUIP_TYPE_STATE_RESET
    }
}

export const equipTypeEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        equipmentTypeName: formValues.equipmentTypeName,
        equipmentTypeDescription: formValues.equipmentTypeDescription,
        equipmentTypeMake: formValues.equipmentTypeMake,
        equipmentTypeModel: formValues.equipmentTypeModel,
        equipmentTypeSeries: formValues.equipmentTypeSeries,
        equipmentTypeWarranty: formValues.equipmentTypeWarranty,
        equipmentTypeVersion: formValues.equipmentTypeVersion,
        equipmentTypeCount: formValues.equipmentTypeCount,
        equipmentCategoryId: formValues.equipmentCategoryId,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(equipTypeCreateStart());
            const response = await axios.patch(BASE_URL + "/api/equipmentTypes/" + formValues.equipmentTypeId, values);
            console.log(response);
            dispatch(showToaster('success','Equipment Type Successfully Edited.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}
