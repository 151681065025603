import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Divider } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar'
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    ...commonStyles(theme),
    button: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: theme.spacing(20)
    },
    div: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px'
    },
    text: {
        color: '#002884',
        float: 'left',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    text2: {
        color: '#002884',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(12)
    },
    button1: {
        float: 'right',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    }
});

class SectionSpeedViolationChart extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            sectionSpeedViolationBarData: [],
            keys: [],
            selectedButton: '',
            anchorEl: null,
            open: false,
            menuButton: 'Today'
        }
        this.setAnchorEl = this.setAnchorEl.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.handleMenuClose = this.handleMenuClose.bind(this)
    }

    handleButtonClick(event) {
        this.setAnchorEl(event.currentTarget);
        this.setState({
            open: !this.state.open
        })
    }

    setAnchorEl(value) {
        this.setState({
            anchorEl: value,
            open: false
        })
    }

    handleMenuClose() {
        this.setState({
            menuButton: 'Today',
            open: false
        })
        this.setAnchorEl(null);
    }

    async componentDidMount() {
        await this.todayButtonClick();
    }

    todayButtonClick = async () => {

        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;
        console.log("today", today);

        //try {
        //    const todayData = await axios.get(BASE_URL + "/api/dashboard/sectionSpeedViolation-count?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today);
        //    if (todayData.data != null) {
        //        if (todayData.data.message) {
        //            this.setState({
        //                sectionSpeedViolationBarData: [],
        //                keys: [],
        //                selectedButton: 'Today',
        //                menuButton: 'Today'
        //            })
        //        }
        //        else {
        //            this.setState({
        //                sectionSpeedViolationBarData: todayData.data,
        //                keys: todayData.data.keys,
        //                selectedButton: 'Today',
        //                menuButton: 'Today'
        //            })
        //        }
        //        console.log("todayData", todayData.data);
        //        console.log("section Speed Violation Bar Data", this.state.sectionSpeedViolationBarData);
        //    }
        //    this.setAnchorEl(null);
        //}
        //catch (error) {
        //    console.log("error");
        //}

        try {
            const todayData = await axios.get(BASE_URL + "/api/dashboard/sectionSpeedViolation-count?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today);
            if (todayData.data != null) {
                if (todayData.data.message) {
                    this.setState({
                        sectionSpeedViolationBarData: [],
                        keys: [],
                        selectedButton: 'Today',
                        menuButton: 'Today'
                    });
                } else {
                    this.setState({
                        sectionSpeedViolationBarData: todayData.data.data,
                        keys: todayData.data.keys,
                        selectedButton: 'Today',
                        menuButton: 'Today'
                    });
                }
                console.log("todayData", todayData.data);
                console.log("section Speed Violation Bar Data", this.state.sectionSpeedViolationBarData);
            }
            this.setAnchorEl(null);
        } catch (error) {
            console.log("error");
        }
    }

    onOneWeekButtonClick = async () => {

        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;
        console.log("today", today);

        var lastWeekDate = new Date();
        lastWeekDate.setDate(lastWeekDate.getDate() - 7);
        let lastWeekDay = lastWeekDate.getDate();
        let lastWeekMonth = lastWeekDate.getMonth() + 1;
        let lastWeekYear = lastWeekDate.getFullYear();

        let lastWeek = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;

        console.log("lastWeek", lastWeek);

        //try {
        //    const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/sectionSpeedViolation-count?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today);
        //    if (lastWeekData.data != null) {
        //        if (lastWeekData.data.message) {
        //            this.setState({
        //                sectionSpeedViolationBarData: [],
        //                keys: [],
        //                selectedButton: 'Last 7 days',
        //                menuButton: 'Last 7 days'
        //            })
        //        }
        //        else {
        //            this.setState({
        //                sectionSpeedViolationBarData: lastWeekData.data,
        //                keys: lastWeekData.data.keys,
        //                selectedButton: 'Last 7 days',
        //                menuButton: 'Last 7 days'
        //            })
        //        }
        //        console.log("lastWeekData", lastWeekData.data);
        //        console.log("section Speed Violation Bar Data", this.state.sectionSpeedViolationBarData);
        //    }
        //    this.setAnchorEl(null);
        //}
        //catch (err) {
        //    console.log(err);
        //}
        try {
            const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/sectionSpeedViolation-count?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today);
            if (lastWeekData.data != null) {
                if (lastWeekData.data.message) {
                    this.setState({
                        sectionSpeedViolationBarData: [],
                        keys: [],
                        selectedButton: 'Last 7 days',
                        menuButton: 'Last 7 days'
                    });
                } else {
                    this.setState({
                        sectionSpeedViolationBarData: lastWeekData.data.data,
                        keys: lastWeekData.data.keys,
                        selectedButton: 'Last 7 days',
                        menuButton: 'Last 7 days'
                    });
                }
                console.log("lastWeekData", lastWeekData.data);
                console.log("section Speed Violation Bar Data", this.state.sectionSpeedViolationBarData);
            }
            this.setAnchorEl(null);
        } catch (err) {
            console.log(err);
        }
    }

    onOneMonthButtonClick = async () => {

        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let today = year + "-" + month + "-" + day;
        console.log("today", today);

        var lastMonthDate = new Date();
        lastMonthDate.setDate(lastMonthDate.getDate() - 30);
        let lastMonthDay = lastMonthDate.getDate();
        let lastMonthMonth = lastMonthDate.getMonth() + 1;
        let lastMonthYear = lastMonthDate.getFullYear();

        let lastMonth = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;

        console.log("lastMonth", lastMonth);


        try {
            const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/sectionSpeedViolation-count?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today);
            if (lastMonthData.data != null) {
                if (lastMonthData.data.message) {
                    this.setState({
                        sectionSpeedViolationBarData: [],
                        keys: [],
                        selectedButton: 'Last 30 days',
                        menuButton: 'Last 30 days'
                    })
                }
                else {
                    this.setState({
                        sectionSpeedViolationBarData: lastMonthData.data.data,
                        keys: lastMonthData.data.keys,
                        selectedButton: 'Last 30 days',
                        menuButton: 'Last 30 days'
                    })
                }
                console.log("lastMonthData", lastMonthData.data);
                console.log("section Speed Violation Bar Data", this.state.sectionSpeedViolationBarData);
            }
            this.setAnchorEl(null);
        }
        catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;

        const datalength = this.state.sectionSpeedViolationBarData.length;
        console.log("Section Speed Violation Total", this.state.sectionSpeedViolationBarData.length);

        return (
            <React.Fragment>
                <div className={classes.div}>
                    <Typography className={classes.text} variant="h6" align="left">Section Speed Violation</Typography>

                    <Button className={classes.button1} size="small" variant="contained" color="primary" onClick={this.handleButtonClick}>
                        {this.state.menuButton}
                    </Button>

                    <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
                        <MenuItem onClick={() => this.todayButtonClick(this.state.eventTypeId)}>Today</MenuItem>
                        <MenuItem onClick={() => this.onOneWeekButtonClick(this.state.eventTypeId)}>Last 7 days</MenuItem>
                        <MenuItem onClick={() => this.onOneMonthButtonClick(this.state.eventTypeId)}>Last 30 days</MenuItem>
                    </Menu>

                </div>
                <Divider />
                {datalength && datalength !== 0 ?
                    (
                        <ResponsiveBar
                            data={this.state.sectionSpeedViolationBarData}
                            keys={this.state.keys}
                            indexBy="direction"
                            margin={{ top: 15, right: 90, bottom: 90, left: 45 }}
                            padding={0.5}
                            groupMode="stacked"
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            enableGridY={false}
                            markers={[
                                {
                                    axis: 'y',
                                    value: 0,
                                    lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
                                    legendOrientation: 'vertical',
                                },
                            ]}

                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Traffic Incident Type',
                                legendPosition: 'middle',
                                legendOffset: 35,
                                itemWidth: 40,
                                itemHeight: 20,
                                // renderTick: CustomFontTick
                            }}
                            axisLeft={{
                                tickSize: 2,
                                tickPadding: 3,
                                tickRotation: 0,
                                legend: 'Count',
                                legendPosition: 'middle',
                                legendOffset: -28,
                                format: e => Math.floor(e) === e && e
                            }}
                            labelSkipWidth={5}
                            labelSkipHeight={5}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'top-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: -50,
                                    translateY: 20,
                                    itemsSpacing: 0,
                                    itemWidth: 30,
                                    itemHeight: 15,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 10,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    ) :
                    (
                        <Typography className={classes.text2} variant="h6" align="center">No Data Available For {this.state.selectedButton}</Typography>
                    )}


            </React.Fragment>
        )

    }
}

export default withStyles(styles)(SectionSpeedViolationChart)