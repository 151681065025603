import axios from "axios";
import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const setSelectedEvent = (eventData) => {
  return {
    type: actionTypes.SET_SELECTED_EVENT,
    eventData: eventData
  };
};


export const getEventAdditionalInfoSuccess = (additionalInfo) => {
  return {
    type: actionTypes.GET_EVENT_ADDITIONAL_INFO_SUCCESS,
    additionalInfo: additionalInfo,
  };
};

export const getEventAdditionalInfo = (eventId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/eventAdditionalInfo?eventId=" + eventId)
      .then(res => {
        console.log("getEventAdditionalInfo", res.data);
        dispatch(getEventAdditionalInfoSuccess(res.data));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const getEventAccidentVehicleInfoSuccess = (vehicleInfo) => {
  return {
    type: actionTypes.GET_EVENT_ACCIDENT_VEHICLE_INFO,
    vehicleInfo: vehicleInfo.data,
  };
};

export const getEventAccidentVehicleInfo = (eventId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/eventAccidentVehicleInfo?eventId=" + eventId)
      .then(res => {
        console.log(res.data);
        dispatch(getEventAccidentVehicleInfoSuccess(res.data));
      }).catch(error => {
        console.log('error.response.data.message')
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}


export const getEventFilesSuccess = (eventFiles) => {
  return {
    type: actionTypes.GET_EVENT_FILES_SUCCESS,
    eventFiles: eventFiles.data,
  };
};

export const getEventFiles = (eventId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/eventFiles?eventId=" + eventId)
      .then(res => {
        console.log("getEventFiles", res.data);
        dispatch(getEventFilesSuccess(res.data));
        
      }).catch(error => {
        
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const changeEventStatus = (eventId, body) => {
  return dispatch => {
   
      
      axios
        .patch(BASE_URL + "/api/events/" + eventId + "/status", body)
        .then(res => {
          console.log("changeEventStatus", body);
          dispatch(fetchEventDetails(eventId));
          if(body.eventStatus == "Accepted"){
            dispatch(showToaster('success', 'Event Successfully Accepted.'));
          }
          if(body.eventStatus == "Rejected"){
            dispatch(showToaster('success', 'Event Successfully Rejected.'));
          }
          if(body.resolveRemarks){
            dispatch(showToaster('success', 'Event Successfully Resolved.'));
          } 
          
        }).catch(error => {
          // dispatch(getEventGeneralDetailsFail(error.response.data.message));
          dispatch(showToaster('error', error.response.data.message));
        });
    
  };
}

export const setEventStatusChangeSuccess = (eventFiles) => {
  return {
    type: actionTypes.CHANGE_EVENT_STATUS_SUCCESS,
    message: 'SUCCESS',
  };
};

export const fetchEventDetails = (eventId) => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/events/" + eventId)
      .then(res => {
        dispatch(setSelectedEvent(res.data));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}
export const fetchEventTimeline = (eventId) => {
  return async dispatch => {
    let res = await axios.get(BASE_URL + '/api/eventAlerts?eventId=' + eventId + '&$sort[updatedAt]=-1&reqType=all&eventNotificationStatus=1')

    let alerts = res.data;

    let resLifeCycle = await axios.get(BASE_URL + "/api/eventLifecycle?txnEventId=" + eventId + "&$sort[updatedAt]=1&reqType=all");
    let lifeCycle = resLifeCycle.data;

    let resSopActions = await axios.get(BASE_URL + "/api/eventSopAction?eventId=" + eventId + '&$sort[updatedAt]=-1&reqType=all')
     let sopActions = resSopActions.data;

     let resEventVehicles =await axios.get(BASE_URL + "/api/eventAidVehicles?txnEventId=" + eventId);
     let eventVehicles = resEventVehicles.data.data;
     console.log("Event Vehicles>>>>>>>>>>>>>",eventVehicles);

    let timeline = [...alerts,...lifeCycle,...sopActions,...eventVehicles];
    timeline = _.sortBy(timeline, (item)=>item.updatedAt);
    console.log('timeline: ', timeline);
    dispatch(fetchEventTimelineSuccess(timeline));
  };
}
export const fetchEventTimelineSuccess = (timeline) => {
  return {
    type: actionTypes.EVENT_TIMELINE_SUCCESS,
    eventTimeline: timeline,
  };
};

