import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Divider,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import format from "date-fns/format";
import commonStyles from "../../commonStyles";
import axios from "axios";
import appConfig from "../../appConfig.json";
import Toaster from "../../components/common/Toaster";
import { showToaster } from "../../store/actions/common";
import PDFExportPanel from "./pdfExport/pdfExportPanel";
import BackdropLoading from "../../components/common/BackdropLoading";
import ReportsFooter from "../../components/common/ReportsFooter";

const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => ({
  ...commonStyles,
  cardHeaderStyle: {
    textAlign: "center",
    color: "#002884",
    padding: 0,
    marginLeft: "10px",
  },
  title: {
    margin: theme.spacing(2, 1),
    color: "white",
  },
  mopCell: {
    background: "white",
    borderLeft: "1px solid lightgrey !important",
    borderRight: "1px solid lightgrey !important",
    // borderBottom: '1px solid lightgrey !important'
  },
  colHeader: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    border: "1px solid darkgrey !important",
    padding: "10px",
  },
  reportFooter: {
    margin: theme.spacing(2),
  },
  toolTip: {
    paddingBottom: "15px",
    marginLeft: "10px",
    fontWeight: "bold"
  }
});

function EventManagementPeformance(props) {
  const { classes } = props;

  let dispatch = useDispatch();

  const today = new Date();
  const presentDate = format(today, "yyyy-MM-dd");
  today.setDate(today.getDate() - parseInt(730));
  const twoYearAgoDate = format(today, "yyyy-MM-dd");
  const [filters, setFilters] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [fromDate, setFromDate] = useState(presentDate);
  const [toDate, setToDate] = useState(presentDate);

  const [shiftId, setShiftId] = useState([]);
  const [directionId, setDirectionId] = useState([]);
  const [chainageId, setChainageId] = useState([]);
  const [eventStatusId, setEventStatusId] = useState([]);
  const [plazaCode, setPlazaCode] = useState([]);

  const [shifts, setShifts] = useState([]);
  const [directions, setDirections] = useState([]);
  const [chainage, setChainage] = useState([]);
  const [eventStatus, setEventStatus] = useState([]);
  const [plazas, setPlazas] = useState([]);

  const toasterOpen = useSelector((state) => state.common.toasterOpen);
  const toasterMessageConfig = useSelector(
    (state) => state.common.toasterMessageConfig
  );

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [footerValues, setFooterValues]= useState({});

  const dataCompletenessApplicable = 1;
  // ----------------------To put min max condition to to and from date -----------------------
  // First get that maxDays value from redux store 4
  // const maxDaysAllowed= 90;

  const maxDaysAllowed=useSelector((state)=>state?.common.globalConfig.date_filter_range.configValue1)
  const [minToDate,setMinToDate]=useState(twoYearAgoDate)
  const[maxToDate,setMaxToDate]=useState(presentDate)
  const [minFromDate,setMinFromDate]=useState(twoYearAgoDate)
  const[maxFromDate,setMaxFromDate]=useState(presentDate)
  const [errorMessage,setErrorMessage]=useState("No Data available for Selected filters")

  const [selectedDirection, setselectedDirection] = useState([]);


  const  onDirectionChange = (event) => {
    setDirectionId(event.target.value)
    const direction = [];
     directions.forEach((e) =>{
      console.log("%%%%%%%%%%%%%%",e.directionId)
      if(event.target.value.includes(e.directionId)){
        console.log("###############")
      direction.push(e.directionId) 
    }
    console.log("direction", direction)
  })
    setselectedDirection(direction)
    // console.log("event.target.value",event.target.value)
    // console.log("direction",directions,direction)
  }

  useEffect(() => {
    const reportGet = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          BASE_URL +
          "/api/summaryReports/dailyEventManagementPerformance?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate
        );
        setTotalRowCount(response.data?.rowTotal);
        setRowData(response.data?.data);
        setIsLoading(false);
      } catch (error) {
        dispatch(showToaster("error", error?.response?.data?.message));
        setIsLoading(false);
      }
    };
    reportGet();
  }, []);

  useEffect(() => {
    // const getShifts = async () => {
    //   try {
    //     const res = await axios.get(BASE_URL + "/api/shifts?reqType=all");
    //     setShifts(res.data);
    //   } catch (error) {
    //     dispatch(showToaster("error", error.response.data.message));
    //   }
    // };

    const getDirections = async () => {
      try {
        const res = await axios.get(BASE_URL + "/api/direction?reqType=all");
        setDirections(res.data);
      } catch (error) {
        dispatch(showToaster("error", error.response.data.message));
      }
    };
    
    const getChainage = async () => {
      try {
        const res = await axios.get(BASE_URL + "/api/chainage?reqType=all");
        setChainage(res.data);
      } catch (error) {
        dispatch(showToaster("error", error.response.data.message));
      }
    };

    const getEventStatus = async () => {
        try {
          const res = await axios.get(BASE_URL + "/api/selectedEventStatus?eventStatusId=1,2,3,4,5&reqType=all");
        //   console.log("res", res)                [id][]=1&filter[id][]=2&filter[id][]=3              
          setEventStatus(res.data);
        } catch (error) {
          dispatch(showToaster("error", error.response.data.message));
        }
      };

    // const getTcs = async () => {
    //   try {
    //     const res = await axios.get(
    //       BASE_URL + "/api/allUsers?userRoleId=105&reqType=all"
    //     );
    //     setTollCollectors(res.data);
    //   } catch (error) {
    //     dispatch(showToaster("error", error.response.data.message));
    //   }
    // };

    // const getPlazas = async () => {
    //   try {
    //     const res = await axios.get(BASE_URL + "/api/plaza?reqType=all");
    //     setPlazas(res.data);
    //   } catch (error) {
    //     dispatch(showToaster("error", error.response.data.message));
    //   }
    // };

    // getShifts();
    getDirections();
    getChainage();
    getEventStatus();
    // getTcs();
    // getPlazas();
  }, []);

  const getJoinedNames = (idArray, fieldName) => {
    let result = [];

    if (fieldName === "PLAZA") {
      plazas.forEach((plaza) => {
        if (idArray.indexOf(plaza.plazaCode) > -1) {
          result.push(plaza.plazaName);
        }
      });
    }

    if (fieldName === "DIRECTION") {
      directions.forEach((direction) => {
        if (idArray.indexOf(direction.directionId) > -1) {
          result.push(direction.directionName);
        }
      });
    }

    if (fieldName === "CHAINAGE") {
      chainage.forEach((chain) => {
        if (idArray.indexOf(chain.chainageId) > -1) {
          result.push(chain.chainageName);
        }
      });
    }

    if (fieldName === "EVENTSTATUS") {
      eventStatus.forEach((eqp) => {
        if (idArray.indexOf(eqp.eventStatusId) > -1) {
          result.push(eqp.eventStatusName);
        }
      });
    }

    if (fieldName === "SHIFT") {
      shifts.forEach((shift) => {
        if (idArray.indexOf(shift.shiftId) > -1) {
          result.push(shift.shiftName);
        }
      });
    }
    return result.join(", ");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const urlParams = [];
    setPageLoading(true);
    setRowData([])

    if (fromDate &&toDate ){
      let to=new Date(toDate)
      let from=new Date(fromDate)
      var Difference_In_Time = from.getTime()-to.getTime();
      var Difference_In_Days = Math.abs(Difference_In_Time / (1000 * 3600 * 24))
      console.log("Difference_In_Days,maxDaysAllowed",Difference_In_Days,maxDaysAllowed)
    
      if(Difference_In_Days<=maxDaysAllowed||Difference_In_Days===0){
        urlParams.push({ key: "fromDate", value: fromDate });
        urlParams.push({ key: "toDate", value: toDate });
      }
      else{
        console.log(" daily tariff for count else ")
        setPageLoading(false);
        setErrorMessage(`Date Span for filtering the data should be less than or equal to ${maxDaysAllowed}  days`)
        
        return 
    }
    }

    if (plazaCode.length > 0) {
      urlParams.push({ key: "plazaCode", value: plazaCode });
    }

    // if (tcId.length > 0) {
    //   urlParams.push({ key: "tc", value: tcId });
    // }

    if (directionId.length > 0) {
      urlParams.push({ key: "directionId", value: directionId });
    }

    if (chainageId.length > 0) {
      urlParams.push({ key: "chainageId", value: chainageId });
    }

    if (eventStatusId.length > 0) {
        urlParams.push({key: "eventStatusId", value: eventStatusId})
    }

    if (shiftId.length > 0) {
      urlParams.push({ key: "shiftId", value: shiftId });
    }

    setFilters(urlParams);

    let allUrlParams = "";
    if (urlParams != null && urlParams.length > 0) {
      urlParams.forEach((urlParamObj) => {
        allUrlParams =
          allUrlParams + "&" + urlParamObj["key"] + "=" + urlParamObj["value"];
      });
    }

    try {
      let queryString = allUrlParams.slice(1);
      const response = await axios.get(
        BASE_URL + "/api/summaryReports/dailyEventManagementPerformance?" + queryString
      );
      if (response?.data?.rowTotal===0){
        setErrorMessage( "No Data available for Selected filters")
      }
      setTotalRowCount(response?.data?.rowTotal);
      setRowData(response?.data?.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      dispatch(showToaster("error", error?.response?.data?.message));
    }
  };

  const onResetClick = () => {
    setFromDate(presentDate);
    setToDate(presentDate);
    setShiftId([]);
    setDirectionId([]);
    setChainageId([]);
    setPlazaCode([]);
    setEventStatusId([])
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const cellStyle = (params) => {
    console.log("%55555555params", params)
    if (params.value == null) {
      return {
        backgroundColor: "white",
      };
    } else {
      let style = {
        border: "1px solid lightgrey !important",
        padding: "10px",
      };
      if (
        params.colDef.field.toLowerCase() == "total" ||
        (params.data.lane && params.data.lane.toLowerCase() == "total") ||
        params.colDef.field == "TC" 
      ) {
        style.fontWeight = "bold";
      }
      if (
        
        (params.data.date && params.data.date.toLowerCase() == "total") 
      ) {
        style.fontWeight = "bold";
      }
      return style;
    }
  };

  const downloadCSV = () => {
    var params = {
      fileName: ' Daily Event/Incident Management Performance Report.csv',
    };
    gridApi.exportDataAsCsv(params);
  };

  const getFooterValues=(values) => {
    console.log("values",values);
    setFooterValues(values);
    
  }

  console.log("maxDaysAllowed", maxDaysAllowed)


  return pageLoading ? (
    <React.Fragment>
      <BackdropLoading open={pageLoading} />
      <Toaster
        open={toasterOpen}
        message={toasterMessageConfig && toasterMessageConfig.message}
        severity={toasterMessageConfig && toasterMessageConfig.type}
      />
    </React.Fragment>
  ) : (
    <div className={classes.etmsPageOuterDiv}>
      <Card className={classes.etmsPageOuterCard}>
        <CardHeader
          title={
            <Typography variant="h2" className={classes.cardHeaderStyle}>
               Daily Event/Incident Management Performance Report
            </Typography>
          }
          action={
            rowData && rowData.length > 0 && totalRowCount > 0 ?
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={downloadCSV}
                  style={{ margin: "5px" }}
                >
                  Download EXCEL
                </Button>
                <PDFExportPanel
                  gridApi={gridApi}
                  columnApi={gridColumnApi}
                  heading="Daily Event/Incident Management Performance Report"
                  filters={filters}
                  shifts={shifts}
                  directions={directions}
                  // lanes={lanes}
                  // tcs={tollCollectors}
                  plazas={plazas}
                  reportName="dailyEventManagementReport"
                  footerValues={footerValues}
                  // allReportFilters={["PLAZA", "SHIFT", "LANE", "TC", "DIRECTION"]}
                  allReportFilters={[ "DIRECTION", "CHAINAGE"]}

                />
              </div> : null
          }
        />
        <Divider />
        <CardContent>
          <Card>
            <CardHeader
              title="Filters"
              style={{ padding: 0, marginLeft: "10px" }}
            />
            <CardContent>
            <div className={classes.toolTip}>Maximum allowed date range is 90 days.</div>
              <form>
              <Grid container spacing={2}>
                <Grid item lg={2}>
                  <FormControl
                    variant="outlined"
                    component="fieldset"
                    fullWidth={true}
                  >
                    <TextField
                    required
                      id="fromDate"
                      variant="outlined"
                      label="From Date"
                      type="date"
                      onChange={(event) =>{
                        const dateOffset = (24*60*60*1000) * parseInt(maxDaysAllowed);
                        const myDate = new Date(event.target.value);
                        const max=myDate.setTime(myDate.getTime()+dateOffset);
                        const maxDate=max&&format(max, "yyyy-MM-dd")
                        setFromDate(event.target.value)
                        setMinToDate(event.target.value)
                       

                        new Date(maxDate)>new Date(presentDate)?setMaxFromDate(presentDate):setMaxFromDate(maxDate)
                        if (new Date(event.target.value)<new Date(twoYearAgoDate) || new Date(event.target.value)>new Date(presentDate)){
                          setMinToDate(twoYearAgoDate)
                          setMaxToDate(presentDate)
                          setMinFromDate(twoYearAgoDate)
                          setMaxFromDate(presentDate)
                         }
                  
                      } }
                      value={fromDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: maxFromDate,
                        min:minFromDate
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={2}>
                  <FormControl
                    variant="outlined"
                    component="fieldset"
                    fullWidth={true}
                  >
                    <TextField
                      required
                      id="toDate"
                      variant="outlined"
                      label="To Date"
                      type="date"
                      onChange={(event) =>{setToDate(event.target.value)
                        if (new Date(event.target.value)<new Date(twoYearAgoDate) || new Date(event.target.value)>new Date(presentDate)){
                          setMinToDate(twoYearAgoDate)
                          setMaxToDate(presentDate)
                          setMinFromDate(twoYearAgoDate)
                          setMaxFromDate(presentDate)
                         }
                         setMaxFromDate(event.target.value)
                         const dateOffset = (24*60*60*1000) * parseInt(maxDaysAllowed);
                         const myDate = new Date(event.target.value);
                         const min=myDate.setTime(myDate.getTime()-dateOffset);
                         const minDate=min&&format(min, "yyyy-MM-dd")
                         new Date(minDate)<new Date(twoYearAgoDate)?setMinFromDate(twoYearAgoDate):setMinFromDate(minDate);
                         setMinFromDate(minDate) 
                      }}
                      value={toDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: maxToDate,
                        min:minToDate
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Plaza</InputLabel>
                    <Select
                      name="plaza"
                      value={plazaCode}
                      multiple
                      input={<OutlinedInput label="Plaza" />}
                      onChange={(event) => setPlazaCode(event.target.value)}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "PLAZA")
                      }
                    >
                      {plazas &&
                        plazas.map((plaza) => {
                          return (
                            <MenuItem
                              key={plaza.plazaId}
                              value={plaza.plazaCode}
                            >
                              <Checkbox
                                checked={
                                  plazaCode.indexOf(plaza.plazaCode) > -1
                                }
                              />
                              <ListItemText primary={plaza.plazaName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Shift</InputLabel>
                    <Select
                      name="shift"
                      size="small"
                      multiple
                      value={shiftId}
                      input={<OutlinedInput label="Shift" />}
                      onChange={(event) => setShiftId(event.target.value)}
                      label="Select Shift"
                      renderValue={(selected) =>
                        getJoinedNames(selected, "SHIFT")
                      }
                    >
                      {shifts &&
                        shifts.map((shift) => {
                          return (
                            <MenuItem key={shift.shiftId} value={shift.shiftId}>
                              <Checkbox
                                checked={shiftId.indexOf(shift.shiftId) > -1}
                              />
                              <ListItemText primary={shift.shiftName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid> */}


                    
                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Direction</InputLabel>
                    <Select
                      name="directionId"
                      multiple
                      value={directionId}
                      input={<OutlinedInput label="Direction" />}
                      // onChange={(event) => setDirectionId(event.target.value)}
                      onChange={onDirectionChange}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "DIRECTION")
                      }
                    >
                      {directions &&
                        directions.map((direction) => {
                          console.log("direction@@@@@@@@@", direction)
                          return (
                            
                            <MenuItem key={direction.directionId} value={direction.directionId}>
                              <Checkbox
                                checked={directionId.indexOf(direction.directionId) > -1}
                              />
                              <ListItemText primary={direction.directionName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>       



                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Chainage</InputLabel>
                    <Select
                      name="chainageId"
                      multiple
                      value={chainageId}
                      input={<OutlinedInput label="Chainage" />}
                      onChange={(event) => setChainageId(event.target.value)}
                      renderValue={(selected) =>
                        getJoinedNames(selected, "CHAINAGE")
                      }
                    >
                      {chainage &&
                        chainage.filter(chain=> selectedDirection.includes(chain.directionId)).map((chain) => {
                          return (
                            <MenuItem key={chain.chainageId} value={chain.chainageId}>
                              <Checkbox
                                checked={chainageId.indexOf(chain.chainageId) > -1}
                              />
                              <ListItemText primary={chain.chainageName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={2} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Event Status</InputLabel>
                    <Select
                      name="eventStatus"
                      multiple
                      value={eventStatusId}
                      input={<OutlinedInput label="EventStatus" />}
                      onChange={(event) => setEventStatusId(event.target.value)}
                      renderValue={(selected) => getJoinedNames(selected, "EVENTSTATUS")}
                    >
                      {eventStatus &&
                        eventStatus.map((equipment) => {
                          return (
                            <MenuItem
                              key={equipment.eventStatusId}
                              value={equipment.eventStatusId}
                            >
                              <Checkbox
                                checked={
                                  eventStatusId.indexOf(equipment.eventStatusId) > -1
                                }
                              />
                              <ListItemText primary={equipment.eventStatusName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    type="submit"
                    fullWidth
                  >
                    Search
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={onResetClick}
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
              </form>
            </CardContent>
          </Card>
          {rowData && rowData.length > 0 && totalRowCount > 0 ? (
            <div>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100vh",
                  width: "95vw",
                  marginTop: "1rem",
                  overflow: "hidden",
                  border: "1px solid black",
                }}
              >
                <AgGridReact
                  rowData={rowData}
                  onGridReady={onGridReady}
                  suppressRowTransform={true}
                >
                  <AgGridColumn
                    field="date"
                    headerClass={classes.colHeader}
                    headerName="Txn Date"
                    cellStyle={cellStyle}
                  />
                  {Object.keys(rowData[0]).map((key) =>
                    key !== "date" ? (
                      <AgGridColumn
                        field={key}
                        cellStyle={cellStyle}
                        headerClass={classes.colHeader}
                        headerName={key.toUpperCase()}
                      />
                    ) : null
                  )}
                </AgGridReact>
              </div>
              <div className={classes.reportFooter}>
                <ReportsFooter
                  filters={filters}
                  reportCode={"EVTMANPER"}
                  dataCompletenessApplicable={dataCompletenessApplicable}
                  getFooterValues={getFooterValues}
                />
              </div>
            </div>
          ) : (
            <div>
              {isLoading ? (
                ""
                // <BackdropLoading open={isLoading} />
              ) : (
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
              {errorMessage}
                </Typography>
              )}
            </div>
          )}
        </CardContent>
      </Card>
      <Toaster
        open={toasterOpen}
        message={toasterMessageConfig && toasterMessageConfig.message}
        severity={toasterMessageConfig && toasterMessageConfig.type}
      />
    </div>
  );
}

export default withStyles(styles)(EventManagementPeformance);
