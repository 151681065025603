import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    directions : [],
    chainages : [],
    vehicleClasses : [],
    sections : [],
    violationStatus : []
};

const updateDirection = ( state, action ) => {
    var updatedState = updateObject(state, {
        directions : action.directions
    });
    return updatedState;
};

const updateChainage = ( state, action ) => {
    var updatedState = updateObject(state, {
        chainages : action.chainages
    });
    return updatedState;
};

const updateVehicleClass = ( state, action ) => {
    var updatedState = updateObject(state, {
        vehicleClasses : action.vehicleClasses
    });
    return updatedState;
};

const updateSections = ( state, action ) => {
    var updatedState = updateObject(state, {
        sections : action.sections
    });
    return updatedState;
};


const updateViolationStatus = ( state, action ) => {
    var updatedState = updateObject(state, {
        violationStatus : action.violationStatus
    });
    return updatedState;
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.UPDATE_DIRECTION_AV:
            return updateDirection(state, action);
        case actionTypes.UPDATE_CHAINAGE_AV:
            return updateChainage(state, action);
        case actionTypes.UPDATE_VEHICLECLASS_AV:
            return updateVehicleClass(state, action);
        case actionTypes.UPDATE_SECTIONS_AV:
            return updateSections(state, action);
        case actionTypes.UPDATE_VIOLATION_STATUS_AV:
            return updateViolationStatus(state, action);
        default:
            return state;
    }
}

export default reducer;