import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const eventTypeCreateStart = () => {
    return {
        type: actionTypes.EVENT_TYPE_CREATE_START
    };
};

export const eventTypeCreate = (formValues) => {
    console.log("Add: "+ JSON.stringify(formValues));
    return async (dispatch) => {
        try{
            dispatch(eventTypeCreateStart());
            const response = await axios.post("http://localhost:3030/api/eventTypes",formValues)
            dispatch(showToaster('success','Event Type Created Successfully'));
        }catch(error){
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const eventTypeStateReset = () => {
    return {
        type: actionTypes.EVENT_TYPE_STATE_RESET
    };
};

export const eventTypeEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        eventTypeName: formValues.eventTypeName,
        eventTypeDescription: formValues.eventTypeDescription,
        isEnabled : finalIsEnabled
    }
    return async (dispatch) => {
        try{
            dispatch(eventTypeCreateStart());
            const response = await axios.patch("http://localhost:3030/api/eventTypes/"+ formValues.eventTypeId,values);
            dispatch(showToaster('success','Event Type Edited Successfully'));
        }catch(error){
            dispatch(showToaster('error',error.response.data.message))
        }
    }
}
