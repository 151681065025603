import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, TextField, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class HotlistVehicleAddForm extends React.Component {

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
        console.log("event.target.id", event.target.id);
        console.log("event.target.value", event.target.value);
    }

    onChangeRadio = event => {
        this.setState({ vehicleStatus: event.target.value });
        console.log("vehcle status", event.target.value);
    }
    //#endregion

    //#region  Form Submit
    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    renderDateTimeField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {

        /* 
         * We are only taking the first 16 digits of the date and leaving the rest 
         * because below component needs a 16 digit value 
         */

        input.value = input.value.slice(0, 16);

        return (
            <React.Fragment>
                <TextField
                    variant="outlined"
                    fullWidth
                    label={label}
                    id="datetime-local"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={touched && invalid}
                    helperText={touched && error}
                    {...input}
                    {...custom}
                />
            </React.Fragment>
        )
    }

    radioHotlistVehicleStatusButton = ({ input, meta: { touched, error, invalid }, ...reset }) => {

        /* Convert value to string as we have set it to string inside FormControlLabel */
        input.value = input.value.toString();

        return (
            <FormControl fullWidth variant="outlined" error={touched && invalid} helperText={touched && error}>
                <FormLabel component="legend">Vehicle Status</FormLabel>
                <RadioGroup row  {...input} {...reset}  >
                    <FormControlLabel value="1" control={<Radio />} label="Available" labelPlacement="end" />
                    <FormControlLabel value="0" control={<Radio />} label="Not Available" labelPlacement="end" />
                </RadioGroup>
                {(touched && error) ? <FormHelperText>{touched && error}</FormHelperText> : null}
            </FormControl>
        );
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Hotlist Vehicle"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" id="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Hotlist Vehicle"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Hotlist Vehicle"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="hotlistVehicleNumber" id="hotlistVehicleNumber" component={reduxFormFunctions.renderTextField} label="Hotlist Vehicle Number" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="vehicleStatus" id="vehicleStatus" component={this.radioHotlistVehicleStatusButton} label="Vehicle Status" onChange={this.onChangeRadio}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="hotlistFromTime" id="hotlistFromTime" component={this.renderDateTimeField} label="Hotlist From Time" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="hotlistToTime" id="hotlistToTime" component={this.renderDateTimeField} label="Hotlist To Time" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};

    if (!formValues.hotlistVehicleNumber) {
        error.hotlistVehicleNumber = "Enter Hotlist Vehicle Number";
    }
    if (!formValues.vehicleStatus) {
        error.vehicleStatus = "Please select availability status";
    }
    if (!formValues.hotlistFromTime) {
        error.hotlistFromTime = "Enter Hotlist From Time";
    }
    if (!formValues.hotlistToTime) {
        error.hotlistToTime = "Enter Hotlist To Time";
    }
    if (formValues.hotlistFromTime && formValues.hotlistToTime) {
        if (formValues.hotlistFromTime == formValues.hotlistToTime) {
            error.hotlistToTime = "Hotlist From Time and Hotlist To Time can not be same";
        }
    }
    return error;
};

export default reduxForm({
    validate: validate,
    form: 'HotlistVehicleAddForm'
})(withStyles(styles)(HotlistVehicleAddForm))