import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    loading: null
};

const shiftStateReset = (state,action) => {
    return updateObject(state, { loading: null });
};

const shiftCreateStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SHIFT_STATE_RESET :
            return shiftStateReset(state, action);
        
        case actionTypes.SHIFT_CREATE_START :
            return shiftCreateStart(state, action);

        default:
            return state;   
    }
}

export default reducer;