import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

const equipCatCreateStart = () => {
    return {
        type : actionTypes.EQUIP_CATEGORY_CREATE_START
    };
};
export const equipCatCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(equipCatCreateStart());
            const response = await axios.post(BASE_URL + "/api/equipmentCategories", formValues);
            dispatch(showToaster('success','Equipment Category Successfully Created.'));
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}

export const equipCatStateReset = () => {
    return {
        type: actionTypes.EQUIP_CATEGORY_STATE_RESET
    }
}

export const equipCatEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        equipmentCategoryName: formValues.equipmentCategoryName,
        equipmentCategoryDescription: formValues.equipmentCategoryDescription,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(equipCatCreateStart());
            const response = await axios.patch(BASE_URL + "/api/equipmentCategories/" + formValues.equipmentCategoryId, values);
            console.log(response);
            dispatch(showToaster('success','Equipment Category Successfully Edited.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}