import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    arrLocationCat: [],
    loading: false,
    strImagePath: null
};

const getLocationCatSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        arrLocationCat: action.locationCat
    });
    return updatedState;
};

const getImageSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        strImagePath: action.strImagePath
    });
    return updatedState;
};

const locationTypeStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const locationTypeCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_LOCATION_CAT_SUCCESS:
            return getLocationCatSuccess(state, action);

        case actionTypes.GET_IMAGE_PATH_SUCCESS:
            return getImageSuccess(state, action);
        case actionTypes.LOCATION_TYPE_STATE_RESET:
            return locationTypeStateReset(state, action);
        case actionTypes.LOCATION_TYPE_CREATE_START:
            return locationTypeCreateStart(state, action);

        default:
            return state;
    }
}

export default reducer;