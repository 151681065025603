import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";


const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

class LocationCatForm extends React.Component {

    onSubmit = (values) => {
        this.props.onSubmit(values);
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <Card>
                        <CardHeader
                            subheader={this.props.type + " Location Category"}
                            title="Location Category"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationCategoryName" component={reduxFormFunctions.renderTextField} label="Category Name" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationCategoryDescription" component={reduxFormFunctions.renderTextField} label="Category Description" />
                                </Grid>
                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
}

const validate = formValues => {
    const errors = {};

    if (!formValues.locationCategoryName) {
        errors.locationCategoryName = 'Enter Category Name';
    } else if (/^[0-9 ]*$/g.test(formValues.locationCategoryName)) {
        errors.locationCategoryName = 'Category Name should only have alphanumeric, underscores and spaces';
    }

    if (!formValues.locationCategoryDescription) {
        errors.locationCategoryDescription = 'Enter Category Description';
    } else if (/^[0-9 ]*$/g.test(formValues.locationCategoryDescription)) {
        errors.locationCategoryDescription = 'Category Description should only have alphanumeric, underscores and spaces';
    }

    if (formValues.locationCategoryDescription) {
        if (formValues.locationCategoryDescription.length > 201) {
            errors.locationCategoryDescription = "Maximum characters should be 200 only";
        }
    }

    return errors;
};

export default reduxForm({
    form: 'LocationCatForm',
    validate: validate,
})(withStyles(styles)(LocationCatForm));