import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Divider } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar'
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  button: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(20)
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px'
  },
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  text2: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(12)
  },
  button1: {
    float: 'right',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  }
});

class TrafficIncidentChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      trafficIncidentBarData: [],
      keys: [],
      selectedButton: '',
      anchorEl: null,
      open: false,
      menuButton: 'Today'
    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
  }

  handleButtonClick(event) {
    this.setAnchorEl(event.currentTarget);
    this.setState({
      open: !this.state.open
    })
  }

  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: false
    })
  }

  handleMenuClose() {
    this.setState({
      menuButton: 'Today',
      open: false
    })
    this.setAnchorEl(null);
  }

  async componentDidMount() {
    await this.todayButtonClick();
  }

  todayButtonClick = async () => {

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today", today);

    try {
      const todayData = await axios.get(BASE_URL + "/api/dashboard/trafficIncident-pie?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today);
      if (todayData.data != null) {
        if (todayData.data.message) {
          this.setState({
            trafficIncidentBarData: [],
            keys: [],
            selectedButton: 'Today',
            menuButton: 'Today'
          })
        }
        else {
          this.setState({
            trafficIncidentBarData: todayData.data.data,
            keys: todayData.data.keys,
            selectedButton: 'Today',
            menuButton: 'Today'
          })
        }
        console.log("traffic bar data", this.state.trafficIncidentBarData);
      }
      this.setAnchorEl(null);
    }
    catch (error) {
      console.log("error");
    }
  }

  onOneWeekButtonClick = async () => {

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today", today);

    var lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);
    let lastWeekDay = lastWeekDate.getDate();
    let lastWeekMonth = lastWeekDate.getMonth() + 1;
    let lastWeekYear = lastWeekDate.getFullYear();

    let lastWeek = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;

    console.log("lastWeek", lastWeek);

    try {
      const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/trafficIncident-pie?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today);
      if (lastWeekData.data != null) {
        if (lastWeekData.data.message) {
          this.setState({
            trafficIncidentBarData: [],
            keys: [],
            selectedButton: 'Last 7 days',
            menuButton: 'Last 7 days'
          })
        }
        else {
          this.setState({
            trafficIncidentBarData: lastWeekData.data.data,
            keys: lastWeekData.data.keys,
            selectedButton: 'Last 7 days',
            menuButton: 'Last 7 days'
          })
        }
        console.log("traffic bar data", this.state.trafficIncidentBarData);
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  onOneMonthButtonClick = async () => {

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today", today);

    var lastMonthDate = new Date();
    lastMonthDate.setDate(lastMonthDate.getDate() - 30);
    let lastMonthDay = lastMonthDate.getDate();
    let lastMonthMonth = lastMonthDate.getMonth() + 1;
    let lastMonthYear = lastMonthDate.getFullYear();

    let lastMonth = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;

    console.log("lastMonth", lastMonth);

    try {

      const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/trafficIncident-pie?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today)
      if (lastMonthData.data != null) {
        if (lastMonthData.data.message) {
          this.setState({
            trafficIncidentBarData: [],
            keys: [],
            selectedButton: 'Last 30 days',
            menuButton: 'Last 30 days'
          })
        }
        else {
          this.setState({
            trafficIncidentBarData: lastMonthData.data.data,
            keys: lastMonthData.data.keys,
            selectedButton: 'Last 30 days',
            menuButton: 'Last 30 days'
          })
        }
        console.log("traffic bar data", this.state.trafficIncidentBarData);
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    const { classes } = this.props;

    const CenteredMetric = ({ nodes, centerX, centerY }) => {
      return (
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          {this.state.total}
        </text>
      )
    }

    const CustomFontTick = tick => {
      return (
        <g transform={`translate(${tick.x},${tick.y + 18})`}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            // style={{
            //   fontSize: 8,
            //   opacity: 1,
            // }}
            style={this.props.modelStatus === true ? { fontSize: 10, fontWeight: 'bold' } : { fontSize: 4, fontWeight: 'bold', transform: 'scale(0.8 ,1.5)' }}

          >{tick.value}
          </text>
        </g>
      )
    }

    const data = this.state.trafficIncidentBarData.length;
    return (
      <React.Fragment>
        <div className={classes.div}>
          <Typography className={classes.text} variant="h6" align="left">Traffic Incident</Typography>

          <Button className={classes.button1} size="small" variant="contained" color="primary" onClick={this.handleButtonClick}>
            {this.state.menuButton}
          </Button>

          <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
            <MenuItem onClick={() => this.todayButtonClick(this.state.eventTypeId)}>Today</MenuItem>
            <MenuItem onClick={() => this.onOneWeekButtonClick(this.state.eventTypeId)}>Last 7 days</MenuItem>
            <MenuItem onClick={() => this.onOneMonthButtonClick(this.state.eventTypeId)}>Last 30 days</MenuItem>
          </Menu>
        </div>
        <Divider />
        {data && data != 0 ?
          (
            <ResponsiveBar
              data={this.state.trafficIncidentBarData}
              keys={this.state.keys}
              indexBy="TrafficIncident"
              margin={{ top: 10, right: 70, bottom: 95, left: 40 }}
              padding={0.5}
              groupMode="stacked"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              //colors={{ scheme: 'paired' }}
              colors={({ id }) => {
                const colorPalette = {
                  'OPEN':'#C54B52',
                  'ACTIVE': '#4B7CC5',
                  'MANAGE': '#D38831',
                  'RESOLVE': '#85CD60',
                  'AUDIT': '#CDCB60',
                };
                return colorPalette[id] || '#CCCCCC'; // Default color if id is not found in the palette
              }}leGridY={false}

              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Traffic Incident Type',
                legendPosition: 'middle',
                legendOffset: 35,
                itemWidth: 40,
                itemHeight: 20,
                renderTick: CustomFontTick
              }}

              axisLeft={{
                tickSize: 2,
                tickPadding: 3,
                tickRotation: 0,
                legend: 'Count',
                legendPosition: 'middle',
                legendOffset: -32,
                format: e => Math.floor(e) === e && e
              }}

              markers={[
                {
                  axis: 'y',
                  value: 0,
                  lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
                  legendOrientation: 'vertical',
                },
              ]}

              labelSkipWidth={5}
              labelSkipHeight={7}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 45,
                  translateY: 20,
                  itemsSpacing: 0,
                  itemWidth: 55,
                  itemHeight: 15,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 6,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          ) :
          (
            <Typography className={classes.text2} variant="h6" align="center">No Data Available For {this.state.selectedButton}</Typography>
          )}

      </React.Fragment>
    )

  }

}

export default withStyles(styles)(TrafficIncidentChart)