import React, { Component } from "react";
import MaterialTable from "material-table";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';

import { TablePagination } from '@material-ui/core';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import appConfig from '../../appConfig.json';
import axios from 'axios';
import _ from 'lodash';

const BASE_URL = appConfig.BASE_URL;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const styles = theme => ({
  root: {
    marginTop: '10px',
    clear: 'both',
    // '& tbody.MuiTableBody-root >:nth-of-type(2n)': {
    //   backgroundColor: '#e5f4f9',
    // },
    '& tbody>.MuiTableRow-root:hover': {
      backgroundColor: "#eaeaea",
    },
    '& thead th': {
      backgroundColor: '#4888bd',
      color: 'white'
    }
  },
  reportSection: {
    marginTop: '10px',
    paddingBottom: '10px'
  },
  downloadButton: {
    marginRight: '10px'
  }
});

class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: props.skip || 0,
      limit: props.limit || 10,
      currentLimit: props.limit || 10,
      currentSkip: props.skip,
      selectedRowId: null
    }
    this.tableReference = React.createRef();
  }
  componentDidMount() {
    this.reload();
  }
  componentDidUpdate(prevProps) {
    if (this.props.endpoint != prevProps.endpoint ||
      this.props.urlParams != prevProps.urlParams ||
      this.state.skip != this.state.currentSkip ||
      this.state.limit != this.state.currentLimit ||
      this.props.reqBody != prevProps.reqBody) {
      let stateObj = { currentLimit: this.state.limit };
      if (this.state.skip == this.state.currentSkip) {
        this.reload(true);
        stateObj.currentSkip = 0
        stateObj.skip = 0;
      } else {
        this.reload();
        stateObj.currentSkip = this.state.skip
      }
      this.setState(stateObj);
    }
  }

  reload(reset = false) {
    let additionalUrlParams = "";
    if (this.props.method == null || this.props.method.toLowerCase() == 'get') {
      if (this.props.urlParams != null && this.props.urlParams.length > 0) {
        this.props.urlParams.forEach((urlParamObj) => {
          additionalUrlParams = additionalUrlParams + '&' + urlParamObj['key'] + "=" + urlParamObj['value'];
        });
      }
      this.props.fetchMasterData(this.props.endpoint + (this.props.endpoint.indexOf('?') == -1 ? '?' : '&') + '$skip=' + (reset ? 0 : (this.state.skip * this.state.limit)) + '&$limit=' + this.state.limit + (this.props.isMasterGrid == null || this.props.isMasterGrid == true ? '&reqType=grid' : '') + additionalUrlParams);
    } else {
      if (this.props.isReportGrid == true) {
        if (reset == true) {
          this.props.reqBody['PAGINATION_OFFSET'] = 0;
        } else {
          this.props.reqBody['PAGINATION_OFFSET'] = (this.state.skip * this.state.limit);
        }
        this.props.reqBody['PAGINATION_NEXT'] = this.state.limit;
      }
      this.props.fetchGenericData(this.props.method, this.props.endpoint, this.props.reqBody);
    }

  }
  handlePageChange(page) {
    this.setState({ skip: page });
  }

  openDownloadXLSMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeDownloadXLSMenu = () => {
    this.setState({ anchorEl: null });
  };

  openDownloadPDFMenu = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  closeDownloadPDFMenu = () => {
    this.setState({ anchorEl2: null });
  };
  downloadReport = async (reportFormat, reportData) => {
    let additionalUrlParams = "";
    if (this.props.urlParams != null && this.props.urlParams.length > 0) {
      this.props.urlParams.forEach((urlParamObj) => {
        additionalUrlParams = additionalUrlParams + '&' + urlParamObj['key'] + "=" + urlParamObj['value'];
      });
    }
    let url = BASE_URL + '/' + this.props.endpoint;
    if (url.indexOf('?') != -1) {
      url = url + '&';
    } else {
      url = url + '?';
    }
    url = url + 'reqType=report&reportFormat=' + reportFormat;
    if (reportData == 'currentPage') {
      url = url + '&$skip=' + (this.state.skip * this.state.limit) + '&$limit=' + this.state.limit + '&reportData=currentpage';
    } else {
      url = url + '&reportData=all';
    }
    url = url + additionalUrlParams;
    let response;
    if (reportFormat == 'xls') {
      response = await axios
        .get(url, {
          headers:
          {
            'Content-Disposition': "attachment;",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
          responseType: 'arraybuffer'
        });
    } else if (reportFormat == 'pdf') {
      response = await axios
        .get(url, {
          headers:
          {
            'Accept': 'application/pdf',
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer'
        });
    }
    console.log('response: ', response)
    const objUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = objUrl;
    if (reportFormat == 'xls') {
      link.setAttribute('download', this.props.endpoint.substring(this.props.endpoint.lastIndexOf('/') + 1) + '.xlsx');
    } else if (reportFormat == 'pdf') {
      link.setAttribute('download', this.props.endpoint.substring(this.props.endpoint.lastIndexOf('/') + 1) + '.pdf');
    }
    document.body.appendChild(link);
    link.click();
    if (reportFormat == 'xls') {
      this.closeDownloadXLSMenu();
    }
    else {
      this.closeDownloadPDFMenu();
    }
  }
  render() {
    const { classes } = this.props;
    var actionsArray = [];
    if (this.props.actions != null && this.props.actions.length > 0) {
      actionsArray = this.props.actions;
    }
    if (this.props.isEditable) {
      actionsArray.push({
        icon: EditIcon, onClick: this.props.onEditClick
      });
    }
    this.props.masterGrid.masterColumns.forEach(column => {
      if (column.type == 'image') {
        column.render = (rowData) => {
          return <img style={{ 'height': '40px' }} src={BASE_URL + "/" + rowData[column.field]}></img>
        }
      } else if ((column.type && column.type.toLowerCase() == 'datetime') || column.field == 'createdAt' || column.field == 'Created At' || column.field == 'Updated At' || column.field == 'updatedAt') {
        column.render = (rowData) => {
          // console.log(column.field);
          // console.log(rowData);
          let fieldData = _.get(rowData, column.field);
          if (fieldData) {
            return <div> {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(fieldData))}</div>
          }
          return fieldData;
        }
      } else if (column.field == 'isEnabled') {
        column.render = (rowData) => {
          let style = {
            color: rowData.isEnabled ? 'green' : 'red'
          }
          return <div style={style}>{rowData.isEnabled ? 'Enabled' : 'Disabled'}</div>
        }
      }
      column.cellStyle = {
        border: '1px dotted lightgrey'
      }
      column.headerStyle = {
        borderRight: '1px dotted lightgrey',
        borderLeft: '1px dotted lightgrey',
        // backgroundColor:'lightgrey'
      }
    });
    
    return (
      <div className={classes.root} style={{ maxWidth: "100%" }}>
        <MaterialTable
          icons={tableIcons}
          columns={this.props.masterGrid.masterColumns}
          data={this.props.masterGrid.masterData.data}
          isLoading={this.props.masterGrid.status == 'loading'}
          pageSize={this.state.limit}
          emptyRowsWhenPaging={false}
          pageSizeOptions={this.props.pageSizeOptions || [5, 10, 50, 100]}

          // onRowClick={(evt, selectedRow) => {
          //   if (evt.target.ondblclick == null) {
          //     evt.target.ondblclick = () => {
          //       if (this.props.onRowDblClick) {
          //         this.props.onRowDblClick(evt, selectedRow);
          //       }
          //     }
          //   }
          //   if (this.props.onRowClick) {
          //     this.props.onRowClick(evt, selectedRow);
          //   }
          // }}
          onRowClick={(event, rowData) => {
            if (this.props.idField != null) {
              this.setState({ selectedRowId: rowData[this.props.idField] })
            }
            if (this.props.onRowClick) {
              this.props.onRowClick(event, rowData)
            }
          }
          }
          options={{
            rowStyle: (rowData, index) => {
              let bgColor = index % 2 == 0 ? '#e5f4f9' : 'white';
              if (this.props.idField && rowData[this.props.idField]) {
                if (this.state.selectedRowId == rowData[this.props.idField]) {
                  bgColor = '#fba566';
                }
              }
              return {
                backgroundColor: bgColor
              };
            },
            // rowStyle: {sele},
            maxBodyHeight: this.props.maxHeight ? this.props.maxHeight : 450,
            minBodyHeight: 200,
            paging: this.props.paging == null? true: this.props.paging,
            toolbar: false,
            actionsColumnIndex: -1,
            pageSize: this.state.limit,
            pageSizeOptions: this.props.pageSizeOptions || [5, 10, 50, 100],
            search: false,
            headerStyle: { fontWeight: 600 },
            selection: this.props.multiselect ? this.props.multiselect : false,
            // selectionProps: {
            //   color: 'primary',
            // }
            // showTextRowsSelected: false
          }}
          onSelectionChange={this.props.onSelectionChange ? this.props.onSelectionChange : null}
          title=""
          actions={actionsArray}
          tableRef={this.tableReference}
          components={{
            Pagination: (componentProps) => <TablePagination
              {...componentProps}
              count={this.props.masterGrid.masterData.totalCount}
              page={this.props.masterGrid.masterData.page}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={this.props.pageSizeOptions || [5, 10, 50, 100]}
              onChangePage={(evt, page) => {
                this.setState({ skip: page })
              }}
              onChangeRowsPerPage={(evt, rowsPerPage) => {
                this.setState({ limit: evt.target.value });
                this.tableReference.current.onChangeRowsPerPage(evt);
              }}
            />
          }}
        />
        {/* <CircularProgress></CircularProgress> */}
        {this.props.downloadReport == true &&
          <div className={classes.reportSection}>
            <Button className={classes.downloadButton} onClick={this.openDownloadXLSMenu} color="primary" variant="contained">Export To Excel</Button>
            <Menu
              id="download-menu-xls"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.closeDownloadXLSMenu}
            >
              <MenuItem onClick={() => this.downloadReport('xls', 'currentPage')}>Current Page</MenuItem>
              <MenuItem onClick={() => this.downloadReport('xls', 'all')}>All</MenuItem>
            </Menu>
            <Button className={classes.downloadButton} onClick={this.openDownloadPDFMenu} color="primary" variant="contained">Export To PDF</Button>
            <Menu
              id="download-menu-pdf"
              anchorEl={this.state.anchorEl2}
              keepMounted
              open={Boolean(this.state.anchorEl2)}
              onClose={this.closeDownloadPDFMenu}
            >
              <MenuItem onClick={() => this.downloadReport('pdf', 'currentPage')}>Current Page</MenuItem>
              <MenuItem onClick={() => this.downloadReport('pdf', 'all')}>All</MenuItem>
            </Menu>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    masterGrid: state.masterGrid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMasterData: (endpoint) => dispatch(actions.fetchMasterGridData(endpoint)),
    fetchGenericData: (method, endpoint, data) => dispatch(actions.fetchGridData(method, endpoint, data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(DataGrid));