import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton, Box, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DataGrid from '../../../components/mastersGrid/DataGrid';
import ECBAudioPlayer from './ECBAudioPlayer';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import axios from 'axios';
import appConfig from '../../../appConfig.json';
const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    modal: {
        'overflow-y': 'auto',
    },
    marquee: {
        color:'red',
        fontWeight: 'bold'
      }
});


class ECBMapDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            audioModalOpen: false,
            audioSrc : null
        };
        this.grid = React.createRef();
    }

    closeModal = () => {
        this.setState({audioModalOpen: false});
    }

    render() {
        const { classes, selectedEquipment } = this.props;
        console.log(this.props.selectedEquipment)

        let modalAudioDiv = <Modal open={this.state.audioModalOpen} 
                                    className={classes.modal} 
                                    onClose={() => { this.closeModal() }}
                                    closeAfterTransition>
                                        <ECBAudioPlayer onClose={() => { this.closeModal() }} audioSrc={this.state.audioSrc}/>
                            </Modal>
         const headerTitle = selectedEquipment.equipmentName + ' | ' + this.props.selectedEquipment.chainageName + ' | ' + this.props.selectedEquipment.directionName;
         let alert;
        if(selectedEquipment.isFaulty === 1){
        alert =   
                <div className={classes.marquee}>
                    These equipment is under Maintaince
                </div> 
        }
        return (
            <React.Fragment>
                <Container maxWidth="md" className={classes.container}>
                    <Card>
                        <CardHeader
                            title={headerTitle}
                            subheader = {alert}
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>

                            <Box mt={3}>
                                {this.props.selectedEquipment.equipmentId != null &&
                                    <DataGrid  ref={this.grid}
                                        endpoint="api/ecbCalls"
                                        limit="5"
                                        skip="0"
                                        paging={false}
                                        urlParams={[
                                            { 
                                                key: "equipmentId", value: this.props.selectedEquipment.equipmentId,
                                                // key:"$sort[updatedAt]", value: "-1",
                                         },
                                         { 
                                            // key: "equipmentId", value: this.props.selectedEquipment.equipmentId,
                                            key:"$sort[updatedAt]", value: "-1",
                                        }   
                                        ]}
                                        actions={[
                                            {
                                                icon: VolumeUpIcon,
                                                onClick: async (event, rowData) => {
                                                    console.log("ROWDATA FROM ", rowData);
                                                    const res = rowData.fullAudioFilePath
                                                    this.setState({ audioSrc: res });
                                                    // try {
                                                        
                                                    // } catch (error) {
                                                    //     console.log(error.response);
                                                    // }
                                                    this.setState({ audioModalOpen: true })
                                                }
                                            }
                                        ]}
                                    />
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
                {modalAudioDiv}
            </React.Fragment>
        );

    }
}

export default connect(null, null)(withStyles(styles)(ECBMapDetails));