import axios from "axios";
import { arrayInsert } from "redux-form";
import * as actionTypes from '../actionTypes';
import { getEventAdditionalInfoSuccess } from "./events";
import { showToaster } from '../common';
import {fetchEventDetails} from './events';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const editEventAdditionalInfoSuccess = (successMsg) => {
  return {
    type: actionTypes.EVENT_INFORMATION_CRATED,
    successMsg: successMsg
  };
}

export const editEventAdditionalInfo = (eventAddInfo) => {
  return dispatch => {
    var txnEventAdditionalInfoId = eventAddInfo.txnEventAdditionalInfoId;
    delete eventAddInfo.txnEventAdditionalInfoId;
    if (txnEventAdditionalInfoId == null) {
      axios
        .post(BASE_URL + "/api/eventAdditionalInfo/", eventAddInfo)
        .then(res => {
          console.log("editEventAdditionalInfo API", res.data);
          dispatch(showToaster('success', 'Information saved successfully!'));
          dispatch(fetchEventDetails(eventAddInfo.eventId));
          console.log('getEventAdditionalInfoSuccess')
          dispatch(getEventAdditionalInfoSuccess(res.data));
        }).catch(error => {
          dispatch(showToaster('error', 'Error saving Information!'));
          // dispatch(getEventGeneralDetailsFail(error.response.data.message));
        });
    } else {
      axios
        .patch(BASE_URL + "/api/eventAdditionalInfo/" + txnEventAdditionalInfoId, eventAddInfo)
        .then(res => {
          console.log("editEventAdditionalInfo API", res.data);
          dispatch(showToaster('success', 'Information saved successfully!'));
          // dispatch(editEventAdditionalInfoSuccess("Event Additional Information Saved"));
        }).catch(error => {
          dispatch(showToaster('error', 'Error saving Information!'));
          // dispatch(getEventGeneralDetailsFail(error.response.data.message));
        });
    }
  };

}



export const addVehicleInfo = (vhchlInfoAdded) => {
  return dispatch => {
    console.log("vhchlInfoAdded actions", vhchlInfoAdded);
    axios
      .post(BASE_URL + "/api/eventAccidentVehicleInfo/", vhchlInfoAdded)
      .then(res => {
        console.log("addVehicleInfo API", res.data);
        // dispatch(getStakeholderUserSuccess(res.data));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };

}


export const patchVehicleInfo = (vhchlInfoToPatch) => {
  return async dispatch => {
    
    for (var j = 0; j < vhchlInfoToPatch.length; j++) {
      try {
        
        var vhclInfoId = vhchlInfoToPatch[j].eventAccidentVehicleInformationId;
        var patchVhclInfo = {
          typeOfVehicle: vhchlInfoToPatch[j].typeOfVehicle,
          vehicleMakeModel: vhchlInfoToPatch[j].vehicleMakeModel,
          vehicleNumberPlate: vhchlInfoToPatch[j].vehicleNumberPlate,
        }
        var ret = await axios.patch(BASE_URL + "/api/eventAccidentVehicleInfo/" + vhclInfoId, patchVhclInfo);
      } catch (err) {

      }
      
      
    }
  };

}


export const deleteVehicleInfo = (vhchlInfoDeleted) => {
  return async dispatch => {
    console.log('deleting vehicle info', vhchlInfoDeleted)
    for (var j = 0; j < vhchlInfoDeleted.length; j++) {
      try {
        var ret = await axios.patch(BASE_URL + "/api/eventAccidentVehicleInfo/" + vhchlInfoDeleted[j].eventAccidentVehicleInformationId, {isEnabled : 0});
      } catch (err) {

      }
    }
  };
}

export const addEventFiles = (files, addEventParams) => {
  return async dispatch => {
    // console.log("addInfoFileSourceId", addInfoFileSourceId);
    if (files != null) {
      for (var j = 0; j < files.length; j++) {
        try {
          console.log("uploading file", files[j].eventFileName);
          const formData = new FormData();
          formData.append('public', false)
          formData.append('uploadPath', addEventParams.eventPath)
          formData.append('files', files[j].eventFile);
          const retAddFiles = await axios.post(BASE_URL + "/api/uploads", formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          });
          console.log("return add event files", files[j], retAddFiles);

          var eventFile = {
            eventId: addEventParams.txnEventId,
            eventFileName: files[j].eventFileName,
            eventFileExtension: files[j].eventFileExtension,
            eventFilePath: retAddFiles.data[0].path,
            eventFileSourceId: addEventParams.eventFileSourceId,
            eventType: addEventParams.eventTypeId,
            eventSubType: addEventParams.eventSubtypeId,
          }

          var eventFilesArr = [];
          eventFilesArr.push(eventFile);
          console.log(eventFile);
          const retEventFile = await axios.post(BASE_URL + "/api/eventFiles", eventFilesArr);
          console.log(retEventFile)
        } catch (err) {

        }
      }
    }
  };
}


export const deleteEventFiles = (eventFilesDeleted) => {
  return async dispatch => {
    if (eventFilesDeleted != null) {
      for (var j = 0; j < eventFilesDeleted.length; j++) {
        try {
          var ret = await axios.delete(BASE_URL + "/api/eventFiles/" + eventFilesDeleted[j].txnEventFilesId);
        } catch (err) {

        }
      }
    }
  };
}