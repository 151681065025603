import axios from "axios";
import * as actionTypes from './actionTypes';
import appConfig from '../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

export const getGlobalConfigSuccess = (globalConfig) => {
  return {
    type: actionTypes.GLOBAL_CONFIG_SUCCESS,
    globalConfig: globalConfig,
  };
};

export const getGlobalConfigFail = (errorMsg) => {
  return {
    type: actionTypes.GLOBAL_CONFIG_FAIL,
    errorMsg: errorMsg
  }
}

export const getGlobalConfig = () => {
  return dispatch => {
    axios
      .get(BASE_URL + "/api/globalConfig?reqType=all")
      .then(res => {
        let globalConfigMap = {};
        res.data.forEach((config) => {
          globalConfigMap[config.configName] = {
            appId: config.appId,
            configDescription: config.configDescription,
            configValue1: config.configValue1,
            configValue2: config.configValue2,
            configRemarks: config.configRemarks
          }
        })
        dispatch(getGlobalConfigSuccess(globalConfigMap));
      }).catch(error => {
        // Something happened in setting up the request that triggered an Error
        dispatch(getGlobalConfigFail(error.response.data.message));
      });
  };
};

export const getEntitlementsSuccess = (entitlements) => {
  return {
    type: actionTypes.GET_ENTITLEMENTS_SUCCESS,
    entitlements: entitlements,
  };
};

export const getEntitlements = () => {
  return dispatch => {
    axios.get(BASE_URL + "/api/roleEntitlements?structure=array")
      .then(res => {
        if (res.data.menuEntitlement)
          dispatch(getEntitlementsSuccess(res.data.menuEntitlement))
      }).catch(e => console.log("error in getting entiitlements..", e))
  }
}

export const showToaster = (type, message, time) => {
  if (time == null) {
    time = 3;
  }
  time = time * 1000;
  return dispatch => {
    dispatch(toasterON({ type, message }));
    setTimeout(() => dispatch(toasterOFF()), time);
  }
}
export const drawerOpen = (toasterMessageConfig) => {
  return {
    type: actionTypes.DRAWER_OPEN,
    drawerOpen: true,
  };
};

export const drawerClose = (toasterMessageConfig) => {
  return {
    type: actionTypes.DRAWER_CLOSE,
    drawerOpen: false,
  };
};

export const toasterON = (toasterMessageConfig) => {
  return {
    type: actionTypes.TOASTER_ON,
    toasterMessageConfig: toasterMessageConfig,
  };
};

export const toasterOFF = (_) => {
  return {
    type: actionTypes.TOASTER_OFF,
    toasterMessageConfig: null,
  };
};