import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import DataGrid from '../../../../components/mastersGrid/DataGrid';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import LocationTypeCreate from './locationTypeCreate';
import LocationTypeEdit from './locationTypeEdit';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm } from 'redux-form';
import * as reduxFormFunction from '../../../../components/common/Redux_forms_Common';
import Toaster from '../../../../components/common/Toaster';
import checkEntitlement from "../../../../utils/checkEntitlement";


const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8',
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto',
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    }
});

class LocationType extends React.Component {

    currentSelectedRow = null;
    constructor() {
        super();
        this.state = {
            isEnabled: "all",
            locationTypeName: "",
            locationCategoryId: "",
            urlParams: [],
            isOpen: false,          
            modalType: ""
        };
        this.grid = React.createRef();
    }

    componentDidMount() {
        this.props.getLocationCat1();
    }

    onSearchSubmit = (event) => {
        event.preventDefault();
        console.log("state =", this.state);
        const urlParams = [];
        console.log("LENGTH: " + this.state.locationCategoryId);
        if (this.state.locationTypeName.length > 0) {
            urlParams.push({ 'key': 'locationTypeName', value: this.state.locationTypeName });
        }

        if (this.state.locationCategoryId != 0) {
            urlParams.push({ 'key': 'locationCategoryId', value: this.state.locationCategoryId });
        }

        this.setState({ urlParams: urlParams })
        console.log("urlParams : " + JSON.stringify(urlParams))
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleRadioChange = (event) => {
        console.log(event.target.value);
        this.setState({ isEnabled: event.target.value });
    }

    componentDidUpdate(prevProps, prevState) {
        /*
         * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
         */ 
        if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
            this.grid.current.reload();
        }
    }

    render() {
        const { classes } = this.props;
        let modalDiv = null;

        if (this.state.modalType === "ADD") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
                closeAfterTransition>
                <LocationTypeCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>            
        }

        if (this.state.modalType === "EDIT") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
                closeAfterTransition>
                <LocationTypeEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>            
        }

        return (
            <Container maxWidth="xl" className={classes.root}>
                <Toaster
                    open={this.props.toasterOpen}
                    message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
                    severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
                />
                <Typography variant="h4" gutterBottom className={classes.header}>
                    Location Type
          </Typography>
                <Button variant="contained"
                 disabled={!checkEntitlement(this.props.location.pathname,'add')}
                    color="primary"
                    startIcon={<AddIcon />}
                    className={classes.addButton}
                    onClick={() => this.setState({ isOpen: true, modalType: "ADD" })} >
                    Add Type
          </Button>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        <div style={{ maxWidth: '70px' }}>
                                            <Typography variant="h6" gutterBottom className={classes.sheader}>
                                                Search
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Name"
                                            name="locationTypeName"
                                            className={classes.sheader}
                                            onChange={this.handleInputChange}
                                            value={this.state.locationTypeName}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field name="locationCategoryId" component={reduxFormFunction.renderSelectField} onChange={this.handleInputChange} label="Category" id="locationCategoryId">
                                            <option value={'0'}>{'Select Category'}</option>
                                            {this.props.arrLocationCatProps.map((userRoleProp) => <option key={userRoleProp.locationCategoryId} value={userRoleProp.locationCategoryId}>{userRoleProp.locationCategoryName}</option>)}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Button
                                            className={`${classes.sheader} ${classes.addButton}`}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<SearchIcon />}
                                            type="submit"
                                            onClick={() => {
                                                //temporary. Form the urlParams object according to the search performed
                                                // this.setState({ urlParams: [{ key: 'loginId', value: 'ATAD001' }] });
                                            }}>
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <DataGrid downloadReport={true} ref={this.grid} endpoint="api/locationTypes" urlParams={this.state.urlParams} isEditable={checkEntitlement(this.props.location.pathname,'edit')} onEditClick={(event, rowData) => {
                        console.log("ROWDATA" + JSON.stringify(rowData));
                        this.currentSelectedRow = rowData;
                        this.setState({ isOpen: true, modalType: "EDIT" })
                    }} />
                </Box>
                {modalDiv}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.locationTypeReducer.loading,
        toasterOpen: state.common.toasterOpen,
        toasterMessageConfig: state.common.toasterMessageConfig,       
        arrLocationCatProps: state.locationTypeReducer.arrLocationCat,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLocationCat1: () => dispatch(actions.getLocationCat())
    };
};

LocationType = connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationType);

export default reduxForm({
    form: 'LocationType'    
})(withStyles(styles)(LocationType));