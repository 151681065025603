import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, InputLabel, Box, Tabs, Tab
} from '@material-ui/core';
import TabPanel from '../../../components/Navigation/TabPanel';
import { connect } from "react-redux";
import { FormHelperText, Typography } from '@material-ui/core'
import EventAlertVMS from './EventAlertVMS';
import EventAlertSMS from './EventAlertSMS';
import EventAlertEmail from './EventAlertEmail';
import EventAlertWeb from './EventAlertWeb';
import axios from "axios";
import EventHighwayAid from './EventHighwayAid';
import EventManualAlert from './EventManualAlert';
import appConfig from '../../../appConfig.json';
import commonStyles from '../../../commonStyles';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
});

class EventsAlert extends React.Component {
  constructor() {
    super();
    this.state = {
      tabValue: 0,
      vmsCategoryId: null
    };
  }
  componentDidMount() {
    axios
      .get(BASE_URL + "/api/notificationCategories?reqType=all")
      .then(res => {
        //console.log(res.data);
        this.setState({
          vmsCategoryId: this.getNotificationCategoryId('VMS', res.data),
          smsCategoryId: this.getNotificationCategoryId('SMS', res.data),
          emailCategoryId: this.getNotificationCategoryId('EMAIL', res.data),
          webAlertCategoryId: this.getNotificationCategoryId('WEB ALERT', res.data)
        });
        // this.setState({notificationCategories:res.data});
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  }
  tabOnChange = (event, value) => {
    this.setState({ tabValue: value });
  }

  renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
    <TextField
      variant="outlined"
      fullWidth
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )

  renderDateField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
    //console.log("input and custom in datefield", input, custom);
    return (
      <TextField
        fullWidth
        variant="outlined"
        label={label}
        type="datetime-local"
        defaultValue="2017-05-24T10:30"
        error={touched && invalid}
        helperText={touched && error}
        // className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        {...input}
        {...custom}
      />
    )
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
    return (
      <FormControl fullWidth variant="outlined" error={touched && error} helperText={touched && error}>
        <InputLabel>{label}</InputLabel>
        <Select
          error={touched && error}
          native
          {...input}
          {...custom}
          inputProps={{
            name: input.name,
            id: 'color-native-simple'
          }}
        >
          {children}

        </Select>
        {this.renderFromHelper({ touched, error })}
      </FormControl>
    )
  }


  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }

  renderCheckbox = ({ input, label }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    </div>
  )

  getNotificationCategoryId(notificationCategoryName, notificationCategories) {
    //console.log('getting notification category by name: ', notificationCategoryName)
    if (notificationCategories != null) {
      var notificationCategory = notificationCategories.find((nCategory) => nCategory.notificationCategoryName == notificationCategoryName);
      if (notificationCategory != null) {
        //console.log('notificationCategory.notificationCategoryId: ', notificationCategory.notificationCategoryId);
        return notificationCategory.notificationCategoryId;
      }
    }
  }

  render() {
    const { classes } = this.props;
    console.log('In render.. state is: ', this.state);
    console.log('In render.. state is: ', this.props);
    console.log('updateBy: ', this.props.initialValues.updatedAt.toString().slice(0,10) + '  ' + this.props.initialValues.updatedAt.toString().slice(11,16));
    var vmsCatId = this.state.vmsCategoryId;
    return (
      <React.Fragment>
        <form autoComplete="off">
          <fieldset disabled={true}>
            <Typography variant="h5" className="sectionHeading">Event</Typography>
            <Grid container spacing={8}>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="eventTypeId" component={reduxFormFunctions.renderSelectField} label="Event Type" onChange={this.eventTypeOnChange} disabled>
                  <option value="" />
                  {this.props.eventType.map((eventType) => {
                    return (<option value={eventType.eventTypeId}>{eventType.eventTypeName}</option>);
                  })}
                </Field>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="eventSubtypeId" component={reduxFormFunctions.renderSelectField} label="Event Sub Type" onChange={this.eventSubtypeOnChange}>
                  <option value="" />
                  {this.props.eventSubType.map((eventSubType) => {
                    return (<option value={eventSubType.eventSubtypeId}>{eventSubType.eventSubtypeName}</option>);
                  })}
                </Field>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="location" component={this.renderTextField} label="Location" />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                  <TextField
                    name="updatedAt"
                    fullWidth
                    label="Last Update Time"
                    multiline
                    rowsMax={1}
                    value={this.props.initialValues.updatedAt.toString().slice(0,10) + '  ' + this.props.initialValues.updatedAt.toString().slice(11,16)}
                    variant="outlined"
                    disabled
                  />
                {/* <Field name="updatedAt" component={this.renderTextField} label="Last Update Time" /> */}
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="nearestHospitalLocation.locationDetailsName" component={this.renderTextField} label="Nearest Hospital" />
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="nearestPoliceLocation.locationDetailsName" component={this.renderTextField} label="Nearest Police Station" />
              </Grid>
            </Grid>
          </fieldset>

          <Divider variant="list" className={classes.atmsPageSectionDivider} />
          <Typography variant="h5" className="sectionHeading">Actions</Typography>
          <Box>
            <Tabs
              className={classes.tabs}
              value={this.state.tabValue}
              onChange={this.tabOnChange}
              indicatorColor="primary"
              textColor="primary">
              <Tab label="VMS" />
              <Tab label="SMS" />
              <Tab label="EMAIL" />
              <Tab label="WEB ALERT" />
              <Tab label="HIGHWAY AID" />
              <Tab label="MANUAL ALERTS" />
            </Tabs>
            <TabPanel value={this.state.tabValue} index={0}>
              <EventAlertVMS notificationCategoryId={vmsCatId} eventType={this.props.initialValues.eventType} eventId={this.props.eventId} eventStatusName={this.props.eventStatusName} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              <EventAlertSMS notificationCategoryId={this.state.smsCategoryId} eventId={this.props.eventId} eventStatusName={this.props.eventStatusName} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
              <EventAlertEmail notificationCategoryId={this.state.emailCategoryId} eventId={this.props.eventId} eventStatusName={this.props.eventStatusName} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={3}>
              <EventAlertWeb notificationCategoryId={this.state.webAlertCategoryId}  eventId={this.props.eventId} eventStatusName={this.props.eventStatusName} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={4}>
              <EventHighwayAid eventId={this.props.eventId} eventStatusName={this.props.eventStatusName} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={5}>
              <EventManualAlert eventId={this.props.eventId} eventDescription={this.props.eventDescription} eventStatusName={this.props.eventStatusName} />
            </TabPanel>
          </Box>
        </form >
      </React.Fragment >
    );
  }

}

const validate = formValues => {
  const errors = {};

  return errors;
};

const mapStateToProps = state => {
  return {
    eventType: state.eventGeneralDts.eventType,
    eventSubType: state.eventGeneralDts.eventSubType,
    eventId: state.selectedEvent.eventData.txnEventId,
    eventDescription: state.selectedEvent.eventData.eventDescription,
    eventStatusName: state.selectedEvent.eventData.eventStatus.eventStatusName
  };
};

EventsAlert = connect(
  mapStateToProps,
  null
)(EventsAlert);

export default reduxForm({
  form: 'EventsAlert',
  validate: validate,
})(withStyles(styles)(EventsAlert));
