import { updateObject } from "../utility";

const initialState = {
  masterColumns: [],
  masterData: [],
  status: 'not_done'
};

const fetchGridDataSuccess = (state, action) => {
  var updatedState = updateObject(state, {
    masterColumns: action.gridData.data.columns,
    masterData: {
      data: action.gridData.data.rows,
      totalCount: action.gridData.total,
      page: (action.gridData.skip / action.gridData.limit)
    },
    status: 'done'
  });
  return updatedState;
};
const fetchGridDataFail = (state, action) => {
  return updateObject(state, {
    errorMsg: action.errorMsg,
    status: 'error'
  });
};
const reloadGridStart = (state, action) => {
  return updateObject(state, {
    status: 'loading',
    masterColumns: [],
    masterData: [],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GRID_DATA_FETCH_SUCCESS':
      return fetchGridDataSuccess(state, action);
      
    case 'GRID_DATA_FETCH_FAIL':
      return fetchGridDataFail(state, action);
      
    case 'GRID_RELOAD_START':
      return reloadGridStart(state, action);
      
    default:
      return state;
  }
};

export default reducer;