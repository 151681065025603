import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, Card, CardContent, Grid } from '@material-ui/core';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from "react-redux";
import ViolationFilter from '../../../components/common/violationManagement/ViolationFilter';
import RejectionDetails from './RejectionDetails';
import * as actions from '../../../store/actions/index';
import globals from '../../../utils/globals';

const styles = theme => ({
    root: {
      marginTop: theme.spacing(1),
      backgroundColor: '#f4f6f8',
    },
    header: {
        display: 'inline'
    }
});

class Dashboard extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedRejectedRow : null,
      urlParams: null,
      violationName : null
    };
    this.grid = React.createRef();    // Stores the Ref for DataGrid
    this.rejectDetRef = React.createRef();
  }

  componentDidMount() {

    // Get the Directions
    this.props.getDirections();
    
    // Get the Vehicle Class
    this.props.getVehicleClass();

    // Get the Sections
    this.props.getSections();

  }

   /*
   * OnSubmit for rejection search in filter
   */

  onSubmit = (urlParams) => {
    
    console.log("onSubmit violation = urlParams", urlParams);
    this.setState({ urlParams: urlParams })
  }

  handleScrollTo = (elRef) => {
    const el = elRef.current ? elRef.current : elRef;
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
  
  render() {
    const { classes } = this.props;
    let violationName = null;
    let apiUrl = null;

    if (this.props.violationType) {

      /*
       * Get the violation type from the global and create the API URL
       */
      violationName = globals.violationTypes[this.props.violationType.configValue1];
      apiUrl = "api/speedViolation?speedViolationType=" + this.props.violationType.configValue1 + "&violationStatusName=REJECTED";
    

      return (
        <Container maxWidth="xl" className={classes.root}>
            {/* Header of the Dashboard*/}
            <Typography variant="h4" gutterBottom className={classes.header}>
              Violation Rejection Dashboard
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.header}>
              {" " + violationName }
            </Typography>

            {/* Violation Rejection Filter */}
            <Box mt={3}>
              <Card>
                <CardContent>
                  <ViolationFilter 
                    onSubmit={(urlParams) => {
                      this.onSubmit(urlParams)
                    }}
                  />
                </CardContent>
              </Card>
            </Box>
            
            {/* Violation Rejection DataGrid */}
            <Box mt={3}>
              <DataGrid ref={this.grid} endpoint={apiUrl} isMasterGrid={false} downloadReport={true} urlParams={this.state.urlParams} onRowClick={(event, rowData) => {
                    console.log("I came inside Edit Click", event, rowData);
                    this.setState({ selectedRejectedRow: rowData });
                    this.handleScrollTo(this.rejectDetRef);
                }} 
              />
            </Box>

            {/* Violation Scrunity Filter */}
            <Box mt={3} ref={this.rejectDetRef}>
              <Card>
                <CardContent>
                  <RejectionDetails violationType={this.props.violationType.configValue1} selectedRejectedRow={this.state.selectedRejectedRow}/>
                </CardContent>
              </Card>
            </Box>
            
        </Container>
      );
    } else {
      return <div>Loading...</div>
    }
  }
}

const mapStateToProps = state => {
  return {
    violationType: state.common.globalConfig.violation_type
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDirections: () => dispatch(actions.getDirections()),
    getVehicleClass: () => dispatch(actions.getVehicleClass()),
    getSections: () => dispatch(actions.getSections())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
