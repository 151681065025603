import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
} from "@material-ui/core";
import DataGrid from "../../components/mastersGrid/DataGrid";
import AddIcon from "@material-ui/icons/Add";
import commanStyle from "../../commonStyles";

import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core";

import GlobalCreate from "./GlobalCreate";
import GlobalEdit from "./GlobalEdit";
import Toaster from "../../components/common/Toaster";
import { useLocation } from "react-router-dom";
import checkEntitlement from "../../utils/checkEntitlement";

const styles = (theme) => ({
  ...commanStyle(theme),
  addConfig: {
    float: "right",
  },
  header: {
    display: "inline",
    color: "#002884",
  },
  searchBox: {
    marginTop: theme.spacing(5),
    clear: "both",
  },
  modal: {
    marginLeft: "250px",
    height: "550px",
    marginTop: "20px",
    "overflow-y": "auto",
    marginRight: "200px",
  },

  sheader: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
});

const GlobalConfiguration = (props) => {
  const [urlParam, setUrlParam] = useState([]);
  const [modalCredentials, setModalOpen] = useState({
    isOpen: false,
    modalType: "",
  });
  const [configName, setConfigName] = useState("");

  const [currentSelectedRow, setRowData] = useState({});
  const { classes } = props;
  const grid = useRef();
  let location = useLocation();

  const toasterOpen = useSelector((state) => state.common.toasterOpen);

  const toasterMessageConfig = useSelector(
    (state) => state.common.toasterMessageConfig
  );

  useEffect(() => {
    if (toasterMessageConfig && toasterMessageConfig.type === "success") {
      grid.current.reload();
    }
  }, [toasterMessageConfig]);

  let modalDiv = null;

  if (modalCredentials.modalType === "Add") {
    modalDiv = (
      <Modal
        className={classes.modal}
        open={modalCredentials.isOpen}
        onClose={() => {
          setModalOpen({
            ...modalCredentials,
            isOpen: false,
            modalType: "Add",
          });
        }}
        closeAfterTransition
      >
        <GlobalCreate
          onClose={() =>
            setModalOpen({
              ...modalCredentials,
              isOpen: false,
              modalType: "",
            })
          }
        />
      </Modal>
    );
  }

  if (modalCredentials.modalType === "Edit") {
    modalDiv = (
      <Modal
        className={classes.modal}
        open={modalCredentials.isOpen}
        onClose={() => {
          setModalOpen({
            ...modalCredentials,
            isOpen: false,
            modalType: "Edit",
          });
        }}
        closeAfterTransition
      >
        <GlobalEdit
          initialValues={currentSelectedRow}
          onClose={() =>
            setModalOpen({ ...modalCredentials, isOpen: false, modalType: "" })
          }
        />
      </Modal>
    );
  }
  const onSearchSubmit = (event) => {
    event.preventDefault();
    const params = [];
    if (configName) {
      params.push({ key: "configName", value: configName });
    setUrlParam(params);
    }
  };

  const reset = () => {
    if(urlParam.length >0) {
      setUrlParam([]);
    }
    setConfigName("");
  };

  const onChangeHandler = (event) => {
    const value = event.target.value;
    console.log(event.target.value);
    console.log(" search is changing");
    setConfigName(value);
  };

  return (
    <div className={classes.etmsPageOuterDiv}>
      <Card className={classes.etmsPageOuterCard}>
        <CardContent>
          <Typography variant="h5" gutterBottom className={classes.header}>
            Global Configuration
          </Typography>

          <Button
            variant="contained"
            disabled={!checkEntitlement(location.pathname, 'add')}
            color="primary"
            startIcon={<AddIcon />}
            className={classes.addConfig}
            onClick={() => {
              setModalOpen({
                ...modalCredentials,
                isOpen: true,
                modalType: "Add",
              });
              console.log(" The button was clicked");
            }}
          >
            Add Global Config
          </Button>
          <Box mt={3} className={classes.searchBox}>
            <Card>
              <CardContent>
                <form noValidate onSubmit={onSearchSubmit} autoComplete="off">
                  <Grid container spacing={3} alignItems="center">
                    <Grid item lg={2} xs={2}>
                        {/* <Typography
                          variant="h6"
                          gutterBottom
                          className={classes.sheader}
                        >
                          Search
                        </Typography> */}
                    </Grid>
                    <Grid item lg={3} xs={2}>
                        <TextField
                          label="Config Name"
                          value={configName}
                          onChange={onChangeHandler}
                          name="configName"
                          className={classes.sheader}
                          variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={2} xs={4}>
                      <Button
                        className={`${classes.sheader}${classes.addConfig}`}
                        variant="contained"
                        color="primary"
                        startIcon={<SearchIcon />}
                        type="submit"
                        fullWidth
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                      <Button
                        
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={reset}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
          <Box mt={3}>
            <DataGrid
              downloadReport={true}
              ref={grid}
              urlParams={urlParam}
              endpoint="api/globalConfig"
              // delete res.configId
              isEditable={checkEntitlement(location.pathname, 'edit')}
              onEditClick={(event, rowData) => {
                let RowData = rowData;
                setRowData(RowData);
                setModalOpen({
                  ...modalCredentials,
                  isOpen: true,
                  modalType: "Edit",
                });
              }}
            />
          </Box>
        </CardContent>
      </Card>
      <Toaster
        open={toasterOpen}
        message={toasterMessageConfig && toasterMessageConfig.message}
        severity={toasterMessageConfig && toasterMessageConfig.type}
      />

      {modalDiv}
    </div>
  );
};

export default withStyles(styles)(GlobalConfiguration);
