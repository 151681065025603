import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    ArrParentMenu: [],
    loading: false
};

const getParentMenuSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrParentMenu: action.arrParentMenu
    });
    return updatedState;
}

const menuStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const menuCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_PARENT_MENU_SUCCESS:
            return getParentMenuSuccess(state, action);

        case actionTypes.MENU_CREATE_START:
            return menuCreateStart(state, action);

        case actionTypes.MENU_STATE_RESET:
            return menuStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;
