import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../../store/actions/index';
import * as reduxFormFunctions from '../../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../../utils/getRoute';
import checkEntitlement from "../../../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

class SOPItemAddForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sopCategoryId: 0,
        };
    }

    componentDidMount() {
        this.props.getSopCat();
    }

    onChange = event => {
        console.log("event.target.id=" + event.target.id);
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add SOP Item"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>

            </React.Fragment>
            subheaderName = "Edit SOP Item"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="SOP Item"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="sopCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select SOP Category" id="sopCategoryId" >
                                        <option value={""}>{'Select SOP Category'}</option>
                                        {this.props.ArrSopCat.map((sopCatDropDown) => <option key={sopCatDropDown.sopCategoryId} value={sopCatDropDown.sopCategoryId}>{sopCatDropDown.sopCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="sopItemDescription" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="SOP Item Description" id="sopItemDescription" />
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

//#region Validations
const validate = formValues => {
    const error = {};

    if (!formValues.sopCategoryId) {
        error.sopCategoryId = "Select SOP Category";
    }
    if (formValues.sopCategoryId) {
        if (formValues.sopCategoryId == "") {
            error.sopCategoryId = "Select SOP Category";
        }
    }
    if (!formValues.sopItemDescription) {
        error.sopItemDescription = "Enter SOP Item Description";
    }
    if (/^[0-9 ]*$/g.test(formValues.sopItemDescription)) {
        error.sopItemDescription = 'Please enter valid description';
    }
    if (formValues.sopItemDescription) {
        if (formValues.sopItemDescription.length > 201) {
            error.sopItemDescription = "Maximum characters should be 200 only";
        }
    }

    return error;
};
// //#endregion

const mapStateToProps = state => {
    return {
        ArrSopCat: state.sopItemReducer.ArrSopCat
    };
};
const mapDispatchToProps = (dispatch, state) => {
    return {
        getSopCat: () => dispatch(actions.getSopCat())
    };
};

SOPItemAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(SOPItemAddForm)

export default reduxForm({
    validate: validate,
    form: 'SOPItemAddForm'
})(withStyles(styles)(SOPItemAddForm));