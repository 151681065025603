import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, RadioGroup, Radio, FormLabel, FormControl, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import axios from 'axios';
import appConfig from '../../../../appConfig.json';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";
import FormHelperText from '@material-ui/core/FormHelperText';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    },
    imageInput: {
        // float: 'right',
        display: 'inline - block',
        padding: '6px 12px',
        cursor: 'pointer',
    }
});

class AssetVehicleDetailsAddForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            assetVehicleCategoryId: 0,
            assetVehicleDetailsId: 0,
            selectedFile: null
        };
        this.fileInput = React.createRef();
    }

    radioAssetAvailabilityStatusButton = ({ input, meta: { touched, error, invalid }, ...reset }) => {

        /* Convert value to string as we have set it to string inside FormControlLabel */
        input.value = input.value.toString();

        return (
            <FormControl fullWidth variant="outlined" error={touched && invalid} helperText={touched && error}>
                <FormLabel component="legend">Asset Vehicle Availability Status</FormLabel>
                <RadioGroup row {...input} {...reset}  >
                    <FormControlLabel value="1" control={<Radio />} label="Available" labelPlacement="end" />
                    <FormControlLabel value="2" control={<Radio />} label="Busy" labelPlacement="end" />
                    <FormControlLabel value="3" control={<Radio />} label="Maintenance" labelPlacement="end" />

                </RadioGroup>
                {(touched && error) ? <FormHelperText>{touched && error}</FormHelperText> : null}
            </FormControl>
        )
    }

    componentDidMount() {
        this.props.getAssetVehicleCat();
        console.log("initial props", this.props.initialValues);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.assetVehicleCategoryId !== this.state.assetVehicleCategoryId) {
    //         const result = this.props.ArrAssetVehicleCat.find(vehicleCategory => vehicleCategory.assetVehicleCategoryId == this.state.assetVehicleCategoryId)

    //         this.setState({
    //             assetVehicleDetailsId: result.vehicleDetails[0].assetVehicleDetailsId
    //         })
    //     }
    // }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleFileChange = event => {
        if (event.target.id == "file") {
            this.setState({
                selectedFile: event.target.files[0],
            })
        }
    }

    //onSubmit
    onSubmit = async (values) => {
        let vehicleCatState = this.state;
        if (this.state.selectedFile) {
            try {
                const data = new FormData()
                data.append('public', "false")
                data.append('uploadPath', "assetVehicleDetails")
                data.append('files', this.state.selectedFile);
                var name = this.state.selectedFile.name;
                values['assetVehicleDetailsImageName'] = name.substr(0, name.indexOf('.'));
                values['assetVehicleDetailsImageExtension'] = name.substr(name.indexOf('.') + 1, name.length - name.indexOf('.'));
                values['assetVehicleDetailsImagePath'] = '/assetVehicleDetails';

                const uploadFileResponse = await axios.post(BASE_URL + "/api/uploads", data);

                console.log("Form Values: ", values)
                console.log("State: ", vehicleCatState)
                this.props.onSubmit(values, vehicleCatState);
            }
            catch (err) {
                console.log("Upload file error " + err)
            }
        } else {
            console.log("Form Values: ", values)
            console.log("State: ", vehicleCatState)
            this.props.onSubmit(values, vehicleCatState);
        }
    }

    resetForm = () => {
        this.props.reset();
        this.setState({
            selectedFile: null,
            file: null
        });
        this.fileInput.value = null;
    }

    render() {
        let { classes, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;
        let VehicleImage = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={this.resetForm}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add New Asset Vehicle Details"
        }

        if (this.props.type === "Edit") {
            if (this.state.selectedFile) {
                pristine = false;
            }
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            VehicleImage =
                <React.Fragment>
                    {this.props.uploaded ?
                        <React.Fragment>
                            <p>Previous Image :</p>
                            <img src={this.props.uploaded} width="100" height="100" alt="img" />
                        </React.Fragment>
                        : "Upload an Image: "
                    }
                </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>

            </React.Fragment>

            subheaderName = "Edit Asset Vehicle Details"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Asset Vehicle Details"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Asset Vehicle Category" id="assetVehicleCategoryId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrAssetVehicleCat.map((assetVehicleCatDropDown) => <option key={assetVehicleCatDropDown.assetVehicleCategoryId} value={assetVehicleCatDropDown.assetVehicleCategoryId}>{assetVehicleCatDropDown.assetVehicleCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsDescription" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Description" id="assetVehicleDetailsDescription" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Name" id="assetVehicleDetailsName" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsBaseLocation" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Base Location" id="assetVehicleDetailsBaseLocation" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsClass" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Class" id="assetVehicleDetailsClass" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsNumber" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Number" id="assetVehicleDetailsNumber" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsColor" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Color" id="assetVehicleDetailsColor" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsModel" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Make/Model" id="assetVehicleDetailsModel" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsMobileImeiNumber" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Mobile IMEI Number" id="assetVehicleDetailsMobileImeiNumber" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsContactNumber" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Contact Number" id="assetVehicleDetailsContactNumber" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="vehicleAppRegId" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Asset Vehicle Mobile App Registration ID" id="vehicleAppRegId" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="assetVehicleDetailsAvailabilityStatus" component={this.radioAssetAvailabilityStatusButton} onChange={this.onChange} label="Asset Vehicle Availability Status" id="assetVehicleDetailsAvailabilityStatus" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    {VehicleImage}
                                    <input type="file" name="file" id="file" ref={(ref) => { this.fileInput = ref }} label="Upload file" onChange={this.handleFileChange} className={classes.imageInput} />
                                </Grid>
                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (!formValues.assetVehicleCategoryId) {
        error.assetVehicleCategoryId = "Select Asset Vehicle Category"
    }
    if (formValues.assetVehicleCategoryId) {
        if (formValues.assetVehicleCategoryId == "") {
            error.assetVehicleCategoryId = "Select Asset Vehicle Category"
        }
    }
    if (!formValues.assetVehicleDetailsDescription) {
        error.assetVehicleDetailsDescription = "Enter Asset Vehicle Details";
    }
    if (formValues.assetVehicleDetailsDescription) {
        if (formValues.assetVehicleDetailsDescription.length > 201) {
            error.assetVehicleDetailsDescription = "Maximum characters should be 200 only";
        }
    }
    if (!formValues.assetVehicleDetailsName) {
        error.assetVehicleDetailsName = "Enter Asset Vehicle Name";
    }
    if (!formValues.assetVehicleDetailsBaseLocation) {
        error.assetVehicleDetailsBaseLocation = "Enter Asset Vehicle Base Location";
    }
    if (!formValues.assetVehicleDetailsClass) {
        error.assetVehicleDetailsClass = "Enter Asset Vehicle Class";
    }
    if (!formValues.assetVehicleDetailsNumber) {
        error.assetVehicleDetailsNumber = "Enter Asset Vehicle Number";
    }
    if (!formValues.assetVehicleDetailsColor) {
        error.assetVehicleDetailsColor = "Enter Asset Vehicle Color";
    }
    if (!formValues.assetVehicleDetailsModel) {
        error.assetVehicleDetailsModel = "Enter Asset Vehicle Make/Model";
    }
    if (!formValues.assetVehicleDetailsContactNumber) {
        error.assetVehicleDetailsContactNumber = "Enter Asset Vehicle Contact Number";
    }
    if (formValues.assetVehicleDetailsContactNumber) {
        if (!re.test(formValues.assetVehicleDetailsContactNumber)) {
            error.assetVehicleDetailsContactNumber = "Enter Valid Contact Number";
        }
    }
    if (!formValues.assetVehicleDetailsAvailabilityStatus) {
        error.assetVehicleDetailsAvailabilityStatus = " Please Select Availability Status "
    }

    if (formValues.vehicleAppRegId) {
        if (!/^[0-9]*$/.test(formValues.vehicleAppRegId)) {
            error.vehicleAppRegId = " Enter only numeric values"
        }
    }

    return error;
};

const mapStateToProps = state => {
    return {
        ArrAssetVehicleCat: state.assetVehicleDetailsReducer.ArrAssetVehicleCat
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        getAssetVehicleCat: () => dispatch(actions.getAssetVehicleCat())
    };
};

AssetVehicleDetailsAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetVehicleDetailsAddForm)


export default reduxForm({
    validate: validate,
    form: 'AssetVehicleDetailsAddForm'
})(withStyles(styles)(AssetVehicleDetailsAddForm))
