import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    ArrAssetVehicleCat: [],
    loading: null
};

const getAssetVehicleCatSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrAssetVehicleCat: action.arrAssetVehicleCat
    });
    return updatedState;
}

const assetVehicleDetailsStateReset = (state,action) => {
    return updateObject(state, {loading: null });
};

const assetVehicleDetailsCreateStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const reducer = (state= initialState,action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_VEHICLE_CAT_SUCCESS:
            return getAssetVehicleCatSuccess(state,action)
        
        case actionTypes.ASSET_VEHICLE_DETAILS_CREATE_START :
            return assetVehicleDetailsCreateStart(state,action);
        
        case actionTypes.ASSET_VEHICLE_DETAILS_STATE_RESET :
            return assetVehicleDetailsStateReset(state,action);

        default:
            return state;
    }
}

export default reducer;