import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const stakeholderCatSuccess = (stakeholderCat) => {
    return {
        type: actionTypes.STAKEHOLDER_CAT_SUCCESS,
        stakeholderCat: stakeholderCat
    };
};

export const fetchStakeholderCatData = (endpoint) => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/stakeholdersCategories")
            .then(res => {
                dispatch(stakeholderCatSuccess(
                    res.data                   
                ));
                console.log(res.data)
            }).catch(error => {
                console.log('error occured' + error);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
};

export const stakeholderCatCreateStart = () => {
    return {
        type: actionTypes.STAKEHOLDER_CAT_CREATE_START
    };
};

export const stakeholderCatCreate = (formValues) => {
    return async (dispatch) => {
        try{
            dispatch(stakeholderCatCreateStart());
            const response = await axios.post(BASE_URL + "/api/stakeholdersCategories", formValues);
            console.log("Created")
            dispatch(showToaster('success','Stakeholder Category Successfully Created.'))
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const stakeholderCatStateReset = () => {
    return {
        type: actionTypes.STAKEHOLDER_CAT_STATE_RESET
    };
};

export const stakeholderCatEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        stakeholdersCategoryName : formValues.stakeholdersCategoryName,
        stakeholdersCategoryDescription: formValues.stakeholdersCategoryDescription,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(stakeholderCatCreateStart());
            const response = await axios.patch(BASE_URL + "/api/stakeholdersCategories/" + formValues.stakeholdersCategoryId,values);
            console.log(response);
             dispatch(showToaster('success','Stakeholder Category Successfully Edited.'));

        }catch(error) {
            console.log(error)
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}