import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, Divider, CardContent, Grid, Typography, Paper, Box, Container, Button, TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, InputLabel, Input, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { connect } from "react-redux";
import { DataGrid } from '@material-ui/data-grid';
import * as actions from '../../store/actions/index';
import axios from 'axios';
import _ from 'lodash';
import { autofill } from 'redux-form';
import MaterialTable from "material-table";
import Toaster from '../../components/common/Toaster';
import appConfig from '../../appConfig.json';
import FormHelperText from '@material-ui/core/FormHelperText';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  outerDiv: {
    'margin-top': theme.spacing(1),
    'margin-left': theme.spacing(3),
    'margin-right': theme.spacing(3),
    flexGrow: 1,
  },
  notificationDiv: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
  },
  notificationGrid: {
    marginTop: theme.spacing(2),
  },
  buttonDiv: {
    marginTop: theme.spacing(5),
  },
  gridTable: {
    'min-height': 450,
    width: '100%',
    'padding-top': '0px !important',
    'overflow-y': 'auto',
    // border: '1px solid black'
  },
  textArea: {
    display: 'block'
  },
  actionButton: {
    margin: '10px'
  },
  resetButton: {
    margin: '20px 0px',
    backgroundColor: '#ff4500',
    color: 'white',
  },
  contactDetails: {
    marginBottom: theme.spacing(2)
  },
  manualNotificationPaper: {
    padding: theme.spacing(2)
  },
  errMsg:{
    color: 'red',
    margin: '0'
  }
});

const vmsColumns = [
  { field: 'vmsId', title: 'VMS Id', width: 100 },
  { field: 'message', title: 'Proposed Message', width: 450 },
];

const notificationModesColumns = [
  { field: 'mode', title: 'Mode', width: 100 },
  { field: 'message', title: 'Proposed Message', width: 450 },
];

const highwayAidColumns = [
  { field: 'assetCategory.assetVehicleCategoryName', title: 'Category', width: 150 },
  {
    field: 'vehicleDetails', title: 'Vehicle Details', width: 400, render: (params) => {
      // console.log('params: ', params);
      return (
        <div>{params.assetVehicleDetailsNumber} |  {params.assetVehicleDetailsContactNumber}</div>
      )
    }
  }
];

class quickEvent extends React.Component {

  getNotificationCategoryId(notificationCategoryName, notificationCategories) {
    //console.log('getting notification category by name: ', notificationCategoryName)
    if (notificationCategories != null) {
      var notificationCategory = notificationCategories.find((nCategory) => nCategory.notificationCategoryName == notificationCategoryName);
      if (notificationCategory != null) {
        //console.log('notificationCategory.notificationCategoryId: ', notificationCategory.notificationCategoryId);
        return notificationCategory.notificationCategoryId;
      }
    }
  }
  constructor() {
    super();
    this.state = {
      notificationCategories: [],
      messageTemplates: [],
      notificationParams: [],
      selectedVmsAlerts: [],
      selectedNotificationAlerts: [],
      selectedVehicleAid: [],
      contactDetail: null,
      errorMsg:  null,
      isError : false
    };
    this.highwayAidTableRef = React.createRef();
    this.notificationModesTableRef = React.createRef();
    this.vmsTableRef = React.createRef();
    // this.mobileNumbers = React.createRef();
  }

  resetManualAlertDetails() {
    this.setState({
      messageTemplates: [],
      messageTemplateId: '',
      messageTemplate: '',
      manualAlertMessage: '',
      notificationCategoryId: '',
      contactDetail: "",
      notificationParams: this.state.notificationParams.map(x => { 
        x.value = '';
        return x;
      })
    })
  }

  componentDidUpdate() {
    if (this.props.alertSentIndicator != null) {
      this.props.fetchNotificationData({ smsCategoryId: this.state.smsCategoryId, webAlertCategoryId: this.state.webAlertCategoryId,emailAlertCategoryId:this.state.emailAlertCategoryId, eventId: this.props.selectedEvent.eventData.txnEventId });
      this.props.fetchVMSData({ eventNotificationCategoryId: this.state.vmsCategoryId, eventId: this.props.selectedEvent.eventData.txnEventId });
      this.props.getAvailableVehicleAids();
      this.props.resetSelectedAlertsIndicator();
    }
  }

  changeNotificationCategory(event) {
    let notificationCategoryId = event.target.value;
    axios
      .get(BASE_URL + "/api/notificationTemplates?reqType=all&notificationCategoryId=" + notificationCategoryId)
      .then(res => {
        console.log(res.data);
        this.setState({ messageTemplates: res.data, notificationCategoryId: notificationCategoryId });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
      this.setState({
        messageTemplateId: '',
        messageTemplate: '',
        manualAlertMessage: '',
        notificationParams: this.state.notificationParams.map(x => { 
          x.value = '';
          return x;
        })
      })
  }
  substituteParams(messageTemplate, notificationParams) {
    if (messageTemplate) {
      if (notificationParams == null) {
        notificationParams = this.state.notificationParams;
      }
      notificationParams.forEach((param) => {
        if (param.value != null && param.value != '') {
          messageTemplate = messageTemplate.replace(param.notificationParamsName, param.value);
        }
      });
      return messageTemplate;
    }
  }

  changeMessageTemplate(event) {
    let messageTemplateId = event.target.value;
    let messageTemplate = '';
    let message = '';
    let template = this.state.messageTemplates.find((messageTemplate) => messageTemplate.notificationTemplateId == messageTemplateId);
    // let messageTemplate = event.target.value;
    if (template) {
      messageTemplate = template.notificationTemplateMessage;
      message = this.substituteParams(messageTemplate);
    }
    this.setState({ messageTemplateId: messageTemplateId, messageTemplate: messageTemplate, manualAlertMessage: message });
  }

  setParamValue(event, index) {
    let notificationParams = [...this.state.notificationParams];
    // let param = {...params[index]};
    // param.value = event.target.value;
    let param = { ...notificationParams[index], value: event.target.value };
    notificationParams[index] = param;
    let message = this.substituteParams(this.state.messageTemplate, notificationParams);
    this.setState({ notificationParams: notificationParams, manualAlertMessage: message });
  }


  componentDidMount() {
    axios
      .get(BASE_URL + "/api/notificationParams?reqType=all")
      .then(res => {
        console.log(res.data);
        this.setState({
          notificationParams: res.data
        });
      }).catch(error => {
        console.log(error);
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
    axios
      .get(BASE_URL + "/api/notificationCategories?reqType=all")
      .then(res => {
        //console.log(res.data);
        let vmsCategoryId = this.getNotificationCategoryId('VMS', res.data);
        let smsCategoryId = this.getNotificationCategoryId('SMS', res.data);
        let webAlertCategoryId = this.getNotificationCategoryId('WEB ALERT', res.data);
        let emailAlertCategoryId = this.getNotificationCategoryId('EMAIL', res.data);

        this.setState({
          vmsCategoryId: vmsCategoryId,
          smsCategoryId: smsCategoryId,
          webAlertCategoryId: webAlertCategoryId,
          emailAlertCategoryId: emailAlertCategoryId,
          notificationCategories: res.data
        });
        this.props.fetchNotificationData({ smsCategoryId: smsCategoryId, webAlertCategoryId: webAlertCategoryId, emailAlertCategoryId:emailAlertCategoryId, eventId: this.props.selectedEvent.eventData.txnEventId });
        this.props.fetchVMSData({ eventNotificationCategoryId: vmsCategoryId, eventId: this.props.selectedEvent.eventData.txnEventId });
        this.props.getAvailableVehicleAids();
        // this.setState({notificationCategories:res.data});
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  }

  handleContactDetailChange = (e) => {
    this.setState({ contactDetail: e.target.value });
  }


  sendNotifications() {

    // let vmsAlertsIds = this.state.selectedVmsAlerts.map(alert=>alert.txnEventAlertId);
    // let notifAlertsIds = this.state.selectedNotificationAlerts.map(alert=>alert.txnEventAlertId);
    // let allSelectedAlertIds = vmsAlertsIds.concat(notifAlertsIds);
    let selectedVmsAlerts = _.filter(this.vmsTableRef.current.state.data, ((row) => row.tableData.checked == true && row.eventNotificationStatus != 1));
    let selectedNotificationAlerts = _.filter(this.notificationModesTableRef.current.state.data, ((row) => row.tableData.checked == true && row.eventNotificationStatus != 1));
    let vmsAlertsIds = selectedVmsAlerts.map(alert => alert.txnEventAlertId);
    let notifAlertsIds = selectedNotificationAlerts.map(alert => alert.txnEventAlertId);

    let allSelectedAlertIds = vmsAlertsIds.concat(notifAlertsIds);
    // let selectedVehicles = this.props.availableVehicles.filter((vehicle) => {
    //   return this.state.selectedVehicleAid.indexOf(vehicle.assetVehicleDetailsId.toString()) != -1;
    // });
    let selectedVehicles = _.filter(this.highwayAidTableRef.current.state.data, ((row) => row.tableData.checked == true));
    let payload = {};
    if (selectedVehicles.length > 0 || allSelectedAlertIds.length > 0) {
      payload.eventId = this.props.selectedEvent.eventData.txnEventId;
      payload.alertIds = allSelectedAlertIds;
      payload.selectedVehicles = selectedVehicles;
    }
    if (this.state.manualAlertMessage != null && this.state.manualAlertMessage != '') {
      payload.manualAlert = {
        eventId: this.props.selectedEvent.eventData.txnEventId,
        eventDescription: this.props.selectedEvent.eventData.eventDescription,
        eventNotificationCategoryId: this.state.notificationCategoryId,
        eventNotificationTemplateId: this.state.messageTemplateId,
        eventNotificationMessage: this.state.manualAlertMessage,
        contactDetail: this.state.contactDetail,
        eventAlertSource: 0
        // eventNotificationStatus: 1,
        //aidCategoryId: this.state.aidCategoryId,
        //aidId: this.state.aidId,
        //manualAlertRemarks: this.state.remarks
      };
    }
    console.log('PAYLOAAAD',payload)
    this.props.sendSelectedAlertsAndAssignVehicles(payload);
    this.resetManualAlertDetails();

  }

  sendNotificationsToVMS = () => {
    // Prepare the payload for the request
    const vmsMessageLinesData =[{
      lineMessage: this.props.vmsAlerts.message,
      font: '25',
      fontColor: 'Orange',
    }
  ];
    const requestData = {
      eqpId:this.props.vmsAlerts[0].vmsId ,
      numberOfLines: this.props.vmsAlerts.length,
      isReusable: 0, // 0 for reusable, 1 for not reusable
      msgtypeId: 2,
      leftImageNo: 2,
      leftImageX: 20,
      leftImageY: 25,
      messageLocationX: 30,
      messageLocationY: 35,
      rightImageNo: 2,
      rightImageX: 20,
      rightImageY: 22,
      vmsMessageLinesData: vmsMessageLinesData,
    };

    axios.post(BASE_URL + '/api/vms', requestData)
      .then(response => {
        // Handle a successful response here
        // You can display a success message or perform other actions
        console.log('Notifications sent successfully:', response.data);
      })
      .catch(error => {
        // Handle errors that may occur during the request
        console.error('Error sending notifications:', error);
        // You can display an error message or take appropriate actions
      });
  }














  render() {
    const { classes } = this.props;
    let eventData = this.props.selectedEvent.eventData;
    let errorMsg = ''
    // console.log('eventData',this.props.selectedEvent.eventData);
    console.log('State notificationParams',this.props.notificationAlerts)
    console.log('availableVehicles',this.props.availableVehicles)
    console.log("props for vmssssssss>>",this.props.vmsAlerts)

    return (
      <React.Fragment>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        {/* Outer most DIV*/}
        <div className={classes.outerDiv}>
          <Box>
            <Typography variant="h5" gutterBottom className={classes.header}>
              Quick  Event Management
            </Typography>
          </Box>
          <Card>
            {/*First section is Event Details*/}
            <CardHeader title="Event Details" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event ID
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.txnEventId}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Type
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    
                    {eventData.eventType.eventTypeName}

                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Sub Type
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventSubtype.eventSubtypeName}

                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Severity
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {(eventData.eventSeverity !== null) ? eventData.eventSeverity.severityName : ''}

                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Detection Date time
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(eventData.detectionTime))}

                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Equipment Source
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                  {(eventData.equipmentType !== null) ? eventData.equipmentType.equipmentTypeName :''}
                    {/* {eventData.equipmentType.equipmentTypeName} */}

                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Description
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventDescription}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Direction
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.directionName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Chainage
                                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.chainageName}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div className={classes.notificationDiv}>
            <Grid direction="row" justify="space-evenly" container spacing={8} className={classes.notificationGrid}>
              <Grid item xs={6} className={classes.gridTable}>
                <Typography variant="h6" gutterBottom>
                  Notification to VMS
                </Typography>

                <MaterialTable
                  title="NOTIFICATION TO THE VMS"
                  columns={vmsColumns}
                  tableRef={this.vmsTableRef}
                  data={this.props.vmsAlerts ? this.props.vmsAlerts : []}
                  options={{
                    selection: true,
                    sorting: false,
                    // selectionProps: rowData => ({
                    //   disabled: rowData.eventNotificationStatus == 1,
                    //   // color: 'primary',
                    //   // checked: this.state.selectedVmsAlerts.indexOf(rowData.txnEventAlertId)!= -1

                    // }),
                    paging: false,
                    search: false,
                    showTextRowsSelected: false
                  }}

                />
              </Grid>
              <Grid item xs={6} className={classes.gridTable}>
                <Typography variant="h6" gutterBottom>
                  Notification Modes
                </Typography>

                <MaterialTable
                  title="NOTIFICATION MODES"
                  columns={notificationModesColumns}
                  data={this.props.notificationAlerts ? this.props.notificationAlerts : []}
                  tableRef={this.notificationModesTableRef}
                  options={{
                    selection: true,
                    sorting: false,
                    // selectionProps: rowData => ({
                    //   disabled: rowData.eventNotificationStatus == 1,
                    //   // color: 'primary',
                    //   // checked: this.state.selectedNotificationAlerts.indexOf(rowData.txnEventAlertId)!= -1
                    // }),
                    paging: false,
                    search: false,
                    showTextRowsSelected: false
                  }}

                />
              </Grid>
            </Grid>
            <Grid direction="row" justify="space-evenly" container spacing={8} className={classes.notificationGrid}>
              <Grid item xs={6} className={classes.gridTable}>
                <Typography variant="h6" gutterBottom>
                  Highway Aid
                </Typography>

                <MaterialTable
                  title="HIGHWAY AID"
                  columns={highwayAidColumns}
                  data={this.props.availableVehicles ? this.props.availableVehicles : []}
                  tableRef={this.highwayAidTableRef}
                  options={{
                    selection: true,
                    sorting: false,
                    // selectionProps: rowData => ({
                    //   disabled: rowData.availability.vehicleStatusName != 'AVAILABLE',
                    //   // color: 'primary',
                    // }),
                  
                    paging: false,
                    search: false,
                    showTextRowsSelected: false
                  }}

                />
              </Grid>
              <Grid item xs={6} className={classes.gridTable}>
                <Typography variant="h6" gutterBottom>
                  Manual Notification
                </Typography>
                <Paper className={classes.manualNotificationPaper}>
                  <React.Fragment>
                    <InputLabel>Contact Details</InputLabel>
                    <Input fullWidth={true} value={this.state.contactDetail} placeholder="Comma separated mobile numbers or email addresses"
                      onChange={this.handleContactDetailChange} className={classes.contactDetails}
                    />
                  </React.Fragment>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <React.Fragment>
                        <InputLabel>Notification Category</InputLabel>
                        <FormControl fullWidth variant="outlined">
                          <Select native value={this.state.notificationCategoryId} onChange={(event) => this.changeNotificationCategory(event)}>
                            <option value="" />
                            {this.state.notificationCategories.map((notificationCategory) => {
                              if (notificationCategory.notificationCategoryName == 'SMS' || notificationCategory.notificationCategoryName == 'EMAIL')
                                return (<option value={notificationCategory.notificationCategoryId}>{notificationCategory.notificationCategoryName}</option>);
                            })}
                          </Select>
                          
                        </FormControl>
                      </React.Fragment>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <React.Fragment>
                        <InputLabel>Message Template</InputLabel><FormControl fullWidth variant="outlined">
                          <Select native value={this.state.messageTemplateId} onChange={(event) => this.changeMessageTemplate(event)} onClick={()=>{
                            if(this.state.messageTemplates.length === 0 ){
                              
                              errorMsg = 'Please select notification category'
                              this.setState({
                                errorMsg:  errorMsg,
                                isError : true
                              });
                              console.log('Please select')
                            }else{
                              this.setState({
                                errorMsg:  null,
                                isError : false
                              });
                            }

                            this.setState({
                              notificationParams: this.state.notificationParams.map(x => { 
                                x.value = '';
                                return x;
                              })
                            })
                            
                          }}>
                            <option value="" />
                            {this.state.messageTemplates.map((messageTemplate) => {
                              return (<option value={messageTemplate.notificationTemplateId}>{messageTemplate.notificationTemplateName}</option>);
                            })}
                          </Select>
                          {(this.state.isError === true) ?<p className={classes.errMsg}>{this.state.errorMsg}</p> : ''}
                        </FormControl>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} >
                      <React.Fragment>
                        <InputLabel>Selected Template Message Preview</InputLabel>
                        <FormControl variant="outlined" className={classes.textArea}>
                          <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            rows={4}
                            disabled={true}
                            value={this.state.manualAlertMessage}
                          /></FormControl>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                  <Typography variant="h6" gutterBottom>Param details</Typography>
                  <Grid container spacing={3}>
                  
                    {(this.state.messageTemplates.length !== 0)?
                      this.state.notificationParams.map((param, index) => {
                        return (
                          <Grid item md={4} xs={12} className={classes.gridItem}><FormControl variant="outlined">
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              value = {this.state.notificationParams[index].value}
                              label={param.notificationParamsName}
                              onChange={(event) => this.setParamValue(event, index)}
                            />
                          </FormControl>
                          </Grid>
                        )
                      }): ''
                    }
                  </Grid>

                  <Button variant="contained" size="medium" className={classes.resetButton} onClick={() => this.resetManualAlertDetails()}>
                    Reset Details
                </Button>
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div className={classes.buttonDiv}>
            <Divider />
            <Card>
              <Divider />
              <CardContent>
                <Grid direction="row" justify="flex-end" container spacing={1} >
                  <Button variant="contained" size="large" color="secondary" className={classes.actionButton} onClick={() => {
                    this.props.history.push("/manageevent");
                  }}>
                    Cancel
                  </Button>
                  <Button variant="contained" size="large" color="primary" className={classes.actionButton} onClick={() => {
                    this.props.history.push("/eventflow");
                  }}>
                    Manage via Advanced Option
                  </Button>
                  <Button variant="contained" size="large" color="primary" className={classes.actionButton} onClick={() => {
                    this.sendNotifications(); this.sendNotificationsToVMS() ;
                  }}>
                    Notify
                  </Button>
                </Grid>
              </CardContent>
            </Card>
            <Divider />
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    selectedEvent: state.selectedEvent,
    vmsAlerts: state.eventAlerts.vmsAlerts == null ? null : state.eventAlerts.vmsAlerts.filter((notificationAlert) => notificationAlert.eventNotificationStatus != 1),
    // smsAlert: state.eventAlerts.smsAlert,
    // webAlert: state.eventAlerts.webAlert,
    notificationAlerts: state.eventAlerts.notificationAlerts == null ? null : state.eventAlerts.notificationAlerts.filter((notificationAlert) => notificationAlert.eventNotificationStatus != 1),
    availableVehicles: state.eventAlerts.availableVehicles,
    alertSentIndicator: state.eventAlerts.alertSentIndicator,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNotificationData: (url) => dispatch(actions.fetchNotificationData(url)),
    fetchVMSData: (url) => dispatch(actions.fetchVMSAlerts(url)),
    getAvailableVehicleAids: (url) => dispatch(actions.getAvailableVehicleAids(url)),
    // sendVMSMessage: (id) => dispatch(actions.sendVMSMessage(id)),
    sendSelectedAlertsAndAssignVehicles: (params) => dispatch(actions.sendSelectedAlertsAndAssignVehicles(params)),
    resetSelectedAlertsIndicator: () => dispatch(actions.resetSelectedAlertsIndicator())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(quickEvent));