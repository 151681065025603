import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const assetVehicleCatCreateStart = () => {
    return {
        type: actionTypes.ASSET_VEHICLE_CAT_CREATE_START
    };
};

export const assetVehicleCatCreate = (formValues) => {
    console.log("Add: " + JSON.stringify(formValues));
    return async (dispatch) => {
        try {
            dispatch(assetVehicleCatCreateStart());
            const response = await axios.post("http://localhost:3030/api/assetVehicleCategories", formValues);
            dispatch(showToaster('success', 'Asset Vehicle Category Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error', error.response.data.message));
        }
    };
};

export const assetVehicleCatStateReset = () => {
    return {
        type: actionTypes.ASSET_VEHICLE_CAT_STATE_RESET
    };
};

export const assetVehicleCatEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    
    let values = {
        assetVehicleCategoryName: formValues.assetVehicleCategoryName,
        assetVehicleCategoryDescription: formValues.assetVehicleCategoryDescription,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(assetVehicleCatCreateStart());
            const response = await axios.patch("http://localhost:3030/api/assetVehicleCategories/" + formValues.assetVehicleCategoryId, values);
            dispatch(showToaster('success', 'Asset Vehicle Category Successfully Edited.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message));
        }
    }
}
