import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import Divider from '@material-ui/Divider';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2,
  },
  activeMenu: {
    color: 'blue',
    fontWeight: 'bold'
  }
});

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

class NestedMenu extends React.Component {
  state = {};
  toggle = (e) => {
    this.setState({ [e]: !this.state[e] });
  };

  handleClick = (route) => {
    if (route != null) {
      this.props.history.push(route);
    }
  }
  getNestedMenu = (node) => {
    const { classes } = this.props;
    if (!node.children || node.children.length == 0) return (
      <ListItem button >
        <ListItemText className={this.props.location.pathname === node.menuRoute?classes.activeMenu:''} primary={titleCase(node.menuName)} onClick={this.handleClick.bind(this, node.menuRoute)} />
      </ListItem>
    )
    return (
      <div>
        <ListItem button onClick={this.toggle.bind(this, node.menuName)}>
          <ListItemText primary={titleCase(node.menuName)} />
          {this.state[node.menuName] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state[node.menuName]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.nested}>
            {node.children.map(c => (this.getNestedMenu(c)))}
          </List>
        </Collapse></div>

    );
  }
  componentDidMount() {
    this.props.fetchData('api/roleEntitlements?structure=menu');
  }
  render() {
    const { classes } = this.props;
    //const items = getItems();
    return (
      <div className="nestedMenu" style={{height: `calc(100vh - 65px)`, overflow:'auto'}}>
        <List className={classes.root}>
          {this.props.menuItems.map(item => {
            return (
              <div>
                {this.getNestedMenu(item)}
                <Divider/>
              </div>
            )
          })}
        </List>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menuItems: state.menu.menuItems
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchData: (endpoint) => dispatch(actions.fetchMenu(endpoint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(NestedMenu)));
