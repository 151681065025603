import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
    Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import axios from 'axios';
import appConfig from '../../../../appConfig.json';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

const BASE_URL = appConfig.BASE_URL;

//#region Styles
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    },
    imageInput: {
        display: 'inline - block',
        padding: '6px 12px',
        cursor: 'pointer',
    }
});
//#endregion

class LocationDetailsForm extends React.Component {
    constructor() {
        super();
        this.state = {
            locationCategoryId: 0,
            uploadedFileName: '',
            uploadedFileExtension: '',
            directionId: 0,
            directionName: '',
            chainageId: 0,
            chainageName: '',
            selectedFile: null,

        };
        this.fileInput = React.createRef();
    }
    componentDidMount() {
        if (this.props.initialValues) {
            console.log("Initial values: ", this.props.initialValues);
            if (this.props.initialValues.locationDetailsImageName && this.props.initialValues.locationDetailsImageExtension) {
                this.setState({
                    uploadedFileName: this.props.initialValues.locationDetailsImageName,
                    uploadedFileExtension: this.props.initialValues.locationDetailsImageExtension,
                })
            }
            this.setState({
                locationCategoryId: this.props.initialValues.locationCategoryId,
                directionId: this.props.initialValues.directionId,
                directionName: this.props.initialValues.directionName
            })
        }
        this.props.getLocationDetailsCat();
        this.props.getLocationDetailsDir();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.locationCategoryId !== prevState.locationCategoryId) {
            this.props.getLocationDetailsType(this.state.locationCategoryId);
        }
        if (this.state.directionId !== prevState.directionId) {
            this.props.getLocationDetailsChainage(this.state.directionId);
        }
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleFileChange = event => {
        if (event.target.id == "file") {
            this.setState({
                selectedFile: event.target.files[0],
            })
        }
        console.log("file from onChange", this.state.selectedFile);
    }

    //#region Form Submit
    onSubmit = async (values) => {
        console.log("onSubmit values: ", values)
        if (this.state.selectedFile) {
            try {
                const data = new FormData()
                data.append('public', "false")
                data.append('uploadPath', "location")
                data.append('files', this.state.selectedFile);
                var name = this.state.selectedFile.name;
                values['locationDetailsImageName'] = name.substr(0, name.indexOf('.'));
                values['locationDetailsImageExtension'] = name.substr(name.indexOf('.') + 1, name.length - name.indexOf('.'));
                console.log("File Name: " + this.state.selectedFile.name);
                values['locationDetailsImagePath'] = 'uploads/location';
                await axios.post(BASE_URL + "/api/uploads", data, {
                }).then(res => {
                    console.log(res.statusText)
                })
                this.props.onSubmit(values);
            }
            catch (err) {
                console.log("Upload file error: " + err)
            }
        } else {
            this.props.onSubmit(values);
        }

    }
    //#endregion

    resetForm = () => {
        this.props.reset();
        this.setState({
            selectedFile: null,
            file: null
        });
        this.fileInput.value = null;
    }

    //#region render()
    render() {
        let { classes, pristine, invalid } = this.props;

        let buttons = null;
        let isEnabled = null;
        let locationDetailImage = null

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={this.resetForm}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
        }

        if (this.props.type === "Edit") {
            if (this.state.selectedFile) {
                pristine = false;
            }
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            locationDetailImage =
                <React.Fragment>
                    {this.props.uploaded ?
                        <React.Fragment>
                            <p>Previous Image :</p>
                            <img src={this.props.uploaded} width="100" height="100" alt="img" />
                        </React.Fragment>
                        : "Upload an Image: "
                    }
                </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <Card>
                        <CardHeader
                            subheader={this.props.type + " Location Details"}
                            title="Location Details"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Category" id="locationCategoryId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrLocationCatProps.map((userRoleProp) => <option key={userRoleProp.locationCategoryId} value={userRoleProp.locationCategoryId}>{userRoleProp.locationCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Type" id="locationTypeId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrLocationTypeProps.map((arrLocationType) => <option key={arrLocationType.locationTypeId} value={arrLocationType.locationTypeId}>{arrLocationType.locationTypeName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Direction" id="directionId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrLocationDirProps.map((arrLocationDir) => <option key={arrLocationDir.directionId} value={arrLocationDir.directionId}>{arrLocationDir.directionName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Chainage" id="chainageId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrLocationChainageProps.map((arrLocationChainage) => <option key={arrLocationChainage.chainageId} value={arrLocationChainage.chainageId}>{arrLocationChainage.chainageName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    {locationDetailImage}
                                    <input type="file" name="file" id="file" ref={(ref) => { this.fileInput = ref }} label="Upload file" onChange={this.handleFileChange} className={classes.imageInput} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationDetailsName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Name" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationDetailsDescription" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Description" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsAddress" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Address" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationDetailsLongitude" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Logitude" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locationDetailsLatitude" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Latitude" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsIpAddress" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="IP Address" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsLandlineNumber" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Landline Number" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsPrimaryMobile" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Primary Mobile Number" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsSecondaryMobile" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Secondary Mobile Number" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsPrimaryEmail" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Primary Email Id" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="locaionDetailsSecondaryEmail" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Secondary Email Id" />
                                </Grid>
                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
    //#endregion
}

//#region Validations
const validate = formValues => {
    const errors = {};

    if (!formValues.locationCategoryId || formValues.locationCategoryId == 0) {
        errors.locationCategoryId = 'Select Location Category';
    }

    if (!formValues.locationTypeId || formValues.locationTypeId == 0) {
        errors.locationTypeId = 'Select Location Type';
    }

    if (!formValues.directionId || formValues.directionId == 0) {
        errors.directionId = 'Select Direction';
    }

    if (!formValues.chainageId || formValues.chainageId == 0) {
        errors.chainageId = 'Select Chainage';
    }

    if (!formValues.locationDetailsName) {
        errors.locationDetailsName = 'Enter Name';
    } else if (/^[0-9 ]*$/g.test(formValues.locationDetailsName)) {
        errors.locationDetailsName = 'Name should only have alphanumeric, underscores and spaces';
    }
    if (!formValues.locationDetailsDescription) {
        errors.locationDetailsDescription = 'Enter Description';
    } else if (/^[0-9 ]*$/g.test(formValues.locationDetailsDescription)) {
        errors.locationDetailsDescription = 'Description should only have alphanumeric, underscores and spaces';
    }
    if (formValues.locationDetailsDescription) {
        if (formValues.locationDetailsDescription.length > 200) {
            errors.locationDetailsDescription = "Maximum characters should be 200 only";
        }
    }
    if (!formValues.locaionDetailsAddress) {
        errors.locaionDetailsAddress = 'Enter Address';
    }
    if (!formValues.locationDetailsLongitude) {
        errors.locationDetailsLongitude = 'Enter Logitude';
    } else if (!/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(formValues.locationDetailsLongitude)) {
        errors.locationDetailsLongitude = 'Enter correct format';
    }
    if (!formValues.locationDetailsLatitude) {
        errors.locationDetailsLatitude = 'Enter Latitude';
    } else if (!/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(formValues.locationDetailsLatitude)) {
        errors.locationDetailsLatitude = 'Enter correct format';
    }
    if (!formValues.locaionDetailsIpAddress) {
        errors.locaionDetailsIpAddress = 'Enter IP Address';
    } else if (!/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(formValues.locaionDetailsIpAddress)) {
        errors.locaionDetailsIpAddress = 'Enter correct format';
    }
    if (!formValues.locaionDetailsLandlineNumber) {
        errors.locaionDetailsLandlineNumber = 'Enter Landline Number';
    }
    if (!formValues.locaionDetailsPrimaryMobile) {
        errors.locaionDetailsPrimaryMobile = 'Enter Primary Mobile Number';
    } else if (formValues.locaionDetailsPrimaryMobile.length > 10) {
        errors.locaionDetailsPrimaryMobile = 'Mobile Number cannot be greater than 10';
    } else if (!/^[0-9]*$/.test(formValues.locaionDetailsPrimaryMobile)) {
        errors.locaionDetailsPrimaryMobile = 'Mobile Number should only have numbers';
    }
    if (!formValues.locaionDetailsSecondaryMobile) {
        errors.locaionDetailsSecondaryMobile = 'Enter Secondary Mobile Number';
    } else if (formValues.locaionDetailsSecondaryMobile.length > 10) {
        errors.locaionDetailsSecondaryMobile = 'Mobile Number cannot be greater than 10';
    } else if (!/^[0-9]*$/.test(formValues.locaionDetailsSecondaryMobile)) {
        errors.locaionDetailsSecondaryMobile = 'Mobile Number should only have numbers';
    }
    if (!formValues.locaionDetailsPrimaryEmail) {
        errors.locaionDetailsPrimaryEmail = 'Enter Primary Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.locaionDetailsPrimaryEmail)) {
        errors.locaionDetailsPrimaryEmail = 'Invalid email address';
    }
    if (!formValues.locaionDetailsSecondaryEmail) {
        errors.locaionDetailsSecondaryEmail = 'Enter Secondary Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.locaionDetailsSecondaryEmail)) {
        errors.locaionDetailsSecondaryEmail = 'Invalid email address';
    }

    return errors;
};
//#endregion

const mapStateToProps = state => {
    return {
        arrLocationCatProps: state.locationDetailsReducer.arrLocationDtlCat,
        arrLocationTypeProps: state.locationDetailsReducer.arrLocationDtlType,
        arrLocationDirProps: state.locationDetailsReducer.arrLocationDtlDir,
        arrLocationChainageProps: state.locationDetailsReducer.arrLocationDtlChainage
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        getLocationDetailsCat: () => dispatch(actions.getLocationDetailsCat()),
        getLocationDetailsType: (state) => dispatch(actions.getLocationDetailsType(state)),
        getLocationDetailsDir: () => dispatch(actions.getLocationDetailsDir()),
        getLocationDetailsChainage: (dirId) => dispatch(actions.getLocationDetailsChainage(dirId))
    };
};

LocationDetailsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationDetailsForm);

export default reduxForm({
    validate: validate,
    form: 'LocationDetailsForm'
})(withStyles(styles)(LocationDetailsForm));