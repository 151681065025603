import React from "react";
import GlobalAddForm from "./GlobalAddForm";
import { withStyles } from "@material-ui/core";
import axios from "axios";
import appConfig from "../../appConfig.json";
import { useDispatch } from "react-redux";
import { showToaster } from "../../store/actions";
const BASE_URL = appConfig.BASE_URL;

const styles = () => {};
const GlobalCreate = (props) => {
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    console.log(values);

    if (values.configValue1 === 0 || values.configValue1 === true) {
      return values.configValue1 === "TRUE";
    }
    if (values.configValue1 === 1 || values.configValue1 === false) {
      return values.configValue1 === "FALSE";
    }
    if (values.configValue2 === 0 || values.configValue2 === false) {
      return values.configValue2 === "FALSE";
    }
    if (values.configValue2 === 1 || values.configValue2 === true) {
      return values.configValue2 === "TRUE";
    }
    // values.isEnabled = 1;

    // Calling the API
    try {
      let response = await axios.post(BASE_URL + "/api/globalConfig", values);
      console.log(response);
      dispatch(showToaster("success", "Global config created"));
    } catch (error) {
      // console.log(`error`);
      dispatch(showToaster("error", error.response.data.message));
    }
    props.onClose();
  };
  return (
    <>
      <GlobalAddForm onClose={props.onClose} type="Add" onSubmit={onSubmit} />
    </>
  );
};

export default withStyles(styles)(GlobalCreate);
