import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getParentMenuSuccess = (arrParentMenu) => {
    console.log("IN SUCCESS");
    return {
        type: actionTypes.GET_PARENT_MENU_SUCCESS,
        arrParentMenu: arrParentMenu,
    };
};

export const getParentMenu = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/menu?reqType=all&menuLeafNode=0")
            .then(res => {
                console.log("ACTION :" + res.data);
                dispatch(getParentMenuSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const menuCreateStart = () => {
    return {
        type: actionTypes.MENU_CREATE_START
    };
};

export const menuCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(menuCreateStart());
            const response = await axios.post(BASE_URL + "/api/menu", formValues);
            console.log(response);
            dispatch(showToaster('success','Menu Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const menuStateReset = () => {
    return {
        type: actionTypes.MENU_STATE_RESET
    }
};

export const menuEdit = (formValues) => {
    console.log("Final:" + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        parentMenuId: formValues.parentMenuId,
        menuName: formValues.menuName,
        menuLevel: formValues.menuLevel,
        menuDescription: formValues.menuDescription,
        menuRoute: formValues.menuRoute,
        menuLeafNode: formValues.menuLeafNode,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(menuCreateStart());
            const response = await axios.patch(BASE_URL + "/api/menu/" + formValues.menuId, values);
            console.log("SSS:" + JSON.stringify(response));
            dispatch(showToaster('success','Menu Successfully Edited.'));
        } catch (error) {
            console.log("WWW: " + error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}