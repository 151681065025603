import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
    Select, TextField, Button, FormControl, FormControlLabel,
    Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, FormLabel,
    RadioGroup, Radio
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormHelperText from '@material-ui/core/FormHelperText'
import { connect } from "react-redux";
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";

//#region Styles
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});
//#endregion

class UserDetailsForm extends React.Component {


    onChange = event => {
        console.log("event.target.id=" + event.target.id);
        this.setState({ [event.target.id]: event.target.value });
    };


    renderPasswordField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
        return (
            <TextField
                variant="outlined"
                type='password'
                fullWidth
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        )
    }

    renderFromHelper = ({ touched, error }) => {
        if (!(touched && error)) {
            return
        } else {
            return <FormHelperText>{touched && error}</FormHelperText>
        }
    }

    radioGenderButton = ({ input, meta: { touched, error }, ...rest }) => (
        <FormControl fullWidth variant="outlined">
            <FormLabel component="legend">GENDER</FormLabel>
            <RadioGroup row {...input} {...rest}>
                <FormControlLabel value="female" control={<Radio />} label="Female" labelPlacement="start" />
                <FormControlLabel value="male" control={<Radio />} label="Male" labelPlacement="start" />
            </RadioGroup>
            {this.renderFromHelper({ touched, error })}
        </FormControl>
    )

    radioPayrollEmployeeButton = ({ input, meta: { touched, error }, ...rest }) => {
        input.value = input.value.toString();
        return (

            <FormControl fullWidth variant="outlined">
                <FormLabel component="legend">Payroll Employee</FormLabel>
                <RadioGroup row {...input} {...rest}>
                    <FormControlLabel value="1" control={<Radio />} label="Yes" labelPlacement="start" />
                    <FormControlLabel value="0" control={<Radio />} label="No" labelPlacement="start" />
                </RadioGroup>
                {this.renderFromHelper({ touched, error })}
            </FormControl>
        )
    }

    //#endregion Input Component Handlers

    //#region Form Submit
    onSubmit = (values) => {
        console.log("OnSubmit inside UserDetailsForm", values);
        this.props.onSubmit(values);
    }
    //#endregion

    //#region render()
    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let passwordFields = null;
        let isEnabled = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            passwordFields = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="loginPassword" component={this.renderPasswordField} label="Password" />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Field name="confirmPassword" component={this.renderPasswordField} label="Confirm Password" />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Field name="passwordHint" component={reduxFormFunctions.renderTextField} label="Password Hint" />
                </Grid>
            </React.Fragment>
            isEnabled = null;
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            passwordFields = null;
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    {/* <fieldset null> */}
                    <Card>
                        <CardHeader
                            subheader={this.props.type + " User Details"}
                            title="User Details"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="userDetailName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Full Name" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="userRoleId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="User Role">
                                        <option value={""}>{'Select User Role'}</option>
                                        {this.props.userRoles.map((userRole) => <option key={userRole.userRoleId} value={userRole.userRoleId}>{userRole.userRoleName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="loginId" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Login ID" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="mobileNo" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Mobile Number" />
                                </Grid>
                                {passwordFields}
                                <Grid item md={6} xs={12}>
                                    <Field name="emailId" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Email ID" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="userStatusId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="User Status">
                                        <option value={""}>{'Select User Status'}</option>
                                        {this.props.userStatus.map((userStatus) => <option key={userStatus.userStatusId} value={userStatus.userStatusId}>{userStatus.userStatusName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="gender" component={this.radioGenderButton} label="Gender" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="dateOfBirth" component={reduxFormFunctions.renderDateField} onChange={this.onChange} label="Date of Birth" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="dateOfJoining" component={reduxFormFunctions.renderDateField} onChange={this.onChange} label="Date of Joining" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="shiftId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Allocate Shift">
                                        <option value={""}>{'Select Shift'}</option>
                                        {this.props.userShifts.map((shift) => <option value={shift.shiftId}>{shift.shiftName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="payrollEmployee" component={this.radioPayrollEmployeeButton} label="Payroll Employee" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="activationDate" component={reduxFormFunctions.renderDateField} onChange={this.onChange} label="Activation Date" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="expiryDate" component={reduxFormFunctions.renderDateField} onChange={this.onChange} label="Expiry Date" />
                                </Grid>
                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
    //#endregion
}

//#region Validations
const validate = formValues => {
    const errors = {};

    var currentDate = new Date().toISOString().slice(0, 10);

    console.log("Current Date", currentDate);
    console.log("Date of birth", formValues.dateOfBirth);

    if (!formValues.userDetailName) {
        errors.userDetailName = 'Enter Full Name';
    } else if (!/^[a-zA-Z0-9_ ]*$/.test(formValues.userDetailName)) {
        errors.userDetailName = 'User Name should only have alphanumeric, underscores and spaces';
    }

    if (!formValues.loginId) {
        errors.loginId = 'Enter Login ID';
    } else if (!/^[a-zA-Z0-9_]*$/.test(formValues.loginId)) {
        errors.loginId = 'Login ID should be alphanumeric and underscores';
    }

    if (!formValues.mobileNo) {
        errors.mobileNo = 'Enter Mobile Number';
    } else if (formValues.mobileNo.length > 10) {
        errors.mobileNo = 'Mobile Number cannot be greater than 10';
    } else if (!/^[0-9]*$/.test(formValues.mobileNo)) {
        errors.mobileNo = 'Mobile Number should only have numbers';
    }

    if (!formValues.loginPassword) {
        errors.loginPassword = 'Enter Password';
    }
    // else if (formValues.loginPassword.length > 8) {
    //     errors.loginPassword = 'Password cannot be greater than 8 digit.';
    // }

    if (!formValues.confirmPassword) {
        errors.confirmPassword = 'Enter Confirm Password';
    }
    // } else if (formValues.confirmPassword.length > 8) {
    //     errors.confirmPassword = 'Confirm Password cannot be greater than 8 digit.';
    // }

    if (formValues.confirmPassword) {
        if (formValues.loginPassword !== formValues.confirmPassword) {
            errors.confirmPassword = 'Password and Confirm password should be equal';
            errors.loginPassword = 'Password and Confirm password should be equal';
        }
    }

    if (!formValues.passwordHint) {
        errors.passwordHint = 'Enter Password Hint';
    } else if (!/^[a-zA-Z0-9_ ]*$/.test(formValues.passwordHint)) {
        errors.passwordHint = 'Password Hint should be alphanumeric, underscores and spaces';
    }

    if (!formValues.emailId) {
        errors.emailId = 'Enter Email ID';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.emailId)) {
        errors.emailId = 'Invalid email address';
    }

    if (!formValues.dateOfBirth) {
        errors.dateOfBirth = 'Enter Date of Birth';
    }

    if (formValues.dateOfBirth) {
        if (formValues.dateOfBirth > currentDate) {
            errors.dateOfBirth = 'Do not select Future Date';
        }
    }

    if (!formValues.dateOfJoining) {
        errors.dateOfJoining = 'Enter Date of Joining';
    }

    if (!formValues.activationDate) {
        errors.activationDate = 'Enter account activation date';
    }

    if (!formValues.expiryDate) {
        errors.expiryDate = 'Enter account Expiry date';
    }

    if (formValues.activationDate && formValues.expiryDate) {
        if (formValues.activationDate > formValues.expiryDate) {
            errors.expiryDate = "Expiry date can't be the previous date of Activation Date";
        }
    }

    if (!formValues.userRoleId) {
        errors.userRoleId = 'Select User Role';
    }
    if (formValues.userRoleId) {
        if (formValues.userRoleId === "") {
            errors.userRoleId = 'Select User Role';
        }
    }

    if (!formValues.userStatusId) {
        errors.userStatusId = 'Select User Status';
    }
    if (formValues.userStatusId) {
        if (formValues.userStatusId === "") {
            errors.userStatusId = 'Select User Status';
        }
    }

    if (!formValues.shiftId) {
        errors.shiftId = 'Select User Shift';
    }
    if (formValues.shiftId) {
        if (formValues.shiftId === "") {
            errors.shiftId = 'Select User Shift';
        }
    }

    if (!formValues.gender) {
        errors.gender = 'Select Gender';
    }

    if (!formValues.payrollEmployee) {
        errors.payrollEmployee = 'Select Payroll';
    }

    return errors;
};
//#endregion

const mapStateToProps = state => {
    return {
        userRoles: state.userDetails.userRoles,
        userStatus: state.userDetails.userStatus,
        userShifts: state.userDetails.userShifts,
    };
};

UserDetailsForm = connect(
    mapStateToProps,
    null
)(UserDetailsForm);

export default reduxForm({
    form: 'UserDetailsForm',
    validate: validate,
})(withStyles(styles)(UserDetailsForm));