import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, Button, InputLabel, Box, Typography
} from '@material-ui/core';
import axios from "axios";
import appConfig from '../../appConfig.json';
import { connect } from "react-redux";
import FormHelperText from '@material-ui/core/FormHelperText'
import Toaster from '../../components/common/Toaster';
import * as reduxFormFunctions from '../../components/common/Redux_forms_Common';
import commonStyles from '../../commonStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import {renderTextField, renderSelectField} from '../../components/common/Redux_forms_Common';
import MenuItem from '@material-ui/core/MenuItem';

let status ;
let fromRout;



const BASE_URL = appConfig.BASE_URL;
const styles = theme => ({
    title: {
        flexGrow: 1,
        margin: '100px',
    },
    header: {
      display: 'inline'
  },
    headaddevent:{
      fontSize: '18px'
    },
    addButtonDiv: {
      float: 'right',
      marginTop: theme.spacing(2),
    },
    addButton: {
      margin: theme.spacing(1)
    },
    field: {
      border: 0
  },
  atmsPageSectionDivider:{
    margin: theme.spacing(2)
  },
  flexdiv: {
    display: 'flex'
  },

  gridcontainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spinDiv: {
    position: 'absolute',
    top: '45%',
    left: '45%',
    height: '100vh'
},
    ...commonStyles(theme)
});


class addEvent extends React.Component {


    constructor(props) {
        super(props)
    
        this.state = {
            eventData: null,
            equipmentType: [],
            notificationSource: [],
            eventType: [],
            eventSeverity: [],
            equipments: [],
            equipmentID : null,
            equipmentsDrop : null,
            eventSubType: [],
            eventReason:[],
            eventReasonDrop: null,
            eventSeverityDrop: null,
            direction:[],
            chainage:[],
            activationDatetime: null,
            status : false,
            spinLoader: false,
            toasterOpen: false,
            toasterMessageConfig: {
              message: '',
              type: '',
            },
            errorMsg: null,
            estimatedDuration : null,
            eventDescription: null,   
            directionNameDrop: null,  
            eventLatitude: '',
            eventLongitude: '',
            isLatlong: false,
            eventId: null,
            fromRout: null,
            redirect : false,

        }

        
      }

     async componentDidMount() {
        console.log('component Mounted');
        this.setState({componentMonted: true})
        if(this.props.location.state){
        console.log('Check param')
      
        console.log('Recived Props',this.props.location.state)
        let recivedProps = this.props.location.state;
        this.setState({eventId: recivedProps.eventId})
        this.setState({fromRout: recivedProps.fromRout})
        fromRout =  recivedProps.fromRout;

        
        const txnEventId = recivedProps.eventId;
        console.log('ID', txnEventId)

        //Txn Event
        this.setState({spinLoader : true})
        try {
          const eventData = await axios.get(BASE_URL + "/api/events/" + txnEventId)
          this.setState({eventData: eventData.data})
          
          if(eventData.data.equipment === null){
            this.getnotificationsource()
            if(eventData.data.equipment === null){
              let eqpId =  this.state.notificationSource.equipmentTypeId;
              this.getEquipment(eqpId);
            }
          }else{
            this.setState({equipmentType: eventData.data.equipmentType})
          }

          if(eventData.data.equipment === null){
            let eqpId =  this.state.equipmentType.equipmentTypeId;
            this.getEquipment(eqpId);
          }else{
              this.setState({equipmentsDrop: eventData.data.equipment})
          }
          
          this.setState({activationDatetime: eventData.data.activationDatetime})
          if(eventData.data.eventType == null){
            this.geteventType()
          }else{
            this.setState({eventType: eventData.data.eventType})
          }

          if(eventData.data.eventSubtype == null){
            let eventTypeId = this.state.eventType.equipmentTypeId
            this.getEventSubType(eventTypeId)
          }else{
            this.setState({eventSubType: eventData.data.eventSubtype})
          }
         
         
          
          if(eventData.data.eventReason === null){
            let eventSubTypeId =  this.state.eventSubType.eventSubtypeId;
            console.log(eventSubTypeId)
            this.getEventReasons(eventSubTypeId);
            
          }else{
            this.setState({eventReasonDrop: eventData.data.eventReason})
          }

          if(eventData.data.eventSeverity === null){
            this.geteventSeverity();
            
          }else{
            this.setState({eventSeverityDrop: eventData.data.eventSeverity})
          }
          
          if(eventData.data.estimatedDuration === null){
            console.log('eventDurationNull')             
          }else{
            this.setState({estimatedDuration: eventData.data.estimatedDuration})
          }
          
          if(eventData.data.eventDescription === null){
            console.log('eventDescriptionNull')             
          }else{
            this.setState({eventDescription: eventData.data.eventDescription})
          }
          
          if(eventData.data.directionName === null || eventData.data.chainageName === null){
              this.getdirection()    
          }else{
            this.setState({directionNameDrop: eventData.data.directionName})
            this.setState({chainageName: eventData.data.chainageName})
          }

          if(eventData.data.eventLatitude === null || eventData.data.eventLongitude === null){
            if(this.state.equipmentsDrop !== null){
              const eqpId = this.state.equipmentsDrop.equipmentId
              console.log('eqp',eqpId)
              this.getEqpLatLong(eqpId)  
            }
              
          }else{
              this.setState({eventLatitude: eventData.data.eventLatitude})
              this.setState({eventLongitude: eventData.data.eventLongitude})
              this.setState({isLatlong: true})
            }

            
            
        }
        catch (error) {
          console.log("api failed");
          console.log(error)
        }
        this.setState({spinLoader : false}) 
      }else{

        this.getnotificationsource();
        this.geteventType();
        this.geteventSeverity();  
        this.getdirection(); 
      }
      }  
     
      
      



    getnotificationsource = async() =>{
      // Notification Source
     try {
       const notificationSource = await axios.get(BASE_URL + "/api/equipmentTypes?reqType=all")
       this.setState({notificationSource: notificationSource.data});
     }
     catch (error) {
       console.log("error");
       this.drawToaster(error);
     }
   }
   
   getEquipment = async(equipmentTypeId) =>{
    // Equipment
   try {
    const equipment= await axios.get(BASE_URL + "/api/equipments?equipmentTypeId=" + equipmentTypeId + "&reqType=all")
    this.setState({equipments: equipment.data})
   }
   catch (error) {
     console.log("error");
     this.drawToaster(error);
   }
 }

  geteventType = async() =>{
        //Event Type
         try {
           const eventType = await axios.get(BASE_URL + "/api/eventTypes?reqType=all")
           this.setState({eventType: eventType.data });
         }
         catch (error) {
           console.log("error");
           this.drawToaster(error);
         }
   }
  
  getEventSubType = async(eventTypeId) =>{
    //Event Type
     try {
       const eventsubType = await axios.get(BASE_URL + "/api/eventSubtypes?eventTypeId=" + eventTypeId + "&reqType=all")
       this.setState({eventSubType: eventsubType.data });
     }
     catch (error) {
       console.log("error");
       this.drawToaster(error);
     }
}


  getEventReasons = async(eventSubTypeId) =>{
  //Event Type
  console.log('EventReason Call')
   try {
     const eventReason = await axios.get(BASE_URL + "/api/eventReasons?eventSubtypeId=" + eventSubTypeId + "&reqType=all")
     this.setState({eventReason: eventReason.data});
     console.log("SUCSESS")
   }
   catch (error) {
     console.log("error");
     this.drawToaster(error);
   }
  }

  geteventSeverity = async() =>{
     //Event Severity
     try {
       const eventSeverity = await axios.get(BASE_URL + "/api/eventSeverity?reqType=all")
       this.setState({eventSeverity: eventSeverity.data });
     }
     catch (error) {
       console.log("error");
       this.drawToaster(error);
     }
   }
   

  getdirection = async() =>{
     //Direction
     try {
       const direction = await axios.get(BASE_URL + "/api/direction?reqType=all")
       this.setState({direction: direction.data });
     }
     catch (error) {
       console.log("error");
       this.drawToaster(error);
     }
   }

  getchainage = async(directionId) =>{
    //Chainage
    try {
      const chainage = await axios.get(BASE_URL + "/api/chainage?directionId=" + directionId + "&reqType=all")
      this.setState({chainage: chainage.data });
    }
    catch (error) {
      console.log("error");
      this.drawToaster(error);
    }
  }

  getEqpLatLong = async(value) =>{
    //Chainage
    let eqpId = value
    try {
      const latLong = await axios.get(BASE_URL + "/api/equipments?equipmentId=" + eqpId + "&reqType=all")
      this.setState({eventLatLongData: latLong.data[0] });
      console.log("lat", this.state.eventLatLongData.latitude)
      this.setState({eventLongitude: this.state.eventLatLongData.longitude });
      this.setState({eventLatitude: this.state.eventLatLongData.latitude });
      console.log("lat", this.state.eventLatLongData.longitude)
      this.setState({isLatlong: true})
    }
    catch (error) {
      console.log("error");
      this.drawToaster(error);
    }
  }

    drawToaster = (errormsg) =>{
      this.setState({
        toasterOpen: true,
        toasterMessageConfig: {
          message: errormsg,
          type: "error",
        }}); 
    }

    renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
      <React.Fragment>
        <InputLabel>{label}</InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          // label={label}
          placeholder={label}
          error={touched && invalid}
          helperText={touched && error}
          {...input}
          {...custom}
        />
      </React.Fragment>
    )
      
      renderDateField = ({ label, input, defaultValue, meta: { touched, invalid, error }, ...custom }) => {
        // console.log("input and custom in datefield", input, custom);
        // Changing the data value
        // input.value = new Date(input.value).toISOString().slice(0, 16);
        // console.log(input.value)
        let dt = new Date();
        dt.setHours(dt.getHours() + 5);
        dt.setMinutes(dt.getMinutes() + 30);
        const currDate = dt.toISOString().slice(0, 16);
        this.setState({currDate: currDate})
        console.log(currDate, 'curr')
        if (this.state.status !== false){
          return (
            <FormControl fullWidth={true}>
              <TextField 
            
                variant="outlined"
                label= 'Sheduled Date-time'
                type="datetime-local"
                defaultValue={defaultValue}
                error={touched && invalid}
                helperText={touched && error}
                InputProps={{inputProps: { min: currDate} }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...input}
                {...custom}
               
              />
            </FormControl>
          )
          
        }else {
          return (
            <FormControl fullWidth={true}>
              <TextField 
            
                variant="outlined"
                label= {label}
                type="datetime-local"
                defaultValue={defaultValue}
                error={touched && invalid}
                helperText={touched && error}
                InputProps={{inputProps: { max: currDate} }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...input}
                {...custom}
               
              />
            </FormControl>
          )
        }
        
      }
      renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
        return (
          <FormControl fullWidth variant="outlined" error={touched && error} helperText={touched && error}>
            <InputLabel>{label}</InputLabel>
            <Select
            native
            label={label}
            {...input}
            {...custom}
            shrink={true}
            InputLabelProps={{
              shrink: true,
            }}
          >
              {children}
    
            </Select>
            {this.renderFromHelper({ touched, error })}
          </FormControl>
    
        )
      }

      radioIsEnabled = ({ input, reset, ...props }) => (
        <FormControl fullWidth variant="outlined">
          <FormControlLabel
            control={<Checkbox {...input}
              name="isSheduled"
              checked={input.value ? 1 : 0} />}
              label="isSheduled"
            disabled={props.disabled} />
        </FormControl>
      )

      // dateFieldOnchange = (event, newValue, previousValue, name) => {
      //   console.log(newValue, new Date().getTimezoneOffset())
      //   if(newValue === this.state.currDate){
      //     console.log('Date time cant be current')
      //     this.setState({errorMsg: 'Date time cant be current'})
      //   }
      // }
 
      renderFromHelper = ({ touched, error }) => {
        if (!(touched && error)) {
          return
        } else {
          return <FormHelperText>{touched && error}</FormHelperText>
        }
      }
    
  
    
      notificationSrcOnChange = (event, newValue, previousValue, name) => {
        let eqpId = newValue;
        this.getEquipment(eqpId);
      };
    
      eventTypeOnChange = (event, newValue, previousValue, name) => {
        let eventTypeId = newValue;
        axios.get(BASE_URL + "/api/eventSubtypes?eventTypeId=" + eventTypeId + "&reqType=all").then((res => {
          // console.log('eqipmentData =', res.data )
          this.setState({eventSubType: res.data}) 
          console.log('eventSubTypetData=', res.data)             
       })).catch((err) =>{
        console.log(err);
        this.drawToaster(err);
       })
      };
    
      eventSubtypeOnChange = (event, newValue, previousValue, name) => {
        let eventSubTypeId = newValue;
        axios.get(BASE_URL + "/api/eventReasons?eventSubtypeId=" + eventSubTypeId + "&reqType=all").then((res => {
          // console.log('eqipmentData =', res.data )
          this.setState({eventReason: res.data}) 
          console.log('eventReasonData=', res.data)             
       })).catch((err) =>{
        console.log(err);
        this.drawToaster(err);
       })
      }

      directionOnChange = (event, newValue, previousValue, name) => {
        let directionId = newValue;
        
        axios.get(BASE_URL + "/api/chainage?directionId=" + directionId + "&reqType=all").then((res => {
          // console.log('eqipmentData =', res.data )
          this.setState({chainage: res.data}) 
          console.log('chainageData=', res.data)             
       })).catch((err) =>{
        console.log(err);
        this.drawToaster(err);
       })
       console.log('directionId =',directionId)
      }

      equipmentOnChange = (event, newValue, previousValue, name) => {
          let eqpId = newValue;

          this.setState({equipmentID: eqpId})
          this.getEqpLatLong(eqpId)
          
          console.log('eqpId =',eqpId)
      }

      handleChange = (event,values) => {
        this.setState((prevState) => {
           return {
              ...prevState,
              status: !prevState.status
           }
        });
        this.props.reset(values.detectionTime)
        status = this.state.status
    }

      handleClose  = () => {
        this.setState({
          // ...state,
          toasterOpen: false,
        });
      };

      resetState = () => {
        this.setState({
          equipments: [],
          equipmentID : null,
          equipmentsDrop : null,
          eventSubType: [],
          eventReason:[],
          eventReasonDrop: null,
          eventSeverityDrop: null,
          chainage:[], 
          directionNameDrop: null, 
          eventLatitude: null,
          eventLongitude: null,
          isLatlong : false,
          status: false,
        })
      }

      afterApiCall = () => {
        if(this.state.fromRout != null){
          // setTimeout( this.props.history.push("/ecb"), 5000) ;
          this.props.history.push("/ecb")
         
           
        }else{
          this.setState({
            spinLoader: false,
          });
          this.props.reset();
          this.resetState();
          console.log('spinloader after',this.state)
        } 
      }




      // OnSubmit 
      onSubmit = (values) => {
        //Retriving Chainage and Direction Name
            const x =this.state.direction
            console.log('x', x);
            let dirName = '' ;
            const direction =x.map((value)=>{
              console.log(value) 
              if(value.directionId == values.directionName){
                  dirName = value.directionName
              }
            });
            console.log('direction=', direction)

            const y =this.state.chainage
            let chnName = '' ;
            const chainage =y.map((value)=>{
              // console.log(value) 
              if(value.chainageId == values.chainageName){
                  chnName = value.chainageName
              }
            });

            console.log('chainage=', chainage)


       if(this.state.eventData != null){
               
            let genDtsVals = {
                txnEventId : this.state.eventId,
                eventLatitude : this.state.eventLatitude,
                eventLongitude: this.state.eventLongitude,     
              }

            if(this.state.equipmentsDrop === null){
              genDtsVals['equipmentId'] = this.state.equipmentID
            }else{
              genDtsVals['equipmentId'] = this.state.equipmentsDrop.equipmentId
            }
            
            if(this.state.eventReasonDrop == null){
              genDtsVals['eventReasonId'] = values.eventReasonId
            }

            if(this.state.eventReasonDrop == null){
              genDtsVals['eventReasonId'] = values.eventReasonId
            }

            if(this.state.eventSeverityDrop == null){
              genDtsVals['eventSeverityId'] = values.eventSeverityId
            }

            if(this.state.estimatedDuration == null){
              genDtsVals['estimatedDuration'] = values.estimatedDuration
            }

            if(this.state.eventDescription == null){
              genDtsVals['eventDescription'] = values.eventDescription
            }

            if(this.state.directionNameDrop === null){
              genDtsVals['chainageId'] = values.chainageName;
              genDtsVals['chainageName'] = chnName;
              genDtsVals['directionName'] = dirName;
            }

              console.log('Selected Values',genDtsVals);
              this.setState({
                spinLoader: true,
              });

              axios.patch(BASE_URL + "/api/events/" , genDtsVals)
              .then(res =>{
                console.log('RES', res)
                  this.setState({
                    toasterOpen: true,
                    toasterMessageConfig: {
                      message: 'Event succesfully updated',
                      type: "success",
                  }}); 
                  console.log('state in then',this.state)
                  if(this.state.toasterOpen === true){
                    setTimeout(() => {
                      console.log('Entered Settimeout')
                      this.afterApiCall();
                    }, 3000);
                     
                  }
                  
            
              })
              .catch(err =>{
                this.setState({
                  toasterOpen: true,
                  toasterMessageConfig: {
                    message: 'Some error occured',
                    type: "error",
                  },})
  
              });
       }
       else{
            
            let genDtsVals = {};
            if(this.state.status == true){
              genDtsVals = {
                eventSeverityId: values.eventSeverityId,
                estimatedDuration: values.estimatedDuration,
                eventDescription: values.eventDescription,
                eventReasonId: values.eventReasonId,
                eventTypeId: values.eventTypeId,
                eventSubtypeId: values.eventSubtypeId,
                equipmentId: values.equipmentId,
                equipmentTypeId: values.equipmentTypeId,
                chainageId: values.chainageName,
                chainageName: chnName,
                directionName: dirName,
                activationDatetime : values.detectionTime,
                isScheduled: this.state.status,
                      
              }
            }
            else{
              genDtsVals = {
                eventSeverityId: values.eventSeverityId,
                estimatedDuration: values.estimatedDuration,
                eventDescription: values.eventDescription,
                eventReasonId: values.eventReasonId,
                eventTypeId: values.eventTypeId,
                eventSubtypeId: values.eventSubtypeId,
                equipmentId: values.equipmentId,
                equipmentTypeId: values.equipmentTypeId,
                chainageId: values.chainageName,
                chainageName: chnName,
                directionName: dirName,
                detectionTime : values.detectionTime,

              }
            }


            if(this.state.isLatlong === false ){
                genDtsVals['eventLatitude'] = values.eventLatitude;
                genDtsVals['eventLongitude'] = values.eventLongitude;
            }
            else{
              genDtsVals['eventLatitude'] = this.state.eventLatitude;
              genDtsVals['eventLongitude'] = this.state.eventLongitude;
            }
            const postResult = JSON.stringify(genDtsVals)
          
            this.setState({
              spinLoader: true,
            }); 
            console.log('spinloader before',this.state)
            axios.post( BASE_URL + "/api/events", postResult)
            .then(res =>{
              console.log(this.state);
              this.setState({
                toasterOpen: true,
                toasterMessageConfig: {
                  message: 'Event succesfully created',
                  type: "success",
                }},this.afterApiCall()); 
                  
            })
            .catch(err =>{
              console.log(err);
              this.setState({
                toasterOpen: true,
                toasterMessageConfig: {
                  message: 'Some error occured',
                  type: "error",
                }})

            });
          }
       
      }
  
    
     


    render() {
  
    // console.log('ID:',this.props)
    console.log(this.state, 'state')
   
    const { classes, reset, pristine, invalid } = this.props;

 
    let progress = null;
    let isEventSheduled = null;

    if(this.state.spinLoader === true) {
      console.log('Rendering spinloader')
        progress =  <div className={classes.spinDiv}>
                        <CircularProgress size={200} thickness={2} />
                    </div>
    }

    if(this.state.fromRout !== "ecb"){
      isEventSheduled = 
      <div>
               <Grid container spacing={3} className={classes.gridcontainer} >
         <Grid item md={6} xs={12}>
            <div className={classes.flexdiv}>
            
            <Field name="isSheduled" component={this.radioIsEnabled} label="isSheduled" onChange={this.handleChange}   />
               
            </div>
         </Grid>           
       </Grid>
         <Divider variant="list" className={classes.atmsPageSectionDivider} />
      </div>
    }

   function fieldComp(value , id, label){
      return(
        <TextField
                  id={id}
                  fullWidth
                  select
                  label={label}
                  value={value}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  disabled
                >
                <option value={value}>
                  
                  {value}
                </option>
              </TextField>
      )
   }

      function textFielComp(value, id, label){
        return(
          <TextField
            id={id}
            fullWidth
            label={label}
            multiline
            rowsMax={1}
            value={value}
            variant="outlined"
            disabled
          />
        )
      }

      function textFielDisComp(value, id, label){
        return(
          <TextField
            id={id}
            fullWidth
            label={label}
            multiline={true}
            rowsMax={2}
            value={value}
            variant="outlined"
            disabled
          />
        )
      }

      


    return (
      <React.Fragment>
         {progress}
        <Toaster
          open={this.state.toasterOpen}
          message={this.state.toasterMessageConfig && this.state.toasterMessageConfig.message}
          severity={this.state.toasterMessageConfig && this.state.toasterMessageConfig.type}
          onClose = {this.handleClose}
        />
         
        
        <div className={classes.atmsPageOuterDiv}>
       
        <Grid container spacing={1} className={classes.gridcontainer} >
          <Grid item md={6} xs={12}>
            <Typography variant="h4" gutterBottom className={classes.header}>
                      Event/Scheduled
            </Typography>
          </Grid>
        </Grid>
          <Card className={classes.atmsPageOuterCard}>
          
          <form  className={classes.rootform} autoComplete="off" name="addEvent"  onSubmit={this.props.handleSubmit(this.onSubmit)}>
      
       <fieldset className={classes.field} >
        {isEventSheduled}
      

         <Typography variant="h5" className="sectionHeading">Source</Typography>
         
         <Grid container spacing={3} className={classes.gridcontainer}>
           <Grid item md={3} xs={12}>
             { (this.state.equipmentType.length === 0)
              ? <Field name="equipmentTypeId" component={this.renderSelectField} label="Notification Source" onChange={this.notificationSrcOnChange} >
                <option value="" />
                  {this.state.notificationSource.map((notificationSource) => {
                    return (<option value={notificationSource.equipmentTypeId}>{notificationSource.equipmentTypeName}</option>);
                  })} 
                </Field>
                :fieldComp(this.state.equipmentType.equipmentTypeName, "equipmentTypeId","Notification Source" )
                
              }     
           </Grid>
           <Grid item md={3} xs={12}>
            { (this.state.equipmentsDrop === null)
              ? <Field name="equipmentId" component={this.renderSelectField} label="Equipment" onChange={this.equipmentOnChange} >
                  <option value="" />
                  {this.state.equipments.map((equipments) => {
                    return (<option value={equipments.equipmentId}>{equipments.equipmentName}</option>);
                  })}
                </Field>
                :fieldComp(this.state.equipmentsDrop.equipmentName, "equipmentId","Equipment" )
                
              } 
           </Grid>

           <Grid item md={3} xs={12}>
           { (this.state.activationDatetime === null)
              ?<Field name="detectionTime" component={this.renderDateField} label="Detection Time" onChange={this.dateFieldOnchange}   />
                :textFielComp(this.state.activationDatetime.toString().slice(0,10) + '  ' + this.state.activationDatetime.toString().slice(11,16), "detectionTime" ,"Detection Time" )
               
              } 
             
           </Grid>
          
         </Grid>
         <Divider variant="list" className={classes.atmsPageSectionDivider} />
         <Typography variant="h5">Event</Typography>
         <Grid container spacing={3} className={classes.gridcontainer}>
           <Grid item md={3} xs={12}>
           { (this.state.eventData === null)
              ?<Field name="eventTypeId" component={this.renderSelectField} label="Event Type" onChange={this.eventTypeOnChange}  >
              <option value="" />
              {this.state.eventType.map((eventType) => {
                return (<option value={eventType.eventTypeId}>{eventType.eventTypeName}</option>);
              })}
            </Field>
                :fieldComp(this.state.eventType.eventTypeName, "eventTypeId","Event Type" )
               
              }
             
           </Grid>
           <Grid item md={3} xs={12}>
           { (this.state.eventData === null)
              ?<Field name="eventSubtypeId" component={this.renderSelectField} label="Event Sub Type" onChange={this.eventSubtypeOnChange}  >
              <option value="" />
              {this.state.eventSubType.map((eventSubType) => {
                return (<option value={eventSubType.eventSubtypeId}>{eventSubType.eventSubtypeName}</option>);
              })}
            </Field>
                :fieldComp(this.state.eventSubType.eventSubtypeName, "eventSubtypeId","Event Sub Type" )
                
              }
           </Grid>
           <Grid item md={3} xs={12}>
           { (this.state.eventReasonDrop === null)
                  ? <Field name="eventReasonId" component={this.renderSelectField} label="Event Reason">
                  <option value="" />
                  {this.state.eventReason.map((eventReason) => {
                    return (<option value={eventReason.eventReasonId}>{eventReason.eventReasonName}</option>);
                  })}
                </Field>
                    :fieldComp(this.state.eventReasonDrop.eventReasonName, "eventReasonId","Event Reason" )
                    
}
             
           </Grid>
           <Grid item md={3} xs={12}>
           { (this.state.eventSeverityDrop === null)
              ?<Field name="eventSeverityId" id="eventSeverityId" component={this.renderSelectField} label="Event Severity" >
              <option value="" />
              {this.state.eventSeverity.map((eventSeverity) => {
                return (<option value={eventSeverity.eventSeverityId}>{eventSeverity.severityName}</option>);
              })}
            </Field>
                :fieldComp(this.state.eventSeverityDrop.severityName, "eventSeverityId","Event Severity" )
                
              }
             
           </Grid>
           <Grid item md={3} xs={12}>
           { (this.state.estimatedDuration === null)
              ?<Field name="estimatedDuration" parse={(val) => typeof val == 'String'? parseInt(val, 10): val} type="number" component={reduxFormFunctions.renderTextField} label="Estimated Duration (minutes)" />
                :textFielComp(this.state.estimatedDuration, "estimatedDuration" ,"Estimated Duration (minutes)" )
                
              }
             
           </Grid>
           <Grid item md={6} xs={12}>
           { (this.state.eventDescription === null)
              ?<Field name="eventDescription" component={reduxFormFunctions.renderTextField} label="Event Description"  />
                 :textFielDisComp(this.state.eventDescription, "eventDescription" ,"Event Description" ) 
                
              }
             
           </Grid>
           
         </Grid>
         <Divider variant="list" className={classes.atmsPageSectionDivider} />
         <Typography variant="h5">Location</Typography>
         
         <Grid container spacing={3} className={classes.gridcontainer}>
         <Grid item md={3} xs={12} >
         { (this.state.directionNameDrop === null)
              ?<Field name="directionName" component={this.renderSelectField} label="Direction Name" onChange={this.directionOnChange} >
              <option value="" />
                {this.state.direction.map((direction) => {
                  return (<option value={direction.directionId}>{direction.directionName}</option>);
                })}
              </Field>
                :fieldComp(this.state.directionNameDrop, "directionName","Direction Name" )
              }
             
           </Grid>
           <Grid item md={3} xs={12}>
           { (this.state.directionNameDrop === null)
              ?<Field name="chainageName" component={this.renderSelectField} label="Chainage Name"  >
              <option value="" />
                {this.state.chainage.map((chainage) => {
                  return (<option value={chainage.chainageId}>{chainage.chainageName}</option>);
                })}
              </Field>
                :fieldComp(this.state.chainageName, "chainageName", "Chainage Name")
              }
             
           </Grid>
           
    
           <Grid item md={3} xs={12} >
           { (this.state.isLatlong === false)
              ?<Field name="eventLatitude" type="number" component={reduxFormFunctions.renderTextField} label="Latitude"   />
                :textFielComp(this.state.eventLatitude, "eventLatitude", "Latitude")
              
                
              }
             
           </Grid>
           <Grid item md={3} xs={12}>
           { (this.state.isLatlong === false)
              ? <Field name="eventLongitude" type="number" component={reduxFormFunctions.renderTextField} label="Longitude"   />
                :textFielComp(this.state.eventLongitude, "eventLongitude", "Longitude") 
                
              }
            
           </Grid>
         </Grid>
         <Divider variant="list" className={classes.atmsPageSectionDivider} />
         <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                           <Button  variant="contained" color="primary" className={classes.addButton} size="large" disabled={pristine} onClick={()=> {
                              this.props.reset();
                              this.resetState();
                           }}>Reset</Button>
              <Button  variant="contained" color="primary" className={classes.addButton} size="large" type="submit">
             {this.state.fromRout === "ecb" ? 'Update Event' : 'Add Event'}
              </Button>
        </Box>
  
        
         
       </fieldset>
     </form >
          </Card>
        </div>
        
      </React.Fragment >
    );
  }
}


const validate = (formValues,state) => {
  let dt = new Date();
  dt.setHours(dt.getHours() + 5);
  dt.setMinutes(dt.getMinutes() + 30);
  const currDate = dt.toISOString().slice(0, 16);
  console.log('this.props.form.EventGeneralDtsForm', status, fromRout)

  if(fromRout == "ecb" || fromRout === undefined){
    console.log("ECB inside validation function")
    const error = {};
    return error;
  }else{
    const error = {};
    console.log("NULL")
    if (!formValues.equipmentTypeId) {
      error.equipmentTypeId = "Notification Source Name is required";
    }
    
    if (!formValues.equipmentId) {
      error.equipmentId = "Equipment Name is required";
    }
    
    if (!formValues.detectionTime) {
      error.detectionTime = "Enter Event Date Time";
    }
    if (formValues.detectionTime === currDate) {
      error.detectionTime = "Event date time can't be current date time";
    }
    if(status !== false){
      console.log('True')
      if (formValues.detectionTime > currDate) {
        error.detectionTime = "Detection date-time can't be a future date-time";
        console.log('True')
      }
    }else{
      if(formValues.detectionTime < currDate){
        error.detectionTime = "Detection date-time can't be a past date-time";
        console.log('False')
      }
    }
  
    if (!formValues.eventTypeId) {
      error.eventTypeId = "Event Type is required";
    }
    if (!formValues.eventSubtypeId) {
      error.eventSubtypeId = "Event Subtype is required";
    }
  
    if (!formValues.eventDescription) {
      error.eventDescription = "Event Description is required";
    }
    if (formValues.eventDescription) {
      if (formValues.eventDescription.length > 1000) {
        error.eventDescription = "Maximum characters should be 1000 only";
      }
    }
    if (!formValues.directionName) {
      error.directionName = "directionName is required";
    }
    if (!formValues.chainageName) {
      error.chainageName = "chainageName is required";
    }
    if (!formValues.eventLatitude) {
      error.eventLatitude = "Latitude is required";
    }
   
    if (formValues.eventLatitude) {
      if (/^(\d+(?:[\.\,]\d{5})?)$/.test(formValues.eventLatitude) == true){
          error.eventLatitude = "Decimal number is expected in format XX.XX";
      }
      
    }
  
    if (!formValues.eventLongitude) {
      error.eventLongitude = "Longitude is required";
    }
    if (formValues.eventLongitude) {
      if (/^(\d+(?:[\.\,]\d{5})?)$/.test(formValues.eventLongitude) == true){
          error.eventLongitude = "Decimal number is expected in format XX.XX";
      }
      
    }

    return error;
  }
  
  
  
  

}

  
const mapStateToProps = state => { 
  return {

    }
  };
  
const mapDispatchToProps = dispatch => {
    return {
    
    };
};



addEvent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(addEvent);

export default withRouter(reduxForm({
  form: 'addEvent',
  validate: validate,
})(withStyles(styles)(addEvent)));
;


