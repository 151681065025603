import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {   
    ArrDirection: [],
    ArrChainage: [],    
    loading: null    
};

const getChainageSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrChainage: action.arrChainage
    });
    return updatedState;
}

const getDirectionForSectionSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrDirection: action.arrDirection
    });
    return updatedState;
}

const sectionStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const sectionCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHAINAGE_SUCCESS:
            return getChainageSuccess(state, action)

        case actionTypes.GET_DIRECTION_FOR_SECTION_SUCCESS:
            return getDirectionForSectionSuccess(state, action)

        case actionTypes.SECTION_CREATE_START:
            return sectionCreateStart(state, action);

        case actionTypes.SECTION_STATE_RESET:
            return sectionStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;