import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    loading: false,
};

const locationCatStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const locationCatCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_CATEGORY_STATE_RESET:
            return locationCatStateReset(state, action);
        case actionTypes.LOCATION_CATEGORY_CREATE_START:
            return locationCatCreateStart(state, action);
        default:
            return state;
    }
}

export default reducer;