import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    // Directions: [],
    // strAddDirection: '',
    loading: null
};

// const directionSuccess = (state, action) => {
//     var updatedState = updateObject(state,
//         {
//             Directions: action.directions
//         });
//     return updatedState;
// };

// const directionAddSuccess = (state, action) => {
//     var updatedState = updateObject(state,
//         {
//             strAddDirection: action.strAddDirection
//         });
//     return updatedState;
// };

// const directionAddFail = (state, action) => {
//     var updatedState = updateObject(state,
//         {
//             strAddDirection: action.strAddDirection
//         });
//     return updatedState;
// }

const directionStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const directionCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case actionTypes.DIRECTION_SUCCESS:
        //     return directionSuccess(state, action);

        // case actionTypes.DIRECTION_ADD_SUCCESS:
        //     return directionAddSuccess(state, action);

        // case actionTypes.DIRECTION_ADD_FAIL:
        //     return directionAddFail(state, action);

        case actionTypes.DIRECTION_STATE_RESET:
            return directionStateReset(state, action);

        case actionTypes.DIRECTION_CREATE_START:
            return directionCreateStart(state, action);

        default:
            return state;
    }
}

export default reducer;
