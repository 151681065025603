import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, Card, CardContent, Button } from '@material-ui/core';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from "react-redux";
import ViolationFilter from '../../../components/common/violationManagement/ViolationFilter';
import Toaster from '../../../components/common/Toaster';
import * as actions from '../../../store/actions/index';
import globals from '../../../utils/globals';
import ChallanDetails from './ChallanDetails';
import axios from 'axios';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f4f6f8',
  },
  header: {
    display: 'inline'
  },
  bulkChaBut: {
    float: 'right',
    marginBottom: theme.spacing(1)
  }
});

class Dashboard extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedChallanRow: null,
      urlParams: [{ 'key': 'speedChallanStatusId', value: 102 }],
      violationName: null,
      acceptViolStatusId: null,
      selection: [],
      openToast: false,
      msgToast: "",
      toastSeverity: "success",
    };
    this.grid = React.createRef();
    this.challanRef = React.createRef();
  }

  async componentDidMount() {

    // Get the Directions
    this.props.getDirections();

    // Get the Vehicle Class
    this.props.getVehicleClass();

    // Get the Sections
    this.props.getSections();

    // Get the Violation Status
    this.props.getViolationStatus();


    // Get the vehicle categories and populate the vehicle category dropdown
    try {
      let response = await axios.get(BASE_URL + "/api/speedViolationStatuses?reqType=all&reqSource=challan");
      let vStatusTemp = response.data.find(vStatus => vStatus.speedViolationStatusName === "ACCEPTED");
      this.setState({ urlParams: [{ 'key': 'speedChallanStatusId', value: vStatusTemp.speedViolationStatusId }] });
      this.setState({ acceptViolStatusId: vStatusTemp.speedViolationStatusId })
    } catch (error) {
      this.setState({ openToast: true, msgToast: "Unable to get the Violation Status", toastSeverity: "error" });
    }
  }

  bulkChallan = async (event) => {
    event.preventDefault();
    // console.log("challan for bulk report", this.state.selection);
    let selectedIds = this.state.selection.map((row) => row.txnSpeedChallanId);
    console.log(selectedIds)

    try {
      var response = await axios.post(BASE_URL + "/api/speedChallan/bulkPrint", { ids: selectedIds }, {
        headers:
        {
          'Accept': 'application/pdf',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer'
      });
      console.log(response);

      const objUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = objUrl;
      link.setAttribute('download', 'bulkChallan.pdf');
      document.body.appendChild(link);
      link.click();
      this.setState({ openToast: true, msgToast: "Successfully Downloaded the Bulk Challan PDF ", toastSeverity: "success" });
    } catch (error) {
      console.log(error.response);
      this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
    }
  }

  onSubmit = (urlParams) => {
    // console.log("onSubmit violation = urlParams", urlParams);
    this.setState({ urlParams: urlParams })
  }

  reloadViolations = () => {
    this.setState({ selectedChallanRow: null })
    this.grid.current.reload();
  }

  handleScrollTo = (elRef) => {
    const el = elRef.current ? elRef.current : elRef;
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  

  render() {
    const { classes } = this.props;
    let violationName = null;
    let apiUrl = null;

    // Get the Challan which are shown on the GRID
    let challanStatus = this.state.urlParams.find((params) => params.key == "speedChallanStatusId");
    console.log("ChallanStatusId", challanStatus)
 
    if (this.props.violationType) {
      violationName = globals.violationTypes[this.props.violationType.configValue1];
      apiUrl = "api/speedChallan?speedViolationType=" + this.props.violationType.configValue1;  


      return (
        <Container maxWidth="xl" className={classes.root}>
          {/* Header */}
          <Typography variant="h4" gutterBottom className={classes.header}>
            Challan Dashboard
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.header}>
            {" " + violationName}
          </Typography>

          {/* Violation Challan Filter */}
          <Box mt={3}>
            <Card>
              <CardContent>
                <ViolationFilter
                  onSubmit={(urlParams) => {
                    this.onSubmit(urlParams)
                  }}
                  challanFilter={true}
                  acceptViolStatusId={this.state.acceptViolStatusId}
                />
              </CardContent>
            </Card>
          </Box>

          {/* Violation Challan DataGrid */}
          <Box mt={3}>
            <Button color="primary" className={classes.bulkChaBut} variant="contained" disabled={challanStatus?.value !== 104} onClick={this.bulkChallan}>
              Bulk Challan
            </Button>
            <DataGrid ref={this.grid} endpoint={apiUrl} downloadReport={true} multiselect={challanStatus?.value === 104} isMasterGrid={false}
              urlParams={this.state.urlParams}
              onSelectionChange={(selection) => {
                this.setState({ selection: selection })
              }}
              onRowClick={(event, rowData) => {
                // console.log("Edit Click in Dashboard", event, rowData);
                this.setState({ selectedChallanRow: rowData });
                this.handleScrollTo(this.challanRef)
              }}
            />
          </Box>

          {/* Violation Challan Details */}
          <Box mt={3} ref={this.challanRef}>
            <Card>
              <CardContent>
                <ChallanDetails violationType={this.props.violationType.configValue1}
                  selectedChallanRow={this.state.selectedChallanRow}
                  key={this.state.selectedChallanRow}
                  reloadViolations={this.reloadViolations} />
              </CardContent>
            </Card>
          </Box>
          <Toaster
            open={this.state.openToast}
            message={this.state.msgToast}
            severity={this.state.toastSeverity}
            onClose={() => {
              this.setState({ openToast: false });
            }}
          />
        </Container>
      );
    } else {
      return <div>Loading...</div>
    }
  }
}

const mapStateToProps = state => {
  return {
    violationType: state.common.globalConfig.violation_type,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDirections: () => dispatch(actions.getDirections()),
    getVehicleClass: () => dispatch(actions.getVehicleClass()),
    getSections: () => dispatch(actions.getSections()),
    getViolationStatus: () => dispatch(actions.getViolationStatus())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
