import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import ReactAudioPlayer from 'react-audio-player';
import { Container, Card, CardHeader, Divider, CardContent, IconButton, Box, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    audioCont: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
});

class ECBAudioPlayer extends React.Component {

     render() {
        const { classes, audioSrc } = this.props;
        console.log('props', audioSrc)
           
        return (
            <React.Fragment>
                <Container maxWidth="sm" className={classes.audioCont}>
                    <Card>
                        <CardHeader
                            title={"ECB Audio Player"}
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Box mt={3}>
                                {audioSrc !== 'null/null.null' ? <ReactAudioPlayer
                                    src= {audioSrc}
                                    controls
                                    controlsList="nodownload"
                                /> :"Call Recording is not Available"}   
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
}

export default connect(null, null)(withStyles(styles)(ECBAudioPlayer));