import React from 'react';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Select, Button, FormControl, Grid, Checkbox, InputLabel, Input, MenuItem, ListItemText } from '@material-ui/core';
import * as actions from '../../../store/actions/index';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import format from 'date-fns/format';


const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8'
    },
    button1: {
        marginLeft: '50px' ,
        marginTop: '8px'
    },
    button2: {
        float: 'right',
        marginLeft: '15px',
        marginTop: '8px'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    selectFormControl: {
        minWidth: 120,
    },
    searchFilter: {
        marginLeft: '5px'
    }
});

class EventAuditSearchFilters extends React.Component {
    constructor() {
        super();
        let date = new Date();
        // let fromDate = new Date();
        let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        console.log("defaultToDate", defaultToDate)
        
        this.state = {
            fromDate: defaultFromDate,
            toDate: defaultToDate,
            eventTypeId: [],
            eventSubtypeId: [],
            isOpenSnack: false,
            isError: false,
            errorMessage: ''
        };
        this.grid = React.createRef();
    }

    componentDidMount() {
        this.props.getEventType();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.eventTypeId !== prevState.eventTypeId) {
            this.props.getEventSubType(this.state.eventTypeId);
        }
    }

    onAlertClose = () => {
        this.setState({
            isOpenSnack: false,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        });
    }

    resetEventAuditFilter = () => {
        let date = new Date();
        let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        this.setState({
            fromDate: defaultFromDate,
            toDate: defaultToDate,
            eventTypeId: [],
            eventSubtypeId: []
        })
    }

    onSearchSubmit = (event) => {
        event.preventDefault();

        const urlParams = [];

        if (this.state.fromDate || this.state.toDate) {

            var fDate = new Date(this.state.fromDate);
            var tDate = new Date(this.state.toDate);
            if (this.props.timeValue) {
                var dateRange = this.props.timeValue.configValue1;
                console.log("dateRange", dateRange)
            }

            // To calculate the time difference of two dates 
            var Difference_In_Time = tDate.getTime() - fDate.getTime();

            // To calculate the no. of days between two dates 
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            console.log("Difference_In_Days", Difference_In_Days);

            if (tDate > fDate) {
                console.log("1st IF")
                if (Difference_In_Days < dateRange) {
                    console.log("2nd IF")
                    if (this.state.fromDate) {
                        urlParams.push({ 'key': 'fromDate', value: this.state.fromDate });
                    }

                    if (this.state.toDate) {
                        urlParams.push({ 'key': 'toDate', value: this.state.toDate });
                    }
                }
                else {
                    this.setState({
                        isError: true,
                        isOpenSnack: true,
                        errorMessage: "Number of days between From Date and To Date should not exceed 90."
                    });
                    console.log("errorMessage", this.state.errorMessage);
                }
            }
            else {
                this.setState({
                    isError: true,
                    isOpenSnack: true,
                    errorMessage: "To date should be the past date of From Date"
                });
                console.log("errorMessage", this.state.errorMessage);
            }
        }

        if (this.state.eventTypeId > 0) {
            urlParams.push({ 'key': 'eventTypeId', value: this.state.eventTypeId });
        }

        if (this.state.eventSubtypeId > 0) {
            urlParams.push({ 'key': 'eventSubtypeId', value: this.state.eventSubtypeId });
        }
        console.log("Filters : " + JSON.stringify(urlParams))
        this.props.onSearchSubmit(urlParams);
    }

    handleMultiSelect(e) {
        const name = e.target.name;
        const value = e.target.value;
        console.log("name is ", name);
        let stateObj = {};
        stateObj[name] = value;
        this.setState(stateObj);
    }
  
      

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleFromDateTimeChange = (dateTime) => {
        console.log("handleFromDateTimeChange", this.state.fromDate);
        this.setState({
            fromDate: dateTime.format("MM-DD-YYYY HH:mm")
            // fromDate: dateTime
        })
        console.log("handleFromDateTimeChange", this.state.fromDate);
    }

    handleToDateTimeChange = (dateTime) => {
        console.log("handleToDateTimeChange", this.state.toDate);
        this.setState({
            toDate: dateTime.format("MM-DD-YYYY HH:mm")
            // toDate: dateTime
        })
        console.log("handleToDateTimeChange", this.state.toDate);
    }

    getJoinedNames(idArray, fieldName) {
        let result = [];

        if (fieldName == "EventType") {
            this.props.arrEventTypeProps.forEach((eventType) => {
                if (idArray.indexOf(eventType.eventTypeId) > -1) {
                    result.push(eventType.eventTypeName);
                }
            });
        }

        if (fieldName == "EventSubtype") {
            this.props.arrEventSubTypeProps.forEach((eventSubtype) => {
                if (idArray.indexOf(eventSubtype.eventSubtypeId) > -1) {
                    result.push(eventSubtype.eventSubtypeName);
                }
            });
        }

        return result.join(', ');
    }
    

    render() {
        const { classes } = this.props;
        console.log("fromDate", this.props.fromDate)
        if (this.state.isError) {
            alert =
                <Snackbar open={this.state.isOpenSnack} autoHideDuration={4000} onClose={() => { this.onAlertClose() }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                    <Alert onClose={() => { this.onAlertClose() }} variant="filled" severity="error" className={classes.alert}>
                        <AlertTitle>Error</AlertTitle>
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
        }

        return (
            <React.Fragment>
                <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit} >

                    <Grid container spacing={2}>
                        <Grid item md={1} xs={12}>                           
                                <Typography variant="h5" gutterBottom >
                                    Search
                                    </Typography>                            
                        </Grid>
                        <Grid item md={2} xs={12} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    id="fromDate"
                                    name="fromDate"
                                    label="From"
                                    format="DD-MM-YYYY HH:mm"
                                    disableFuture={true}
                                    ampm={true}
                                    value={this.state.fromDate}
                                    onChange={(val) => this.handleFromDateTimeChange(val)} 
                                    inputVariant="outlined"/>
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={2} xs={12} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    id="toDate"
                                    name="toDate"
                                    label="To"
                                    ampm={true}
                                    disableFuture={true}
                                    format="DD-MM-YYYY HH:mm"
                                    value={this.state.toDate}
                                    onChange={(val) => this.handleToDateTimeChange(val)} 
                                    inputVariant="outlined"/>
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid item md={2} xs={12}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel>Event Type</InputLabel>
                                <Select
                                    name="eventTypeId"
                                    multiple
                                    value={this.state.eventTypeId}
                                    // input={<Input />}
                                    onChange={(e) => this.handleMultiSelect(e)}
                                    label="Select Event Type"
                                    renderValue={(selected) => this.getJoinedNames(selected, "EventType")}
                                >
                                    {this.props.arrEventTypeProps && this.props.arrEventTypeProps.map((eventType) => {
                                        return (
                                            <MenuItem key={eventType.eventTypeId} value={eventType.eventTypeId}>
                                                <Checkbox checked={this.state.eventTypeId.indexOf(eventType.eventTypeId) > -1} />
                                                <ListItemText primary={eventType.eventTypeName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel variant="outlined">Event Sub Type</InputLabel>
                                <Select
                                    name="eventSubtypeId"
                                    multiple
                                    value={this.state.eventSubtypeId}
                                    // input={<Input />}
                                    onChange={(e) => this.handleMultiSelect(e)}
                                    label="Select Event Sub Type"
                                    renderValue={(selected) => this.getJoinedNames(selected, "EventSubtype")}
                                >
                                    {this.props.arrEventSubTypeProps && this.props.arrEventSubTypeProps.map((eventSubtype) => {
                                        return (
                                            <MenuItem key={eventSubtype.eventSubtypeId} value={eventSubtype.eventSubtypeId}>
                                                <Checkbox checked={this.state.eventSubtypeId.indexOf(eventSubtype.eventSubtypeId) > -1} />
                                                <ListItemText primary={eventSubtype.eventSubtypeName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Button className={classes.button1} variant="contained" color="primary" startIcon={<SearchIcon />} type="submit">
                                Search
                                </Button>
                        </Grid>
                        <Grid item md={1} xs={12}>
                                <Button className={classes.button2} variant="contained" color="primary" startIcon={<RefreshIcon />} type="reset" onClick={this.resetEventAuditFilter}>
                                    Reset
                                </Button>
                            </Grid>

                        {alert}
                    </Grid>
                </form>
            </React.Fragment>
        );
    };
}

//#region Map Functions
const mapStateToProps = state => {
    return {
        arrEventTypeProps: state.eventGeneralDts.eventType,
        arrEventSubTypeProps: state.eventGeneralDts.eventSubType,
        timeValue: state.common.globalConfig.date_filter_range
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEventType: () => dispatch(actions.getEventType()),
        getEventSubType: (state) => dispatch(actions.getEventSubType(state))
    };
};
//#endregion

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventAuditSearchFilters));