import { updateObject } from "../utility";

const initialState = {
    menuItems: []
};

const fetchMenuDataSuccess = ( state, action ) => {
    var updatedState= updateObject( state, {
      menuItems : action.menuData
    });
    return updatedState;
};
const fetchMenuDataFail = ( state, action ) => {
    return updateObject( state, {
        errorMsg : action.errorMsg
    });
};
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'MENU_DATA_FETCH_SUCCESS':
            return fetchMenuDataSuccess(state, action);
            break;
        case 'MENU_DATA_FETCH_FAIL':
            return fetchMenuDataFail(state, action);
        default:
            return state;
    }
};

export default reducer;