import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    loading: null
};

const assetVehicleCatStateReset = (state,action) => {
    return updateObject(state, {loading: null });
};

const assetVehicleCatCreateStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const reducer = (state= initialState,action) => {
    switch (action.type) {        
        case actionTypes.ASSET_VEHICLE_CAT_CREATE_START :
            return assetVehicleCatCreateStart(state,action);
 
        case actionTypes.ASSET_VEHICLE_CAT_STATE_RESET :
            return assetVehicleCatStateReset(state,action);

        default:
            return state;
    }
}

export default reducer;