import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const hotlistVehicleCreateStart = () => {
    return {
        type: actionTypes.HOTLIST_VEHICLE_CREATE_START
    };
};

export const hotlistVehicleCreate = (formValues) => {

    console.log("FormValues",formValues);
    
    return async (dispatch) => {
        try {
            dispatch(hotlistVehicleCreateStart());
            const response = await axios.post(BASE_URL + "/api/hotlistVehicle", formValues);           
            dispatch(showToaster('success','Hotlist Vehicle Successfully Created.'))
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const hotlistVehicleStateReset = () => {
    return {
        type: actionTypes.HOTLIST_VEHICLE_STATE_RESET
    };
};

export const hotlistVehicleEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        hotlistVehicleNumber: formValues.hotlistVehicleNumber,
        vehicleStatus: formValues.vehicleStatus,
        hotlistFromTime: formValues.hotlistFromTime,
        hotlistToTime: formValues.hotlistToTime,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(hotlistVehicleCreateStart());
            const response = await axios.patch(BASE_URL + "/api/hotlistVehicle/" + formValues.hotlistVehicleId, values);
            console.log(response);
            dispatch(showToaster('success','Hotlist Vehicle Successfully Edited.'));

        } catch (error) {
            console.log(error)
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}