import React, { Component } from "react";
import MaterialTable from "material-table";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import Button from '@material-ui/core/Button';


import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EditIcon from '@material-ui/icons/Edit';
import { Input } from '@material-ui/core';
import Toaster from '../../../components/common/Toaster';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const styles = theme => ({
  root: {
    marginTop: '50px',
    clear: 'both'
  },
  sendButton: {
    marginTop: '10px'
  }
});

class EventAlertEmail extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchData({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId });
  }

  componentDidUpdate() {
    if (this.props.emailAlert == null) {
      this.props.fetchData({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId });
    }
  }

  componentWillReceiveProps(newProps, oldProps) {
    if ((newProps.notificationCategoryId != this.state.notificationCategoryId) || (newProps.eventId != this.state.eventId)) {
      this.setState({
        notificationCategoryId: newProps.notificationCategoryId,
        eventId: newProps.eventId
      });
      this.props.fetchData({ eventNotificationCategoryId: newProps.notificationCategoryId, eventId: newProps.eventId });
    }

  }
  reload() {
    this.props.fetchData({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId });

  }

  //sendMessage(rowData) {
  //  console.log('sending...', this.props.stakeholderUsers);
  //  console.log('propsssssssssssss>>>>>>',this.props)
  //  this.props.sendEmail({ eventNotificationCategoryId: this.props.notificationCategoryId, eventId: this.props.eventId, id: this.props.emailAlert.txnEventAlertId });
  //}
  sendMessage() {
    console.log('sending...', this.props.stakeholderUsers);
    const { eventId, eventDescription, notificationCategoryId, smsAlert, stakeholderUsers } = this.props;
  if (stakeholderUsers && stakeholderUsers.length > 0) {
      stakeholderUsers.forEach(stakeholderUserCategory => {
        const { categoryUsers } = stakeholderUserCategory;

        if (categoryUsers && categoryUsers.length > 0) {
          categoryUsers.forEach(user => {
            console.log("user================>", user);
            //const { stakeholderUserMobileNo } = user;

            // Assuming you have an action named `sendManualAlert` that sends an SMS
            this.props.sendEmail({
              eventNotificationCategoryId: this.props.notificationCategoryId, 
              eventId: this.props.eventId, 
              id: this.props.emailAlert.txnEventAlertId, 
              eventNotificationMessage: this.props.emailAlert.message,
              eventNotificationStatus: 1,
              contactDetail: user.stakeholderUserEmailId,
            });
          });
        }
      });
    }
  }

  render() {
    const { classes } = this.props;


    return (
      <div className={classes.root} style={{ maxWidth: "100%" }}>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <div>Email</div>
        <Input fullWidth={true}
          value={this.props.emailAlert ? this.props.emailAlert.message : ''}
          readOnly
        />
        <div><Button className={classes.sendButton} disabled={this.props.eventStatusName!= 'MANAGE' || (this.props.emailAlert ? this.props.emailAlert.eventNotificationStatus == 1 : true)} color="primary" size="large" variant="contained" onClick={() => this.sendMessage()}>{this.props.emailAlert ? (this.props.emailAlert.eventNotificationStatus == 1 ? 'SENT' : 'SEND') : 'SEND'}</Button></div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    emailAlert: state.eventAlerts.emailAlert,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig,
    stakeholderUsers: state.eventGeneralDts.stakeholderUsers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: (url) => dispatch(actions.fetchEmailAlert(url)),
    sendEmail: (emailObj) => dispatch(actions.sendEmail(emailObj))
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(EventAlertEmail));