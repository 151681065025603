import React from 'react';
import SectionAddForm from './sectionAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class SectionEdit extends React.Component {

    componentDidMount() {
        this.props.sectionStateReset();
    }

    onSubmit = formValues => {
        this.props.sectionEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        console.log("Section Edit: InitialValues", this.props.initialValues);

        return (
            <React.Fragment>
                {alert}
                {progress}
                <SectionAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.sectionReducer.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sectionEdit: (formValues) => dispatch(actions.sectionEdit(formValues)),
        sectionStateReset: () => dispatch(actions.sectionStateReset())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SectionEdit));