import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

const equipmentsCreateStart = () => {
    return {
        type: actionTypes.EQUIPMENTS_CREATE_START
    };
};
export const equipmentsStateReset = () => {
    return {
        type: actionTypes.EQUIPMENTS_STATE_RESET
    }
}
export const getEquipmentsCatSuccess = (arrEquipmentsCat) => {
    console.log("arrEquipmentsCat :" + arrEquipmentsCat)
    return {
        type: actionTypes.GET_EQUIPMENTS_CAT_SUCCESS,
        arrEquipmentsCat: arrEquipmentsCat,
    };
};
export const getEquipmentsTypeSuccess = (arrEquipmentsType) => {
    return {
        type: actionTypes.GET_EQUIPMENTS_TYPE_SUCCESS,
        arrEquipmentsType: arrEquipmentsType,
    };
};
export const getEquipmentsGroupSuccess = (arrEquipmentsGroup) => {
    return {
        type: actionTypes.GET_EQUIPMENTS_GROUP_SUCCESS,
        arrEquipmentsGroup: arrEquipmentsGroup,
    };
};
export const getEquipmentsDirSuccess = (arrEquipmentsDir) => {
    return {
        type: actionTypes.GET_EQUIPMENTS_DIR_SUCCESS,
        arrEquipmentsDir: arrEquipmentsDir,
    };
};
export const getEquipmentsChainageSuccess = (arrEquipmentsChainage) => {
    return {
        type: actionTypes.GET_EQUIPMENTS_CHAINAGE_SUCCESS,
        arrEquipmentsChainage: arrEquipmentsChainage,
    };
};
//#region CATEGORY DROPDOWN IN SEARCH

export const getEquipmentsCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/equipmentCategories?reqType=all")
            .then(res => {
                console.log("equipmentCat res.data: ", res.data);
                dispatch(getEquipmentsCatSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :", error.response.data.message);
                dispatch(showToaster("error", error.response.data.message));
            });
    };
}
//#endregion

//#region TYPE DROPDOWN IN SEARCH
export const getEquipmentsType = (catId) => {
    console.log("equipmentTypeId : ", catId);
    return dispatch => {
        axios
            .get(BASE_URL + "/api/equipmentTypes?reqType=all&equipmentCategoryId=" + catId)
            .then(res => {
                console.log("ACTION :", res.data);
                dispatch(getEquipmentsTypeSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :", error.response.data.message);
                dispatch(showToaster("error", error.response.data.message));
            });
    };
}
//#endregion

//#region GROUP DROPDOWN IN SEARCH
export const getEquipmentsGroup = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/equipmentGroups?reqType=all")
            .then(res => {
                console.log("ACTION :", res.data);
                dispatch(getEquipmentsGroupSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :", error.response.data.message);
                dispatch(showToaster("error", error.response.data.message));
            });
    };
}
//#endregion

//#region DIRECTION DROPDOWN IN SEARCH
export const getEquipmentsDir = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/direction?reqType=all")
            .then(res => {
                console.log("getDirection res.data: ", res.data);
                dispatch(getEquipmentsDirSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :", error.response.data.message);
                dispatch(showToaster(error.response.data.message));
            });
    };
}
//#endregion

//#region CHAINAGE DROPDOWN IN SEARCH
export const getEquipmentsChainage = (dirId) => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/chainage?reqType=all&directionId=" + dirId)
            .then(res => {
                console.log("getChainage res.data: ", res.data);
                dispatch(getEquipmentsChainageSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :", error.response.data.message);
                dispatch(showToaster("error", error.response.data.message));
            });
    };
}
//#endregion

/////////////////////////////
export const equipmentsCreate = (formValues, eqState) => {
    const values = {
        directionName: eqState.directionName,
        chainageName: eqState.chainageName,
        chainageId: formValues.chainageId,
        equipmentCategoryId: formValues.equipmentCategoryId,
        directionId: formValues.directionId,
        equipmentName: formValues.equipmentName,
        equipmentGroupId: formValues.equipmentGroupId,
        equipmentTypeId: formValues.equipmentTypeId,
        ipAddress: formValues.ipAddress,
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        unqEqptypeCode: formValues.unqEqptypeCode,
    }
    return async (dispatch) => {
        try {
            dispatch(equipmentsCreateStart());
            const response = await axios.post(BASE_URL + "/api/equipments", values);
            console.log("equipmentsCreate res: ", response)
            dispatch(showToaster('success', 'New Equipment Added Successfully'));
        } catch (error) {
            dispatch(showToaster("error", error.response.data.message));
        }
    };
}

export const equipmentsEdit = (formValues, eqState) => {
    console.log("state in edit: ", eqState);
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }
    let values = {
        equipmentCategoryId: formValues.equipmentCategoryId,
        equipmentTypeId: formValues.equipmentTypeId,
        equipmentGroupId: formValues.equipmentGroupId,
        directionId: eqState.directionId,
        chainageId: eqState.chainageId,
        directionName: eqState.directionName,
        chainageName: eqState.chainageName,
        equipmentName: formValues.equipmentName,
        unqEqptypeCode: formValues.unqEqptypeCode,
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        ipAddress: formValues.ipAddress,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(equipmentsCreateStart());
            const response = await axios.patch(BASE_URL + "/api/equipments/" + formValues.equipmentId, values);
            console.log("equipmentsEdit response: ", response);
            dispatch(showToaster("success", "Equipment Successfully Edited"));
        } catch (error) {
            dispatch(showToaster("error", error.response.data.message));
        }
    };
}