import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

//#region INITIAL STATE
const initialState = {
    arrLocationDtlCat: [],
    arrLocationDtlType: [],
    arrLocationDtlDir: [],
    arrLocationDtlChainage: [],
    loading: false,
};
//#endregion

//#region FUNCTIONS
const locationDetailsStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const locationDetailsCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const locationDetailsCat = (state, action) => {
    var updatedState = updateObject(state, {
        arrLocationDtlCat: action.arrLocationDtlCat
    });
    return updatedState;
};

const locationDetailsType = (state, action) => {
    var updatedState = updateObject(state, {
        arrLocationDtlType: action.arrLocationDtlType
    });
    return updatedState;
};

const locationDetailsDir = (state, action) => {
    var updatedState = updateObject(state, {
        arrLocationDtlDir: action.arrLocationDtlDir
    });
    return updatedState;
};

const locationDetailsChainage = (state, action) => {
    var updatedState = updateObject(state, {
        arrLocationDtlChainage: action.arrLocationDtlChainage
    });
    return updatedState;
};
//#endregion

//#region CASES
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_DETAILS_STATE_RESET:
            return locationDetailsStateReset(state, action);
        case actionTypes.LOCATION_DETAILS_CREATE_START:
            return locationDetailsCreateStart(state, action);

        case actionTypes.GET_LOCATION_DETAILS_CAT_SUCCESS:
            return locationDetailsCat(state, action);
        case actionTypes.GET_LOCATION_DETAILS_TYPE_SUCCESS:
            return locationDetailsType(state, action);
        case actionTypes.GET_LOCATION_DETAILS_DIR_SUCCESS:
            return locationDetailsDir(state, action);
        case actionTypes.GET_LOCATION_DETAILS_CHAINAGE_SUCCESS:
            return locationDetailsChainage(state, action);
        default:
            return state;
    }
}
//#endregion

export default reducer;