//Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const SIGN_OUT = 'SIGN_OUT';

//common
export const GLOBAL_CONFIG_SUCCESS = 'GLOBAL_CONFIG_SUCCESS';
export const GLOBAL_CONFIG_FAIL = 'GLOBAL_CONFIG_FAIL';
export const TOASTER_ON = 'TOASTER_ON';
export const TOASTER_OFF = 'TOASTER_OFF';
export const DRAWER_CLOSE = 'DRAWER_CLOSE';
export const DRAWER_OPEN = 'DRAWER_OPEN';
export const GET_ENTITLEMENTS_SUCCESS = 'GET_ENTITLEMENTS_SUCCESS'

// User Details
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
export const GET_USER_STATUS_SUCCESS = 'GET_USER_STATUS_SUCCESS';
export const GET_USER_SHIFT_SUCCESS = 'GET_USER_SHIFT_SUCCESS';
export const USER_DTLS_STATE_RESET = "USER_DTLS_STATE_RESET";
export const USER_DTLS_CREATE_START = 'USER_DTLS_CREATE_START';

//User Role
export const USER_ROLE_SUCCESS = 'USER_ROLE_SUCCESS';
export const USER_ROLE_ADD_SUCCESS = 'USER_ROLE_ADD_SUCCESS';
export const USER_ROLE_ADD_FAIL = 'USER_ROLE_ADD_FAIL';
export const USER_ROLE_STATE_RESET = 'USER_ROLE_STATE_RESET';
export const USER_ROLE_CREATE_START = 'USER_ROLE_CREATE_START';

//Equipment Type
export const GET_EQUIP_CAT_SUCCESS = 'GET_EQUIP_CAT_SUCCESS';
export const EQUIP_TYPE_SUCCESS = 'EQUIP_TYPE_SUCCESS';
export const EQUIP_TYPE_ADD_SUCCESS = 'EQUIP_TYPE_ADD_SUCCESS';
export const EQUIP_TYPE_ADD_FAIL = 'EQUIP_TYPE_ADD_FAIL';
export const EQUIP_TYPE_STATE_RESET = 'EQUIP_TYPE_STATE_RESET';
export const EQUIP_TYPE_CREATE_START = 'EQUIP_TYPE_CREATE_START';

//Equipment Category
export const EQUIP_CATEGORY_STATE_RESET = 'EQUIP_CATEGORY_STATE_RESET';
export const EQUIP_CATEGORY_CREATE_START = 'EQUIP_CATEGORY_CREATE_START';

//Equipment Group
export const EQUIP_GROUP_STATE_RESET = 'EQUIP_GROUP_STATE_RESET';
export const EQUIP_GROUP_CREATE_START = 'EQUIP_GROUP_CREATE_START';

//Location Type
export const GET_LOCATION_CAT_SUCCESS = 'GET_LOCATION_CAT_SUCCESS';
export const GET_IMAGE_PATH_SUCCESS = 'GET_IMAGE_PATH_SUCCESS';
export const LOCATION_TYPE_STATE_RESET = "LOCATION_TYPE_STATE_RESET";
export const LOCATION_TYPE_CREATE_START = 'LOCATION_TYPE_CREATE_START';

//Location Cat
export const LOCATION_CATEGORY_MODAL_OPEN = 'LOCATION_CATEGORY_MODAL_OPEN';
export const LOCATION_CATEGORY_STATE_RESET = "LOCATION_CATEGORY_STATE_RESET";
export const LOCATION_CATEGORY_CREATE_START = 'LOCATION_CATEGORY_CREATE_START';
export const LOCATION_CATEGORY_CREATED = 'LOCATION_CATEGORY_CREATED';
export const LOCATION_CATEGORY_FAILURE = 'LOCATION_CATEGORY_FAILURE';

//Location Details
// export const LOCATION_DETAILS_MODAL_OPEN = 'LOCATION_DETAILS_MODAL_OPEN';
export const LOCATION_DETAILS_STATE_RESET = 'LOCATION_DETAILS_STATE_RESET';
export const LOCATION_DETAILS_CREATE_START = 'LOCATION_DETAILS_CREATE_START';
// export const LOCATION_DETAILS_CREATED = 'LOCATION_DETAILS_CREATED';
// export const LOCATION_DETAILS_FAILURE = 'LOCATION_DETAILS_FAILURE';
export const GET_LOCATION_DETAILS_CAT_SUCCESS = 'GET_LOCATION_DETAILS_CAT_SUCCESS';
export const GET_LOCATION_DETAILS_TYPE_SUCCESS = 'GET_LOCATION_DETAILS_TYPE_SUCCESS';
export const GET_LOCATION_DETAILS_DIR_SUCCESS = 'GET_LOCATION_DETAILS_DIR_SUCCESS';
export const GET_LOCATION_DETAILS_CHAINAGE_SUCCESS = 'GET_LOCATION_DETAILS_CHAINAGE_SUCCESS'

//Event General Details
export const GET_NOTIFICATION_SOURCE_SUCCESS = 'GET_NOTIFICATION_SOURCE_SUCCESS';
export const GET_EVENT_STATUS_SUCCESS = 'GET_EVENT_STATUS_SUCCESS';
export const GET_EVENT_TYPE_SUCCESS = 'GET_EVENT_TYPE_SUCCESS';
export const GET_EVENT_SEVERITY_SUCCESS = 'GET_EVENT_SEVERITY_SUCCESS';
export const GET_EQUIPMENTS_SUCCESS = 'GET_EQUIPMENTS_SUCCESS';
export const GET_EVENTSUBTYPE_SUCESS = 'GET_EVENTSUBTYPE_SUCESS';
export const GET_EVENTREASON_SUCCESS = 'GET_EVENTREASON_SUCCESS';
export const GET_STAKEHOLDERUSER_SUCCESS = 'GET_STAKEHOLDERUSER_SUCCESS';
export const GET_EVENT_ADDITIONAL_INFO_SUCCESS = 'GET_EVENT_ADDITIONAL_INFO_SUCCESS';
export const GET_EVENT_ACCIDENT_VEHICLE_INFO = 'GET_EVENT_ACCIDENT_VEHICLE_INFO';
export const GET_EVENT_FILES_SUCCESS = 'GET_EVENT_FILES_SUCCESS';
export const ADD_EVENT_FILE = 'ADD_EVENT_FILE';
export const GET_EVENT_GENERAL_DETAILS_FAIL = "GET_EVENT_GENERAL_DETAILS_FAIL";
export const LOADING_START = 'LOADING_START';

//Events
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const CHANGE_EVENT_STATUS_SUCCESS = 'CHANGE_EVENT_STATUS_SUCCESS';
export const EVENT_TIMELINE_SUCCESS = 'EVENT_TIMELINE_SUCCESS';

//Event Information
export const EVENT_INFORMATION_CRATED = 'EVENT_INFORMATION_CRATED';
export const LOADING_START_EVENTINFO = 'LOADING_START_EVENTINFO';

// Event SOP
export const GET_LOCATION_TYPES_SUCCESS = 'GET_LOCATION_TYPES_SUCCESS';
export const GET_LOCATION_DETAILS_SUCCESS = 'GET_LOCATION_DETAILS_SUCCESS';
export const GET_EVENT_SOP_SUCCESS = 'GET_EVENT_SOP_SUCCESS';
export const GET_SELECTED_EVENT_SOP_SUCCESS = 'GET_SELECTED_EVENT_SOP_SUCCESS';
export const UPDATE_EVENT_SOP_SUCCESS = 'UPDATE_EVENT_SOP_SUCCESS';

//Equipments
export const EQUIPMENTS_FAILURE = 'EQUIPMENTS_FAILURE';
// export const EQUIPMENTS_ADD_SUCCESS = 'EQUIPMENTS_ADD_SUCCESS';
// export const EQUIPMENTS_MODAL_OPEN = 'EQUIPMENTS_MODAL_OPEN';
export const EQUIPMENTS_STATE_RESET = 'EQUIPMENTS_STATE_RESET';
export const EQUIPMENTS_CREATE_START = 'EQUIPMENTS_CREATE_START';
export const EQUIPMENTS_CREATED = 'EQUIPMENTS_CREATED';
export const GET_EQUIPMENTS_CAT_SUCCESS = 'GET_EQUIPMENTS_CAT_SUCCESS';
export const GET_EQUIPMENTS_TYPE_SUCCESS = 'GET_EQUIPMENTS_TYPE_SUCCESS';
export const GET_EQUIPMENTS_GROUP_SUCCESS = 'GET_EQUIPMENTS_GROUP_SUCCESS';
export const GET_EQUIPMENTS_DIR_SUCCESS = 'GET_EQUIPMENTS_DIR_SUCCESS';
export const GET_EQUIPMENTS_CHAINAGE_SUCCESS = 'GET_EQUIPMENTS_CHAINAGE_SUCCESS';

// Maps
export const GET_EQUIPMENTS_ON_MAPS = 'GET_EQUIPMENTS_ON_MAPS';
export const GET_EQUIPMENT_CATEGORIES = 'GET_EQUIPMENT_CATEGORIES';
export const GET_EVENTS_ON_MAPS = 'GET_EVENTS_ON_MAPS';

//Violation
export const VIOLATION_MODAL_OPEN = 'VIOLATION_MODAL_OPEN';
export const VIOLATION_STATE_RESET = "VIOLATION_STATE_RESET";
export const VIOLATION_CREATE_START = 'VIOLATION_CREATE_START';
//export const VIOLATION_CREATED = 'VIOLATION_CREATED';
export const VIOLATION_FAILURE = 'VIOLATION_FAILURE';

// Audit Violation Common API
export const UPDATE_DIRECTION_AV = 'UPDATE_DIRECTION_AV';
export const UPDATE_CHAINAGE_AV = 'UPDATE_CHAINAGE_AV';
export const UPDATE_VEHICLECLASS_AV = 'UPDATE_VEHICLECLASS_AV';
export const UPDATE_SECTIONS_AV = "UPDATE_SECTIONS_AV"
export const UPDATE_VIOLATION_STATUS_AV = "UPDATE_VIOLATION_STATUS_AV"

export const VMS_ALERTS_SUCCESS = 'VMS_ALERTS_SUCCESS'
export const VMS_SEND_MESSAGE_SUCCESS = 'VMS_SEND_MESSAGE_SUCCESS'
export const SMS_ALERT_SUCCESS = 'SMS_ALERT_SUCCESS'
export const SMS_SEND_SUCCESS = 'SMS_SEND_SUCCESS'
export const EMAIL_ALERT_SUCCESS = 'EMAIL_ALERT_SUCCESS'
export const EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS'
export const WEB_ALERT_SUCCESS = 'WEB_ALERT_SUCCESS'
export const RECENT_WEB_ALERTS_SUCCESS = 'RECENT_WEB_ALERTS_SUCCESS'
export const VEHICLE_AID_ASSIGN_SUCCESS = 'VEHICLE_AID_ASSIGN_SUCCESS'
export const SEND_MANUAL_ALERT_SUCCESS = 'SEND_MANUAL_ALERT_SUCCESS'
export const ALL_SENT_ALERTS_SUCCESS = 'ALL_SENT_ALERTS_SUCCESS';
export const AVAILABLE_VEHICLES_SUCCESS = 'AVAILABLE_VEHICLES_SUCCESS';
export const SEND_ALL_ALERTS_VEHICLES_SUCCESS = 'SEND_ALL_ALERTS_VEHICLES_SUCCESS';
export const SEND_ALL_ALERTS_VEHICLES_FAILED = 'SEND_ALL_ALERTS_VEHICLES_FAILED';
export const NOTIFICATION_ALERTS_SUCCESS = 'NOTIFICATION_ALERTS_SUCCESS';
export const RESET_ALERTS_SENT_INDICATOR = 'RESET_ALERTS_SENT_INDICATOR';
export const MANUAL_ALERT_LOADING_START = 'MANUAL_ALERT_LOADING_START';

//Speed Data
export const GET_AUDIT_DIRECTION_SUCCESS = 'GET_AUDIT_DIRECTION_SUCCESS';
export const GET_AUDIT_CHAINAGE_SUCCESS = 'GET_AUDIT_CHAINAGE_SUCCESS';
export const GET_AUDIT_EQUIPMENT_SUCCESS = 'GET_AUDIT_EQUIPMENT_SUCCESS';
export const GET_AUDIT_VEHTYPE_SUCCESS = 'GET_AUDIT_VEHTYPE_SUCCESS';
export const AUDIT_FAILURE = 'AUDIT_FAILURE';
export const SPEED_AUDIT_CREATED = 'SPEED_AUDIT_CREATED';
export const SPEED_AUDIT_STATE_RESET = 'SPEED_AUDIT_STATE_RESET'

//Shift 
export const SHIFT_STATE_RESET = 'SHIFT_STATE_RESET';
export const SHIFT_CREATE_START = 'SHIFT_CREATE_START';

//AssetVehicleCat
export const ASSET_VEHICLE_CAT_STATE_RESET = 'ASSET_VEHICLE_CAT_STATE_RESET';
export const ASSET_VEHICLE_CAT_CREATE_START = 'ASSET_VEHICLE_CAT_CREATE_START';

//AssetVehicleDetails
export const ASSET_VEHICLE_DETAILS_STATE_RESET = 'ASSET_VEHICLE_DETAILS_STATE_RESET';
export const ASSET_VEHICLE_DETAILS_CREATE_START = 'ASSET_VEHICLE_DETAILS_CREATE_START';
export const GET_ASSET_VEHICLE_CAT_SUCCESS = 'GET_ASSET_VEHICLE_CAT_SUCCESS';

//EventType
export const EVENT_TYPE_STATE_RESET = 'EVENT_TYPE_STATE_RESET';
export const EVENT_TYPE_CREATE_START = 'EVENT_TYPE_CREATE_START';

//EventSubtype
export const EVENT_SUBTYPE_STATE_RESET = 'EVENT_SUBTYPE_STATE_RESET';
export const EVENT_SUBTYPE_CREATE_START = 'EVENT_SUBTYPE_CREATE_START';
export const GET_EVENT_TYPES_FOR_EVENT_SUBTYPE_SUCCESS = 'GET_EVENT_TYPES_FOR_EVENT_SUBTYPE_SUCCESS';
export const GET_EVENT_SEVERITY_FOR_EVENT_SUBTYPE_SUCCESS = 'GET_EVENT_SEVERITY_FOR_EVENT_SUBTYPE_SUCCESS';

//SOPCat
export const SOP_CAT_STATE_RESET = 'SOP_CAT_STATE_RESET';
export const SOP_CAT_CREATE_START = 'SOP_CAT_CREATE_START';

//SOPItem
export const SOP_ITEM_STATE_RESET = 'SOP_ITEM_STATE_RESET';
export const SOP_ITEM_CREATE_START = 'SOP_ITEM_CREATE_START';
export const GET_SOP_CAT_SUCCESS = 'GET_SOP_CAT_SUCCESS';

//HotlistVehicle
export const HOTLIST_VEHICLE_STATE_RESET = 'HOTLIST_VEHICLE_STATE_RESET';
export const HOTLIST_VEHICLE_CREATE_START = 'HOTLIST_VEHICLE_CREATE_START';

//Chainage
export const CHAINAGE_STATE_RESET = 'CHAINAGE_STATE_RESET';
export const CHAINAGE_CREATE_START = 'CHAINAGE_CREATE_START';
export const GET_DIRECTION_SUCCESS = 'GET_DIRECTION_SUCCESS';

//Section
export const SECTION_STATE_RESET = 'SECTION_STATE_RESET';
export const SECTION_CREATE_START = 'SECTION_CREATE_START';
export const GET_CHAINAGE_SUCCESS = 'GET_CHAINAGE_SUCCESS';
export const GET_DIRECTION_FOR_SECTION_SUCCESS = 'GET_DIRECTION_FOR_SECTION_SUCCESS';

//StakeholderCat
export const STAKEHOLDER_CAT_SUCCESS = 'STAKEHOLDER_CAT_SUCCESS';
export const STAKEHOLDER_CAT_ADD_SUCCESS = 'STAKEHOLDER_CAT_ADD_SUCCESS';
export const STAKEHOLDER_CAT_ADD_FAIL = 'STAKEHOLDER_CAT_ADD_FAIL';
export const STAKEHOLDER_CAT_STATE_RESET = 'STAKEHOLDER_CAT_STATE_RESET';
export const STAKEHOLDER_CAT_CREATE_START = 'STAKEHOLDER_CAT_CREATE_START';

//StakeholderUser
export const STAKEHOLDER_USER_SUCCESS = 'STAKEHOLDER_USER_SUCCESS';
export const STAKEHOLDER_USER_ADD_SUCCESS = 'STAKEHOLDER_USER_ADD_SUCCESS';
export const STAKEHOLDER_USER_ADD_FAIL = 'STAKEHOLDER_USER_ADD_FAIL';
export const STAKEHOLDER_USER_STATE_RESET = 'STAKEHOLDER_USER_STATE_RESET';
export const STAKEHOLDER_USER_CREATE_START = 'STAKEHOLDER_USER_CREATE_START';
export const GET_STAKEHOLDER_CAT_SUCCESS = 'GET_STAKEHOLDER_CAT_SUCCESS';
export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS';

//Menu
export const MENU_STATE_RESET = 'MENU_STATE_RESET';
export const MENU_CREATE_START = 'MENU_CREATE_START';
export const GET_PARENT_MENU_SUCCESS = 'GET_PARENT_MENU_SUCCESS';

//Notification Category
export const NOTIFICATION_CAT_STATE_RESET = 'NOTIFICATION_CAT_STATE_RESET';
export const NOTIFICATION_CAT_CREATE_START = 'NOTIFICATION_CAT_CREATE_START';

//Notification Template
export const NOTIFICATION_TEMPLATE_STATE_RESET = 'NOTIFICATION_TEMPLATE_STATE_RESET';
export const NOTIFICATION_TEMPLATE_CREATE_START = 'NOTIFICATION_TEMPLATE_CREATE_START';
export const GET_NOTIFICATION_CAT_SUCCESS = 'GET_NOTIFICATION_CAT_SUCCESS';

//Event Rule
export const EVENT_RULE_STATE_RESET = 'EVENT_RULE_STATE_RESET';
export const EVENT_RULE_CREATE_START = 'EVENT_RULE_CREATE_START';
export const GET_EVENT_TYPES_FOR_EVENT_RULE_SUCCESS = 'GET_EVENT_TYPES_FOR_EVENT_RULE_SUCCESS';
export const GET_EVENT_SUBTYPE_FOR_EVENT_RULE_SUCCESS = 'GET_EVENT_SUBTYPE_FOR_EVENT_RULE_SUCCESS';
export const GET_THRESHOLD_FOR_EVENT_RULE_SUCCESS = 'GET_THRESHOLD_FOR_EVENT_RULE_SUCCESS';
export const GET_ARITHMETIC_OPERATION_FOR_EVENT_RULE_SUCCESS = 'GET_ARITHMETIC_OPERATION_FOR_EVENT_RULE_SUCCESS';


//Directions

// export const DIRECTION_ADD_SUCCESS = 'DIRECTION_ADD_SUCCESS';
// export const DIRECTION_ADD_FAIL = 'DIRECTION_ADD_FAIL';
export const DIRECTION_STATE_RESET = 'DIRECTION_STATE_RESET';
export const DIRECTION_CREATE_START = 'DIRECTION_CREATE_START';

export const GET_ECB_TYPE_SUCCESS = 'GET_ECB_TYPE_SUCCESS';
export const GET_ECB_CALL_TYPE_SUCCESS = 'GET_ECB_CALL_TYPE_SUCCESS';
export const GET_ECB_CALL_STATUS_SUCCESS = 'GET_ECB_CALL_STATUS_SUCCESS';
export const GET_ECB_NAME_SUCCESS = 'GET_ECB_NAME_SUCCESS';
export const ECB_DIRECTION_SUCCESS = 'ECB_DIRECTION_SUCCESS';
export const ECB_CHAINAGE_SUCCESS = 'ECB_CHAINAGE_SUCCESS';
export const ECB_CREATE_SUCCESS = 'ECB_CREATE_SUCCESS';
export const ECB_EDIT_SUCCESS = 'ECB_EDIT_SUCCESS';
export const ECB_STATE_RESET = 'ECB_STATE_RESET';

export const SOP_MAP_GET_EVENT_TYPE = 'SOP_MAP_GET_EVENT_TYPE';
export const SOP_MAP_GET_EVENT_SUBTYPE = 'SOP_MAP_GET_EVENT_SUBTYPE';
export const SOP_MAP_STATE_RESET = 'SOP_MAP_STATE_RESET';
export const GET_SOP_CATEGORY = 'SOP_MAP_GET_CATEGORY';
export const GET_SOP_ITEM = 'GET_SOP_ITEM';
export const SOP_MAP_CREATE_START = 'SOP_MAP_CREATE_START';
export const SOP_MAP_ERROR = 'SOP_MAP_ERROR';
