import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class SectionAddForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fromChainageName: "",
            toChainageName: "",
            directionName: "",
            directionId: "",
            fromChainageId: "",
            toChainageId: ""
        }
    }

    componentDidMount() {
        if (this.props.initialValues) {
            this.setState({
                directionId: this.props.initialValues.directionId
            })
        }
        this.props.getDirectionForSection();
        this.props.getChainage(this.state.directionId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.directionId !== prevState.directionId) {
            this.props.getChainage(this.state.directionId);
        }
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    onDropdownChange = (event, newValue, previousValue, name) => {

        let chainageData = this.props.ArrChainage[newValue - 1];
        let directionData = this.props.ArrDirection[newValue - 1];

        // console.log("Directiion name", directionData);
        // console.log("Chainage Data", chainageData);

        if (chainageData && name == "fromChainage") {
            this.setState({
                fromChainageName: chainageData.chainageName
            })
        }
        console.log("fromChainageName", this.state.fromChainageName);

        if (chainageData && name == "toChainage") {
            this.setState({
                toChainageName: chainageData.chainageName
            })
        }
        console.log("toChainageName", this.state.toChainageName);

        if (directionData && name =="directionId") {
            this.setState({
                directionName: directionData.directionName,
                directionId: directionData.directionId
            })
        }
    }

    onSubmit = (values) => {
        console.log("this.props.initialValues>>>>>>>>",this.props.initialValues);
        console.log("this.state.fromChainageName>>>>>>",this.state.fromChainageName);
        if (this.props.initialValues && this.props.initialValues.fromChainageName && (this.state.fromChainageName == '')) {
            // console.log("fromChainage name IF", this.props.initialValues.fromChainageName);
            values["fromChainageName"] = this.props.initialValues.fromChainageName
        }
        else if (this.state.fromChainageName) {
            // console.log("fromChainage name IF", this.state.fromChainageName);
            values["fromChainageName"] = this.state.fromChainageName;
        }

        if (this.props.initialValues && this.props.initialValues.toChainageName && (this.state.toChainageName == '')) {
            // console.log("toChainageName name IF", this.props.initialValues.toChainageName);
            values["toChainageName"] = this.props.initialValues.toChainageName
        }
        else if (this.state.toChainageName) {
            values["toChainageName"] = this.state.toChainageName;
        }

        if (this.props.initialValues && this.props.initialValues.directionName && (this.state.directionName == '')) {
            // console.log("directionName name IF", this.props.initialValues.directionName);
            values["directionName"] = this.props.initialValues.directionName
        }
        else if (this.state.directionName) {
            values["directionName"] = this.state.directionName;
        }

        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
        console.log("Values onSubmit", values);
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;
        console.log("directionId: ", this.state.directionId)
        console.log("FROMcHANINAGE: ", this.state.fromChainageName)
        console.log("toChainage: ", this.state.toChainageName)

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Section"
        }

        if (this.props.type === "Edit") {
            console.log("initialValues: ", this.props.initialValues)
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Section"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Section"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="sectionName" id="sectionName" component={reduxFormFunctions.renderTextField} label="Section Name" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionId" component={reduxFormFunctions.renderSelectField} onChange={this.onDropdownChange} label="Select Direction" id="directionId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrDirection.map((directionDropDown) => <option key={directionDropDown.directionId} value={directionDropDown.directionId}>{directionDropDown.directionName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="fromChainage" component={reduxFormFunctions.renderSelectField} onChange={this.onDropdownChange} label="Select From Chainage" id="fromChainageId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrChainage.map((chainageDropDown) => <option key={chainageDropDown.chainageId} value={chainageDropDown.chainageId}>{chainageDropDown.chainageName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="toChainage" component={reduxFormFunctions.renderSelectField} onChange={this.onDropdownChange} label="Select To Chainage" id="toChainageId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrChainage.map((chainageDropDown) => <option key={chainageDropDown.chainageId} value={chainageDropDown.chainageId}>{chainageDropDown.chainageName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="sectionDistance" id="sectionDistance" component={reduxFormFunctions.renderTextField} label="Section Distance" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="sectionCrossingThresholdTime" id="sectionCrossingThresholdTime" component={reduxFormFunctions.renderTextField} label="Section Crossing Threshold Time" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const re = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;

    if (!formValues.fromChainageId) {
        error.fromChainageId = "Select From Chainage";
    }
    if (formValues.fromChainageId) {
        if (formValues.fromChainageId == "") {
            error.fromChainageId = "Select From Chainage";
        }
    }
    if (!formValues.toChainageId) {
        error.toChainageId = "Select To Chainage";
    }
    if (formValues.toChainageId) {
        if (formValues.toChainageId == "") {
            error.toChainageId = "Select To Chainage";
        }
    }
    if (!formValues.directionId) {
        error.directionId = "Select Direction";
    }
    if (formValues.directionId) {
        if (formValues.directionId == "") {
            error.directionId = "Select Direction";
        }
    }

    if (!formValues.sectionName) {
        error.sectionName = "Enter Section Name";
    }

    if (!formValues.sectionDistance) {
        error.sectionDistance = "Enter Section Distance";
    }

    if (!formValues.sectionCrossingThresholdTime) {
        error.sectionCrossingThresholdTime = "Enter Section Crossing Threshold Time";
    }

    if (formValues.sectionDistance) {
        if (!re.test(formValues.sectionDistance)) {
            error.sectionDistance = "Enter only numbers";
        }
    }

    if (formValues.sectionCrossingThresholdTime) {
        if (!re.test(formValues.sectionCrossingThresholdTime)) {
            error.sectionCrossingThresholdTime = "Enter only numbers";
        }
    }

    return error;
};

const mapStateToProps = state => {
    return {
        ArrChainage: state.sectionReducer.ArrChainage,
        ArrDirection: state.sectionReducer.ArrDirection
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDirectionForSection: () => dispatch(actions.getDirectionForSection()),
        getChainage: (dirId) => dispatch(actions.getChainage(dirId))
    }
}

SectionAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionAddForm)


export default reduxForm({
    validate: validate,
    form: 'ChainageAddForm',
    // initialValues: {directionId: 0}
})(withStyles(styles)(SectionAddForm));