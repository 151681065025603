import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    ArrNotificationCat: [],    
    loading: ''
};

const getNotificationCatSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrNotificationCat: action.arrNotificationCat
    });
    return updatedState;
}

const notificationTemplateStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const notificationTemplateCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATION_CAT_SUCCESS:
            return getNotificationCatSuccess(state, action)

        case actionTypes.NOTIFICATION_TEMPLATE_CREATE_START:
            return notificationTemplateCreateStart(state, action);

        case actionTypes.NOTIFICATION_TEMPLATE_STATE_RESET:
            return notificationTemplateStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;