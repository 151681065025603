import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Select, MenuItem, FormControl, Modal, Divider, InputLabel } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TrendsForMetEquipments from './trendsForMetEquipments';
import IconButton from '@material-ui/core/IconButton';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px'
  },
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  text2: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(12)
  },
  selectFormControl: {
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(3),
  },
  gridData: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(5),
    
  },
  gridList: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1),
    // width: 605,
    padding: '2px'
  },
  paper: {
    width: theme.spacing(193),
    height: theme.spacing(90),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  modal: {
    position: 'absolute',
  }

});

class MetChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      chainageDropdownData: [],     // store Chainage dropdown data
      equipmentIdForTrends: 0,      // Equipment Id for Trends
      equipmentId: 0,               // Equipment Id
      modalOpen: false,             // Set Modal close for Trends
      metSummaryData: [],           // MET Equipment Data       
    }
  }

  async componentDidMount() {
    try {
      const res = await axios.get(BASE_URL + "/api/metEquipmentForDashboard");      // GET call to fetch Chainage Dropdown Data
      if (res.data != null) {
        this.setState({
          chainageDropdownData: res.data
        })
        console.log("chainage dropdown data", this.state.chainageDropdownData);
      }

      let firstEquipmentId = res.data[0];       // Store data of first Chainage
      let equipID = '';

      console.log("firstEquipmentId data", firstEquipmentId);

      if (firstEquipmentId) {
        equipID = firstEquipmentId.equipmentId;        // Get Equipment Id of first Chainage
      }

      console.log("1st equipment id", equipID);

      this.setState({
        equipmentId: equipID,
        equipmentIdForTrends: equipID
      })

      const metData = await axios.get(BASE_URL + "/api/dashboard/metSummaryToday?EQUIPMENT_ID=" + equipID);   // GET call to fetch MET Equipment Data of Equipment at First Chainage
      if (metData.data != null) {
        this.setState({
          metSummaryData: metData.data.data
        })
        console.log("metSummaryData", this.state.metSummaryData);
      }
    }
    catch (error) {
      console.log("error");
    }
  }

  handleChainageChange = async (event) => {       // Handle Chainage dropdown change and call for MET equipment data based on selected Chainage
    const name = event.target.name;
    const value = event.target.value;
    console.log("handleChainageChange", name, value);

    this.setState({
      equipmentId: value,
      equipmentIdForTrends: value
    });

    try {
      const metData = await axios.get(BASE_URL + "/api/dashboard/metSummaryToday?EQUIPMENT_ID=" + value);     // GET call to fetch MET equipment data based on selected Chainage
      if (metData.data != null) {
        this.setState({
          metSummaryData: metData.data.data
        })
        console.log("metSummaryData", this.state.metSummaryData);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  showTrendCharts = () => {       // Function to open Modal for MET Trends
    this.setState({
      modalOpen: true
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.div}>
          <Typography className={classes.text} variant="h6" align="left">Met</Typography>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel>Chainage Name</InputLabel>
            <Select
              id="equipmentId"
              name="equipmentId"
              value={this.state.equipmentId}
              onChange={this.handleChainageChange}
              label="Chainage Name"
              maxMenuHeight="10"
            >
              {this.state.chainageDropdownData && this.state.chainageDropdownData.map((chainage) => {
                return (
                  <MenuItem value={chainage.equipmentId}>{chainage.chainageName}</MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <span id="grid">
            <IconButton color="primary" onClick={() => this.showTrendCharts()}>
              <ShowChartIcon />
            </IconButton>

          </span>
          <Modal
            className={classes.modal}
            fullWidth
            open={this.state.modalOpen}
            onClose={() => this.setState({ modalOpen: false })}>

            <TrendsForMetEquipments equipmentId={this.state.equipmentIdForTrends} onClose={() => this.setState({ modalOpen: false })}></TrendsForMetEquipments>

          </Modal>
        </div>
        <Divider />
        <div>
          {this.state.equipmentId === 0 ?
            (
              <Typography className={classes.text2} variant="h6" align="center">Select Chainage</Typography>
            ) :
            (
              <GridList className={classes.gridList} cols={5} cellHeight={250}>
                {this.state.metSummaryData.map((metData, index) => {
                  return (
                    <GridListTile key={index}>
                      <div className={classes.gridData}>
                        <FontAwesomeIcon title={metData.label} color="#002884" icon={['fas', metData.icon]} size="2x" />
                        <h4>{metData.value}</h4>
                        <h5>{metData.label}</h5>
                      </div>
                    </GridListTile>
                  )
                })
                }
              </GridList>
            )
          }
        </div>
      </React.Fragment >
    )
  }
}

export default withStyles(styles)(MetChart)