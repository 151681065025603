import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';
import _ from 'lodash';

const initialState = {
    eventData : {},
    addEventInfo: {},
    eventTimeline:[]
};

const setSelectedEvent = ( state, action ) => {
    return updateObject( state, {
        eventData: action.eventData
    });
};

const getAddtionalInfoSuccess = ( state, action) => {
    let addEventInfoNew = _.pick(action.additionalInfo, ['noOfCasualties', 'noOfVehicles', 'policeFirNo', 'txnEventAdditionalInfoId', 'informationDescription']);
    var updatedState = updateObject(state, {
        addEventInfo : {...state.addEventInfo ,...addEventInfoNew }
    });
    return updatedState;
}

const getEventAccidentVehicleInfo = (state, action) => {
    // let addEventInfo = pick(action.additionalInfo, ['noOfCasualties', 'noOfVehicles', 'policeFirNo']);
    var vehicleInfoNew = _.map(action.vehicleInfo, _.partialRight(_.pick, ['eventAccidentVehicleInformationId', 'vehicleMakeModel', 'vehicleNumberPlate', 'typeOfVehicle']));
    var updatedState = updateObject(state, {
         addEventInfo : {...state.addEventInfo ,...{ vehicleInfo: vehicleInfoNew} }
    });
    return updatedState;
}

const getEventFiles = (state, action) => {
    var eventFilesNew = _.map(action.eventFiles, _.partialRight(_.pick, ['eventFileName', 'eventFileExtension', 'eventFilePath', 'eventFileImageUrl', 'txnEventFilesId']));
    var updatedState = updateObject(state, {
         addEventInfo : {...state.addEventInfo ,...{ eventFiles: eventFilesNew} }
    });
    return updatedState;
}

const addEventFile = (state, action) => {
    console.log(action);
    state.eventFiles.push({});
    // var updatedState = updateObject(state, {
    //     addEventInfo : {...state.addEventInfo ,...{ eventFiles: eventFilesNew} }
    // });
    return state;
}
const setEventTimeline = ( state, action ) => {
  return updateObject( state, {
      eventTimeline: action.eventTimeline
  });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_SELECTED_EVENT:
            return setSelectedEvent(state, action);
        case actionTypes.GET_EVENT_ADDITIONAL_INFO_SUCCESS:
            return getAddtionalInfoSuccess(state, action);
        case actionTypes.GET_EVENT_ACCIDENT_VEHICLE_INFO:
            return getEventAccidentVehicleInfo(state, action);
        case actionTypes.GET_EVENT_FILES_SUCCESS:
            return getEventFiles(state, action);
        case actionTypes.ADD_EVENT_FILE:
            return addEventFile(state, action);
        case actionTypes.EVENT_TIMELINE_SUCCESS:
            return setEventTimeline(state, action);
        default:
            return state;
    }
};

export default reducer;