import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getEventTypesForEventRuleSuccess = (arrEventType) => {
    return {
        type: actionTypes.GET_EVENT_TYPES_FOR_EVENT_RULE_SUCCESS,
        arrEventType: arrEventType
    }
}

export const getEventSubtypeForEventRuleSuccess = (arrEventSubtype) => {
    return {
        type: actionTypes.GET_EVENT_SUBTYPE_FOR_EVENT_RULE_SUCCESS,
        arrEventSubtype: arrEventSubtype
    }
}

export const getThresholdForEventRuleSuccess = (arrThreshold) => {
    return {
        type: actionTypes.GET_THRESHOLD_FOR_EVENT_RULE_SUCCESS,
        arrThreshold: arrThreshold
    }
}

export const getArithmeticOperationForEventRuleSuccess = (arrArithmeticOperation) => {
    return {
        type: actionTypes.GET_ARITHMETIC_OPERATION_FOR_EVENT_RULE_SUCCESS,
        arrArithmeticOperation: arrArithmeticOperation
    }
}

export const getEventTypesForEventRule = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/eventTypes?reqType=all")
            .then(res => {
                console.log("ACTION for Event Types: " + res.data);
                dispatch(getEventTypesForEventRuleSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const getEventSubtypeForEventRule = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/eventSubtypes?reqType=all")
            .then(res => {
                console.log("ACTION for Event Subtype : " + res.data);
                dispatch(getEventSubtypeForEventRuleSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const getThresholdForEventRule = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/threshold?reqType=all")
            .then(res => {
                console.log("ACTION for Threshold : " + res.data);
                dispatch(getThresholdForEventRuleSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const getArithmeticOperationForEventRule = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/arithmeticOperations?reqType=all")
            .then(res => {
                console.log("ACTION for Arithmetic Operation : " + res.data);
                dispatch(getArithmeticOperationForEventRuleSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const eventRuleCreateStart = () => {
    return {
        type: actionTypes.EVENT_RULE_CREATE_START
    };
};

export const eventRuleCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(eventRuleCreateStart());
            const response = await axios.post(BASE_URL + "/api/eventRules", formValues);
            dispatch(showToaster('success','Event Rule Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}

export const eventRuleStateReset = () => {
    return {
        type: actionTypes.EVENT_RULE_STATE_RESET
    };
};

export const eventRuleEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        eventRuleName: formValues.eventRuleName,
        eventRuleDescription: formValues.eventRuleDescription,
        eventTypeId: formValues.eventTypeId,
        eventSubtypeId: formValues.eventSubtypeId,
        thresholdId: formValues.thresholdId,
        arithmeticOperationId: formValues.arithmeticOperationId,
        thresholdValue: formValues.thresholdValue,
        eventRuleBannerMessage: formValues.eventRuleBannerMessage,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(eventRuleCreateStart());
            const response = await axios.patch(BASE_URL + "/api/eventRules/" + formValues.eventRuleId, values);
            dispatch(showToaster('success','Event Rule Successfully Edited.'));
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    }
}