import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function BackdropLoading(props) {
    const classes = useStyles()


    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.open}>
                <img src="DEENRLOGO.png" style={{ height: '30px', width: '30px', marginRight: '15px' }} alt="DEENRLOGO" />
                <Typography variant="h4" style={{ marginRight: '15px' }}>DEENR INFRATECH</Typography>
                <CircularProgress color="inherit" />

            </Backdrop>
        </div>
    )

}