import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    StakeholderUser: [],
    ArrStakeholdersCat: [],
    ArrShift: [],
    strAddStakeholderUser: ''
};

const getStakeholderCatSuccess = (state,action) => {
    var updatedState = updateObject(state, {
        ArrStakeholdersCat: action.arrStakeholderCat
    });
    return updatedState;
}

const getShiftsSuccess = (state,action) => {
    var updatedState = updateObject(state, {
        ArrShift: action.arrShift
    });
    return updatedState;
}

const stakeholderUserSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        StakeholderUser: action.stakeholderUser
    });
    return updatedState;
}

// const stakeholderUserFail = (state,action) => {
//     return updateObject(state, {errorMsgCrt: action.errorMsgCrt})
// };

const stakeholderUserAddSuccess = (state,action) => {
    var updatedState = updateObject(state, 
        {
            strAddStakeholderUser: action.strAddStakeholderUser    
        });
    return updatedState;
};
const stakeholderUserAddFail = (state,action) => {
    var updatedState = updateObject(state, 
        {
            strAddStakeholderUser: action.strAddStakeholderUser    
        });
    return updatedState;
}

const stakeholderUserStateReset = (state,action) => {
    return updateObject(state, {loading: null });
};
const stakeholderUserCreateStart = (state, action) => {
    return updateObject(state, { loading: true});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STAKEHOLDER_CAT_SUCCESS:
            return getStakeholderCatSuccess(state, action)

        case actionTypes.GET_SHIFTS_SUCCESS:
            return getShiftsSuccess(state, action)

        case actionTypes.STAKEHOLDER_USER_SUCCESS:
            return stakeholderUserSuccess(state, action);

        case actionTypes.STAKEHOLDER_USER_ADD_SUCCESS:
            return stakeholderUserAddSuccess(state, action);

        case actionTypes.STAKEHOLDER_USER_ADD_FAIL:
            return stakeholderUserAddFail(state, action);

        case actionTypes.STAKEHOLDER_USER_CREATE_START:
            return stakeholderUserCreateStart(state, action);

        case actionTypes.STAKEHOLDER_USER_STATE_RESET:
            return stakeholderUserStateReset(state, action);

        default:
            return state;
    }
}

export default reducer;