import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Divider } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  button: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(20)
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px'
  },
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  text3: {
    color: '#002884',
    float: 'right',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    padding: '1px'
  },
  text2: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(12)
  },
  button1: {
    float: 'right',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  }
});

class SpeedViolationChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      barData: [],
      keys: [],
      total: 0,
      selectedButton: '',
      anchorEl: null,
      open: false,
      menuButton: 'Today',
      today: '',
      totalTraffic: 0
    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
  }

  handleButtonClick(event) {
    this.setAnchorEl(event.currentTarget);
    this.setState({
      open: !this.state.open
    })
  }

  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: false
    })
  }

  handleMenuClose() {
    this.setState({
      menuButton: 'Today',
      open: false
    })
    this.setAnchorEl(null);
  }

  async componentDidMount() {    
    await this.todayButtonClick();
  }

  todayButtonClick = async () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today",today);

    try {
      const todayData = await axios.get(BASE_URL + "/api/dashboard/speedViolationStatus-count?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today);
      // const todayData = await axios.get(BASE_URL + "/api/dashboard/speedViolationStatus-count?FROM_DATE_TIME=2021-01-23&TO_DATE_TIME=2021-04-23");
      if (todayData.data != null) {
        if (todayData.data.message) {
          this.setState({
            barData: [],
            keys: [],
            totalTraffic: 0,
            selectedButton: 'Today',
            menuButton: 'Today'
          })
        }
        else {
          this.setState({
            barData: todayData.data.data,
            keys: todayData.data.keys,
            totalTraffic: todayData.data.totalTraffic,
            selectedButton: 'Today',
            menuButton: 'Today'
          })
          console.log("Bar data ", this.state.barData);
          console.log("keys ", this.state.keys);
        }
      }
      this.setAnchorEl(null);
    }
    catch (error) {
      console.log("error");
    }
  }

  onOneWeekButtonClick = async () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today",today);

    var lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);
    let lastWeekDay = lastWeekDate.getDate();
    let lastWeekMonth = lastWeekDate.getMonth() + 1;
    let lastWeekYear = lastWeekDate.getFullYear();

    let lastWeek = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;

    console.log("lastWeek", lastWeek);

    try {
      const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/speedViolationStatus-count?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today);
      // const lastWeekData = await axios.get(BASE_URL + "/api/dashboard/speedViolationStatus-count?FROM_DATE_TIME=2020-03-23&TO_DATE_TIME=2021-04-23");
      if (lastWeekData.data != null) {
        if (lastWeekData.data.message) {
          this.setState({
            barData: [],
            keys: [],
            totalTraffic: 0,
            selectedButton: 'Last 7 days',
            menuButton: 'Last 7 days'
          })
        }
        else {
          this.setState({
            barData: lastWeekData.data.data,
            keys: lastWeekData.data.keys,
            totalTraffic: lastWeekData.data.totalTraffic,
            selectedButton: 'Last 7 days',
            menuButton: 'Last 7 days'
          })
          console.log("Bar data ", this.state.barData);
        }
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  onOneMonthButtonClick = async () => {

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let today = year + "-" + month + "-" + day;
    console.log("today",today);

    var lastMonthDate = new Date();
    lastMonthDate.setDate(lastMonthDate.getDate() - 30);
    let lastMonthDay = lastMonthDate.getDate();
    let lastMonthMonth = lastMonthDate.getMonth() + 1;
    let lastMonthYear = lastMonthDate.getFullYear();

    let lastMonth = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;

    console.log("lastMonth", lastMonth);


    try {
      const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/speedViolationStatus-count?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today);
      if (lastMonthData.data != null) {
        if (lastMonthData.data.message) {
          this.setState({
            barData: [],
            keys: [],
            totalTraffic: 0,
            selectedButton: 'Last 30 days',
            menuButton: 'Last 30 days'
          })
        }
        else {
          this.setState({
            barData: lastMonthData.data.data,
            keys: lastMonthData.data.keys,
            totalTraffic: lastMonthData.data.totalTraffic,
            selectedButton: 'Last 30 days',
            menuButton: 'Last 30 days'
          })
          console.log("Bar data ", this.state.barData);
        }
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }


  render() {
    const { classes } = this.props;

    const data = this.state.barData.length;
    console.log("violation data", this.state.barData);

    return (
      <React.Fragment>
        <div className={classes.div}>
          <Typography className={classes.text} variant="h6" align="left">Speed Violation Status</Typography>

          <Button className={classes.button1} size="small" variant="contained" color="primary" onClick={this.handleButtonClick}>
            {this.state.menuButton}
          </Button>
          <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
            <MenuItem onClick={() => this.todayButtonClick(this.state.eventTypeId)}>Today</MenuItem>
            <MenuItem onClick={() => this.onOneWeekButtonClick(this.state.eventTypeId)}>Last 7 days</MenuItem>
            <MenuItem onClick={() => this.onOneMonthButtonClick(this.state.eventTypeId)}>Last 30 days</MenuItem>            
          </Menu>
         
        </div>
        <Divider />
        <Typography className={classes.text3} variant="h6" align="right">Total Traffic - {this.state.totalTraffic}</Typography>
        {data && data !== 0 ?
          (
            <ResponsiveBar
              data={this.state.barData}
              groupMode="grouped"
              layout="vertical"
              keys={this.state.keys}
              enableGridY={false}
              indexBy="direction"
              margin={{ top: 5, right: 100, bottom: 120, left: 50 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: 'category10' }}
              markers={[
                {
                  axis: 'y',
                  value: 0,
                  lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
                  legendOrientation: 'vertical',
                },
              ]}
              colorBy="id"
              innerPadding={5}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 15,
                tickRotation: 0,
                legend: 'Direction',
                legendPosition: 'middle',
                legendOffset: 40
              }}
              axisLeft={{
                tickSize: 2,
                tickPadding: 3,
                tickRotation: 0,
                format: e => Math.floor(e) === e && e,
                legend: 'Count',
                legendPosition: 'middle',
                legendOffset: -35
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor="#faf4f4"
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 70,
                  translateY: -100,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />

          ) :
          (
            <Typography className={classes.text2} variant="h6" align="center">No Data Available For {this.state.selectedButton}</Typography>
          )}

      </React.Fragment>
    )

  }

}

export default withStyles(styles)(SpeedViolationChart)
