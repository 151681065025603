import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, FieldArray, reduxForm } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, IconButton, Button, InputLabel, InputBase,
  Typography, Box
} from '@material-ui/core';
import { connect } from "react-redux";
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import * as actions from '../../../store/actions/index';
import _ from 'lodash';
import axios from "axios";
import Toaster from '../../../components/common/Toaster';
import appConfig from '../../../appConfig.json';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import commonStyles from '../../../commonStyles';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  vehicleBut: {
    justifyContent: "center"
  },
  errorMsg: {
    color: 'red'
  }
});

class EventInformationForm extends React.Component {

  constructor() {
    super();
    this.state = {
      vhchlInfoDeleted: [],
      eventFilesDeleted: [],
      noOfVehicles: null,
      errorMsgButton: null,

    };
  }

  async componentDidMount() {
    // List of Vehicle Details

    try {
      const response = await axios.get(BASE_URL + "/api/eventFileSource");
      this.setState({
        addInfoFileSourceId: this.getEventFileSourceId('ADDITIONAL_INFO', response.data.data)
      });
      // console.log(this.state.addInfoFileSourceId);
    } catch (error) {
      console.log(error);
    }

    if(this.props.form.EventInformationForm.initial.noOfVehicles){
      this.setState({noOfVehicles : this.props.form.EventInformationForm.initial.noOfVehicles})
    }
  }

  getEventFileSourceId(eventFileSourceName, eventFileSources) {
    console.log('getting event file source id for: ', eventFileSourceName, eventFileSources);
    if (eventFileSources != null) {
      var eventFileSourceRet = eventFileSources.find((eventFileSource) => eventFileSource.eventFileSourceName == eventFileSourceName);
      if (eventFileSourceRet != null) {
        console.log('eventFileSourceRet.eventFileSourceId: ', eventFileSourceRet.eventFileSourceId);
        return eventFileSourceRet.eventFileSourceId;
      }
    }
  }

  renderText = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
    return (
      <Typography variant="body1">
        {input.value}
      </Typography>
    )
  }

  renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
    // console.log("renderTextField:", input, custom);
    return (
      <React.Fragment>
        <InputLabel>{label}</InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          // label={label}
          placeholder={label}
          error={touched && invalid}
          helperText={touched && error}
          {...input}
          {...custom}
        />
      </React.Fragment>
    )
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
    return (
      <React.Fragment>
        <InputLabel>{label}</InputLabel>
        <FormControl fullWidth variant="outlined" error={touched && error} helperText={touched && error}>
          <Select
            error={touched && error}
            native
            {...input}
            {...custom}
            inputProps={{
              name: input.name,
              id: 'color-native-simple'
            }}
          >
            {children}
          </Select>
          {this.renderFromHelper({ touched, error })}
        </FormControl>
      </React.Fragment>
    )
  }


  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }


  renderVehicles = ({ fields, meta: { error, submitFailed } }) =>{
   return (
    
      <div>
        {/* <li> */}
        <Button variant="contained" size="large" color="primary" onClick={() => {
          this.setState({ addVehicle: this.state.addVehicle + 1 })
          if(this.state.noOfVehicles === null ){
            console.log(this.props.form.EventInformationForm.initial.noOfVehicles, 'vehicle no.')
            if(!this.props.form.EventInformationForm.initial.noOfVehicles){
              this.setState({errorMsgButton: "First add no. of vehicle involved"})
            }
            
          }else{
            fields.push({})
          }
          
           
        }}>
          Add Vehicle
              </Button>
        {submitFailed && error && <span>{error}</span>}
        {/* </li> */}
        {fields.map((member, index) => {
          // if(index >= this.state.noOfVehicles){
          //   this.setState({errorMsgButton: "Can't add more vehicle "});
          //   return
          // }else{
          //   this.setState({errorMsgButton: null})
            console.log(this.props.form.EventInformationForm.initial.vehicleInfo, 'vehicle info')
            return(
             
              // <li key={index}>
              <Grid key={index} container spacing={3}>
                <Grid item md={2} xs={12}>
                  <h4>Vehicle #{index + 1}</h4>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Field
                    name={`${member}.typeOfVehicle`}
                    type="text"
                    component={reduxFormFunctions.renderTextField}
                    label="Type of Vehicle"
                    required
                 
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Field
                    name={`${member}.vehicleMakeModel`}
                    type="text"
                    component={reduxFormFunctions.renderTextField}
                    label="Vehicle Make Model"
                    required
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Field
                    name={`${member}.vehicleNumberPlate`}
                    type="text"
                    component={reduxFormFunctions.renderTextField}
                    label="Vehicle Number Plate"
                    required
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                {this.props.selectedEvent.eventData.eventStatusAlias != 'MANAGE' ?'' :<IconButton onClick={(val) => {
                    console.log(this.props.form.EventInformationForm.values.vehicleInfo[index]);
                    this.setState({
                      vhchlInfoDeleted: [...this.state.vhchlInfoDeleted, this.props.form.EventInformationForm.values.vehicleInfo[index]],
                    });
                    fields.remove(index)
                  }}>
                    <DeleteIcon />
                  </IconButton>}
                </Grid>
              </Grid>
              // </li>
      
            )
          // }
          })  }
          <p style={{color : 'red'}}>{this.state.errorMsgButton}</p>
      </div>
    )
  } 


  eventFiles = ({ fields, meta: { error, submitFailed } }) => (
    <div>
      {/* <li> */}
      <input  style={{ display: "none" }} ref={(ref) => this.upload = ref} type="file" onChange={(event) => {

        var fname = event.target.files[0].name
        // eventFileName = fname.split('.').pop()

        fields.push({
          eventFileName: fname.split('.')[0],
          eventFileExtension: fname.split('.').pop(),
          eventFile: event.target.files[0]
        })
      }} />
      {submitFailed && error && <span>{error}</span>}
      {/* </li> */}
      <Button variant="contained" size="large" color="primary" onClick={(e) => this.upload.click()}>Upload file</Button>
      {fields.map((member, index) => (
        // <li key={index}>
        <Grid key={index} container spacing={2} alignItems="center">
          <Grid item md={2} xs={12}>
            <h4>Event File #{index + 1}</h4>
          </Grid>
          <Grid item md={2} xs={12}>
            <Field
              name={`${member}.eventFileName`}
              type="text"
              component={this.renderText}
              label="File Name"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Field
              name={`${member}.eventFileExtension`}
              type="text"
              component={this.renderText}
              label="File Extension"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            {this.props.selectedEvent.eventData.eventStatusAlias != 'MANAGE'?'' : <IconButton onClick={() => {
              console.log(this.props.form.EventInformationForm.values.eventFiles[index]);
              this.setState({
                eventFilesDeleted: [...this.state.eventFilesDeleted, this.props.form.EventInformationForm.values.eventFiles[index]],
              });
              fields.remove(index)
            }}>
              <DeleteIcon />
            </IconButton>}
          </Grid>
        </Grid>
        // </li>

      ))}
    </div>
  )

  // addVehicle = () => {
  //     this.setState({ addVehicle: this.state.addVehicle + 1 })
  // }

  // OnSubmit in Event Information Form
  onSubmit = (values) => {
    console.log("OnSubmit inside Event Information Form", values);
    /*
     * Code to add and delete event files
     */
    var eventFilesAdded = [];
    var eventPath = this.props.selectedEvent.eventData.txnEventId;              //Location of the files inside upload. Its the txnEventId
    var eventFilesNew = this.props.form.EventInformationForm.values.eventFiles || [];
    var eventFilesOld = this.props.form.EventInformationForm.initial.eventFiles || [];

    for (var j = 0; j < eventFilesNew.length; j++) {
      if (eventFilesNew[j].hasOwnProperty("eventFile") === true) {
        eventFilesAdded.push(eventFilesNew[j]);
      }
    }

    var addEventParams = {
      eventPath: eventPath,
      eventFileSourceId: this.state.addInfoFileSourceId,
      txnEventId: this.props.selectedEvent.eventData.txnEventId,
      eventTypeId: this.props.selectedEvent.eventData.eventTypeId,
      eventSubtypeId: this.props.selectedEvent.eventData.eventSubtypeId,
    }

    this.props.addEventFiles(eventFilesAdded, addEventParams);
    // console.log(this.state.eventFilesDeleted);
    this.props.deleteEventFiles(this.state.eventFilesDeleted);

    /*
     *  Code to Add, Delete and Patch Vehicles
     */

    var vhchlInfoToPatch = [];
    var vhchlInfoAdded = [];

    // Vehicle info consists of 1.Type of Vehicle 2. Make Model 3. Number plate
    console.log(this.props.form.EventInformationForm.values.vehicleInfo,'new');
    console.log(this.props.form.EventInformationForm.initial.vehicleInfo, 'old')
    var vhchlInfoNew = this.props.form.EventInformationForm.values.vehicleInfo;
    var vhchlInfoOld = this.props.form.EventInformationForm.initial.vehicleInfo;

    console.log(vhchlInfoNew, vhchlInfoNew);

    // Find out the entries which needs to be patched at backend
    // for every entry

    for (var i = 0; i < vhchlInfoOld.length; i++) {
      var idOld = vhchlInfoOld[i].eventAccidentVehicleInformationId;
      // console.log("idOld", idOld);
      for (var j = 0; j < vhchlInfoNew.length; j++) {
        var idNew = vhchlInfoNew[j].eventAccidentVehicleInformationId
        // console.log("entry", idNew)
        if (idOld === idNew) {
          if (_.isEqual(vhchlInfoOld[i], vhchlInfoNew[j]) === false) {
            // console.log("Changed")
            vhchlInfoToPatch.push(vhchlInfoNew[j])
          }
          break;
        }
      }
    }
    // console.log("vhchlInfoToPatch", vhchlInfoToPatch);


    for (var j = 0; j < vhchlInfoNew.length; j++) {
      if (vhchlInfoNew[j].hasOwnProperty("eventAccidentVehicleInformationId") === false) {
        // console.log("Changed")
        vhchlInfoNew[j].eventId = this.props.selectedEvent.eventData.txnEventId;
        vhchlInfoAdded.push(vhchlInfoNew[j]);
      }
    }

    // console.log("vhchlInfoAdded ", vhchlInfoAdded);

    // console.log("vhchlInfoDeleted", this.state.vhchlInfoDeleted);

    this.props.patchVehicleInfo(vhchlInfoToPatch);
    this.props.addVehicleInfo(vhchlInfoAdded);
    this.props.deleteVehicleInfo(this.state.vhchlInfoDeleted);


    for (var i = 0; i < this.props.form.EventInformationForm.initial.vehicleInfo.length; i++) {
      console.log("eventAccidentVehicleInformationId", this.props.form.EventInformationForm.initial.vehicleInfo[i].eventAccidentVehicleInformationId);
      console.log("vehicleMakeModel", this.props.form.EventInformationForm.initial.vehicleInfo[i].vehicleMakeModel);
      console.log("vehicleNumberPlate", this.props.form.EventInformationForm.initial.vehicleInfo[i].vehicleNumberPlate);
      console.log("typeOfVehicle", this.props.form.EventInformationForm.initial.vehicleInfo[i].typeOfVehicle);
    }
    var eventAddInfo = {
      txnEventAdditionalInfoId: this.props.selectedEvent.addEventInfo.txnEventAdditionalInfoId,
      informationDescription: values.informationDescription,
      noOfCasualties: values.noOfCasualties,
      noOfVehicles: values.noOfVehicles,
      policeFirNo: values.policeFirNo,
      eventId: this.props.selectedEvent.eventData.txnEventId,
      eventType: this.props.selectedEvent.eventData.eventTypeId,
      eventSubType: this.props.selectedEvent.eventData.eventSubtypeId,
      isEnabled: 1
    }

    console.log("eventAddInfo", eventAddInfo);
    this.props.editEventAdditionalInfo(eventAddInfo);


    // // Below field should be editable when the Event General Details
    // Severity
    // Estimated Duration
    // Event Description
    // Event Reason

    // console.log("values Severity ID", values.eventSeverityId);
    // console.log("values Estimated Duration ", values.estimatedDuration);
    // console.log("values Event Description ", values.eventDescription);
    // // console.log("values Event Reason ID", values.eventReasonId);
    // var genDtsVals = {
    //     txnEventId : values.txnEventId,
    //     eventSeverityId : values.eventSeverityId,
    //     estimatedDuration : values.estimatedDuration,
    //     eventDescription : values.eventDescription,
    //     eventReasonId : values.eventReasonId
    // }
    // console.log("genDtsVals", genDtsVals);
    // this.props.editEventGeneralDts(genDtsVals);
    // // this.props.(editGenDts);
  }

  render() {
    const { classes } = this.props;
    console.log(this.props, 'this.props')
    return (
      <React.Fragment>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        
        <fieldset disabled={this.props.selectedEvent.eventData.eventStatusAlias != 'MANAGE'}>
        <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Typography variant="h5">Event</Typography>

            <Grid container spacing={3}>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <TextField label="Incident Type" fullWidth={true} variant="outlined" defaultValue="Accident" disabled={true} />
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="informationDescription" component={reduxFormFunctions.renderSelectField} label="Incident SubType">
                  <option value="" />
                  <option value="1">MAJOR</option>
                  <option value="2">MINOR</option>
                </Field>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="noOfCasualties" component={reduxFormFunctions.renderTextField} label="No of Casualities(number)" type="number"/>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="noOfVehicles" component={reduxFormFunctions.renderTextField} label="Vehicle Involved(number)" onChange={(e) =>{this.setState({noOfVehicles: e.target.value})}} type="number"/>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridItem}>
                <Field name="policeFirNo" component={reduxFormFunctions.renderTextField} label="Police FIR Number(number)" type="number"/>
              </Grid>
            </Grid>

            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5">Add Vehicle Details</Typography>

            <Grid>
              <FieldArray name="vehicleInfo" component={this.renderVehicles} />
            </Grid>

            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5">File Upload</Typography>

            <Grid>
              <FieldArray name="eventFiles" component={this.eventFiles} />
            </Grid>

            <Divider variant="list" className={classes.atmsPageSectionDivider} />

            <Button variant="contained" size="large" className={classes.atmsSaveButton} disabled={ this.props.selectedEvent.eventData.eventStatusAlias != 'MANAGE' } type="submit">
              SAVE
            </Button>

          </div>
        </form >
        </fieldset>
      </React.Fragment >
    );
  }

}

const validate = (formValues, state) => {
  const errors = {};
  
  if(formValues.noOfCasualties){
    if (/^(\d+(?:[\.\,]\d{5})?)$/.test(formValues.noOfCasualties) == false){
        errors.noOfCasualties = "Number of Casualties cant be decimal";
  console.log(formValues,'formValues')

  }
    
}else{
  errors.noOfCasualties = "Number of Casualties is required";
}

  if(formValues.noOfVehicles){
    if (/^(\d+(?:[\.\,]\d{5})?)$/.test(formValues.noOfVehicles) == false){
        errors.noOfVehicles = "Number of Vehicles cant be decimel";
  }
    
  }
  else{
    errors.noOfVehicles = "Number of Vehicles  is required";
  }

  if(formValues.policeFirNo){
    if (/^(\d+(?:[\.\,]\d{5})?)$/.test(formValues.policeFirNo) == false){
        errors.policeFirNo = "police Fir No. cant be decimel";
  }
    
  }else{
    errors.policeFirNo = "police Fir No. is required";
  }
  
  // if(formValues.vehicleInfo){
  //   for(let i = 0 ; i < formValues.vehicleInfo.length ; i++){
  //     // if(!formValues.vehicleInfo[i].typeOfVehicle){
  //     //   errors[vehicleInfo[i].typeOfVehicle] = "police Fir No. is required";
  //     //   console.log('error',...errors.vehicleInfo)
  //     // }
      
  //   }
  //   formValues.vehicleInfo.map((x) => {console.log('obj', x.typeOfVehicle)})
  // }
  
  
  console.log(errors, 'errors')  
  return errors;
};

const mapStateToProps = state => {
  console.log('state.selectedEvent.additionalInfo: ', state);
  return {
    eventType: state.eventGeneralDts.eventType,
    eventSubType: state.eventGeneralDts.eventSubType,
    selectedEvent: state.selectedEvent,
    form: state.form,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEventAdditionalInfo: (eventId) => dispatch(actions.getEventAdditionalInfo(eventId)),
    editEventAdditionalInfo: (eventAddInfo) => dispatch(actions.editEventAdditionalInfo(eventAddInfo)),
    addVehicleInfo: (vhchlInfoAdded) => dispatch(actions.addVehicleInfo(vhchlInfoAdded)),
    patchVehicleInfo: (vhchlInfoToPatch) => dispatch(actions.patchVehicleInfo(vhchlInfoToPatch)),
    deleteVehicleInfo: (vhchlInfoDeleted) => dispatch(actions.deleteVehicleInfo(vhchlInfoDeleted)),
    addEventFiles: (eventfiles, eventPath, addInfoFileSourceId) => dispatch(actions.addEventFiles(eventfiles, eventPath, addInfoFileSourceId)),
    deleteEventFiles: (eventFilesDeleted) => dispatch(actions.deleteEventFiles(eventFilesDeleted)),
  }
}

EventInformationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventInformationForm);

export default reduxForm({
  form: 'EventInformationForm',
  validate: validate,
  enableReinitialize: true,
})(withStyles(styles)(EventInformationForm));



