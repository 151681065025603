import { updateObject } from "../utility";
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user: null,     // Stores the user details
    isAuthenticated : false,
    // error: null,    
    loading: false, 
};

const authStart = ( state, action ) => {
    return updateObject( state, { loading: true });
};

const authSuccess = ( state, action ) => {
    return updateObject( state, {
        user : action.user,
        isAuthenticated : true,
        loading : false 
    });
};

const authFail = ( state, action ) => {
    return updateObject( state, {
        isAuthenticated : false,
        loading : false
    });
};

// Set Auth reducer to initial state
const signOut = ( state, action ) => {
    return updateObject( state, {
        user : null,
        isAuthenticated : false,     
        loading : false,
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: 
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: 
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: 
            return authFail(state, action);
        case actionTypes.SIGN_OUT: 
            return signOut(state, action);    
        default:
            return state;
    }
};

export default reducer;