import React, { Component } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from "@material-ui/core/styles";
import commonStyles from "../../../commonStyles";
import TreeItem from '@material-ui/lab/TreeItem';


const styles = (theme) => ({
  ...commonStyles(theme),
});


class MenuLeafNode extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // log in user entitlements
      node: null,
      //  leafnode entilement
      isAccessible: false,
      isEdit: false,
      isDelete: false,
      isAdd: false,

      //  seleted role entitlements
      selctedRoleEntitlement: null,

    }
  }

  init = () => {
    this.setState({
      isAccessible: false,
      isDelete: false,
      isEdit: false,
      isAdd: false,
      node: this.props.node
    })
    this.props.selectedRoleEntitleMents.forEach((menu) => {
      if (this.props.node.menuId === menu.entitlementsId) {
        this.setState({
          isAccessible: menu.isAccessible === 1,
          isDelete: menu.isDelete === 1,
          isEdit: menu.isEdit === 1,
          isAdd: menu.isAdd === 1,
          selctedRoleEntitlement: menu,
        })
      }
    })
  }

  componentDidMount() {
    this.init()
  }


  handleInputChange = (e) => {
    // check its add or delete

    if (this.props.selectedRoleId === '') { console.log("please selecte role frist..."); return null };
    console.log(e.target.checked, e.target.name, this.state.node.menuId)
    const setObj = {}
    const targetName = e.target.name;
    if (e.target.checked && (targetName === 'Add' || targetName === 'Delete' || targetName === 'Edit')) {
      if(targetName==="Delete")
      {
        setObj.isEdit=true;
      }
      
      setObj.isAccessible = true

    }
    setObj['is' + targetName] = e.target.checked

    this.state.node['is' + e.target.name] && this.setState({ ...setObj }, () => {
      console.log(this.state)
      let entitlement = {
        roleId: this.props.selectedRoleId,
        entitlementsId: this.state.node.menuId,
        isAccessible: this.state.isAccessible ? 1 : 0,
        isEdit: this.state.isEdit ? 1 : 0,
        isDelete: this.state.isDelete ? 1 : 0,
        isAdd: this.state.isAdd ? 1 : 0,
        isApproved: 1,
        formId: 0,
        entitlementsType: 0
      };
      if (this.state.selctedRoleEntitlement !== null) entitlement.txnRoleEntitlementsId = this.state.selctedRoleEntitlement.txnRoleEntitlementsId
      this.props.updateNodeEntitlements(entitlement)
    })
  }

  getListItem = (name, isDisabled, isChecked) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked && true}
            onChange={this.handleInputChange}
            name={name}
            color="primary"
            disabled={isDisabled}
          />
        }
        label={name}
      />
    )
  }

  render() {
    const isAcccesibleDisable =(this.state.isAdd || this.state.isEdit || this.state.isDelete)
    
    return (
      this.state.node && <TreeItem style={{ padding: '0.5em' }} nodeId={this.state.node.menuId.toString()} label={this.state.node.menuName}>
        <div> {this.getListItem('Accessible', !this.state.node.isAcccesible && isAcccesibleDisable, this.state.isAccessible)} </div>
        <div>{this.getListItem('Add', !this.state.node.isAdd, this.state.isAdd)} </div>
        <div> {this.getListItem('Edit', !this.state.node.isEdit || this.state.isDelete , this.state.isEdit)} </div>
        <div> {this.getListItem('Delete', !this.state.node.isDelete, this.state.isDelete)} </div>
        
      </TreeItem >
    );
  }
}


export default (withStyles(styles)(MenuLeafNode));
