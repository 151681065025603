import React from 'react';
import EventRuleAddForm from './eventRuleAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class EventRuleEdit extends React.Component {
    
    componentDidMount() {
        this.props.eventRuleStateReset();
    }

    onSubmit = formValues => {
        this.props.eventRuleEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        console.log("EDIT= initial Values", this.props.initialValues);

        return (
            <React.Fragment>
                {alert}
                {progress}
                <EventRuleAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.eventRuleReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        eventRuleEdit: (formValues) => dispatch(actions.eventRuleEdit(formValues)),
        eventRuleStateReset: () => dispatch(actions.eventRuleStateReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventRuleEdit));