import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    // SopCat: [],
    // strAddSopCat: '',
    loading: ''
};

const sopCatStateReset = (state,action) => {
    return updateObject(state, { loading: null });
};

const sopCatCreateStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const reducer = (state= initialState,action) => {
    switch (action.type) {        
        
        case actionTypes.SOP_CAT_CREATE_START :
            return sopCatCreateStart(state,action);

        case actionTypes.SOP_CAT_STATE_RESET :
            return sopCatStateReset(state,action);

        default:
            return state;
    }
}

export default reducer;