import React, { useState, useEffect } from "react";
import GlobalAddForm from "./GlobalAddForm";
import { withStyles } from "@material-ui/core";
import axios from "axios";
import appConfig from "../../appConfig.json";
// import { ValueService } from "ag-grid-community";
import { useDispatch } from "react-redux";
import { showToaster } from "../../store/actions";

const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => {};

const GlobalEdit = (props) => {
  const dispatch = useDispatch();
  console.log("initialValues",props.initialValues);
  useEffect(()=>{
    props.initialValues.defaultValues={ etmsApplicationCode:props.initialValues.etmsApplicationCode}

  },[props.initialValues])

  const onSubmit = async (values) => {
    console.log(values);
    let finalIsEnabled = 1;

    // delete values.tableData;
    // delete values.createdAt;
    // delete values.updatedAt;

    if (values.IsEnabled === true || values.IsEnabled === 1) {
      finalIsEnabled = 1;
    } else if (values.IsEnabled === false && values.IsEnabled === 0) {
      finalIsEnabled = 0;
    }
    if (
      (values.configValue1 && values.configValue1 === true) ||
      values.configValue1 === 1
    ) {
      values.configValue1 = "TRUE";
    } else if (values.configValue1 === 0 || values.configValue1 === false) {
      values.configValue1 = "FALSE";
    }
    if (values.configValue2 === false || values.configValue2 === 0) {
      values.configValue2 = "FALSE";
    } else if (values.configValue2 === 1 || values.configValue2 === true) {
      values.configValue2 = "TRUE";
    }

    let value = {
      etmsApplicationCode: values.etmsApplicationCode,
      configName: values.configName,
      configDescription: values.configDescription,
      configValue1: values.configValue1,
      configValue2: values.configValue2,
      configRemarks: values.configRemarks,
      configId: values.configId,
      IsEnabled: values.isEnabled,
      configDatatype: values.configDatatype,
      // updatedBy: values.updatedBy.loginId,
      // createdBy: values.createdBy,
    };
    console.log("$$$$$$$$$values", values)
    console.log("$$$$$$$$$value", value)
    try {
      let response = await axios.patch(
        BASE_URL + `/api/globalConfig/${value.configId}`,
        value
      );
      dispatch(showToaster("success", "Global Config Successfully Edited."));
    } catch (error) {
      console.log(`error`);
      dispatch(showToaster("error", error.response.data.message));
    }
    props.onClose();
  };

  return (
    <>
      <GlobalAddForm
        type="Edit"
        onClose={props.onClose}
        initialValues={props.initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default withStyles(styles)(GlobalEdit);
