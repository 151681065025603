import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  root: {
    marginTop: '50px',
    clear: 'both'
  },
  closebutton:{
    padding: '0'
  },
  closebuttonContainer:{
    display: 'flex',
    flexDirection: 'row-reverse',
  }
});

class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message || '',
      title: props.title || '',
      open: false,
      primaryOption: props.primaryOption || 'OK',
      secondaryOption: props.secondaryOption || 'CANCEL',
      // onClose: props.
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.open!= this.state.open || newProps.message!= this.state.message
      || newProps.title!= this.state.title || newProps.primaryOption!=this.state.primaryOption 
      || newProps.secondaryOption != this.state.secondaryOption) {
      this.setState({
        open: newProps.open,
        message: newProps.message,
        title: newProps.title,
        primaryOption: newProps.primaryOption,
        secondaryOption: newProps.secondaryOption
      });
    }
  }

  handleClose(selectedOption) {
    console.log('open', this.state.open)
    if(selectedOption && this.props.onClose) {
      this.props.onClose(selectedOption);
    }else{
      this.props.onClose()
    }
  }

  render() {
    const { classes } = this.props;
    // const { onClose } = this.props;
    // console.log(onClose)
    return (
      <Dialog onClose={()=>this.handleClose()} open={this.state.open}>
        <div className = {classes.closebuttonContainer}>
          <IconButton  className = {classes.closebutton} onClick = {()=>this.handleClose()}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </div>
        <DialogTitle>{this.state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(this.state.secondaryOption)} color="primary">
            {this.state.secondaryOption}
          </Button>
          <Button onClick={() => this.handleClose(this.state.primaryOption)} color="primary" autoFocus>
            {this.state.primaryOption}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default (withStyles(styles)(ConfirmationDialog));