import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Select, FormControl, Divider, Grid, InputLabel } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import axios from "axios";
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  button: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(20)
  },
  div: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '2px'
  },
  text: {
    color: '#002884',
    float: 'left',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  text2: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(12)
  },
  button1: {
    float: 'right',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  selectFormControl: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 80
  }
});

class EventChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      eventTypeDropdownData: [],            // store array of Event Types
      eventTypeId: 0,                       // declare Event Type Id to 0
      pieData: [],                          // store Chart data
      total: 0,                             // declare total to 0
      selectedButton: 'Today',              // Current Selected Button
      anchorEl: null,                       // For menu
      open: false,                          // Keep menu close as open is false 
      menuButton: 'Today',                  // Menu Button declared as "Today"     
    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
  }

  handleEventTypeChange = async (event) => {           // Get Event Type Id from dropdown and call respective function based on selected button and pass value as eventTypeId
    const name = event.target.name;
    const value = event.target.value;
    console.log("handleEventTypeChange", name, value);

    this.setState({
      eventTypeId: value
    });

    console.log("event id  ", this.state.eventTypeId);

    if (this.state.menuButton === 'Today') {
      await this.todayButtonClick(value);               // As current Menu button is "Today" call function to get Today's data
    }
    else if (this.state.menuButton === 'Last 7 days') {
      await this.onOneWeekButtonClick(value);           // As current Menu button is "Last 7 days" call function to get Last 7 days data
    }

    else if (this.state.menuButton === 'Last 30 days') {
      await this.onOneMonthButtonClick(value);          // As current Menu button is "Last 30 days" call function to get Last 30 days data
    }
  }

  handleButtonClick(event) {                           // Open Menu 
    this.setAnchorEl(event.currentTarget);
    this.setState({
      open: !this.state.open
    })
  }

  setAnchorEl(value) {                                 // Close Menu after selection
    this.setState({
      anchorEl: value,
      open: false
    })
  }

  handleMenuClose() {                                 // Close Menu
    this.setState({
      menuButton: 'Today',
      open: false
    })
    this.setAnchorEl(null);
  }

  async componentDidMount() {
    console.log("Event type", this.state.eventTypeId);

    let value = 'all';                          // Set value= all to get data of all event types

    try {
      await this.todayButtonClick(value);       // Function call to get All event types data for Today

      const dropDownRes = await axios.get(BASE_URL + "/api/eventTypes?reqType=all");      // GET call to fetch Event Types data

      if (dropDownRes.data != null) {
        this.setState({
          eventTypeDropdownData: dropDownRes.data     // Store Event Types data 
        })
        console.log("event type res data", dropDownRes.data);
        console.log("eventTypeDropdownData", this.state.eventTypeDropdownData);
      }
    }
    catch (error) {
      console.log("error");
    }
  }

  todayButtonClick = async (value) => {            // Function to get Today's data

    // Calculate Today's date
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = year + "-" + month + "-" + day;

    console.log("today is ", today);
    console.log("event id in today", this.state.eventTypeId);
    console.log("value in today", value);

    try {
      if (value === 0) {        // when no event type is selected from Dropdown
        const todayResData = await axios.get(BASE_URL + "/api/dashboard/eventStatus-pie?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today + "&EVENT_TYPE_ID=all");

        if (todayResData.data != null) {
          if (todayResData.data.message) {
            this.setState({
              pieData: [],
              total: 0,
              selectedButton: 'Today',
              menuButton: 'Today'
            })
          }
          else {
            this.setState({
              pieData: todayResData.data.data,
              total: todayResData.data.total,
              selectedButton: 'Today',
              menuButton: 'Today'
            })
            console.log("todayResData data", todayResData.data);
            console.log("pie data", this.state.pieData);
            console.log("total", this.state.total);
            console.log("menu button", this.state.menuButton);
          }
        }
      }
      else {
        const todayResData = await axios.get(BASE_URL + "/api/dashboard/eventStatus-pie?FROM_DATE_TIME=" + today + "&TO_DATE_TIME=" + today + "&EVENT_TYPE_ID=" + value);

        if (todayResData.data != null) {
          if (todayResData.data.message) {
            this.setState({
              pieData: [],
              total: 0,
              selectedButton: 'Today',
              menuButton: 'Today'
            })
          }
          else {
            this.setState({
              pieData: todayResData.data.data,
              total: todayResData.data.total,
              selectedButton: 'Today',
              menuButton: 'Today'
            })
            console.log("pie data", this.state.pieData);
          }
        }
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  onOneWeekButtonClick = async (value) => {           // Function to get One week's data
    // Calculate Today's date
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = year + "-" + month + "-" + day;

    // Calculate Last 7th day date
    var lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);
    let lastWeekDay = lastWeekDate.getDate();
    let lastWeekMonth = lastWeekDate.getMonth() + 1;
    let lastWeekYear = lastWeekDate.getFullYear();

    let lastWeek = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;

    console.log("lastWeek", lastWeek);
    console.log("Event type", this.state.eventTypeId);
    console.log("value in 7 days", value);

    try {
      if (value === 0) {          // when no event type is selected from Dropdown
        let lastWeekData = await axios.get(BASE_URL + "/api/dashboard/eventStatus-pie?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today + "&EVENT_TYPE_ID=all");
        if (lastWeekData.data != null) {
          if (lastWeekData.data.message) {
            this.setState({
              pieData: [],
              total: 0,
              selectedButton: 'Last 7 days',
              menuButton: 'Last 7 days'
            })
          }
          else {
            this.setState({
              pieData: lastWeekData.data.data,
              total: lastWeekData.data.total,
              selectedButton: 'Last 7 days',
              menuButton: 'Last 7 days'
            })
            console.log("pie data", this.state.pieData);
          }
        }
      }
      else {      // when event type is selected from dropdown and have "value"
        let lastWeekData = await axios.get(BASE_URL + "/api/dashboard/eventStatus-pie?FROM_DATE_TIME=" + lastWeek + "&TO_DATE_TIME=" + today + "&EVENT_TYPE_ID=" + value);
        if (lastWeekData.data != null) {
          if (lastWeekData.data.message) {
            this.setState({
              pieData: [],
              total: 0,
              selectedButton: 'Last 7 days',
              menuButton: 'Last 7 days'
            })
          }
          else {
            this.setState({
              pieData: lastWeekData.data.data,
              total: lastWeekData.data.total,
              selectedButton: 'Last 7 days',
              menuButton: 'Last 7 days'
            })
            console.log("pie data in else", this.state.pieData);
          }
        }
      }

      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  onOneMonthButtonClick = async (value) => {       // Function to get One month's data
    // Calculate Today's date
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = year + "-" + month + "-" + day;

    // Calculate Last 30th day date
    var lastMonthDate = new Date();
    lastMonthDate.setDate(lastMonthDate.getDate() - 30);
    let lastMonthDay = lastMonthDate.getDate();
    let lastMonthMonth = lastMonthDate.getMonth() + 1;
    let lastMonthYear = lastMonthDate.getFullYear();

    let lastMonth = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;

    console.log("lastMonth", lastMonth);
    console.log("Event type", this.state.eventTypeId);
    console.log("value in one month", value);

    try {

      if (value === 0) {      // when no event type is selected from Dropdown
        const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/eventStatus-pie?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today + "&EVENT_TYPE_ID=all");
        if (lastMonthData.data != null) {
          if (lastMonthData.data.message) {
            this.setState({
              pieData: [],
              total: 0,
              selectedButton: 'Last 30 days',
              menuButton: 'Last 30 days'
            })
          }
          else {
            this.setState({
              pieData: lastMonthData.data.data,
              total: lastMonthData.data.total,
              selectedButton: 'Last 30 days',
              menuButton: 'Last 30 days'
            })
            console.log("pie data", this.state.pieData);
          }
        }
      }
      else {        // when event type is selected from dropdown and have "value"
        const lastMonthData = await axios.get(BASE_URL + "/api/dashboard/eventStatus-pie?FROM_DATE_TIME=" + lastMonth + "&TO_DATE_TIME=" + today + "&EVENT_TYPE_ID=" + value);
        if (lastMonthData.data != null) {
          if (lastMonthData.data.message) {
            this.setState({
              pieData: [],
              total: 0,
              selectedButton: 'Last 30 days',
              menuButton: 'Last 30 days'
            })
          }
          else {
            this.setState({
              pieData: lastMonthData.data.data,
              total: lastMonthData.data.total,
              selectedButton: 'Last 30 days',
              menuButton: 'Last 30 days'
            })
            console.log("pie data", this.state.pieData);
          }
        }
      }
      this.setAnchorEl(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    const { classes } = this.props;
    // console.log(" ------------------------------------------------------------------")
    // console.log(" eventTypeDropdownData: ", this.state.eventTypeDropdownData)
    // console.log("eventTypeId: ", this.state.eventTypeId)
    // console.log("selectedButton: ", this.state.selectedButton)
    // console.log(" anchorEL: ", this.state.anchorEL)
    // console.log("open: ", this.state.open)


    const CenteredMetric = ({ nodes, centerX, centerY }) => {     // Function to show "Total" at center
      return (
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 550,
          }}
        >TOTAL : {this.state.total}
        </text>
      )
    }

    const data = this.state.pieData.length;     // Calculate length of data array
    const colorPalette = {
      'Open': "#C54B52",
      'Active': "#4B7CC5",
      'Reject': "pink",
      'Manage': "#D38831",
      'Resolve': "#85CD60",
      'Audit': "#CDCB60",
    };
    return (
      <React.Fragment>
        <div className={classes.div}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography className={classes.text} variant="h6" align="left">Event Status</Typography>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel id="eventTypeLabel">Event Type</InputLabel>
                <Select
                  labelId="eventTypeLabel"
                  id="eventTypeId"
                  name="eventTypeId"
                  value={this.state.eventTypeId ? this.state.eventTypeId : 0}
                  onChange={this.handleEventTypeChange}
                  label="Event Type"
                >
                  <MenuItem value={0}>{'ALL EVENT TYPES'}</MenuItem>
                  {this.state.eventTypeDropdownData && this.state.eventTypeDropdownData.map((eventType) => {
                    return (
                      <MenuItem value={eventType.eventTypeId}>{eventType.eventTypeName}</MenuItem>
                    );
                  })}
                </Select>

              </FormControl>


            </Grid>

            <Grid item xs={3}>
              <Button className={classes.button1} size="small" variant="contained" color="primary" onClick={this.handleButtonClick}>
                {this.state.menuButton}
              </Button>
              <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
                <MenuItem onClick={() => this.todayButtonClick(this.state.eventTypeId)}>Today</MenuItem>
                <MenuItem onClick={() => this.onOneWeekButtonClick(this.state.eventTypeId)}>Last 7 days</MenuItem>
                <MenuItem onClick={() => this.onOneMonthButtonClick(this.state.eventTypeId)}>Last 30 days</MenuItem>
              </Menu>
            </Grid>

          </Grid>
        </div>
        <Divider />
        { data && data !== 0 ?
          (
            <ResponsivePie
              data={this.state.pieData}
              radialLabelsTextColor="#333333"
              margin={{ top: 20, right: 50, bottom: 90, left: 120 }}
              //colors={{ scheme: 'paired' }}
              colors={({ data }) => {
                console.log("data:", data); // Add this line for debugging
                if (data) {
                  const id = data.id;
                  return colorPalette[id] || '#CCCCCC';
                }
                return '#CCCCCC'; // Default color if data is undefined
              }}
              innerRadius={0.6}
              enableSliceLabels={true}
              layers={['slices', 'sliceLabels', 'legends', CenteredMetric]}
              // colors={{ datum: 'data.color' }}
              legends={[
                {
                  anchor: 'top-left',
                  direction: 'column',
                  justify: false,
                  translateX: -100,
                  translateY: -10,
                  itemsSpacing: 10,
                  itemWidth: 70,
                  itemHeight: 20,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          ) :
          (
            <Typography className={classes.text2} variant="h6" align="center">No Data Available For {this.state.selectedButton}</Typography>
          )}

      </React.Fragment>
    )
  }
}

export default withStyles(styles)(EventChart)