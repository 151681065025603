import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    loading: null
};

const equipGroupStateReset = ( state, action ) => {
    return updateObject( state, { loading: null });
};

const equipGroupCreateStart = ( state, action ) => {
    return updateObject( state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {        
        case actionTypes.EQUIP_GROUP_STATE_RESET:
            return equipGroupStateReset(state, action);
        case actionTypes.EQUIP_GROUP_CREATE_START:
            return equipGroupCreateStart(state, action);
        default:
            return state;
    }
};

export default reducer;