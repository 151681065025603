import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Button, CardHeader, Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MapIcon from '@material-ui/icons/Map';
import appConfig from '../../appConfig.json';
import commonStyles from '../../commonStyles';
import Paper from '@material-ui/core/Paper';
import EventChart from './eventChart';
import MetChart from './metChart';
import EquipmentHealthChart from './equipmentHealthChart';
import SpeedViolationProcessingChart from './speedViolationProcessingChart';
import SpeedViolationChart from './speedViolationChart';
import SectionSpeedViolationChart from './sectionSpeedViolationChart';
import TrafficIncidentChart from './trafficIncidentChart';
import VehicleAidChart from './vehicleAidChart';
import EmergencyCallBoxChart from './emergencyCallBoxChart';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
  atmsCard: {
    'min-height': '90vh',
    backgroundColor: 'rgb(218 230 255)'
  },
  paper: {
    // width: theme.spacing(75),
    height: theme.spacing(40),
    // marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(0.5),
    padding: '10px'
  },
  paper2: {
    width: theme.spacing(150),
    height: theme.spacing(70),
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(0.5),
  },
  text: {
    color: '#002884',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.1),
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  fullScreenIcon: {
    float: 'right',
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    float: 'right'
  }
});


class LandingPageDashboard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      modalType: ""
    }
  }

  showFullScreen = (chartType) => {
    this.setState({
      modalType: chartType,
      modalOpen: true
    })
  }

  render() {
    const { classes } = this.props;

    let modalDiv = null;

    if (this.state.modalType === "Event") {
      modalDiv = <Modal
        id="chartModal1"
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "Event" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "Event" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <EventChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "MET") {
      modalDiv = <Modal
        id="chartModal2"
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "MET" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "MET" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <MetChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "EquipmentHealth") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "EquipmentHealth" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "EquipmentHealth" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <EquipmentHealthChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "SpeedViolationProcessing") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "SpeedViolationProcessing" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "SpeedViolationProcessing" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <SpeedViolationProcessingChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "SpeedViolation") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "SpeedViolation" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "SpeedViolation" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <SpeedViolationChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "SectionSpeedViolation") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "SectionSpeedViolation" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "SectionSpeedViolation" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <SectionSpeedViolationChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "TrafficIncident") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "TrafficIncident" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "TrafficIncident" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <TrafficIncidentChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
    }

    if (this.state.modalType === "VehicleAid") {
      console.log("Inside vehicle aid")
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "VehicleAid" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "VehicleAid" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <VehicleAidChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
      console.log("After vehicle aid")
    }

    if (this.state.modalType === "ECB") {
      console.log("Inside ECB")
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false, modalType: "ECB" })}>
        <Paper elevation={4} className={classes.paper2}>
          <IconButton className={classes.button} align="right" onClick={() => this.setState({ modalOpen: false, modalType: "ECB" })}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <EmergencyCallBoxChart modelStatus={this.state.modalOpen} />
        </Paper>
      </Modal>
      console.log("After ECB")
    }

    return (
      <div className={classes.atmsPageOuterDiv}>
        {modalDiv}
        <Card className={classes.atmsCard}>
          <CardHeader
            action={
              <Button
                variant="contained"
                color="primary"
                endIcon={<MapIcon></MapIcon>}
                onClick={() => { this.props.history.push("/mapdashboard") }}
              >
                GO TO MAP
              </Button>
            }
            title="Dashboard"
          />
          <CardContent>

            <Grid container spacing={1}>
              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart1" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("Event")}>
                    <FullscreenIcon />
                  </span>
                  <EventChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart7" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("TrafficIncident")}>
                    <FullscreenIcon />
                  </span>
                  <TrafficIncidentChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart2" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("MET")}>
                    <FullscreenIcon />
                  </span>
                  <MetChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart3" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("EquipmentHealth")}>
                    <FullscreenIcon />
                  </span>
                  <EquipmentHealthChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart4" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("SpeedViolationProcessing")}>
                    <FullscreenIcon />
                  </span>
                  <SpeedViolationProcessingChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart5" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("SpeedViolation")}>
                    <FullscreenIcon />
                  </span>
                  <SpeedViolationChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart6" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("SectionSpeedViolation")}>
                    <FullscreenIcon />
                  </span>
                  <SectionSpeedViolationChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart8" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("VehicleAid")}>
                    <FullscreenIcon />
                  </span>
                  <VehicleAidChart />
                </Paper>
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={3}>
                <Paper elevation={3} className={classes.paper}>
                  <span id="chart9" className={classes.fullScreenIcon} onClick={() => this.showFullScreen("ECB")}>
                    <FullscreenIcon />
                  </span>
                  <EmergencyCallBoxChart />
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );

  }
}

export default (withStyles(styles)(LandingPageDashboard));