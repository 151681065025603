import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import appConfig from '../../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    marquee: {
        color:'red',
        fontWeight: 'bold'
      }
});
class UpstreamCCTV extends React.Component {

   

    async componentDidMount() {
        try {
            const x = this.props.selectedEquipment.equipmentId
            const equipmentData = await axios.get(BASE_URL + "/api/equipments/" + x)
            this.setState({videoUrl: equipmentData.data.streamingUrl});
          }
          catch (error) {
            console.log("error");
          }

          this.intervalID = setInterval(
            () => this.tick(),
            1000
          );
    }

    constructor(props) {
        super(props)
    
        this.state = {
            videoUrl : '', 
            time: new Date().toLocaleString(),            
        }
        
      }
    
  

    componentWillUnmount() {
        clearInterval(this.intervalID);
      }
    
    tick() {
        this.setState({
          time: new Date().toLocaleString()
        });
    }
    

    render() {
        const { classes, selectedEquipment } = this.props;


        console.log('STATE', this.props)
        
        console.log("selectedEquipment in the ECB Modal is", selectedEquipment);
        const headerTitle = selectedEquipment.equipmentName + ' | ' + this.props.selectedEquipment.chainageName + ' | ' + this.props.selectedEquipment.directionName + ' | ' + this.state.time;
        const vidUrl =this.state.videoUrl+'?autoplay=1';
        let alert;
        if(selectedEquipment.isFaulty === 1){
        alert =   
                <div className={classes.marquee}>
                    These equipment is under Maintaince
                </div> 
        }
        return (
             <React.Fragment>
                 <Container maxWidth="md" className={classes.container}>
                 <Card>
                            <CardHeader
                                
                                title= {headerTitle}
                                subheader = {alert}
                                action={
                                    <IconButton onClick={() => { this.props.onClose() }}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                }
                            />
                            <Divider />
                            <CardContent>
                            <iframe width ="800" height="400" src={vidUrl} title={selectedEquipment?.equipmentName} frameBorder="0"  allowFullScreen></iframe>
                    
                            </CardContent>
                            <Divider />
                        </Card>
                 </Container>
             </React.Fragment>
        );
    }
}



export default connect( null, null )(withStyles(styles)(UpstreamCCTV));