import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    loading: ''
};

const hotlistVehicleStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const hotlistVehicleCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HOTLIST_VEHICLE_STATE_RESET:
            return hotlistVehicleStateReset(state, action);

        case actionTypes.HOTLIST_VEHICLE_CREATE_START:
            return hotlistVehicleCreateStart(state, action);

        default:
            return state;
    }
}

export default reducer;
