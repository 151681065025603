import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Divider, CardHeader, Button, Modal, Typography, Box, Card, CardContent, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DataGrid from '../../../../../components/mastersGrid/DataGrid';
import RefreshIcon from '@material-ui/icons/Refresh';
import { connect } from 'react-redux';
import SOPMapCreate from './SOPMapCreate';
import SOPMapEdit from './SOPMapEdit';
import Toaster from '../../../../../components/common/Toaster';
import * as actions from '../../../../../store/actions/index';
import checkEntitlement from "../../../../../utils/checkEntitlement";
import { Field, reduxForm } from 'redux-form';
import * as reduxFormFunctions from '../../../../../components/common/Redux_forms_Common';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8'
    },
    addButton: {
        float: 'right',
        marginBottom: '1rem'
    },
    mainGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        display: 'inline',
        textAlign: 'center',
        color: '#002884',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: '200',
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto',

    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
});

class SOPMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventTypeId: null,
            eventSubtypeId: null,
            isOpen: false,
            modalType: "",
            rowData: null
        }
        this.grid = React.createRef();
    }

    componentDidMount() {
        this.props.getEventTypes();
    }

    componentDidUpdate(prevProps, prevState) {
        /*
         * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
         */

        if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
            if (this.grid.current) { //grid is not present when the page first renders when someone new logins so if this condition is not there it will give an error
                this.grid.current.reload();
            }
        }
        if (prevState.eventTypeId !== this.state.eventTypeId) {
            this.props.getEventSubtypes(this.state.eventTypeId)
        }
    }

    onChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }
    onEventChange = event => {
        if (this.state.eventSubtypeId) {
            this.setState({
                eventSubtypeId: null
            })
        }
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    onReset = event => {
        this.props.reset();
        this.setState({
            eventTypeId: null,
            eventSubtypeId: null
        })
    }

    onSearchSubmit = (event) => {
        event.preventDefault();
    }

    render() {
        const { classes } = this.props;
        let modalDiv = null;

        if (this.state.modalType === "ADD") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
                closeAfterTransition
            >
                <SOPMapCreate eventTypeId={this.state.eventTypeId} eventSubtypeId={this.state.eventSubtypeId} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        if (this.state.modalType === "EDIT") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
                closeAfterTransition>
                <SOPMapEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        return (
            <div className={classes.atmsPageOuterDiv}>
                <Card maxWidth="x1" className={classes.atmsPageOuterCard}>
                    <CardHeader title="Event SOP Mapping" />
                    <Divider />
                    <CardContent>
                        <Box mt={3}>
                            <Card>
                                <CardContent>
                                    <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                                        <Grid container spacing={5} className={classes.mainGrid}>
                                            <Grid item lg={4} md={6} xs={12}>
                                                <Field name="eventTypeId" id="eventTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onEventChange} label="Event Type">
                                                    <option value={null}>{""}</option>
                                                    {this.props.eventTypes.map(eventType => {
                                                        return (
                                                            <option key={eventType.eventTypeId} value={eventType.eventTypeId}>
                                                                {eventType.eventTypeName}
                                                            </option>
                                                        )
                                                    })}
                                                </Field>
                                            </Grid>
                                            <Grid item lg={4} md={6} xs={12}>
                                                <Field name="eventSubtypeId" id="eventSubtypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Event Subtype">
                                                    <option value={null}>{""}</option>
                                                    {this.props.eventSubtypes.map(eventSubtype => {
                                                        return (
                                                            <option key={eventSubtype.eventSubtypeId} value={eventSubtype.eventSubtypeId}>
                                                                {eventSubtype.eventSubtypeName}
                                                            </option>
                                                        )
                                                    })}
                                                </Field>
                                            </Grid>
                                            <Grid item lg={4} md={6} xs={12} >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<RefreshIcon />}
                                                    type="reset"
                                                    onClick={this.onReset}
                                                >
                                                    Reset
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box mt={2} gutterBottom>
                            {this.state.eventTypeId && this.state.eventSubtypeId ?
                                <Button variant="contained"
                                    disabled={!checkEntitlement(this.props.location.pathname, 'add')}
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    className={classes.addButton}
                                    onClick={() => this.setState({ isOpen: true, modalType: "ADD" })} >
                                    Map SOP Item
                            </Button> : null
                            }
                        </Box>

                        <Box mt={1} >
                            {this.state.eventSubtypeId && this.state.eventTypeId ?
                                <DataGrid
                                    downloadReport={true}
                                    ref={this.grid}
                                    endpoint={`api/eventSop?eventTypeId=${this.state.eventTypeId}&eventSubtypeId=${this.state.eventSubtypeId}`}
                                    isEditable={checkEntitlement(this.props.location.pathname, 'edit')}
                                    onEditClick={(event, rowData) => {
                                        this.currentSelectedRow = rowData;
                                        this.setState({ isOpen: true, modalType: "EDIT" })
                                    }}
                                /> :
                                <Box mt={1} className={classes.header}>
                                    <Typography variant="h5" >
                                        Please Select both Event Type and Sub type
                                    </Typography>
                                </Box>
                            }

                        </Box>
                    </CardContent>
                </Card>
                <Toaster
                    open={this.props.toasterOpen}
                    message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
                    severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
                />
                {modalDiv}
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.sopMapReducer.loading,
        toasterOpen: state.common.toasterOpen,
        toasterMessageConfig: state.common.toasterMessageConfig,
        eventTypes: state.sopMapReducer.eventTypes,
        eventSubtypes: state.sopMapReducer.eventSubtypes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventTypes: () => dispatch(actions.getEventTypes()),
        getEventSubtypes: (eventTypeId) => dispatch(actions.getEventSubtypes(eventTypeId)),
    }
}
SOPMap = connect(
    mapStateToProps,
    mapDispatchToProps
)(SOPMap)


export default reduxForm({
    form: 'SOPMap'
})(withStyles(styles)(SOPMap));
