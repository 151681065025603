import React from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';


class NotoficationPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: '',
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }
  componentDidMount() {
    console.log("this.props", this.props)
  }
  handleClick(event) {
    this.setState({ anchorEl: event.target })
  };

  handleClose() {
    this.setState({ anchorEl: null })
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
      <IconButton color="inherit" aria-describedby={id} variant="contained"   onClick={this.handleClick}>
       {this.props.actionButton}
      </IconButton>
       
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {this.props.popoverContent}
        </Popover>
      </React.Fragment>
    )
  }
}

export default NotoficationPopover;

