import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

const equipGroupCreateStart = () => {
    return {
        type : actionTypes.EQUIP_GROUP_CREATE_START
    };
};
export const equipGroupCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(equipGroupCreateStart());
            const response = await axios.post(BASE_URL + "/api/equipmentGroups", formValues);
            dispatch(showToaster('success','Equipment Group Successfully Created.'));
        } catch (err) {
         dispatch(showToaster('error',err.response.data.message));
        }
    };
}

export const equipGroupStateReset = () => {
    return {
        type: actionTypes.EQUIP_GROUP_STATE_RESET
    }
}

export const equipGroupEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        equipmentGroupName: formValues.equipmentGroupName,
        equipmentGroupDescription: formValues.equipmentGroupDescription,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(equipGroupCreateStart());
            const response = await axios.patch(BASE_URL + "/api/equipmentGroups/" + formValues.equipmentGroupId, values);
            console.log(response);
            dispatch(showToaster('success','Equipment Group Successfully Edited.'))
        } catch (err) {
            console.log(err);
            dispatch(showToaster('error',err.response.data.message));
        }
    };
}
