import React from 'react';
import LocationTypeForm from './locationTypeForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";
import axios from 'axios';
import appConfig from '../../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class LocationTypeEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedFile: null
        }
    }

    async componentDidMount() {
        if (this.props.initialValues) {
            let path = this.props.initialValues.locationTypeImageUrl;
            try {
                const res = await axios.get(`${BASE_URL}/api/uploads?file=${path}`, { responseType: 'blob' });
                console.log("response: ", res)
                this.setState({
                    uploadedFile: URL.createObjectURL(res.data)
                })
            } catch (error) {
                console.log("EDIT ERROR: ", error);
            }
        }
        this.props.locationTypeStateReset();
        console.log("Uploaded from EDIT file", this.state.uploadedFile)
    }

    onSubmit = formValues => {
        console.log("onSubmit edit formVlaues: ", formValues);
        this.props.locationTypeEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        console.log("errorMsgCrt", this.props.initialValues);

        return (
            <React.Fragment>
                { alert}
                { progress}
                <LocationTypeForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} uploaded={this.state.uploadedFile} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.locationTypeReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        locationTypeEdit: (formValues) => dispatch(actions.locationTypeEdit(formValues)),
        locationTypeStateReset: () => dispatch(actions.locationTypeStateReset()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationTypeEdit));