import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

class EventRuleAddForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            eventTypeId: 0,
            eventSubtypeId: 0,
            thresholdId: 0,
            arithmeticOperationId: 0
        };
    }

    componentDidMount() {
        this.props.getEventTypesForEventRule();
        this.props.getEventSubtypeForEventRule();
        this.props.getThresholdForEventRule();
        this.props.getArithmeticOperationForEventRule();       
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add New Event Rule"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>

            </React.Fragment>
            subheaderName = "Edit Event Rule"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Event Rule"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventRuleName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Event Rule Name" id="eventRuleName" />
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventRuleDescription" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Event Rule Description" id="eventRuleDescription" />
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Event Type" id="eventTypeId" >
                                        <option value={""}>{'Select Event Type'}</option>
                                        {this.props.ArrEventType.map((eventTypeDropDown) => <option key={eventTypeDropDown.eventTypeId} value={eventTypeDropDown.eventTypeId}>{eventTypeDropDown.eventTypeName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventSubtypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Event Subtype" id="eventSubtypeId" >
                                        <option value={""}>{'Select Event Subtype'}</option>
                                        {this.props.ArrEventSubtype.map((eventSubtypeDropDown) => <option key={eventSubtypeDropDown.eventSubtypeId} value={eventSubtypeDropDown.eventSubtypeId}>{eventSubtypeDropDown.eventSubtypeName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="thresholdId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Threshold" id="thresholdId" >
                                        <option value={""}>{'Select Threshold'}</option>
                                        {this.props.ArrThreshold.map((thresholdDropDown) => <option key={thresholdDropDown.thresholdId} value={thresholdDropDown.thresholdId}>{thresholdDropDown.thresholdName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="arithmeticOperationId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Arithmetic Operation" id="arithmeticOperationId" >
                                        <option value={""}>{'Select Arithmetic Operation'}</option>
                                        {this.props.ArrArithmeticOperation.map((arithmeticOperationDropDown) => <option key={arithmeticOperationDropDown.arithmeticOperationId} value={arithmeticOperationDropDown.arithmeticOperationId}>{arithmeticOperationDropDown.arithmeticOperationName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="thresholdValue" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Threshold Value" id="thresholdValue" />
                                </Grid>
                                <Grid item item md={6} xs={12}>
                                    <Field name="eventRuleBannerMessage" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Event Rule Banner Message" id="eventRuleBannerMessage" />
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const RegXforName = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
    const RegXforNumber = /^[0-9\b]+$/;

    if (!formValues.eventRuleName) {
        error.eventRuleName = "Enter Event Rule Name";
    }

    if (!formValues.eventRuleDescription) {
        error.eventRuleDescription = "Enter Event Rule Description";
    }

    if (formValues.eventRuleDescription) {
        if (formValues.eventRuleDescription.length > 201) {
            error.eventRuleDescription = "Maximum characters should be 200 only";
        }
    }

    if (!formValues.eventTypeId) {
        error.eventTypeId = "Select Event Type";
    }

    if (formValues.eventTypeId) {
        if (formValues.eventTypeId == "") {
            error.eventTypeId = "Select Event Type";
        }
    }

    if (!formValues.eventSubtypeId) {
        error.eventSubtypeId = "Select Event Subtype";
    }

    if (formValues.eventSubtypeId) {
        if (formValues.eventSubtypeId == "") {
            error.eventSubtypeId = "Select Event Subtype";
        }
    }

    if (!formValues.thresholdId) {
        error.thresholdId = "Select Threshold";
    }

    if (formValues.thresholdId) {
        if (formValues.thresholdId == "") {
            error.thresholdId = "Select Threshold";
        }
    }

    if (!formValues.arithmeticOperationId) {
        error.arithmeticOperationId = "Select Arithmetic Operation";
    }

    if (formValues.arithmeticOperationId) {
        if (formValues.arithmeticOperationId == "") {
            error.arithmeticOperationId = "Select Arithmetic Operation";
        }
    }

    if (!formValues.thresholdValue) {
        error.thresholdValue = "Enter Threshold Value";
    }

    if (formValues.thresholdValue) {
        if (!RegXforNumber.test(formValues.thresholdValue)) {
            error.thresholdValue = "Enter only Numbers";
        }
    }

    if (!formValues.eventRuleBannerMessage) {
        error.eventRuleBannerMessage = "Select Event Rule Banner Message";
    }
    
    return error;
};

const mapStateToProps = state => {
    return {
        ArrEventType: state.eventRuleReducer.ArrEventType,
        ArrEventSubtype: state.eventRuleReducer.ArrEventSubtype,
        ArrThreshold: state.eventRuleReducer.ArrThreshold,
        ArrArithmeticOperation: state.eventRuleReducer.ArrArithmeticOperation
    };
};
const mapDispatchToProps = (dispatch, state) => {
    return {
        getEventTypesForEventRule: () => dispatch(actions.getEventTypesForEventRule()),
        getEventSubtypeForEventRule: () => dispatch(actions.getEventSubtypeForEventRule()),
        getThresholdForEventRule: () => dispatch(actions.getThresholdForEventRule()),
        getArithmeticOperationForEventRule: () => dispatch(actions.getArithmeticOperationForEventRule())       
    };
};

EventRuleAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventRuleAddForm)

export default reduxForm({
    validate: validate,
    form: 'EventRuleAddForm'
})(withStyles(styles)(EventRuleAddForm));