import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

const locationCatCreateStart = () => {
    return {
        type: actionTypes.LOCATION_CATEGORY_CREATE_START
    };
};


//#region  ADD
export const locationCatCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(locationCatCreateStart());
            const response = await axios.post(BASE_URL + "/api/locationCategories", formValues);
            dispatch(showToaster('success', "Location Category Successfully Created."));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message));
        }
    };
}
//#endregion

//#region STATE
export const locationCatStateReset = () => {
    return {
        type: actionTypes.LOCATION_CATEGORY_STATE_RESET
    }
}
//#endregion

//#region EDIT
export const locationCatEdit = (formValues) => {
    console.log("Edit: ", formValues);
    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }

    let values = {
        locationCategoryName: formValues.locationCategoryName,
        locationCategoryDescription: formValues.locationCategoryDescription,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(locationCatCreateStart());
            const response = await axios.patch(BASE_URL + "/api/locationCategories/" + formValues.locationCategoryId, values);
            console.log(response);
            dispatch(showToaster('success', "Location Category Successfully Edited."));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error', error.response.data.message));
        }
    };
}
//#endregion