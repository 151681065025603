import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getSOPCatSuccess = (arrSopCat) => {
    return {
        type: actionTypes.GET_SOP_CAT_SUCCESS,
        arrSopCat: arrSopCat
    }
}

export const getSopCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/sopCategories?reqType=all")
            .then(res => {
                console.log("ACTION for SOP Category: " + res.data);
                dispatch(getSOPCatSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const sopItemCreateStart = () => {
    return {
        type: actionTypes.SOP_ITEM_CREATE_START
    };
};

export const sopItemCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(sopItemCreateStart());
            const response = await axios.post(BASE_URL + "/api/sopItems", formValues);
            dispatch(showToaster('success','SOP Item Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}

export const sopItemStateReset = () => {
    return {
        type: actionTypes.SOP_ITEM_STATE_RESET
    };
};

export const sopItemEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }

    let values = {
        sopCategoryId: formValues.sopCategoryId,
        sopItemDescription: formValues.sopItemDescription,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(sopItemCreateStart());
            const response = await axios.patch(BASE_URL + "/api/sopItems/" + formValues.sopItemId, values);
            dispatch(showToaster('success','SOP Item Successfully Edited.'));
            
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    }
}