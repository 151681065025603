import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    userRoles: [],
    userStatus: [],
    userShifts: [],
    loading: false
};

const userRoleSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
            userRoles : action.userRoles
    });
    return updatedState;
};

const userStatusSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
            userStatus : action.userStatus
    });
    return updatedState;
};

const userShiftSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
            userShifts : action.userShifts
    });
    return updatedState;
};

const userDetailsStateReset = ( state, action ) => {
    return updateObject( state, { loading: null });
};

const userDetailsCreateStart = ( state, action ) => {
    return updateObject( state, { loading: true });
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.GET_USER_ROLE_SUCCESS:
            return userRoleSuccess(state, action);
        case actionTypes.GET_USER_STATUS_SUCCESS:
            return userStatusSuccess(state, action);
        case actionTypes.GET_USER_SHIFT_SUCCESS:
            return userShiftSuccess(state, action);
        case actionTypes.USER_DTLS_STATE_RESET:
            return userDetailsStateReset(state, action);
        case actionTypes.USER_DTLS_CREATE_START:
            return userDetailsCreateStart(state, action);
        default:
            return state;
    }
}

export default reducer;