import React from 'react';
import { Select, TextField, FormControl, Checkbox, FormControlLabel, InputLabel, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    variant="outlined"
    fullWidth
    label={label}
    // placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom} />
)

export const renderTimeField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    variant="outlined"
    fullWidth
    label={label}
    type="time"
    defaultValue="07:30"
    InputLabelProps={{
      shrink: true,
    }}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)


export const radioIsEnabled = ({ input, reset, ...props }) => (
  <FormControl fullWidth variant="outlined">
    <FormControlLabel
      control={<Checkbox {...input}
        name="isEnabled"
        checked={input.value ? 1 : 0} />}
      label="Enabled"
      disabled={props.disabled} />
  </FormControl>
)


export const radioIsEvent = ({ input, reset, ...props }) => (
  <FormControl fullWidth variant="outlined">
    <FormControlLabel
      control={<Checkbox {...input}
        name="isEvent"
        checked={input.value ? true : false} />}
      label="Is it an Event ?"
      disabled={props.disabled}
    />
  </FormControl>
)



export const radioIsNotificationEnabled = ({ input, ...reset }) => (
  <FormControl fullWidth variant="outlined">
    <FormControlLabel
      control={<Checkbox {...input}
        name="isNotificationEnable"
        checked={input.value ? true : false} />}
      label="is Notification Enable" />
  </FormControl>
)

export const radioIsPrimaryContact = ({ input, ...reset }) => (
  <FormControl fullWidth variant="outlined">
    <FormControlLabel
      control={<Checkbox {...input}
        name="isPrimaryContact"
        checked={input.value ? true : false} />}
      label="is Primary Contact" />
  </FormControl>
)

export const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export const renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
  return (
    <FormControl fullWidth={true} variant="outlined" error={touched && error} helperText={touched && error}>
      <InputLabel >{label}</InputLabel>
      <Select
        native
        label={label}
        {...input}
        {...custom}
        shrink={true}
        InputLabelProps={{
          shrink: true,
        }}
      >
        {children}
      </Select>
      {(touched && error) ? <FormHelperText>{touched && error}</FormHelperText> : null}
    </FormControl>
  )
}

export const renderInput = ({ input, type, meta }) => {
  const { mime } = this.props;
  return (
    <div>
      <input
        name={input.name}
        type={type}
        accept={mime}
        onChange={event => this.handleChange(event, input)}
      />
    </div>
  );
};
export const handleChange = (event, input) => {
  event.preventDefault();
  this.setState({
    selectedFile: event.target.files[0],
    //loaded: 0,
  })
};

export const renderText = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
  return (
    <Typography variant="body1">
      {input.value}
    </Typography>
  )
}

export const renderDateField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    fullWidth
    variant="outlined"
    label={label}
    type="date"
    defaultValue="2020-08-31"
    error={touched && invalid}
    helperText={touched && error}
    // className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    {...input}
    {...custom}
  />
)

export const renderDateTimeField = ({ label, input, value, meta: { touched, invalid, error }, ...custom }) => (
  <MuiPickersUtilsProvider utils={MomentUtils} >
    <KeyboardDateTimePicker
      label={label}
      format="DD-MM-YYYY HH:mm:ss"
      disableFuture={true}
      value={value}
      ampm={true}
      error={touched && invalid}
      helperText={touched && error}
      InputLabelProps={{
        shrink: true,
      }}
      {...input}
      {...custom}

    />
  </MuiPickersUtilsProvider>
)

