import React from 'react';
import axios from 'axios';
import LocationDetailsForm from './LocationDetailsForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";
import appConfig from '../../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
});

class LocationDetailsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedFile: null
        };
    }

    async componentDidMount() {
        if (this.props.initialValues) {
            let path = this.props.initialValues.locationDetailsImageUrl;
            try {
                const res = await axios.get(`${BASE_URL}/api/uploads?file=${path}`, { responseType: 'blob' });
                console.log("getImage res: ", res)
                this.setState({
                    uploadedFile: URL.createObjectURL(res.data)
                });

            } catch (error) {
                console.log("getImage error: ", error);
            }
        }
        this.props.locationDetailsStateReset();
        console.log("Uploaded from EDIT file", this.state.uploadedFile)
    }

    onSubmit = formValues => {
        this.props.locationDetailsEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }

        return (
            <React.Fragment>
                { progress}
                <LocationDetailsForm initialValues={this.props.initialValues} uploaded={this.state.uploadedFile} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.locationDetailsReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        locationDetailsEdit: (formValues) => dispatch(actions.locationDetailsEdit(formValues)),
        locationDetailsStateReset: () => dispatch(actions.locationDetailsStateReset()),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationDetailsEdit));