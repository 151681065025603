import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Container, Card, CardHeader, Divider, CardContent, IconButton , Grid, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import appConfig from '../../../appConfig.json';
import commonStyles from '../../../commonStyles';
import TablePagination from '@material-ui/core/TablePagination';

const BASE_URL = appConfig.BASE_URL;
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    tableRoot: {
        marginTop: '10px'
    },
    gridtext: {
        textAlign: 'center',
        paddingTop : '20px'
    },
    tableHead: {
        display: 'block',
        padding: '0'
    },
    table: {
        width: 'auto',
        tableLayout : 'fixed',
        borderCollapse : 'collapse',
    },
    tableBody: {
        display: 'block',
        height: '150px',
        overflowY: 'scroll',
    },
    tableHeading:{
        margin : '0 !important'
    },
    marquee: {
        color:'red',
        fontWeight: 'bold'
    },
    ...commonStyles(theme)
});
class ATCCDetails extends React.Component {
     
    constructor(props) {
        super(props)
    
        this.state = {  
            atccData : [] , 
            fromDate : '',
            toDate : '',   
            count : 0,
            page : 0,
            rowsPerPage: 3,

        }

        
      }

    async componentDidMount() {
        //api call
       try {
         let equipmentId = this.props.selectedEquipment.equipmentId;
         let fromDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3, 
            new Date().getDate()
            ).toISOString().slice(0,10);
         let toDate = new Date().toISOString().slice(0,10);
         const atccData = await axios.get(BASE_URL + "/api/dashboard/atcc-map?FROM_DATE_TIME=" + fromDate + "&TO_DATE_TIME=" + toDate + "&equipmentId=" + equipmentId)
         this.setState({atccData: atccData.data.data});
         this.setState({fromDate: fromDate});
         this.setState({toDate: toDate});
         this.setState({count: atccData.data.data.length});
       }
       catch (error) {
         console.log("error");
       }
       console.log('atccData', this.state.atccData)
    }

    handleChangePage = (event, value) => {
        this.setState({page: value});
    };
    
    handleChangeRowsPerPage = (event, value) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10)});
        this.setState({page: 0});

      };
      
    

    render() {
        const { classes, selectedEquipment } = this.props;
        // console.log('state', this.state)
        // console.log("selectedEquipment in the ECB Modal is", selectedEquipment);
        const headerTitle = selectedEquipment.equipmentName + ' | ' + this.props.selectedEquipment.chainageName + ' | ' + this.props.selectedEquipment.directionName;
        let alert;
        if(selectedEquipment.isFaulty === 1){
        alert =   
                <div className={classes.marquee}>
                    These equipment is under Maintaince
                </div> 
        }
        const StyledTableRow = withStyles((theme) => ({
            root: {
              '&:nth-of-type(odd)': {
                backgroundColor: 'rgb(229, 244, 249)',
              },
              '&:nth-of-type(even)': {
                backgroundColor: 'white',
              },
            },
          }))(TableRow);

          //Checking if the data is null
          let tableData = ''
          let atccDataLength = 0;
          

          if(this.state.atccData == null){
            atccDataLength = 0;
            const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, atccDataLength - this.state.page * this.state.rowsPerPage);
            tableData = emptyRows > 0 && (
               <div className = {classes.gridtext}> No records to display </div>
              )
          }else{
            atccDataLength = this.state.atccData.length
            tableData = 
            (this.state.rowsPerPage > 0
                ? this.state.atccData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                : this.state.atccData
            ).map((row) => (
                <StyledTableRow key={row.VEHICLE_CLASS_NAME}>
                <TableCell component="th" scope="row" style={{ width: 320 }}>
                    {row.VEHICLE_CLASS_NAME}
                </TableCell>
                <TableCell style={{ width: 260 }}>
                    {row.AVG_SPEED}
                </TableCell>
                <TableCell style={{ width: 260 }}>
                    {row.COUNT}
                </TableCell>
                </StyledTableRow>
            ))
          }
       
        return (
             <React.Fragment>
                 <Container maxWidth="md" className={classes.container}>
                 <Card >
                            <CardHeader
                                title= {headerTitle}
                                subheader = {alert}
                                action={
                                    <IconButton onClick={() => { this.props.onClose() }}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                }
                            />
                            <Divider />
                            <CardContent>
                            <Grid container spacing={1} className={classes.gridcontainer} >
                                <Grid item md={12} xs={12} lg={12} className={classes.gridtext}>
                                    <Typography variant="h5" className="sectionHeading">Data between {this.state.fromDate} - {this.state.toDate}</Typography>
                                    
                                </Grid>
                            </Grid>
                            <Divider variant="list" className={classes.atmsPageSectionDivider} />
                            <Grid container spacing={1} className={classes.gridcontainer} >
                                <Grid item md={6} xs={6} lg={6}>
                                    <Typography variant="h5" className="sectionHeading">Direction name </Typography>
                                </Grid>
                                <Grid item md={6} xs={6} lg={6}>
                                    <Typography variant="h5" className="sectionHeading"> {this.props.selectedEquipment.directionName} </Typography>
                                </Grid>
                                
                            </Grid>
                            <Divider variant="list" className={classes.atmsPageSectionDivider} />
                            <Grid container spacing={1} className={classes.gridcontainer} >
                                <Grid item md={6} xs={6} lg={6}>
                                    <Typography variant="h5" className="sectionHeading">Chainage name </Typography>
                                </Grid>
                                <Grid item md={6} xs={6} lg={6}>
                                    <Typography variant="h5" className="sectionHeading"> {this.props.selectedEquipment.chainageName} </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="list" className={classes.atmsPageSectionDivider} />
                            <TableContainer component={Paper} className={classes.tableRoot}>
                                <Table className={classes.table} aria-label="custom pagination table">
                                <TableHead className={classes.tableHead} stickyHeader aria-label="sticky table">
                                    <TableRow>
                                        <TableCell style={{ width: 320 }} > <Typography variant="h5" className={classes.tableHeading}>Vehicle Class</Typography></TableCell>
                                        <TableCell style={{ width: 260 }} ><Typography variant="h5" className={classes.tableHeading}>Avg speed</Typography></TableCell>
                                        <TableCell style={{ width: 260 }}><Typography variant="h5" className={classes.tableHeading}>Count</Typography></TableCell>

                                    </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        {tableData}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                            rowsPerPageOptions={[3, 5, 10, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={atccDataLength}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            // ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                    </Table>
                                </TableContainer>
                           
                            </CardContent>
                            <Divider />
                        </Card>
                 </Container>
             </React.Fragment>
        );
    }
}



export default connect( null, null )(withStyles(styles)(ATCCDetails));