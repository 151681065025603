import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm, FieldArray } from 'redux-form';
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, InputLabel, Button, IconButton, Typography
} from '@material-ui/core';
import { connect } from "react-redux";
import FormHelperText from '@material-ui/core/FormHelperText'
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Toaster from '../../../components/common/Toaster';
import appConfig from '../../../appConfig.json';
import commonStyles from '../../../commonStyles';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  ...commonStyles(theme),
});

class EventResolve extends React.Component {
  constructor() {
    super();
    this.state = {
      resolveTime : ''

    };
  } 

  getEventFileSourceId(eventFileSourceName, eventFileSources) {
    console.log('getting event file source id for: ', eventFileSourceName, eventFileSources);
    if (eventFileSources != null) {
      var eventFileSourceRet = eventFileSources.find((eventFileSource) => eventFileSource.eventFileSourceName == eventFileSourceName);
      if (eventFileSourceRet != null) {
        console.log('eventFileSourceRet.eventFileSourceId: ', eventFileSourceRet.eventFileSourceId);
        return eventFileSourceRet.eventFileSourceId;
      }
    }
  }

  async componentDidMount() {

    try {
      //API 1

      const response = await axios.get(BASE_URL + "/api/eventFileSource?reqType=all");
      this.setState({
        resolveFileSourceId: this.getEventFileSourceId('RESOLVE', response.data)
      });

      //API 2
      const eventStatusResponse = await axios.get(BASE_URL + "/api/eventStatus?reqType=all");
      let resolveObj = eventStatusResponse.data.find((data) => data.eventStatusName == 'RESOLVE');

      this.setState({
        resolveStatusId: resolveObj.eventStatusId
      });
      // console.log(this.state.addInfoFileSourceId);
    } catch (error) {
      console.log(error);
    }
  }
  renderText = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
    return (
      <Typography variant="body1" gutterBottom>
        {input.value}
      </Typography>
    )
  }
  renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
    <TextField
      variant="outlined"
      fullWidth
      label={label}
      placeholder={label}
      error={touched && invalid}
      readonly={this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'RESOLVE'}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )

  renderDateField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
     console.log("input and custom in datefield", input);
        let dt = new Date();
        dt.setHours(dt.getHours() + 5);
        dt.setMinutes(dt.getMinutes() + 30);
        const currDate = dt.toISOString().slice(0, 16);
        if(this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'RESOLVE'){
         let  selectedValue = new Date(input.value)
         
        selectedValue.setHours(selectedValue.getHours() + 5);
        selectedValue.setMinutes(selectedValue.getMinutes() + 30);
        console.log('jpb', selectedValue)
          return (
            <TextField
              fullWidth
              variant="outlined"
              label={label}
              readonly={this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'RESOLVE'}
              type="datetime-local"
              value = {selectedValue.toISOString().slice(0, 16)}
              error={touched && invalid}
              helperText={touched && error}
              // className={classes.textField}
            />
          )
        }else{
          return (
            <TextField
              fullWidth
              variant="outlined"
              label={label}
              readonly={this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'RESOLVE'}
              type="datetime-local"
              error={touched && invalid}
              helperText={touched && error}
              // className={classes.textField}
              InputProps={{inputProps: { max: currDate} }}
              InputLabelProps={{
                shrink: true,
              }}
              {...input}
              {...custom}
            />
          )
        }
    
  }

  resolveEvent = (values) =>   {
    console.log(this.props.form, "this.props.form")
    // console.log(this.state.resolveStatusId, 'resolveStatusId')
    if (!!this.props.form.EventResolve.values.resolveRemarks && !!this.props.form.EventResolve.values.resolveTime) {
      var eventFilesAdded = [];
      var eventPath = this.props.selectedEvent.eventData.txnEventId;              //Location of the files inside upload. Its the txnEventId
      var eventFilesNew = this.props.form.EventResolve.values.eventFiles || [];
      var eventFilesOld = this.props.form.EventResolve.initial.eventFiles || [];

      for (var j = 0; j < eventFilesNew.length; j++) {
        if (eventFilesNew[j].hasOwnProperty("eventFile") === true) {
          eventFilesAdded.push(eventFilesNew[j]);
        }
      }

      for (var j = 0; j < eventFilesOld.length; j++) {
        if (eventFilesOld[j].hasOwnProperty("eventFile") === true) {
          eventFilesAdded.push(eventFilesOld[j]);
        }
      }

      var addEventParams = {
        eventPath: eventPath,
        eventFileSourceId: this.state.resolveFileSourceId,
        txnEventId: this.props.selectedEvent.eventData.txnEventId,
        eventTypeId: this.props.selectedEvent.eventData.eventTypeId,
        eventSubtypeId: this.props.selectedEvent.eventData.eventSubtypeId,
      }

      this.props.addEventFiles(eventFilesAdded, addEventParams);
      // console.log(this.state.eventFilesDeleted);

      this.props.deleteEventFiles(this.state.eventFilesDeleted);
      this.props.changeEventStatus(this.props.selectedEvent.eventData.txnEventId, {
        eventStatusId: this.state.resolveStatusId,
        resolveRemarks: this.props.form.EventResolve.values.resolveRemarks,
        resolveTime: this.props.form.EventResolve.values.resolveTime
      });
    }
  }
  eventFiles = ({ fields, meta: { error, submitFailed } }) => (
    <div>
      {/* <li> */}
      <input style={{ display: "none" }} ref={(ref) => this.upload = ref} type="file" onChange={(event) => {
        console.log("eventFiles", event.target.value);
        console.log("eventFiles", event.target.files[0]);

        fields.push({
          eventFileName: event.target.files[0].name,
          eventFile: event.target.files[0]
        })
      }} />
      {submitFailed && error && <span>{error}</span>}
      {/* </li> */}
      <Button  disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'RESOLVE'} variant="contained" size="large" color="primary" onClick={(e) => this.upload.click()}>Upload file</Button>
      {fields.map((member, index) => (
        // <li key={index}>
        <Grid key={index} container spacing={8}>
          <Grid item md={2} xs={12}>
            <h4>Event File #{index + 1}</h4>
          </Grid>
          <Grid item md={2} xs={12}>
            <Field
              name={`${member}.eventFileName`}
              type="text"
              component={this.renderText}
              label="File Name"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Field
              name={`${member}.eventFileExtension`}
              type="text"
              component={this.renderText}
              label="File Extension"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <IconButton onClick={() => fields.remove(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        // </li>

      ))}
    </div>
  )
  render() {
    const { classes } = this.props;
    console.log('resolve', this.props.form.EventResolve)

    return (
      <React.Fragment>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <form autoComplete="off" name="EventResolve"  onSubmit={this.props.handleSubmit(this.resolveEvent)}>
          <fieldset disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName != 'MANAGE'}>
            <Typography variant="h5" className="sectionHeading">Resolve Event</Typography>

            <Grid container spacing={8}>
              <Grid item md={4} xs={12} >
                <Field name="resolveRemarks" component={reduxFormFunctions.renderTextField} label="Resolve Remarks *"></Field>
              </Grid>
              <Grid item md={4} xs={12} >
                <Field name="resolveTime" component={this.renderDateField} label="Resolve Time *" />
              </Grid>
            </Grid>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Typography variant="h5" className="sectionHeading">Upload Resolve Proofs</Typography>
            <Grid container spacing={1}>
              <Grid item md={2} xs={12}>
                <FieldArray name="eventFiles" component={this.eventFiles} />
              </Grid>
            </Grid>
            <Divider variant="list" className={classes.atmsPageSectionDivider} />
            <Button className={classes.atmsSaveButton}  disabled={this.props.selectedEvent.eventData.eventStatus.eventStatusName != 'MANAGE' }  color="primary" size="large" variant="contained" type="submit">RESOLVE EVENT</Button>
          </fieldset>
        </form>
      </React.Fragment >
    );
  }

}

const validate = formValues => {
  const errors = {};
  let dt = new Date();
  dt.setHours(dt.getHours() + 5);
  dt.setMinutes(dt.getMinutes() + 30);
  const currDate = dt.toISOString().slice(0, 16);
  console.log(formValues, currDate)
  if (formValues.resolveTime > currDate) {
    errors.resolveTime = "Detection date-time can't be a future date-time";
  }
  if (!formValues.resolveRemarks) {
    errors.resolveRemarks = "Enter Resolve Remarks";
  }
  if (!formValues.resolveTime) {
    errors.resolveTime = "Enter Resolve Time";
  }
  return errors;
};

const mapStateToProps = state => {
  return {
    selectedEvent: state.selectedEvent,
    form: state.form,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addEventFiles: (eventfiles, eventPath, addInfoFileSourceId) => dispatch(actions.addEventFiles(eventfiles, eventPath, addInfoFileSourceId)),
    deleteEventFiles: (eventFilesDeleted) => dispatch(actions.deleteEventFiles(eventFilesDeleted)),
    changeEventStatus: (eventId, body) => dispatch(actions.changeEventStatus(eventId, body))
  }
}
EventResolve = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventResolve);

export default reduxForm({
  form: 'EventResolve',
  validate: validate,
  enableReinitialize: true,
})(withStyles(styles)(EventResolve));