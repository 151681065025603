import {
  DeenrImage,
  DeenrImageStyle,
} from "../../../components/common/DeenrImage";

export default function getDocDefinition(
  printParams,
  agGridApi,
  agGridColumnApi
) {
  const {
    PDF_HEADER_COLOR,
    PDF_INNER_BORDER_COLOR,
    PDF_OUTER_BORDER_COLOR,
    PDF_ODD_BKG_COLOR,
    PDF_EVEN_BKG_COLOR,
    PDF_HEADER_HEIGHT,
    PDF_ROW_HEIGHT,
    PDF_PAGE_ORITENTATION,
    PDF_WITH_CELL_FORMATTING,
    PDF_WITH_COLUMNS_AS_LINKS,
    PDF_SELECTED_ROWS_ONLY,
    PDF_WITH_HEADER_IMAGE,
    PDF_WITH_FOOTER_PAGE_COUNT,
    PDF_LOGO,
    PDF_HEADING,
    PDF_IMAGE,
    fromDate,
    toDate,
    shiftNames,
    directionNames,
    chainageNames,
    laneNames,
    tcNames,
    plazaNames,
    txnTypeNames,
    vehicleClassNames,
    footerValues,
    allReportFilters,
    overAllAccuracy,
  } = printParams;
  console.log(" allReportFilters ",allReportFilters)
  console.log(" footer values ",footerValues)
  console.log("PRINT PARAMS", printParams)

  return (() => {
    const columnGroupsToExport = getColumnGroupsToExport();

    const columnsToExport = getColumnsToExport();

    const widths = getExportedColumnsWidths(columnsToExport);

    const rowsToExport = getRowsToExport(columnsToExport);

    const body = columnGroupsToExport
      ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
      : [columnsToExport, ...rowsToExport];

    const headerRows = columnGroupsToExport ? 2 : 1;

    const header = PDF_WITH_HEADER_IMAGE
      ? {
          image: "ag-grid-logo",
          width: 150,
          alignment: "center",
          margin: [0, 10, 0, 10],
        }
      : null;

    // const footer = PDF_WITH_FOOTER_PAGE_COUNT
    //   ? function (currentPage, pageCount) {
    //       return {
    //         text: "Page: " + currentPage.toString() + " of " + pageCount,
    //         alignment: "center",
    //         margin: [0, 20, 0, 10],
    //         bold: true,
    //       };
    //     }
    //   : null;
   


    const footer = PDF_WITH_FOOTER_PAGE_COUNT
      ? function () {
        let reportGenDateTime=  
          Intl.DateTimeFormat('en-IN', 
          { hourCycle: 'h23',
           year: 'numeric',
           month: '2-digit', 
           day: '2-digit',
           hour: '2-digit',
           minute: '2-digit'
          }).format(new Date(footerValues?.reportGenerationDateTime))
          console.log("%%%%%%%%%%%%%%%%",reportGenDateTime)
           
        return {
            
            //footerValues.dataCompletenessStatus === 1?
                columns : [

                //   {
                //     text:  "OverAll Accuracy : " + overAllAccuracy,
                //     alignment: "left",
                //     margin: [0, 10, 0, 0],
                    
                //     bold: true,
                //   },
                  
                //   {
                //    text: footerValues?.dataCompletenessStatus?  "Data Completeness:  " + "True": "Data Completeness:  "  + "False",
                  
                  
                //   //color: footerValues?.dataCompletenessStatus? "green" :"red",
                //   //text: "Page: " + currentPage.toString() + " of " + pageCount,
                //   alignment: "center",
                //   margin: [0, 10, 0, 0],
                  
                //   bold: true,
                // },
                {
                  text:"User:  "  + footerValues?.currentUser,
                  alignment: "center",
                  margin: [0, 10, 0, 10],
                  width: 100,
                  bold: true,
                },
                
                // {
                //   text:"Generation Date & Time:  "  +  reportGenDateTime,/* (<div>
        
                //   {Intl.DateTimeFormat('en-IN', 
                //   { hourCycle: 'h23',
                //   year: 'numeric',
                //   month: '2-digit', 
                //   day: '2-digit',
                //   hour: '2-digit',
                //   minute: '2-digit'
                //   }).format(new Date(footerValues?.reportGenerationDateTime.props.children))}
                
                  
                // </div>), */
                //   //new Date(footerValues?.reportGenerationDateTime).toLocaleString("en-GB").split('SS')[0],
                //   alignment: "center",
                //   margin: [0, 10, 0, 0],
                //   width: 300,
                //   bold: true,
                // },{
                //   text:"Report Generation Count:  " + footerValues?.reportCount,
                //   alignment: "center",
                //   margin: [0, 10, 0, 0],
                //   bold: true,
                // }
              ]

       } }: null;
      

    const pageMargins = [
      10,
      PDF_WITH_HEADER_IMAGE ? 70 : 20,
      10,
      PDF_WITH_FOOTER_PAGE_COUNT ? 40 : 10,
    ];

    const heights = (rowIndex) =>
      rowIndex < headerRows ? PDF_HEADER_HEIGHT : PDF_ROW_HEIGHT;

    const fillColor = (rowIndex, node, columnIndex) => {
      if (rowIndex < node.table.headerRows) {
        return PDF_HEADER_COLOR;
      }
      return rowIndex % 2 === 0 ? PDF_ODD_BKG_COLOR : PDF_EVEN_BKG_COLOR;
    };

    const hLineWidth = (i, node) =>
      i === 0 || i === node.table.body.length ? 1 : 1;

    const vLineWidth = (i, node) =>
      i === 0 || i === node.table.widths.length ? 1 : 0;

    const hLineColor = (i, node) =>
      i === 0 || i === node.table.body.length
        ? PDF_OUTER_BORDER_COLOR
        : PDF_INNER_BORDER_COLOR;

    const vLineColor = (i, node) =>
      i === 0 || i === node.table.widths.length
        ? PDF_OUTER_BORDER_COLOR
        : PDF_INNER_BORDER_COLOR;

    const FROM_DATE = fromDate
      ? {
          text: [{ text: "From Date: ", bold: true }, fromDate],
          margin: [0, 0, 0, 5],
        }
      : null;
    const TO_DATE = toDate
      ? {
          text: [{ text: "To Date: ", bold: true }, toDate],
          margin: [0, 0, 0, 5],
        }
      : null;

    let  SHIFT = null;
    if (allReportFilters?.includes('SHIFT')) {
      SHIFT = shiftNames
        ? {
            text: [{ text: "Shift: ", bold: true }, shiftNames],
            margin: [0, 0, 0, 5],
          }
        : {
          text: [{ text: "Shift: ", bold: true }, 'All'],
          margin: [0, 0, 0, 5],
        };
    }

    let LANE = null;
    if (allReportFilters?.includes('LANE')) {
      LANE = laneNames
      ? {
          text: [{ text: "Lane: ", bold: true }, laneNames],
          margin: [0, 0, 0, 5],
        }
      : {
        text: [{ text: "Lane: ", bold: true }, "All"],
        margin: [0, 0, 0, 5],
      };
    }

    let TC_ID = null;
    if (allReportFilters?.includes('TC')) {
        TC_ID = tcNames
          ? { text: [{ text: "Tc: ", bold: true }, tcNames], margin: [0, 0, 0, 5] }
          :  {text: [{ text: "Tc: ", bold: true }, "All"], margin: [0, 0, 0, 5] };
    }
    
    let PLAZA_CODE = null;
    if (allReportFilters?.includes('PLAZA')) {
      PLAZA_CODE = plazaNames
        ? {
            text: [{ text: "Plaza: ", bold: true }, plazaNames],
            margin: [0, 0, 0, 5],
          }
        : {
          text: [{ text: "Plaza: ", bold: true }, "All"],
          margin: [0, 0, 0, 5],
        };
    }
   
    let TXN_TYPE_ID = null;

    if (allReportFilters?.includes('TXN_TYPE')) {
      TXN_TYPE_ID = txnTypeNames
      ? {
          text: [{ text: "Txn Type: ", bold: true }, txnTypeNames],
          margin: [0, 0, 0, 5],
        }
      : {
        text: [{ text: "Txn Type: ", bold: true }, "All"],
        margin: [0, 0, 0, 5],
      };
    }

    let VEHICLE_CLASS_CODE = null;

    if (allReportFilters?.includes('VEHICLE CLASS')) {
      VEHICLE_CLASS_CODE = vehicleClassNames
      ? {
          text: [{ text: "Vehicle Class: ", bold: true }, vehicleClassNames],
          margin: [0, 0, 0, 5],
        }
      : {
        text: [{ text: "Txn Type: ", bold: true }, "All"],
        margin: [0, 0, 0, 5],
      };
    }
    
    let DIRECTION = null;

    if (allReportFilters?.includes('DIRECTION')) {
      DIRECTION = directionNames
      ? {
          text: [{ text: "Direction: ", bold: true }, directionNames],
          margin: [0, 0, 0, 5],
        }
      : {
        text: [{ text: "Direction: ", bold: true }, "All"],
        margin: [0, 0, 0, 5],
      };
    }

    let CHAINAGE = null;

    if (allReportFilters?.includes('CHAINAGE')) {
        CHAINAGE = chainageNames
      ? {
          text: [{ text: "Chainage: ", bold: true }, chainageNames],
          margin: [0, 0, 0, 5],
        }
      : {
        text: [{ text: "Chainage: ", bold: true }, "All"],
        margin: [0, 0, 0, 5],
      };
    }

    let EQUIPMENT = null;

    if (allReportFilters?.includes('EQUIPMENT')) {
      
    }

    const docDefintiion = {
      pageOrientation: PDF_PAGE_ORITENTATION,
      header,
      footer,
      content: [
        { image: DeenrImage, fit: [60, 60], style: "image" },

        { text: PDF_HEADING, style: "heading" },
        {
          table:{
            widths: ['*','auto'],
            body: [
              [FROM_DATE, TO_DATE],
              [DIRECTION, CHAINAGE],
            //   [TC_ID, TXN_TYPE_ID],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: "myTable",
          table: {
            headerRows,
            widths,
            body,
            heights,
          },
        //   layout: {
        //     fillColor,
        //     hLineWidth,
        //     vLineWidth,
        //     hLineColor,
        //     vLineColor,
        //   },
        layout: {
            fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
            }
        }
        },
      ],
      images: {
        "ag-grid-logo": PDF_LOGO,
      },
      styles: {
        myTable: {
          margin: [0, 10, 0, 0],
          
        },
        tableHeader: {
          bold: true,
          margin: [0, PDF_HEADER_HEIGHT / 3, 0, 0],
          fillColor: "#5aa3df",
        },
        tableCell: {
          // margin: [0, 15, 0, 0]
        },
        heading: {
          alignment: "center",
          fontSize: 20,
          margin: [0, 0, 0, 10],
          bold: true,
        },
        image: {
        //   background: DeenrImageStyle,
        },
      },
      pageMargins,
    };

    return docDefintiion;
  })();

  function getColumnGroupsToExport() {
    let displayedColumnGroups = agGridColumnApi.getAllDisplayedColumnGroups();

    let isColumnGrouping = displayedColumnGroups.some((col) =>
      col.hasOwnProperty("children")
    );

    if (!isColumnGrouping) {
      return null;
    }

    let columnGroupsToExport = [];

    displayedColumnGroups.forEach((colGroup) => {
      let isColSpanning = colGroup.children.length > 1;
      let numberOfEmptyHeaderCellsToAdd = 0;

      if (isColSpanning) {
        let headerCell = createHeaderCell(colGroup);
        columnGroupsToExport.push(headerCell);
        // subtract 1 as the column group counts as a header
        numberOfEmptyHeaderCellsToAdd--;
      }

      // add an empty header cell now for every column being spanned
      colGroup.displayedChildren.forEach((childCol) => {
        let pdfExportOptions = getPdfExportOptions(childCol.getColId());
        if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
          numberOfEmptyHeaderCellsToAdd++;
        }
      });

      for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
        columnGroupsToExport.push({});
      }
    });

    return columnGroupsToExport;
  }

  function getColumnsToExport() {
    let columnsToExport = [];

    agGridColumnApi.getAllDisplayedColumns().forEach((col) => {
      let pdfExportOptions = getPdfExportOptions(col.getColId());
      if (pdfExportOptions && pdfExportOptions.skipColumn) {
        return;
      }
      let headerCell = createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    return columnsToExport;
  }

  function getRowsToExport(columnsToExport) {
    let rowsToExport = [];

    agGridApi.forEachNodeAfterFilterAndSort((node) => {
      if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
        return;
      }
      let rowToExport = columnsToExport.map(({ colId }) => {
        let cellValue = agGridApi.getValue(colId, node);
        let tableCell = createTableCell(cellValue, colId);
        return tableCell;
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }

  function getExportedColumnsWidths(columnsToExport) {
    return columnsToExport.map(() => 100 / columnsToExport.length + "%");
  }

  function createHeaderCell(col) {
    let headerCell = {};

    let isColGroup = col.hasOwnProperty("children");

    if (isColGroup) {
      headerCell.text = col.originalColumnGroup.colGroupDef.headerName;
      headerCell.colSpan = col.children.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.colId = col.getColId();
    }

    headerCell["style"] = "tableHeader";

    return headerCell;
  }

  function createTableCell(cellValue, colId) {
    const tableCell = {
      text: cellValue !== undefined ? cellValue : "",
      style: "tableCell",
    };

    const pdfExportOptions = getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL } = pdfExportOptions;

      if (PDF_WITH_CELL_FORMATTING && styles) {
        Object.entries(styles).forEach(
          ([key, value]) => (tableCell[key] = value)
        );
      }

      if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
        tableCell["link"] = createURL(cellValue);
        tableCell["color"] = "blue";
        tableCell["decoration"] = "underline";
      }
    }

    return tableCell;
  }

  function getPdfExportOptions(colId) {
    let col = agGridColumnApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }
}
