import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Divider, Button, Modal, Typography, Box, Card, CardContent, Grid, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import MenuCreate from './menuCreate';
import MenuEdit from './menuEdit';
import commonStyles from '../../../commonStyles';
import { Field, reduxForm } from 'redux-form';
import * as reduxFormFunction from '../../../components/common/Redux_forms_Common';
import Toaster from '../../../components/common/Toaster';
import checkEntitlement from "../../../utils/checkEntitlement"

const styles = theme => ({
    ...commonStyles(theme),
    searchBox: {
        marginTop: theme.spacing(5),
        clear: 'both'
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto'
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    }
});

class Menu extends React.Component {

    currentSelectedRow = null;
    /** @param {Record<string, any>} props */
    constructor(props) {
        super(props);
        this.state = {
            parentMenuId: "",
            urlParams: [],
            isOpen: false,
            modalType: "",
            menuLeafNode: false

        };
        this.grid = React.createRef();
    }

    componentDidMount() {
        this.props.getParentMenu();
    }

    onSearchSubmit = (event) => {
        event.preventDefault();
        console.log("state =", this.state);
        const urlParams = [];
        console.log("LENGTH: " + this.state.parentMenuId);

        if (this.state.parentMenuId !== "") {
            urlParams.push({ 'key': 'parentMenuId', value: this.state.parentMenuId });
        }

        if (this.state.menuLeafNode === true) {
            urlParams.push({ 'key': 'menuLeafNode', value: 1 });
        }
        if (this.state.menuLeafNode === false) {
            urlParams.push({ 'key': 'menuLeafNode', value: 0 });
        }

        this.setState({ urlParams: urlParams })
        console.log("urlParams : " + JSON.stringify(urlParams))
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
        console.log("change name", event.target.name);
        console.log("change value", event.target.value);
    }

    handleCheckboxChange = (event) => {
        console.log("---------------------------------------")
        console.log("leafy node value: ", event.target.value);
        console.log("leafy node checked: ", event.target.checked);
        this.setState({ menuLeafNode: event.target.checked });

    }

    checkIsLeafNode = ({ input, ...reset }) => (
        <FormControl fullWidth variant="outlined">
            <FormControlLabel
                control={<Checkbox {...input}
                    name="menuLeafNode"
                    checked={input.value ? true : false} />}
                label="Leaf Node" />
        </FormControl>
    )

    componentDidUpdate(prevProps, prevState) {
        /*
         * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
         */
        if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
            this.grid.current.reload();
        }
    }

    render() {
        const { classes } = this.props;
        let modalDiv = null;

        if (this.state.modalType === "ADD") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
                closeAfterTransition>
                <MenuCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        if (this.state.modalType === "EDIT") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, type: "EDIT" })}
                closeAfterTransition>
                <MenuEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        return (
            <div className={classes.atmsPageOuterDiv}>
                <Card className={classes.atmsPageOuterCard}>
                    <CardHeader title="Menu" />
                    <Divider />
                    <CardContent>
                        <Button variant="contained"
                            disabled={!checkEntitlement(this.props.location.pathname, 'add')}
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={() => this.setState({ isOpen: true, modalType: "ADD" })} >
                            Add Menu
                        </Button>
                        <Box mt={3} className={classes.searchBox}>
                            <Card>
                                <CardContent>
                                    <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={2}>
                                                <div style={{ maxWidth: '70px' }}>
                                                    <Typography variant="h6" gutterBottom className={classes.sheader}>
                                                        Search
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Field name="parentMenuId" component={reduxFormFunction.renderSelectField} onChange={this.handleInputChange} label="Parent Menu Name" id="parentMenuId">
                                                    <option value={""}>{""}</option>
                                                    {this.props.ArrParentMenu.map((parentMenu) => <option key={parentMenu.menuId} value={parentMenu.menuId}>{parentMenu.menuName}</option>)}
                                                </Field>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Field name="menuLeafNode" id="menuLeafNode" component={this.checkIsLeafNode} label="Leaf Node" onChange={this.handleCheckboxChange}></Field>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    className={`${classes.sheader} ${classes.addButton}`} variant="contained"
                                                    color="primary" startIcon={<SearchIcon />}
                                                    type="submit"
                                                >
                                                    Search
                                        </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box mt={3}>
                            <DataGrid downloadReport={true} ref={this.grid} endpoint="api/menu" urlParams={this.state.urlParams} isEditable={checkEntitlement(this.props.location.pathname, 'edit')} onEditClick={(event, rowData) => {
                                this.currentSelectedRow = rowData;
                                this.setState({ isOpen: true, modalType: "EDIT" })
                            }} />
                        </Box>
                    </CardContent>
                </Card>
                <Toaster
                    open={this.props.toasterOpen}
                    message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
                    severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
                />
                {modalDiv}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.menuReducer.loading,
        toasterOpen: state.common.toasterOpen,
        toasterMessageConfig: state.common.toasterMessageConfig,
        ArrParentMenu: state.menuReducer.ArrParentMenu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getParentMenu: () => dispatch(actions.getParentMenu()),
        menuStateReset: () => dispatch(actions.menuStateReset())
    }
}

Menu = connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);

export default reduxForm({
    form: "Menu"
})(withStyles(styles)(Menu));