/* eslint-disable */
import React, { Component } from 'react';
import { connect } from "react-redux";


// import Card from "@material-ui/core/Card";
// import CardHeader from "@material-ui/core/CardHeader";
// import CardContent from "@material-ui/core/CardContent";

import { withStyles } from "@material-ui/core/styles";
import commonStyles from "../../../commonStyles";
import {
  Card, CardHeader, Divider, CardContent, Grid,
  TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, Button, InputLabel, Box, Typography, Modal,List,ListItem,ListItemIcon,ListItemText
} from '@material-ui/core';

import { Field, reduxForm } from 'redux-form';

import axios from "axios"
// import MenuLeafNode from './MenuLeafNode';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import appConfig from '../../../appConfig.json';
import { selectedRowsCountSelector } from '@material-ui/data-grid';
import { useSelector, useDispatch } from "react-redux";
import AssignedReportList from './AssignedReportList';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const BASE_URL = appConfig.BASE_URL;


const styles = (theme) => ({
  ...commonStyles(theme),
  treeView: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'100%'
  }
});

let expandList = [];
let currentUserRoleId ;
class ReportList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      selectedRoleEntitleMents: [],
      isUpdated: false,
      selectedRoleReports: [],
      allreportList: [],
      checked:[],
      selectedReport:[{"reportDetailsId": ""}],
      alert: {
        msg: null,
        severity: 'success'
      },
      openSnakbar: false,
      isRefresh:false,
      reportCheckedState: {}

    }
  }

  // currentUserRoleId = useSelector(state => state.auth.user?.userRoleId);
  // var selectedReports = [];


  //handleToggle = (value) => () => {
    
  //  const currentIndex = this.state.checked.indexOf(value);
  //  console.log("%%%%%%%%%%%%", this.state.checked)
  //  const newChecked = [...this.state.checked];
  //  console.log("currentIndex", currentIndex)
  //  console.log("newChecked", newChecked)

  //  if (currentIndex === -1) {
  //    newChecked.push(value);
  //    expandList.push({"reportDetailsId":value, "roleId":this.props.selectedRoleId})
  //    // this.state.selectedReport.push("reportDetailsId")
  //  } else {
  //    newChecked.splice(currentIndex, 1);
  //    expandList.splice(currentIndex,1);
  //  }

  //  this.setState({checked : newChecked});
  //  // this.setState({
  //  //   selectedReport: {
  //  //     ["reportDetailsId"]: newChecked
  //  //   }
  //  // })
  //  // this.setState({selectedReport: newChecked})
  //};

  handleToggle = (value) => () => {
    const { checked, reportCheckedState } = this.state;
  
    const isChecked = !reportCheckedState[value];
  
    // Update the checked state for the specific report
    this.setState((prevState) => ({
      reportCheckedState: {
        ...prevState.reportCheckedState,
        [value]: isChecked,
      },
    }));
  
    // Update the checked array
    const newChecked = isChecked ? [...checked, value] : checked.filter((item) => item !== value);
  
    // Update the expandList
    const newExpandList = isChecked
      ? [...expandList, { reportDetailsId: value, roleId: this.props.selectedRoleId }]
      : expandList.filter((item) => item.reportDetailsId !== value);
  
    this.setState({
      checked: newChecked,
    });
  
    expandList = newExpandList;
  };
  
  

  openSnakbar = (alertType, alertMsg) => {
    this.setState({ openSnakbar: true, alert: { msg: alertMsg, severity: alertType } })
  }

  handleClick = () => {
    // Notify the parent component to refresh
    this.props.onRefresh2();
  }

  handleUpdate = async () => {
    // const UPDATE_URI = 'http://localhost:3030/api/roleEntitlements'
    const UPDATE_URI = BASE_URL + '/api/reportPrivileges'

    await axios.patch(UPDATE_URI, [...expandList]).then((res) => {
      this.openSnakbar('success', 'Assigned Report Successfully !');
      // this.setState({ editedEntitleMents: [], isUpdated: true, isSubmiting: false })
      this.setState({ isUpdated: true })
      this.setState({ isRefresh: true })
      expandList = [];
      // <AssignedReportList
      //   isRefresh={this.setState({ isRefresh: true })}
      // /> 
      
     this.handleClick();

    }).catch(e => {
      console.log("error in udating entitlements", e)
      this.openSnakbar('error', 'Error !! Error while Assigning Report !!!');
    })
  }



  // render menu list recursively
  renderNode = (node) => {
    expandList.push(node.menuId.toString())
    return node.children.length > 0
      ?
      this.getMenuRootNode(node)
      : "eerewrew"
      // : <MenuLeafNode
      //   key={node.menuId}
      //   node={node} selectedRoleId={this.props.selectedRoleId}
      //   updateNodeEntitlements={this.props.updateNodeEntitlements}
      //   selectedRoleEntitleMents={this.state.selectedRoleEntitleMents}
      // />
  }

  
  // if user role change update entitlements
  watchSelectedChangeRole(currentUserRole) {
    if (this.props.selectedRoleId === '') {
      this.setState({ selectedRoleEntitleMents: [] })
    } else {
      this.setState({ isLoading: true })
      // const SelctedRoleEndPoint = `http://localhost:3030/api/roleEntitlements?roleId=${selectedRoleId}&entitlementsType=0`
      // const SelctedRoleEndPoint = BASE_URL + "/api/reportPrivileges?roleId%21#{this.props.currentUserRoleId}"
      const SelectedRoleReports = BASE_URL + `/api/reportPrivileges?roleId=${currentUserRole}`
      // const SelctedRoleEndPoint = BASE_URL + `/api/reportDetails?reqType=all`
      const SelctedRoleEndPoint = BASE_URL + `/api/reportPrivileges?roleId=${this.props.selectedRoleId}`



      // const SelctedRoleEndPoint = BASE_URL + `/api/reportPrivileges?roleId=1`


      axios.get(SelctedRoleEndPoint).then((res) => {
        const initialReportCheckedState = res.data.reduce((acc, report) => {
          acc[report.reportDetailsId] = false; // Set to true or false based on your logic
          return acc;
        }, {});
        this.setState({ selectedRoleEntitleMents: [...res.data],reportCheckedState: initialReportCheckedState, isLoading: false })
      }).catch(e => {
        console.log("error in feching entitlements", e)
        this.props.openSnakbar('Error', 'Error in fetching Entitlements!!!');
        this.setState({ selectedRoleEntitleMents: [], isLoading: false })
      })

      axios.get(SelectedRoleReports).then((res) => {
        const initialReportCheckedState = res.data.reduce((acc, report) => {
          acc[report.reportDetailsId] = false; // Set to true or false based on your logic
          return acc;
        }, {});
        this.setState({ selectedRoleReports: [...res.data],reportCheckedState: initialReportCheckedState, isLoading: false })
      }).catch(e => {
        console.log("error in feching entitlements", e)
        this.props.openSnakbar('Error', 'Error in fetching Entitlements!!!');
        this.setState({ selectedRoleReports: [], isLoading: false })
      })
    }
  }

  allReportsList(currentUserRoleId) {
    // if (this.props.selectedRoleId === '') {
    //   this.setState({ selectedRoleEntitleMents: [] })
    // } else {
      this.setState({ isLoading: true })
      // const SelctedRoleEndPoint = `http://localhost:3030/api/roleEntitlements?roleId=${selectedRoleId}&entitlementsType=0`
      // const SelctedRoleEndPoints = BASE_URL + `/api/reportPrivileges?roleId=${currentUserRoleId}`
      // const SelctedRoleEndPoint = BASE_URL + `/api/reportPrivileges?roleId=${selectedRoleId}`
      const SelctedRoleEndPoints = BASE_URL + `/api/reportDetails?reqType=all`


      // const SelctedRoleEndPoint = BASE_URL + `/api/reportPrivileges?roleId=1`


      axios.get(SelctedRoleEndPoints).then((res) => {
        this.setState({ allreportList: [...res.data], isLoading: false })
      }).catch(e => {
        console.log("error in feching entitlements", e)
        this.props.openSnakbar('Error', 'Error in fetching Entitlements!!!');
        this.setState({ allreportList: [], isLoading: false })
      })
    // }
  }


  componentDidUpdate(prevProps, prevState) {
    // if role change update values
    if (this.state.isUpdated || this.state.setUserName) {
      this.watchSelectedChangeRole(this.props.currentUserRoleId)
      this.setState({ isUpdated: false })
    }
    // if (prevProps.selectedRoleId !== this.props.selectedRoleId) {
    //   this.watchSelectedChangeRole(this.props.selectedRoleId)
    // }
    if (prevProps.refreshSibling1 !== this.props.refreshSibling1 && this.props.refreshSibling1) {
      console.log('SiblingComponent2 Refreshed');
      this.watchSelectedChangeRole(this.props.currentUserRoleId)
    }

  }

  componentDidMount() {
    this.watchSelectedChangeRole(this.props.currentUserRoleId)
    // this.allReportsList(this.props.currentUserRoleId);
  }

  handleClose = () => {
    this.setState({ openSnakbar: false })
  }



  // renderCheckbox = ({ input, label }) => (
  //   <div>
  //     <FormControlLabel
  //       control={
  //         <Checkbox
  //           checked={
  //             // (this.state.stakeholderArray.indexOf(input.name) !== -1 ? true : false)
  //             (this.props.form.EventGeneralDtsForm.values.stakeHolder && this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',').indexOf(input.name) !== -1) ? true : false
  //           }
  //           onChange={(evt) => {
  //             input.onChange(evt);
  //             if (evt.target.checked) {
  //               // let stakeholderArray = [];
  //               if (this.props.form.EventGeneralDtsForm.values.stakeholder != null) {
  //                 this.setState({
  //                   stakeholderArray : this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',')
  //                 })
  //                 // this.state.stakeholderArray = this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',');
  //                 console.log('STAKEHOLDERS', this.state.stakeholderArray)
  //               }
  //               this.state.stakeholderArray.push(input.name);
  //               console.log('STAKEHOLDERS', this.state.stakeholderArray)
  //               this.props.change('stakeHolder', this.state.stakeholderArray.join());
  //             }
  //             if(!evt.target.checked){
  //               console.log('unchecked',input.name );
  //               if(this.state.stakeholderArray.indexOf(input.name) !== -1){
  //                 this.state.stakeholderArray.splice (this.state.stakeholderArray.indexOf(input.name), 2)
  //                 console.log('STAKEHOLDERS', this.state.stakeholderArray)
  //                 this.props.change('stakeHolder', this.state.stakeholderArray.join())
  //               }
  //             }

  //           }}
  //         />
  //       }
  //       label={label}
  //     />
  //   </div>
  // )
  // rendering tree
  // getMenuRootNode = (node) => {
  //   return (
  //     <TreeItem style={{ padding: '0.5em' }} nodeId={node.menuId.toString()} label={node.menuName} key={node.menuId.toString()}>
  //       { node.children.map(e => this.renderNode(e))}
  //     </TreeItem >
  //   );
  // }
 
  // rendering entitlements
  render() {
    const { classes } = this.props;
    
    const tree = <><TreeView
      className={classes.treeView}
      defaultExpanded={expandList}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {/* {this.props.menuItems.length >= 0 && this.props.menuItems.map((menu) => <div key={menu.menuId}>{this.renderNode(menu)}</div>)} */}
    </TreeView></> 
    

    // console.log("selectedRoleReports", this.state.selectedRoleReports)
    let reportList = this.state.selectedRoleEntitleMents.map(e => e.reportDetails);
    let roleReportList = this.state.selectedRoleReports.map(e => e.reportDetails);
    let roleReportLists = roleReportList.map(({isEnabled,...rest}) => ({...rest}))

    // console.log("reportList##############", reportList);
    // console.log("roleReportLists", roleReportList);

    const filteredReportList = roleReportList.filter(i => reportList.every(j => i["reportDetailsId"] !== j["reportDetailsId"]))

    console.log("filtered-N-A", filteredReportList)

    // const difference = reportList.filter((o1) => !roleReportList.some((o2) => o1.reportDetailsId === o2.reportDetailsId));
    // console.log("difference", difference)
    // let totalReportsList = this.state.allreportList.map(e=> e.reportDetails)
    // console.log("selectedRoleEntitleMents",this.state.selectedRoleEntitleMents)
    // console.log("##################reportList", roleReportLists)
    // console.log("roleReportList", roleReportList)
    // console.log("d", d)
    // console.log("totalReportList", totalReportsList)
    // console.log("this.state.checked", this.state.checked)
    // console.log("selectedReport", this.state.selectedReport)
      console.log("expandList-N-A", expandList)
    // console.log("currentUserRole", this.state.currentUserRoleId)
    // console.log("selectedRoleId", this.state.selectedRoleId)
    console.log("this.props", this.props)

    return (
      <div>
        <Card >
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSnakbar} autoHideDuration={6000} onClose={this.handleClose}>
          <MuiAlert className={classes.snakbar} elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.alert.severity}>
            {this.state.alert.msg}
          </MuiAlert>
        </Snackbar>
          <CardHeader
            className={classes.textPrimary}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            title='Non-Assigned Reports'
          />
          <Divider variant="list" className={classes.atmsPageSectionDivider} />
          <CardContent
           
            style={{ height: "50vh", overflow: "auto", fontWeight: 'bold' }}
            
          >
            {/* {this.state.isLoading ? <div className={classes.loader}><CircularProgress /> </div> : tree} */}
            
            {/* <Typography variant="h5">Assigned Reports</Typography> */}
            <FormGroup row>
              <Grid container spacing={6}>
                <List style={{ marginTop: "2em" }}>
                  
                  {filteredReportList && filteredReportList.map((report) => {
                    const labelId = `transfer-list-item-${report.reportDetailsId}-label`
                    // console.log("labelId", labelId)
                    // console.log("$$$$$$$$$$$$$$$$$$",report.reportDetailsId, report.reportName)
                    return (
                      
                      <Grid item md={2} xs={12}>
                        <ListItem
                          
                          disablePadding
                          key={report.reportDetailsId}
                          // role="listitem"
                          button
                          // onClick={this.handleToggle(report.reportDetailsId)}
                        >
                            <Checkbox 
                            // (this.state.stakeholderArray.indexOf(input.name) !== -1 ? true : false)
  //   (this.props.form.EventGeneralDtsForm.values.stakeHolder && this.props.form.EventGeneralDtsForm.values.stakeHolder.split(',').indexOf(input.name) !== -1) ? true : false
                              checked={this.state.reportCheckedState[report.reportDetailsId]}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              onChange={this.handleToggle(report.reportDetailsId)}
                              
                            />
                          {/* {totalReportsList && totalReportsList.map(rep => {
                            {console.log("rep", rep)} */}
                            <ListItemText 
                                id={report.reportDetailsId} 
                                primary={`${report.reportName} REPORT`}
                                primaryTypographyProps={{ 
                                  variant: 'subtitle2', 
                                  style: {
                                      whiteSpace: 'nowrap',
                                      // overflow: 'hidden',
                                      textOverflow: 'ellipsis'
                                  }
                                } 
                                }/>
                          {/* })} */}

                        </ListItem>

                        
                          
                      </Grid>
                    )
                  })}
                </List>

                
                
              </Grid>
            </FormGroup>

          </CardContent>
        </Card>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          >
          {/* <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">EDIT</Button> */}
          <Button 
            className={classes.button} 
            color="primary" 
            size="large" 
            variant="contained" 
            onClick={this.handleUpdate} 
            disabled={!expandList.length > 0}
          >ASSIGN</Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuItems: state.menu.menuItems,
    currentUserRoleId: state.auth.user.userRoleId
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ReportList));