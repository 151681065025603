import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';


//#region Styles
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
});
//#endregion

class EquipmentsForm extends React.Component {
    constructor() {
        super();
        this.state = {
            equipmentCategoryId: 0,
            equipmentTypeId: 0,
            directionId: 0,
            chainageId: 0,
            directionName: "",
            chainageName: ""
        };
    }
    componentDidMount() {
        if (this.props.initialValues && this.props.type === "Edit") {
            this.setState({
                directionId: this.props.initialValues.directionId,
                directionName: this.props.initialValues.directionName,
                chainageId: this.props.initialValues.chainageId,
                chainageName: this.props.initialValues.chainageName,
                equipmentCategoryId: this.props.initialValues.equipmentCategoryId
            });
        }
        this.props.getEquipmentsCat();
        this.props.getEquipmentsGroup();
        this.props.getEquipmentsDir();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.equipmentCategoryId !== prevState.equipmentCategoryId) {
            this.props.getEquipmentsType(this.state.equipmentCategoryId);
        }
        if (this.state.directionId !== prevState.directionId) {
            this.props.getEquipmentsChainage(this.state.directionId);
        }
    }
    onDirectionChange = event => {
        const directionsArray = this.props.arrEquipmentsDirProps;
        if (event.target.value == 0) {
            this.setState({
                directionId: event.target.value,
                directionName: null,
                chainageId: 0,
                chainageName: null
            });
        } else {
            const result = directionsArray.find(direction => direction.directionId == event.target.value);  //Here I found a wierd behaviour. If I use === istead of == then result will be undefined.
            this.setState({
                directionId: event.target.value,
                directionName: result.directionName
            });
        }
    }
    // To set chainageName 
    onChainageChange = event => {
        const chainageArray = this.props.arrEquipmentsChainageProps;
        if (event.target.value == 0) {
            this.setState({
                chainageId: event.target.value,
                chainageName: null
            })
        } else {
            const result = chainageArray.find(chainage => chainage.chainageId == event.target.value); //Not working if === is used instead of ==
            this.setState({
                chainageId: event.target.value,
                chainageName: result.chainageName
            })
        }

    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    onReset = () => {
        this.setState({
            equipmentTypeId: 0,
            chainageId: 0,
            directionName: "",
            chainageName: "",
        })
        this.props.reset();
    }
    //#region Form Submit
    onSubmit = (values) => {
        const eqState = this.state;
        console.log("onSubmit values: ", values);
        console.log("onSubmit state: ", eqState);
        this.props.onSubmit(values, eqState);
    }
    //#endregion

    //#region render()
    render() {
        const { classes, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;

        console.log("------------------------------------------")
        console.log("directionName: ", this.state.directionName)
        console.log("chainageName: ", this.state.chainageName)
        console.log("directionId: ", this.state.directionId)
        console.log("chainageId: ", this.state.chainageId)
        console.log("equipmentCategoryId: ", this.state.equipmentCategoryId)
        console.log("initialValues: ", this.props.initialValues)

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={this.onReset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit" disabled={pristine || invalid}>SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <Card>
                        <CardHeader
                            subheader={this.props.type + " Equipments"}
                            title="Equipments"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.handleInputChange} label=" Equipment Category" id="equipmentCategoryId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrEquipmentsCatProps.map((userRoleProp) => <option key={userRoleProp.equipmentCategoryId} value={userRoleProp.equipmentCategoryId}>{userRoleProp.equipmentCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.handleInputChange} label="Equipment Type" id="equipmentTypeId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrEquipmentsTypeProps.map((arrEquipmentsType) => <option key={arrEquipmentsType.equipmentTypeId} value={arrEquipmentsType.equipmentTypeId}>{arrEquipmentsType.equipmentTypeName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentGroupId" component={reduxFormFunctions.renderSelectField} onChange={this.handleInputChange} label="Equipment Group" id="equipmentGroupId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrEquipmentsGroupProps.map((userRoleProp) => <option key={userRoleProp.equipmentGroupId} value={userRoleProp.equipmentGroupId}>{userRoleProp.equipmentGroupName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionId" component={reduxFormFunctions.renderSelectField} onChange={this.onDirectionChange} label="Direction" id="directionId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrEquipmentsDirProps.map((arrEquipmentsDir) => <option key={arrEquipmentsDir.directionId} value={arrEquipmentsDir.directionId}>{arrEquipmentsDir.directionName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="chainageId" component={reduxFormFunctions.renderSelectField} onChange={this.onChainageChange} label="Chainage" id="chainageId">
                                        <option value={0}>{''}</option>
                                        {this.props.arrEquipmentsChainageProps.map((arrEquipmentsChainage) => <option key={arrEquipmentsChainage.chainageId} value={arrEquipmentsChainage.chainageId}>{arrEquipmentsChainage.chainageName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="equipmentName" component={reduxFormFunctions.renderTextField} label=" Equipment Name" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="unqEqptypeCode" component={reduxFormFunctions.renderTextField} label="Unique Equipment Code" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="latitude" component={reduxFormFunctions.renderTextField} label="Latitude" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="longitude" component={reduxFormFunctions.renderTextField} label="Longitude" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="ipAddress" component={reduxFormFunctions.renderTextField} label="IP Address" />
                                </Grid>

                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>

                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container >
        );
    }
    //#endregion
}

//#region Validations
const validate = (formValues) => {
    const errors = {};

    if (!formValues.equipmentCategoryId || formValues.equipmentCategoryId == 0) {
        errors.equipmentCategoryId = 'Select Category';
    }
    if (!formValues.equipmentTypeId || formValues.equipmentTypeId == 0) {
        errors.equipmentTypeId = 'Select Type';
    }

    if (!formValues.equipmentGroupId) {
        errors.equipmentGroupId = 'Select Group';
    }
    if (!formValues.directionId || formValues.directionId == 0) {
        errors.directionId = 'Select Direction';
    }
    if (!formValues.chainageId || formValues.chainageId == 0) {
        errors.chainageId = 'Select Chainage';
    }
    if (!formValues.equipmentName) {
        errors.equipmentName = 'Enter Name';
    } else if (!/^[a-zA-Z0-9_ ]*$/.test(formValues.equipmentName)) {
        errors.userDetailName = 'Name should only have alphanumeric, underscores and spaces';
    }
    if (!formValues.unqEqptypeCode) {
        errors.unqEqptypeCode = 'Enter unique Eqp type Code';
    }
    if (!formValues.longitude) {
        errors.longitude = 'Enter Logitude';
    } else if (!/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(formValues.longitude)) {
        errors.longitude = 'Enter correct format';
    }
    if (!formValues.latitude) {
        errors.latitude = 'Enter Latitude';
    } else if (!/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(formValues.latitude)) {
        errors.latitude = 'Enter correct format';
    }
    if (!formValues.ipAddress) {
        errors.ipAddress = 'Enter IP Address';
    } else if (!/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(formValues.ipAddress)) {
        errors.ipAddress = 'Enter correct format';
    }

    return errors;
};
//#endregion

const mapStateToProps = state => {
    return {
        arrEquipmentsCatProps: state.equipmentsReducer.arrEquipmentsCat,
        arrEquipmentsTypeProps: state.equipmentsReducer.arrEquipmentsType,
        arrEquipmentsGroupProps: state.equipmentsReducer.arrEquipmentsGroup,
        arrEquipmentsDirProps: state.equipmentsReducer.arrEquipmentsDir,
        arrEquipmentsChainageProps: state.equipmentsReducer.arrEquipmentsChainage
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        getEquipmentsCat: () => dispatch(actions.getEquipmentsCat()),
        getEquipmentsType: (state) => dispatch(actions.getEquipmentsType(state)),
        getEquipmentsGroup: () => dispatch(actions.getEquipmentsGroup()),
        getEquipmentsDir: () => dispatch(actions.getEquipmentsDir()),
        getEquipmentsChainage: (state) => dispatch(actions.getEquipmentsChainage(state)),
        equipmentsStateReset: () => dispatch(actions.equipmentsStateReset()),
    };
};

EquipmentsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EquipmentsForm);

export default reduxForm({
    form: 'EquipmentsForm',
    validate: validate,
})(withStyles(styles)(EquipmentsForm));