import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { Paper, Container, IconButton, Card, CardHeader, Divider, CardContent, Box, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactToPrint from "react-to-print";
import ReactToPdf from 'react-to-pdf';

const styles = theme => ({
  container: {
    display: 'flex',
    outline: 'none',
    padding: '0px',
  },
  button: {
    marginRight: theme.spacing(2)
  },
  paperDiv: {
    'overflow-y': 'auto',
    margin: '10px'
  }
});

class ChallanView extends React.Component {
  constructor() {
    super();
    this.pdfRef = React.createRef();
  }
  render() {
    const { classes } = this.props;
    console.log("challan view", this.props);
    return (
      <React.Fragment>

        <Container maxWidth="md" className={classes.container}>
          <Card className={classes.paperDiv}>
            <CardHeader
              title="TRAFFIC CHALLAN"
              action={
                <IconButton onClick={() => { this.props.onClose() }}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              }
            />
            <Divider />
            <CardContent ref={el => (this.componentRef = el)}>
              <div ref={this.pdfRef}>
              <Paper className={classes.paperDiv}>
                {parse(this.props.challanContent ? this.props.challanContent : "")}
              </Paper>
              </div>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}>
              <ReactToPrint
                trigger={() => <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">PRINT</Button>}
                content={() => this.componentRef}
              />
              <ReactToPdf targetRef={this.pdfRef} scale={0.88} x={.5} y={.5} filename="challan.pdf">
                {({ toPdf }) => (
                  <Button className={classes.button} color="primary" size="large" variant="contained" onClick={toPdf}>Generate pdf</Button>
                )}
              </ReactToPdf>
            </Box>
          </Card>
        </Container>
      </React.Fragment>
    );

  };
}


export default withStyles(styles)(ChallanView);