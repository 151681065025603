import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class DirectionAddForm extends React.Component {

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Direction"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Direction"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Directions"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionName" id="directionName" component={reduxFormFunctions.renderTextField} label="Direction Name" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionAlias" id="directionAlias" component={reduxFormFunctions.renderTextField} label="Direction Alias" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionType" id="directionType" component={reduxFormFunctions.renderTextField} label="Direction Type" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionDescription" id="directionDescription" component={reduxFormFunctions.renderTextField} label="Direction Description" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionLatitudeStart" id="directionLatitudeStart" component={reduxFormFunctions.renderTextField} label="Direction Latitude Start" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionLatitudeEnd" id="directionLatitudeEnd" component={reduxFormFunctions.renderTextField} label="Direction Latitude End" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionLongitudeStart" id="directionLongitudeStart" component={reduxFormFunctions.renderTextField} label="Direction Longitude Start" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="directionLongitudeEnd" id="directionLongitudeEnd" component={reduxFormFunctions.renderTextField} label="Direction Longitude End" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="totalKilometers" id="totalKilometers" component={reduxFormFunctions.renderTextField} label="Total Kilometers" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
}

//#region Validations
const validate = formValues => {
    const error = {};

    if (!formValues.directionName) {
        error.directionName = "Enter Direction Name";
    }
    if (!formValues.directionAlias) {
        error.directionAlias = "Enter Direction Alias";
    }
    if (!formValues.directionDescription) {
        error.directionDescription = "Enter Direction Description";
    }
    if (formValues.directionDescription) {
        if (formValues.directionDescription.length > 201) {
            error.directionDescription = "Maximum characters should be 200 only";
        }
    }
    if (!formValues.directionType) {
        error.directionType = "Enter Direction type";
    }
    if (!formValues.directionLatitudeStart) {
        error.directionLatitudeStart = "Enter Direction Latitude Starting point";
    }
    if (!formValues.directionLatitudeEnd) {
        error.directionLatitudeEnd = "Enter Direction Latitude Ending point";
    }
    if (!formValues.directionLongitudeStart) {
        error.directionLongitudeStart = "Enter Direction Longitude Starting point";
    }
    if (!formValues.directionLongitudeEnd) {
        error.directionLongitudeEnd = "Enter Direction Longitude Ending point";
    }
    if (formValues.directionLongitudeStart && formValues.directionLongitudeEnd) {
        if (formValues.directionLongitudeStart === formValues.directionLongitudeEnd) {
            error.directionLongitudeEnd = "Longitude Start and End points can not be same";
        }
    }
    if (formValues.directionLatitudeStart && formValues.directionLatitudeEnd) {
        if (formValues.directionLatitudeStart === formValues.directionLatitudeEnd) {
            error.directionLatitudeEnd = "Latitude Start and End points can not be same";
        }
    }
    return error;
};

export default reduxForm({
    validate: validate,
    form: 'DirectionAddForm'
})(withStyles(styles)(DirectionAddForm));