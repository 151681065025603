import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, Divider, CardContent, Grid, Typography, Paper, Button, TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, InputLabel, Input, ListItem, Radio, RadioGroup, Modal
} from '@material-ui/core';
import { connect } from "react-redux";
import { DataGrid } from '@material-ui/data-grid';
import * as actions from '../../store/actions/index';
import axios from 'axios';
import _ from 'lodash';
import { autofill } from 'redux-form';
import MaterialTable from "material-table";
import appConfig from '../../appConfig.json';
import Toaster from '../../components/common/Toaster';
import UpstreamCCTV from './manageevent/eventEquipmentModals/UpstreamCCTV';


const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  outerDiv: {
    'margin-top': theme.spacing(1),
    'margin-left': theme.spacing(3),
    'margin-right': theme.spacing(3),
    flexGrow: 1,
  },
  submitButton: {
    float: 'right'
  }
});

class ApproveRejectEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      radioAR: 'ACCEPT'
    };
  }

  componentDidUpdate(newProps) {
    if (this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'ACTIVE' || this.props.selectedEvent.eventData.eventStatus.eventStatusName == 'REJECT') {
      this.props.history.push('/manageevent');
    }
  }

  onEquipmentModalClose = () => {
    this.setState({
      modalType: "",
      modalOpen: false
    })
  }

  componentDidMount() {
    axios
      .get(BASE_URL + "/api/eventStatus?reqType=all")
      .then(res => {
        if (res.data != null) {
          this.setState({
            eventRejectId: this.getEventStatusId('REJECT', res.data),
            eventActiveId: this.getEventStatusId('ACTIVE', res.data)
            // eventManageId: this.getEventStatusId('MANAGE', res.data)
          })
        }
      }).catch(error => {
      });
  }

  handleRadioAR = (event) => {
    this.setState({
      radioAR: parseInt(event.target.value),
    })
  }

  handleRejectionRemarks = (event) => {
    this.setState({
      rejectionRemarks: event.target.value
    })
  }

  submitEventStatus = () => {
    console.log('radioAr', this.state.radioAR)
    let body = {
      eventStatusId: this.state.radioAR
    };
    if(this.state.radioAR == this.state.eventRejectId){
      body['eventStatus'] = 'Rejected'
    }

    if(this.state.radioAR == this.state.eventActiveId){
      body['eventStatus'] = 'Accepted'
    }
    
    if (this.state.radioAR == this.state.eventRejectId && this.state.rejectionRemarks != null && this.state.rejectionRemarks != "") {
      body.resolveRemarks = this.state.rejectionRemarks;
    }
    console.log(body, 'body')
    this.props.changeEventStatus(this.props.selectedEvent.eventData.txnEventId, body)
  }

  getEventStatusId(eventStatusName, eventStatusDetails) {
    let eventStatus = eventStatusDetails.find((eveStatus) => eveStatus.eventStatusName == eventStatusName);
    if (eventStatus != null) {
      return eventStatus.eventStatusId;
    }
  }

  render() {
    const { classes } = this.props;
    console.log(' this.props.selectedEvent: ', this.props);
    let eventData = this.props.selectedEvent.eventData;
    
    return (
      <React.Fragment >
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <div className={classes.outerDiv}>
          <Card>
            <CardHeader title="Approval" />
            <Divider />
            <CardContent>
              <Grid container direction="row" justify="space-between">
                <Grid item xs={3} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                  Event Approval
                    </Typography>
                </Grid>
                <Grid item xs={6} container direction="row" className={classes.gridItem}>
                  <Grid item xs={6} className={classes.gridItem}>
                    <RadioGroup row name="accept_reject" value={this.state.radioAR} onChange={this.handleRadioAR}>
                      <FormControlLabel
                        value={this.state.eventActiveId}
                        control={<Radio color="primary" />}
                        label="Accept"
                        labelPlacement="right"
                      />
                      <FormControlLabel
                        value={this.state.eventRejectId}
                        control={<Radio color="primary" />}
                        label="Reject"
                        labelPlacement="right"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}>
                    {this.state.radioAR === this.state.eventRejectId &&
                      <TextField
                        name="remarks"
                        label="Rejection Remarks"
                        multiline
                        fullWidth
                        rows={2}
                        variant="outlined"
                        onChange={this.handleRejectionRemarks}
                      />
                    }
                  </Grid>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button variant="contained" color="primary" className={classes.submitButton} onClick={this.submitEventStatus}>
                    Submit
                </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Source" />
            <Divider />
            <CardContent>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Notification Source
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                   {(eventData.equipmentType !== null) ? eventData.equipmentType.equipmentTypeName :''}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Equipment
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {(eventData.equipment !== null) ? eventData.equipment.equipmentName : ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Detection Time
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(eventData.detectionTime))}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Status
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventStatus.eventStatusName}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider />
          <Card>
            <CardHeader title="Event" />
            <Divider />
            <CardContent>
              <Grid container spacing={8}>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Type
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                  {(eventData.eventType !== null) ? eventData.eventType.eventTypeName :''}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Sub Type
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                  {(eventData.eventSubtype !== null) ? eventData.eventSubtype.eventSubtypeName :''}
                    {/* {eventData.eventSubtype.eventSubtypeName} */}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Severity
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventSeverity && eventData.eventSeverity.severityName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Estimated Duration
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.estimatedDuration}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Description
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventDescription}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Event Reason
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventReason && eventData.eventReason.eventReasonName}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider />
          <Card>
            <CardHeader title="Location" />
            <Divider />
            <CardContent>
              <Grid container spacing={8}>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Chainage
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.chainageName}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Direction
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.directionName}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Upstream
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.upstreamLocation}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Downstream
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.downstreamLocation}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Latitude
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventLatitude}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridItem}>
                  <Typography variant="h6" gutterBottom>
                    Longitude
                    </Typography>
                  <Typography variant="body2" gutterBottom>
                    {eventData.eventLongitude}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider />
          <Card>
            <CardHeader title="Feeds" />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={3} xs={12}>
                <Button variant="contained" size="large" color="primary" onClick={() => this.setState({modalOpen:true})}>
                  UPSTREAM CCTV
                <Modal open={this.state.modalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <UpstreamCCTV selectedEquipment={this.props.selectedEvent.eventData.chainageEquipment} onClose={this.onEquipmentModalClose}></UpstreamCCTV>
                </Modal>
                                        </Button>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button variant="contained" size="large" color="primary">
                    UPSTREAM PTZ
                  </Button>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button variant="contained" size="large" color="primary">
                  DOWNSTREAM CCTV
                  </Button>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button variant="contained" size="large" color="primary">
                    DOWNSTREAM PTZ
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </React.Fragment >
    );
  }
}


const mapStateToProps = state => {
  return {
    selectedEvent: state.selectedEvent,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeEventStatus: (eventId, body) => dispatch(actions.changeEventStatus(eventId, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApproveRejectEvent));