import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';
import _ from 'lodash';

const initialState = {
  vmsAlerts: null,
  smsAlert: null,
  emailAlert: null,
  webAlert: null,
  recentWebAlerts: [],
  availableVehicles: [],
  notificationAlerts: [],
  alertSentIndicator: null
};

const setVMSAlerts = (state, action) => {
  return updateObject(state, {
    vmsAlerts: action.vmsAlerts
  });
};
const vmsMessageSent = (state, action) => {
  return updateObject(state, {
    vmsMessageResponse: action.message
  });
};

const setSMSAlert = (state, action) => {
  return updateObject(state, {
    smsAlert: action.smsAlert
  });
};
const smsSent = (state, action) => {
  return updateObject(state, {
    smsResponse: action.message
  });
};
const setEmailAlert = (state, action) => {
  return updateObject(state, {
    emailAlert: action.emailAlert
  });
};
const emailSent = (state, action) => {
  return updateObject(state, {
    emailResponse: action.message
  });
};
const setWebAlert = (state, action) => {
  var result = action.webAlert.length > 0 ? action.webAlert[0] : [];
  return updateObject(state, {
    webAlert: result
  });
};
const setRecentWebAlerts = (state, action) => {
  return updateObject(state, {
    recentWebAlerts: action.recentWebAlerts
  });
};
const setVehicleAssignSuccess = (state, action) => {
  return updateObject(state, {
    vehicleAssignSuccess: action.message
  });
};
const setManualAlertSuccess = (state, action) => {
  return updateObject(state, {
    manualAlertSuccess: action.message
  });
};
const setAllSentAlerts = (state, action) => {
  return updateObject(state, {
    alertSentIndicator: action.alertSentIndicator
  });
};
const resetAlertSentIndicator = (state, action) => {
  return updateObject(state, {
    alertSentIndicator: null
  });
};
const setAvailableVehicles = (state, action) => {
  return updateObject(state, {
    availableVehicles: action.availableVehicles
  });
};
const setNotificationAlerts = (state, action) => {
  return updateObject(state, {
    notificationAlerts: action.notificationAlerts
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VMS_ALERTS_SUCCESS:
      return setVMSAlerts(state, action);
    case actionTypes.VMS_SEND_MESSAGE_SUCCESS:
      return vmsMessageSent(state, action);
    case actionTypes.SMS_ALERT_SUCCESS:
      return setSMSAlert(state, action);
    case actionTypes.SMS_SEND_SUCCESS:
      return smsSent(state, action);
    case actionTypes.EMAIL_ALERT_SUCCESS:
      return setEmailAlert(state, action);
    case actionTypes.EMAIL_SEND_SUCCESS:
      return emailSent(state, action);
    case actionTypes.WEB_ALERT_SUCCESS:
      return setWebAlert(state, action);
    case actionTypes.RECENT_WEB_ALERTS_SUCCESS:
      return setRecentWebAlerts(state, action);
    case actionTypes.VEHICLE_AID_ASSIGN_SUCCESS:
      return setVehicleAssignSuccess(state, action);
    case actionTypes.SEND_MANUAL_ALERT_SUCCESS:
      return setManualAlertSuccess(state, action);
    case actionTypes.ALL_SENT_ALERTS_SUCCESS:
      return setAllSentAlerts(state, action);
    case actionTypes.RESET_ALERTS_SENT_INDICATOR:
      return resetAlertSentIndicator(state, action);
    case actionTypes.AVAILABLE_VEHICLES_SUCCESS:
      return setAvailableVehicles(state, action);
    case actionTypes.NOTIFICATION_ALERTS_SUCCESS:
      return setNotificationAlerts(state, action);
    default:
      return state;
  }
};

export default reducer;