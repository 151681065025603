import {updateObject} from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {    
    ArrDirection: [],
    loading: null    
};

const getDirectionSuccess = (state, action) => {
    var updatedState = updateObject(state, {
        ArrDirection: action.arrDirection
    });
    return updatedState;
}

const chainageStateReset = (state,action) => {
    return updateObject(state, { loading: null });
};

const chainageCreateStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const reducer = (state= initialState,action) => {
    switch (action.type) {
        case actionTypes.GET_DIRECTION_SUCCESS:
            return getDirectionSuccess(state,action)
 
        case actionTypes.CHAINAGE_CREATE_START :
            return chainageCreateStart(state,action);

        case actionTypes.CHAINAGE_STATE_RESET :
            return chainageStateReset(state,action);

        default:
            return state;
    }
}

export default reducer;