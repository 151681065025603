import { updateObject } from '../../utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    loading: ''
};

const eventTypeStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const eventTypeCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.EVENT_TYPE_CREATE_START:
            return eventTypeCreateStart(state, action);

        case actionTypes.EVENT_TYPE_STATE_RESET:
            return eventTypeStateReset(state, action);
        default:
            return state;
    }
}

export default reducer;