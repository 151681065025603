import React from 'react';
import AssetVehicleDetailsAddForm from './assetVehicleDetailsAddForm';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";
import axios from 'axios';
import appConfig from '../../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class AssetVehicleDetailsEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            Uploadedfile: null,

        };
    }

    async componentDidMount() {
        console.log("In EDIT componentDidMount");
        try {
            const res = await axios.get(BASE_URL + "/api/uploads?file=uploads/" + this.props.initialValues.assetVehicleDetailsImageUrl, {responseType: 'blob'});
            this.setState({
                Uploadedfile: URL.createObjectURL(res.data)
            });

        } catch (error) {
            console.log("Error");
        }
        this.props.assetVehicleDetailsStateReset();
      //  console.log("Uploaded from EDIT file",this.state.Uploadedfile)
    }

    onSubmit = formValues => {
        this.props.assetVehicleDetailsEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        console.log("errorMsgCrt", this.props.initialValues);
        
        return (
            <React.Fragment>
                {alert}
                {progress}
                <AssetVehicleDetailsAddForm initialValues={this.props.initialValues} uploaded={this.state.Uploadedfile} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.assetVehicleDetailsReducer.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        assetVehicleDetailsEdit: ( formValues ) => dispatch( actions.assetVehicleDetailsEdit( formValues ) ),
        assetVehicleDetailsStateReset: () => dispatch(actions.assetVehicleDetailsStateReset())
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AssetVehicleDetailsEdit));