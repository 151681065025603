import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, FormControl, Box, InputLabel, Select, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import axios from 'axios';
import appConfig from '../../../appConfig.json';
import ReactAudioPlayer from 'react-audio-player';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class EcbAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            directionId: 0,
            directions: [],
            directionName: '',
            // directionName2: '',
            chainageId: 0,
            chainages: [],
            chainageName: '',
            equipmentTypeId: 0,
            equipmentId: 0,
            equipmentName: '',
            equipmentNameId: 0,
            ecbCallTypeId: 0,
            callerName: '',
            callerContact: '',
            ecbCallInfoDescription: '',
            ReceiveDateTime: null,
            TerminateDateTime: null,

            eventTypes: [],
            eventTypeId: 0,
            eventSubtypes: [],
            eventSubtypeId: 0,
            isEvent: false,
            isChecked: false,
            isDisabled: true,
            selectedFile: null,
            e: false
        }
        this.fileInput = React.createRef();
    }

    async componentDidMount() {
        if (this.props.type === 'Edit' && this.props.initialValues.ecbCallTypeId) {
            this.setState({
                ecbCallTypeId: this.props.initialValues.ecbCallType.ecbCallTypeId
            })
        }
        try {
            const directions = await axios.get(`${BASE_URL}/api/direction?reqType=all`); //TO get all the directions when Ecb Name(equipmentId) is unknown 
            const eventTypes = await axios.get(BASE_URL + "/api/eventTypes?reqType=all"); //This is event types which will be shown if isEvent checked
            this.setState({
                eventTypes: eventTypes.data,
                directions: directions.data
            });

            if (this.eventTypeId !== null) {
                const eventSubtypes = await axios.get(`${BASE_URL}/api/eventSubtypes?reqType=all&eventTypeId=${this.eventTypeId}`);
                this.setState({
                    eventSubtypes: eventSubtypes.data
                })
            }

        } catch (e) {
            console.log('eventType get Error: ', e);
        }

    }

    async componentDidUpdate(prevProps, prevState) {

        if (this.state.equipmentTypeId !== prevState.equipmentTypeId) {
            this.props.getEcbNames(this.state.equipmentTypeId)
        }
        if (this.state.directionId !== prevState.directionId) {
            const chainages = await axios.get(`${BASE_URL}/api/chainage?reqType=all&directionId=${this.state.directionId}`)  //This will execute everytime directionId is changed and will give list of chainages
            this.setState({ chainages: chainages.data })
        }
        if (this.state.equipmentId !== prevState.equipmentId) { //this will run after selecting Ecb type and Ecb name.
            this.props.getDirectionName(this.state.directionId); //So I am using API calls from actions & reducers if ecbs are selected
            this.props.getEcbChainage(this.state.directionId);
        }

        if (this.state.eventTypeId !== prevState.eventTypeId) {
            const eventSubtypes = await axios.get(`${BASE_URL}/api/eventSubtypes?reqType=all&eventTypeId=${this.state.eventTypeId}`);
            this.setState({
                eventSubtypes: eventSubtypes.data
            })
        }
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onCallTypeChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    onEventChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleFileChange = event => {
        if (event.target.id == "file") {
            this.setState({
                selectedFile: event.target.files[0],
            })
        }
        console.log("file from onChange", this.state.selectedFile);
    }


    //Made this to set directionName mainly.
    onDirectionChange = event => {
        const directionsArray = this.state.directions;
        if (event.target.value == "") {
            this.setState({
                directionId: event.target.value,
            });
        } else {
            const result = directionsArray.find(direction => direction.directionId == event.target.value);  //Here I found a wierd behaviour. If I use === istead of == then result will be undefined.
            this.setState({
                directionId: event.target.value,
                directionName: result.directionName
            });
        }
    }
    // To set chainageName 
    onChainageChange = event => {
        const chainageArray = this.state.chainages;
        if (event.target.value == "") {
            this.setState({ chainageId: event.target.value })
        } else {
            const result = chainageArray.find(chainage => chainage.chainageId == event.target.value); //Not working if === is used instead of ==
            this.setState({ chainageId: event.target.value, chainageName: result.chainageName })
        }

    }

    onChangeEvent = event => {  //For isEvent condition
        this.setState({ [event.target.name]: event.target.checked });
    }

    onEquipmentNameChange = async (event, values) => { //For Ecb name selection 
        let equipmentId = event.target.value;
        try {
            let response = await axios.get(BASE_URL + "/api/equipments/" + equipmentId);
            this.setState({
                equipmentId: equipmentId,
                chainageName: response.data.chainageName,
                directionName: response.data.directionName,
                directionId: response.data.directionId,
                chainageId: response.data.chainageId
            })
        } catch (error) {
            console.log("error", error);
        }
    }

    handleReceiveDateTimeChange = (dateTime) => {
        var fDate = new Date(dateTime);
        let todayDate = new Date();
        if (fDate > todayDate) {
            this.props.showToaster('error', 'Cannot Take future time')
        } else {
            this.setState({
                ReceiveDateTime: dateTime
            })
        }

    }
    handleTerminateDateTimeChange = (dateTime) => {
        var tDate = new Date(dateTime);
        let todayDate = new Date();
        if (tDate > todayDate) {
            this.props.showToaster('error', 'Cannot Take future time')
        } else {
            this.setState({
                TerminateDateTime: dateTime
            })
        }
    }

    onSubmit = async (values) => {
        const ecbState = this.state;
        if (this.state.selectedFile) {
            try {
                const data = new FormData()
                data.append('public', "true")
                data.append('uploadPath', "callRecordings")
                data.append('files', this.state.selectedFile);
                var name = this.state.selectedFile.name;
                values['ecbCallAudioFilename'] = name.substr(0, name.indexOf('.'));
                values['ecbCallAudioExtension'] = name.substr(name.indexOf('.') + 1, name.length - name.indexOf('.'));
                values['ecbCallAudioPath'] = 'public/uploads/callRecordings';
                await axios.post(BASE_URL + "/api/uploads", data, {
                }).then(res => {
                    console.log("Upload post res: ", res)
                })

            }
            catch (error) {
                this.props.showToaster('error', error.response.data.message)
            }
        }

        if (this.state.ReceiveDateTime || this.state.TerminateDateTime) {
            var fDate = new Date(this.state.ReceiveDateTime);
            var tDate = new Date(this.state.TerminateDateTime);

            if (Date.parse(tDate) > Date.parse(fDate)) {
                if (this.state.ReceiveDateTime) {
                    values.ReceiveDateTime = this.state.ReceiveDateTime.format('YYYY-MM-DDTHH:mm:ss');
                }
                if (this.state.TerminateDateTime) {
                    values.TerminateDateTime = this.state.TerminateDateTime.format('YYYY-MM-DDTHH:mm:ss');
                }
                this.props.onSubmit(values, ecbState);
            } else {
                this.props.showToaster('error', ' Call Received time should be past of Termination time')
            }
        }
        console.log(" ECB Submit Values: ", values);
        console.log("ecbState: ", ecbState);
        if (this.props.type === 'Edit') {
            this.props.onSubmit(values, ecbState);
        }
    }

    onReset = () => {
        this.setState({
            directionName: null,
            directionId: 0,
            chainageId: 0,
            chainageName: null,
            equipmentId: 0,
            isChecked: false,
            isEvent: false,
            selectedFile: null,
            ReceiveDateTime: null,
            TerminateDateTime: null,
        })
        this.fileInput.value = null;
        this.props.reset();
    }

    render() {
        const { classes, pristine, invalid } = this.props;

        let buttons = null;
        let subheaderName = null;
        let showEditFields = null; //To show Edit fields if type=== EDIT
        let showAddFields = null; //To show Add fields if type === ADD
        let eventFields = null; //To show event fields if isEvent === true
        // console.log("-------------------------")
        // console.log("pristine: ", pristine)
        // console.log("this.state.ReceiveDateTime: ", this.state.ReceiveDateTime)


        if (this.state.isEvent) {
            //If isEvent is true... show these 3 fields in both patch and add form.
            eventFields =
                <React.Fragment>
                    <Grid item md={6} xs={12}>
                        <Field name="eventTypeId" id="eventTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onEventChange} label="Event Type">
                            <option value={""}>{""}</option>
                            {this.state.eventTypes.map(eventType => {
                                return (
                                    <option key={eventType.eventTypeId} value={eventType.eventTypeId}>
                                        {eventType.eventTypeName}
                                    </option>
                                )
                            })}
                        </Field>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Field name="eventSubtypeId" id="eventSubtypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onEventChange} label="Event Subtype">
                            <option value={""}>{""}</option>
                            {this.state.eventSubtypes.map(eventSubtype => {
                                return (
                                    <option key={eventSubtype.eventSubtypeId} value={eventSubtype.eventSubtypeId}>
                                        {eventSubtype.eventSubtypeName}
                                    </option>
                                )
                            })}
                        </Field>
                    </Grid>
                    <Grid item md={12} xs={12} lg={12}>
                        <Field
                            name="eventDescription"
                            id="eventDescription"
                            component={reduxFormFunctions.renderTextField}
                            label="Event Description"
                            multiline={true}
                            onChange={this.onEventChange}
                            rows={2}
                        >
                        </Field>
                    </Grid>
                </React.Fragment>
        }

        //If User clicks on New call button on ECB dasboard this condition will be true
        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit" disabled={pristine || invalid}>ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={!this.state.ReceiveDateTime && !this.state.TerminateDateTime && pristine} onClick={this.onReset}>RESET</Button>
            </React.Fragment>

            showAddFields =
                <React.Fragment>
                    <Grid item md={6} xs={12}>
                        <Field name="equipmentTypeId" id="equipmentTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Ecb type">
                            <option value={""}>{""}</option>
                            {this.props.ecbTypes.map((ecbType) => { //Getting it from redux state
                                return (
                                    <option key={ecbType.equipmentTypeId} value={ecbType.equipmentTypeId}>
                                        {ecbType.equipmentTypeName}
                                    </option>
                                )
                            })}
                        </Field>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Field name="equipmentId" id="equipmentId" component={reduxFormFunctions.renderSelectField} onChange={this.onEquipmentNameChange} label="Ecb Name">
                            <option value={""}>{""}</option>
                            {this.props.equipmentNames.map((equipment) => {
                                return (
                                    <option key={equipment.equipmentNameId} value={equipment.equipmentId}>
                                        {equipment.equipmentName}
                                    </option>
                                )
                            })}
                        </Field>
                    </Grid>

                    {/* Ternary operator to show feilds if ecbs are selected or not*/}
                    {
                        this.state.equipmentId ?
                            <React.Fragment>
                                <Grid item md={6} xs={12}>
                                    <FormControl fullWidth={true} variant="outlined" >
                                        <InputLabel shrink htmlFor="directionId">Direction Name</InputLabel>
                                        <Select                                 //Doubt.. I got this worked by trail and error...But I don't get the concept of both the Selects.There are attributes I don't know what they are doing
                                            native
                                            value={this.state.directionId}
                                            onChange={this.onChange}
                                            name="directionId"
                                            autoWidth={true}
                                            disabled={true}
                                            label="direction Name"
                                            inputProps={{
                                                name: "directionId",
                                                id: "directionId"
                                            }}
                                        >
                                            <option value={this.state.directionName}>{this.state.directionName}</option>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl fullWidth={true} variant="outlined" >
                                        <InputLabel shrink htmlFor="chainageId">Chainage Name</InputLabel>
                                        <Select
                                            native
                                            value={this.state.chainageId}
                                            onChange={this.onChange}
                                            name="chainageId"
                                            autoWidth={true}
                                            disabled={true}
                                            label="Chainage Name"
                                            inputProps={{
                                                name: "chainageId",
                                                id: "chainageId"
                                            }}
                                        >
                                            <option value={this.state.chainageName}>{this.state.chainageName}</option>

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item md={6} xs={12}>
                                    <FormControl fullWidth={true} variant="outlined" >
                                        <InputLabel shrink htmlFor="directionId">Direction Name</InputLabel>
                                        <Select
                                            native
                                            value={this.state.directionId}
                                            name="directionId"
                                            onChange={this.onDirectionChange}
                                            autoWidth={true}
                                            disabled={!this.state.isEvent}
                                            label="Direction Name"
                                            inputProps={{
                                                name: "directionId",
                                                id: "directionId"
                                            }}
                                        >
                                            <option value={""}>{""}</option>
                                            {this.state.directions.map((direction) => {
                                                return (
                                                    <option key={direction.directionId} value={direction.directionId}>
                                                        {direction.directionName}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl fullWidth={true} variant="outlined" >
                                        <InputLabel shrink htmlFor="chainageId">Chainage Name</InputLabel>
                                        <Select
                                            native
                                            value={this.state.chainageId}
                                            onChange={this.onChainageChange}
                                            name="chainageId"
                                            autoWidth={true}
                                            disabled={!this.state.isEvent}
                                            label="Chainage Name"
                                            inputProps={{
                                                name: "chainageId",
                                                id: "chainageId"
                                            }}
                                        >
                                            <option value={""}>{""}</option>
                                            {this.state.chainages.map((chainage) => {
                                                return (
                                                    <option key={chainage.chainageId} value={chainage.chainageId}>
                                                        {chainage.chainageName}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                    }
                    {eventFields}
                </React.Fragment>
            subheaderName = "New Call Details";
        }

        //If user clicks on the Row of data grid, that is to edit the data this condition will be executed
        if (this.props.type === "Edit") {
            this.txnEventId = this.props.initialValues.txnEventId; //initialValues contain all the row data.
            this.equipment = this.props.initialValues.equipment;

            if (this.props.uploaded) {
                this.audioSrc = this.props.uploaded
            } else {
                this.audioSrc = this.props.initialValues.fullAudioFilePath;
            }


            if (this.props.initialValues.event !== null) {
                this.eventTypeId = this.props.initialValues.event.eventTypeId;
                // this.directionName = this.props.initialValues.event.directionName;
            }

            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={!this.state.selectedFile && pristine} type="submit">SAVE</Button>
            </React.Fragment>


            showEditFields =
                <React.Fragment>
                    {this.equipment ?
                        <React.Fragment>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="equipment.equipmentId"
                                    id="equipment.equipmentId"
                                    component={reduxFormFunctions.renderTextField}
                                    label="Equipment ID"
                                    onChange={() => { }}
                                    disabled={true}
                                >
                                </Field>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Field
                                    name="equipment.equipmentName"
                                    id="equipment.equipmentName"
                                    component={reduxFormFunctions.renderTextField}
                                    label="Equipment name"
                                    onChange={this.onChange}
                                    disabled={true}
                                >
                                </Field>
                            </Grid>
                        </React.Fragment>
                        : null
                    }
                    {this.props.initialValues.directionName ?
                        <React.Fragment>
                            <Grid item md={6} xs={12} >
                                <Field
                                    name="directionName"
                                    id="directionName"
                                    component={reduxFormFunctions.renderTextField}
                                    label="Direction Name"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Field
                                    name="chainageName"
                                    id="chainageName"
                                    component={reduxFormFunctions.renderTextField}
                                    label="Chainage Name"
                                    disabled={true}
                                />
                            </Grid>
                        </React.Fragment>
                        : null
                    }


                    {/*-----------------------------IF EVENT IS ALREADY CREATED -----------------------------------*/}
                    {
                        this.txnEventId ?
                            <React.Fragment>
                                <Grid item md={6} xs={12}>
                                    <label htmlFor="eventTypeId">Event Type</label>
                                    <Field
                                        name="event.eventTypeId"
                                        id="eventTypeId"
                                        component={reduxFormFunctions.renderSelectField}
                                        onChange={this.onEventChange}
                                        // label="Event Type"
                                        disabled={true}
                                    >
                                        <option value={""}>{""}</option>
                                        {this.state.eventTypes.map(eventType => {
                                            return (
                                                <option key={eventType.eventTypeId} value={eventType.eventTypeId}>
                                                    {eventType.eventTypeName}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <label htmlFor="eventSubtypeId">Event Subtype</label>
                                    <Field
                                        name="event.eventSubtypeId"
                                        id="eventSubtypeId"
                                        component={reduxFormFunctions.renderSelectField}
                                        onChange={this.onEventChange}
                                        // label="Event Subtype"
                                        disabled={true}
                                    >
                                        <option value={""}>{""}</option>
                                        {this.state.eventSubtypes.map(eventSubtype => {
                                            return (
                                                <option key={eventSubtype.eventSubtypeId} value={eventSubtype.eventSubtypeId}>
                                                    {eventSubtype.eventSubtypeName}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </Grid>
                                <Grid item md={12} xs={12} lg={12}>
                                    <Field
                                        name="event.eventDescription"
                                        id="eventDescription"
                                        component={reduxFormFunctions.renderTextField}
                                        label="Event Description"
                                        multiline={true}
                                        onChange={this.onEventChange}
                                        rows={2}
                                        disabled={true}
                                    >
                                    </Field>
                                </Grid>
                            </React.Fragment>
                            : null
                    }
                    {eventFields}

                </React.Fragment>
            subheaderName = "Ecb Call Details"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="ECB"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            {!this.txnEventId ?
                                <Grid item md={2} xs={4}>
                                    <Field
                                        name="isEvent"
                                        id="isEvent"
                                        component={reduxFormFunctions.radioIsEvent}
                                        label="Is an Event?"
                                        checked={this.state.isChecked}
                                        onChange={this.onChangeEvent}
                                    >
                                    </Field>
                                </Grid>
                                : null
                            }
                            <Grid container spacing={3}>

                                {showEditFields}
                                {showAddFields}


                                {/*common feilds*/}
                                <Grid item md={6} xs={12}>
                                    <Field name="ecbCallTypeId" id="ecbCallTypeId" component={reduxFormFunctions.renderSelectField} onChange={this.onCallTypeChange} label="Call Type">
                                        <option value={""}>{""}</option>
                                        {this.props.callTypes.map((callType) => {
                                            return (
                                                <option key={callType.ecbCallTypeId} value={callType.ecbCallTypeId}>
                                                    {callType.ecbCallTypeName}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="ecbCallInfoDescription"
                                        id="ecbCallInfoDescription"
                                        component={reduxFormFunctions.renderTextField}
                                        label="Call Notes"
                                        onChange={this.onChange}

                                    >
                                    </Field>
                                </Grid>
                                {this.props.type === 'Edit' ?
                                    <React.Fragment>
                                        <Grid item md={6} xs={12} >
                                            <Field
                                                id="ecbCallReceiveDatetime"
                                                name="ecbCallReceiveDatetime"
                                                component={reduxFormFunctions.renderDateTimeField}
                                                label="ECB Call Receive Datetime"
                                                value={this.state.ReceiveDateTime}
                                                onChange={(val) => this.handleReceiveDateTimeChange(val)}
                                                fullWidth={true}
                                            // disabled={true}
                                            >
                                            </Field>
                                        </Grid>

                                        <Grid item md={6} xs={12} >
                                            <Field
                                                id="ecbCallTerminationDatetime"
                                                name="ecbCallTerminationDatetime"
                                                component={reduxFormFunctions.renderDateTimeField}
                                                label="ECB Call Termination Datetime"
                                                value={this.state.TerminateDateTime}
                                                onChange={(val) => this.handleTerminateDateTimeChange(val)}
                                                fullWidth={true}
                                            // disabled={true}
                                            >
                                            </Field>
                                        </Grid>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <Grid item md={6} xs={12} >
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDateTimePicker
                                                    // error={this.state.ReceiveDateTime ? false : true}
                                                    // helperText={error ? "Please select date time" : null}
                                                    id="ecbCallReceiveDatetime"
                                                    name="ecbCallReceiveDatetime"
                                                    label="ECB Call Receive Datetime"
                                                    format="DD-MM-YYYY HH:mm:ss"
                                                    disableFuture={true}
                                                    ampm={true}
                                                    fullWidth={true}
                                                    value={this.state.ReceiveDateTime}
                                                    onChange={(val) => this.handleReceiveDateTimeChange(val)}
                                                    required />
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid item md={6} xs={12} >
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDateTimePicker
                                                    id="ecbCallTerminationDatetime"
                                                    name="ecbCallTerminationDatetime"
                                                    label="ECB Call Termination Datetime"
                                                    ampm={true}
                                                    disableFuture={true}
                                                    fullWidth={true}
                                                    format="DD-MM-YYYY HH:mm:ss"
                                                    value={this.state.TerminateDateTime}
                                                    onChange={(val) => this.handleTerminateDateTimeChange(val)}
                                                    required />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </React.Fragment>
                                }

                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="callerName"
                                        id="callerName"
                                        component={reduxFormFunctions.renderTextField}
                                        label="Caller Name"
                                        onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="callerContact"
                                        id="callerContact"
                                        component={reduxFormFunctions.renderTextField}
                                        label="Caller Contact"
                                        onChange={this.onChange}>
                                    </Field>
                                </Grid>
                                {this.audioSrc && this.audioSrc !== "null/null.null" ?
                                    <Grid item md={6} xs={12}>
                                        <Typography variant='subtitle1'>Call Recording</Typography>
                                        <ReactAudioPlayer
                                            src={this.audioSrc}
                                            controls
                                            controlsList="nodownload"
                                        />
                                    </Grid> :
                                    <Grid item md={6} xs={12}>
                                        <Typography variant='subtitle1' gutterBottom>Upload Call Recording:</Typography>
                                        <input type="file" name="file" id="file" ref={(ref) => { this.fileInput = ref }} label="Upload file" onChange={this.handleFileChange} accept="audio/*" />
                                    </Grid>
                                }

                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
}

//#region Validations
const validate = (formValues) => {
    const error = {};
    console.log("formValues: ", formValues)
    if (formValues.isEvent === true) {
        if (!formValues.eventTypeId) {
            error.eventTypeId = "Please Select Event Type"                      //these error messages are not showing in the form
        }
        if (!formValues.eventSubtypeId) {                                                                             //It's not validating
            error.eventSubtypeId = "Please Select Event Subtype"
        }
    }
    if (!formValues.ecbCallReceiveDatetime || formValues.ecbCallReceiveDatetime == null) {
        error.ecbCallReceiveDatetime = "Please select call received date and time"
    }
    if (!formValues.ecbCallTerminationDatetime || formValues.ecbCallTerminationDatetime == null) {
        error.ecbCallTerminationDatetime = "Please select call termination date and time"
    }
    if (!formValues.ecbCallTypeId) {
        error.ecbCallTypeId = "Please Select Call Type"
    }

    if (formValues.callerContact) {
        if (!/^[0-9]\d{9}$/.test(formValues.callerContact)) {
            error.callerContact = "Enter a valid Contact number"
        }
    }
    return error;
};

const mapStateToProps = state => {
    return {
        ecbTypes: state.ecbReducer.ecbTypes,
        equipmentNames: state.ecbReducer.equipmentNames,
        EcbDirections: state.ecbReducer.directions, //to get the diretions if ecb type is known
        EcbChainages: state.ecbReducer.chainages,  //to get the chainage if ecb type and ecb name(equipmentId) is known
        callTypes: state.ecbReducer.callTypes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDirectionName: (state) => dispatch(actions.getDirectionName(state)), //to get the diretions if ecb type is known
        getEcbChainage: (state) => dispatch(actions.getEcbChainage(state)), //to get the chainage if ecb type and ecb name(equipmentId) is known
        getEcbNames: (state) => dispatch(actions.getEcbNames(state)),
        showToaster: (x, y) => dispatch(actions.showToaster(x, y)),
    }
}

EcbAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EcbAddForm);

export default reduxForm({
    validate: validate,
    form: 'EcbAddForm',
})(withStyles(styles)(EcbAddForm));