import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';
// import { getSelectedEventSopItemsSuccess } from "../../actions/eventManagement/eventSop";

const initialState = {
    locationTypes: [],
    locationDetails: [],
    eventSopActions: [],
    selectedEventSopItems: null
};


const getLocationTypesSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        locationTypes : action.locationTypes
    });
    return updatedState;
}

const getlocationsDetailsSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        locationDetails : action.locationDetails
    });
    return updatedState;
}

const getEventSopSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventSopActions : action.eventSopActions
    });
    return updatedState;
}

const getSelectedEventSopItemsSuccess = ( state, action ) => {
  var updatedState = updateObject(state, {
      selectedEventSopItems : action.selectedEventSopItems
  });
  return updatedState;
}

const updateEventSopItemsSuccess = ( state, action ) => {
  var updatedState = updateObject(state, {
      message : action.message
  });
  return updatedState;
}

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.GET_LOCATION_TYPES_SUCCESS:   
            return getLocationTypesSuccess(state, action);
        case actionTypes.GET_LOCATION_DETAILS_SUCCESS:
            return getlocationsDetailsSuccess(state, action);
        case actionTypes.GET_EVENT_SOP_SUCCESS:
            return getEventSopSuccess(state, action);
        case actionTypes.GET_SELECTED_EVENT_SOP_SUCCESS:
            return getSelectedEventSopItemsSuccess(state, action);
        case actionTypes.UPDATE_EVENT_SOP_SUCCESS:
            return updateEventSopItemsSuccess(state, action);
        default:
            return state;
    }
}

export default reducer;