import axios from "axios";
import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import { showToaster } from '../common';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

// export const fetchVMSAlerts = (vmsParams) => {
//   return dispatch => {
//     axios
//       .get(BASE_URL + '/api/eventAlerts?eventNotificationCategoryId='+vmsParams.eventNotificationCategoryId+'&eventId='+vmsParams.eventId)
//       .then(res => {
//         //console.log(res.data);
//         dispatch(fetchVMSAlertsSuccess(res.data));
//       }).catch(error => {
//         // dispatch(getEventGeneralDetailsFail(error.response.data.message));
//       });
//   };
// }

export const fetchNotificationData = (params) => {
  const eventAlertSources = [0, 1, 2]; // Array of values for eventAlertSource

  const sourcesString = eventAlertSources.join();
  return async dispatch => {
    try {
      let smsResponse = await axios
        .get(BASE_URL + '/api/eventAlerts?eventNotificationCategoryId=' + params.eventNotificationCategoryId + '&eventId=' + params.eventId + '&eventAlertSource=${sourcesString}')

      //console.log(res.data);
      let smsAlerts = smsResponse.data.map((alert) => {
        return {
          ...alert,
          mode: 'SMS'
        }
      });;
      let webResponse = await axios

        .get(BASE_URL + '/api/eventAlerts?reqType=all&eventNotificationCategoryId=' + params.webAlertCategoryId + '&eventId=' + params.eventId + '&eventAlertSource=${sourcesString}')
      let webAlerts = webResponse.data.map((alert) => {
        return {
          ...alert,
          message: alert.eventNotificationMessage,
          mode: 'WEB ALERT'
        }
      });
      let emailResponse = await axios
        .get(BASE_URL + '/api/eventAlerts?reqType=all&eventNotificationCategoryId=' + params.emailAlertCategoryId + '&eventId=' + params.eventId + '&eventAlertSource=${sourcesString}')
      let emailAlerts = emailResponse.data.map((alert) => {
        return {
          ...alert,
          message: alert.message,
          mode: 'EMAIL'
        }
      });
      let notifAlerts = smsAlerts.concat(webAlerts);
      notifAlerts = notifAlerts.concat(emailAlerts);
      dispatch(fetchNotificationDataSuccess(notifAlerts));
    } catch (error) {
      console.log(error);
    }
    // dispatch(getEventGeneralDetailsFail(error.response.data.message));
  }
}



export const fetchNotificationDataSuccess = (notificationAlerts) => {
  return {
    type: actionTypes.NOTIFICATION_ALERTS_SUCCESS,
    notificationAlerts: notificationAlerts,
  };
};

export const fetchVMSAlerts = (vmsParams) => {
  return dispatch => {
    const eventAlertSources = [0, 1, 2]; // Array of values for eventAlertSource

    const sourcesString = eventAlertSources.join();
    axios
      .get(BASE_URL + '/api/eventAlerts?eventNotificationCategoryId=' + vmsParams.eventNotificationCategoryId + '&eventId=' + vmsParams.eventId + '&eventAlertSource=${sourcesString}')
      .then(res => {
        //console.log(res.data);
        dispatch(fetchVMSAlertsSuccess(res.data));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const sendVMSMessage = (params) => {
  return async dispatch => {
    try {
      var res = await axios
        .patch(BASE_URL + '/api/eventAlerts/' + params.id + '/sendMessage', {
        });

      //console.log(res.data);
      dispatch(showToaster('success', 'VMS Message sent successfully!'));
      dispatch(fetchVMSAlerts(params));
    } catch (error) {
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      dispatch(showToaster('error', 'Error sending VMS Message!'));

    };
  };
}


export const fetchVMSAlertsSuccess = (vmsAlerts) => {
  return {
    type: actionTypes.VMS_ALERTS_SUCCESS,
    vmsAlerts: vmsAlerts,
  };
};

export const sendVMSMessageSuccess = (_) => {
  return {
    type: actionTypes.VMS_SEND_MESSAGE_SUCCESS,
    message: 'Message sent successfully',
  };
};

//SMS

export const fetchSMSAlert = (vmsParams) => {

  return dispatch => {
    const eventAlertSources = [0, 1, 2]; // Array of values for eventAlertSource

    const sourcesString = eventAlertSources.join();
    axios
      .get(BASE_URL + '/api/eventAlerts?eventNotificationCategoryId=' + vmsParams.eventNotificationCategoryId + '&eventId=' + vmsParams.eventId )
      .then(res => {
        //console.log(res.data);
        dispatch(fetchSMSAlertSuccess(res.data[0]));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

//export const sendSMS = (params) => {
//  console.log("Paramssssssss",params);
//  return async dispatch => {
//    try {
//      var res = await axios
//        .patch(BASE_URL + '/api/eventAlerts/' + params.id + '/sendMessage', {params
//        });
//      dispatch(showToaster('success', 'SMS sent successfully!'));

//      console.log(res.data);
      
     
//      dispatch(fetchSMSAlert(params));
//    } catch (error) {
//      dispatch(showToaster('error', error.response.data.message));
//      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
//    };
//  };
//}
export const sendSMS = (params) => {
  console.log("Paramssssssss", params);
  return async dispatch => {
    try {
      var res = await axios.patch(BASE_URL + '/api/eventAlerts/' + params.id + '/sendMessage', params);
      dispatch(showToaster('success', 'SMS sent successfully!'));

      console.log(res.data);

      dispatch(fetchSMSAlert(params));
    } catch (error) {
      dispatch(showToaster('error', error.response.data.message));
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
    };
  };
};



export const fetchSMSAlertSuccess = (smsAlert) => {
  return {
    type: actionTypes.SMS_ALERT_SUCCESS,
    smsAlert: smsAlert,
  };
};

export const sendSMSSuccess = (_) => {
  return {
    type: actionTypes.SMS_SEND_SUCCESS,
    message: 'Message sent successfully',
  };
};

//Email
export const fetchEmailAlert = (emailParams) => {
  return dispatch => {
    const eventAlertSources = [0, 1, 2]; // Array of values for eventAlertSource

    const sourcesString = eventAlertSources.join();
    axios
      .get(BASE_URL + '/api/eventAlerts?reqType=all&eventNotificationCategoryId=' + emailParams.eventNotificationCategoryId + '&eventId=' + emailParams.eventId + '&eventAlertSource=${sourcesString}')
      .then(res => {
        //console.log(res.data);
        dispatch(fetchEmailAlertSuccess(res.data[0]));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const sendEmail = (params) => {
  console.log("Paramssssssss", params);
  return async dispatch => {
    try {
      var res = await axios
        .patch(BASE_URL + '/api/eventAlerts/' + params.id + '/sendMessage',params);

      dispatch(showToaster('success', 'Email sent successfully!'));
      console.log(res.data);
      dispatch(fetchEmailAlert(params));
    } catch (error) {
      dispatch(showToaster('error', 'Error sending Email!'));
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
    };
  };
}


export const fetchEmailAlertSuccess = (emailAlert) => {
  return {
    type: actionTypes.EMAIL_ALERT_SUCCESS,
    emailAlert: emailAlert,
  };
};

export const sendEmailSuccess = (_) => {
  return {
    type: actionTypes.EMAIL_SEND_SUCCESS,
    message: 'Email sent successfully',
  };
};

//Web Alert

export const fetchWebAlert = (vmsParams) => {
  return dispatch => {
    axios
      .get(BASE_URL + '/api/eventAlerts?reqType=all&eventNotificationCategoryId=' + vmsParams.eventNotificationCategoryId + '&eventId=' + vmsParams.eventId)
      .then(res => {
        //console.log(res.data);
        dispatch(fetchWebAlertSuccess(res.data));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const sendWebAlert = (params) => {
  return async dispatch => {
    try {
      var res = await axios
        .patch(BASE_URL + '/api/eventAlerts/' + params.id + '/sendMessage', {
          eventNotificationStatus: 1
        });

      dispatch(showToaster('success', 'Web Alert sent successfully!'));
      //console.log(res.data);
      dispatch(fetchWebAlert(params));
      dispatch(fetchRecentWebAlerts(params))
    } catch (error) {
      dispatch(showToaster('error', 'Error sending web alert!'));
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
    };
  };
}


export const fetchWebAlertSuccess = (webAlert) => {
  return {
    type: actionTypes.WEB_ALERT_SUCCESS,
    webAlert: webAlert,
  };
};

export const fetchRecentWebAlerts = () => {
  return dispatch => {
    axios
      .get(BASE_URL + '/api/recentWebAlerts')
      .then(res => {
        //console.log(res.data);
        dispatch(fetchRecentWebAlertSuccess(res.data));
      }).catch(error => {
        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const fetchRecentWebAlertSuccess = (recentWebAlerts) => {
  return {
    type: actionTypes.RECENT_WEB_ALERTS_SUCCESS,
    recentWebAlerts: recentWebAlerts.map(a => a.eventNotificationMessage),
  };
};
export const assignAidVehicle = (params) => {
  return async dispatch => {
    try {
      var res = await axios
        .post(BASE_URL + '/api/eventAidVehicles/', {
          assetVehicleCategoryId: params.vehicle.assetVehicleCategoryId,
          assetVehicleDetailsId: params.vehicle.assetVehicleDetailsId,
          aidVehicleStatus: 1,
          aidVehicleInchargeContact: params.vehicle.assetVehicleDetailsContactNumber,
          txnEventId: params.eventId,
        });

      //console.log(res.data);
      dispatch(assignAidVehicleSuccess(res.data));
      dispatch(showToaster('success', 'Vehicle Aid assigned successfully!'));
    } catch (error) {
      console.log(error);
      dispatch(showToaster('error', 'Error Assigning vehicle aid!'));
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
    };
  };
}

export const assignAidVehicleSuccess = (response) => {
  return {
    type: actionTypes.VEHICLE_AID_ASSIGN_SUCCESS,
    message: response,
  };
};

export const getAvailableVehicleAids = (params) => {
  return async dispatch => {
    try {
      var res = await axios
        .get(BASE_URL + '/api/assetVehicleDetails?reqType=all&availabilityStatus=available');
        console.log('response',res.data);
      let uniqVehicles = _.uniqBy(res.data, 'assetCategory.assetVehicleCategoryId');

      dispatch(getAvailableVehicleAidsSuccess(uniqVehicles));
    } catch (error) {
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
    };
  };
}
export const resetSelectedAlertsIndicator = () => {
  return {
    type: actionTypes.RESET_ALERTS_SENT_INDICATOR,
    alertSentIndicator: null,
  };
};
export const sendSelectedAlertsAndAssignVehicles = (params) => {
  return async dispatch => {
    let apiCalls = [];
    if (params.alertIds && params.alertIds.length > 0) {
      params.alertIds.forEach((alertId) => {
        console.log(alertId)
        apiCalls.push(axios.patch(BASE_URL + '/api/eventAlerts/' + alertId + '/sendMessage'));
      })
    }

    if (params.selectedVehicles && params.selectedVehicles.length > 0) {
      params.selectedVehicles.forEach((vehicle) => {
        apiCalls.push(axios
          .post(BASE_URL + '/api/eventAidVehicles/', {
            assetVehicleCategoryId: vehicle.assetVehicleCategoryId,
            assetVehicleDetailsId: vehicle.assetVehicleDetailsId,
            aidVehicleStatus: 1,
            aidVehicleInchargeContact: vehicle.assetVehicleDetailsContactNumber,
            txnEventId: params.eventId,
          }));
      })
    }

    if (apiCalls.length == 0 && !params.manualAlert) {
      dispatch(showToaster('error', 'No alerts or vehicle aid was selected'));
    } else {
      console.log('apiCalls', apiCalls)
      Promise.all(
        apiCalls
      ).then(function (responses) {
        // Get a JSON object from each of the responses
        console.log('whats here??');
        console.log(responses)
      }).then(async function (data) {
        // Log the data to the console
        // You would do something with both sets of data here
        console.log('data',data);
        if (params.manualAlert) {
          var res = await axios
            .post(BASE_URL + '/api/eventAlerts/', params.manualAlert);
          var txnEventAlertId = res.data.txnEventAlertId;
          console.log('manual alert',res, txnEventAlertId)
          let manualAlertRes = await axios.patch(BASE_URL + '/api/eventAlerts/' + txnEventAlertId + '/sendMessage')
          dispatch(selectedAlertsSentSuccess('success'));
          dispatch(showToaster('success', 'All Alerts sent successfully!'));
        } else {
          dispatch(selectedAlertsSentSuccess('success'));
          dispatch(showToaster('success', 'All Alerts sent successfully!'));
        }
      }).catch(function (error) {
        // if there's an error, log it
        console.log(error);
        dispatch(selectedAlertsSentFailed(error.response.data.message));
        dispatch(showToaster('error', error.response.data.message));
      })
    }
  };
}



export const selectedAlertsSentSuccess = (response) => {
  return {
    type: actionTypes.ALL_SENT_ALERTS_SUCCESS,
    alertSentIndicator: 'Alerts sent!',
  };
};
export const selectedAlertsSentFailed = (response) => {
  return {
    type: actionTypes.SEND_ALL_ALERTS_VEHICLES_FAILED,
    message: 'Alerts not sent!',
  };
};

export const getAvailableVehicleAidsSuccess = (vehicles) => {
  return {
    type: actionTypes.AVAILABLE_VEHICLES_SUCCESS,
    availableVehicles: vehicles,
  };
};

export const sendManualAlert = (body) => {
  return async dispatch => {
    try {
      console.log('@#@#body', body)
      var res = await axios
        .post(BASE_URL + '/api/eventAlerts/', body);
      var txnEventAlertId = res.data.txnEventAlertId;
      let manualAlertRes = await axios.patch(BASE_URL + '/api/eventAlerts/' + txnEventAlertId + '/sendMessage')
      //console.log(res.data);
      dispatch(sendManualAlertSuccess('Success'));
      dispatch(showToaster('success', 'Manual Alert sent successfully!'));
    } catch (error) {
      dispatch(showToaster('error',error.response.data.message));
      // dispatch(getEventGeneralDetailsFail(error.response.data.message));
    };
  };
}

export const sendManualAlertSuccess = (_) => {
  return {
    type: actionTypes.SEND_MANUAL_ALERT_SUCCESS,
    message: 'Manual Alert Sent Successfully',
  };
};
