import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const sopMapStateReset = () => {
    return {
        type: actionTypes.SOP_MAP_STATE_RESET
    };
};

export const getEventTypesSuccess = (eventTypes) => {
    return {
        type: actionTypes.SOP_MAP_GET_EVENT_TYPE,
        eventTypes: eventTypes
    }
}

export const getEventTypes = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${BASE_URL}/api/eventTypes?reqType=all`)
            dispatch(getEventTypesSuccess(response.data))
        } catch (error) {
            console.log(" getEventType error: ", error)
        }
    }
}

export const getEventSubtypesSuccess = (eventSubtypes) => {
    return {
        type: actionTypes.SOP_MAP_GET_EVENT_SUBTYPE,
        eventSubtypes: eventSubtypes
    }
}

export const getEventSubtypes = (eventTypeId) => {
    return async dispatch => {
        try {
            const response = await axios.get(`${BASE_URL}/api/eventSubtypes?reqType=all&eventTypeId=${eventTypeId}`)
            dispatch(getEventSubtypesSuccess(response.data))
        } catch (error) {
            console.log("getEventSubtypes error: ", error)
        }
    }
}

export const getEventSopCategoriesSuccess = (eventSopCategories) => {
    return {
        type: actionTypes.GET_SOP_CATEGORY,
        eventSopCategories: eventSopCategories
    }
}

export const getEventSopCategories = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${BASE_URL}/api/sopCategories?reqType=all`);
            dispatch(getEventSopCategoriesSuccess(response.data))
        } catch (error) {
            console.log("get Sop categories error: ", error)
        }
    }
}

export const getEventSopItemsSuccess = (eventSopItems) => {
    return {
        type: actionTypes.GET_SOP_ITEM,
        eventSopItems: eventSopItems
    }
}

export const getEventSopItems = (eventSopCategoryId) => {
    return async dispatch => {
        try {
            const response = await axios.get(`${BASE_URL}/api/sopItems?reqType=all&sopCategoryId=${eventSopCategoryId}`);
            dispatch(getEventSopItemsSuccess(response.data))
        } catch (error) {
            console.log("getEventSopItems error: ", error)
        }
    }
}

export const sopMapCreateStart = () => {
    return {
        type: actionTypes.SOP_MAP_CREATE_START
    };
};


export const sopMapEdit = formValues => {
    let isEnable = 1;
    let eventSopDisplayIndex = parseInt(formValues.eventSopDisplayIndex, 10);

    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        isEnable = 1;
    } else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        isEnable = 0;
    }

    let values = {
        isEnabled: isEnable,
        eventSopDisplayIndex: eventSopDisplayIndex
    }

    return async dispatch => {
        try {
            dispatch(sopMapCreateStart());
            const response = await axios.patch(BASE_URL + "/api/eventSop/" + formValues.eventSopId, values)
            console.log("SOP Edit response: ", response)
            dispatch(showToaster('success', 'Event SOP Edited Successfully'))
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message))
        }
    }
}

export const sopMapCreateError = (error) => {
    return {
        type: actionTypes.SOP_MAP_ERROR,
        error: error
    }
}

export const sopMapCreate = (formValues, sopMapState) => {
    let values = {
        eventTypeId: formValues.eventTypeId,
        eventSubtypeId: formValues.eventSubtypeId,
        eventSopCategoryId: formValues.eventSopCategoryId,
        eventSopItemId: formValues.eventSopItemId,
        eventSopDisplayIndex: formValues.eventSopDisplayIndex
    }
    return async dispatch => {
        try {
            dispatch(sopMapCreateStart());
            const res = await axios.post(BASE_URL + "/api/eventSop", values);
            console.log('SOP Create res: ', res)
            dispatch(sopMapCreateError(null))
            dispatch(showToaster('success', 'Event SOP created Successfully'));
        } catch (error) {
            console.log("post error: ", error.response.data.name)
            let e = error.response.data.name
            dispatch(sopMapCreateError(e))
            dispatch(showToaster('error', error.response.data.message));
        }
    }
}
