import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getAssetVehicleCatSuccess = (arrAssetVehicleCat) => {
    return {
        type: actionTypes.GET_ASSET_VEHICLE_CAT_SUCCESS,
        arrAssetVehicleCat: arrAssetVehicleCat
    };
};

export const getAssetVehicleCat = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/assetVehicleCategories?reqType=all")
            .then(res => {
                console.log(" getAssetVehicleCat res: ", res.data);
                dispatch(getAssetVehicleCatSuccess(res.data));
            }).catch(error => {
                console.log("getAssetVehicleCat res ERROR :" + error.response.data.message);
                dispatch(showToaster('error', error.response.data.message))
            });
    };
}

export const assetVehicleDetailsCreateStart = () => {
    return {
        type: actionTypes.ASSET_VEHICLE_DETAILS_CREATE_START
    };
};

export const assetVehicleDetailsCreate = (formValues, vehicleCatState) => {
    let values = {
        // assetVehicleDetailsId: vehicleCatState.assetVehicleDetailsId,
        assetVehicleCategoryId: formValues.assetVehicleCategoryId,
        assetVehicleDetailsClass: formValues.assetVehicleDetailsClass,
        assetVehicleDetailsName: formValues.assetVehicleDetailsName,
        assetVehicleDetailsAvailabilityStatus: formValues.assetVehicleDetailsAvailabilityStatus,
        assetVehicleDetailsBaseLocation: formValues.assetVehicleDetailsBaseLocation,
        assetVehicleDetailsColor: formValues.assetVehicleDetailsColor,
        assetVehicleDetailsContactNumber: formValues.assetVehicleDetailsContactNumber,
        assetVehicleDetailsDescription: formValues.assetVehicleDetailsDescription,
        assetVehicleDetailsImageExtension: formValues.assetVehicleDetailsImageExtension,
        assetVehicleDetailsImageName: formValues.assetVehicleDetailsImageName,
        assetVehicleDetailsImagePath: formValues.assetVehicleDetailsImagePath,
        assetVehicleDetailsMobileImeiNumber: formValues.assetVehicleDetailsMobileImeiNumber,
        assetVehicleDetailsModel: formValues.assetVehicleDetailsModel,
        assetVehicleDetailsNumber: formValues.assetVehicleDetailsNumber,
        vehicleAppRegId: formValues.vehicleAppRegId,

    }
    return async (dispatch) => {
        try {
            dispatch(assetVehicleDetailsCreateStart());
            const response = await axios.post(BASE_URL + "/api/assetVehicleDetails", values);
            console.log("assetVehicleDetailsCreate response: ", response)
            dispatch(showToaster('success', 'Asset Vehicle Details Successfully Created.'));
        } catch (error) {
            console.log(error);
            dispatch(showToaster('error', error.response.data.message));
        }
    };
};

export const assetVehicleDetailsStateReset = () => {
    return {
        type: actionTypes.ASSET_VEHICLE_DETAILS_STATE_RESET
    };
};

export const assetVehicleDetailsEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if (formValues.isEnabled === true || formValues.isEnabled === 1) {
        finalIsEnabled = 1;
    }
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled = 0;
    }

    let values = {
        assetVehicleCategoryId: formValues.assetVehicleCategoryId,
        assetVehicleDetailsName: formValues.assetVehicleDetailsName,
        assetVehicleDetailsDescription: formValues.assetVehicleDetailsDescription,
        assetVehicleDetailsBaseLocation: formValues.assetVehicleDetailsBaseLocation,
        assetVehicleDetailsContactNumber: formValues.assetVehicleDetailsContactNumber,
        assetVehicleDetailsNumber: formValues.assetVehicleDetailsNumber,
        assetVehicleDetailsClass: formValues.assetVehicleDetailsClass,
        assetVehicleDetailsColor: formValues.assetVehicleDetailsColor,
        assetVehicleDetailsModel: formValues.assetVehicleDetailsModel,
        assetVehicleDetailsMobileImeiNumber: formValues.assetVehicleDetailsMobileImeiNumber,
        vehicleAppRegId: formValues.vehicleAppRegId,
        assetVehicleDetailsAvailabilityStatus: formValues.assetVehicleDetailsAvailabilityStatus,
        assetVehicleDetailsImagePath: formValues.assetVehicleDetailsImagePath,
        assetVehicleDetailsImageName: formValues.assetVehicleDetailsImageName,
        assetVehicleDetailsImageExtension: formValues.assetVehicleDetailsImageExtension,
        isEnabled: finalIsEnabled

    }
    return async (dispatch) => {
        try {
            dispatch(assetVehicleDetailsCreateStart());
            const response = await axios.patch(BASE_URL + "/api/assetVehicleDetails/" + formValues.assetVehicleDetailsId, values);
            dispatch(showToaster('success', 'Asset Vehicle Details Successfully Edited.'));
        } catch (error) {
            dispatch(showToaster('error', error.response.data.message));
        }
    }
}