import React from 'react';
import ChainageAddForm from './chainageAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class ChainageEdit extends React.Component {
    
    componentDidMount() {
           this.props.chainageStateReset();         
       }

    onSubmit = formValues => {        
        console.log("edit formValues",formValues) ;
        
        this.props.chainageEdit(formValues);
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        let progress = null;
        let alert = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        console.log("Chainage Edit: InitialValues", this.props.initialValues);

        return (
            <React.Fragment>
                {alert}
                {progress}
                <ChainageAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.chainageReducer.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        chainageEdit: ( formValues ) => dispatch( actions.chainageEdit( formValues ) ),
        chainageStateReset: () => dispatch(actions.chainageStateReset())
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ChainageEdit));