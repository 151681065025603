import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card, CardHeader, Divider, CardContent, Grid, IconButton, Box, FormHelperText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class StakeholderUserAddForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            stakeholdersCategoryId: 0,
            shiftId: 0,
            isNotificationEnable: false,
            isPrimaryContact: false,
        };
    }

    componentDidMount() {
        this.props.getStakeholderCat();
        this.props.getShifts();
    }
    onChange = event => {
        console.log("event.target.id=" + event.target.id);
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log("onSubmit formvalues", values);
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;


        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Stakeholder User"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Stakeholder User"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                        <CardHeader
                            subheader={subheaderName}
                            title="Stakeholder User"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />

                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="stakeholdersCategoryId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Stakeholders Category" id="stakeholdersCategoryId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrStakeholdersCat.map((stakeholdersCatDropDown) => <option key={stakeholdersCatDropDown.stakeholdersCategoryId} value={stakeholdersCatDropDown.stakeholdersCategoryId}>{stakeholdersCatDropDown.stakeholdersCategoryName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="stakeholderUserName" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Stakeholder User Name" id="stakeholderUserName" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="stakeholderUserMobileNo" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Stakeholder User Mobile No" id="stakeholderUserMobileNo" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="stakeholderUserEmailId" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Stakeholder User Email Id" id="stakeholderUserEmailId" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="stakeholderUserAddress" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Stakeholder User Address" id="stakeholderUserAddress" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="stakeholderUserDesignation" component={reduxFormFunctions.renderTextField} onChange={this.onChange} label="Stakeholder User Designation" id="stakeholderUserDesignation" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="isNotificationEnable" component={reduxFormFunctions.radioIsNotificationEnabled} label="is Notification Enable" id="isNotificationEnable" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="isPrimaryContact" component={reduxFormFunctions.radioIsPrimaryContact} label="is Primary Contact" id="isPrimaryContact" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="shiftId" component={reduxFormFunctions.renderSelectField} onChange={this.onChange} label="Select Shift" id="shiftId" >
                                        <option value={""}>{''}</option>
                                        {this.props.ArrShift.map((shiftDropDown) => <option key={shiftDropDown.shiftId} value={shiftDropDown.shiftId}>{shiftDropDown.shiftName}</option>)}
                                    </Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        )
    }
}

const validate = formValues => {
    const error = {};
    const RegExpMobileNumber = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    const RegExpEmailId = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;

    if (!formValues.stakeholdersCategoryId) {
        error.stakeholdersCategoryId = "Select Stakeholder Category"
    }
    if (formValues.stakeholdersCategoryId) {
        if (formValues.stakeholdersCategoryId == "") {
            error.stakeholdersCategoryId = "Select Stakeholder Category"
        }
    }
    if (!formValues.stakeholderUserName) {
        error.stakeholderUserName = "Enter Stakeholder User Name";
    }
    if (!formValues.stakeholderUserMobileNo) {
        error.stakeholderUserMobileNo = "Enter Stakeholder User Mobile Number";
    }
    if (formValues.stakeholderUserMobileNo) {
        if (!RegExpMobileNumber.test(formValues.stakeholderUserMobileNo)) {
            error.stakeholderUserMobileNo = "Enter Valid Mobile Number";
        }
    }
    if (!formValues.stakeholderUserEmailId) {
        error.stakeholderUserEmailId = "Enter StakeholderUser Email Id";
    }
    if (formValues.stakeholderUserEmailId) {
        if (!RegExpEmailId.test(formValues.stakeholderUserEmailId)) {
            error.stakeholderUserEmailId = "Enter Valid Email Id";
        }
    }
    if (!formValues.stakeholderUserAddress) {
        error.stakeholderUserAddress = "Enter Stakeholder User Address";
    }
    if (!formValues.stakeholderUserDesignation) {
        error.stakeholderUserDesignation = "Enter Stakeholder User Designation";
    }
    if (!formValues.shiftId) {
        error.shiftId = "Select Shift";
    }
    if (formValues.shiftId) {
        if (formValues.shiftId == "") {
            error.shiftId = "Select Shift";
        }
    }

    return error;
}

const mapStateToProps = state => {
    return {
        ArrStakeholdersCat: state.stakeholderUserReducer.ArrStakeholdersCat,
        ArrShift: state.stakeholderUserReducer.ArrShift
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getStakeholderCat: () => dispatch(actions.getStakeholderCat()),
        getShifts: () => dispatch(actions.getShifts())
    }
}

StakeholderUserAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(StakeholderUserAddForm);


export default reduxForm({
    validate: validate,
    form: 'StakeholderUserAddForm'
})(withStyles(styles)(StakeholderUserAddForm))