import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';


const initialState = {
    equipments: [],
    equipmentCategories: [],
    eventsOnMap: []
};


const equipmentsOnMapSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        equipments : action.equipments
    });
    return updatedState;
};

const equipmentsCategoriesSuccess = ( state, action ) => {
    console.log(state, action.equipmentCategories);
    var updatedState = updateObject(state, {
        equipmentCategories : action.equipmentCategories,
    });
    return updatedState;
};


const eventsOnMapSuccess = ( state, action ) => {
    var updatedState = updateObject(state, {
        eventsOnMap : action.eventsOnMap
    });
    return updatedState;
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.GET_EQUIPMENTS_ON_MAPS:
            return equipmentsOnMapSuccess(state, action);
        case actionTypes.GET_EQUIPMENT_CATEGORIES:
            return equipmentsCategoriesSuccess(state, action);
        case actionTypes.GET_EVENTS_ON_MAPS:
            return eventsOnMapSuccess(state, action);
        default:
            return state;
    }
}

export default reducer;