import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { Button, Container, Card,CardHeader, Divider, CardContent, Grid, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as reduxFormFunctions from '../../../../components/common/Redux_forms_Common';
import getRoute from '../../../../utils/getRoute';
import checkEntitlement from "../../../../utils/checkEntitlement";

const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    button: {
        marginRight: theme.spacing(2)
    }
})

class NotificationCatAddForm extends React.Component {

    //#region On Change
    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    //#endregion

    //#region  Form Submit
    onSubmit = (values) => {
        this.props.onSubmit(values);
        console.log(JSON.stringify(values));
    }

    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        let subheaderName = null;

        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
            subheaderName = "Add Notification Category"
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(),'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
            subheaderName = "Edit Notification Category"
        }

        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Card>
                    <CardHeader
                            subheader={subheaderName}
                            title="Notification Category"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationCategoryName" id="notificationCategoryName" component={reduxFormFunctions.renderTextField} label="Notification Category Name" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="notificationCategoryDescription" id="notificationCategoryDescription" component={reduxFormFunctions.renderTextField} label="Notification Category Description" onChange={this.onChange}></Field>
                                </Grid>
                                <Grid>{isEnabled}</Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>  
                    </Card>
                </form>
            </Container>
        )
    }
}

//#region Validations
const validate = formValues => {
    const error = {};

    if (!formValues.notificationCategoryName) {
        error.notificationCategoryName = "Enter Notification Category Name";
    }
    if (!formValues.notificationCategoryDescription) {
        error.notificationCategoryDescription = "Enter Notification Category Description";
    }

    if(formValues.notificationCategoryDescription) {
        if(formValues.notificationCategoryDescription.length > 201){
            error.notificationCategoryDescription = "Minimum characters should be 200 only";
        }
    }

    return error;
};
// //#endregion

export default reduxForm({
    validate: validate,
    form: 'NotificationCatAddForm'
})(withStyles(styles)(NotificationCatAddForm));