import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Divider, Button, Modal, Box, Card, CardContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from 'react-redux';
import EcbCreate from './ecbCreate';
import EcbEdit from './ecbEdit';
import ECBAudioPlayer from '../../mapsDashboard/equipmentModals/ECBAudioPlayer';
import commonStyles from '../../../commonStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EcbFilter from './ecbFilter';
import Toaster from '../../../components/common/Toaster';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import axios from 'axios';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    ...commonStyles(theme),
    searchBox: {
        marginTop: theme.spacing(5),
        clear: 'both'
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto'
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    }
});

class ECB extends React.Component {
    currentSelectedRow = null;

    constructor(props) {
        super(props);
        this.state = {
            urlParams: [],
            isOpen: false,
            modalType: '',
            audioModalOpen: false,
            audioSrc: null
        }
        this.grid = React.createRef();  //Doubt
    }

    closeModal = () => {
        this.setState({ audioModalOpen: false });
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value    //why there is [] brackets for name.
        })
    }

    componentDidUpdate(prevProps, prevState) {
        /*
         * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
         */
        if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
            this.grid.current.reload();
        }
    }

    // Called by the Search Filter 
    onSearchSubmit = (urlParams) => {
        this.setState({ urlParams: urlParams })
    }

    render() {
        const { classes } = this.props;
        let modalDiv = null;

        if (this.state.modalType === 'AUDIO') {
            modalDiv = <Modal
                open={this.state.audioModalOpen}
                className={classes.modal}
                onClose={() => { this.closeModal() }}
                closeAfterTransition>
                <ECBAudioPlayer onClose={() => { this.closeModal() }} audioSrc={this.state.audioSrc} />
            </Modal>
        }

        if (this.state.modalType === "ADD") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
                closeAfterTransition
            >
                <EcbCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        if (this.state.modalType === "EDIT") {
            modalDiv = <Modal
                className={classes.modal}
                open={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
                closeAfterTransition>
                <EcbEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
            </Modal>
        }

        return (
            <div className={classes.atmsPageOuterDiv} >
                <Card className={classes.atmsPageOuterCard}>
                    <CardHeader title="ECB Call Notification" />
                    <Divider />
                    <CardContent>
                        <Button variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={() => this.setState({ isOpen: true, modalType: "ADD" })}
                        >
                            New Call Details
                        </Button>
                        <Box mt={3} className={classes.searchBox}>
                            <Card>

                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <CardHeader
                                            title="ECB Filters"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <EcbFilter
                                            onSearchSubmit={(urlParams) => {
                                                this.onSearchSubmit(urlParams)
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Card>
                        </Box>

                        {/* Event DataGrid */}
                        <Box mt={3}>
                            <Card>
                                <CardHeader
                                    title="ECB Details"
                                />
                                <DataGrid downloadReport={true}
                                    ref={this.grid}
                                    endpoint="api/ecbCalls"
                                    urlParams={this.state.urlParams}
                                    actions={[
                                        {
                                            icon: VolumeUpIcon,
                                            onClick: async (event, rowData) => {
                                                let path = rowData.fullAudioFilePath;
                                                try {
                                                    const res = await axios.get(`${BASE_URL}/api/uploads?file=${path}`, { responseType: 'blob' });
                                                    console.log("response: ", res)
                                                    this.setState({
                                                        uploadedFile: URL.createObjectURL(res.data)
                                                    })
                                                } catch (error) {
                                                    console.log("ERROR: ", error);
                                                }
                                                console.log("ROWDATA FROM ", rowData);
                                                this.setState({ audioModalOpen: true, audioSrc: this.state.uploadedFile, modalType: 'AUDIO' });
                                            }
                                        }
                                    ]}
                                    onRowClick={(event, rowData) => {
                                        this.currentSelectedRow = rowData
                                        console.log("rowData", rowData);
                                        this.setState({ isOpen: true, modalType: "EDIT" });
                                    }}
                                />
                            </Card>
                        </Box>
                    </CardContent>
                </Card>
                <Toaster
                    open={this.props.toasterOpen}
                    message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
                    severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
                />
                {modalDiv}
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        toasterOpen: state.common.toasterOpen,
        toasterMessageConfig: state.common.toasterMessageConfig,
    }
}


export default connect(mapStateToProps, null)(withStyles(styles)(ECB))