import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, Divider, CardContent, Grid, Typography, Paper, Button, TextField, FormControl, Select, FormControlLabel,
  Checkbox, FormGroup, InputLabel, Input, ListItem, Radio, RadioGroup, MenuItem,
  AccordionDetails, Accordion, AccordionSummary, FormLabel
} from '@material-ui/core';
import _ from 'lodash';
import axios from 'axios';
import * as reduxFormFunctions from '../../components/common/Redux_forms_Common';


import format from 'date-fns/format';
import appConfig from '../../appConfig.json';
import { connect } from "react-redux";

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
  submitButton: {
    marginTop: '10px'
  },
  resetButton: {
    marginTop: '10px',
    marginRight: '20px'
  },
  form: {
    width: '100%'
  }
});

class ReportFilters extends React.Component {
  constructor(props) {
    super();
    var maxPreviousDays = props.timeValue.configValue1;
    console.log("Max Previous Days:",props.timeValue.configValue1);
    this.state = {
      '__filter_name_From Date Time': Intl.DateTimeFormat('en-IN', { 
        hourCycle: 'h23', 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit' }).format(new Date()),
      '__filter_name_To Date Time': Intl.DateTimeFormat('en-IN', { 
        hourCycle: 'h23', 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit' }).format((new Date()).setDate((new Date()).getDate() - parseInt(maxPreviousDays))),

      selectedReport: '',
      equipmentNames: [],

    };
    console.log("Initial State:", this.state);
    this.filterForm = React.createRef();
  }
  dependencies = [];
  dependecyMap = {};

  componentDidMount() {
    this.handleReset();
  }

  getDefaultDate(filterName) {
    let now = new Date();
    let fromDate = new Date();
    let defaultToDate = format(now, "yyyy-MM-dd'T'HH:mm");
    if (filterName == 'TO_DATE_TIME') {
      return defaultToDate;
    } else if (filterName == 'FROM_DATE_TIME') {
      var maxPreviousDays = this.props.timeValue.configValue1;

      return format(fromDate.setDate(fromDate.getDate() - parseInt(maxPreviousDays)), "yyyy-MM-dd'T'HH:mm");
    }
  }

  //componentDidUpdate(prevProps) {
  //  if (this.props.selectedReportId != prevProps.selectedReportId) {
  //    //this means new report is selected and we need to reset filters
    
  //    this.handleReset();
  //  }
  //}

  componentDidUpdate(prevProps) {
    if (this.props.selectedReportId !== prevProps.selectedReportId) {
      // New report is selected, reset filters
      this.handleReset();
      // Update selected report
      this.setState({ selectedReport: this.props.selectedReportId });

      console.log("Selected Report:", this.props.selectedReportId);
  
      // Fetch equipment names based on the selected report
      if (this.props.selectedReportId ) {
        axios
          .get(BASE_URL + "/api/equipmentNames?selectedReportId=" + this.props.selectedReportId)
          .then((res) => {
            if (res.data != null) {
              this.setState({
                equipmentNames: res.data,
              });
            }
            console.log("new equipments:::",res.data)
          })
          .catch((error) => {
            console.log(error)
          });
      }
    }
  }
  
  

  //getFormElement = (elementConfig) => {
  //  const props = {
  //    name: elementConfig.name,
  //    label: elementConfig.alias,
  //    onChange: this.onChange,
  //    value: this.state[elementConfig.name]
  //  };

  //  if (this.dependencies.indexOf(elementConfig.name) != -1) {
  //    props.onChange = this.onChangeFilterRefresh;
  //  }

  //  if (elementConfig.filterType === 'DateTimePicker') {
  //    return (
  //      <FormControl fullWidth={true}>
  //        <TextField
  //          {...props}
  //          variant="outlined"
  //          type="datetime-local"
  //          defaultValue={this.getDefaultDate(elementConfig.name)}
  //          InputLabelProps={{
  //            shrink: true
  //          }}
  //          inputProps={{ label: elementConfig.alias }}
  //        />
  //      </FormControl>
  //    )
  //  }
  //  if (elementConfig.filterType === "TextBox") {
  //    return <TextField {...props} fullWidth={true} variant="outlined" inputProps={{ label: elementConfig.alias }} />
  //  }

  //  if (elementConfig.filterType === "Radio") {
  //    let options = elementConfig.filterOptions.split(',');
  //    let radioOptions = [];
  //    options.forEach((option) => {
  //      let labelValue = option.split(':');
  //      radioOptions.push({ value: labelValue[0], label: labelValue[1] });
  //    });
  //    return (<FormControl>
  //      <FormLabel component="legend">{elementConfig.alias}</FormLabel>
  //      <RadioGroup row {...props}>
  //        {radioOptions.map((option) => {
  //          return (<FormControlLabel value={option.value} control={<Radio />} label={option.label} />);
  //        })}
  //      </RadioGroup>
  //    </FormControl>);
  //  }

  //  if (elementConfig.filterType === "DropDown") {
  //    return (
  //      <FormControl variant="outlined" fullWidth={true}>
  //        <InputLabel>{elementConfig.alias}</InputLabel>
  //        <Select native fullWidth={true} {...props} variant="outlined" inputProps={{ label: elementConfig.alias }} >
  //          <option value="" ></option>
  //          {elementConfig.options && elementConfig.options.map((option) => {
  //            return (<option value={option.value} >{option.label}</option>);
  //          })}
  //        </Select>
  //      </FormControl>
  //    )
  //  }


  //  if (elementConfig.filterType === "DropDown") {
  //    return (
  //      <FormControl variant="outlined" fullWidth={true}>
  //        <InputLabel>{elementConfig.alias}</InputLabel>
  //        <Select
  //          native
  //          fullWidth={true}
  //          {...props}
  //          variant="outlined"
  //          inputProps={{ label: elementConfig.alias }}
  //        >
  //          <option value=""></option>
  //          {this.state.equipmentNames.map((equipmentName) => (
  //            <option key={equipmentName} value={equipmentName}>
  //              {equipmentName}
  //            </option>
  //          ))}
  //        </Select>
  //      </FormControl>
  //    );
  //  }
  //}


  getFormElement = (elementConfig) => {
    const props = {
      name: elementConfig.name,
      label: elementConfig.alias,
      onChange: this.onChange,
      value: this.state[elementConfig.name]
    };
  
    if (this.dependencies.indexOf(elementConfig.name) !== -1) {
      props.onChange = this.onChangeFilterRefresh;
    }
  
    if (elementConfig.filterType === 'DateTimePicker') {
      return (
        <FormControl fullWidth={true}>
          <TextField
            {...props}
            variant="outlined"
            type="datetime-local"
            defaultValue={this.getDefaultDate(elementConfig.name)}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ label: elementConfig.alias }}
          />
        </FormControl>
      );
    }
    if (elementConfig.filterType === "TextBox") {
      return <TextField {...props} fullWidth={true} variant="outlined" inputProps={{ label: elementConfig.alias }} />;
    }
  
    if (elementConfig.filterType === "Radio") {
      let options = elementConfig.filterOptions.split(',');
      let radioOptions = [];
      options.forEach((option) => {
        let labelValue = option.split(':');
        radioOptions.push({ value: labelValue[0], label: labelValue[1] });
      });
      return (
        <FormControl>
          <FormLabel component="legend">{elementConfig.alias}</FormLabel>
          <RadioGroup row {...props}>
            {radioOptions.map((option) => {
              return (<FormControlLabel value={option.value} control={<Radio />} label={option.label} />);
            })}
          </RadioGroup>
        </FormControl>
      );
    }
  
    if (elementConfig.filterType === "DropDown") {
      if (elementConfig.name === 'equipmentNames') {
        return (
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel>{elementConfig.alias}</InputLabel>
            <Select
              native
              fullWidth={true}
              {...props}
              variant="outlined"
              inputProps={{ label: elementConfig.alias }}
            >
              <option value=""></option>
              {this.state.equipmentNames.map((equipmentName) => (
                <option key={equipmentName} value={equipmentName}>
                  {equipmentName}
                </option>
              ))}
            </Select>
          </FormControl>
        );
      }
      else {
        return (
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel>{elementConfig.alias}</InputLabel>
            <Select native fullWidth={true} {...props} variant="outlined" inputProps={{ label: elementConfig.alias }} >
              <option value=""></option>
              {elementConfig.options && elementConfig.options.map((option) => {
                return (<option value={option.value}>{option.label}</option>);
              })}
            </Select>
          </FormControl>
        );
      }
    }
  }
  

  handleReset = () => {
    let stateObj = {};
    let now = new Date();
    let fromDate = new Date();
    for (const objKey in this.state) {
      stateObj[objKey] = '';
    }
    var maxPreviousDays = 90;
    stateObj['TO_DATE_TIME'] = format(now, "yyyy-MM-dd'T'HH:mm");
    stateObj['FROM_DATE_TIME'] = format(fromDate.setDate(fromDate.getDate() - parseInt(maxPreviousDays)), "yyyy-MM-dd'T'HH:mm");
    stateObj['__filter_name_From Date Time'] = Intl.DateTimeFormat('en-IN', { 
      hourCycle: 'h23', 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit' }).format(now);
    stateObj['__filter_name_To Date Time'] = Intl.DateTimeFormat('en-IN', { 
      hourCycle: 'h23', 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit' }).format(fromDate.setDate(fromDate.getDate() - parseInt(maxPreviousDays)));
    this.setState(stateObj);
    let reqBody = _.omitBy(stateObj, (val) => val == '')
    console.log(reqBody);
    this.props.applyFilter(reqBody);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let reqBody = _.omitBy(this.state, (val) => val == '')
    console.log(reqBody);
    this.props.applyFilter(reqBody);
  }

  onChange = (event, x) => {
    let stateObj = { [event.target.name]: event.target.value };
    if (event.target.type == 'select-one') {
      stateObj['__filter_name_' + event.target.getAttribute('label')] = event.target.selectedOptions[0].text;
    } else if (event.target.type == 'datetime-local') {
      stateObj['__filter_name_' + event.target.getAttribute('label')] = Intl.DateTimeFormat('en-IN', { 
        hourCycle: 'h23', 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(event.target.value));
    } else if (event.target.type == 'text') {
      stateObj['__filter_name_' + event.target.getAttribute('label')] = event.target.value;
    }
    this.setState(stateObj);

  }

  onChangeFilterRefresh = event => {
    //let uniqueObjs = _.omitBy(this.state, (val) => val == '');
    let filterValues = [];
    if (this.state[event.target.name] == null) {
      filterValues.push({ key: event.target.name, value: event.target.value })
    }
    for (const objKey in this.state) {
      if (objKey == event.target.name) {
        filterValues.push({ key: objKey, value: event.target.value })
      } else {
        filterValues.push({ key: objKey, value: this.state[objKey] })
      }
    }
    filterValues = _.filter(filterValues, (filter) => filter.value != '');
    let stateObj = { [event.target.name]: event.target.value };
    this.dependecyMap[event.target.name].forEach((depFilter) => {
      stateObj[depFilter] = '';
    })
    if (event.target.type == 'select-one') {
      stateObj['__filter_name_' + event.target.getAttribute('label')] = event.target.selectedOptions[0].text;
    }
    // else if (event.target.type == 'datetime-local') {
    //   stateObj['__filter_name_' + event.target.getAttribute('label')] = Intl.DateTimeFormat('en-IN', { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(event.target.value));
    // }else if (event.target.type == 'text') {
    //   stateObj['__filter_name_' + event.target.getAttribute('label')] = event.targt.value;
    // }
    this.setState(stateObj);
    this.props.refreshFilters(filterValues);
  }

  render() {
    const { classes } = this.props;
    console.log(this.props.filters);
    this.dependencies = [];
    this.dependecyMap = {};
    this.props.filters.forEach((filterField, index) => {
      if (filterField.dependencies != null) {
        this.dependencies.push(filterField.dependencies.reportFilterName);
        if (this.dependecyMap[filterField.dependencies.reportFilterName] == null) {
          this.dependecyMap[filterField.dependencies.reportFilterName] = [];
        }
        this.dependecyMap[filterField.dependencies.reportFilterName].push(filterField.name);
      }
    });
    return (
      <React.Fragment >
        <form name="filterForm" className={classes.form} ref={this.filterForm} autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
          <Grid container spacing={2}>
            {this.props.filters.map((filterField, index) => {
              return (
                <Grid item md={3} xs={12}>
                  {this.getFormElement(filterField)}

                </Grid>
              )
            })
            }
          </Grid>
          <Button className={classes.resetButton} onClick={this.handleReset} color="secondary" size="large" variant="contained">RESET</Button>
          <Button type="submit" className={classes.submitButton} color="primary" size="large" variant="contained">SUBMIT</Button>
        </form>
      </React.Fragment >
    );
  }
}


const mapStateToProps = state => {
  return {
    timeValue: state.common.globalConfig.date_filter_range
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // changeEventStatus: (eventId, body) => dispatch(actions.changeEventStatus(eventId, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportFilters));