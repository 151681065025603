import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import EcbAddForm from './ecbAddForm';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import axios from 'axios';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;


const styles = theme => ({
    spinDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

class EcbEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedFile: null
        }
    }

    async componentDidMount() {
        this.props.ecbStateReset();

        if (this.props.initialValues.event !== null) {
            this.props.initialValues.directionName = this.props.initialValues.event.directionName
            this.props.initialValues.chainageName = this.props.initialValues.event.chainageName
        } else if (!this.props.initialValues.event && this.props.initialValues.equipment) {
            this.props.initialValues.directionName = this.props.initialValues.equipment.directionName
            this.props.initialValues.chainageName = this.props.initialValues.equipment.chainageName
        } else {
            this.props.initialValues.directionName = null
            this.props.initialValues.chainageName = null
        }

        if (this.props.initialValues) {
            let path = this.props.initialValues.fullAudioFilePath;
            try {
                const res = await axios.get(`${BASE_URL}/api/uploads?file=${path}`, { responseType: 'blob' });
                console.log("response: ", res)
                this.setState({
                    uploadedFile: URL.createObjectURL(res.data)
                })
            } catch (error) {
                console.log("EDIT ERROR: ", error);
            }
        }
        console.log("Uploaded from EDIT file", this.state.uploadedFile)
    }


    onSubmit = async (formValues, ecbState) => {
        await this.props.ecbEdit(formValues, ecbState);

        if (formValues.isEvent === true) { //If isEvent is true then only redirect the user to addevent page
            const eventId = this.props.eventId

            this.props.history.push({
                pathname: '/addevent',
                state: {
                    eventId: eventId,
                    fromRout: 'ecb',
                }
            })
        }
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        let progress = null;

        if (this.props.loading) {
            progress = <div className={classes.spinDiv}>
                <CircularProgress size={200} thickness={2} />
            </div>
        }
        return (
            <React.Fragment>
                {progress}
                <EcbAddForm initialValues={this.props.initialValues} type="Edit" onClose={this.props.onClose} onSubmit={this.onSubmit} uploaded={this.state.uploadedFile} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.ecbReducer.loading,
        eventId: state.ecbReducer.eventId
    };
};
const mapDispatchToProps = dispatch => {
    return {
        ecbEdit: (formValues, ecbState) => dispatch(actions.ecbEdit(formValues, ecbState)),
        ecbStateReset: () => dispatch(actions.ecbStateReset())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EcbEdit)));