import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const getEventTypesForEventSubtypeSuccess = (arrEventType) => {
    return {
        type: actionTypes.GET_EVENT_TYPES_FOR_EVENT_SUBTYPE_SUCCESS,
        arrEventType: arrEventType
    }
}

export const getEventSeverityForEventSubtypeSuccess = (arrEventSeverity) => {
    return {
        type: actionTypes.GET_EVENT_SEVERITY_FOR_EVENT_SUBTYPE_SUCCESS,
        arrEventSeverity: arrEventSeverity
    }
}

export const getEventTypesForEventSubtype = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/eventTypes?reqType=all")
            .then(res => {
                console.log("ACTION for Event Types: " + res.data);
                dispatch(getEventTypesForEventSubtypeSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const getEventSeverityForEventSubtype = () => {
    return dispatch => {
        axios
            .get(BASE_URL + "/api/eventSeverity?reqType=all")
            .then(res => {
                console.log("ACTION for Event Severity : " + res.data);
                dispatch(getEventSeverityForEventSubtypeSuccess(res.data));
            }).catch(error => {
                console.log("ACTION ERROR :" + error.response.data.message);
                dispatch(showToaster('error',error.response.data.message))
            });
    };
}

export const eventSubtypeCreateStart = () => {
    return {
        type: actionTypes.EVENT_TYPE_CREATE_START
    };
};

export const eventSubtypeCreate = (formValues) => {
    return async (dispatch) => {
        try {
            dispatch(eventSubtypeCreateStart());
            const response = await axios.post(BASE_URL + "/api/eventSubtypes", formValues);
            dispatch(showToaster('success','Event Subtype Successfully Created.'));

        } catch (error) {
            console.log(error);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}

export const eventSubtypeStateReset = () => {
    return {
        type: actionTypes.EVENT_SUBTYPE_STATE_RESET
    };
};

export const eventSubtypeEdit = (formValues) => {
    console.log("Edit: " + JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    console.log("finalIsEnabled",finalIsEnabled);
    let values = {
        eventTypeId: formValues.eventTypeId,
        eventSubtypeName: formValues.eventSubtypeName,
        eventSubtypeDescription: formValues.eventSubtypeDescription,
        sensorEventId: formValues.sensorEventId,
        eventSeverityId: formValues.eventSeverityId,
        registerEventSampleCount: formValues.registerEventSampleCount,
        registerEventSamplingTime: formValues.registerEventSamplingTime,
        breakEventSamplingTime: formValues.breakEventSamplingTime,
        breakEventSampleCount: formValues.breakEventSampleCount,
        isEnabled: finalIsEnabled
    }

    return async (dispatch) => {
        try {
            dispatch(eventSubtypeCreateStart());
            const response = await axios.patch(BASE_URL + "/api/eventSubtypes/" + formValues.eventSubtypeId, values);
            dispatch(showToaster('success','Event Subtype Successfully Edited.'));
        
        } catch (error) {
            dispatch(showToaster('error',error.response.data.message))
        }
    }
}