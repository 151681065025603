import React from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import { withStyles } from '@material-ui/core/styles';
import { Menu, Card, CardContent, CardHeader, MenuItem, Divider, Grid, Button, ListItemIcon, Modal, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as ANPRLogo } from "../../assets/logos/equipments/ANPR.svg";     // 1
import anprSrc from "../../assets/logos/equipments/ANPR.svg";
import ANPRDetails from "./equipmentModals/ANPRDetails";
import { ReactComponent as ATCCLogo } from "../../assets/logos/equipments/ATCC.svg";     // 2
import atccSrc from "../../assets/logos/equipments/ATCC.svg";
import ATCCDetails from "./equipmentModals/ATCCDetails";
import { ReactComponent as CCTVLogo } from "../../assets/logos/equipments/CCTV.svg";     // 3
import cctvSrc from "../../assets/logos/equipments/CCTV.svg";
import CCTVDetails from "./equipmentModals/CCTVDetails";
import { ReactComponent as ECBLogo } from "../../assets/logos/equipments/ECB.svg";       // 4
import ecbSrc from "../../assets/logos/equipments/ECB.svg";
import ECBDetails from "./equipmentModals/ECBDetails";
import { ReactComponent as METLogo } from "../../assets/logos/equipments/MET.svg";       // 5
import metSrc from "../../assets/logos/equipments/MET.svg";
import METDetails from "./equipmentModals/METDetails";
import { ReactComponent as PTZLogo } from "../../assets/logos/equipments/PTZ.svg";       // 6
import ptzSrc from "../../assets/logos/equipments/PTZ.svg";
import PTZDetails from "./equipmentModals/PTZDetails";
import { ReactComponent as VIDSLogo } from "../../assets/logos/equipments/VIDS.svg";     // 7
import vidsSrc from "../../assets/logos/equipments/VIDS.svg";
import VIDSDetails from "./equipmentModals/VIDSDetails";
import { ReactComponent as VMSLogo } from "../../assets/logos/equipments/VMS.svg";       // 8
import vmsSrc from "../../assets/logos/equipments/VMS.svg";
import ctrCenterSrc from "../../assets/logos/equipments/CMDCTR-BLUE.png";
import VMSDetails from "./equipmentModals/VMSDetails";
import { ReactComponent as SELECTALLLogo } from "../../assets/logos/equipments/ALL.svg"; // 9
import commonStyles from '../../commonStyles';
import RoomIcon from '@material-ui/icons/Room';

import anprGreenSrc from "../../assets/logos/equipments/ANPR-GREEN.png";
import anprRedSrc from "../../assets/logos/equipments/ANPR-RED.png";
import atccGreenSrc from "../../assets/logos/equipments/ATCC-GREEN.png";
import atccRedSrc from "../../assets/logos/equipments/ATCC-RED.png";
import cctvGreenSrc from "../../assets/logos/equipments/CCTV-GREEN.png";
import cctvRedSrc from "../../assets/logos/equipments/CCTV-RED.png";
import ecbGreenSrc from "../../assets/logos/equipments/ECB-GREEN.png";
import ecbRedSrc from "../../assets/logos/equipments/ECB-RED.png";
import metGreenSrc from "../../assets/logos/equipments/MET-GREEN.png";
import metRedSrc from "../../assets/logos/equipments/MET-RED.png";
import ptzGreenSrc from "../../assets/logos/equipments/PTZ-GREEN.png";
import ptzRedSrc from "../../assets/logos/equipments/PTZ-RED.png";
import vidsGreenSrc from "../../assets/logos/equipments/VIDS-GREEN.png";
import vidsRedSrc from "../../assets/logos/equipments/VIDS-RED.png";
import vmsGreenSrc from "../../assets/logos/equipments/VMS-GREEN.png";
import vmsRedSrc from "../../assets/logos/equipments/VMS-RED.png";

const logos = {
    // 'ANPR': ANPRLogo,
    'ANPR_SPEED': ANPRLogo,
    'ATCC': ATCCLogo,
    'CCTV': CCTVLogo,
    'ECB': ECBLogo,
    'MET': METLogo,
    'PTZ': PTZLogo,
    'VIDS': VIDSLogo,
    'FIXED VMS': VMSLogo
};

const svgLocation = {
    'ANPR_SPEED': anprSrc,
    // 'ANPR': anprSrc,
    'ATCC': atccSrc,
    'CCTV': cctvSrc,
    'ECB': ecbSrc,
    'MET': metSrc,
    'PTZ': ptzSrc,
    'VIDS': vidsSrc,
    'FIXED VMS': vmsSrc,
    'CTRLCTR': ctrCenterSrc,
};


const pngGreenLocation = {
    'ANPR_SPEED': anprGreenSrc,
    'ATCC': atccGreenSrc,
    'CCTV': cctvGreenSrc,
    'ECB': ecbGreenSrc,
    'MET': metGreenSrc,
    'PTZ': ptzGreenSrc,
    'VIDS': vidsGreenSrc,
    'FIXED VMS': vmsGreenSrc,
};

const pngRedLocation = {
    'ANPR_SPEED': anprRedSrc,
    'ATCC': atccRedSrc,
    'CCTV': cctvRedSrc,
    'ECB': ecbRedSrc,
    'MET': metRedSrc,
    'PTZ': ptzRedSrc,
    'VIDS': vidsRedSrc,
    'FIXED VMS': vmsRedSrc,
};

const mapContainerStyleDiv = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: 18.652252,
    lng: 73.741905
};

const styles = theme => ({
    ...commonStyles(theme),
    card: {
        marginTop: '70px',
        marginLeft: '20px'
    },
    root: {
        flexGrow: 1,
    },
    mapDiv: {
        height: '80vh'
    },
    header: {
        padding: '8px'
    }
});

const onLoad = polyline => {
    console.log('polyline: ', polyline)
};

/* 
 * The below will be SEED data for every project
 * these are the lat long along the Mumbai - Pune Expressway
 */

const pathRavetKalamboli = [
    { lat: 18.662469, lng: 73.727902 },
    { lat: 18.663607, lng: 73.711025 },
    { lat: 18.690592, lng: 73.68425 },
    { lat: 18.703814, lng: 73.675982 },
    { lat: 18.704139, lng: 73.667909 },
    { lat: 18.716089, lng: 73.637977 },
    { lat: 18.704627, lng: 73.593705 },
    { lat: 18.710805, lng: 73.565709 },
    { lat: 18.721536, lng: 73.550765 },
    { lat: 18.734542, lng: 73.533415 },
    { lat: 18.75031, lng: 73.523109 },
    { lat: 18.740882, lng: 73.470291 },
    { lat: 18.732916, lng: 73.445212 },
    { lat: 18.736655, lng: 73.429065 },
    { lat: 18.759575, lng: 73.428249 },
    { lat: 18.767864, lng: 73.390802 },
    { lat: 18.757503, lng: 73.377481 },
    { lat: 18.758234, lng: 73.372027 },
    { lat: 18.770546, lng: 73.364733 },
    { lat: 18.762338, lng: 73.353787 },
    { lat: 18.771603, lng: 73.360314 },
    { lat: 18.764573, lng: 73.35252 },
    { lat: 18.765711, lng: 73.351328 },
    { lat: 18.762643, lng: 73.347957 },
    { lat: 18.770749, lng: 73.343942 },
    { lat: 18.768108, lng: 73.339931 },
    { lat: 18.771928, lng: 73.328522 },
    { lat: 18.772253, lng: 73.323321 },
    { lat: 18.776966, lng: 73.319542 },
    { lat: 18.779729, lng: 73.315334 },
    { lat: 18.787124, lng: 73.309665 },
    { lat: 18.794924, lng: 73.289579 },
    { lat: 18.813368, lng: 73.276701 },
    { lat: 18.820923, lng: 73.266593 },
    { lat: 18.826935, lng: 73.262041 },
    { lat: 18.830672, lng: 73.253281 },
    { lat: 18.847569, lng: 73.230864 },
    { lat: 18.898491, lng: 73.202025 },
    { lat: 18.931294, lng: 73.160112 },
    { lat: 18.95021, lng: 73.15472 },
    { lat: 18.967175, lng: 73.159615 },
    { lat: 19.000046, lng: 73.130261 },
    { lat: 19.012178, lng: 73.117678 },
    { lat: 19.018102, lng: 73.106598 },
]

const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
}

class MapDash extends React.Component {

    constructor() {
        super();
        this.state = {
            selectEc: "SHOW ALL",      // Equipment Selection as per Menu
            anchorEl: null,       // point on the screen for menu
            clickedMarker: {},
            clickedEquipment: null,
            clickedModalOpen: false,
            clickedCategoryName: null,

            hoverMarker: {},
            showHoverWindow: false,
            hoverCategoryName: null,
            hoverEquipment: null,
            startMouseOverTime: null,  
            showEvent : true,
            map: null,
            hoverEvent: null,
            zoomVal: 10,
            // anchorEl: null,
            // selectEc: "SHOW ALL",
            showingInfoWindow: false,
            activeClickedMarker: {},
            addLat : 0.02,
            center: {
                lat: 18.652252,
                lng: 73.741905
            },
        };
    }

    componentDidMount() {
        // Get the equipments by Lat and Long
        this.props.getEquipments();
        // Get the equipments categories
        this.props.getEquipementCategories();
        // Get the events on map
        this.props.getEventsOnMap();

    }


    // onButtonClick this open the MenuList
    onButtonClick = (event) => {
        // console.log("on Button Click", event);
        this.setState({ anchorEl: event.currentTarget });
    }

    // handleClose this closes the MenuList. Sets the anchorE1 null
    handleClose = (event) => {
        this.setState({ anchorEl: null });
    }

    getEquipmentCategoryNameById = (equipment) => {
        let equipmentCatObj = this.props.equipmentCategories.find((equipmentCategories) => equipmentCategories.equipmentCategoryId == equipment.equipmentCategoryId);
        if (equipmentCatObj) {
            return equipmentCatObj.equipmentCategoryName;
        }
    }

    // Below function is called when MenuItem is clicked
    handleMenuItemClick = (equipmentCategory) => {
        this.setState({ anchorEl: null });
        this.hoverCloseClick()
        if (equipmentCategory instanceof Object) {
            const categoryName = this.getEquipmentCategoryNameById(equipmentCategory);
            console.log("handleMenuItemClick Clicked Category Name", categoryName);
            this.setState({ selectEc: categoryName });
            this.setState({ showEvent: false });
        }
        else if(equipmentCategory === "EVENTS"){
            console.log(this.props.eventsOnMap);
            this.setState({ selectEc: "ALL EVENTS" });
            this.setState({ showEvent: true });
        }
        else {
            this.setState({ selectEc: "SHOW ALL" });
            this.setState({ showEvent: true });
        }
    }




    onEquipmentMarkerClick = (props, marker, e, equipment) => {
        console.log("onEquipmentMarkerClick props", props);
        console.log("onEquipmentMarkerClick e", e);
        console.log("onEquipmentMarkerClick marker", marker);
        console.log("onEquipmentMarkerClick equipment", equipment);
        console.log("onEquipmentMarkerClick call");
        // Get the categoryname
        let categoryName = this.getEquipmentCategoryNameById(equipment);

        this.setState({
            clickedEquipment: equipment,
            clickedModalOpen: true,
            clickedCategoryName: categoryName,
            showHoverWindow: false
        });
        console.log("STATE", this.state)
    }

    onEquipmentModalClose = () => {
        this.setState({
            clickedModalOpen: false
        })
    }

    onEquipmentMarkerMouseover = (props, marker, e, equipment) => {
        console.log("onEquipmentMarkerMouseover call");
        let categoryName = this.getEquipmentCategoryNameById(equipment);
        this.setState({
            hoverEvent : null,
            plaza: null,
            // selectedPlace: props,
            hoverEquipment: equipment,
            hoverCategoryName: categoryName
        });
    }

    onEventMarkerMouseover = (props, marker, e, event) => {
        console.log("onEventMarkerMouseover call");
        this.setState({
            hoverEquipment: null,
            hoverCategoryName: null,
            hoverEvent: event,
            plaza: null,
        });
    }

    onPlazaHover= () =>{
        this.setState({
            hoverEquipment: null,
            hoverCategoryName: null,
            hoverEvent : null,
            plaza: 'Toll Booth'
        });
        console.log(this.state.plaza)
    }

    hoverCloseClick = () => {
        this.setState({
            // selectedPlace: props,
            hoverEquipment: null,
            hoverCategoryName: null,
            hoverEvent : null,
            plaza: null,
        });
    }


    // Shows the equipment markers
    showCategoryMarkers = (equipment) => {
        let url = null;
        let categoryName = this.getEquipmentCategoryNameById(equipment);
        if (equipment.isFaulty == 1) {
            url = pngRedLocation[categoryName]
        } else {
            url = pngGreenLocation[categoryName]
        }

        return (
            <Marker
                icon={{
                    url: url,
                    fillColor: "green",
                    fillOpacity: .6,
                    strokeWeight: 0,
                    scale: 3,
                }}
                onClick={(props, marker, e) => {
                    this.onEquipmentMarkerClick(props, marker, e, equipment)
                }}
                onMouseOver={
                    (props, marker, e) => {
                        this.onEquipmentMarkerMouseover(props, marker, e, equipment)
                    }
                }
                position={{ lat: parseFloat(equipment.latitude), lng: parseFloat(equipment.longitude) }}

            />
        );
    }

    showEventMarkets = (event) => {
        // console.log('event', event)
        return (
            <Marker icon={{
                    // url: 'http://cdn.onlinewebfonts.com/svg/img_489871.png',
                    fillColor: "green",
                    fillOpacity: .6,
                    strokeWeight: 0,
                    scale: 3,
                }}
                onClick={(props, marker, e) => {
                     this.props.history.push("/manageevent") 
                }}
                onMouseOver={
                    (props, marker, e) => {
                        this.onEventMarkerMouseover(props, marker, e, event)
                    }
                }
                position={{ lat: parseFloat(event.eventLatitude) , lng: parseFloat(event.eventLongitude) }}>
            </Marker>
        );
    }

    //  handleZoomChanged =() =>{
    //      if(this.state.map !== null){
    //          console.log(this.state.map.state.map.zoom)
    //          if(this.state.map.state.map.zoom >= 12){
    //             this.setState({
    //                 addLat : 0
    //             })
    //          }
            
    //      }
    //   }

    render() {
        const { classes } = this.props;
        console.log('State',this.state.addLat);
        // console.log('PROPS',this.props);
        // console.log('EVENTS', this.props.eventsOnMap )
        let modalEquipmentDiv = null;            // This is the Equipment Modal Div
        let infoWindow = null;

        if (this.state.clickedCategoryName) {
            if (this.state.clickedCategoryName === "ANPR_SPEED") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <ANPRDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></ANPRDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "ATCC") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <ATCCDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></ATCCDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "CCTV") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <CCTVDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></CCTVDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "ECB") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <ECBDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></ECBDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "MET") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <METDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></METDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "PTZ") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <PTZDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></PTZDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "VIDS") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <VIDSDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></VIDSDetails>
                </Modal>
            } else if (this.state.clickedCategoryName === "VIDS") {
                modalEquipmentDiv = <Modal open={this.state.clickedModalOpen} className={classes.modal} onClose={() => { this.setState({ modalOpen: false }) }} closeAfterTransition>
                    <VMSDetails selectedEquipment={this.state.clickedEquipment} onClose={this.onEquipmentModalClose}></VMSDetails>
                </Modal>
            }
        }
        
        

        if (this.state.hoverEquipment) {
            infoWindow = <InfoWindow
                position={{ lat: parseFloat(this.state.hoverEquipment.latitude)+ this.state.addLat, lng: parseFloat(this.state.hoverEquipment.longitude) }}
                onCloseClick={this.hoverCloseClick}
            >
                <div>
                    <Typography variant="h6" gutterBottom>
                        {this.state.hoverCategoryName}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Equipment Name: {this.state.hoverEquipment.equipmentName} <br />
                        Chainage Name : {this.state.hoverEquipment.chainageName}
                    </Typography>
                </div>
            </InfoWindow>
        } 
        if (this.state.hoverEvent){
            infoWindow = <InfoWindow
                position={{ lat: parseFloat(this.state.hoverEvent.eventLatitude)+ this.state.addLat, lng: parseFloat(this.state.hoverEvent.eventLongitude) }}
                onCloseClick={this.hoverCloseClick}
            >
                <div>
                    <Typography variant="h6" gutterBottom>
                        {this.state.hoverEvent.eventDescription}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                    Event Description: {(this.state.hoverEvent.eventDescription !== null) ? this.state.hoverEvent.eventDescription : '' } <br />
                    Event Severity : {(this.state.hoverEvent.eventSeverity !== null) ? this.state.hoverEvent.eventSeverity.severityName : '' }
                    </Typography>
                </div>
            </InfoWindow>
        }
        if(this.state.plaza){
            infoWindow = <InfoWindow
                position={{ lat: parseFloat("18.662469")+ 0.02 , lng: parseFloat("73.727902") }}
                onCloseClick={this.hoverCloseClick}
            >
                <div>
                    <Typography variant="h6" gutterBottom>
                        {this.state.plaza}
                    </Typography>
                </div>
            </InfoWindow>

        }


        return (
            <div className={classes.atmsPageOuterDiv}>
                <Card className={classes.atmsPageOuterCard}>
                    <Grid container spacing={3} direction="row" justify="space-between" alignItems="center" className={classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                                Events and Equipments on MAP
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button color="primary" variant="contained" onClick={this.onButtonClick}>
                                {this.state.selectEc}
                            </Button>
                            <Menu
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}>
                                {this.props.equipmentCategories.map((equipmentCategory) => {
                                    let CategoryIcon = logos[equipmentCategory.equipmentCategoryName];
                                    return (
                                        <MenuItem onClick={() => this.handleMenuItemClick(equipmentCategory)}>
                                            <ListItemIcon>
                                                <SvgIcon>
                                                    <CategoryIcon />
                                                </SvgIcon>
                                            </ListItemIcon>
                                            {equipmentCategory.equipmentCategoryName}
                                        </MenuItem>
                                    )
                                })}
                                <MenuItem onClick={() => this.handleMenuItemClick("EVENTS")}>
                                    <ListItemIcon>
                                        <SvgIcon>
                                            <RoomIcon />
                                        </SvgIcon>
                                    </ListItemIcon>
                                        EVENTS
                                    </MenuItem>
                                <MenuItem onClick={() => this.handleMenuItemClick("ALL")}>
                                    <ListItemIcon>
                                        <SvgIcon>
                                            <SELECTALLLogo />
                                        </SvgIcon>
                                    </ListItemIcon>
                                        SHOW ALL
                                    </MenuItem>

                                   
                                    
                                    
                            </Menu>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<DashboardIcon />}
                                onClick={() => { this.props.history.push("/landingPage") }}
                            >
                                GO TO DASHBOARD
                                        </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    <CardContent className={classes.mapDiv}>
                        <LoadScript googleMapsApiKey="AIzaSyBYR1JlNUR_rNKpRAnxz2HDEUp_mZ6aDNk">
                            <GoogleMap
                                mapContainerStyle={mapContainerStyleDiv}
                                center={center}
                                zoom={10}
                                onZoomChanged={(prevzoom) => {
                                    if(this.state.map !== null){
                                        console.log('zoom',this.state.map.state.map.zoom )
                                        if(this.state.map.state.map.zoom >= 12){
                                            if(this.state.zoomVal < this.state.map.state.map.zoom){
                                                console.log(true)
                                                this.setState({
                                                    addLat : parseFloat(this.state.addLat)/2,          
                                                })
                                            }
                                            else{
                                                console.log(false)
                                                this.setState({
                                                    addLat : parseFloat(this.state.addLat)*2,          
                                                })
                                            }
                                        }if(this.state.map.state.map.zoom < 12){
                                            console.log(this.state.map.state.map.zoom)
                                            this.setState({
                                                addLat : 0.02,          
                                            })
                                        }
                                        this.setState({zoomVal : this.state.map.state.map.zoom})
                                        // if(this.state.map.state.map.zoom >= 12){
                                        //     console.log('ZOOM', this.state.addLat)  
                                        //     console.log("REACHED", this.state.zoomVal)
                                        //     this.setState({
                                        //         addLat : parseFloat(this.state.addLat)/2,
                                                
                                        //     })
                                        //     console.log('ADDLAT', this.state.addLat)
                                        // }
                                    }
                                }}
                                ref={(ref) => { this.state.map = ref }}
                                // onLoad={this.onGoogleMapLoad}
                                options={{
                                    restriction: {
                                        latLngBounds: {
                                            north: 19.137384, // Mumbai
                                            south: 18.510866, // Pune
                                            west: 72.874748,  // Mumbai
                                            east: 73.879864,  // Pune
                                        },
                                        strictBounds: false,
                                    }
                                }}
                            >

                                <Marker
                                    icon={{
                                        url: svgLocation['CTRLCTR'],
                                        fillColor: "green",
                                        fillOpacity: .6,
                                        strokeWeight: 0,
                                        scale: 3,
                                    }}
                                    onMouseOver={
                                        (props, marker, e) => {
                                            this.onPlazaHover(props, marker, e)
                                        }
                                    }
                                    position={{ lat: parseFloat("18.662469"), lng: parseFloat("73.727902") }}
                                />

                                {this.props.equipments.map(equipment => {
                                    if (this.state.selectEc === "SHOW ALL") {
                                        
                                        return this.showCategoryMarkers(equipment);
                                    }
                                    else if(this.state.selectEc === "EVENT"){
                                        this.props.eventsOnMap.map((event) => {
                                            // console.log("ALL EVENT", event)
                                            if (event.eventLatitude && event.eventLongitude && this.state.showEvent === true) {
                                                return this.showEventMarkets(event);
                                            }
                                        })
                                    }
                                    else {
                                        let categoryName = this.getEquipmentCategoryNameById(equipment);
                                        if (categoryName === this.state.selectEc) {
                                            
                                            return this.showCategoryMarkers(equipment);
                                        }
                                    }
                                })
                                }

                                {infoWindow}
                                

                                {this.props.eventsOnMap.map((event) => {
                                    // console.log("ALL EVENT", event)
                                    if (event.eventLatitude && event.eventLongitude && this.state.showEvent === true) {
                                        return this.showEventMarkets(event);
                                    }
                                })}

                                <Polyline
                                    onLoad={onLoad}
                                    path={pathRavetKalamboli}
                                    options={options}
                                />
                            </GoogleMap>
                        </LoadScript>
                        {modalEquipmentDiv}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        equipments: state.mapsReducer.equipments,
        equipmentCategories: state.mapsReducer.equipmentCategories,
        eventsOnMap: state.mapsReducer.eventsOnMap
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEquipments: () => dispatch(actions.getEquipments()),
        getEquipementCategories: () => dispatch(actions.getEquipementCategories()),
        getEventsOnMap: () => dispatch(actions.getEventsOnMap()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapDash));