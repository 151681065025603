import axios from "axios";
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL;

const auditFailure = (errorMsgCrt) => {
    return {
        type : actionTypes.EQUIPMENTS_FAILURE,
        errorMsgCrt : errorMsgCrt
    };
}
export const getAuditDirSuccess = (arrDirection) => {
    return {
        type : actionTypes.GET_AUDIT_DIRECTION_SUCCESS,
        arrDirection : arrDirection,
    };
};
export const getAuditChainageSuccess = (arrChainage) => {
    return {
        type : actionTypes.GET_AUDIT_CHAINAGE_SUCCESS,
        arrChainage : arrChainage,
    };
};


export const getAuditEquipmentsSuccess = (arrEquipment) => {
    //console("Equip-Action: " + JSON.stringify(arrEquipment))
    return {
        type : actionTypes.GET_AUDIT_EQUIPMENT_SUCCESS,
        arrEquipment : arrEquipment,
    };
};

export const getAuditVehicleTypeSuccess = (arrVehicleType) => {
    return {
        type : actionTypes.GET_AUDIT_VEHTYPE_SUCCESS,
        arrVehicleType : arrVehicleType,
    };
};
//#region SUCCESS
const speedAuditSuccess = (successMsgCrt) => {
    console.log("successMsgCrt : "+ successMsgCrt);
    return {
        type : actionTypes.SPEED_AUDIT_CREATED,
        successMsgCrt : successMsgCrt
    };
}

//#endregion
//#region DIRECTION DROPDOWN IN SEARCH
export const getAuditDir = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/direction?reqType=all")
        .then(res => {    
            //console.log("ACTION-DIR: "+ JSON.stringify(res.data));
            dispatch(getAuditDirSuccess(res.data));
        }).catch(error => {
            console.log("ACTION ERROR :" + error.response.data.message);
            dispatch(auditFailure(error.response.data.message));
        });
    };
}
//#endregion

//#region DIRECTION DROPDOWN IN SEARCH
export const getAuditEquip = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/equipments?reqType=all")
        .then(res => {    
            dispatch(getAuditEquipmentsSuccess(res.data));
        }).catch(error => {
            console.log("ACTION ERROR :" + error.response.data.message);
            dispatch(auditFailure(error.response.data.message));
        });
    };
}
//#endregion

//#region DIRECTION DROPDOWN IN SEARCH
export const getAuditVehicleType = () => {
    return dispatch => {
        axios
        .get(BASE_URL + "/api/vehicleClasses?reqType=all")
        .then(res => {    
            dispatch(getAuditVehicleTypeSuccess(res.data));
        }).catch(error => {
            console.log("ACTION ERROR :" + error.response.data.message);
            dispatch(auditFailure(error.response.data.message));
        });
    };
}
//#endregion

//#region CHAINAGE DROPDOWN IN SEARCH
export const getAuditChainage = (dirId) => {
    console.log("dirId: "+dirId)
    return dispatch => {
        axios
        .get(BASE_URL + "/api/chainage?reqType=all&directionId="+ dirId)
        .then(res => {    
            console.log("ACTION :" + res.data);
            dispatch(getAuditChainageSuccess(res.data));
        }).catch(error => {
            console.log("ACTION ERROR :" + error.response.data.message);
            dispatch(auditFailure(error.response.data.message));
        });
    };
}
//#endregion

export const auditform = (formValues) => {
    // console.log(formValues);
    // console.log("vehClassId: "+formValues.auditedVehicleRegNumber + "vehNum :" +formValues.auditedVehicleClassId)
    return dispatch => {
        
            axios.patch(BASE_URL + "/api/vehicleAudit/"+ formValues.txnSpeedRawId,formValues)
            .then(res=>{
                console.log("SSS:" + JSON.stringify(res));
                dispatch(speedAuditSuccess("Vehicle Data Audited Successfully."));
            })
            .catch(error =>{
                console.log("WWW: " + error);
                dispatch(auditFailure("Vehicle Data Audition Failed."));
            })
};
}
export const auditStateReset = () => {
    return {
        type: actionTypes.SPEED_AUDIT_STATE_RESET
    }
}
