import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import {
    Button, Container, Card,
    CardHeader, Divider, CardContent, Grid, IconButton, Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import * as reduxFormFunctions from '../../../components/common/Redux_forms_Common';
import getRoute from '../../../utils/getRoute';
import checkEntitlement from "../../../utils/checkEntitlement";

//#region Styles
const styles = theme => ({
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    Button: {
        right: '10px'
    }
});
//#endregion


class UserRoleAddForm extends React.Component {
    //#region constructor
    constructor() {
        super();
        this.state = {
            parentUserRoleId: "",
            userRoleName: "",
            userRoleDescription: ""
        };
    }
    //#endregion


    componentDidMount() {
        this.props.fetchData(this.props.endpoint);
    }


    //#region On Change
    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    //#endregion

    onSubmit = (values) => {
        console.log("values: ", values);
        this.props.onSubmit(values);
    }

    //#region render()
    render() {
        const { classes, reset, pristine, invalid } = this.props;
        let buttons = null;
        let isEnabled = null;
        if (this.props.type === "Add") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" type="submit">ADD</Button>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine} onClick={reset}>RESET</Button>
            </React.Fragment>
            isEnabled = null;
        }

        if (this.props.type === "Edit") {
            buttons = <React.Fragment>
                <Button className={classes.button} color="primary" size="large" variant="contained" disabled={pristine || invalid} type="submit">SAVE</Button>
            </React.Fragment>
            isEnabled = <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Field name="isEnabled" props={{ disabled: !checkEntitlement(getRoute(), 'delete') }} component={reduxFormFunctions.radioIsEnabled} label="Enabled" />
                </Grid>
            </React.Fragment>
        }
        return (
            <Container maxWidth="md" className={classes.container}>
                <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <Card>
                        <CardHeader
                            subheader="Add User Role"
                            title="User Role"
                            action={
                                <IconButton onClick={() => { this.props.onClose() }}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field name="parentUserRoleId" component={reduxFormFunctions.renderSelectField} label="Parent Role" onChange={this.onChange} id="parentUserRoleId">
                                        <option value={""}>{''}</option>
                                        <option value={this.props.roles.userRoleId}>{this.props.roles.userRoleName}</option>
                                        {this.props.arrUserRolesProp.map((userRoleProp) =>
                                            <option key={userRoleProp.userRoleId} value={userRoleProp.userRoleId}>
                                                {userRoleProp.userRoleName}
                                            </option>)}
                                    </Field>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="userRoleName" id="userRoleName" component={reduxFormFunctions.renderTextField} label="Role Name" onChange={this.onChange} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field name="userRoleDescription" id="userRoleDescription" component={reduxFormFunctions.renderTextField} label="Role Description" onChange={this.onChange} />
                                </Grid>
                                {isEnabled}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}>
                            {buttons}
                        </Box>
                    </Card>
                </form>
            </Container>
        );
    }
    //#endregion
}

//#region Validations
const validate = formValues => {
    const error = {};
    // /^[a-zA-Z]([a-zA-Z]|\s)*$/g

    if (!formValues.userRoleName) {
        error.userRoleName = 'Enter Role Name';
    }
    if (/^[0-9 ]*$/g.test(formValues.userRoleName)) {
        error.userRoleName = 'Please enter valid role name'
    }

    // if (!/^[a-zA-Z]([a-zA-Z]|\s)*$/g.test(formValues.userRoleName)) {
    //     error.userRoleName = 'Please enter valid role name'
    // }
    if (/^[0-9 ]*$/g.test(formValues.userRoleDescription)) {
        error.userRoleDescription = 'Please enter valid role description'
    }

    if (!formValues.userRoleDescription) {
        error.userRoleDescription = 'Enter Role description';
    }

    if (formValues.userRoleDescription) {
        if (formValues.userRoleDescription.length > 201) {
            error.userRoleDescription = 'Maximum characters should be 200 only';
        }
    }

    if (!formValues.parentUserRoleId) {
        error.parentUserRoleId = 'Select Parent Role';
    }

    if (formValues.parentUserRoleId) {
        if (formValues.parentUserRoleId == "") {
            error.parentUserRoleId = 'Select Parent Role';
        }
    }


    return error;
};
//#endregion

//#region Map Functions
const mapStateToProps = state => {
    return {
        arrUserRolesProp: state.userRole.arrUserRoles,
        roles: state.auth.user.role

    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchData: (endpoint) => dispatch(actions.fetchUserRoleData(endpoint))
    };
};
//#endregion

UserRoleAddForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRoleAddForm);

export default reduxForm({
    validate: validate,
    form: 'UserRoleAddForm'
})(withStyles(styles)(UserRoleAddForm));