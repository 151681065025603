import { updateObject } from "../utility";
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  globalConfig: [],
  toasterOpen: false,
  toasterConfig: {},
  drawerOpen: false,
  entitlements:[]
};


const globalConfigSuccess = (state, action) => {
  return updateObject(state, {
    globalConfig: action.globalConfig
  });
};

const globalConfigFail = (state,) => {

};

const toasterON = (state, action) => {
  return updateObject(state, {
    toasterMessageConfig: action.toasterMessageConfig,
    toasterOpen: true
  });
};

const toasterOFF = (state, action) => {
  return updateObject(state, {
    toasterMessageConfig: {},
    toasterOpen: false
  });
};
const drawerOpen = (state, action) => {
  return updateObject(state, {
    drawerOpen: true
  });
};

const drawerClose = (state, action) => {
  return updateObject(state, {
    drawerOpen: false
  });
};

const getEntitlementsSuccess=(state,action)=>{
  return updateObject(state,{
    entitlements:action.entitlements
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_CONFIG_SUCCESS:
      return globalConfigSuccess(state, action);
    case actionTypes.GLOBAL_CONFIG_FAIL:
      return globalConfigFail(state, action);
    case actionTypes.TOASTER_ON:
      return toasterON(state, action);
    case actionTypes.TOASTER_OFF:
      return toasterOFF(state, action);
    case actionTypes.DRAWER_OPEN:
      return drawerOpen(state, action);
    case actionTypes.DRAWER_CLOSE:
      return drawerClose(state, action);
    case actionTypes.GET_ENTITLEMENTS_SUCCESS:
      return getEntitlementsSuccess(state,action);
    default:
      return state;
  }
};

export default reducer;