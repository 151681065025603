import axios from 'axios';
import * as actionTypes from '../actionTypes';
import appConfig from '../../../appConfig.json';
import { showToaster } from '../common';

const BASE_URL = appConfig.BASE_URL;

export const shiftCreateStart = () => {
    return {
        type: actionTypes.SHIFT_CREATE_START
    };
};

export const shiftCreate = (formValues) => {
    return async (dispatch) => {
        try{
            dispatch(shiftCreateStart());
            const response = await axios.post(BASE_URL + "/api/shifts", formValues);
            console.log("Created response",response);
            dispatch(showToaster('success','Shift Successfully Created.'));
        } catch (error) {
            console.log("error",error);
            console.log("error message",error.message);
            dispatch(showToaster('error',error.response.data.message))
        }
    };
};

export const shiftStateReset = () => {
    return {
        type: actionTypes.SHIFT_STATE_RESET
    };
};

export const shiftEdit = (formValues) => {
    console.log("Edit: "+ JSON.stringify(formValues));
    let finalIsEnabled = 1;
    if(formValues.isEnabled === true || formValues.isEnabled === 1){        
        finalIsEnabled= 1;       
    }  
    else if (formValues.isEnabled === false || formValues.isEnabled === 0) {
        finalIsEnabled= 0;        
    }
    let values = {
        shiftName: formValues.shiftName,
        shiftDescription: formValues.shiftDescription,
        shiftStartTime: formValues.shiftStartTime,
        shiftEndTime: formValues.shiftEndTime,
        isEnabled: finalIsEnabled
    }
    return async (dispatch) => {
        try {
            dispatch(shiftCreateStart());
            const response = await axios.patch(BASE_URL + "/api/shifts/" + formValues.shiftId,values);
            console.log(response);
             dispatch(showToaster('success','Shift Successfully Edited.'));

        }catch(error) {
            console.log(error)
            dispatch(showToaster('error',error.response.data.message))
        }
    };
}
