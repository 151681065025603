import axios from "axios";
import * as actionTypes from '../actionTypes';
import {showToaster} from '../common';
import {fetchEventDetails} from './events';
import appConfig from '../../../appConfig.json';

const BASE_URL = appConfig.BASE_URL; 

export const loadingStart = () => {
  return {
      type : actionTypes.LOADING_START
  };
};

export const getNotificationSourceSuccess = (notificationSource) => {
  return {
    type: actionTypes.GET_NOTIFICATION_SOURCE_SUCCESS,
    notificationSource: notificationSource,
  };
};

export const getEventGeneralDetailsFail = (errorMsg) => {
  return {
    type: actionTypes.GET_EVENT_GENERAL_DETAILS_FAIL,
    errorMsg: errorMsg,
  };
};

export const getNotificationSource = () => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/equipmentTypes?reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getNotificationSourceSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const getEventStatusSuccess = (eventStatus) => {
  return {
    type: actionTypes.GET_EVENT_STATUS_SUCCESS,
    eventStatus: eventStatus,
  };
};

export const getEventStatus = () => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/eventStatus?reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getEventStatusSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}


export const getEventTypeSuccess = (eventType) => {
  return {
    type: actionTypes.GET_EVENT_TYPE_SUCCESS,
    eventType: eventType,
  };
};

export const getEventType = () => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/eventTypes?reqType=all")
      .then(res => {
        console.log("Event Type:", res.data);
        dispatch(getEventTypeSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const getEventSeveritySuccess = (eventSeverity) => {
  return {
    type: actionTypes.GET_EVENT_SEVERITY_SUCCESS,
    eventSeverity: eventSeverity,
  };
};

export const getEventSeverity = () => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/eventSeverity?reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getEventSeveritySuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const getEquipmentsSuccess = (equipments) => {
  return {
    type: actionTypes.GET_EQUIPMENTS_SUCCESS,
    equipments: equipments,
  };
};

export const getEquipment = (categoryId) => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/equipments?equipmentTypeId=" + categoryId + "&reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getEquipmentsSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const getEventSubTypeSuccess = (eventSubType) => {
  return {
    type: actionTypes.GET_EVENTSUBTYPE_SUCESS,
    eventSubType: eventSubType,
  };
};

export const getEventSubType = (eventTypeId) => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/eventSubtypes?eventTypeId=" + eventTypeId + "&reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getEventSubTypeSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}


export const getEventReasonsSuccess = (eventReason) => {
  return {
    type: actionTypes.GET_EVENTREASON_SUCCESS,
    eventReason: eventReason,
  };
};

export const getEventReason = (eventSubtypeId) => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/eventReasons?eventSubtypeId=" + eventSubtypeId + "&reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getEventReasonsSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const getStakeholderUserSuccess = (stakeholderUsers) => {
  return {
    type: actionTypes.GET_STAKEHOLDERUSER_SUCCESS,
    stakeholderUsers: stakeholderUsers,
  };
};

export const getStakeholderUser = () => {
  return dispatch => {
    dispatch(loadingStart());
    axios
      .get(BASE_URL + "/api/stakeholdersCategories?reqType=all")
      .then(res => {
        console.log(res.data);
        dispatch(getStakeholderUserSuccess(res.data));
      }).catch(error => {
        dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
  };
}

export const editEventGeneralDts = (genDtsVals) => {
  return dispatch => {
    if(genDtsVals.stakeHolder == ''){
      dispatch(showToaster('error', 'Please Select Stakeholders'));
    }else{
      axios
      .patch(BASE_URL + "/api/events/" + genDtsVals.txnEventId, genDtsVals)
      .then(res => {
        console.log("editEventGeneralDts", res.data);
        // dispatch(getStakeholderUserSuccess(res.data));
        dispatch(showToaster('success', 'Event Details saved successfully!'));
        dispatch(fetchEventDetails(genDtsVals.txnEventId));
      }).catch(error => {
        console.log(error);
        dispatch(showToaster('error', 'Error saving event details'));

        // dispatch(getEventGeneralDetailsFail(error.response.data.message));
      });
    }
    
  };

}