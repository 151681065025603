import { updateObject } from "../../utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    arrUserRoles: [],
    strAddUserRole: '',
    loading: ''
};

const userRoleDataSuccess = (state, action) => {
    var updatedState = updateObject(state,
        {
            arrUserRoles: action.userRoles
        });
    return updatedState;
};

const userRoleAddSuccess = (state, action) => {
    var updatedState = updateObject(state,
        {
            strAddUserRole: action.strAddUserRole
        });
    return updatedState;
};

const userRoleAddFailed = (state, action) => {
    var updatedState = updateObject(state,
        {
            strAddUserRole: action.strAddUserRole
        });
    return updatedState;
};
//#endregion

const userRoleStateReset = (state, action) => {
    return updateObject(state, { loading: null });
};

const userRoleCreateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_ROLE_SUCCESS:
            return userRoleDataSuccess(state, action);
        
        case actionTypes.USER_ROLE_ADD_SUCCESS:
            return userRoleAddSuccess(state, action);

        case actionTypes.USER_ROLE_ADD_FAIL:
            return userRoleAddFailed(state, action);
        
        case actionTypes.USER_ROLE_STATE_RESET:
            return userRoleStateReset(state, action);
        case actionTypes.USER_ROLE_CREATE_START:
            return userRoleCreateStart(state, action);
        
        default:
            return state;
    }
};

export default reducer;