import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Select, TextField, Button, FormControl, InputLabel, MenuItem, Grid, Input, Checkbox, ListItemText } from '@material-ui/core';
import * as actions from '../../../store/actions/index';
import axios from 'axios';
import appConfig from '../../../appConfig.json';
import _ from 'lodash';
import { DateTimePicker } from '@material-ui/pickers';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { de } from 'date-fns/locale';

const BASE_URL = appConfig.BASE_URL;

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: '#f4f6f8',
    },
    addButton: {
        float: 'right'
    },
    header: {
        display: 'inline'
    },
    list: {
        marginTop: theme.spacing(3)
    },
    modal: {
        'overflow-y': 'auto',
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    sheader: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    selectFormControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    buttonDiv : {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
    },
    buttonDiv1 : {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
    }
});

class ViolationFilter extends React.Component {

    constructor() {
        super();
        let date = new Date();
        // let fromDate = new Date();
        let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        console.log("defaultToDate", defaultToDate)
        this.state = {
            fromDate: defaultFromDate,          // 1. From Time Violation 
            toDate: defaultToDate,            // 2. To Time Violation
            vehicleNumber: null,    // 3. Vehicle number 
            vehicleClassCode: [],   // 4. Vehicle Class Code
            vehicleDirectionId: [], // 5. Vehilcle Direction ID
            chainageId: [],         // 6. Chainage Name 
            anprEquipments: [],     // 7. Equipment Name 
            sectionId: "",          // 8. Section Id
            speedRange: "",         // 9. Speed Range
            equipmentId: [],        // 10.Equipment Id
            speedChallanStatusId: "", // 11. Violation Status Id
            reqType:"",             // 12. Violation Type Id
            errors: {
                fromDate: "",       // error fromDate
                toDate: "",         // error toDate
                vehicleNumber: "",  // error vehicleNumber
            },
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        let anprCatId = null;
        let response;

        if (_.isEqual(this.state.vehicleDirectionId, prevState.vehicleDirectionId) == false) {
            this.props.getChainages(this.state.vehicleDirectionId);
        }

        if (_.isEqual(this.state.chainageId, prevState.chainageId) == false) {

            try {

                /* 
                * Get the equiment categories
                */

                response = await axios.get(BASE_URL + "/api/equipmentCategories?reqType=all");
                const anprCat = response.data.find(equipment => equipment.equipmentCategoryName === "ANPR_SPEED")

                if (typeof anprCat !== 'null') {
                    let anprCatId = anprCat.equipmentCategoryId

                    /* 
                    * We get the equipments associated with the categoryID(ANPR). ChainageID and Vehicle Directions are array
                    */
                    response = await axios.get(BASE_URL + "/api/equipments?reqType=all&equipmentCategoryId=" +
                        anprCatId + "&chainageId=" + this.state.chainageId.join(',') + "&directionId=" + this.state.vehicleDirectionId.join(','));
                    console.log("ANPREQUIPMENTS", response.data)
                    this.setState({ anprEquipments: response.data })
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    getJoinedChainageNames(idArray, fieldName) {
        let result = [];

        if (fieldName == "Chainage") {
            this.props.chainages.forEach((chainage) => {
                if (idArray.indexOf(chainage.chainageId) > -1) {
                    result.push(chainage.chainageName);
                }
            });
        }

        return result.join(', ');
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ 'chainageId': value });
    }

    handleMultiSelect(e) {
        const name = e.target.name;
        const value = e.target.value;
        console.log("name is ", name);
        let stateObj = {};
        stateObj[name] = value;
        this.setState(stateObj);
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("handleInputChange", name, value);

        this.setState({
            [name]: value
        });
    }

    getJoinedNames(idArray, fieldName) {
        let result = [];

        if (fieldName == "Chainage") {
            this.props.chainages.forEach((chainage) => {
                if (idArray.indexOf(chainage.chainageId) > -1) {
                    result.push(chainage.chainageName);
                }
            });
        }

        if (fieldName == "Equipment") {
            this.state.anprEquipments.forEach((equipment) => {
                if (idArray.indexOf(equipment.equipmentId) > -1) {
                    result.push(equipment.equipmentName);
                }
            });
        }

        if (fieldName == "VehicleClass") {
            this.props.vehicleClasses.forEach((vehicleClass) => {
                if (idArray.indexOf(vehicleClass.vehicleClassCode) > -1) {
                    result.push(vehicleClass.vehicleClassName);
                }
            });
        }

        if (fieldName == "VehicleDirection") {
            this.props.directions.forEach((direction) => {
                if (idArray.indexOf(direction.directionId) > -1) {
                    result.push(direction.directionName);
                }
            });
        }

        return result.join(', ');
    }

    validate = () => {
        let { errors } = this.state;
        let dateRange;
        const vNumRegEx = /^[a-zA-Z0-9-\s]*$/;
        
        if (this.state.vehicleNumber) {

            if (vNumRegEx.test(this.state.vehicleNumber)) {
                errors.vehicleNumber = '';
                this.setState({ errors: errors });
                return true;
            } else {
                errors.vehicleNumber = 'Only Alphabet, Numbers, Dash(-) and Spaces.';
                this.setState({ errors: errors })
                return false;
            }
        }
        
        if (this.state.fromDate || this.state.toDate) {

            if (this.state.fromDate && !this.state.toDate) {
                // console.log("Only fromDate");
                errors.toDate = 'Please specify To Date.';
                this.setState({ errors: errors });
                return false;
            } else if (this.state.toDate && !this.state.fromDate) {
                // console.log("Only toDate");
                errors.fromDate = 'Please specify From Date.';
                this.setState({ errors: errors });
                return false;
            } else {
                // console.log("toDate and fromDate specified");
                
                errors.toDate = '';
                errors.fromDate = '';
                
                let fDate = new Date(this.state.fromDate);
                let tDate = new Date(this.state.toDate);

                if (this.props.dateFilterRange) {
                    dateRange = this.props.dateFilterRange.configValue1;
                }

                // To calculate the time difference of two dates 
                var diffInTime = tDate.getTime() - fDate.getTime();

                // To calculate the no. of days between two dates 
                var diffInDays = diffInTime / (1000 * 3600 * 24);
                // console.log("Difference_In_Days", diffInDays);
                // console.log("dateRange", dateRange);

                if (tDate > fDate) {
                    if (diffInDays < dateRange) {
                        this.setState({ errors: errors });
                        return true;
                    } else {
                        errors.fromDate = 'Number of days between From Date and To Date should not exceed ' + dateRange;
                        this.setState({ errors: errors });
                        return false;
                    }
                } else {
                    errors.toDate = 'To date should be the greater than From Date';
                    this.setState({ errors: errors });
                    return false;
                }
            }
        }

        return true;
    }
    /* 
     * Lifting State Up
     * We construct the urlparams in the function and pass it on to parent
     * for DataDrid to show
     */

    onSearchSubmit = (event) => {
        event.preventDefault();

        console.log("onSearchSubmit = AudVioCmnFilter", typeof this.state.fromDate);
        const urlParams = [];

        if (!this.validate()) {
            console.log("onSearchSubmit vehicleNumber validate", this.state.vehicleNumber);

            return false;
        }

        if (this.state.fromDate) {
            urlParams.push({ 'key': 'fromDate', value: this.state.fromDate });
        }

        if (this.state.toDate) {
            urlParams.push({ 'key': 'toDate', value: this.state.toDate });
        }

        if (this.state.vehicleNumber) {
            urlParams.push({ 'key': 'vehicleNumber', value: this.state.vehicleNumber });
        }

        if (this.state.vehicleClassCode.length > 0) {
            urlParams.push({ 'key': 'vehicleClassCode', value: this.state.vehicleClassCode });
        }

        if (this.state.vehicleDirectionId.length > 0) {
            urlParams.push({ 'key': 'vehicleDirectionId', value: this.state.vehicleDirectionId });
        }

        if (this.state.chainageId.length > 0) {
            urlParams.push({ 'key': 'fromChainageId', value: this.state.chainageId });
        }

        if (this.state.equipmentId.length > 0) {
            urlParams.push({ 'key': 'fromEqpId', value: this.state.equipmentId });
        }

        if (this.state.speedRange) {
            urlParams.push({ 'key': 'speedRange', value: this.state.speedRange });
        }

        if (this.state.speedChallanStatusId) {
            urlParams.push({ 'key': 'speedChallanStatusId', value: this.state.speedChallanStatusId });
        }

        if (this.state.sectionId) {
            urlParams.push({ 'key': 'sectionId', value: this.state.sectionId });
        }
        if (this.state.reqType) {
            urlParams.push({ 'key': 'reqType', value: this.state.reqType });
        }

        this.props.onSubmit(urlParams);
    }

    /* 
     * Reset the Violation Filter
     */

    resetViolationFilter = () => {
        let date = new Date();
        let defaultToDate = format(date, "yyyy-MM-dd'T'HH:mm");
        let defaultFromDate = format(date, "yyyy-MM-dd'T'00:00")
        this.setState({
            fromDate: defaultFromDate,
            toDate: defaultToDate,
            vehicleNumber: "",
            vehicleClassCode: [],
            vehicleDirectionId: [],    // Vehilcle Direction ID  
            chainageId: [],
            anprEquipments: [],
            sectionId: "",
            speedRange: "",
            equipmentId: [],
            speedChallanStatusId: "",
            reqType: "",
            errors: {
                fromDate: "",
                toDate: "",
                vehicleNumber: "",
            },
        })
    }

    handleDateTimeChange = (dateTime, objKey) => {

        console.log("handleDateTimeChange dateTime", format(dateTime, "yyyy-MM-dd'T'HH:mm"));
        let stateObj = {};
        stateObj[objKey] = format(dateTime, "yyyy-MM-dd'T'HH:mm");
        this.setState(stateObj);

    }

    render() {
        const { classes } = this.props;
        const { errors } = this.state;

        // console.log("violation type config value", this.props.violationType.configValue1);
        // console.log("state in render", this.state);

        return (
            <React.Fragment>
                <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>

                    <Grid container spacing={3}>

                        {/* 1. From Time Violation */}
                        <Grid item xs={2} >
                            <DateTimePicker
                                id="fromDate"
                                name="fromDate"
                                label="From"
                                format="yyyy/MM/dd HH:mm"
                                fullWidth
                                disableFuture={true}
                                ampm={false}
                                value={this.state.fromDate}
                                onChange={(val) => this.handleDateTimeChange(val, "fromDate")}
                                inputVariant="outlined"
                                allowKeyboardControl={false}
                            />
                            {errors.fromDate.length > 0 &&
                                <span>{errors.fromDate}</span>}
                        </Grid>

                        {/* 2. To Time Violation */}
                        <Grid item xs={2} >

                            <DateTimePicker
                                id="toDate"
                                name="toDate"
                                label="To"
                                format="yyyy/MM/dd HH:mm"
                                fullWidth
                                disableFuture={true}
                                ampm={false}
                                value={this.state.toDate}
                                onChange={(val) => this.handleDateTimeChange(val, "toDate")}
                                inputVariant="outlined"
                                allowKeyboardControl={false}
                            />
                            {errors.toDate.length > 0 &&
                                <span>{errors.toDate}</span>}
                        </Grid>

                        {/* 3. Vehicle number dropdown */}
                        <Grid item xs={2}>
                            <TextField
                                name="vehicleNumber"
                                id="vehicleNumber"
                                label="Vehicle Number"
                                fullWidth
                                value={this.state.vehicleNumber}
                                onChange={this.handleInputChange}
                                variant="outlined"
                            />
                            {errors.vehicleNumber.length > 0 &&
                                <span>{errors.vehicleNumber}</span>}
                        </Grid>

                        {/* 4. Vehicle Class Dropdown */}
                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel>Vehicle Class</InputLabel>
                                <Select
                                    name="vehicleClassCode"
                                    multiple
                                    value={this.state.vehicleClassCode}
                                    // input={<Input />}
                                    onChange={(e) => this.handleMultiSelect(e)}
                                    label="Vehicle Class"
                                    variant="outlined"
                                    renderValue={(selected) => this.getJoinedNames(selected, "VehicleClass")}
                                >
                                    {this.props.vehicleClasses && this.props.vehicleClasses.map((vehicleClass) => {
                                        return (
                                            <MenuItem key={vehicleClass.vehicleClassCode} value={vehicleClass.vehicleClassCode}>
                                                <Checkbox checked={this.state.vehicleClassCode.indexOf(vehicleClass.vehicleClassCode) > -1} />
                                                <ListItemText primary={vehicleClass.vehicleClassName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* 5. Direction Name Dropdown */}
                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel>Direction</InputLabel>
                                <Select
                                    name="vehicleDirectionId"
                                    multiple
                                    value={this.state.vehicleDirectionId}
                                    // input={<Input />}
                                    onChange={(e) => this.handleMultiSelect(e)}
                                    label="Vehicle Direction"
                                    variant="outlined"
                                    renderValue={(selected) => this.getJoinedNames(selected, "VehicleDirection")}
                                >
                                    {this.props.directions && this.props.directions.map((direction) => {
                                        return (
                                            <MenuItem key={direction.directionId} value={direction.directionId}>
                                                <Checkbox checked={this.state.vehicleDirectionId.indexOf(direction.directionId) > -1} />
                                                <ListItemText primary={direction.directionName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* 6. Chainage Name Dropdown */}
                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel>Chainage</InputLabel>
                                <Select
                                    name="chainageId"
                                    multiple
                                    value={this.state.chainageId}
                                    // input={<Input />}
                                    onChange={(e) => this.handleChange(e)}
                                    variant="outlined"
                                    renderValue={(selected) => this.getJoinedChainageNames(selected, "Chainage")}
                                >
                                    {this.props.chainages && this.props.chainages.map((chainage) => {
                                        return (
                                            <MenuItem key={chainage.chainageId} value={chainage.chainageId}>
                                                <Checkbox checked={this.state.chainageId.indexOf(chainage.chainageId) > -1} />
                                                <ListItemText primary={chainage.chainageName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* 7. Equipment Name Dropdown */}

                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel>Equipment</InputLabel>
                                <Select
                                    id="equipmentId"
                                    label="Equipment Name"
                                    name="equipmentId"
                                    multiple
                                    value={this.state.equipmentId}
                                    // input={<Input />}
                                    onChange={(e) => this.handleMultiSelect(e)}
                                    variant="outlined"
                                    renderValue={(selected) => this.getJoinedNames(selected, "Equipment")}
                                >
                                    {this.state.anprEquipments && this.state.anprEquipments.map((equipment) => {
                                        return (
                                            <MenuItem key={equipment.equipmentId} value={equipment.equipmentId}>
                                                <Checkbox checked={this.state.equipmentId.indexOf(equipment.equipmentId) > -1} />
                                                <ListItemText primary={equipment.equipmentName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* 8. Section Name Dropdown */}
                        {this.props.violationType && (this.props.violationType.configValue1 == 1 ||
                            this.props.violationType.configValue1 == 2) &&
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel>Section Name</InputLabel>
                                    <Select
                                        id="sectionId"
                                        name="sectionId"
                                        value={this.state.sectionId}
                                        onChange={this.handleInputChange}
                                        label="Section Name"
                                        variant="outlined"
                                    >
                                        {this.props.sections && this.props.sections.map((section) => {
                                            return (
                                                <MenuItem value={section.sectionId}>{section.sectionName}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}

                        {/* 9. Vehicle Speed Dropdown */}
                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                <InputLabel>Vehicle Speed</InputLabel>
                                <Select
                                    id="speedRange"
                                    name="speedRange"
                                    value={this.state.speedRange}
                                    onChange={this.handleInputChange}
                                    label="Vehicle Speed Range"
                                    variant="outlined"
                                >
                                    <MenuItem value={"0-60"}>Below 60</MenuItem>
                                    <MenuItem value={"60-75"}>60-75</MenuItem>
                                    <MenuItem value={"75-90"}>75-90</MenuItem>
                                    <MenuItem value={"90-100"}>90-100</MenuItem>
                                    <MenuItem value={"100-105"}>100-105</MenuItem>
                                    <MenuItem value={"105-300"}>Above 105</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        {this.props.violationType && (
                            this.props.violationType.configValue1 == 2) &&
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel>Violation Type</InputLabel>
                                    <Select
                                        id="reqType"
                                        name="reqType"
                                        value={this.state.reqType}
                                        onChange={this.handleInputChange}
                                        label="Violation Type"
                                        variant="outlined"
                                    >
                                        {/* {this.props.sections && this.props.sections.map((section) => {
                                            return (
                                                <MenuItem value={section.sectionId}>{section.sectionName}</MenuItem>
                                            );
                                        })} */}
                                        <MenuItem value={"ALL"}>ALL</MenuItem>
                                        <MenuItem value={"0"}>Spot Speed</MenuItem>
                                        <MenuItem value={"1"}>Section Speed</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>}

                        {/* 10. Violation Status */}
                        {this.props.challanFilter == true &&
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth className={classes.selectFormControl}>
                                    <InputLabel shrink="true">Challan Status</InputLabel>
                                    <Select
                                        id="speedChallanStatusId"
                                        name="speedChallanStatusId"
                                        value={this.state.speedChallanStatusId ? this.state.speedChallanStatusId : this.props.acceptViolStatusId}
                                        onChange={this.handleInputChange}
                                        label="Challan Status"
                                        variant="outlined"
                                    >
                                        {this.props.violationStatus && this.props.violationStatus.map((vStatus) => {
                                            return (
                                                <MenuItem value={vStatus.speedViolationStatusId}>{vStatus.speedViolationStatusName}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {/* 11. Reset Button */}
                        <Grid item  className={classes.buttonDiv}>
                            <Button variant="contained"color='secondary' onClick={this.resetViolationFilter}>
                                Reset
                            </Button>
                        </Grid>

                        {/* 12. Search Button */}
                        <Grid item  className={classes.buttonDiv1}>
                            <Button variant="contained" color="primary" type="submit">
                                Search
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        directions: state.audVioCmnReducer.directions,
        chainages: state.audVioCmnReducer.chainages,
        vehicleClasses: state.audVioCmnReducer.vehicleClasses,
        sections: state.audVioCmnReducer.sections,
        violationStatus: state.audVioCmnReducer.violationStatus,
        violationType: state.common.globalConfig.violation_type,
        dateFilterRange: state.common.globalConfig.date_filter_range
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getChainages: (dirId) => dispatch(actions.getChainages(dirId)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViolationFilter));
