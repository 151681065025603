import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  Paper, Grid,  Card, CardHeader, Divider,  Button, Typography, 
          Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
          TextField, Modal
        } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import axios from 'axios';
import ChallanView from '../ChallanView';
import commonStyles from '../../../../commonStyles';
import Toaster from '../../../../components/common/Toaster';
import appConfig from '../../../../appConfig.json';
const BASE_URL = appConfig.BASE_URL;

// violation comment
const styles = theme => ({
    ...commonStyles(theme),
    sectionHeaders : {
        marginLeft : theme.spacing(2)
    },
    header: {
        display: 'inline'
    },
    modal: {
        'overflow-y': 'auto',
        display: 'flex',
        marginTop: theme.spacing(1),
        outline: 'none',
        padding: '0px',
    },
    rvtbl: {
        margin : '0px 16px'
    },
    butDiv : {
        'min-height': '10vh',
        marginTop : '10px',
    },
    relatedViolaton : {
        'min-height': '80vh',
    },
    cdetcont : {
        'padding': '8px 16px'
    }
});

class ViolationRelated extends React.Component {
    constructor() {
        super();
        this.state = {
            relatedChallan : null,
            violationDetails : null,
            rejectRemarks : "",
            openRejectDialog : false,
            openToast: false,
            msgToast: "",
            toastSeverity: "success",
            rejectionSpeedChallanId : null,
            challanHtmlOpen : false,
            challanContent : null,
            challanViewFrom : "",
            errors: {
                rejectRemarks : "",             // error rejectRemarks
            },
        };
    }
    
    componentDidMount() {
        // console.log("ViolationRelated componentDidMount", this.props.location.state);
        /* 
         * Set the related challan in the state
         */
        this.setState({ relatedChallan : this.props.location.state.rc, 
                        violationDetails : this.props.location.state.violationDetails });
    }

    loadRelatedChallan = async () => {
        console.log("violationDetails challanId", this.state.violationDetails.challanId);
        let response = await axios.get(BASE_URL + "/api/speedChallan/" + this.state.violationDetails.challanId + "/related");
        this.setState({relatedChallan : response.data.data });
    }

    // called on pressing Reject button.
    onRejectButtonClick = async (rejectionSpeedChallanId) => {
        console.log("rejectionSpeedChallanId", rejectionSpeedChallanId);
        this.setState({ openRejectDialog: true, rejectionSpeedChallanId : rejectionSpeedChallanId });
    }

    // called on cancelling the Reject button.
    onRejectCancel = () => {
        this.setState({ openRejectDialog: false, rejectionSpeedChallanId : null });
    }

    
    onRejectSubmit = async () => {
        const { errors } = this.state;

        /* View the challan for the speed violation */
        if (this.state.rejectRemarks.length === 0) {
            errors.rejectRemarks = 'Please enter rejection remarks';
            this.setState({ errors: errors })
            return false;
        } 
        errors.rejectRemarks = '';
        this.setState({ errors: errors });

        try {
            let response = await axios.patch(BASE_URL + "/api/speedChallan/" +
                this.state.rejectionSpeedChallanId,
                {
                    "challanStatus": "REJECTED CHALLAN",
                    "challanRejectionRemark": this.state.rejectRemarks,
                });
            // console.log("response of reject speed challan", response);
            this.setState({  
                            openToast: true, 
                            msgToast: "Rejected Challan Vehicle Number : ", 
                            toastSeverity: "success" 
                        });
            this.setState({ openRejectDialog: false, rejectionSpeedChallanId : null });
            // Load the related challan again
            this.loadRelatedChallan();
        } catch (error) {
            console.log("this is the error msg");
            this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
        }
        
    }

    generateChallan = async () => {
        let response;

        /* Generate the challan for the speed violation */

        try {
            let genParams = {};

            genParams.vehicleMake = this.state.violationDetails.vehicleMake;
            genParams.vehicleModel = this.state.violationDetails.vehicleModel;
            genParams.vehicleColor = this.state.violationDetails.vehicleColor;
            genParams.ownerName = this.state.violationDetails.ownerName;
            genParams.ownerMobileNumber = this.state.violationDetails.ownerMobileNumber;
            genParams.ownerAtpRegistrationId = this.state.violationDetails.ownerAtpRegistrationId;
            genParams.ownerAddress = this.state.violationDetails.ownerAddress;

            response = await axios.patch(BASE_URL + "/api/speedChallan/" + this.state.violationDetails.challanId + "/generate", genParams);
            console.log("response of generated speed challan", response);
            console.log("violation details", this.state.violationDetails);
                                                // close the backdrop for related challan modal to open
            this.setState({ challanHtmlOpen: true, challanViewFrom : "GENERATECHALLAN", challanContent : response.data.challanContent });
        } catch (error) {
            console.log("this is the error msg");
            this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
        }
    }

    duplicateChallan = async (vehicleNumber) => {
        let response;

        let generatedChallanSameSection = this.state.relatedChallan.generatedChallanSameSection;
        if (generatedChallanSameSection.length === 0) {
            
            this.setState({ openToast: true, 
                msgToast: "Failed to Duplicate Vehicle Number " + vehicleNumber + " as there are no Related Challan in same section", 
                toastSeverity: "error" });
            return;
        }
        
        /* Duplicate the challan for speed violation 
         * violationID is txnSpeedChallanId which will be marked as duplicate against the first Generated challan in the same section & same direction.  
         * If there is no challan which is Generated in the same direction and section in the given time window,the duplication will fail.
         */

        try {
            response = await axios.patch(BASE_URL + "/api/speedChallan/" + this.state.violationDetails.challanId + "/duplicate");
            console.log("response of duplicate", response);
            this.setState({ openToast: true, 
                msgToast: "Selected Violation for Vehicle Number : " + vehicleNumber + " is marked as DUPLCIATE", 
                toastSeverity: "success" });
        } catch {
            this.setState({ openToast: true, 
                msgToast: "Selected Violation for Vehicle Number : " + vehicleNumber + " cannot be marked as DUPLICATE", 
                toastSeverity: "error" });
        }
    }

    viewChallan = async (txnSpeedChallanId) => {
        let response;

        /* View the challan for the speed violation */

        try {
            response = await axios.get(BASE_URL + "/api/speedChallan/" + txnSpeedChallanId + "/viewChallan");
            console.log("response of generated speed challan", response);
            this.setState({ challanHtmlOpen: true, challanViewFrom : "VIEWCHALLAN", challanContent: response.data.challanContent });
        } catch (error) {
            console.log("this is the error msg");
            this.setState({ openToast: true, msgToast: error.response.data.message, toastSeverity: "error" });
        }
    }
    
    challanViewOnClose = () => {
        this.setState({ challanHtmlOpen: false });    
        if (this.state.challanViewFrom === "GENERATECHALLAN") {
            this.props.history.push({
                pathname: "/violationchallan"
            });          
        }
        this.loadRelatedChallan();
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    showRelatedViolationTbl = (relChallanSameSect, duplicate, reject, viewc) => {
        const { classes } = this.props;
        console.log("relChallanSameSect",  this.state.relatedChallan.generatedChallanSameSection.length);
        return (
            <div className={classes.rvtbl}>
                {/* <Typography variant="h5">{sectionName}</Typography> */}
                <Grid container>
                    <TableContainer component={Paper}>
                        <Table size="small" padding="none">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Vehicle Number</TableCell>
                                    <TableCell>Vehicle Section Speed</TableCell>
                                    <TableCell>Section Name</TableCell>
                                    <TableCell>Start Chainage</TableCell>
                                    <TableCell>End Chainage</TableCell>
                                    <TableCell>Start Violation</TableCell>
                                    <TableCell>End Violation</TableCell>
                                    {duplicate && <TableCell>Duplicate</TableCell>}
                                    {reject && <TableCell>Reject</TableCell>}
                                    {viewc && <TableCell>View Challan</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {relChallanSameSect.map((rChallan) => (
                                    <TableRow key={rChallan.txnSpeedChallanId}>
                                        <TableCell component="th" scope="row">
                                            {rChallan.vehicleNumber}
                                        </TableCell>
                                        <TableCell>{rChallan.speed}</TableCell>
                                        <TableCell>{rChallan.sectionName}</TableCell>
                                        <TableCell>{rChallan.fromChainageName}</TableCell>
                                        <TableCell>{rChallan.toChainageName}</TableCell>
                                        <TableCell>{Intl.DateTimeFormat('en-IN', 
                                                    { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', 
                                                        hour: '2-digit', minute: '2-digit' }).
                                                        format(new Date(rChallan.fromDatetime))}
                                        </TableCell>
                                        <TableCell>{Intl.DateTimeFormat('en-IN', 
                                                    { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', 
                                                        hour: '2-digit', minute: '2-digit' }).
                                                        format(new Date(rChallan.toDatetime))}
                                        </TableCell>
                                        {duplicate && <TableCell>
                                            <Button className={classes.button} color="primary" size="small"
                                                variant="contained" autoFocus>
                                                    DUPLICATE
                                            </Button>
                                        </TableCell> }
                                        {reject && <TableCell>
                                            <Button className={classes.button} color="primary" size="small" 
                                                    variant="contained" autoFocus onClick={() => {
                                                    this.onRejectButtonClick(rChallan.txnSpeedChallanId)
                                                }}>
                                                    REJECT
                                            </Button>
                                        </TableCell>}
                                        {viewc && <TableCell>
                                            <Button className={classes.button} color="primary" size="small" 
                                                    variant="contained" autoFocus onClick={() => {
                                                    this.viewChallan(rChallan.txnSpeedChallanId)
                                                }}>
                                                    View Challan
                                            </Button>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div>
        )
    }


    render() {                
        const { classes } = this.props;
        const { errors } = this.state;
        // console.log("ViolationRelated", this.props);

        if (this.state.relatedChallan) {
            let genChallanDiffSect = this.state.relatedChallan.generatedChallanDifferentSections;
            let genChallanSameSect = this.state.relatedChallan.generatedChallanSameSection;
            let relChallanDiffSect = this.state.relatedChallan.relatedChallanDifferentSections;
            let relChallanSameSect = this.state.relatedChallan.relatedChallanSameSection;
      
       
            return (
                <div className={classes.atmsPageOuterDiv}>
                    <Grid>
                        <Card className={classes.relatedViolaton}>
                            <CardHeader title="RELATED VIOLATION" subheader={"Captured violation for same vehicle in last " 
                                                                    + this.props.duplicate_challan_generation_time_window.configValue1 + " hours"}/>
                            <Divider variant="middle" style={{ 'margin': '8px 16px' }}  />
                            <Typography variant="h5" className={classes.sectionHeaders}>Challan Details</Typography>
                            <Grid container spacing={1} className={classes.cdetcont}>
                                <Grid item xs={1} >
                                    <TextField
                                            id="violationId"
                                            label="Violation Id"
                                            value={this.state.violationDetails.violationId}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField
                                            id="vehicleNumber"
                                            label="Vehicle Number"
                                            value={this.state.violationDetails.vehicleNumber}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4} >
                                        <TextField
                                            id="vehicleClass"
                                            label="Violation Class"
                                            value={this.state.violationDetails.vehicleClass}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <TextField
                                            id="directionName"
                                            label="Direction Name"
                                            value={this.state.violationDetails.directionName}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <TextField
                                            id="Vehicle Speed"
                                            label="Vehicle Speed"
                                            value={this.state.violationDetails.vehicleSpeed}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <TextField
                                            id="Section Distance"
                                            label="Section Distance"
                                            value={this.state.violationDetails.sectionDistance}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>   
                                </Grid>
                            <Grid container spacing={1} className={classes.cdetcont}>
                                <Grid item xs={3} >
                                    <TextField
                                        id="Start Violation Time"
                                        label="Start Violation Time"
                                        value={Intl.DateTimeFormat('en-IN', 
                                        { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', 
                                            hour: '2-digit', minute: '2-digit' }).
                                            format(new Date(this.state.violationDetails.startViolationTime))}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        id="End Violation Time"
                                        label="End Violation Time"
                                        value={Intl.DateTimeFormat('en-IN', 
                                        { hourCycle: 'h23', year: 'numeric', month: '2-digit', day: '2-digit', 
                                            hour: '2-digit', minute: '2-digit' }).
                                            format(new Date(this.state.violationDetails?.endViolationTime))}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        id="Start Chainage"
                                        label="Start Chainage"
                                        value={this.state.violationDetails.startChainage}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid> 
                                <Grid item xs={3} >
                                    <TextField
                                        id="End Chainage"
                                        label="End Chainage"
                                        value={this.state.violationDetails.endChainage}
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Divider variant="middle" style={{ 'margin': '16px 0px' }}  />
                            <Typography variant="h5" className={classes.sectionHeaders}>Related Violation - Same Section</Typography>
                            {relChallanSameSect.length > 0 && 
                                this.showRelatedViolationTbl(relChallanSameSect, false, true, false)}
                            <Divider variant="middle" style={{ 'margin': '16px 0px' }} />

                            {/* Section of Related Challan - Different Section  */}
                            <Typography variant="h5" className={classes.sectionHeaders}>Related Violation - Different Section</Typography>
                            {relChallanDiffSect.length > 0 
                                && this.showRelatedViolationTbl(relChallanDiffSect, false, true, false)}
                            <Divider variant="middle" style={{ 'margin': '16px 0px' }} />

                            {/* Section of Generated Challan - Same Section  */}
                            <Typography variant="h5" className={classes.sectionHeaders}>Generate Challan - Same Section</Typography>
                            {genChallanSameSect.length > 0 
                                && this.showRelatedViolationTbl(genChallanSameSect, false, false, true)}
                            <Divider variant="middle" style={{ 'margin': '16px 0px' }} />

                            {/* Section of Generated Challan - Same Section  */}
                            <Typography variant="h5" className={classes.sectionHeaders}>Generated Challan - Different Section</Typography>
                            {genChallanDiffSect.length > 0 
                                && this.showRelatedViolationTbl(genChallanDiffSect, false, false, true)}
                            <Divider variant="middle" style={{ 'margin': '16px 0px' }} />
                            
                        </Card>
                        <Paper>
                            <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.butDiv}>
                                    <Button color="primary" style={{ 'margin': '0px 20px' }} size='medium'  variant="contained" autoFocus 
                                        onClick={() => { 
                                                        this.props.history.push({
                                                            pathname : "/violationchallan"
                                                        }); 
                                                }}>
                                        BACK
                                    </Button>
                                    <Button color="primary" style={{ 'margin': '0px 20px' }} size='medium'  variant="contained" onClick={() => { this.onRejectButtonClick(this.state.violationDetails.challanId) }} autoFocus>
                                        REJECT
                                    </Button>
                                    <Button color="primary" style={{ 'margin': '0px 20px' }} size='medium'  variant="contained" 
                                        disabled={this.state.relatedChallan.generatedChallanSameSection.length === 0}
                                        onClick={() => { this.duplicateChallan(this.state.violationDetails.vehicleNumber) }} autoFocus>
                                        DUPLICATE
                                    </Button>
                                    <Button color="primary" style={{ 'margin': '0px 20px' }} size='medium' variant="contained" onClick={() => { this.generateChallan() }}>
                                        GENERATE
                                    </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                        {/* 
                          * The below Dialogue is open by the Reject button. The code should be similar
                          * to Reject Dialogue in ChallanDetails.js. If some changes are done below. Please
                          * Change in that section also.
                          */}
                       
                        <Dialog open={this.state.openRejectDialog} fullWidth={true} maxWidth='md'>
                            <DialogTitle>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    Reject Violation
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Enter the Rejection Remarks
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    value={this.state.rejectRemarks}
                                    margin="dense"
                                    id="rejectRemarks"
                                    name="rejectRemarks"
                                    onChange={this.handleInputChange}
                                    required={true}
                                    fullWidth
                                />
                                {errors.rejectRemarks.length > 0 &&
                                    <span>{errors.rejectRemarks}</span>}
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" onClick={this.onRejectCancel}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={this.onRejectSubmit}>
                                    Reject
                                </Button>
                            </DialogActions>
                        </Dialog>
                       
                       {/* 
                        * END REJECT DIALOGUE 
                        */}
                        {/* Toaster is open on calling the API on button click */}
                        
                        <Toaster
                            open={this.state.openToast}
                            message={this.state.msgToast}
                            severity={this.state.toastSeverity}
                            onClose={() => {
                           
                                this.setState({ openToast: false });
                            }}
                        />
                        <Modal className={classes.modal}
                            open={this.state.challanHtmlOpen}
                            onClose={this.challanViewOnClose}
                        >
                            <ChallanView challanContent={this.state.challanContent} onClose={this.challanViewOnClose} />
                        </Modal>
                </div>  
            );      
        } else {
            return <div>Loading..</div> 
        }
    }
}


const mapStateToProps = state => {
    return {
        duplicate_challan_generation_time_window : state.common.globalConfig.duplicate_challan_generation_time_window
    };
  };

  
  export default connect(mapStateToProps, null)(withStyles(styles)(ViolationRelated));