import React from 'react';

export default function TabPanel (props) {
  const { children, value, index, ...other } = props;
  console.log("TabPanel", props);
  return (
    <React.Fragment>
        <div role="tabpanel" hidden={value !== index} id={`tabPanel-${index}`} {...other}>
            { value === index && (
                <div>{children}</div>
            )}
        </div>
    </React.Fragment>
  );
}