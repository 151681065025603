import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
     Container,
     Checkbox,
     Typography,
     Button,
     Grid,
     TextField,
     Select,
     MenuItem,
     FormControl,
     InputLabel,
     Box,
     IconButton,
     ListItemText,
     OutlinedInput,
     //Checkbox, 
     FormControlLabel, 
     Divider, withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import appConfig from "../../appConfig.json";
import AddIcon from '@material-ui/icons/Add';
import commonStyles from "../../commonStyles";
import axios from "axios";
import { showToaster } from "../../store/actions/common";
import SuccessModal from "./vmsSuccesPopup";
//import FormHelperText from '@mui/material/FormHelperText';
import Toaster from "../../components/common/Toaster";
import vmsImage1 from "../../images/VMS Images/VMS_IMAGE_01.jpg";
import vmsImage2 from "../../images/VMS Images/VMS_IMAGE_02.jpg";
import vmsImage3 from "../../images/VMS Images/VMS_IMAGE_03.jpg";
import vmsImage4 from "../../images/VMS Images/VMS_IMAGE_04.jpg";



const BASE_URL = appConfig.BASE_URL;

const styles = (theme) => ({
     ...commonStyles,
     cardHeaderStyle: {
       textAlign: "center",
       color: "#002884",
       padding: 0,
       marginLeft: "10px",
     },
     
   });



function VmsMessageSendingScreen( props) {
      console.log("PROPS FOR VMSSSSSSSSSSSSSSSSSSSSSSSS",props);
     const { classes } = props;
     const [allFieldsFilled, setAllFieldsFilled] = useState(false);
     const [directions, setDirections] = useState([]);
     const [directionId, setDirectionId] = useState([]);
     const [equipment,setEquipment] = useState([]);
     const [equipmentId,setEquipmentId] = useState([]);
     const [events,setEvents] = useState([]);     
     const [eventId,setEventId]=useState([]);
     const [successToastMessage, setSuccessToastMessage] = useState(null);
     const [leftImageX, setLeftImageX] = useState(''); 
     const [leftImageY, setLeftImageY] = useState('');
     const [rightImageX, setRightImageX] = useState(''); 
     const [rightImageY, setRightImageY] = useState('');
     const [selectedLeftImageNumber, setSelectedLeftImageNumber] = useState(1); // Initialize with the default number (1 in this case)
     const [selectedRightImageNumber, setSelectedRightImageNumber] = useState(1); // Initialize with the default number (1 in this case)
     const [successModalOpen, setSuccessModalOpen] = useState(false);
     const [successMessage, setSuccessMessage] = useState('');
     const [messageLocX,setMessageLocX] = useState('');
     const [messageLocY,setMessageLocY] = useState('');
     const [isReusable, setIsReusable] = useState();

     
     const [selectedDirection, setselectedDirection] = useState([]);


     //Error for validations-------------------------
     const [directionError, setDirectionError] = useState('');
     const [equipmentError, setEquipmentError] = useState('');
     const [leftImageXError, setLeftImageXError] = useState('');
     const [leftImageYError, setLeftImageYError] = useState('');
     const [rightImageXError, setRightImageXError] = useState('');
     const [rightImageYError, setRightImageYError] = useState('');
     const [messageLocXError, setMessageLocXError] = useState('');
     const [messageLocYError, setMessageLocYError] = useState('');
     const [linesError, setLinesError] = useState([]);

     const vmsImages = [
          { number: 1, image: vmsImage1,name:"VMS Image-1" },
          { number: 2, image: vmsImage2,name:"VMS Image-2" },
          { number: 3, image: vmsImage3,name:"VMS Image-3" },
          { number: 4, image: vmsImage4,name:"VMS Image-4" },
        ];
        
     
     const colors = ['Red', 'Blue', 'Green', 'Orange', 'Yellow'];

     useEffect(()=>{
        const isFilled = (
          directionId.length > 0 &&
          equipmentId.length > 0 &&
          eventId.length > 0 &&
          leftImageX !== '' &&
          leftImageY !== '' &&
          rightImageX !== '' &&
          rightImageY !== '' &&
          selectedLeftImageNumber !== null &&
          selectedRightImageNumber !== null &&
          messageLocX !== '' &&
          messageLocY !== ''
        );
        setAllFieldsFilled(isFilled);
     },[
          directionId,
          equipmentId,
          eventId,
          leftImageX,
          leftImageY,
          rightImageX,
          rightImageY,
          selectedLeftImageNumber,
          selectedRightImageNumber,
          messageLocX,
          messageLocY
     ])



     const handleCheckboxChange = (event) => {
          setIsReusable(event.target.checked);
          const reusableValue = event.target.checked ? 1 : 0; // 0 if checked, 1 if not checked
          // You can use 'reusableValue' in your code as needed, for example, save it to state or use it for further processing.
          console.log("Reusability Value:", reusableValue);
        };





     const onDirectionChange = (event) => {
          const selectedDirections = event.target.value;
          setDirectionId(selectedDirections);
          const direction = [];
          directions.forEach((e) =>{
               console.log(">>>>>>>>>>>>>>>>>>>>>",e)
               console.log(">>>>>>>>>>>>>>>>>>>>>(event.target.value",event.target.value)

               if(event.target.value.includes(e.directionId)){
               direction.push(e.directionId)
               console.log(">>>>>>>>>>direction>>>>>>>>>>>",direction)

               }
          })
          setselectedDirection(direction)
          
          console.log("Selected Directions:", selectedDirection);
        };

        const onEquipmentChange = (event) => {
          const selectedEquipment = event.target.value;
          setEquipmentId(selectedEquipment);
          console.log("Selected Equip:", selectedEquipment);
        };


        let dispatch = useDispatch();

        const resetForm = () => {
          setDirectionId([]);
          setEquipmentId([]);
          setEventId([]);
          setSuccessToastMessage(null);
          setLeftImageX('');
          setLeftImageY('');
          setRightImageX('');
          setRightImageY('');
          setMessageLocX('');
          setMessageLocY('');
          setSelectedLeftImageNumber(1);
          setSelectedRightImageNumber(1);
          setLines([
            {
              text: '',
              font: '',
              color: '',
            },
            {
              text: '',
              font: '',
              color: '',
            },
            {
              text: '',
              font: '',
              color: '',
            },
            {
              text: '',
              font: '',
              color: '',
            },
            {
              text: '',
              font: '',
              color: '',
            },
          ]);
          setLeftImage(null);
          setRightImage(null);
        };
        







     const [lines, setLines] = useState([
          {
               text: '',
               font: '',
               color: '',
          },
          {
               text: '',
               font: '',
               color: '',
          },
          {
               text: '',
               font: '',
               color: '',
          },
          {
               text: '',
               font: '',
               color: '',
          },
          {
               text: '',
               font: '',
               color: '',
          },
     ]);
     const [leftImage, setLeftImage] = useState(null);
     const [rightImage, setRightImage] = useState(null);

     const handleAddLine = () => {
          if (lines.length < 10) { // Limit to 10 lines
            setLines([
              ...lines,
              {
                text: '',
                font: '',
                color: '',
              },
            ]);
          }
        };

     const handleDeleteLine = (index) => {
          const newLines = [...lines];
          newLines.splice(index, 1);
          setLines(newLines);
     };

     //const handleLeftImageChange = (event) => {
     //     const file = event.target.files[0];
     //     setLeftImage(URL.createObjectURL(file));
     //};

     //const handleRightImageChange = (event) => {
     //     const file = event.target.files[0];
     //     setRightImage(URL.createObjectURL(file));
     //};

   useEffect(() => {

       const getDirections = async () => {
          try {
            const res = await axios.get(BASE_URL + "/api/direction?reqType=all");
            setDirections(res.data);
          } catch (error) {
            dispatch(showToaster("error", error.response.data.message));
          }
        };

        const getEquipments = async () => {
             try {
               const res = await axios.get(BASE_URL + "/api/equipments?equipmentCategoryId=5");
               setEquipment(res.data.data);   
             } catch (error) {
               dispatch(showToaster("error", error.response.data.message));
             }
        }

        const getEvents =async () => {
             try {
                 const res =await axios.get(BASE_URL + "/api/eventTypes?reqType=all");
                 setEvents(res.data) 
               //  console.log("Eventssssssssssssssss",res.data);
             } catch (error) {
                 dispatch(showToaster("errror",error.response.data.message)) 
             }
        }
        getDirections();
        getEquipments();
        getEvents();
    }, [])



const getJoinedNames = (idArray, fieldName) => {
     let result = [];
 
  
 
     if (fieldName === "DIRECTION") {
       directions.forEach((direction) => {
         if (idArray.indexOf(direction.directionId) > -1) {
           result.push(direction.directionName);
         }
       });
     }
 
     if (fieldName === "EQUIPMENT") {
       equipment.forEach((eqp) => {
         if (idArray.indexOf(eqp.equipmentId) > -1) {
           result.push(eqp.equipmentName);
         }
       });
     }

     if (fieldName === "EVENT") {
          events.forEach((event) => {
            if (idArray.indexOf(event.eventTypeId) > -1) {
              result.push(event.eventTypeName);
            }
          });
        }
 
  
     return result.join(", ");
   };

   const handleSaveToLibrary = async () => {
     try {
       // Prepare the request data from the form data
     //  const isVmsMessageLinesDataFilled = vmsMessageLinesData.length > 0;
       if (allFieldsFilled ) {
         // All fields are filled, proceed with save logic
   
         const vmsMessageLinesData = lines.map((line, index) => ({
           lineMessage: line.text,
           font: line.font,
           fontColor: line.color,
         }));
         const requestData = {
           eqpId: Array.isArray(equipmentId) ? equipmentId[0] : equipmentId,
           numberOfLines: lines.length,
           isReusable:isReusable ? 1 : 0, 
           msgtypeId: eventId,
           leftImageNo: selectedLeftImageNumber,
           leftImageX: leftImageX,
           leftImageY: leftImageY,
           messageLocationX: messageLocX,
           messageLocationY: messageLocY,
           rightImageNo: selectedRightImageNumber,
           rightImageX: rightImageX,
           rightImageY: rightImageY,
           vmsMessageLinesData: vmsMessageLinesData,
         };
   
         // Send a POST request with the requestData
         const response = await axios.post(BASE_URL + "/api/vms", requestData);
         if (response.status === 201) {
           // Dispatch a success toaster message
           setSuccessMessage('VMS Message Sent!!');
           setSuccessModalOpen(true);
   
           resetForm();
           setMessageLocX('');
           setMessageLocY('');
         }
         console.log('Response from the server:', response.data);
       } else {
         // Incomplete fields, display an error message or handle as needed
         dispatch(showToaster('error', 'Please fill in all required fields.'));
       }
     } catch (error) {
       // Handle any errors that may occur during the save operation
       dispatch(showToaster('error', error.response.data.message));
     }
   };
   
//   console.log("    EQQQQQQQQQQQQQQQQQQQQQ",equipment)
     return (
          <Container style={{ "border": "2px solid black", "padding": "20px 45px 70px 95px", "backgroundColor": "whitesmoke" }} fullWidth>
               {successModalOpen && (
                    <SuccessModal
                         open={successModalOpen}
                         onClose={() => setSuccessModalOpen(false)}
                         message={successMessage}
                         onCheckboxChange={handleCheckboxChange}
                    />
               )}
               <Typography variant="h2" align="center"  className={classes.cardHeaderStyle} >
                    VMS Message Sending Screen
               </Typography>
              
               <Grid container spacing={3}>
                    <Grid item xs={12}>
                         <Typography variant="h5">Connections</Typography>
                         <Box display="flex" alignItems="center" style={{border:"2px solid grey",height:"100px",padding:'2px 4px 2px 8px'}}>
                              <FormControl
                                   variant="outlined"
                                   component="fieldset"
                              >
                                   <InputLabel>Direction</InputLabel>
                                   <Select
                                        name="directionId"
                                        multiple
                                        value={directionId}
                                        input={<OutlinedInput label="Direction" />}
                                        onChange={onDirectionChange} 
                                        renderValue={(selected) =>
                                             getJoinedNames(selected, "DIRECTION")
                                        }
                                       style={{"width":"250px"}} 
                                   >
                                        {directions &&
                                             directions.map((direction) => {
                                                  return (
                                                       <MenuItem
                                                            key={direction.directionId}
                                                            value={direction.directionId}
                                                       >
                                                            <Checkbox
                                                                 checked={directionId.indexOf(direction.directionId) > -1}
                                                            />
                                                            <ListItemText primary={direction.directionName} />
                                                       </MenuItem>
                                                  );
                                             })}
                                   </Select>
                    {directionError && <p>{directionError}</p>}
                              </FormControl>
                              <FormControl
                                   variant="outlined"
                                   component="fieldset"
                              >
                                   <InputLabel>VMS types</InputLabel>
                                   <Select
                                        name="equipmentId"
                                        multiple
                                        value={equipmentId}
                                        input={<OutlinedInput label="Vms types" />}
                                        //onChange={onEquipmentChange}
                                        onChange={(event) => setEquipmentId(event.target.value)}
                                        style={{ "width": "250px",marginLeft:"5px" }}
                                        renderValue={(selected) =>
                                             getJoinedNames(selected, "EQUIPMENT")
                                        }
                                        
                                   >
                                        {equipment &&
                                             equipment.filter(eqp => selectedDirection.includes(eqp.directionId)).map((eqp)=>{
                                                  return (
                                                       <MenuItem
                                                            key={eqp.equipmentId}
                                                            value={eqp.equipmentId} // Update this to the correct field
                                                           
                                                       >
                                                            <Checkbox
                                                                 checked={equipmentId.indexOf(eqp.equipmentId) > -1}
                                                            />
                                                            <ListItemText primary={eqp.equipmentName}  />
                                                       </MenuItem>
                                                  );
                                             })}
                                   </Select>

                              </FormControl>
                              <FormControl
                                   variant="outlined"
                                   component="fieldset"
                              >
                                   <InputLabel>Event types</InputLabel>
                                   <Select
                                        name="eventId"
                                        multiple
                                        value={eventId}
                                        input={<OutlinedInput label="Event Types" />}
                                        //onChange={onEquipmentChange}
                                        onChange={(event) => setEventId(event.target.value)}
                                        style={{ "width": "250px",marginLeft:"5px" }}
                                        renderValue={(selected) =>
                                             getJoinedNames(selected, "EVENT")
                                        }
                                        
                                   >
                                        {events &&
                                             events.map((eve) =>{
                                                  return (
                                                       <MenuItem
                                                            key={eve.eventTypeId}
                                                            value={eve.eventTypeId} // Update this to the correct field
                                                           
                                                       >
                                                            <Checkbox
                                                                   checked={eventId.indexOf(eve.eventTypeId) > -1}
                                                            />
                                                            <ListItemText primary={eve.eventTypeName}  />
                                                       </MenuItem>
                                                  );
                                             })}
                                   </Select>

                              </FormControl>
                         </Box>
                    </Grid>
               </Grid>
               <Divider style={{ "marginTop": "10px" }} />

               <div style={{ "display": "flex", "padding": "15px" }}>

                    <div style={{ "width": "50%" }}>
                         <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} >
                                   <Typography variant="h5">Message Lines</Typography>
                                   <div style={{border:"2px solid grey", maxHeight: '400px',width:'530px',padding:'2px 4px 2px 4px', overflowX: 'auto' }}>
                                   {lines.map((line, index) => (
                                        <Box key={index} display="flex" alignItems="center" style={{ "width": "500px" }}>
                                             <TextField
                                                  label={`Message Line ${index + 1}`}
                                                  fullWidth
                                                  variant="outlined"
                                                  value={line.text}
                                                  onChange={(e) => {
                                                       const newLines = [...lines];
                                                       newLines[index].text = e.target.value;
                                                       setLines(newLines);
                                                  }}
                                                  style={{  "marginTop": "5px" }}
                                             />
                                             <TextField
                                                  label="Font"
                                                  size="small"
                                                  variant="outlined"
                                                  value={line.font}
                                                  onChange={(e) => {
                                                       const newFont = parseInt(e.target.value, 10);
                                                       if (!isNaN(newFont) && newFont >= 1 && newFont <= 255) {
                                                            const newLines = [...lines];
                                                            newLines[index].font = newFont.toString();
                                                            setLines(newLines);
                                                       }
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                       shrink: true,
                                                  }}
                                                  inputProps={{
                                                       min: 1,
                                                       max: 255,
                                                  }}
                                                  style={{ "width": "125px", "marginLeft": "5px" }}
                                             />

                                             <FormControl variant="outlined" size="small" style={{ width: '125px', marginLeft: '5px' }}>
                                                  <InputLabel id={`color-label-${index}`}>Color</InputLabel>
                                                  <Select
                                                       labelId={`color-label-${index}`}
                                                       id={`color-select-${index}`}
                                                       value={line.color}
                                                       onChange={(e) => {
                                                            const newLines = [...lines];
                                                            newLines[index].color = e.target.value;
                                                            setLines(newLines);
                                                       }}
                                                  >
                                                       {colors.map((color) => (
                                                            <MenuItem key={color} value={color}>
                                                                 {color}
                                                            </MenuItem>
                                                       ))}
                                                  </Select>
                                             </FormControl>
                                             <IconButton
                                                  color="secondary"
                                                  onClick={() => handleDeleteLine(index)}
                                             >
                                                  <DeleteIcon />
                                             </IconButton>
                                        </Box>
                                   ))}
                                   <IconButton color="primary" onClick={handleAddLine}>
                                        <AddIcon />
                                   </IconButton>
                                   </div>
                                 
                              </Grid>
                         </Grid>
                         <Grid item xs={12} sm={6} >
                              <Typography variant="h5">Message Location</Typography>
                              <Box alignItems="center" style={{border:'2px solid grey', padding:'5px 4px 2px 4px',width:'530px',height:'200px'}}>
                                   <TextField label="VMS X"   onChange={(e) => setMessageLocX(e.target.value)} style={{ "width": "150px", "height": "50px" }} variant="outlined" /><br/>
                                   <TextField label="VMS Y"   onChange={(e) => setMessageLocY(e.target.value)} style={{ "width": "150px", "height": "50px", "marginTop": "10px" }} variant="outlined" />
                              </Box>
                         </Grid>
                    </div>

                    {/* <Divider /> */}
                    <div style={{ "width": "50%", "marginLeft": "15px",border:"2px solid grey",padding:"2px 4px 2px 8px"}}>
                         <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                   <Typography variant="h5">VMS Image</Typography>
                                   <Grid container spacing={3}>
                                        {/* <Box display="flex"> */}
                                        <Grid item xs={12}>
                                             <Typography variant="h6">Left Pictorial</Typography>
                                             <Box display="flex" alignItems="center" style={{ "width": "500px","marginTop":"5px" }}>
                                                  <Grid item xs={6}>
                                                       <Select
                                                            labelId="left-image-label"
                                                            id="left-image-select"
                                                            value={selectedLeftImageNumber}
                                                            onChange={(e) => setSelectedLeftImageNumber(e.target.value)}
                                                            style={{ "width": "250px" }}
                                                       >
                                                            {vmsImages.map((imageInfo) => (
                                                                 <MenuItem key={imageInfo.number} value={imageInfo.number}>
                                                                     {imageInfo.name}
                                                                 </MenuItem>
                                                            ))}
                                                       </Select>
                                                       <div style={{ width: "200px", height: "200px", border: "2px solid black", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>
                                                            {selectedLeftImageNumber && (
                                                                 <img
                                                                 src={vmsImages.find((imageInfo) => imageInfo.number === selectedLeftImageNumber)?.image}
                                                                      alt={`Left Image ${selectedLeftImageNumber}`}
                                                                      width="200"
                                                                      height="200"
                                                                      onError={() => {
                                                                           console.log(`Error loading Left Image ${selectedLeftImageNumber}`);
                                                                         
                                                                         }}
                                                                 />
                                                            )}
                                                       </div>
                                                       {/*<IconButton
                                                            color="secondary"
                                                            onClick={() => setSelectedLeftImageNumber(null)} // To clear the selection
                                                       >
                                                            <DeleteIcon />
                                                       </IconButton>*/}
                                                  </Grid>

                                                  <Grid item xs={6}>
                                                       <div style={{ width: "100px" }}>
                                                            <TextField
                                                                 label="VMS X"
                                                                 size="small"
                                                                 variant="outlined"
                                                                 value={leftImageX}
                                                                 onChange={(e) => setLeftImageX(e.target.value)}
                                                                 style={{ width: "95px" }}
                                                            />
                                                       </div>
                                                       <div style={{ width: "100px" }}>
                                                            <TextField
                                                                 label="VMS Y"
                                                                 size="small"
                                                                 variant="outlined"
                                                                 value={leftImageY}
                                                                 onChange={(e) => setLeftImageY(e.target.value)}
                                                                 style={{ marginTop: "10px", width: "95px" }}
                                                            />
                                                       </div>
                                                  </Grid>
                                             </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                             <Typography variant="h6">Right Pictorial</Typography>
                                             <Box display="flex" alignItems="center" style={{ "width": "500px","marginTop":"5px" }}>
                                                  <Grid item xs={6}>
                                                       {/*<input type="file" accept="image/*" onChange={handleRightImageChange} />*/}
                                                       <Select
                                                            labelId="right-image-label"
                                                            id="right-image-select"
                                                            value={selectedRightImageNumber}
                                                            onChange={(e) => setSelectedRightImageNumber(e.target.value)}
                                                            style={{ "width": "250px" }}
                                                       >
                                                            {vmsImages.map((imageInfo) => (
                                                                 <MenuItem key={imageInfo.number} value={imageInfo.number}>
                                                                     {imageInfo.name}
                                                                 </MenuItem>
                                                            ))}
                                                       </Select>
                                                       <div style={{ width: "200px", height: "200px", border: "2px solid black", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>
                                                            {selectedRightImageNumber && (
                                                                 <img
                                                                      src={vmsImages.find((imageInfo) => imageInfo.number === selectedRightImageNumber)?.image}
                                                                      alt={`Right Image ${selectedRightImageNumber}`}
                                                                      width="200"
                                                                      height="200"
                                                                 />
                                                            )}
                                                       </div>
                                                       {/*<IconButton
                                                            color="secondary"
                                                            onClick={() => setSelectedRightImageNumber(null)} // To clear the selection
                                                       >
                                                            <DeleteIcon />
                                                       </IconButton>*/}
                                                  </Grid>
                                                  <Grid item xs={6} style={{ marginLeft: "5px", width: "45px" }}>
                                                       <div style={{ width: "100px" }}>
                                                            <TextField
                                                                 label="VMS X"
                                                                 size="small"
                                                                 variant="outlined"
                                                                 value={rightImageX}
                                                                 onChange={(e) => setRightImageX(e.target.value)}
                                                                 style={{ width: "95px" }}
                                                            />
                                                       </div>
                                                       <div style={{ width: "100px" }}>
                                                            <TextField
                                                                 label="VMS Y"
                                                                 size="small"
                                                                 variant="outlined"
                                                                 value={rightImageY}
                                                                 onChange={(e) => setRightImageY(e.target.value)}
                                                                 style={{ marginTop: "10px", width: "95px" }}
                                                            />
                                                       </div>
                                                  </Grid>

                                             </Box>
                                        </Grid>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </div>
               </div>
     <Divider fullWidth/>

     <div style={{marginTop:"10px"}}>
     <Grid item xs={12} >
                    <Button variant="contained" color="primary" onClick={handleSaveToLibrary}  disabled={!allFieldsFilled}  style={{marginLeft:"900px"}}>
                         Save
                    </Button>
                    <FormControlLabel
          control={
            <Checkbox
              checked={isReusable}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Save to Library?"
        />
               </Grid>
     </div>
         

          </Container>
     );
}

export default withStyles(styles)(VmsMessageSendingScreen);
