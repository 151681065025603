import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search';
import DataGrid from '../../../components/mastersGrid/DataGrid.js';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import UserDetailsCreate from './UserDetailsCreate';
import UserDetailsEdit from './UserDetailsEdit';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Toaster from '../../../components/common/Toaster';
import checkEntitlement from "../../../utils/checkEntitlement";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f4f6f8',
  },
  addButton: {
    float: 'right'
  },
  header: {
    display: 'inline'
  },
  list: {
    marginTop: theme.spacing(3)
  },
  modal: {
    'overflow-y': 'auto',
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  sheader: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  }
});

class UserDetails extends React.Component {

  currentSelectedRow = null;
  constructor() {
    super();
    this.state = {
      isEnabled: "all",
      userDetailName: "",
      mobileNo: "",
      urlParams: [],
      isOpen: false,
      modalType: ""
    };
    this.grid = React.createRef();
  }

  componentDidMount() {
    this.props.getUserRole();
    this.props.getUserStatus();
    this.props.getUserShift();
  }

  onSearchSubmit = (event) => {
    event.preventDefault();
    console.log("state =", this.state);
    const urlParams = [];

    if (this.state.userDetailName.length > 0) {
      urlParams.push({ 'key': 'userDetailName', value: this.state.userDetailName });
    }

    if (this.state.mobileNo.length > 0) {
      urlParams.push({ 'key': 'mobileNo', value: this.state.mobileNo });
    }

    if (this.state.isEnabled === "no") {
      urlParams.push({ 'key': 'isEnabled', value: 0 });
    } else if (this.state.isEnabled === "yes") {
      urlParams.push({ 'key': 'isEnabled', value: 1 });
    }

    this.setState({ urlParams: urlParams })
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleRadioChange = (event) => {
    console.log(event.target.value);
    this.setState({ isEnabled: event.target.value });
  }

  componentDidUpdate(prevProps, prevState) {
    /*
     * After the toaster sucess message. We reload the datagrid and thus you see the new entry in the datagrid
     */
    if (prevProps.toasterMessageConfig && prevProps.toasterMessageConfig.type === 'success') {
      this.grid.current.reload();
    }
  }

  render() {
    const { classes } = this.props;
    let modalDiv = null;

    if (this.state.modalType === "ADD") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.isOpen}
        onClose={() => this.setState({ isOpen: false, modalType: "ADD" })}
        closeAfterTransition>
        <UserDetailsCreate onClose={() => this.setState({ isOpen: false, modalType: "" })} />
      </Modal>
    }

    if (this.state.modalType === "EDIT") {
      modalDiv = <Modal
        className={classes.modal}
        open={this.state.isOpen}
        onClose={() => this.setState({ isOpen: false, modalType: "EDIT" })}
        closeAfterTransition>
        <UserDetailsEdit initialValues={this.currentSelectedRow} onClose={() => this.setState({ isOpen: false, modalType: "" })} />
      </Modal>
    }

    return (
      <Container maxWidth="xl" className={classes.root}>
        <Toaster
          open={this.props.toasterOpen}
          message={this.props.toasterMessageConfig && this.props.toasterMessageConfig.message}
          severity={this.props.toasterMessageConfig && this.props.toasterMessageConfig.type}
        />
        <Typography variant="h4" gutterBottom className={classes.header}>
          Users
          </Typography>
        <Button variant="contained"
          disabled={!checkEntitlement(this.props.location.pathname, 'add')}
          color="primary"
          startIcon={<AddIcon />}
          className={classes.addButton}
          onClick={() => this.setState({ isOpen: true, modalType: "ADD" })} >
          Add User
          </Button>
        <Box mt={3}>
          <Card>
            <CardContent>
              <form noValidate autoComplete="off" onSubmit={this.onSearchSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <div style={{ maxWidth: '70px' }}>
                      <Typography variant="h6" gutterBottom className={classes.sheader}>
                        Search
                        </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField label="Name" name="userDetailName" className={classes.sheader} onChange={this.handleInputChange} value={this.state.userDetailName} variant="outlined" />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField label="Mobile Number" name="mobileNo" className={classes.sheader} onChange={this.handleInputChange} value={this.state.mobileNo} variant="outlined"/>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">isEnabled</FormLabel>
                      <RadioGroup row name="isEnabledSearch" value={this.state.isEnabled} onChange={this.handleRadioChange}>
                        <FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="start" />
                        <FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="start" />
                        <FormControlLabel value="all" control={<Radio />} label="all" labelPlacement="start" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      className={`${classes.sheader} ${classes.addButton}`}
                      variant="contained"
                      color="primary"
                      startIcon={<SearchIcon />}
                      type="submit"
                      onClick={() => {
                        //temporary. Form the urlParams object according to the search performed
                        // this.setState({ urlParams: [{ key: 'loginId', value: 'ATAD001' }] });
                      }}>
                      Search
                      </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          <DataGrid downloadReport={true} ref={this.grid} endpoint="api/users" urlParams={this.state.urlParams} isEditable={checkEntitlement(this.props.location.pathname, 'edit')} onEditClick={(event, rowData) => {
            console.log(rowData);
            //pre-populate the modal fields using rowData
            this.currentSelectedRow = rowData;
            this.currentSelectedRow.activationDate = new Date(this.currentSelectedRow.activationDate).toISOString().slice(0, 10);
            this.currentSelectedRow.expiryDate = new Date(this.currentSelectedRow.expiryDate).toISOString().slice(0, 10);
            this.currentSelectedRow.dateOfBirth = new Date(this.currentSelectedRow.dateOfBirth).toISOString().slice(0, 10);
            this.currentSelectedRow.dateOfJoining = new Date(this.currentSelectedRow.dateOfJoining).toISOString().slice(0, 10);
            console.log(this.currentSelectedRow);
            this.setState({ isOpen: true, modalType: "EDIT" })
          }} />
        </Box>
        {modalDiv}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.userDetails.loading,
    toasterOpen: state.common.toasterOpen,
    toasterMessageConfig: state.common.toasterMessageConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserRole: () => dispatch(actions.getUserRole()),
    getUserStatus: () => dispatch(actions.getUserStatus()),
    getUserShift: () => dispatch(actions.getUserShift())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserDetails));